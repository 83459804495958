/**
 * BASIC FORMAT
 * 
 * DO NOT FORGET COMMAS.
 * Add / Remove passives as needed, but do NOT remove "ability1". 
 * If no passive, either leave ability1 as ``, OR as "This Equip does not have a passive"
 * Types can be physLB, magLB, defLB, suppLB, or healLB
 *         
    {
        id: 0,
        name: `Heal Ring`,
        translate: ``,
        location: ``,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 3,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100010.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100011.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100010.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100011.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: ``,
                break: ``
            },
        passive: 
            {
                ability1: ``,
                ability2: ``
            },
        lore: ``
    },

 */

const EquipInformation = [
    { //Heal Ring - Complete
        id: 100011,
        name: `Heal Ring`,
        location: `Craftable - Smith / Alchemist - Exchange Alchemy Stones`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 1,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100010.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100011.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100010.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100011.png`
            },
        stats:
            {
                hp: 20,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - Heals Target's HP by 480.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A ring that allows the wearer to heal themselves and others. These rings were created by a great wizard after the Ancient War, in recompense to all the allies he was unable to save. Easy to obtain and very effective, they remain a popular piece of equipment. Being able to find such rings on ancient battlefields also speaks to how long they have been used.`
    },
    { //Detox Ring - Complete
        id: 100021,
        name: `Detox Ring`,
        location: `Craftable - Smith`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 1,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100020.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100021.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100020.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100021.png`
            },
        stats:
            {
                hp: 20,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - Heals Target's HP by 96. Heals Target's Poison.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A ring that grants protection from poison to the wearer and their allies. First created by a wizard to protect soldiers wielding poison weapons, there rings became a terrible reminder for those men of the horrors of their use. The use of poison weapons was eventually strictly prohibited as part of later peace accords between the Five Great Nations.`
    },
    { //Lightkeeper Ring - Complete
        id: 100031,
        name: `Lightkeeper Ring`,
        location: `Craftable - Smith`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 1,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100030.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100031.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100030.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100031.png`
            },
        stats:
            {
                hp: 20,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - Heals Target's HP by 96. Heals Target's Blind.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Created during the Ancient War to defend against demonic magic that steals away the power of sight. The benefits of the ring extend to thew wearer and those around them. Considered vital when facing a powerful wizard, there were some who yet shunned reliance on such trinkets, instead honing their skills to the point that they simply did not rely on sight.`
    },
    { //Vivacious Ring - Complete
        id: 100041,
        name: `Vivacious Ring`,
        location: `Craftable - Smith`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 1,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100040.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100041.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100040.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100041.png`
            },
        stats:
            {
                hp: 20,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - Heals Target's HP by 96. Heals Target's Seal.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Having chanting interrupted in battle is often fatal, making rings such as these prized since before the the Ancient War. On old battlefields you may even find one of these with a finger bone still inside, a grisly hint at the best way to cancel their protection, while no other bones in the vicinity indicates the subsequent eradication of the original owner.`
    },
    { //Monster Fang Charm - Complete
        id: 100051,
        name: `Monster Fang Charm`,
        location: `Craftable - Smith`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 1,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100050.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100051.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100050.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100051.png`
            },
        stats:
            {
                hp: 0,
                atk: 20,
                def: 0
            },
        skillset:  
            {
                skill: `20s CT - 550% DMG (Physical).`,
                break: 200
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A fang charm containing the fighting spirit of a terrible monster. Owning such a charm was once proof of defeating such a beast, while it's soul would also continue to inspire the holder. However, when a monster dies it is completely consumed by other monsters, meaning the holder of such a charm also becomes a target for this cannibalistic custom.`
    },
    { //Giant Battle Shield - Complete
        id: 100061,
        name: `Giant Battle Shield`,
        location: `Craftable - Smith`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 1,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100060.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100061.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100060.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100061.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 20
            },
        skillset:  
            {
                skill: `60s CT - Reduces Target's DMG Taken by 20% and Earth DMG taken by 40% for 5s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A massive shield loved by giant warriors. Multiple giants equipped with these could form up into a veritable mobile fortress. Of particular note is a shield from the Ancient War now found in the east of the Gramenia Republic, where it is worshipped in a human village. Legends even say that the giant Radigan once protected the village using the shield.`
    },
    { //Merfolk Trident - Complete
        id: 100071,
        name: `Merfolk Trident`,
        location: `Craftable - Smith / Alchemist - Exchange Alchemy Stones`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 1,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100070.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100071.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100070.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100071.png`
            },
        stats:
            {
                hp: 0,
                atk: 20,
                def: 0
            },
        skillset:  
            {
                skill: `20s CT - 500% Water DMG (Physical).`,
                break: 200
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A three pronged spear given to members of sea-dwelling merfolk after they prove themselves in a tribal test. This trial is so difficult that some even lose their lives in the attempt. The merfolk use of this trident in both everyday life and in war is a clear indication of their trust in it. Sometimes, it may even mean more to them than their own lives.`
    },
    { //Regeneration Staff - Complete
        id: 100081,
        name: `Regeneration Staff`,
        location: `Craftable - Smith`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 1,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100080.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100081.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100080.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100081.png`
            },
        stats:
            {
                hp: 0,
                atk: 20,
                def: 0
            },
        skillset:  
            {
                skill: `20s CT - 500% Earth DMG (Magic).`,
                break: 200
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A staff made from the limb of a tree brimming with the power of life. Even after being cut from the tree its life force is such that it continues to slowly grow, and can be knitted back together in a single night if broken. It's prodigious power can be transmitted around it, too, and it can even create an oasis of greenery if plunged down into arid dirt.`
    },
    { //Saint's Medal - Complete
        id: 100091,
        name: `Saint's Medal`,
        location: `Craftable - Smith`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 1,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100090.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100091.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100090.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100091.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 20
            },
        skillset:  
            {
                skill: `60s CT - Reduces all Allies' DMG Taken by 3% and Light DMG Taken by 6% for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A medal awarded to those who seek to do only good. It was created for Alistair, a wizard and military doctor for the Isliid Empire during the Ancient War, who headed out alone to save a handful of demons and wounded men trapped on the slopes of an erupting volcano. The lava claimed his life, but the wounded were saved by the protective spell he left behind.`
    },
    { //Lightning Branch Baton - Complete
        id: 100101,
        name: `Lightning Branch Baton`,
        location: `Craftable - Smith / Alchemist - Exchange Alchemy Stones`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 1,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100100.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100101.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100100.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100101.png`
            },
        stats:
            {
                hp: 0,
                atk: 20,
                def: 0
            },
        skillset:  
            {
                skill: `20s CT - 500% Light DMG (Magic).`,
                break: 200
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Carved from an ancient tree that continued to grow even after being struck by lightning. A craftsman who made conductor's batons happened to use a limb from it and ended up making a baton that can control lightning. Hiding it away, it turned up again when his studio was destroyed during the Ancient War, and has since proven to be a formidable weapon.`
    },
    { //Abyss Scarf - Complete
        id: 100111,
        name: `Abyss Scarf`,
        location: `Craftable - Smith / Alchemist - Exchange Alchemy Stones`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 1,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100110.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100111.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100110.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100111.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 20
            },
        skillset:  
            {
                skill: `60s CT - Reduces Target's DMG Taken by 5% and Water DMG Taken by 10% for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A scarf made from a plant that grows deep in the lakes of Raktehelm. Anyone wearing this scarf can walk into blazing fire without harm or even pain. Originally used by merfolk active on land, having been caught up in the chaos after the war they shared the garments with humans who aided them and this facilitated their spread across the lands.`
    },
    { //Gale Headband - Complete
        id: 100121,
        name: `Gale Headband`,
        location: `Craftable - Smith`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 1,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100120.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100121.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100120.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100121.png`
            },
        stats:
            {
                hp: 10,
                atk: 5,
                def: 5
            },
        skillset:  
            {
                skill: `30s CT - Increases Target's Skill CT Speed by 20% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A headband created in part from an old tree weathered by the wind. Any who wear it become light as a feather and can move like a literal gale. Said to carry the memories of that old tree, so loved by the wind, and enhanced by the crystal set into it. One wearing it is said it have run a week long horseback journey in just one day during the Ancient War.`
    },
    { //Holy Whistle - Complete
        id: 100131,
        name: `Holy Whistle`,
        location: `Craftable - Smith`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 3,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100130.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100131.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100130.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100131.png`
            },
        stats:
            {
                hp: 10,
                atk: 5,
                def: 5
            },
        skillset:  
            {
                skill: `20s CT - Increases Target's DMG Inflicted by 10% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A musical whistle that soothes all who hear it. During the Ancient War, there was a unit besieged by ally betrayal and enemy attacks. Facing despair, suspicion and low morale, all of them expected death. The army minstrel then played this whistle, soothing away all their fears and restoring their courage. The unit fought bravely and managed to survive.`
    },
    { //Alluring Earring - Complete
        id: 100141,
        name: `Alluring Earring`,
        location: `Craftable - Smith / Alchemist - Exchange Alchemy Stones`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 1,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100140.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100141.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100140.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100141.png`
            },
        stats:
            {
                hp: 10,
                atk: 5,
                def: 5
            },
        skillset:  
            {
                skill: `30s CT - Increases Target's Arts Gauge by 5.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `An earring created to look like the eye of a demon with a thousand pupils. When he died he ordered that his eye be used to create a piece of equipment. The energy from it boasts a strange attraction, alluring the souls of not only people but also demons. Copies created later have a similar effect, but none have yet matched the power of the original.`
    },
    { //Healing Necklace - Complete
        id: 100151,
        name: `Healing Necklace`,
        location: `Craftable - Smith`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 2,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100150.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100151.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100150.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100151.png`
            },
        stats:
            {
                hp: 40,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - Heals Target's HP by 100/s for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A relic given by a wizard to the knight who was his master. This wizard was too weak to go into battle, leading to the knight's other retainer's mocking him for failure to aid in defending his master - yet the knight still kept him close. In response to that kindness the power of this item is incredible, and it kept the knight alive through countless battles.`
    },
    { //Soul Recovery - Complete
        id: 100161,
        name: `Soul Recovery`,
        location: `Craftable - Smith`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 2,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100160.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100161.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100160.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100161.png`
            },
        stats:
            {
                hp: 0,
                atk: 40,
                def: 0
            },
        skillset:  
            {
                skill: `20s CT - 500% DMG (Physical). Heals own HP by 100 every HIT.`,
                break: `150`
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A dagger for assassins proposed by a wizard. The pseudo-soul blade can link with the soul of the wielder when bloodied, allowing them to drain the life-force from the one they cut, meaning even a scratch can lead to death. During the disturbances in Raktehelm spies disguised as barbers used these blades to kill many high ranking personnel.`
    },
    { //Fairytale - Complete
        id: 100171,
        name: `Fairytale`,
        location: `Craftable - Smith`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 2,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100170.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100171.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100170.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100171.png`
            },
        stats:
            {
                hp: 0,
                atk: 40,
                def: 0
            },
        skillset:  
            {
                skill: `20s CT - 825% Non-Elemental DMG (Magic).`,
                break: 240
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A type of magical tome that allows anyone to cast spells. The words, rhymes and phrases required for spell chanting are arranged in a storybook format that anyone can follow. Originally made as a fun toy for children by a wizard who loved writing, during the Ancient War many examples were created that allow far more powerful magic to be cast.`
    },
    { //Dragon Blade - Complete
        id: 100181,
        name: `Dragon Blade`,
        location: `Craftable - Smith`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 3,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100180.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100181.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100180.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100181.png`
            },
        stats:
            {
                hp: 0,
                atk: 60,
                def: 0
            },
        skillset:  
            {
                skill: `20s CT - 1000% Fire DMG (Physical).`,
                break: `300`
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A sword made from the bones of a dragon capable of killing any attacker in a single strike. Any who wield it obtain that same power. However, those without sufficient training will lose their mind to the blade and simply attack anyone they see. Therefore only the most highly trained warriors in both body and mind are allowed to even touch it.`
    },
    { //Ouroboros - Complete
        id: 100191,
        name: `Ouroboros`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100190.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100191.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100190.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100191.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `20s CT - 1500% Dark DMG (Physical).`,
                break: 400
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A long spear named for a phenomena in alchemy meaning "complete" or "source". During the Ancient War the alchemist Georig sent this weapon to his friend Parakels. At first, Parakels thought it only a fine weapon, but then he started to dream about fighting himself. By the time he stopped having those dreams , no one could touch him in spear combat.`
    },
    { //Sword Breaker - Complete
        id: 100201,
        name: `Sword Breaker`,
        location: `Craftable - Smith`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 2,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100200.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100201.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100200.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100201.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 40
            },
        skillset:  
            {
                skill: `60s CT - 200% Non-Elemental DMG (Physical). Reduces Enemies' ATK by 10% for 10s.`,
                break: 150
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Also known as the "sword killer". Snapping or smashing down countless weapons, skilled users can fight and survive without even using a shield. "Sword Hunter Chan" was especially famous for using one of these during the Ancient War. He was motivated solely to find expensive weapons to destroy in battle, leading him to eventually be targeted by all sides.`
    },
    { //Mystic Arrow - Complete
        id: 100211,
        name: `Mystic Arrow`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 3,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100210.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100211.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100210.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100211.png`
            },
        stats:
            {
                hp: 0,
                atk: 60,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 2500% Dark DMG (Magic).`,
                break: 600
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A magical bow that can fire "flying" arrows. Carved from the horn of a succubus, its branding magic allows its arrows to always reach their target. those targeted talk of having seen the archer, raised their defenses in plenty of time, but then getting shot from behind - even if there was no one behind them at all.`
    },
    { //Mighty Brace - Complete
        id: 100221,
        name: `Mighty Brace`,
        location: `Craftable - Smith`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 2,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100220.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100221.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100220.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100221.png`
            },
        stats:
            {
                hp: 20,
                atk: 10,
                def: 10
            },
        skillset:  
            {
                skill: `30s CT - Increases Target's Break Power by 20% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `An arm brace made using the fur from powerful yeti beasts. Although the yeti had no specific weapons or magic of their own, their strength was such that a single yeti could destroy an entire armed unit alone. Indeed, it was even said that they could fight oon an equal footing with the gods. One wearing this brace obtains some of that yeti strength.`
    },
    { //Crimson Mantle - Complete
        id: 100231,
        name: `Crimson Mantle`,
        location: `Craftable - Smith / Alchemist - Exchange Alchemy Stones`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 2,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100230.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100231.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100230.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100231.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 40
            },
        skillset:  
            {
                skill: `60s CT - Reduces Target's DMG taken by 5% and Fire DMG Taken by 14% for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `"Defeat me, if you can!" The words of the duelist Ronmel, who fought during the Ancient War wearing this cape. He tore across the battlefield, leaving a red trail as he cut down foes and without a single one able to lay a finger on his mantle. In later life Ronmel left a record stating that he wore the cape to aid his allies by distracting the enemy.`
    },
    { //Numbless Ring - Complete
        id: 100241,
        name: `Numbless Ring`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 1,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100240.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100241.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100240.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100241.png`
            },
        stats:
            {
                hp: 20,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - Heals Target's Paralyze and HP by 96.`,
                break: ``
            },
        passive: 
            {
                ability1: ``,
                ability2: ``
            },
        lore: `A ring worn by wizards studying paralytics in order to escape their effects. The materials are themselves paralytic and so poorly made examples can have the opposite of the desired effect - in spades! The first example was created by the apothecary Clifford, famous for his personality issues, and so some think that risk was entirely intentional.`
    },
    { //Winged Boots - Complete
        id: 100251,
        name: `Winged Boots`,
        location: `Craftable - Smith / Alchemist - Exchange Alchemy Stones`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 2,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100250.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100251.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100250.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100251.png`
            },
        stats:
            {
                hp: 20,
                atk: 10,
                def: 10
            },
        skillset:  
            {
                skill: `30s CT - Increases Target's Skill CT Speed by 40% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Footwear made from the powerful wings of the monstrous bird Algantavis. Even a gentle step in these boots will cause a breeze to sweep the wearer high up into the air. Records of the Ancient War state that monstrous birds rarely came into battle, but mistakenly step in their territory and a flock of them would literally blow the transgressors away.`
    },
    { //Berserker Gloves - Complete
        id: 100261,
        name: `Berserker Gloves`,
        location: `Craftable - Smith`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 2,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100260.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100261.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100260.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100261.png`
            },
        stats:
            {
                hp: 0,
                atk: 40,
                def: 0
            },
        skillset:  
            {
                skill: `20s CT - 750% Fire DMG (Physical).`,
                break: `240`
            },
        passive: 
            {
                ability1: ``,
                ability2: ``
            },
        lore: `There was once a mighty human warrior, even hired by the gods for his overwhelming strength. However, he was also excessively violent. Although his end remains a mystery, one theory is that the gods themselves exploited the chaos of battle to kill him. As his gloves drive those who wear them into a frenzy, his spirit is said to linger inside them still.`
    },
    { //Steel Goggles - Complete
        id: 100271,
        name: `Steel Goggles`,
        location: `Craftable - Smith`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 1,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100270.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100271.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100270.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100271.png`
            },
        stats:
            {
                hp: 10,
                atk: 5,
                def: 5
            },
        skillset:  
            {
                skill: `60s CT - Increases Target's Critical Rate by 100% for 3s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Goggles prized by volcano workers and explorers of taxing climes. Originally designed to protect the eyes, the narrowing of the visual field was considered a tradeoff for a clear view. In recent years, however, this restriction has also been noted to provide greater focus, and fighters have started to wear them regardless of the battle environment.`
    },
    { //Maiden's Water Jug - Complete
        id: 100281,
        name: `Maiden's Water Jug`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 3,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100280.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100281.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100280.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100281.png`
            },
        stats:
            {
                hp: 30,
                atk: 15,
                def: 15
            },
        skillset:  
            {
                skill: `30s CT - Increases Target's Arts Gauge by 20.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A jug used by maidens in the service of the divine for holding holy water. Coroc in the Gramenia Republic was especially devastated by the Ancient War, and was slowly slipping back into the desert. Maidens appeared there, praying and spreading water from these jugs. The people were skeptical, but within just one month Coroc was a green oasis again.`
    },
    { //Great Tree Sparksap - Complete
        id: 100291,
        name: `Great Tree Sparksap`,
        location: `Craftable - Smith`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 2,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100290.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100291.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100290.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100291.png`
            },
        stats:
            {
                hp: 40,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - Heals Target's HP by 840.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Hardened sap sometimes found at the foot of a gigantic tree. People living close to the forest call them "tree gifts" and welcome them as a sign of a bountiful harvest. They are also highly prized aesthetically, often selling for more than gemstones, and this has led the villagers to create a self defense force to drive away any potential poachers.`
    },
    { //Unihorn Staff - Complete
        id: 100301,
        name: `Unihorn Staff`,
        location: `Alchemist - Exchange Alchemy Stones`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 2,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100300.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100301.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100300.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100301.png`
            },
        stats:
            {
                hp: 0,
                atk: 40,
                def: 0
            },
        skillset:  
            {
                skill: `20s CT - 750% Light DMG (Magic).`,
                break: 240
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A staff made from the horn of a unihorn, an especially rare and fabled beast. Reflecting the beast's pure nature, a single wave of this staff is all it takes to eradicate any miasma. However, this also means the staff will shatter if held by anyone with evil in their heart. As only a few remain, their locations are kept by a select handful of individuals.`
    },
    { //Creativity Brush - Complete
        id: 100311,
        name: `Creativity Brush`,
        location: `Craftable - Smith`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 2,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100310.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100311.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100310.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100311.png`
            },
        stats:
            {
                hp: 0,
                atk: 40,
                def: 0
            },
        skillset:  
            {
                skill: `20s CT - 750% Dark DMG (Magic).`,
                break: 240
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A magical brush that makes anything it draws real. Oldelia, a spirit artist, was driven mad by his desire to use the brush, eventually stealing it from a demon temple. He used it to create an otherworldly army and almost wiped out Magia in the west of the Magus Empire. Although foiled at last, the beasts he created went on to inspire a new branch of art.`
    },
    { //Fairy Teardrop - Complete
        id: 100321,
        name: `Fairy Teardrop`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 3,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100320.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100321.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100320.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100321.png`
            },
        stats:
            {
                hp: 60,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - Heals all Allies' HP by 250. Heals all Allies' Disease.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Mystical medicine created from morning mist in the Fairy Forest. A concentration of the healing power of the trees, it can even save patients with a terminal prognosis, but a healthy person will suffer hallucinations and other side effects. Therefore, in order to prevent mistaken imbibing, in the Isliid Empire it can only be prescribed by doctors.`
    },
    { //Prayer Wine 'Soma' - Complete
        id: 100331,
        name: `Prayer Wine 'Soma'`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100330.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100331.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100330.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100331.png`
            },
        stats:
            {
                hp: 120,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `30s CT - Heals all Allies' HP by 300. Heals all Allies' Poison and Curse.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Holy wine extracted from the sap of the divine tree Soma, where the spirits of departed fairies gather. Sweet, refreshing, and a powerful analeptic. Originally created as an offering to the divine, during the Ancient War it was used to bring courage to fighting men. Records state that not a single combatant who drank this wine fell in battle.`
    },
    { //Blessed Wine 'Amurita' - Complete
        id: 100341,
        name: `Blessed Wine 'Amurita'`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 3,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100340.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100341.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100340.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100341.png`
            },
        stats:
            {
                hp: 60,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - Heals Target's HP by 250. Heals all Allies' Seal and Blind.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A type of holy wine brewed in temples. Said to have originally been used to purify the singing voices of a holy choir prior to ritual performances. Clear and delicious, with a hint of floral fragrance. Singers who have imbibed this wine can move listeners to tears with their song, and it is therefore also much loved by people in the performing arts.`
    },
    { //Oracle Flower - Complete
        id: 100351,
        name: `Oracle Flower`,
        location: `Craftable - Smith / Alchemist - Exchange Alchemy Stones`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 2,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100350.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100351.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100350.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100351.png`
            },
        stats:
            {
                hp: 40,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - Heals Target's HP by 200. If User is Earth Elemental then Healing x 1.5.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A flower said to forewarn its owner. Major General Salbatol of the Gramenia Republic once received one from a young girl as he set out to battle. Thinking little more of it, just before a raid he noticed the flower had died. He called the attack off, and they later found his plan had been leaked to the enemy; the flower had saved him and all his men.`
    },
    { //Heavenly Robe 'Michael' - Complete
        id: 100361,
        name: `Heavenly Robe 'Michael'`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100360.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100361.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100360.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100361.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 120
            },
        skillset:  
            {
                skill: `30s CT - Negates 400 DMG Dealt to all Allies' for 8s. Reduces all Allies' Light Elemental DMG Taken by 35% for 8s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A holy garment named for the highest of the angels. Created under the holiest conditions, it can repel all magic. Once, during battle with demons, High Priest Nephilim of the Kingdom of Famelle wrapped a fleeing child in one of these robes. Raging fighting consumed the temple but the child survived, and this led them to the robes being shared with all believers.`
    },
    { //Amulet of Affection - Complete
        id: 100371,
        name: `Amulet of Affection`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100370.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100371.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100370.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100371.png`
            },
        stats:
            {
                hp: 120,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - Heal all Allies' HP by 500. If User is Light Elemental then Healing x 1.5.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A jeweled amulet sometimes found in divine ruins. In a time when the gods still lived there, legend tells of how the daughter of a priest prayed to a jewel in order to save the mortally wounded young man whom she loved. Miraculously, he was saved, and now family members of those who must send loved ones into battle come to ruins looking for similar jewels.`
    },
    { //Angel's Harp - Complete
        id: 100381,
        name: `Angel's Harp`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100380.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100381.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100380.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100381.png`
            },
        stats:
            {
                hp: 500,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - Heals all Allies' HP by 1200.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A relic that only angels were one allowed to own and play. Its music can touch the very soul, healing and purifying all. During the Ancient War, an angel who couldn’t bear to see the other races fighting took his ritual harp onto the battlefield and started to play. Before long, his soothing melody had calmed, then stilled the raging conflict.`
    },
    { //Paladin Shield - Complete
        id: 100391,
        name: `Paladin Shield`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 3,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100390.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100391.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100390.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100391.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 60
            },
        skillset:  
            {
                skill: `30s CT - Reduces all Allies' DMG Taken by 5% and Magical DMG Taken by 10% for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A holy shield given to only the highest of the knights who serve a temple. The shields used by holy knights during the Ancient War were offered up to the gods after the fighting ended. They are currently still worshipped, sparkling brilliantly despite their countless battles. Modern knights use shields from a smithy in Reigrad in the Isliid Empire.`
    },
    { //Thief's Pouch - Complete
        id: 100401,
        name: `Thief's Pouch`,
        location: `Craftable - Smith`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 2,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100400.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100401.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100400.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100401.png`
            },
        stats:
            {
                hp: 20,
                atk: 10,
                def: 10
            },
        skillset:  
            {
                skill: `30s CT - Increases Target's Arts Gauge by 10.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A pouch used by the legendary thief Kalvar, who was active across all of Raktehelm. It contains his seven tools and a variety of functional pouches. Replicas given out as an incentive to join the Thieves' Guild were popular for a time. More recently, thieves have moved away from the seven tools and fill the pouch with what best suits their own robbing style.`
    },
    { //Tome of Idia - Complete
        id: 100411,
        name: `Tome of Idia`,
        location: `Craftable - Smith`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 3,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100410.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100411.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100410.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100411.png`
            },
        stats:
            {
                hp: 0,
                atk: 60,
                def: 0
            },
        skillset:  
            {
                skill: `20s CT - 1100% Non-Elemental DMG (Magic).`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A magical text that has existed since ancient times, author unknown. Written in a lost language, it contains rites for summoning otherworldly beasts and the names gods from beyond the veil. The original is housed at the Famelle Royal Library, but under strict guard. The bizarre cadence of just a few lines from it can cause madness in normal men.`
    },
    { //Tiamat Horn - Complete
        id: 100421,
        name: `Tiamat Horn`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 3,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100420.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100421.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100420.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100421.png`
            },
        stats:
            {
                hp: 30,
                atk: 15,
                def: 15
            },
        skillset:  
            {
                skill: `30s CT - Increases all Allies' DMG Inflicted by 10% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The battle horn said to have been blown by the goddess Tiamat. Causing an earthquake-like rumble it let the whole world know when battle is joined. Tiamat was once a commander in god's army. She rebelled against their audacious ways, however, and was eventually sealed away. All who hear this horn are inspired to fight bravely.`
    },
    { //Terra Conjunction - Complete
        id: 100431,
        name: `Terra Conjunction`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100430.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100431.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100430.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100431.png`
            },
        stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
        skillset:  
            {
                skill: `180s CT - Increases all Allies' Critical Rate by 100% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A celestial globe used for astrological magic. The vicinity of the user is treated as a single heavenly body, providing control over the flow of time. Requiring vast magic to use, even experienced wizards can struggle. Originally created by the alchemist Alishis in order to travel through time, in the end control of only a short period proved possible.`
    },
    { //Stigmata Diategh - Complete
        id: 100441,
        name: `Stigmata Diategh`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 3,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100440.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100441.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100440.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100441.png`
            },
        stats:
            {
                hp: 0,
                atk: 60,
                def: 0
            },
        skillset:  
            {
                skill: `20s CT - 1000% Light DMG (Physical).`,
                break: 300
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A claw-like relic containing the power of the lightning god. Once activated the wearer obtains speed beyond the reaction times of any living creature. Those who see the flicker of this relic will be dead before they even know it. However, such speed also damages the one who uses it, and the wounds thus inflicted led to the "stigmata" being added to the name.`
    },
    { //Phantom Knight Shield - Complete
        id: 100451,
        name: `Phantom Knight Shield`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100450.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100451.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100450.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100451.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 120
            },
        skillset:  
            {
                skill: `30s CT - Negates 600 DMG Dealt to all Allies' for 8s. Reduces all Allies' Magical DMG Taken by 25% for 8s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A shield forged from the armor of knights who died in battle. Harboring remnant energy from multiple knights, the shield itself is said to possess consciousness. For any it does not deem worthy, it remains heavy and hard to use. However, for those whom it does approve it becomes light as a feather, and offers the protection of all the fallen phantoms.`
    },
    { //Warrior's Dagger -  Complete
        id: 100461,
        name: `Warrior's Dagger`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 3,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100460.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100461.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100460.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100461.png`
            },
        stats:
            {
                hp: 0,
                atk: 60,
                def: 0
            },
        skillset:  
            {
                skill: `20s CT - 1100% DMG (Physical).`,
                break: 300
            },
        passive: 
            {
                ability1: ``
            },
        lore: `"Take this. Fight... on..." The words cutting short, the resulting silence, told me that my master had passed. I held the dagger he had given me to my chest, and could do naught by grieve. - "Hill of Warrior's Slumber", Final Chapter. The dagger received by that student is now said to provide any who hold it with the protection of his fallen master.`
    },
    { //Shield Core - Complete
        id: 100471,
        name: `Shield Core`,
        location: `Craftable - Smith`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 3,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100470.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100471.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100470.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100471.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 60
            },
        skillset:  
            {
                skill: `60s CT - Reduces all Allies' DMG Taken by 4% and Earth Elemental DMG Taken by 8% for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `During the Ancient War, there was a wizard too old to go into battle. One day, after seeing families grieving for their lost loved ones at the town graveyard, he returned home and created magical equipment to protect the lives of other soldiers. He continued to make them until he died of old age, and the procession of mourners at his funeral was long indeed.`
    },
    { //Enril Scythe - Complete
        id: 100481,
        name: `Enril Scythe`,
        location: `Craftable - Smith / Alchemist - Exchange Alchemy Stones`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 2,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100480.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100481.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100480.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100481.png`
            },
        stats:
            {
                hp: 0,
                atk: 40,
                def: 0
            },
        skillset:  
            {
                skill: `20s CT - 825% DMG (Physical).`,
                break: 240
            },
        passive: 
            {
                ability1: ``
            },
        lore: `During the Ancient War, the storm god Enril is said to have wandered the lands as a human. He would join the losing side or fight for those whom he took a liking to, swinging his vast scythe to cut down foes with blades of air. He quickly became known as an ally to the weak, but none of those he fought with ever learned that he was actually a god.`
    },
    { //Oracle Panflute - Complete
        id: 100491,
        name: `Oracle Panflute`,
        location: `Craftable - Smith`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 2,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100490.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100491.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100490.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100491.png`
            },
        stats:
            {
                hp: 20,
                atk: 10,
                def: 10
            },
        skillset:  
            {
                skill: `30s CT - Increases all Allies' DMG Inflicted by 7% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A flute blessed by the oracle goddess Apronika. Its music contains complex reverberations, bringing a range of emotions to all who hear it. If the music makes you afraid, it means danger is close. During the Ancient War, the playing of this flute daily helped the people escape danger, keeping losses to a minimum regardless of the scale of the conflict.`
    },
    { //Crown of the Spirit King - Complete
        id: 100501,
        name: `Crown of the Spirit King`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 3,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100500.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100501.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100500.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100501.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 60
            },
        skillset:  
            {
                skill: `30s CT - Reduces Target's DMG Taken by 10% and Water Elemental DMG Taken by 20% for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The Ancient Forest of Gonal has existed in the west of the Kingdom of Famelle since before the Ancient War, and it is deep inside here that the Spirit King is said to dwell. His crown is proof of domain over all spirits, while past crowns are now said to be hidden away. Only the line of kings know the location, and revealing it means immediate abdication.`
    },
    { //Conflagration Mask - Complete
        id: 100511,
        name: `Conflagration Mask`,
        location: `Craftable - Smith`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 2,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100510.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100511.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100510.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100511.png`
            },
        stats:
            {
                hp: 20,
                atk: 10,
                def: 10
            },
        skillset:  
            {
                skill: `30s CT - Increases Target's DMG Inflicted by 20% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A mask created by the fire demon Ifrit. Although it is always aflame, it will never burn the one it considers its owner. The original mask thought lost after the Ancient War, but during a performance of The Phantom of the Library at the Famelle Royal Theatre the mask of the actor playing the phantom, revealed itself as the original when it burst into flame.`
    },
    { //Yumil Ice Fragment - Complete
        id: 100521,
        name: `Yumil Ice Fragment`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 3,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100520.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100521.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100520.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100521.png`
            },
        stats:
            {
                hp: 0,
                atk: 60,
                def: 0
            },
        skillset:  
            {
                skill: `20s CT - 1000% Water DMG (Magic).`,
                break: 300
            },
        passive: 
            {
                ability1: ``
            },
        lore: `There was once a merfolk princess called Yumil who fell in love with a human youth. They met in secret, but then the queen learnt about their forbidden relations. To protect her love Yumil chose banishment to the depths of the icy seas. Whe she finally breathed her last, all alone, the unmeltable crystallization of her feelings was left behind.`
    },
    { //Three-Headed Dog's Chain - Complete
        id: 100531,
        name: `Three-Headed Dog's Chain`,
        location: `Craftable - Smith`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 3,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100530.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100531.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100530.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100531.png`
            },
        stats:
            {
                hp: 30,
                atk: 15,
                def: 15
            },
        skillset:  
            {
                skill: `30s CT - Increases Target's DMG Inflicted by 30% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A collar and chain used to control a demon-made living weapon called Cerberus. Without this attached the beast would tear anyone to shreds, even its creator. In the Ancient War even the most powerful warriors all feared the Cerberus. On the other hand, legend also says that the canine fiend let a baker escape after he piled the beast with treats.`
    },
    { //Luminous Orb - Complete
        id: 100541,
        name: `Luminous Orb`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 3,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100540.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100541.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100540.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100541.png`
            },
        stats:
            {
                hp: 60,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - Heals all Allies' HP by 440.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Magical, lifesaving items gifted by angels to their allies during the Ancient War. Many of those fighting for the angels had no medical experience, and wounds suffered in battle were often fatal. This led the angels to provide them with these orbs, which can heal any injury just by holding them. Examples that still work can be found in Raktehelm even today.`
    },
    { //Mountain Hammer Gaiaslave - Complete
        id: 100551,
        name: `Mountain Hammer Gaiaslave`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 3,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100550.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100551.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100550.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100551.png`
            },
        stats:
            {
                hp: 0,
                atk: 60,
                def: 0
            },
        skillset:  
            {
                skill: `20s CT - 1000% Earth DMG (Physical).`,
                break: 300
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A massive hammer once owned by a mountain god. Harboring the power of creation, striking the ground with it allows terrain to be changed at will. Long ago, tribes living in the mountains squabbled over territory. The god summoned the elders of each tribe and swung this hammer to change the shape of the mountains, making territory boundaries clear.`
    },
    { //Holy Staff 'Dominion' - Complete
        id: 100561,
        name: `Holy Staff 'Dominion'`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100560.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100561.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100560.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100561.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `20s CT - 1650% Non-Elemental DMG (Magic).`,
                break: 400
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A ringed staff bequeathed by the gods to the high priest. Nothing evil can exist within range of its rattling sound. Normally the high priest would never go into battle, let alone fight on the front lines. However, when the demons revived during the unification of Raktehelm, High Priest Franchess took to the field and eradicated the demons in an instant.`
    },
    { //Healing Statue 'Panakeia' - Complete
        id: 100571,
        name: `Healing Statue 'Panakeia'`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100570.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100571.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100570.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100571.png`
            },
        stats:
            {
                hp: 500,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - Heals all Allies' HP by 1000. If User is Light Elemental then Healing x 1.5.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A statue imbued with the power of the healing goddess Panakeia. It can cure not only the sick but also purify the earth and even miasma. She created these statues as safe places for her followers during the Ancient War, and the areas around them remained sickness and starvation free. Many were destroyed in the chaos after the war, leaving few intact today.`
    },
    { //Blazing Blade 'Ragnavelga' - Complete
        id: 100581,
        name: `Blazing Blade 'Ragnavelga'`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100580.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100581.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100580.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100581.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 2500% Fire DMG (Physical). 70% chance of Burning enemy.`,
                break: 1200
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A massive blade forged from fire left by the blazing god Ragnarok, known as the "one who burned the world". During the Ancient War Ragnarok reduced his foes to ash left and right, and even his allies gave him a wide berth. The fire he started would not burn out even after the war ended, and swinging this sword can unleash that destructive power once again.`
    },
    { //Magic Armor 'Gargantua' - Complete
        id: 100591,
        name: `Magic Armor 'Gargantua'`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100590.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100591.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100590.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100591.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `30s CT - Reduces all Allies' DMG Taken by 10% and Dark Elemental DMG Taken by 20% for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The demon Gargantua was so evil, he would kill even his allies to meet his own ends. Eventually other demons laid a trap and sealed his soul within this armor. Any who now wear it receive incredible strength but also an insatiable desire to do evil, as though possessed; a voice telling them more sacrifices are required in numbers of deaths equal to the stars.`
    },
    { //God Life Sword 'Seraph' - Complete
        id: 100601,
        name: `God Life Sword 'Seraph'`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100600.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100601.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100600.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100601.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `20s CT - 2500% Light DMG (Physical).`,
                break: 600
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A holy blade used by divine command to eradicate demons. Sensing the presence of demons causes the blade to ring like a bell, warning the angel wielder. Having slain so many demons during the Ancient War, the sounds of bells in battle eventually started to scare demons away. This is why in Raktehelm even today charms with bells attached are used to ward evil.`
    },
    { //Calming Sword 'Stillness' - Complete
        id: 100611,
        name: `Calming Sword 'Stillness'`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100610.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100611.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100610.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100611.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `20s CT - 1650% DMG (Physical).`,
                break: 400
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A single-edged sword used only in the far east of the Gramenia Republic. This is the oldest of all currently confirmed examples. Records from the Ancient War say it was used by a warrior who lived only by the sword, not relying on magic or alchemy. The moment anyone picks it up their heart calms like still water and they can read enemy movements with ease.`
    },
    { //Soultaker Stone 'Demonica' - Complete
        id: 100621,
        name: `Soultaker Stone 'Demonica'`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100620.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100621.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100620.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100621.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 1500% Dark DMG (Magic). Randomly Blinds Enemy.`,
                break: 800
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A man was enjoying chatting with an artist friend. As the night drew on, the artist presented him with a recently acquired gemstone. Although perhaps sensing something in the allure of the stone, the man thought no more of it and went home. Months later, he visited again to find an empty house devoid of life and the stone, gleaming even more brilliantly.`
    },
    { //Cocytus Arc - Complete
        id: 100631,
        name: `Cocytus Arc`,
        location: `Limited Equip Reedemed from Official GS Artbook purchase`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 2,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100630.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100631.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100630.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100631.png`
            },
        stats:
            {
                hp: 20,
                atk: 10,
                def: 10
            },
        skillset:  
            {
                skill: `30s CT - Auto-fills Target's Arts gauge by 3 for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `This necklace is the vessel for the soul of the Demon Guilias, who was famed for his valor and cunning. Whoever wears it can acquire power beyond human understanding, but in exchange they must give up their own life. Because of this terrible requirement, for a long time it remained hidden deep within ruins once ruled by the Gods, but a thief driven by Guilias' temptation sneaked into the ruins and returned it to the outside world. Since then, the necklace has consumed countless lives and longs for new hosts even now.`
    },
    { //Fox Fan 'White Nine Tails' - Complete
        id: 100641,
        name: `Fox Fan 'White Nine Tails'`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100640.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100641.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100640.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100641.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `60s CT - Increases Target's Break Power by 100% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Legend says that the fox fan will appear at turning points in history. As these also generally involve conflict, the fan is seen as a sign of turmoil, but for the one who comes to rule the chaos it can also be a good omen. What did the fan bring when it appeared during the Ancient War? No one knows. The only clear thing is that it was a branching of fate.`
    },
    { //Cruze's Pocket Watch - Complete
        id: 100651,
        name: `Cruze's Pocket Watch`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 3,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100650.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100651.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100650.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100651.png`
            },
        stats:
            {
                hp: 30,
                atk: 15,
                def: 15
            },
        skillset:  
            {
                skill: `30s CT - Increases all Allies' Skill CT Speed by 50% for 6s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A watch that renowned alchemist Baron Cruze kept locked away in his workshop safe. When activated it allows for brief travel through time. Cruze had a heated rivalry with the alchemist Alishis, creator of a similar device called the Terra Conjunction. Records of Alchemist Associations meetings attended by them both are filled mainly with cursing.`
    },
    { //Summon Skeletal Wings - Complete
        id: 100661,
        name: `Summon Skeletal Wings`,
        location: `Main Story [Chapter 5 > Black Gate > The Black Gate] Stage Clear Reward`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100660.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100661.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100660.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100661.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `120s CT - 6000% DMG (Magic). Summon Gem Blessing: Increases all Allies' Magic DMG by 50% for 30s (Only once).`,
                break: 600
            },
        passive: 
            {
                ability1: `Summon Gem Blessing LV2: Only the highest level effect of the Summon Gem Blessing is effective.`
            },
        lore: `A summonstone allowing a contract with skeletal wings Valzandeth. The contracted one calls with their mind and the magic in the air forms into the terrible beast. Valzandeth is particularly aggressive, even for a dragon, and is also proud; it can even choose to end the contract. The one holding the stone must therefore continuously to prove their might.`
    },
    { //Monster Summonstone - Complete
        id: 100671,
        name: `Monster Summonstone`,
        location: `Main Story [Chapter 2 > Nalgdania > Goliath of the Wastes] Stage Clear Reward`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 3,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100670.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100671.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100670.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100671.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `120s CT - 1000% Non-Elemental DMG (Physical). Summon Gem Blessing: Recovers 30 HP to all Allies' for 20s (only once).`,
                break: 0
            },
        passive: 
            {
                ability1: `Summon Gem Blessing Lv1: Only the highest level effect of the Summon Gem Blessing is effective.`
            },
        lore: `A summonstone indicating a contract with a monster. Beasts always show respect to those they consider superior, and will serve them faithfully. The attacks from their powerful limbs and claws can shred steel like paper. Terrifying when mature, as cubs they are cute and playful. Those who bring a cub home often regret it before too long, however.`
    },
    { //Flicker Bow Heavy Rain - Complete
        id: 100681,
        name: `Flicker Bow Heavy Rain`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100680.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100681.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100680.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100681.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `20s CT - 1500% Light DMG (Magic).`,
                break: 400
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A battle bow that fires arrows at incredible speed. Numerous records concerning it smashing away multiple incoming foes in a single shot speak to its sheer destructive power. However, that power comes at a cost, meaning few can use it. "Flicker Hand" Lezetta is its most famous owner, handling it like an extra limb and filling her foes with merciless arrows.`
    },
    { //Igneet Axe - Complete
        id: 100691,
        name: `Igneet Axe`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100690.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100691.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100690.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100691.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 3750% Fire DMG (Physical).`,
                break: 800
            },
        passive: 
            {
                ability1: ``
            },
        lore: `During the age of the five kings, the army of the Gramenia Republic asked alchemist Ignis to create items that would allow anyone to use magic. After much experimentation, he and his students created this axe. The blade uses a special alloy created by the master metallurgist, and the weapon is capable of turning a log into ash with a single swing.`
    },
    { //Phoenix Feather Duster - Complete
        id: 100701,
        name: `Phoenix Feather Duster`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100700.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100701.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100700.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100701.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 1500% Fire DMG (Magic). 70% chance of Burning Enemy.`,
                break: 800
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A duster named for the phoenix, a bird that spreads fire as it flies and brings glory to a chosen few. Hearing such legends, greedy noble Jildera paid a hunter to capture him one of the birds. The feathers the hunter eventually brought back were used to make this duster. The moment it was used, however, Jildera's fortune went up on smoke.`
    },
    { //Holy Blue Staff - Complete
        id: 100711,
        name: `Holy Blue Staff`,
        location: `Craftable - Smith`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 3,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100710.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100711.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100710.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100711.png`
            },
        stats:
            {
                hp: 60,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - Heals Target's HP by 1000. If User is Water Elemental then Healing x 1.5.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A staff used by a female monk from the Principality of Bamint to aid the injured during the Ancient War. After the war ended she gave the staff to her pupil and said, "This world is made of suffering. But we must not despair. Have a heart filled with mercy, and share it with those you meet. That will bring the light of hope into this world."`
    },
    { //Chaos Rod - Complete
        id: 100721,
        name: `Chaos Rod`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 3,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100720.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100721.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100720.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100721.png`
            },
        stats:
            {
                hp: 0,
                atk: 60,
                def: 0
            },
        skillset:  
            {
                skill: `20s CT - 1000% Dark DMG (Magic).`,
                break: 300
            },
        passive: 
            {
                ability1: ``
            },
        lore: `There was a witch during the Ancient War who chose to serve the demons and fight other humans. She appeared in human villages in a cloud of black mist to spread curses and sickness, and became known and feared as the "witch of chaos". Eventually seen an as enemy by all races, she shared the fate of her beloved demons at the end of a terrible battle.`
    },
    { //Demonsickle Evilscythe - Complete
        id: 100731,
        name: `Demonsickle Evilscythe`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100730.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100731.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100730.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100731.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 6250% Dark DMG (Magic).`,
                break: 1200
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A ritual scythe, wreathed in terrible energy but with little killing power as a weapon. The true terror is the curse it carries to subjugate the soul of anyone it cuts. Alkeroth, devil of lies and betrayal, used this to turn allies and enemies into his puppets. His bizarre orders and insane strategies during the Ancient War were all fueled by this scythe.`
    },
    { //Magitome 'Log Vida' - Complete
        id: 100741,
        name: `Magitome 'Log Vida'`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 3,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100740.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100741.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100740.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100741.png`
            },
        stats:
            {
                hp: 30,
                atk: 15,
                def: 15
            },
        skillset:  
            {
                skill: `40s CT - Increases all Allies' Critical Rate by 50% for 11s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A book containing verses written by ancient humans in praise of natural phenomena as "gods". A treasure-trove of ancient knowledge, the beautiful text sounds like magical chanting when read aloud. The longest passages, loaded with primal power and dedicated to the almighty god of destruction, have been known to burn inattentive inscribers to ash.`
    },
    { //Brilliant Blade 'Regracia' - Complete
        id: 100751,
        name: `Brilliant Blade 'Regracia'`,
        location: `Craftable - Smith`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100750.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100751.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100750.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100751.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 5500% Light DMG (Physical).`,
                break: 0
            },
        passive: 
            {
                ability1: `Increases all Allies' ATK by 15% (Does not stack with abilities of the same name).`
            },
        lore: `A sword owned by Rayas. He found it when exploring caves with his friend Roy, but it was plunged deep in a rock and the boys were unable to pull it free. They visited the cave many more times but could never move it. Eventually Roy left to become a knight. As though answering Rayas' resolve, when he returned to the cave alone he finally removed the sword.`
    },
    { //Sacred Crown 'Raaz' - Missing Lore
        id: 100761,
        name: `Sacred Crown 'Raaz'`,
        location: `Craftable - Smith`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100760.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100761.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100760.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100761.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `50s CT - Reduces all Allies' DMG Taken by 15% and Negates all Allies' Status Ailments except Faint for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increases Own Status Ailment (except Faint) Time Reduction and Evasion Rate by 30%.`
            },
        lore: `Missing Lore.`
    },
    { //Ice Wolf Blade 'Gaimalse' - Complete
        id: 100771,
        name: `Ice Wolf Blade 'Gaimalse'`,
        location: `Limited Event - Chasing my Friend's Illusion (Roy)`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100770.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100771.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100770.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100771.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `70s CT - 5000% Water DMG (Physical). Increases all allies' ATK by 25% for 5s (Only once).`,
                break: 2000
            },
        passive: 
            {
                ability1: `Own DMG 10% UP when Near-Death.`
            },
        lore: `A long sword owned by Roy, and imperial knight. Starting as a regular blade from an imperial smith, Roy's long days of training led to it being pitted many times, and every time he honed it himself. This process eventually made it gleam as though reflecting the soul of the one using it, and the Ice Wolf Blade finally became just as famous as Roy.`
    },
    { //Twin Flames 'Rose Garden' - Complete
        id: 100780,
        name: `Twin Flames 'Rose Garden'`,
        location: `Limited Event - As a Lady (Mira)`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100780.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100781.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100780.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100781.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 6500% Fire DMG (Physical). 100% chance of Burning enemy.`,
                break: 1500
            },
        passive: 
            {
                ability1: `Own ATK 20% UP when Near-Death.`
            },
        lore: `Twin swords loved by Mira, Vice-Commander of the Royal Knights in the Principality of Bamint. Part of becoming of a knight meant she has to use the best equipment the Principality could offer. Taking the ritual knives used when she was born as a princess, powerful fire magic was cast over them and then she gave them the same name as her knight unit.`
    },
    { //Spirit Sword 'Efkeria' - Complete
        id: 100791,
        name: `Spirit Sword 'Efkeria'`,
        location: `Limited Event - Taking the High Road (Fen)`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100790.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100791.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100790.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100791.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 3500% Earth DMG (Physical). Heals own HP by 240 & increases own Arts gauge by 20.`,
                break: 1200
            },
        passive: 
            {
                ability1: `Own DMG 15% UP when enemy's HP is under 50% (Only once).`
            },
        lore: `A dagger long used by Fen, Head of General Staff in the Gramenia Republic. Although he will not reveal where it came from, apparently it was a rare race with whom he has some familiarity. Blessed by the spirits, it is light as paper but can cut as the wind. It's true protection, however, is the prayers for "luck that it brings the one who holds it.`
    },
    { //Treasure Blade 'Ryude Magus' - Complete
        id: 100801,
        name: `Treasure Blade 'Ryude Magus'`,
        location: `Limited Event - The Fabled Sword's Chosen One (Zeorg)`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100800.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100801.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100800.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100801.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 7500% Light DMG (Physical). Increases all allies' CRI rate by 50% during Break for 8s.`,
                break: 1200
            },
        passive: 
            {
                ability1: `DMG to Dragons 20% UP.`
            },
        lore: `A legendary blade from the Kingdom of Famelle and currently carried by the "Hero of Famelle", the aged general Zeorg. Said to be self-aware, and capable of detecting threats to the country. Zeorg himself has used such warnings to protect his homeland numerous times. His battle with a dragon that led to him obtaining the blade is still spoken of today.`
    },
    { //??? - Unknown
        id: 100811,
        name: `???`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 1,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100810.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100811.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100810.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100811.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: ``,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: ``
    },
    { //Battle Axe 'Shurahalt' - Complete
        id: 100821,
        name: `Battle Axe 'Shurahalt'`,
        location: `Craftable - Smith`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100820.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100821.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100820.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100821.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 3100% Fire DMG (Physical).`,
                break: 1500
            },
        passive: 
            {
                ability1: `All allies' Break Power 15% UP (Only once).`
            },
        lore: `The weapon used by Guild Master Ganan when he was Knight Captain. It was gifted to him by the Emperor after defeating a horde of monsters. A certain incident caused him to seal it away, but after battling some young adventurers he took it up again. The first place he headed to get it serviced was the blacksmith he used to drink with.`
    },
    { //Forge Hammer 'Hepaistos' - Complete
        id: 100831,
        name: `Forge Hammer 'Hepaistos'`,
        location: `Rotating Event - Legendary Blacksmith (Gilliam)`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100830.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100831.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100830.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100831.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `80s CT - 7800% Earth DMG (Physical).`,
                break: 1000
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A hammer loved by the blacksmith Reigrad. One the night the young Reigrad decided it was time to strike out on his own, his master simply handed him with this masterpiece of a hammer without a word. "You can borrow that until you can make one better yourself." He has used it to make many weapons since, but says the day he can return it is still far away.`
    },
    { //??? - Unknown
        id: 100841,
        name: `???`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 1,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100840.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100841.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100840.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100841.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: ``,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: ``
    },
    { //??? - Unknown
        id: 100851,
        name: `???`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 1,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100850.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100851.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100850.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100851.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: ``,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: ``
    },
    { //??? - Unknown
        id: 100861,
        name: `???`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 1,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100860.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100861.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100860.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100861.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: ``,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: ``
    },
    { //Super Pan 'Wild Cat' - Complete
        id: 100871,
        name: `Super Pan 'Wild Cat'`,
        location: `Rotating Event - The Master Chef's Test (Sophie)`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100870.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100871.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100870.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100871.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `70s CT - 100% Fire DMG (Physical).`,
                break: 7000
            },
        passive: 
            {
                ability1: `Increases Unit's Skill CT Speed by 5%.`
            },
        lore: `The battle frying pan used by head chef Sophie from Firecat’s Den. On the day she took over the establishment from her father, which was also her birthday, he gifted her this pan and the following advice: “You must be a chef, even in battle. Just keep your cooking and fighting tools separate.” This pan is at her side on every expedition for ingredients.`
    },
    { //Relic 'Funeral Scythe' - Missing Lore
        id: 100881,
        name: `Relic 'Funeral Scythe'`,
        location: `Giant Boss - Shrine of Remembrance (Oldeus)`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100880.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100881.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100880.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100881.png`
            },
        stats:
            {
                hp: 0,
                atk: 250,
                def: 0
            },
        skillset:  
            {
                skill: `70s CT - 7200% DMG (Physical).`,
                break: 900
            },
        passive: 
            {
                ability1: `Blind Time Reduction & Blind evasion rate 10% UP.`
            },
        lore: `Missing Lore.`
    },
    { //Relic 'Cloak of Grief' - Complete
        id: 100891,
        name: `Relic 'Cloak of Grief'`,
        location: `Giant Boss - Shrine of Remembrance (Oldeus)`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100890.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100891.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100890.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100891.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 250
            },
        skillset:  
            {
                skill: `60s CT - Reduces all Allies' Light Elemental DMG Taken by 30% for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increases DMG to Demons by 10%.`
            },
        lore: `The black mantle worn by the death god Oldeus. Sewn from the sinful souls he has reaped, which continue to howl even in death. As these cries disrupt all casting, the wearer is granted powerful resistance to magic. Care is also required, however, as paying attention to those cries for even a moment will curse your soul to wander beyond the veil for eternity.`
    },
    { //Relic 'Empty Heart' - Complete
        id: 100901,
        name: `Relic 'Empty Heart'`,
        location: `Giant Boss - Shrine of Remembrance (Oldeus)`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100900.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100901.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100900.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100901.png`
            },
        stats:
            {
                hp: 60,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `80s CT - Heals Target's HP by 2500.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increases Curse Time Reduction by 10%. Increases Curse Evasion Rate by 10%.`
            },
        lore: `A strange substance that gives off an irregular sparkle. It was long thought to be the source of the death god's power. However, tests in the Magus Empire of La Riiz after the Ancient War proved that it gives off no magic, spirit energy, heat, or anything else at all. It is now believed to connect Oldeus, who exists in a different world, with this one.`
    },
    { //Relic 'End Times Ring' - Complete
        id: 100911,
        name: `Relic 'End Times Ring'`,
        location: `Giant Boss - Shrine of Remembrance (Oldeus)`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 3,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100910.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100911.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100910.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100911.png`
            },
        stats:
            {
                hp: 15,
                atk: 8,
                def: 8
            },
        skillset:  
            {
                skill: `60s CT - Increases all Allies' Critical Rate by 100% for 4s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increases DMG to Gods by 10%.`
            },
        lore: `A beautiful ring belonging to the death god Oldeus. In one region it is deeply believed that being given this ring is a sign of impeding death, but one may survive by using its curse to take 100 other lives. When a terminally ill man came into possession of the ring, he therefore wiped out his entire village, but still died screaming the death god's name.`
    },
    { //Relic 'Bleak Prophet' - Missing Lore
        id: 100921,
        name: `Relic 'Bleak Prophet'`,
        location: `Giant Boss - Sanctuary of the Divine Princess (Radachne)`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100920.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100921.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100920.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100921.png`
            },
        stats:
            {
                hp: 0,
                atk: 250,
                def: 0
            },
        skillset:  
            {
                skill: `80s CT - 9000% DMG (Physical).`,
                break: 1000
            },
        passive: 
            {
                ability1: `DMG to Dragons 10% UP.`
            },
        lore: `Missing Lore.`
    },
    { //Relic 'Crimson Coat' - Complete
        id: 100931,
        name: `Relic 'Crimson Coat'`,
        location: `Giant Boss - Sanctuary of the Divine Princess (Radachne)`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100930.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100931.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100930.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100931.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 250
            },
        skillset:  
            {
                skill: `70s CT - Reduces Target's Fire Elemental DMG Taken by 50% for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increases DMG to Giants by 10%.`
            },
        lore: `A relic created from the wings of insectoid queen Radachne. Making use of the strong yet supple quality of those wings, even the most famous blades in the world can never hope to pierce it. Debate on Radachne has raged since ancient times, with some saying - but with little solid evidence - that her giving this to her children is a sign of a merciful side.`
    },
    { //Relic 'Abyssal Hairpin' - Complete
        id: 100941,
        name: `Relic 'Abyssal Hairpin'`,
        location: `Giant Boss - Sanctuary of the Divine Princess (Radachne)`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100940.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100941.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100940.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100941.png`
            },
        stats:
            {
                hp: 60,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `100s CT - Heals Target's HP by 2500. If User is Water Elemental then Healing x 1.5.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increases DMG to Machines by 10%.`
            },
        lore: `A relic made from underworld butterflies. These purple insects only appear once in the real world, to allow the departed to say farewell to those they leave behind. After imparting those words they quietly fade out of existence by a lake in the underworld. Created from the beautiful wings they leave behind, this relic gifts the wearer intense healing power.`
    },
    { //Relic 'Dark White Spear' - Complete
        id: 100951,
        name: `Relic 'Dark White Spear'`,
        location: `Giant Boss - Sanctuary of the Divine Princess (Radachne)`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 3,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100950.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100951.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100950.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100951.png`
            },
        stats:
            {
                hp: 15,
                atk: 8,
                def: 8
            },
        skillset:  
            {
                skill: `90s CT - Increases all Allies' Arts Gauge by 20.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increases DMG to Spirits by 10%.`
            },
        lore: `The holy spear of insectoid queen Radachne. The tip shines with a permanent white light, which intensifies with each kill. Also famous for being used by the hero Elnis. When Elnis had to defend against the Demon Baron Megiola, he focused all his energy into the spear and destroyed he foe in a single attack, earning himself the nickname "Moonlight Knight".`
    },
    { //??? - Unknown
        id: 100961,
        name: `???`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 1,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100960.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100961.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100960.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100961.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: ``,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: ``
    },
    { //??? - Unknown
        id: 100971,
        name: `???`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 1,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100970.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100971.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100970.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100971.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: ``,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: ``
    },
    { //??? - Unknown
        id: 100981,
        name: `???`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 1,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100980.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100981.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100980.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100981.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: ``,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: ``
    },
    { //Relic 'Machina Core' - Complete
        id: 100991,
        name: `Relic 'Machina Core'`,
        location: `Giant Boss - Fortress of the Machine God (XTF: Eraser)`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_100990.png`,
                detailmax: `/db/Equipment/Detail/item_detail_100991.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_100990.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_100991.png`
            },
        stats:
            {
                hp: 125,
                atk: 62,
                def: 62
            },
        skillset:  
            {
                skill: `5s CT - ATK Mode: Increases all Allies' ATK by 25%. Break Mode: Increases all Allies' Break by 25%.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increases DMG to Beasts by 10%.`
            },
        lore: `A relic that powers self defense and combat systems created by machines. A perfect sphere with no joins or welds, its composition is a complete mystery. It comes as close as possible to the human dream of an eternal engine, being able to create matter from zero and never stopping. It can even attack on its own, unleashing a chain of crater-making explosions.`
    },
    { //Lockdown Shield Jildoth - Complete
        id: 101001,
        name: `Lockdown Shield Jildoth`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_101000.png`,
                detailmax: `/db/Equipment/Detail/item_detail_101001.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_101000.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_101001.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `40s CT - Reduces all Allies' DMG Taken by 15% and Heals all Allies' HP by 25/s for 10s. If User is Light Elemental then Healing x2.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A shield used by the hero Duran during the Ancient War, and a symbol of his creed of fighting to protect the innocent. Facing a man possessed by a magic blade, the hero defended his town with just this shield, it's sparkling light stopping the incoming wave destructive. the true root of this miracle, of course, was the hero's desire to protect others.`
    },
    { //Blade of Sorrow 'Fe'Ode' - Complete
        id: 101011,
        name: `Blade of Sorrow 'Fe'Ode'`,
        location: `Main Story [Chapter 6 > Cave Ruins of Rahn > A Tragic Reunion] Secret Equip`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_101010.png`,
                detailmax: `/db/Equipment/Detail/item_detail_101011.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_101010.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_101011.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 4100% Dark DMG (Magic).`,
                break: 900
            },
        passive: 
            {
                ability1: `Increases BE Gained by 20%.`
            },
        lore: `A sword summoned by the demon Zenon, one of the personal guard of the Six Demon Generals who yet betrayed his own kind. It feeds on the rage and hatred of its user, leading them to eradicate friend and foe alike in a rampage that goes far beyond their desire for revenge - because they have become an empty vessel that can only be filled with blood.`
    },
    { //Temis Egg - Complete
        id: 101021,
        name: `Temis Egg`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 3,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_101020.png`,
                detailmax: `/db/Equipment/Detail/item_detail_101021.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_101020.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_101021.png`
            },
        stats:
            {
                hp: 60,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - Heals Target's HP by 1200.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A gemstone that heals just by holding it. Pained by the vast number of lives lost in the Ancient War, the gods created these as gifts in order to save as many as they could. The eggs came to be worshipped as miracles and are now housed in temples across the land. Few humans know that only a small number of gods were behind these gifts.`
    },
    { //Conquering Dragon Sword 'Gelmed' - Missing Lore
        id: 101031,
        name: `Conquering Dragon Sword 'Gelmed'`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_101030.png`,
                detailmax: `/db/Equipment/Detail/item_detail_101031.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_101030.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_101031.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `30s CT - 5850% Light DMG (Physical).`,
                break: 1000
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Blessed Necklace - Complete
        id: 101041,
        name: `Blessed Necklace`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_101040.png`,
                detailmax: `/db/Equipment/Detail/item_detail_101041.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_101040.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_101041.png`
            },
        stats:
            {
                hp: 120,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `90s CT - Adds a token to revive Target Unit with 50% HP for 120s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A wizard unable to fight alongside his lord, a knight, due to a congenital defect created this at the cost of his life. The knight mourned the wizard's death and kept the necklace with him always. After a major battle, the knight was knocked into a ravine. He was amazed to open his eyes and find himself still alive, thanks to the light of the necklace.`
    },
    { //Divine Green Staff 'Keion' - Complete
        id: 101051,
        name: `Divine Green Staff 'Keion'`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_101050.png`,
                detailmax: `/db/Equipment/Detail/item_detail_101051.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_101050.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_101051.png`
            },
        stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
        skillset:  
            {
                skill: `30s CT - Increases all Allies' Break Power by 28% for 20s. Increases all Allies' Arts Gauge by 1 for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A staff held by a goddess who deeply loved nature and chose to dwell in the forest. The spirits of the forest accepted her as their goddess, came to love her, and called her Keion. She spoke of friendship and understanding across the races and blessed many people, and her staff is now a symbol of the growth and glory of the forest.`
    },
    { //Ice Magic Sword 'Almas' - Complete
        id: 101061,
        name: `Ice Magic Sword 'Almas'`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_101060.png`,
                detailmax: `/db/Equipment/Detail/item_detail_101061.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_101060.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_101061.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 6250% Water DMG (Magic).`,
                break: 1200
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A sword created by the wizard master Almadroth, a key figure in the Ancient War. When more powerful weapons were required to defeat the demons, Almadroth decided to use forbidden arts. At the end of a grueling ritual the sword he created constantly emitted ice magic. Although he spent almost all his magic in its creation, his name lived on through it.`
    },
    { //Demon Axe Exolia - Complete
        id: 101081,
        name: `Demon Axe Exolia`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_101080.png`,
                detailmax: `/db/Equipment/Detail/item_detail_101081.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_101080.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_101081.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `30s CT - 2800% Dark DMG (Physical). Increases own Arts gauge by 10.`,
                break: 600
            },
        passive: 
            {
                ability1: ``
            },
        lore: `An axe used by the demon Leon, who sought to unseal the demon god to whom it originally belonged. Few know the truth about the axe, which is said to be because as a tactician Leon rarely fought: actually, it is because any who faced the axe in battle never lived to tell the tale. As it turns out, even the supposed owner is no exception to this rule.`
    },
    { //??? - Unknown
        id: 101091,
        name: `???`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 1,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_101090.png`,
                detailmax: `/db/Equipment/Detail/item_detail_101091.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_101090.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_101091.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: ``,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: ``
    },
    { //??? - Unknown
        id: 101121,
        name: `???`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 1,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_101120.png`,
                detailmax: `/db/Equipment/Detail/item_detail_101121.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_101120.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_101121.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: ``,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: ``
    },
    { //Hell's Spit 'Karks' - Missing Lore
        id: 101131,
        name: `Hell's Spit 'Karks'`,
        location: `Main Story [Chapter 6 > Cave Ruins of Rahn > A Tragic Reunion] Stage Clear Reward`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 3,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_101130.png`,
                detailmax: `/db/Equipment/Detail/item_detail_101131.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_101130.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_101131.png`
            },
        stats:
            {
                hp: 0,
                atk: 60,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 3300% Fire DMG (Physical).`,
                break: 600
            },
        passive: 
            {
                ability1: `Accuracy 30% UP.`
            },
        lore: `Missing Lore.`
    },
    { //Gem Blade 'Gloria' - Complete
        id: 101141,
        name: `Gem Blade 'Gloria'`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 3,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_101140.png`,
                detailmax: `/db/Equipment/Detail/item_detail_101141.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_101140.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_101141.png`
            },
        stats:
            {
                hp: 0,
                atk: 60,
                def: 0
            },
        skillset:  
            {
                skill: `30s CT - 1500% Light DMG (Magic). Reduces Enemy's Paralyze Resistance by 30% for 5s.`,
                break: 600
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The divine blade of Zexia, head of the ancient God Knights, received this weapon from on high. Adorned with words praising god, fighting while chanting them unleashes the true power of the weapon. Although this suggests a connection between the user and god, in reality the user's own faith and determination in themselves is more important than the poems.`
    },
    { //Burn Book 'Aguni' - Complete
        id: 101151,
        name: `Burn Book 'Aguni'`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_101150.png`,
                detailmax: `/db/Equipment/Detail/item_detail_101151.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_101150.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_101151.png`
            },
        stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
        skillset:  
            {
                skill: `30s CT - Increases all Allies' Skill CT Speed by 100% for 6s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A magical tome containing the crimsons arts of fire demon Agni. Simply chanting the words within allows even those weak in magic to borrow Agni's strength and execute powerful fire-based magic. Known for his cunning, however, the demon mixed in the phrase “I offer my soul to Agni” to catch the unwary off guard, making all due care required.`
    },
    { //Saint's Medal - Complete
        id: 101161,
        name: `Saint's Medal`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 1,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_101160.png`,
                detailmax: `/db/Equipment/Detail/item_detail_101161.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_101160.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_101161.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 20
            },
        skillset:  
            {
                skill: `60s CT - Reduces all Allies' DMG Taken by 3% and Light Elemental DMG Taken by 6% for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A medal awarded to those who seek to do only good. It was created for Alistair, a wizard and military doctor for the Isliid Empire during the Ancient War, who headed out alone to save a handful of demons and wounded men trapped on the slopes of an erupting volcano. The lava claimed his life, but the wounded were saved by the protective spell he left behind.`
    },
    { //Lost Arms - Complete
        id: 101201,
        name: `Lost Arms`,
        location: `Dungeon of Trials 15F Stage Clear Reward`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_101200.png`,
                detailmax: `/db/Equipment/Detail/item_detail_101201.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_101200.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_101201.png`
            },
        stats:
            {
                hp: 0,
                atk: 350,
                def: 350
            },
        skillset:  
            {
                skill: `45s CT - 2800% Water DMG (Physical). Reduces Enemy's ATK by 40% for 8s.`,
                break: 3150
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A sword created as a weapon in defiance of weapons. "What is true strength?" is the question of all who fight must ask. Facing countless trials, warriors have everything protecting them stripped away. The weapon they choose must become nothing less than a part of them. The conviction that remains after everything else is gone is the key to true strength.`
    },
    { //Heaven Blade Klusos - Complete
        id: 101211,
        name: `Heaven Blade Klusos`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 3,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_101210.png`,
                detailmax: `/db/Equipment/Detail/item_detail_101211.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_101210.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_101211.png`
            },
        stats:
            {
                hp: 0,
                atk: 60,
                def: 0
            },
        skillset:  
            {
                skill: `20s CT - 700% Light DMG (Physical). Heals own HP by 200 when HIT.`,
                break: 300
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A weapon used by god soldiers during the later part of the Ancient War. Heavily decorated yet exceptionally light, and with the capacity to heal the user as they fight, it was a valued support weapon. It is also famous as the sword appearing in a fairytale in the kingdom of Famelle, in which a younger brother uses it to save his older brother from demons.`
    },
    { //Water Scale Blade Kyuma - Complete
        id: 101221,
        name: `Water Scale Blade Kyuma`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_101220.png`,
                detailmax: `/db/Equipment/Detail/item_detail_101221.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_101220.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_101221.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 6250% Water DMG (Physical).`,
                break: 1200
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The water dragon Kyuma can shred anything it touches, and this is a sword made from its scales.Sealed with magical power, it is popular among infantry who can't use magic. First created during the Ancient War, the complexity of its forging means only a small number have been made. They are also so beautiful that they are often displayed as works of art.`
    },
    { //Holy Spear Hudkenos - Complete
        id: 101231,
        name: `Holy Spear Hudkenos`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 3,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_101230.png`,
                detailmax: `/db/Equipment/Detail/item_detail_101231.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_101230.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_101231.png`
            },
        stats:
            {
                hp: 0,
                atk: 60,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 1000% Water DMG (Physical). 30% chance of Freezing enemy.`,
                break: 600
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The ice beast Hudnekos dwells in the frozen region in the north-east of the Principality of Bamint, with horns so hard they can pierce a dragon. An adventurer once saw such a battle. While the ice beast won, it's horn was snapped off at the base, allowing the man to collect it after the beast departed. The spear made from it can pierce any material.`
    },
    { //Darkclaw Aetoth - Complete
        id: 101241,
        name: `Darkclaw Aetoth`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 3,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_101240.png`,
                detailmax: `/db/Equipment/Detail/item_detail_101241.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_101240.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_101241.png`
            },
        stats:
            {
                hp: 0,
                atk: 60,
                def: 0
            },
        skillset:  
            {
                skill: `20s CT - 1000% Dark DMG (Physical).`,
                break: 300
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A weapon described in texts kept in the Magus Empire of La Riiz. these claws, used by the Aetoth Bandits, are capable of enveloping foes in darkness. This allowed them to steal many treasures without alerting anyone. A wizard from the Magus Empire captured the thieves and confiscated these claws, but Aetoth himself managed to escape to whereabouts unknown.`
    },
    { //Divine Robe Erial - Complete
        id: 101251,
        name: `Divine Robe Erial`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 3,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_101250.png`,
                detailmax: `/db/Equipment/Detail/item_detail_101251.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_101250.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_101251.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 60
            },
        skillset:  
            {
                skill: `30s CT - Reduces Target's DMG Taken by 25% and Magical DMG Taken by 50% for 8s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A holy garment named for the highest of the gods. Created in the most sacred area of the temple, it can ward off all magic. The high cost means few ever saw one, until one was recovered from a sunken ship after the Ancient War. The fact it remained beautiful after so many years beneath the sea speaks to the incredible quality of its workmanship.`
    },
    { //Holy Knight's Gauntlets - Complete
        id: 101261,
        name: `Holy Knight's Gauntlets`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 3,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_101260.png`,
                detailmax: `/db/Equipment/Detail/item_detail_101261.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_101260.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_101261.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 60
            },
        skillset:  
            {
                skill: `30s CT - Reduces Target's DMG Taken by 30% and Light Elemental DMG Taken by 55% for 8s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Gauntlets worn by a Holy Knight in a Raktehelm myth. Blessed by the protection of light, the central gemstones also heal the wearer. While the process to make them is included in the book, it was also considered a fiction - until someone actually made a pair. That doesn't diminish the difficulty of the feat, however, and their cost is astronomical.`
    },
    { //Lamia's Harp - Complete
        id: 101271,
        name: `Lamia's Harp`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_101270.png`,
                detailmax: `/db/Equipment/Detail/item_detail_101271.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_101270.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_101271.png`
            },
        stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
        skillset:  
            {
                skill: `30s CT - Increases all Allies' Arts Gauge by 10.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `An instrument maker was once so captivated by the sound of Lamia's harp, he devoted his life to recreating it. The completed harp gave power to those around the player, but was so accurate that it also caused the same curse as Lamia. It is therefore rarely used; records even state that were sometimes taken into battle to play it.`
    },
    { //Petra Crown - Complete
        id: 101281,
        name: `Petra Crown`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 3,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_101280.png`,
                detailmax: `/db/Equipment/Detail/item_detail_101281.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_101280.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_101281.png`
            },
        stats:
            {
                hp: 30,
                atk: 15,
                def: 15
            },
        skillset:  
            {
                skill: `30s CT - Increases Target's Skill CT Speed by 100% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A crown gifted by the Emperor of Isliid to the adventurer Vetra, marking his ascension to the top of an unexplored volcano. Those who wear it are protected by the tenacious god of fire, and it greatly aided Vetra in his further adventures. After his death, the volume of copies created speaks to the desire of other explorers to follow in his footsteps.`
    },
    { //Metal Bangle - Complete
        id: 101291,
        name: `Metal Bangle`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 3,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_101290.png`,
                detailmax: `/db/Equipment/Detail/item_detail_101291.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_101290.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_101291.png`
            },
        stats:
            {
                hp: 60,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - Heals Target's HP by 1000. If User is Light Elemental then Healing x 1.5.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `An investigator became lost in the Dara Desert. Faced with a terrible sandstorm, the search party was about to give up when of them noticed a strange light. Following it, they found the missing man wearing this bangle. When he spoke of being enveloped in a warm light before it was placed on his arm, his rescuers offered a prayer to the desert god.`
    },
    {//Burnclaw Alestron - Missing Lore
        id: 101301,
        name: `Burnclaw Alestron`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_101300.png`,
                detailmax: `/db/Equipment/Detail/item_detail_101301.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_101300.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_101301.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `20s CT - 1500% Fire DMG (Physical).`,
                break: 400
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Jade Axe Zeuskia - Complete
        id: 101311,
        name: `Jade Axe Zeuskia`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_101310.png`,
                detailmax: `/db/Equipment/Detail/item_detail_101311.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_101310.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_101311.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `20s CT - 1500% Earth DMG (Physical).`,
                break: 400
            },
        passive: 
            {
                ability1: `An axe long passed down by the spirits. Legend states that a wizard sacrificed his own magic to create this for their protection. Decorated with leaves from the undying holy tree, it brings its user the power and protection of the forest. Master weapon smith Rodia is said to have taken one look and immediately lamented that he could never create its equal.`
            },
        lore: `Missing Lore.`
    },
    { //Bold Dark Spear Nukteris - Complete
        id: 101321,
        name: `Bold Dark Spear Nukteris`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_101320.png`,
                detailmax: `/db/Equipment/Detail/item_detail_101321.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_101320.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_101321.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 3750% Dark DMG (Magic).`,
                break: 800
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Riznelo was one of the richest men alive. After he died, this dark spear was found under the runs of his house. The one who found it is said to have fled in terror from just a glance at its hideous form. Riznelo's diary detailed obtaining it from an "obsidian knight", and how he considered it his mission to never allow it out into the world again.`
    },
    { //Ornith Bow - Complete
        id: 101331,
        name: `Ornith Bow`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_101330.png`,
                detailmax: `/db/Equipment/Detail/item_detail_101331.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_101330.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_101331.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 3750% Earth DMG (Magic).`,
                break: 800
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A concealed weapon used by the legendary hunter Olnith. His hunting style was to shoot from hiding, but if he happened across his prey unexpectedly this was what he used, which can be fired simply by raising the arm with it equipped. It is also blessed by spirits, and many scholars believe Olnith hunted not only beasts but also those who would harm the forest.`
    },
    { //Phantom Mask - Complete
        id: 101361,
        name: `Phantom Mask`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_101360.png`,
                detailmax: `/db/Equipment/Detail/item_detail_101361.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_101360.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_101361.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 120
            },
        skillset:  
            {
                skill: `30s CT - Negates 400 DMG Dealt to all Allies and Reduces all Allies' Dark Elemental DMG Taken by 35% for 8s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A mask worn by a mysterious figure who appeared in front of a scholar investigating a demon castle after the end of the Ancient War. They said nothing, simply removing the mask and leaving it behind. Only the scholar knows who the person was, and why he gave him the mask. The mask itself is tough and harbors dark magic, protecting the wearer.`
    },
    { //Holy Mother's Necklace - Complete
        id: 101371,
        name: `Holy Mother's Necklace`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_101370.png`,
                detailmax: `/db/Equipment/Detail/item_detail_101371.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_101370.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_101371.png`
            },
        stats:
            {
                hp: 120,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - Heals Target's HP by 1500. If User is Water Elemental then Healing x 1.5.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A necklace created for a nun who once ran an orphanage in Isliid. Those who wear it are given the protection of light, receiving not only healing but also liberation from mental troubles. As it includes gemstones, the children were actually kidnapped as they made it, but the nun saved them herself. This earned her the name "holy mother".`
    },
    { //Azure Ring - Complete
        id: 101381,
        name: `Azure Ring`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_101380.png`,
                detailmax: `/db/Equipment/Detail/item_detail_101381.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_101380.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_101381.png`
            },
        stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
        skillset:  
            {
                skill: `30s CT - Increases Target's DMG Inflicted by 50% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A ring made from a deep sea ore only found off the coast of Isliid. The beautiful azure color has captivated the hearts of many people, and no artist has ever successfully recreated it. The stone also contains magic, heightening the consciousness of the wearer. Many therefore desire one of them, but the ore itself is rare, making them expensive.`
    },
    { //Holy Tree Spear Dendron - Missing Lore
        id: 101391,
        name: `Holy Tree Spear Dendron`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_101390.png`,
                detailmax: `/db/Equipment/Detail/item_detail_101391.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_101390.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_101391.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `20s CT - 2500% Earth DMG (Physical).`,
                break: 600
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //God Flicker Bow Kyuknoth - Complete
        id: 101401,
        name: `God Flicker Bow Kyuknoth`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_101400.png`,
                detailmax: `/db/Equipment/Detail/item_detail_101401.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_101400.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_101401.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 2500% Light DMG (Magic). 80% chance of Paralyzing Enemy.`,
                break: 1200
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The great bow of Raktehelm, and equal to the Devil Shadow Bow Meranpatl. A god once visited a village plunged into darkness by demons and fired a arrow from this bow to clear the heavens. Due to its incredible power it is now under guard in a shrine in the west of the Isliid Empire, but travelers still come from around the world to catch a glimpse of it.`
    },
    { //Devil Shadow Bow Meranpatl - Missing Lore
        id: 101411,
        name: `Devil Shadow Bow Meranpatl`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_101410.png`,
                detailmax: `/db/Equipment/Detail/item_detail_101411.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_101410.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_101411.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `20s CT - 2500% Dark DMG (Physical).`,
                break: 600
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Ascension Staff Antoth - Complete
        id: 101421,
        name: `Ascension Staff Antoth`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_101420.png`,
                detailmax: `/db/Equipment/Detail/item_detail_101421.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_101420.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_101421.png`
            },
        stats:
            {
                hp: 500,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - Heals Target's HP by 3600.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Antoth, a priest in a temple in the south west of Gramenia, created his staff to heal his beloved after she was left in a coma from injuries sustained defending him. If the user wants it badly enough, it can even overcome death, but as a result Antoth was banished for practicing necromancy. It is therefore unknown if he was ever reunited with his beloved.`
    },
    { //Netherblade Rodgalos - Missing Lore
        id: 101431,
        name: `Netherblade Rodgalos`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_101430.png`,
                detailmax: `/db/Equipment/Detail/item_detail_101431.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_101430.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_101431.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 2500% Dark DMG (Magic). 50% chance of Blinding enemy.`,
                break: 1200
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Burnspear Eluonith - Complete
        id: 101441,
        name: `Burnspear Eluonith`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_101440.png`,
                detailmax: `/db/Equipment/Detail/item_detail_101441.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_101440.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_101441.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `20s CT - 2500% Fire DMG (Magic).`,
                break: 600
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A spear made from the wings and bones of the burn beast Eluolnith. Harbors magic enough to evaporate an entire lake with a single thrust. Those who can use it without being burned to ash earn the name Fire Lance God. Only five have done so in recorded history, one who was Dalnith in his youth, defeating an Eluolnith alone and offering it up in tribute.`
    },
    { //Divine Robe Radoel - Complete
        id: 101451,
        name: `Divine Robe Radoel`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_101450.png`,
                detailmax: `/db/Equipment/Detail/item_detail_101451.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_101450.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_101451.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `30s CT - Reduces all Allies' DMG Taken by 10% and Earth Elemental DMG Taken by 20% for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The ultimate holy robe, created in a temple deep in the forests of eastern Famelle from the fur of a divine beast that only appears once a year. If the rites cast over it fail then the entire piece is destroyed, making it extremely rare. Ancient records in the Isliid Empire refer to this as a "fabled, mythic robe," speaking to the length of its history.`
    },
    { //Flickerblade Scythe - Complete
        id: 101491,
        name: `Flickerblade Scythe`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 3,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_101490.png`,
                detailmax: `/db/Equipment/Detail/item_detail_101491.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_101490.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_101491.png`
            },
        stats:
            {
                hp: 0,
                atk: 60,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 1000% Light DMG (Physical). 30% chance of Paralyzing Enemy.`,
                break: 600
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A shining, razor-sharp scythe used by the weather goddess Meltina in Raktehelm myth. The duality of this goddess is expressed in her provision of gentle sunlight and bountiful harvests juxtaposed with raging storms and drought. Her scythe is not different, unleashing destructive winds on enemies while also healing the one using it.`
    },
    { //??? - Unknown
        id: 101501,
        name: `???`,
        type: `/db/Mines/thumbnail/LB.png`,
        star: 1,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_101500.png`,
                detailmax: `/db/Equipment/Detail/item_detail_101501.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_101500.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_101501.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: ``,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: ``
    },
    { //Coldian Scarf - Complete
        id: 101511,
        name: `Coldian Scarf`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 3,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_101510.png`,
                detailmax: `/db/Equipment/Detail/item_detail_101511.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_101510.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_101511.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 60
            },
        skillset:  
            {
                skill: `30s CT - Reduces all Allies' DMG Taken by 5% and Dark Elemental DMG Taken by 10% for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A scarf worn by the the members of the legendary Coldia Thieves. Secret magic allows the wearer to become one with the darkness, and allowed the thieves to complete many robberies completely unseen. Coldia is even said to have used such a scarf to walk the underworld undetected, where he stole a great treasure, but the truth remains wreathed in darkness.`
    },
    { //Frozen Beauty Dance Fan - Complete
        id: 101521,
        name: `Frozen Beauty Dance Fan`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 3,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_101520.png`,
                detailmax: `/db/Equipment/Detail/item_detail_101521.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_101520.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_101521.png`
            },
        stats:
            {
                hp: 30,
                atk: 15,
                def: 15
            },
        skillset:  
            {
                skill: `40s CT - Increases Target's Critical Rate by 100% for 5s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A legendary fan said to have blown away an ice storm. After the Ancient War, the Principality of Bamint became locked in ice, and the people despaired at the endless winter. One of the shrine maidens took up this fan and danced, causing the blizzard to cease and the snows to melt. A festival celebrating the coming of spring is still held in Bamint.`
    },
    { //Drakkeus Bolt - Complete
        id: 101531,
        name: `Drakkeus Bolt`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 3,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_101530.png`,
                detailmax: `/db/Equipment/Detail/item_detail_101531.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_101530.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_101531.png`
            },
        stats:
            {
                hp: 0,
                atk: 60,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 2500% Earth DMG (Physical).`,
                break: 600
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A long gun made by a machine who was saved by spirits during the Ancient War. It was designed to keep foes as far as possible, and the resulting gun has range and accuracy far beyond other firearms. Although lethal even at long range, the spirits used them only for warning and defense, and never once used one to take a life.`
    },
    { //Saltcleaver Axe - Complete
        id: 101541,
        name: `Saltcleaver Axe`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 3,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_101540.png`,
                detailmax: `/db/Equipment/Detail/item_detail_101541.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_101540.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_101541.png`
            },
        stats:
            {
                hp: 0,
                atk: 60,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 2500% Water DMG (Physical).`,
                break: 600
            },
        passive: 
            {
                ability1: ``
            },
        lore: `An axe used by giants for sea battles. Incredibly heavy, as expected from a giant weapon, meaning other races can rarely even lift it. Those who can wield it, however, find the power to crush armor and shield with ease. Legends still tell of how a giant warrior used one to part the very sea in two with a single attack during the Ancient War.`
    },
    { //Shield of Dawn - Complete
        id: 101551,
        name: `Shield of Dawn`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 3,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_101550.png`,
                detailmax: `/db/Equipment/Detail/item_detail_101551.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_101550.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_101551.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 60
            },
        skillset:  
            {
                skill: `30s CT - Reduces all Allies' DMG Taken by 15% and Light Elemental DMG Taken by 30% for 8s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Shields made by the gods. Blessed by light, it can protect the bearer from all evil. known for being used by the Dawn Knights, a unit particularly specialized for fighting demons. The sight of them advancing with shields ablaze was much like the breaking of the dawn. demons in the Ancient War came to despise both these shield and the name of the knights.`
    },
    { //Fortress Breaker Daltarion - Complete
        id: 101561,
        name: `Fortress Breaker Daltarion`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_101560.png`,
                detailmax: `/db/Equipment/Detail/item_detail_101561.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_101560.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_101561.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 3750% Earth DMG (Physical).`,
                break: 800
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A hammer created by the demons during the Ancient War. Created as a siege weapon for assaults on fortress and castle walls, and indeed it turned multiple supposedly impregnable defenses into rubble. While appearing too unwieldy for single combat at a glance, hammering the ground once is often more than enough to knock any adversary off their feet.`
    },
    { //Scorch Lance Vehelm - Complete
        id: 101571,
        name: `Scorch Lance Vehelm`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_101570.png`,
                detailmax: `/db/Equipment/Detail/item_detail_101571.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_101570.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_101571.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 3750% Fire DMG (Magic).`,
                break: 800
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A lance named for "Dragon Killer" Velhelm. Starting out as a regular lance, being dipped in so much dragon blood imbued it with the power of fire. Having killed so many dragons, Velhelm purchased the wrath of the entire species. He only rose to the challenge, however, and slew countless attackers who sought him out seeking revenge.`
    },
    { //Earth Headband Yakshutori - Complete
        id: 101581,
        name: `Earth Headband Yakshutori`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_101580.png`,
                detailmax: `/db/Equipment/Detail/item_detail_101581.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_101580.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_101581.png`
            },
        stats:
            {
                hp: 120,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - Heals all Allies' HP by 400. Heals all Allies' Disease.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A headband worn by those who live alongside the earth and control the basic elements of nature. Wearing it provides the vitality of the earth itself. Such individuals dwell apart from the world, shrouding them in mystery. During the Ancient War they came to provide healing for the injured, however. Their motivations are unknown, but they saved many lives.`
    },
    { //Holy Mist Earring - Complete
        id: 101591,
        name: `Holy Mist Earring`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_101590.png`,
                detailmax: `/db/Equipment/Detail/item_detail_101591.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_101590.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_101591.png`
            },
        stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
        skillset:  
            {
                skill: `30s CT - Increases Target's Arts Gauge by 30.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The spirits hold an annual festival in honor of their ancestors, which many races come from across Raktehelm to witness. At the end of the solemn ceremony they offer a prayer to the heavens and the stars all shine brightly in response. This earring houses mist from the following night inside it, and those who wear it remain pure of body at all times.`
    },
    { //Galju Necklace - Complete
        id: 101601,
        name: `Galju Necklace`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_101600.png`,
                detailmax: `/db/Equipment/Detail/item_detail_101601.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_101600.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_101601.png`
            },
        stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
        skillset:  
            {
                skill: `30s CT - Increases Target's Break Power by 800 for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The Galju tribe appear often in legends. Their chief wore this necklace, to which he would add the teeth of the beasts he defeated. Any who now wear it suffer the rage and pain of those slain beasts, but beyond that they are also buoyed by the bold strength of the Galju. The reason the tribes hated the beasts so harshly remains a mystery.`
    },
    { //Cursed Hammer 'Bardrade' - Complete
        id: 101611,
        name: `Cursed Hammer 'Bardrade'`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_101610.png`,
                detailmax: `/db/Equipment/Detail/item_detail_101611.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_101610.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_101611.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 3750% Dark DMG (Physical).`,
                break: 800
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A massive hammer used by the giants. Embedded in its center is the skull of the hero Baldred. Known for defeating countless dragons, he eventually succumbed to sickness. Fearing dragon reprisals, he asked his blacksmith friend to turn his skull into a weapon after he died, and the resulting hammer continued to crush dragons long after his passing`
    },
    { //Gale Spear Rey Vie - Complete
        id: 101621,
        name: `Gale Spear Rey Vie`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_101620.png`,
                detailmax: `/db/Equipment/Detail/item_detail_101621.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_101620.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_101621.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `20s CT - 1500% Earth DMG (Magic).`,
                break: 400
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A treasure spear of the Famelle royal family, and particularly known for being used by "warrior king" Maglagan. There has been much debate on why he named it "Rey Vie". A recently discovered diary from royal aide Beil sheds some light on the matter; on secret trips out of the castle to help people, Maglagan was accompanied by a spirit called Rey.`
    },
    { //Underking Spear 'Hellfire' - Missing Lore
        id: 101631,
        name: `Underking Spear 'Hellfire'`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_101630.png`,
                detailmax: `/db/Equipment/Detail/item_detail_101631.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_101630.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_101631.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 6250% Fire DMG (Physical).`,
                break: 1200
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Magic Bow Logiageiz - Complete
        id: 101641,
        name: `Magic Bow Logiageiz`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_101640.png`,
                detailmax: `/db/Equipment/Detail/item_detail_101641.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_101640.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_101641.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 2500% Water DMG (Magic). 80% chance of Freezing enemy.`,
                break: 1200
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A weapon used by spirits. Although shaped like a bow it's material strength as a weapon is low; rather, it is used to concentrate and then fire a wizard's magic. The more they focus as they draw the string, the stronger the attack becomes. Legends still tell of how a spirit wizard charged a shot all night and then proceeded to level a mountain when he fired.`
    },
    { //Seaband Elgilkrone - Complete
        id: 101651,
        name: `Seaband Elgilkrone`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_101650.png`,
                detailmax: `/db/Equipment/Detail/item_detail_101651.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_101650.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_101651.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `30s CT - Reduces Target's DMG Taken by 33% and Fire Elemental DMG Taken by 66% for 8s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A headband infused with the power of the sea god. Once the dwellers in the seas of Raktehelm forged an alliance beyond the bounds of races, and their leader wore this. As the alliance grew the leader became the true king of the seas. The Ancient War tore the alliance apart, however, and the original headband was lost in the ensuing chaos.`
    },
    { //Insane Bracer Amaltia - Complete
        id: 101661,
        name: `Insane Bracer Amaltia`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_101660.png`,
                detailmax: `/db/Equipment/Detail/item_detail_101661.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_101660.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_101661.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `15s CT - Increases Target's Critical Rate by 100% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The powerful devil collected sinners who have been sent to hell and made them fight, telling the winner they could return to life. He gave each of them one of these bracers, which temporarily brought them back to life while still in hell. No records exist of those who won such duels, so the origin of these bracers in Raktehelm is unknown.`
    },
    { //Goldias Goggles - Complete
        id: 101671,
        name: `Goldias Goggles`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_101670.png`,
                detailmax: `/db/Equipment/Detail/item_detail_101671.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_101670.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_101671.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `30s CT - Reduces Target's DMG Taken by 33% and Light Elemental DMG Taken by 66% for 8s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `When first discovered, these goggles were thought to be functionless oddities. However, one researcher read the incantation carved on the back and their secret power was unleashed with a flash, revealing that they were originally created by a skilled alchemist. The spell reads as follows: "Goldias, warrior of the sun, arrives in a flash of JUSTICE!"`
    },
    { //Chrono Dominator - Complete
        id: 101681,
        name: `Chrono Dominator`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_101680.png`,
                detailmax: `/db/Equipment/Detail/item_detail_101681.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_101680.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_101681.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `30s CT - Increases all Allies' DMG Inflicted by 34% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A relic, created by the alchemist Alishis, that can control the flow of time around it. It was completed just as his bitter rival Baron Cruze also unveiled a watch-based device of a similar nature. It is said that a woman was deeply involved in getting both of them into the control of time, but more detailed records have never been uncovered.`
    },
    { //Relic 'Petraskia' - Missing Lore
        id: 101691,
        name: `Relic 'Petraskia'`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_101690.png`,
                detailmax: `/db/Equipment/Detail/item_detail_101691.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_101690.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_101691.png`
            },
        stats:
            {
                hp: 0,
                atk: 250,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 4000% Fire DMG (Magic). 100% chance to inflict Burn for 10s.`,
                break: 1200
            },
        passive: 
            {
                ability1: `Increase own Blind resistance by 10%.`
            },
        lore: `Missing Lore.`
    },
    { //Relic 'Framshilt' - Complete
        id: 101701,
        name: `Relic 'Framshilt'`,
        location: `Giant Boss - The Crimson King (Degilmagna)`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_101700.png`,
                detailmax: `/db/Equipment/Detail/item_detail_101701.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_101700.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_101701.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 250
            },
        skillset:  
            {
                skill: `60s CT - Reduces all Allies' DMG Taken by 20% for 5s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increases Fire Elemental Resistance by 5%.`
            },
        lore: `A divine shield created from scales of the blazing dragon Degilmagna. It became famous when found pristine in ruins dating from the Ancient War, and became worshipped as a symbol of the battle with Degilmagna. The king of Famelle at the time tried to obtain it, but when he reached the temple the shield had already been taken by a mysterious organization.`
    },
    { //Relic 'Wisfiraft' - Complete
        id: 101711,
        name: `Relic 'Wisfiraft'`,
        location: `Giant Boss - The Crimson King (Degilmagna)`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_101710.png`,
                detailmax: `/db/Equipment/Detail/item_detail_101711.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_101710.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_101711.png`
            },
        stats:
            {
                hp: 30,
                atk: 15,
                def: 15
            },
        skillset:  
            {
                skill: `60s CT - Increases Target's DMG Inflicted by 15% for 30s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increases Curse Time Reduction by 10%. Increases Curse Evasion Rate by 10%`
            },
        lore: `A necklace that gives the power of a blazing dragon to the one who wears it. It was found by three children exploring some ruins. When they were then attacked by demons, they used this to fight back and drive them off. Perhaps hard to believe, but this also proved the power of this relic, Many have tried to discover its power source, but none have succeeded.`
    },
    { //Relic 'Sakredpose' - Complete
        id: 101721,
        name: `Relic 'Sakredpose'`,
        location: `Giant Boss - The Crimson King (Degilmagna)`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 3,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_101720.png`,
                detailmax: `/db/Equipment/Detail/item_detail_101721.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_101720.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_101721.png`
            },
        stats:
            {
                hp: 15,
                atk: 8,
                def: 8
            },
        skillset:  
            {
                skill: `60s CT - Increases all Allies' DMG Inflicted by 18% for 5s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increases Fire Elemental Resistance by 5%.`
            },
        lore: `A relic created by a powerful wizard. It contains the magma that flows inside the body of the blazing dragon Deglmagna. The heat it gives off is magically enhanced, heightening the power of those around it. A robber king who's love of it cost him his life said only this upon his execution: "May the sacred sparkle last forever".`
    },
    { //Relic 'Radeus' - Missing Lore
        id: 101801,
        name: `Relic 'Radeus'`,
        location: `Giant Boss - Glowing Sanctuary (Fosradius)`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_101800.png`,
                detailmax: `/db/Equipment/Detail/item_detail_101801.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_101800.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_101801.png`
            },
        stats:
            {
                hp: 0,
                atk: 250,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 6800% Light DMG (Physical). Increases Arts gauge by 15 when HIT.`,
                break: 700
            },
        passive: 
            {
                ability1: `Physical DMG from enemy 5% DOWN.`
            },
        lore: `Missing Lore.`
    },
    { //Relic 'Force Keratos' - Complete
        id: 101821,
        name: `Relic 'Force Keratos'`,
        location: `Giant Boss - Glowing Sanctuary (Fosradius)`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_101820.png`,
                detailmax: `/db/Equipment/Detail/item_detail_101821.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_101820.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_101821.png`
            },
        stats:
            {
                hp: 60,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `30s CT - 	Heals all Allies' HP by 200. Heals all Allies' Status Ailments except for Faint and Disease.`,
                break: 0
            },
        passive: 
            {
                ability1: `Reduces Physical DMG Taken by 5%.`
            },
        lore: `The Light Dragon Fosradius once sought interaction with humans, and gave this this relic to the most innocent girl he could find. She became an object of worship, and gave blessings to many people. However, not satisfied, the people started to make demands of the girl. The dragon despaired at the depth of human greed, and the girl left the relic and disappeared.`
    },
    { //??? - Unknown
        id: 101831,
        name: `???`,
        type: `/db/Mines/thumbnail/LB.png`,
        star: 1,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_101830.png`,
                detailmax: `/db/Equipment/Detail/item_detail_101831.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_101830.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_101831.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: ``,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: ``
    },
    { //Relic 'Lesser Demonheart' - Complete
        id: 101841,
        name: `Relic 'Lesser Demonheart'`,
        location: `Giant Boss - Caverns of Dark (Valzendeth)`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_101840.png`,
                detailmax: `/db/Equipment/Detail/item_detail_101841.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_101840.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_101841.png`
            },
        stats:
            {
                hp: 125,
                atk: 62,
                def: 62
            },
        skillset:  
            {
                skill: `40s CT - Increases target's Arts gauge by 5 for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Arts Gauge Regenerates by 1 when Near-Death.`
            },
        lore: `A demon sphere containing the loyalty of the ultimate monster Valzandeath. This beast served his master like a true knight, resisting all urges for destruction. However, upon his master's defeat he unleashed his true power in vengeance, scorching the very earth. He then cut out his own heart, in order not to further defile the life he lived with his master.`
    },
    { //Relic 'Mavlocalypse' - Complete
        id: 101851,
        name: `Relic 'Mavlocalypse'`,
        location: `Giant Boss - Caverns of Dark (Valzendeth)`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_101850.png`,
                detailmax: `/db/Equipment/Detail/item_detail_101851.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_101850.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_101851.png`
            },
        stats:
            {
                hp: 0,
                atk: 60,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 4500% Dark DMG (Physical). Poisons ownself. Increases own Break power by 100% for 4s.`,
                break: 1800
            },
        passive: 
            {
                ability1: `Increases Own MAX HP by 10% (Only once).`
            },
        lore: `A axe spat out by the weapon devouring dragon Mavlosgia. Already powerful, exposure to pure dark magic inside his body proceeded to warp and twist it. The central gemstone is charged with magic, allowing each swing to cause massive shockwaves. Those drawn to its power are fated to die, however, as the weapon always calls Mavlosgia back to it.`
    },
    { //Relic 'Diablos' - Complete
        id: 101861,
        name: `Relic 'Diablos'`,
        location: `Giant Boss - Shadow of the God Eater (Mavroskia)`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_101860.png`,
                detailmax: `/db/Equipment/Detail/item_detail_101861.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_101860.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_101861.png`
            },
        stats:
            {
                hp: 0,
                atk: 250,
                def: 0
            },
        skillset:  
            {
                skill: `70s CT - 6800% Dark DMG (Magic). Uses 10% of current HP. Increases own Dark DMG by 50% for 5s.`,
                break: 700
            },
        passive: 
            {
                ability1: `Increases Own MAX HP by 10% (Only once).`
            },
        lore: `A staff created in the battle between the dragon Mavlosgia and a woman from the Magus Empire of La Riiz during the Ancient War. The breath of death from the beast washed over her as they fought, and afterward her staff had been completely warped. Sensing its dark power she immediately sealed it away, but eventually it was stolen by a unknown party.`
    },
    { //Relic 'Skeletooth' - Complete
        id: 101871,
        name: `Relic 'Skeletooth'`,
        location: `Giant Boss - Caverns of Dark (Valzendeth)`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 3,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_101870.png`,
                detailmax: `/db/Equipment/Detail/item_detail_101871.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_101870.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_101871.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 30
            },
        skillset:  
            {
                skill: `50s CT - Substitutes Target's DMG Taken for 6s. If used on self, reduces Own DMG Taken by 10% for 6s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Arts Gauge Regenerates by 1 when Near-Death.`
            },
        lore: `A shield made from the exceptionally tough bones of a Valzandeath, and in particular the wing bones. The beam the beast can fire from its mouth is formed of magic collected from the air using his wings as antenna, which means the wing bones can absorb and store massive volumes of magic. These properties allow this shield to defend against any magic attack.`
    },
    { //Relic 'Frost Killer' - Complete
        id: 101921,
        name: `Relic 'Frost Killer'`,
        location: `Giant Boss - Cry of the Azure Roc (Freydal)`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 3,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_101920.png`,
                detailmax: `/db/Equipment/Detail/item_detail_101921.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_101920.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_101921.png`
            },
        stats:
            {
                hp: 0,
                atk: 30,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 3400% Water DMG (Magic).`,
                break: 1000
            },
        passive: 
            {
                ability1: `Increases DMG to Machines by 10%.`
            },
        lore: `A scythe possessed of the magic of the gale Fledal. Quite literally a feather that can form ice, it is not only made from the wing of a bird with snow magic but has that same magic infused into the blade, earning it the name "relic". It brings the power of a blizzard to the user, allowing them to transform any season into winter with a single swipe.`
    },
    { //Relic 'Zero = Recorder' - Complete
        id: 101941,
        name: `Relic 'Zero = Recorder'`,
        location: `Giant Boss - Cry of the Azure Roc (Freydal)`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_101940.png`,
                detailmax: `/db/Equipment/Detail/item_detail_101941.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_101940.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_101941.png`
            },
        stats:
            {
                hp: 30,
                atk: 15,
                def: 15
            },
        skillset:  
            {
                skill: `60s CT - Adds Freeze Effect to all Allies' Attack for 10s. Increases all Allies' Arts Gauge by 20.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increases Paralysis Time Reduction by 10%. Increases Paralysis Evasion Rate by 10%.`
            },
        lore: `A magic recorder imbued with the power of the gale Fledal. Possessed of the freezing ice magic, it allows the user to create absolute zero conditions as they please. These properties can also be used to provide protection to allies from ice, making this instrument a textbook example of how the way something is used all comes down to the user.`
    },
    { //Relic 'Blaze Staff' - Missing Lore
        id: 102001,
        name: `Relic 'Blaze Staff'`,
        location: `Limited Event - Plot of the Fallen Priest`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102000.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102001.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102000.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102001.png`
            },
        stats:
            {
                hp: 0,
                atk: 250,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 5500% Fire DMG (Magic). Reduces all allies' Skill CT by 30% for 10s.`,
                break: 500
            },
        passive: 
            {
                ability1: `DMG to Earth enemy 5% UP.`
            },
        lore: `Missing Lore.`
    },
    { //Relic 'Blaze Gauntlets' - Missing Lore
        id: 102011,
        name: `Relic 'Blaze Gauntlets'`,
        location: `Limited Event - Plot of the Fallen Priest`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102010.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102011.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102010.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102011.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 250
            },
        skillset:  
            {
                skill: `30s CT - Reduces Target's Physical DMG Taken by 90% for 5s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increases Earth Elemental Resistance by 5%.`
            },
        lore: `Missing Lore.`
    },
    { //Relic 'Blaze Ring' - Missing Lore
        id: 102021,
        name: `Relic 'Blaze Ring'`,
        location: `Limited Event - Plot of the Fallen Priest`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102020.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102021.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102020.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102021.png`
            },
        stats:
            {
                hp: 30,
                atk: 15,
                def: 15
            },
        skillset:  
            {
                skill: `30s CT - Increases Own Skill CT by 200% for 6s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increases DMG to Humans by 10%.`
            },
        lore: `Missing Lore.`
    },
    { //Relic 'Blaze Medicine' - Complete
        id: 102031,
        name: `Relic 'Blaze Medicine'`,
        location: `Limited Event - Plot of the Fallen Priest`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 3,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102030.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102031.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102030.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102031.png`
            },
        stats:
            {
                hp: 30,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `30s CT - Heal's Target's HP by 300. Increases Target's DMG Inflicted by 10% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increases Burning Time Reduction by 10%. Increases Burning Evasion Rate by 10%`
            },
        lore: `This medicine is detailed in a forbidden text kept in a light bringer god temple. When the water beast Juroga attacked Bamint, the gods taught a priest how to make a number of relics. As this medicine uses the power of fire to heal, those with strong fire power themselves can boost its effects. The relics Kalon possessed were just copies of the originals.`
    },
    { //Ex Gloria - Missing Lore
        id: 102041,
        name: `Ex Gloria`,
        location: `Arena 2500 Clear Milestone Reward`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102040.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102041.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102040.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102041.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `80s CT - 9000% DMG (Physical).`,
                break: 1000
            },
        passive: 
            {
                ability1: `Increases all Allies' DMG to Demons by 10% (Only once).`
            },
        lore: `Missing Lore.`
    },
    { //Agio Lamuz - Complete
        id: 102051,
        name: `Agio Lamuz`,
        location: `Arena 250 Clear Milestone Reward`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102050.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102051.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102050.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102051.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 7200% Non-Elemental DMG (Magic).`,
                break: 0
            },
        passive: 
            {
                ability1: `Increases all Light Elemental Allies' DMG by 10% (only once).`
            },
        lore: `A rod created in Isliid with aid from the Magus Empire in order to be gifted to skilled retainers and wizards, and with a focus on practical use in battle. It is modeled on an item used in coronations to represent the "holiness" received from the previous Emperor. The way it responds to positive energy also means none with evil in their hearts can use it.`
    },
    { //Diez Jade - Complete
        id: 102061,
        name: `Diez Jade`,
        location: `Arena 500 Clear Milestone Reward`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102060.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102061.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102060.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102061.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `50s CT - Reduces all Allies' DMG Taken by 15% and Evades all Status Ailments except for Faint for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increases all allies' Status Ailment resistance by 10% (Only once).`
            },
        lore: `A replica of the sparkling shield used by the legendary "Hero King" Emperor of Isliid. Once, when marching with 500 men to take his pregnant queen to rest in her hometown, the Hero King was set upon by 2000 enemy. He fought on the frontline to protect her, and from the legend this shield engraved with "A Knight Protects" started to be given as a reward.`
    },
    { //Claria Health - Complete
        id: 102071,
        name: `Claria Health`,
        location: `Arena 50 Clear Milestone Reward`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102070.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102071.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102070.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102071.png`
            },
        stats:
            {
                hp: 500,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - Heals all Allies' HP by 1200.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increases all Allies' Max HP by 5% (only once).`
            },
        lore: `A treasure given to Isliid knight captains upon receiving direct orders from the Emperor. Although only a figurehead now, the Emperor must still decree large scale military action. Accepting this item means taking on the defense of the empire. It’s sparkling blue stone contains not only raw magic but also the Emperor's wishes for the bearer to return alive.`
    },
    { //Optima Rade - Complete
        id: 102081,
        name: `Optima Rade`,
        location: `Arena 1000 Clear Milestone Reward`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102080.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102081.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102080.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102081.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `40s CT - Increases Target's Arts Gauge by 5 for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Regenerates all Allies' Arts Gauge by 1 when KO'd (only once).`
            },
        lore: `Water drawn from the holy pool defended by the Isliid Empire, under the auspices of the Emperor and to which only strictly authorized knights have access. If the waters in the spring lower, it is an omen of ill portent. Also called “natural holy water”, a single sip can energize the use, making it much sought after by all knights everywhere.`
    },
    { //Fenrir Gauntlets - Complete
        id: 102101,
        name: `Fenrir Gauntlets`,
        location: `Limited Event - Chasing my Friend's Illusion (Roy)`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102100.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102101.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102100.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102101.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 120
            },
        skillset:  
            {
                skill: `30s CT - 	Negates 600 DMG Dealt to all Allies' for 8s (only once). Reduces all Allies' Physical DMG Taken by 25% for 8s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Reduces DMG Taken by 10% when near-death.`
            },
        lore: `Gauntlets used by Roy. Immediately after his appointment as Caption to the 3rd, he distinguished himself in fighting with some monsters and earned these along with the name "Silver Wolf". They are engraved with Fenrir, the symbol of his unit. Easy to use and very though, the special protection cast over them is activated when the wearer needs it the most.`
    },
    { //Fenrir Crest - Complete
        id: 102111,
        name: `Fenrir Crest`,
        location: `Limited Event - Chasing my Friend's Illusion (Roy)`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102110.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102111.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102110.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102111.png`
            },
        stats:
            {
                hp: 500,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - Heals all Allies' HP by 1000. If User is Water Elemental then Healing x 1.5.`,
                break: 0
            },
        passive: 
            {
                ability1: `Reduces DMG Taken by 10% when near-death.`
            },
        lore: `A crest worn by the Isliid Empire 3rd Division "Fenrir" Corps. It serves as proof of their appointment and also a reminder of belonging to a proud history of knights. Blessed with healing magic the crest always shines silver, perhaps a sign of the proud wolf watching over these knights. Its real power is revealed when the life of the wearer is in danger.`
    },
    { //Relic 'Volkans' - Complete
        id: 102121,
        name: `Relic 'Volkans'`,
        location: `Rotating Giant Boss - Trials of the Flame God (Ifrit)`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102120.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102121.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102120.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102121.png`
            },
        stats:
            {
                hp: 0,
                atk: 60,
                def: 0
            },
        skillset:  
            {
                skill: `90s CT - 8000% Fire DMG (Physical). Burns all allies and enemies.`,
                break: 2800
            },
        passive: 
            {
                ability1: `Reduces own Skill CT by 20% when Burning.`
            },
        lore: `Gauntlets worn by the fire god Ifrit in manifested form. He used them to defend enemy attacks and also to seal in some of his own heat. At full power he was capable of scorching a battlefield with a single hand, but these gauntlets let him unleash less intense fire magic. Suited to the violence of the fire god, they can crush any enemy with a single blow.`
    },
    { //Relic 'Logislett' - Complete
        id: 102131,
        name: `Relic 'Logislett'`,
        location: `Rotating Giant Boss - Trials of the Flame God (Ifrit)`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102130.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102131.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102130.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102131.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 250
            },
        skillset:  
            {
                skill: `45s CT - Reduces all Allies' DMG Taken by 25% for 12s. Adds Burning Effect to all Allies' Attack for 12s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increases own Skill CT Speed by 20% when Burning.`
            },
        lore: `A sealing relic that the fire god Ifrit bound around himself to restrain his fire powers. Where normally he would burn everything in sight, with this he could meet and speak with people. While these chains bind all physical, magical and mental powers, they can also repel any incoming force and so many invincible armor if wrapped around the body.`
    },
    { //Relic 'Puneumath' - Complete
        id: 102141,
        name: `Relic 'Puneumath'`,
        location: `Giant Boss - The Green Strikes Back (Elpneumas)`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102140.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102141.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102140.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102141.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 250
            },
        skillset:  
            {
                skill: `35s CT - Increases all Allies' Healing Amount by 50% for 7s (except lifesteal). Unlikely to get knocked back.`,
                break: 0
            },
        passive: 
            {
                ability1: `Regenerates own HP by 4.`
            },
        lore: `The verdant dragon Elpuneumath grew trees on his body, and this robe is created from the threads of silkworms living in such forests. Light as a breeze and tough as steel, it also allows the wearer to waether all environmental conditions. Furthermore, they even hear the gentle voice of nature, making it almost impossible for them to fall in battle.`
    },
    { //Relic 'Benomopis' - Missing Lore
        id: 102151,
        name: `Relic 'Benomopis'`,
        location: `Giant Boss - The Green Strikes Back (Elpneumas)`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 3,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102150.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102151.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102150.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102151.png`
            },
        stats:
            {
                hp: 0,
                atk: 30,
                def: 0
            },
        skillset:  
            {
                skill: `35s CT - 2200% Earth DMG (Physical). Chance of Poisoning enemy.`,
                break: 1200
            },
        passive: 
            {
                ability1: `HP Auto-recovery by 2.`
            },
        lore: `Missing Lore.`
    },
    { //Custom Order 'Toc Blanche' - Complete
        id: 102161,
        name: `Custom Order 'Toc Blanche'`,
        location: `Limited Event - The Master Chef's Test (Sophie)`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102160.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102161.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102160.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102161.png`
            },
        stats:
            {
                hp: 120,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - Heals all Allies' HP by 100/s for 7s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increases Skill CT Speed by 5%.`
            },
        lore: `A special order cook's hat that only the head chef at the Firecat's Den, Sophie, is allowed to wear. Her father wore a more orthodox design, but when Sophie's turn came she really glitzed it up. The reasons are unknown, but her fashion sense clearly shines through. Compliment it she'll say "Praise my cooking, not the hat," but she looks happy about it too.`
    },
    { //Beloved 'Flame Meat Knife' - Missing Lore
        id: 102171,
        name: `Beloved 'Flame Meat Knife'`,
        location: `Limited Event - The Master Chef's Test (Sophie)`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 3,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102170.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102171.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102170.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102171.png`
            },
        stats:
            {
                hp: 0,
                atk: 60,
                def: 0
            },
        skillset:  
            {
                skill: `35s CT - 2200% Fire DMG (Physical).`,
                break: 800
            },
        passive: 
            {
                ability1: `DMG to Beasts 10% UP.`
            },
        lore: `Missing Lore.`
    },
    { //Relic 'Eraser Bit' - Complete
        id: 102181,
        name: `Relic 'Eraser Bit'`,
        location: `Giant Boss - Fortress of the Machine God (XTF: Eraser)`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102180.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102181.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102180.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102181.png`
            },
        stats:
            {
                hp: 0,
                atk: 60,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 4500% Non-Elemental DMG (Magic). Reduces Enemy's Paralyze Resistance by 30% for 8s.`,
                break: 1500
            },
        passive: 
            {
                ability1: `Increases DMG Dealt to Beasts by 10%.`
            },
        lore: `Once a combination of three relics, this machine is now capable of operating alone. It tails the user, identifies threats and attacks with lasers so powerful they may even pose a threat to the user. The reduced output setting is said to offer the best (safest) performance, spinning around the user while bathing enemies an endless stream of death.`
    },
    { //Relic 'Machina Core' - Complete
        id: 102191,
        name: `Relic 'Machina Core'`,
        location: `Giant Boss - Fortress of the Machine God (XTF: Eraser)`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102190.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102191.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102190.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102191.png`
            },
        stats:
            {
                hp: 125,
                atk: 62,
                def: 62
            },
        skillset:  
            {
                skill: `5s CT - ATK Mode: Increases all Allies' ATK by 25%. Break Mode: Increases all Allies' Break by 25%.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increases DMG to Beasts by 10%`
            },
        lore: `A relic that powers self defense and combat systems created by machines. A perfect sphere with no joins or welds, its composition is a complete mystery. It comes as close as possible to the human dream of an eternal engine, being able to create matter from zero and never stopping. It can even attack on its own, unleashing a chain of crater-making explosions.`
    },
    { //Burn Armor 'Noble Bamint' - Complete
        id: 102301,
        name: `Burn Armor 'Noble Bamint'`,
        location: `Limited Event - As a Lady (Mira)`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102300.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102301.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102300.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102301.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `70s CT - 7000% Fire DMG (Magic). 70% chance of Burning Enemy.`,
                break: 1000
            },
        passive: 
            {
                ability1: `Increases DMG to Earth Elemental Units by 5%.`
            },
        lore: `Armor made for Mira, third princess of the Principality of Bamint. Even fighting is part of her duties, and this armor is made to reflect that, including wear during ceremonies and holy rites. Made from tough materials and blessed with Mira's most compatible element, fire, it is a fine piece of work intended to ensure she always returns from battle alive.`
    },
    { //Beautiful Robe 'Grace Barmint' - Complete
        id: 102311,
        name: `Beautiful Robe 'Grace Barmint'`,
        location: `Limited Event - As a Lady (Mira)`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102310.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102311.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102310.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102311.png`
            },
        stats:
            {
                hp: 120,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `300s CT - Revives all Allies at 15% HP. If User is Fire Elemental then Healing x 2.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increases Own Status Ailment (not Faint) Time Reduction and Evasion Rate by 10%.`
            },
        lore: `A robe made for Mira the third princess of Bamint. It has been designed with the intention of reminding her that she is still a princess and therefore has the durability of blocking sword attacks while still preventing it from making clattering sounds while walking. it is light, has good ventilation, and easy to walk in, giving it an excellent comfortability as well as preventing fatigue or stuffiness.`
    },
    { //Holy Shield of Wings - Complete
        id: 102321,
        name: `Holy Shield of Wings`,
        location: `Rotating Giant Boss - Battle against the Silent Holy Knight (Palamicia)`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102320.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102321.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102320.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102321.png`
            },
        stats:
            {
                hp: 500,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `30s CT - Continuously Increases Target's HP Healing amount by 20% (Max 100%) (except lifesteal).`,
                break: 0
            },
        passive: 
            {
                ability1: `Reduces Arts DMG taken by 10% (only once).`
            },
        lore: `A shield shaped like the wings of the goddess Palamacia. Known as the "white swan of the battlefield", she would first raise this shield high to boost the morale of allies and call for the surrender of the enemy. Using twin blades, she did not even need a shield, and yet this one helped her achieve many victories for the gods entirely free from bloodshed.`
    },
    { //Frozen Twin Swords - Complete
        id: 102331,
        name: `Frozen Twin Swords`,
        location: `Rotating Giant Boss - Battle against the Silent Holy Knight (Palamicia)`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 3,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102330.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102331.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102330.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102331.png`
            },
        stats:
            {
                hp: 0,
                atk: 30,
                def: 0
            },
        skillset:  
            {
                skill: `10s CT - 1000% Water DMG (Magic).`,
                break: 350
            },
        passive: 
            {
                ability1: `Reduces Arts DMG Taken by 10% (only once).`
            },
        lore: `The sword of Palamacia, the greatest swordswomen of the gods. It has a clear purity, equal to her own as she danced through battle like a graceful swan. The blade always shines, never stained by blood regardless of the numbers it kills, and its attacks feel like nothing more than "ice across the skin", leaving the departed unaware they have even been cut.`
    },
    { //Evil Spear 'Berserk' - Missing Lore
        id: 102341,
        name: `Evil Spear 'Berserk'`,
        location: `Rotating Event - The Demon's Pride (Grad)`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102340.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102341.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102340.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102341.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `70s CT - 5000% Dark DMG (Physical). 70% chance of Blinding Enemy.`,
                break: 1500
            },
        passive: 
            {
                ability1: `CRI DMG 20% UP.`
            },
        lore: `Missing Lore.`
    },
    { //Evil Helmet 'Nightmare' - Complete
        id: 102351,
        name: `Evil Helmet 'Nightmare'`,
        location: `Rotating Event - The Demon's Pride (Grad)`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 3,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102350.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102351.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102350.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102351.png`
            },
        stats:
            {
                hp: 0,
                atk: 60,
                def: 0
            },
        skillset:  
            {
                skill: `35s CT - 1900% Dark DMG (Magic). Heals Own HP by 120 for each Enemy Unit hit`,
                break: 900
            },
        passive: 
            {
                ability1: `Increases Critical DMG by 10%.`
            },
        lore: `A magical helm worn by the demon Grad, an elite member of the Sin Emperor's forces during the Ancient War. A blue helmeted berserker who only sought battle and blood, he was a true nightmare, which is how the helmet got its name. Until it was finally sealed away, it was never touched by a single blade; a nightmare forged by the blood and screams of battle.`
    },
    { //Magic Sword 'Geshpenst' - Complete
        id: 102361,
        name: `Magic Sword 'Geshpenst'`,
        location: `Limited Event - Revenge of the Cursed Armor (Ragsherum)`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102360.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102361.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102360.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102361.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `55s CT - 6000% Dark DMG (Physical).`,
                break: 1000
            },
        passive: 
            {
                ability1: `All allies' ATK 15% UP (Only once).`
            },
        lore: `The sword used by Ragsherum, leader of the demons who attacked every region after reviving in Gormarey. He was skilled at using the pulse of his magic and soul to influence objects, and could make this sword fly back to his hand from anywhere on the battlefield. Unaware of what was happening, people soon came to believe this sword was possessed by a ghost.`
    },
    { //Magic Fists 'Hellshaft' - Complete
        id: 102371,
        name: `Magic Fists 'Hellshaft'`,
        location: `Limited Event - Revenge of the Cursed Armor (Ragsherum)`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102370.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102371.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102370.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102371.png`
            },
        stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
        skillset:  
            {
                skill: `40s CT - Increases Target's Critical Rate by 100% for 9s. If User is Dark Elemental then increases Target's Arts Gauge by 30.`,
                break: 0
            },
        passive: 
            {
                ability1: `Reduces Max HP by 30% and increases ATK by 30% (only once).`
            },
        lore: `Gauntlets worn by Ragsherum. His goal was to revive every sealed demon and take over the world, and each time he swore to achieve this his fist bunched in these very gloves. Defeated before he could do so, it was only a matter of time before these gauntlets, exposed for so long to such powerful intent, combined with other relics to achieve those same goals.`
    },
    { //Cursed Armor 'Ragsherum' - Complete
        id: 102381,
        name: `Cursed Armor 'Ragsherum'`,
        location: `Limited Event - Revenge of the Cursed Armor (Ragsherum)`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102380.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102381.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102380.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102381.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `95s CT - 9500% Dark DMG (Magic).`,
                break: 700
            },
        passive: 
            {
                ability1: `Increases all Dark Elemental Allies' DMG by 5%.`
            },
        lore: `Armor that became the medium for Ragsherum's vengeful spirit to materialize. After being exposed to the pulse of his magic and soul for so long, the armor became capable of containing his vast energy. It also provides an outer shell and gives his ghost form as Ragsherum Phantom. He has learned much since his death, making him an even more fearsome foe.`
    },
    { //??? - Unknown
        id: 102391,
        name: `???`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 1,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102390.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102391.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102390.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102391.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: ``,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: ``
    },
    { //Relic 'Punish Fireblade' - Complete
        id: 102402,
        name: `Relic 'Punish Fireblade'`,
        location: `Limited Event - Divine Spirit of Rememberance (Marzex)`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102401.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102402.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102401.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102402.png`
            },
        stats:
            {
                hp: 150,
                atk: 150,
                def: 150
            },
        skillset:  
            {
                skill: `50s CT - 3000% Fire DMG (Physical). Reduces Enemy's Fire Resistance by 30% for 15s.`,
                break: 500
            },
        passive: 
            {
                ability1: `Increases ATK gradually for 120s to a MAX of 30% (only once).`
            },
        lore: `Forbidden fire used by the God of the Heavens Marzex, leader of one of the sealed Ten Divine Forces. Representing the fiercest side of this punishing god, this blade is capable of burning even souls to nothing, meaning complete eradication from the world. Gentle enough to aid a lower race such as the humans, when angered the heavens themselves would tremble.`
    },
    { //Relic 'Repent Lance' - Complete
        id: 102411,
        name: `Relic 'Repent Lance'`,
        location: `Limited Event - Divine Spirit of Rememberance (Marzex)`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102410.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102411.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102410.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102411.png`
            },
        stats:
            {
                hp: 150,
                atk: 150,
                def: 150
            },
        skillset:  
            {
                skill: `50s CT - 3000% Water DMG (Magic). Reduces Enemy's Water Resistance by 30% for 15s.`,
                break: 500
            },
        passive: 
            {
                ability1: `Increases DEF gradually for 120s to a MAX of 30% (only once).`
            },
        lore: `A divine lance formed from the benevolence of God of the Heavens Marzex, leader of one of the legendary Ten Divine Forces during the Ancient War. He swing it to make foolish sinners repent, ending their current lives but purifying their souls and allowing them to be reborn. Those souls that would not repent, however, vanished into the ether for eternity.`
    },
    { //Relic 'Fallen Bow' - Complete
        id: 102421,
        name: `Relic 'Fallen Bow'`,
        location: `Limited Event - Divine Spirit of Rememberance (Marzex)`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102420.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102421.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102420.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102421.png`
            },
        stats:
            {
                hp: 150,
                atk: 150,
                def: 150
            },
        skillset:  
            {
                skill: `50s CT - 3000% Earth DMG (Magic). Reduces Enemy's Earth Resistance by 30% for 15s.`,
                break: 500
            },
        passive: 
            {
                ability1: `Increases MAX HP gradually for 120s to a MAX of 30% (only once).`
            },
        lore: `The battle bow of God of the Heavens Marzex, leader of one of the Ten Divine Forces that fought alongside the humans against the demons. Uses his powers as the god of judgement to destroy evil. As demons are personified "sin" for him, it offered incredible demon killing power. The bolts it fired were more like modern cannon fire than arrows from a bow.`
    },
    { //Alchemy Tool 'Hastia' - Complete
        id: 102451,
        name: `Alchemy Tool 'Hastia'`,
        location: `Rotating Event - Legendary Blacksmith (Gilliam)`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102450.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102451.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102450.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102451.png`
            },
        stats:
            {
                hp: 500,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - Heals all Allies' HP by 400. If User is Earth Elemental then Healing x 1.5.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Goggles used by the blacksmith Gilliam as he works. He created them in his youth with the help of an alchemist. Only needing to protect a small area, they offer great performance from a small volume of materials. He named them and his hammer for fire gods from overseas, but a chef friend eventually told him "That one's more like the god of the cook pot".`
    },
    { //Alchemy Tool 'Shurain' - Complete
        id: 102461,
        name: `Alchemy Tool 'Shurain'`,
        location: `Rotating Event - Legendary Blacksmith (Gilliam)`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102460.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102461.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102460.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102461.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 120
            },
        skillset:  
            {
                skill: `70s CT - Reduces all Allies' DMG Taken by 20% for 8s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Boots that journeyed with Giliam around the world in his impassioned youth. Traveling as he did with many heavy tools and materials he burned through footwear pretty fast. A cobbler finally created these for him in thanks for his work adjusting his cobbling tools. Since then a new pair arrive from his friend at regular intervals.`
    },
    { //Gem of Ragsherum's Grudge - Missing Lore
        id: 102471,
        name: `Gem of Ragsherum's Grudge`,
        location: `Limited Event - Despair of the Ruthless Demon Armor (Ragsherum)`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102470.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102471.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102470.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102471.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `120s CT - 7000% Non-Elemental DMG (Physical). Summon Gem Blessing: Increases all allies' Arts DMG by 50% for 20s (Only once).`,
                break: 1200
            },
        passive: 
            {
                ability1: `Summon Gem Blessing Lv5: The highest Level effect of the Summon Gem Blessing is effective.`
            },
        lore: `Missing Lore.`
    },
    { //Magic Sword 'Geshpenst Type-0' - Complete
        id: 102481,
        name: `Magic Sword 'Geshpenst Type-0'`,
        location: `Limited Event - Despair of the Ruthless Demon Armor (Ragsherum)`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102480.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102481.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102480.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102481.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `90s CT - Increases all Allies' Arts Gauge by 50.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increases DMG to Enemy by 15% during the first 30 seconds of a quest (only once).`
            },
        lore: `The twin sword of Magic Sword 'Geshpenst' which Ragsherum, the divisional commander of the six great Demon Generals, used. This magical sword moved as if it had its own will, thus he left this at battlefields turning it into chaos. It is said that he only used this sword before its twin returned so he could avoid being unarmed. Thus, it hardly was used in battles but those who know the true value of this sword will never speak about it.`
    },
    { //Divine Armor Olgrio - Complete
        id: 102491,
        name: `Divine Armor Olgrio`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102490.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102491.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102490.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102491.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `30s CT - Reduces all Allies' DMG Taken by 10% and Light Elemental DMG Taken by 20% for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Armor worn long ago by the divine blade Zexia. Serving the gods was her greatest joy, and she wore this armor at all times and worked incredibly hard so as to never purchase their ire. However, when she finally disobeyed divine orders and spared a target, she also cast off this armor in order to draw a clear line from the past.`
    },
    { //Guardian Bow of Providence - Complete
        id: 102501,
        name: `Guardian Bow of Providence`,
        location: `Main Story [Chapter 7 > The Dark Lord's Lair > [Challenge] Melia's Past] Secret Equip`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102500.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102501.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102500.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102501.png`
            },
        stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
        skillset:  
            {
                skill: `50s CT - 3000% Earth DMG (Physical). Increases all Allies' Evasion Rate by 30% for 12s.`,
                break: 700
            },
        passive: 
            {
                ability1: `Regenerates HP by 8/s.`
            },
        lore: `The bow used by the bow master Ashe Toto to end the "Thaumazein Winter". He unleashed a shot of fury at the "Disaster Witch" who had twisted the providence of nature and frozen the plains. The awakening of his own abilities aided in severing such powerful magic, but it was also the bow itself transforming to aid him that allowed this miracle to take place.`
    },
    { //Eternal Flower Brace - Complete
        id: 102511,
        name: `Eternal Flower Brace`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102510.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102511.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102510.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102511.png`
            },
        stats:
            {
                hp: 120,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - Heal all Allies' HP by 100 for 5s. If user is Earth Elemental then Healing x 2.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A brace worn by bow master Ashe Toto, defender of providence. All flowers must eventually wither, but this is purely a function of their place in the greater framework of the cycle of the seasons. This brace reflects this everlasting circle of blooming and fading, bringing countless blessings to the wearer like the coming of new seasons to their heart.`
    },
    { //Transcythe Ilzion - Complete
        id: 102521,
        name: `Transcythe Ilzion`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102520.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102521.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102520.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102521.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 1800% Light DMG (Physical). 50% chance of Paralyzing Enemy.`,
                break: 800
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A weapon made by the legendary blacksmith Rhodia. A master smith, she was also skilled in a dragon martial art, but she rarely used it after taking up her hammer. In her later years, however, after her only pupil has completed their training, she headed to her final battle. Her greatest work, the Transcythe Ilzion, is sharp enough to enthrall even those it cuts.`
    },
    { //Iris' Summer Parasol - Complete
        id: 102571,
        name: `Iris' Summer Parasol`,
        location: `Limited Equip Summon: Summer Banner`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102570.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102571.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102570.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102571.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 3750% Light DMG (Magic).`,
                break: 800
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A parasol used by Iris on vacation. Her first time at the beach, she knew nothing of the dangers of the sun. Rushing out without protection, the others stopped her and gave her this. Quickly taking to it, she had it close the rest of the time, running along the beach and dashing into the waves. It then shaded her the rest of the time.`
    },
    { //Iris' Bikini - Complete
        id: 102581,
        name: `Iris' Bikini`,
        location: `Limited Equip Summon: Summer Banner`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102580.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102581.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102580.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102581.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `40s CT - Negates 1000 DMG Dealt to all Allies. Reduces all Allies' DMG Taken by 10% for 8s. If User is Light Elemental then Reduces all Allies' Dark Elemental DMG Taken by 30% for 8s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The swimwear purchased by the young girl Iris for her first dip in the sea. She was aided by Mira of Bamint, Sophie of the Firecat's Den, and the Priestess Liese, while the unthinking adventurer Rayas got a slap when he tried to come along. On the day she finally wore it, however, she was the one embarrassing Rayas by continually asking how she looked.`
    },
    { //Iris' Beach Sandals - Complete
        id: 102591,
        name: `Iris' Beach Sandals`,
        location: `Limited Equip Summon: Summer Banner`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 3,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102590.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102591.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102590.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102591.png`
            },
        stats:
            {
                hp: 60,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - Heals all Allies' HP by 440.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Sandals gifted to the young girl Iris by Mira of Bamint, to allow her to play freely on the beach. To start with she didn't even want to get them dirty, but Mira just watched with a smile, knowing she would wear them as soon as she touched the hot sand. After arriving at the beach, however, the only thing Mira learned was how hardy Iris' feet are.`
    },
    { //Liese' Naval Hat - Complete
        id: 102601,
        name: `Liese' Naval Hat`,
        location: `Limited Equip Summon: Summer Banner`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102600.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102601.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102600.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102601.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 3050% Light DMG (Magic).`,
                break: 1500
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A hat used to defend the head of Priestess Liese of Reigrad from the summer sun. Holy priestess or not, as a 13 year old girl she hasn't given up on having some beach fun, and her god would surely not deny her some innocent frolicking. Her safety is the highest priority, however, and she can only enjoy the height of summer once completely protected.`
    },
    { //Liese' Naval Swimsuit - Complete
        id: 102611,
        name: `Liese' Naval Swimsuit`,
        location: `Limited Equip Summon: Summer Banner`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102610.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102611.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102610.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102611.png`
            },
        stats:
            {
                hp: 120,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - Heal all Allies' HP by 500. If User is Light Elemental then Healing x 1.5.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A swimsuit selected by the 13 year old Priestess Liese, in the hope of a memorable summer. Normally having to wear heavy robes, putting this on marked both the start of a brief vacation and her chance to spend some time as a normal girl. Even for her first visit to the beach, she wanted something as cute as possible, and gave her all to this selection.`
    },
    { //Liese' Lifesaver - Complete
        id: 102621,
        name: `Liese' Lifesaver`,
        location: `Limited Equip Summon: Summer Banner`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 3,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102620.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102621.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102620.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102621.png`
            },
        stats:
            {
                hp: 30,
                atk: 15,
                def: 15
            },
        skillset:  
            {
                skill: `30s CT - Increases Target's Arts Gauge by 20.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A float used by Priestess Liese of Reigrad when she made her debut on the beach. Plucking up all her courage, this was her first time in the sea. Without even knowing if she could swim, this rubber ring put her 13 year old heart at ease. She had to enjoy this chance, no matter what. A full day of play, and then ice cream for dessert! Her summer starts here!`
    },
    { //Floating Mikyuon - Complete
        id: 102631,
        name: `Floating Mikyuon`,
        location: `Limited Equip Summon: Summer Banner`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 3,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102630.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102631.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102630.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102631.png`
            },
        stats:
            {
                hp: 0,
                atk: 60,
                def: 0
            },
        skillset:  
            {
                skill: `20s CT - 1000% Water DMG (Magic).`,
                break: 300
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The most popular beach item in Reigrad this summer. Gentle or not, Mikyuon is still a monster. For all the regular people who simply lack the strength to hug the real thing, this beauty provides their chance. It's even 1/1 scale! Hug it, ride it, enjoy it however you like. Just don't mistake the real thing wandering onto the beach for one of these.`
    },
    { //Liese' Secret Adventure - Complete
        id: 102641,
        name: `Liese' Secret Adventure`,
        location: `Limited Equip Summon: Summer Banner`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102640.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102641.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102640.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102641.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 7500% Light DMG (Magic). 80% chance of inflicting Paralysis to enemy.`,
                break: 600
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The highly respected Priestess Liese of Reigrad snuck this swimwear into her luggage. Although she has position and ability, she's still so young. If only she could grow up faster! Unable to wear it on front of others, she just looks at herself in the mirror. Even an all seeing god surely doesn't watch girls changing. A summer secret, then, just for her.`
    },
    { //Mira's Disguise Glasses - Complete
        id: 102651,
        name: `Mira's Disguise Glasses`,
        location: `Limited Equip Summon: Summer Banner`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102650.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102651.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102650.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102651.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 1500% Fire DMG (Magic). 70% chance of Burning Enemy.`,
                break: 800
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The eyewear used by Mira, third princess of the Principality of Bamint, to both protect her eyes from harmful rays and disguise her prominent features. In her high-ranking position, being recognized offers zero advantages, making these glasses vital to enjoying a true vacation. Of course, they are unable to hide her overwhelming aura.`
    },
    { //Mira's Halter Neck - Complete
        id: 102661,
        name: `Mira's Halter Neck`,
        location: `Limited Equip Summon: Summer Banner`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102660.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102661.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102660.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102661.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 5300% Fire DMG (Physical).`,
                break: 3500
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A swimsuit worn by Mira, the Rose Garden knight, after casting off her heavy armor. Highlighting her figure in provocative ways, it catches the gaze of men all along the beach and would make her older sister, the first princess, faint completely. She isn't wearing it for anyone in particular, but she may be caught casting glances at men she once adventured with.`
    },
    { //Mira's Summer Float - Complete
        id: 102671,
        name: `Mira's Summer Float`,
        location: `Limited Equip Summon: Summer Banner`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102670.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102671.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102670.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102671.png`
            },
        stats:
            {
                hp: 120,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - Heals Target's HP by 1500. If User is Fire Elemental then Healing x 1.5.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The third princess of the Principality of Bamint cannot be seen having fun at the beach. Use of an order-made float is tradition. On a secret vacation, however, there is no need to play the noble, and no need to hide her assets. This float design shows no desire to remain hidden, but she loves it so much as to push past the concerns of her friends.`
    },
    { //Mira's Summer Sandals - Complete
        id: 102681,
        name: `Mira's Summer Sandals`,
        location: `Limited Equip Summon: Summer Banner`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 3,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102680.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102681.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102680.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102681.png`
            },
        stats:
            {
                hp: 30,
                atk: 15,
                def: 15
            },
        skillset:  
            {
                skill: `30s CT - Increases all Allies' DMG Inflicted by 10% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Sandals to protect the feet of Mira, third princess of the Principality of Bamint, from sun-warmed sand. Not really suited to walking on the beach, they are designed only to accentuate her legs. She definitely won't be playing in the waves with a special someone, certainly not! Meanwhile, her male friends are left hoping she doesn't fall and break something.`
    },
    { //Rayas' Straw Hat - Complete
        id: 102691,
        name: `Rayas' Straw Hat`,
        location: `Limited Equip Summon: Summer Banner`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102690.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102691.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102690.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102691.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 120
            },
        skillset:  
            {
                skill: `5s CT - Negates 150 Dark Elemental DMG Dealt to all Allies' for 5s (only once).`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A straw hat that the adventurer Rayas purchased to wear at the beach. "This is the only thing for summer!" he laughed with Iris, while Ganan of the Adventurer's Guild and other adults around him looked on somewhat bemused (which went completely unnoticed by Rayas). Upon reaching the beach, the way he used the hat as a makeshift throwing disk only bemused them further.`
    },
    { //Rayas' Summer Pants - Complete
        id: 102701,
        name: `Rayas' Summer Pants`,
        location: `Limited Equip Summon: Summer Banner`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102700.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102701.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102700.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102701.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 6250% Light DMG (Physical). Reduces enemy's Light resistance by 30% for 15s.`,
                break: 1200
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A swimsuit that the adventurer Rayas prepared for swimming in the sea. While he carefully selected both the design and the colors, Ganan of the Adventurer's Guild and other adults around him consider it "supremely childish". Mira of Bamint warned him that "Those ties better never come loose!", while Iris simply said "It suits Rayas perfectly."`
    },
    { //Rayas' Beach Sandals - Complete
        id: 102711,
        name: `Rayas' Beach Sandals`,
        location: `Limited Equip Summon: Summer Banner`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 3,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102710.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102711.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102710.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102711.png`
            },
        stats:
            {
                hp: 60,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - Heals Target's HP by 1200.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The sandals selected by the adventurer Rayas in order to run along the summer sands at full speed. They easily slip on and off, even allowing for such games as launching them into the sea. Of course, in respect to the looks he would get from the adults around him, he would never do that. He still looks like a child just running around wildly, anyway.`
    },
    { //Roy's Swimwear - Complete
        id: 102721,
        name: `Roy's Swimwear`,
        location: `Limited Equip Summon: Summer Banner`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102720.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102721.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102720.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102721.png`
            },
        stats:
            {
                hp: 500,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `70s CT - Heals all Allies' HP by 2000.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The swimwear worn by Roy, Captain of the Isliid Empire Fenrir Knights. Still partly on duty even at the beach, he chose this functional design in case any lifesaving was required of him. His friend Rayas was jealous of its striking design and motif showing off his pride as a knight, while Roy had to stifle laughter when he saw the outfit Rayas selected.`
    },
    { //Roy's Blue Water Dragon - Complete
        id: 102731,
        name: `Roy's Blue Water Dragon`,
        location: `Limited Equip Summon: Summer Banner`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102730.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102731.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102730.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102731.png`
            },
        stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
        skillset:  
            {
                skill: `30s CT - Increases Target's Arts Gauge by 30.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A beach ball selected by Roy, Captain of the Isliid Empire Fenrir knights, for training at the beach. What may look like a game is actually serious exercise once adults get involved, with the serves and spikes launched by trained knights turning the beach into a battlefield. Yet this is still intended as a vacation; the losers treat the winners to ice cream.`
    },
    { //Ganan's Burning Soul - Complete
        id: 102741,
        name: `Ganan's Burning Soul`,
        location: `Limited Equip Summon: Summer Banner`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102740.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102741.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102740.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102741.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 5850% Fire DMG (Physical). Increases all Allies' DEF by 20% for 20s.`,
                break: 1000
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Ganan, Guild Master of the Reigrad Branch of the Adventurer's Guild, getting serious about summer wear. He shows off his forge-sculpted body to an excessive degree, overwhelming all on the beach. As a result, many new members throng to join the guild during the summer months, making this unlikely seaside promotion more effective than one might initially think.`
    },
    { //Ganan's Wooden Sandals - Complete
        id: 102751,
        name: `Ganan's Wooden Sandals`,
        location: `Limited Equip Summon: Summer Banner`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 3,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102750.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102751.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102750.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102751.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 60
            },
        skillset:  
            {
                skill: `30s CT - Reduces all Allies' DMG Taken by 15% and Fire Elemental DMG Taken by 30% for 8s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Wooden sandals worn by Guild Master Ganan when he trains new summertime recruits. His summer training school is known for being exceptionally tough, but those who make t through end up with a physique of steel. This also attracts many keeping to get in shape for some summer fun. Many say that, just like the sun, summer is when Ganan shines the brightest.`
    },
    { //Est's Vacation - Complete
        id: 102761,
        name: `Est's Vacation`,
        location: `Limited Equip Summon: Summer Banner`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102760.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102761.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102760.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102761.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 3750% Water DMG (Physical).`,
                break: 800
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A straw hat used by the Reigrad Arena receptionist Est to protect herself from the sun. She cuts a true figure of a lady as she walks on the beach, holding her hat on head with one hand and with a sparkling smile on her lips. While a gaggle of men follow behind her, those who actually know her can only mutter about such foolish recklessness.`
    },
    { //Est's Mild Fever - Complete
        id: 102771,
        name: `Est's Mild Fever`,
        location: `Limited Equip Summon: Summer Banner`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102770.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102771.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102770.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102771.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 7500% Water DMG (Physical). Increases own DMG by 30% for 5s during Break.`,
                break: 100
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The swimwear selected by Est, the idol of all who visit the Arena. Subtly hiding the curves of her chiseled body, it depicts a calm and elegant presence. Keeping her wraparound skirt in check and walking daintily across the sand, when her father asked her "Why didn't you pick something easier to swim in?" he was rewarded with a nasty body blow.`
    },
    { //Est's Warmth - Complete
        id: 102781,
        name: `Est's Warmth`,
        location: `Limited Equip Summon: Summer Banner`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 3,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102780.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102781.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102780.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102781.png`
            },
        stats:
            {
                hp: 0,
                atk: 60,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 1000% Water DMG (Physical). 30% chance of Freezing Enemy.`,
                break: 600
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Est, the face of the Arena where she works as the receptionist, must remain elegant at all times. This is no different even on the exciting summer beach. Of course, she chooses her shoes to coordinate with her outfit, but she also selects ones that are harder to walk in on purpose. This will stop her wanting to run off into the sunset like she did as a child.`
    },
    { //Melia's Allure - Complete
        id: 102791,
        name: `Melia's Allure`,
        location: `Limited Equip Summon: Summer Banner`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102790.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102791.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102790.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102791.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 3750% Dark DMG (Magic).`,
                break: 800
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The sunglasses used by the high-ranking Magus Empire Captain Melia, supposedly to hide her identity but really just making her stand out all the more. Feared as the "Blue-eyed Black fox", a top-class spy, here she chooses to hide nothing. Add in the third princess of Bamint and they are sure to turn some heads, but maybe that's just what she wants.`
    },
    { //Melia's Bewitchment - Complete
        id: 102801,
        name: `Melia's Bewitchment`,
        location: `Limited Equip Summon: Summer Banner`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102800.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102801.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102800.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102801.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - Increases Arts Gauge by 20 for each Enemy Unit hit.`,
                break: 800
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The swimwear selected by the spy-among-spies, the "Blue-eyed Black Fox" Melia, for this summer. A firm believer in hiding nothing in the ocean, she attracts attention from across the beach and delights in toying with the hearts of men. What she likes more than anything is to mock her male friends after they've been swimming around after her all day.`
    },
    { //Melia's Lust - Complete
        id: 102811,
        name: `Melia's Lust`,
        location: `Limited Equip Summon: Summer Banner`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 3,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102810.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102811.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102810.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102811.png`
            },
        stats:
            {
                hp: 30,
                atk: 15,
                def: 15
            },
        skillset:  
            {
                skill: `30s CT - Increases all Allies' Skill CT Speed by 50% for 6s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Melia, skilled at disguising herself for missions in enemy territory, selected these sandals for the beach using all of her clothing knowledge. With a heel height to accentuate her legs, a sensual design to compliment her swimwear, and even considering how she looks from behind while walking in them, all Rayas had to say was "Melia, you look beautiful!"`
    },
    { //Rem's Glamour - Complete
        id: 102821,
        name: `Rem's Glamour`,
        location: `Limited Equip Summon: Summer Banner`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102820.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102821.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102820.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102821.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `60s CT - 100% Non-Elemental DMG (Magic). Increases Enemies' Weakness Elemental DMG by 20% for 10s.`,
                break: 250
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The swimwear worn by the Reigrad alchemist Rem. Rarely showing her face normally, she caused quite a stir among her friends by suddenly appearing in this. The men could barely speak, the women were shocked at this unexpected rival, and Rayas just happily said "Rem's here!" When Melia who was disappointed that she chose the same color swimwear, asked why she chose this, Rem simply replied "It's just what I ended up making".`
    },
    { //Wind Chime Rolly - Complete
        id: 102831,
        name: `Wind Chime Rolly`,
        location: `Limited Equip Summon: Summer Banner`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 3,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102830.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102831.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102830.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102831.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 60
            },
        skillset:  
            {
                skill: `30s CT - Reduces all Allies' DMG Taken by 5% and Dark Elemental DMG Taken by 10% for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A wind chime shaped like Rolly that helps you forget the heat of the summer. The young girl Iris was having trouble sleeping in the summer heat. Dashing around to try and help her, the adventurer Rayas eventually got the blacksmith Giliam to make these chimes. Iris immediately hung them in her room and slept like a log from that night onward.`
    },
    { //Chicken Water Gun - Complete
        id: 102841,
        name: `Chicken Water Gun`,
        location: `Limited Equip Summon: Summer Banner`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102840.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102841.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102840.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102841.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 2000% Dark DMG (Magic). 30% chance of Blinding Enemy.`,
                break: 500
            },
        passive: 
            {
                ability1: ``
            },
        lore: `This is a popular item among all kids on the beach this summer. Sold using the catchphrase "The Water Pistol Experience", they have incited many raging water battles. Not to be underestimated, they offer water pressure and range far greater than regular toys. When the adventurer Rayas purchased one to test it out, he was soon embroiled in a major water fight.`
    },
    { //Firecat's Passion - Complete
        id: 102851,
        name: `Firecat's Passion`,
        location: `Limited Equip Summon: Summer Banner`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 3,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102850.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102851.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102850.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102851.png`
            },
        stats:
            {
                hp: 0,
                atk: 60,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 1000% Fire DMG (Physical). 30% chance of Burning enemy.`,
                break: 600
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Dining at the seaside is all about hotplate cooking. Sophie, the head chef of the popular Reigrad establishment "The Firecat's Den," knows this all too well. Seeing her sweating in the sun as she fries up some noodles has become a summer tradition, and she always attracts an impressive queue of young suitors. The special mushroom topping comes especially recommended.`
    },
    { //Sophie's Gluttony - Complete
        id: 102861,
        name: `Sophie's Gluttony`,
        location: `Limited Equip Summon: Summer Banner`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102860.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102861.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102860.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102861.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `65s CT - Increases all Allies' Break Power by 50% for 6s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The swimwear worn by head chef Sophie from the Reigrad restaurant called the Firecat's Den. "All I need is to be able to swim in it!" she explained, but was then seen glancing at the swimwear worn by the other girls. Perhaps she also has to take working at her summer job into account? When Rayas said "It suits you perfectly!" he got a spatula in the face.`
    },
    { //Summer Firecat's Den - Complete
        id: 102871,
        name: `Summer Firecat's Den`,
        location: `Limited Equip Summon: Summer Banner`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102870.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102871.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102870.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102871.png`
            },
        stats:
            {
                hp: 120,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `30s CT - Heals all Allies' HP by 300. Heals all Allies' Poison and Burn.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `An apron worn by head Sophie at her summer spinoff restaurant, the Seacat's Den. While she uses it to get into cooking mode, she also secretly wants to use it to protect her swimwear. Her companions were concerned at the impression created by wearing an apron over a swimsuit, but with so many customers she hasn't had much time to think about it.`
    },
    { //Gilliam - Man of the Sea - Complete
        id: 102881,
        name: `Gilliam - Man of the Sea`,
        location: `Limited Equip Summon: Summer Banner`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102880.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102881.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102880.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102881.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 120
            },
        skillset:  
            {
                skill: `30s CT - Negates 400 DMG Dealt to all Allies' for 8s. Reduces all Allies' Earth Elemental DMG Taken by 35% for 8s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The goggles worn by the Reigrad blacksmith Giliam to conquer the seas. Unlike forging goggles, these are designed to be completely waterproof. "The rolling sea! Every man's true love!" After jumping in and starting to swim, he eventually returned at sunset to wax lyrical for far too long about how his goggles never once let in a drop of water.`
    },
    { //Gilliam's Guts - Complete
        id: 102891,
        name: `Gilliam's Guts`,
        location: `Limited Equip Summon: Summer Banner`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102890.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102891.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102890.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102891.png`
            },
        stats:
            {
                hp: 500,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `90s CT - Heals all Allies' HP by 2500. Heals all Allies' Status Ailments except for Faint and Disease.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The tightly fitted loincloth of Reigrad blacksmith Giliam. He stands, arms crossed, on the shore with another strong looking guy at his side. "I'll destroy you this year, Ganan!" "Hahaha, you can't beat me, Giliam!" Their goal? The flag standing on a distant dune. Drinks and food at the Seacat's Den are staked on the outcome of this annual battle!`
    },
    { //Toy Sword 'Charlotte' - Complete
        id: 102901,
        name: `Toy Sword 'Charlotte'`,
        location: `Limited Event - The Fabled Sword's Chosen One (Zeorg)`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102900.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102901.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102900.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102901.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `5s CT - 1700% Light DMG (Physical). 30% chance of reducing enemy's DEF by 10% for 10s.`,
                break: 300
            },
        passive: 
            {
                ability1: `DMG to Demons 20% UP.`
            },
        lore: `The only sword aged general Zeorg will use other than the Ryude Magus. It looks unsuited to such a hero because it was made as a special order based on a toy he gave to his granddaughter, after who it is also named. He claimed it was just for decoration, but gave the blacksmith detailed instructions and ended up with a sword better than most blades.`
    },
    { //Holy Cape 'Hero's Triumph' - Complete
        id: 102911,
        name: `Holy Cape 'Hero's Triumph'`,
        location: `Limited Event - The Fabled Sword's Chosen One (Zeorg)`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102910.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102911.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102910.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102911.png`
            },
        stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
        skillset:  
            {
                skill: `40s CT - Increases all Allies' Evasion Rate by 15% for 8s.`,
                break: 0
            },
        passive: 
            {
                ability1: `DMG to Gods 20% UP.`
            },
        lore: `When the hero Zeorg was young, he received this cape for defeating an evil dragon. Wearing it upon his return to the capital after fighting more monsters or a major battle became one of his duties, bringing reassurance to the hearts of the people, but he never had time to wear it for anything else. It is still known as the trademark of this great hero.`
    },
    { //Ganan's Passion - Missing Lore
        id: 102921,
        name: `Ganan's Passion`,
        location: `Limited Equip Summon: Summer Banner`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102920.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102921.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102920.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102921.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 3000% Fire DMG (Physical). 50% chance of Burning enemy. Reduces enemy's DEF by 10% for 10s.`,
                break: 500
            },
        passive: 
            {
                ability1: `Increases ATK by 10% when Near-Death.`
            },
        lore: `Missing Lore.`
    },
    { //Ryude Magus (Versus) - Complete
        id: 102951,
        name: `Ryude Magus (Versus)`,
        location: `Limited Event - The Fabled Sword's Chosen One (Zeorg)`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102950.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102951.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102950.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102951.png`
            },
        stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
        skillset:  
            {
                skill: `60s CT - 3600% Light DMG (Physical). Increases all allies' Arts gauge by 20.`,
                break: 700
            },
        passive: 
            {
                ability1: `Increases all allies' CRI rate by 20% during Break.`
            },
        lore: `The sword hidden in the shadows of the legend "the Warrior and the Blade" in Raktehelm. When pulling out the sword, Ryude Magus from the stomach of a magical dragon, there was another shining sword. By swallowing strong weapons, this dragon increased its own powers. However, when it swallowed the Ryude Magus, it reacted with its magical powers inside its body and created another sword. This sword has been kept as a secret even up to now...`
    },
    { //Super Shinobi Bee (超必殺忍蜂) - Complete (JP)
        id: 102961,
        name: `超必殺忍蜂`,
        translate: `Super Shinobi Bee`,
        location: `Crossover Equip Summon: King of Fighters Collab`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102960.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102961.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102960.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102961.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `100s CT - 2500% DMG (Physical). Increases all Allies' DMG and Break Power by 20% for 5s.`,
                break: 800
            },
        passive: 
            {
                ability1: `Increase Skill CT Speed and Evasion rate by 5%.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //Phoenix Dance (鳳凰の舞) - Complete (JP)
        id: 102971,
        name: `鳳凰の舞`,
        translate: `Phoenix Dance`,
        location: `Crossover Equip Summon: King of Fighters Collab`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102970.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102971.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102970.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102971.png`
            },
        stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
        skillset:  
            {
                skill: `65s CT - 1000% DMG (Physical). Increases all Allies' DMG by 20% for 8s.`,
                break: 400
            },
        passive: 
            {
                ability1: `Increase Skill CT Speed and Evasion rate by 5%.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //Dragon Flame Dance (龍炎舞) - Complete (JP)
        id: 102981,
        name: `龍炎舞`,
        translate: `Dragon Flame Dance`,
        location: `Crossover Equip Summon: King of Fighters Collab`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 3,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102980.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102981.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102980.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102981.png`
            },
        stats:
            {
                hp: 30,
                atk: 15,
                def: 15
            },
        skillset:  
            {
                skill: `30s CT - 800% DMG (Magic). Increases all Allies' Break Power by 8% for 10s.`,
                break: 300
            },
        passive: 
            {
                ability1: `Increase Skill CT Speed and Evasion rate by 5%.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //Ura Hundred Eight Styles・Ojanagi (裏百八式・大蛇薙) - Complete (JP)
        id: 102991,
        name: `裏百八式・大蛇薙`,
        translate: `Ura Hundred Eight Styles・Ojanagi`,
        location: `Crossover Equip Summon: King of Fighters Collab`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_102990.png`,
                detailmax: `/db/Equipment/Detail/item_detail_102991.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_102990.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_102991.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `100s CT - 4800% DMG (Physical). 80% chance to inflict Burn on enemies.`,
                break: 2000
            },
        passive: 
            {
                ability1: `Increase DEF and CRI rate by 5%.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //900-Style・Nue Picking (九百拾式・鵺摘み) - Complete (JP)
        id: 103001,
        name: `九百拾式・鵺摘み`,
        translate: `900-Style・Nue Picking`,
        location: `Crossover Equip Summon: King of Fighters Collab`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103000.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103001.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103000.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103001.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 2000% DMG (Physical). 50% chance to inflict Burn on enemies.`,
                break: 1300
            },
        passive: 
            {
                ability1: `Increase DEF and CRI rate by 5%.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //104-Style・Rough Biting (百拾四式・荒咬み) - Complete (JP)
        id: 103011,
        name: `百拾四式・荒咬み`,
        translate: `104-Style・Rough Biting`,
        location: `Crossover Equip Summon: King of Fighters Collab`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 3,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103010.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103011.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103010.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103011.png`
            },
        stats:
            {
                hp: 0,
                atk: 60,
                def: 0
            },
        skillset:  
            {
                skill: `30s CT - 800% DMG (Physical). 30% chance to inflict Burn on enemies.`,
                break: 800
            },
        passive: 
            {
                ability1: `Increase DEF and CRI rate by 5%.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //1,000,200,110 Girls (禁千弐百拾壱式・八稚女) - Complete (JP)
        id: 103021,
        name: `禁千弐百拾壱式・八稚女`,
        translate: `1,000,200,110 Girls`,
        location: `Crossover Equip Summon: King of Fighters Collab`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103020.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103021.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103020.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103021.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `100s CT - 6300% DMG (Magic). 60% chance to inflict Blind on enemies.`,
                break: 600
            },
        passive: 
            {
                ability1: `Increase ATK and CRI DMG by 5%.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //Hyakushiki・Oniyaki (百式・鬼焼き) - Complete (JP)
        id: 103031,
        name: `百式・鬼焼き`,
        translate: `Hyakushiki・Oniyaki`,
        location: `Crossover Equip Summon: King of Fighters Collab`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103030.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103031.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103030.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103031.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 2500% DMG (Magic). 40% chance to inflict Blind on enemies.`,
                break: 500
            },
        passive: 
            {
                ability1: `Increase ATK and CRI DMG by 5%.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //Type 108・Yamiharai (百八式・闇払い) - Complete (JP)
        id: 103041,
        name: `百八式・闇払い`,
        translate: `Type 108・Yamiharai`,
        location: `Crossover Equip Summon: King of Fighters Collab`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 3,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103040.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103041.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103040.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103041.png`
            },
        stats:
            {
                hp: 0,
                atk: 60,
                def: 0
            },
        skillset:
            {
                skill: `30s CT - 1500% DMG (Magic). 20% chance to inflict Blind on enemies.`,
                break: 300
            },
        passive: 
            {
                ability1: `Increase ATK and CRI DMG by 5%.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //Grad's Devil Summer - Complete
        id: 103051,
        name: `Grad's Devil Summer`,
        location: `Limited Equip Summon: Summer Banner`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103050.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103051.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103050.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103051.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `80s CT - 6666% Dark DMG (Physical). Increases all allies' CRI rate by 50% for 3s.`,
                break: 1500
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The demon leader Grad, revived from his sealing during the Ancient War, selected this swimwear in order to blend in on the human-infested beach. His muscular, beautiful body attracted plenty of attention, starting to feed his sense of superiority. All he needed was Nier to attract even more eyes, but upon asking her she only said "With you? Never."`
    },
    { //G.R.A.D Harpoon - Complete
        id: 103061,
        name: `G.R.A.D Harpoon`,
        location: `Limited Equip Summon: Summer Banner`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103060.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103061.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103060.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103061.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 3000% Dark DMG (Physical). 50% chance of Blinding enemy.`,
                break: 1000
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A weapon selected by the Sin Emperor's elite guard, Grad, to attack his hated rival during a trip to the beach. Unaware of being watched, his target was celebrating having finally caught a single fish. With a confident laugh, Grad set out to do better, and moments later the demons in his retinue were faced with a veritable mountain of fresh fish.`
    },
    { //Snorkel of Darkness - Complete
        id: 103071,
        name: `Snorkel of Darkness`,
        location: `Limited Equip Summon: Summer Banner`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 3,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103070.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103071.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103070.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103071.png`
            },
        stats:
            {
                hp: 0,
                atk: 60,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 1900% Dark DMG (Magic). Heals Own HP by 120 for each Enemy Unit hit.`,
                break: 900
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The equipment prepared by Grad in order to get close to his hated rival during a beach vacation. Those sharp eyes weren't focused on his rival, however, but the fish swimming around them. "I'll show you how to fish!" His harpoon flashed and, while extremely happy with his fishing trip, he completely forgot the reason he was originally there.`
    },
    { //Nier's Greed - Complete
        id: 103081,
        name: `Nier's Greed`,
        location: `Limited Equip Summon: Summer Banner`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103080.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103081.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103080.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103081.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `70s CT - 6160% Dark DMG (Magic). 80% chance of Poisoning enemy.`,
                break: 1000
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The swimwear selected by the revived demon leader Nier to blend in among humans on the beach. Choosing one overlapping with the swimwear of her targets, she aimed to maximize her voluptuous figure to exert her dominance over them. When Grad asked her "What if it comes untied?" she only replied "Then it will be easier to oil up." While looking at the adventurer with a smile.`
    },
    { //Temptation Sunscreen - Complete
        id: 103091,
        name: `Temptation Sunscreen`,
        location: `Limited Equip Summon: Summer Banner`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103090.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103091.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103090.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103091.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 2000% Dark DMG (Magic). Reduces Enemy's Poison & Burn Resistance by 30% for 15s.`,
                break: 600
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The tool selected by the conniving Demon, Nier, to get close to her desired target. A certain adventurer was watching the waves when a red-haired woman approached him. "Would you oil me up?" she breathed into his ear. Checking to be sure she meant him, he then followed her away behind some rocks... at least, this is what she is planning.`
    },
    { //Nier's Summer Flower - Complete
        id: 103101,
        name: `Nier's Summer Flower`,
        location: `Limited Equip Summon: Summer Banner`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 3,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103100.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103101.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103100.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103101.png`
            },
        stats:
            {
                hp: 30,
                atk: 15,
                def: 15
            },
        skillset:  
            {
                skill: `50s CT - Increases all Allies' Poison, Paralyze, and Burn Resistance by 20% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The cruel and violent demon Nier selected this flower to decorate her blood-red hair. Alongside her provocative summer swimwear, she looks like nothing less than a brilliant, primary tropical flower. Looking too hot to touch, she stood watching an adventurer and a girl in a white swimsuit having fun in the distance: a swimsuit that would never suit her, of course.`
    },
    { //True 'Regracia' - Complete
        id: 103111,
        name: `True 'Regracia'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103110.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103111.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103110.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103111.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 6600% Light DMG (Physical).`,
                break: 1600
            },
        passive: 
            {
                ability1: `When Equipped by Rayas, increases own DMG by 20% (only once).`,
                ability2: `Increases all Allies' ATK by 15% (only once).`
            },
        lore: `A sword that waited, forgotten in a rock deep in a cave. After removing it, Rayas became an adventurer, learnt to summon, and started to get close to the truth of the world. Was this all just a coincidence, or was it fate? In either case, as the sword grows more powerful alongside him, he has started to sense the larger purpose in his owning it.`
    },
    { //True 'Gaimalse' - Missing Lore
        id: 103121,
        name: `True 'Gaimalse'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103120.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103121.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103120.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103121.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 6000% Water DMG (Physical). Increases all allies' ATK by 30% for 10s.`,
                break: 2200
            },
        passive: 
            {
                ability1: `When equipped by Roy. Own DMG 20% UP (only once).`,
                ability2: `Own DMG 10% UP when Near-Death.`
            },
        lore: `Missing Lore.`
    },
    { //True 'Raaz' - Complete
        id: 103131,
        name: `True 'Raaz'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103130.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103131.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103130.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103131.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `50s CT - Negates a total of 1000 DMG for all Allies' and reduces DMG Taken by 20% for 15s. Negates all Status Ailments (except Faint).`,
                break: 0
            },
        passive: 
            {
                ability1: `When Equipped by Iris, increases own Evasion Rate by 50% (only once).`,
                ability2: `Increases Own Status Ailment (not Faint) Time Reduction and Evasion Rate by 30%.`
            },
        lore: `A circlet likely created by the gods. The reason Iris owns it is still unknown, and she didn't like it much herself at first. She has realized that its strange power is also protecting her, however, and now happily keeps it with her - without yet realizing that her positive desire to keep pace with a certain adventurer has unlocked new power within it.`
    },
    { //True 'Noble Bamint' - Missing Lore
        id: 103141,
        name: `True 'Noble Bamint'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103140.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103141.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103140.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103141.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `70s CT - 8400% Fire DMG (Magic). 70% chance of Burning Enemy.`,
                break: 0
            },
        passive: 
            {
                ability1: `When equipped by Mira (Fire), own DMG 20% UP (Only once).`,
                ability2: `DMG to Earth enemy 5% UP.`
            },
        lore: `Missing Lore.`
    },
    { //True 'Rig Arcela' - Complete
        id: 103151,
        name: `True 'Rig Arcela'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103150.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103151.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103150.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103151.png`
            },
        stats:
            {
                hp: 500,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - Heals all Allies' HP by 1200. Heals all Allies' Status Ailments except for Faint and Disease.`,
                break: 0
            },
        passive: 
            {
                ability1: `When Equipped by Liese, increases own DMG by 20% (only once).`,
                ability2: `Increases Healing performed by yourself by 10% (excludes absorbed ATK).`
            },
        lore: `A holy book carried around by Priestess Liese, explaining the teachings of the god of light. It also represents her bond with her parents, who gave it to her when she was four. She has already memorized it all, but she still opens it to read from often. Her feelings toward the text has given the very pages power, and as she grows so too does its strength.`
    },
    { //Beloved Specs 'Pronimos' - Complete
        id: 103161,
        name: `Beloved Specs 'Pronimos'`,
        location: `Limited Event - Taking the High Road (Fen)`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103160.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103161.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103160.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103161.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `5s CT - Mode Changes: Increases all Allies' Arts Gauge by 1 for 15s OR all Allies' Equipment CT by 10% for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increases All Allies' Accuracy by 30% (only once).`
            },
        lore: `Glasses worn by Fen of the Gramenia Republic, known for his trademark cool and collected nature. The rumors that they allow him to read minds or see through lies more reflect his incredible powers of observation than any semblance of the truth. He never denies such talk, however, and at least one adventurer he has traveled with still believes all of it.`
    },
    { //Ru Doll - Complete
        id: 103171,
        name: `Ru Doll`,
        location: `Limited Event - Taking the High Road (Fen)`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103170.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103171.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103170.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103171.png`
            },
        stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
        skillset:  
            {
                skill: `35s CT - Increases Target's Arts Gauge by 20. Increases Target's Equipment CT by 10% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increases Skill CT Speed by 10%.`
            },
        lore: `A doll said to be found in the private rooms of nihilistic Head of General Staff Fen. Based in close detail on a particular Paw Person, it should be noted here specifically that Fen most certainly does not have a hidden love of all things cute and charming. If you want to know what he uses it for, you'll have to pluck up the courage to ask him yourself.`
    },
    { //Divination Cards Devan - Complete
        id: 103221,
        name: `Divination Cards Devan`,
        location: `Rotating Event - Guardian of the Lonely Woods (Quon)`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103220.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103221.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103220.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103221.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `75s CT - 7000% Water DMG (Magic).`,
                break: 1200
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Talismans of Divination used by the former sorcerer of La Riiz, Quon. The keys to powerful rites Quon wove himself, the powerful magic inside allows them to dance in the air like living things. With their movement totally unrestricted, they can cut like the sharpest knife or become and impenetrable shield at his whim. Though this may appear far from their original use, at the same time he can use them to read his opponents' future and thus create unbeatable battle strategies.`
    },
    { //Vestment Regal Mantle - Complete
        id: 103231,
        name: `Vestment Regal Mantle`,
        location: `Rotating Event - Guardian of the Lonely Woods (Quon)`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103230.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103231.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103230.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103231.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `60s CT - Reduces all Allies' DMG Taken by 20% for 10s. All Allies Evade Burn effect for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The secluded sorcerer of La Riiz Quon has donned this mantle as he rises once again. Once his uniform of duty, now it tells of his true elevated rank. He once threw away his status and power to refute the Church which had broken his soul. However, now that he has resolved to to face the Ancient Dragon, he resumed this mantle. Imbued with the puissant techniques of La Riiz, it elevates its wearer's power to their ultimate limits.`
    },
    { //Reward La Riizilian - Complete
        id: 103241,
        name: `Reward La Riizilian`,
        location: `Rotating Event - Guardian of the Lonely Woods (Quon)`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103240.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103241.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103240.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103241.png`
            },
        stats:
            {
                hp: 120,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - Heals all Allies' HP by 500. If User is Water Elemental then Healing x 1.5.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The secluded sorcerer Quon bears this pendant again to rewrite his past. Once his symbol as an official of the Magus Empire of La Riiz, he threw it away in despair. Yet now he has chosen to resume his former status to serve the forest. Although he could not escape his past, perhaps this trial is needed to awaken his true hidden self, as he faces up the choice of throwing away that which was most important.`
    },
    { //Star-Spangled Liese - Complete
        id: 103251,
        name: `Star-Spangled Liese`,
        location: `Limited Equip Summon: Summer Banner`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103250.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103251.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103250.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103251.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 3500% Light DMG (Magic). 80% chance of Paralyzing enemy. Heals all allies' HP by 800.`,
                break: 800
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Liese caused quite a commotion at the beach near Reigrad when she wore this swimsuit. Her companions were speechless at the sight. The cook was over come with motherly concern, worrying where Liese's upbringing had gone wrong. The Guildmaster and Blacksmith just grinned! The noblewoman threw her a towel, the receptionist averted her eyes, and the sorceress smiled. The Knight Captain and the adventurer trembled, and as the alchemist prepared a different swimsuit, young Iris laughed. "I picked it out for her."`
    },
    { //Iris' Change of Pace - Complete
        id: 103261,
        name: `Iris' Change of Pace`,
        location: `Limited Equip Summon: Summer Banner`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103260.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103261.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103260.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103261.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `55s CT - Reduces all Allies' DMG Taken by 15% for 12s. Increases all Allies' Stats by 30% for 12s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The adventurer Rayas was looking for for Iris. She was right beside him, but suddenly vanished. Just when he was getting anxious... Iris reappears, led by the noblewoman and sorceress, wearing a new swimsuit. It appears she had more than one ready to go! Rayas cried out, unthinking. "I'm so glad you're OK! I looked everywhere for you." Earning him a thorough talking-to from the other two women.`
    },
    { //The Legend of Ganan - Complete
        id: 103271,
        name: `The Legend of Ganan`,
        location: `Limited Equip Summon: Summer Banner`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103270.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103271.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103270.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103271.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 6500% Fire DMG (Physical). Increases own Break Power by 30% for 9s.`,
                break: 2200
            },
        passive: 
            {
                ability1: `Negates Paralysis, Freeze & will not Flinch.`
            },
        lore: `The Guidlmaster's final form! Headmaster of summer school at the Adventurer's Guild Reigrad branch. Seeing this, every aspiring adventurer finds their resolve tested. "And so begins hell!" he calls, and trainees drop one after another under his harsh tutelage. But every year, as his pupils are ready to break, he offers them his wisdom. "Stand up! Believe in yourself! Your body will never betray you!" Surely, this year will see the birth of a new legend...`
    },
    { //Demon Whip 'Basilisk' - Missing Lore
        id: 103281,
        name: `Demon Whip 'Basilisk'`,
        location: `Limited Event - The Demon's Dream (Nier)`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103280.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103281.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103280.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103281.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `15s CT - 3200% Dark DMG (Physical). 60% chance of reducing enemy's ATK by 30% for 5s.`,
                break: 400
            },
        passive: 
            {
                ability1: `DMG 15% UP to enemy with Poison or Disease.`
            },
        lore: `Missing Lore.`
    },
    { //Demon Gear 'Kalkinion' - Missing Lore
        id: 103291,
        name: `Demon Gear 'Kalkinion'`,
        location: `Limited Event - The Demon's Dream (Nier)`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103290.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103291.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103290.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103291.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 6200% Dark DMG (Magic). If User is Dark Elemental, drains 5% HP.`,
                break: 1200
            },
        passive: 
            {
                ability1: `Ignores Enemies' DEF by 15%.`
            },
        lore: `Missing Lore.`
    },
    { //Demon Armor 'Episumia' - Complete
        id: 103301,
        name: `Demon Armor 'Episumia'`,
        location: `Limited Event - The Demon's Dream (Nier)`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103300.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103301.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103300.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103301.png`
            },
        stats:
            {
                hp: 120,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - Increases Target's MAX HP by 1800 for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Ignores Enemies' DEF by 15%.`
            },
        lore: `The Demon Armor that protects Nier who is an executive of the Demons who revived at the Sealed Fortress Gormarey. Being an extreme sadist, she feels pleasure in fights and gets excited. Her body temperature rises sharply, so she wished for an armor with high heat dissipation. As for an armor it shows her skin too much, thus Grad made fun of it, but she just said it makes her "excited".`
    },
    { //Twin Hammers Orthros - Complete
        id: 103311,
        name: `Twin Hammers Orthros`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103310.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103311.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103310.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103311.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `100s CT - 1500% Earth DMG (Physical). Absorbs Enemies' Arts gauge by 60% when attack hits.`,
                break: 100
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The twin hammers that represent Catilou, the strongest bandit, during the first period of the Ancient War. Having a lot of followers, thousands of rare items and mystery items were gathered for her. Among the collection she favored the mysterious twin hammers that were decorated with bones of a two-headed magical monster the most. She only thought of these as a topic for a good chat, but people were terrified of the overwhelming powers, thus rumors said that powers were drawn out from the magical monster's soul inside the bones.`
    },
    { //Giant Zolda Helmet - Complete
        id: 103321,
        name: `Giant Zolda Helmet`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 3,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103320.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103321.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103320.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103321.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 60
            },
        skillset:  
            {
                skill: `30s CT - Negates 600 DMG Dealt to Target for 8s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The helmet that represents Zoldes, a famous brave warrior of Giants during the first period of the Ancient War. One after another, warriors ran away once they saw his figure, so there were times that battles did not even start. thus, his helmet is always shining without any scratches which showed off his majesty in battles. However, it seems that he was pretty unhappy that there were few chances to show off the true powers of the helmet made out of the best material.`
    },
    { //Crimson Hammer Zoldavalcan - Complete
        id: 103331,
        name: `Crimson Hammer Zoldavalcan`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103330.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103331.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103330.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103331.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `70s CT - 5800% Fire DMG (Physical). If User is Fire Elemental, increases Critical Rate by 50% for 4s.`,
                break: 2400
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The hammer that Zoldes, a famous brave warrior of Giants that people feared, used during the first period of the Ancient War. No matter how many times he used his hammer with his endless powers, it never broke. Both his helmet and hammer were made by a nameless blacksmith, so the two were named after Zoldes. But he did not like to be the only one praised. "This victory came not from my powers, it came from this helmet and this hammer."`
    },
    { //Flower Cane Irurose - Complete
        id: 103341,
        name: `Flower Cane Irurose`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103340.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103341.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103340.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103341.png`
            },
        stats:
            {
                hp: 120,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - Heals all Allies' HP by 20%.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `This is the rod used by Pola, the "Witch of Funereal Bloom", part of the "Grimm Stella" covenant. The force of life inside it is enough to fill the whole area with rose ivy and flowers every time it is shaken, but as described by her name, Pola never used its powers for saving people but only to carry death. It's not well known, but it seems that the the reason why she could cast a spell in such short words, which is the reason why she became this famous, was actually possible because of the rod.`
    },
    { //Frozen Robe Taumaizen - Complete
        id: 103351,
        name: `Frozen Robe Taumaizen`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103350.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103351.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103350.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103351.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `40s CT - Reduces Own DMG Taken by 30% for 15s. Evades Freezing effect for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The magical robe that the "Witch of Disaster" had created on a whim. This witch was the cause of "Winter of Taumaizen" which turned summer fields into frozen hellscape in an instant. By wearing this robe, you won't need to worry about freezing in even the coldest places. Not only does it have defense abilities against snow magic, it also keeps the constancy of one's body by harmonizing to the cold air. However, once she noticed that this was unnecessary for her, she lost interest, and hung the robe on a tree and left.`
    },
    { //Firebird 'Alle Rouge' - Complete
        id: 103361,
        name: `Firebird 'Alle Rouge'`,
        location: `Limited Event - As a Lady (Mira)`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103360.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103361.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103360.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103361.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 7777% Fire DMG (Physical). Reduces enemy's Burn resistance by 50% for 4s.`,
                break: 1000
            },
        passive: 
            {
                ability1: `DMG & Break power 30% UP while enemy is Burning (Only once).`
            },
        lore: `One of the rare beasts that have existed from ancient times in Raktehelm. It used to be a Spirit that ruled all fire birds and became a God, thus it is more of a conception rather than an existence. Those who are excepted by it receives the protection of the flames, and it burns out anything or anyone that stands in their way. It is the symbol of regeneration, but only a few have actually seen it.`
    },
    { //True 'Gradion' - Missing Lore
        id: 103371,
        name: `True 'Gradion'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103370.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103371.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103370.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103371.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 7000% Fire DMG (Physical). Increases CRI rate by 50% for 8s when enemy is Burning.`,
                break: 1600
            },
        passive: 
            {
                ability1: `When equipped by Clyde, increases own DMG by 75%. Automatically increases Arts gauge (Only once).`,
                ability2: `DMG to Earth enemy 25% UP.`
            },
        lore: `Missing Lore.`
    },
    { //True 'Froid Rosier' - Complete
        id: 103381,
        name: `True 'Froid Rosier'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103380.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103381.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103380.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103381.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 5500% Water DMG (Magic). 60% chance of Freezing Enemy.`,
                break: 1200
            },
        passive: 
            {
                ability1: `When Equipped by Corsair, increases own DMG by 75% and increases Own Arts Gauge automatically (only once).`,
                ability2: `Increases DMG to Fire Elemental Units by 25%.`
            },
        lore: `The sword used by Corsair, one of the 3 Blade Masters during the Ancient War. It is said that she used this sword for her whole life. There are numerous legends that one could not imagine that about this beautiful sword. It was created to amplify Corsair's strength, but becoming too strong, she remade it to match her abilities. As a result, just by drawing the sword, it freezes even roses around her.`
    },
    { //True 'Arth Lumina' - Complete
        id: 103391,
        name: `True 'Arth Lumina'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103390.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103391.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103390.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103391.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 6000% Earth DMG (Physical).`,
                break: 3000
            },
        passive: 
            {
                ability1: `When Equipped by Arth, increases own DMG by 75% and increases Own Arts Gauge automatically (only once).`,
                ability2: `Increases DMG to Water Elemental Units by 25%.`
            },
        lore: `The twin blades that Arth, one of the 3 Blade Masters and left his name in history, used during the Ancient War. It was created by Gods to amplify his sword skills, thus the sword was named after him. A symbol of strength, it became a lesson for him not to forget the war. Being a weapon of destruction and regeneration, it can be said that it was a mirror that reflected his glory and amends.`
    },
    { //Relic 'Deus Volta' - Complete
        id: 103401,
        name: `Relic 'Deus Volta'`,
        location: `Giant Boss - Clashing Thunder of the Emerald Bird (Tonetaurus)`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103400.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103401.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103400.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103401.png`
            },
        stats:
            {
                hp: 30,
                atk: 15,
                def: 15
            },
        skillset:  
            {
                skill: `50s CT - Increases all Allies' DMG Inflicted by 20% and adds Paralyze effect for all Allies' for 8s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Regenerates own HP by 10 (only once).`
            },
        lore: `The source behind the 'Tonetaurus' ability to create infinite bolts of lightning. The particles themselves are too small to be used practically, but from the sheer quantity the Tonetaurus needs, it was possible to transform into a relic. Its enormous power is said to match that of ancient gods.`
    },
    { //Relic 'Pteraturus' - Complete
        id: 103411,
        name: `Relic 'Pteraturus'`,
        location: `Giant Boss - Clashing Thunder of the Emerald Bird (Tonetaurus)`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103410.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103411.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103410.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103411.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 250
            },
        skillset:  
            {
                skill: `50s CT - Negates 800 DMG Dealt to all Allies' for 12s. Increases all Allies' Paralyze and Freeze resistance by 70% for 12s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Regenerates own HP by 10 (only once).`
            },
        lore: `A divine shield made from the 'Tonetaurus' feather which emits lightning bolts. The feathers themselves don't hold a charge, but rather the feathers' unique power converts the atoms in the surrounding air into electricity. A blow like a bolt of lightning has left many to warn of its dangers: "Lest not touch, for death awaits."`
    },
    { //Axioma 'Wahrheit' - Missing Lore
        id: 103421,
        name: `Axioma 'Wahrheit'`,
        location: `Limited Event - The Blade is Thick as Blood (Est)`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103420.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103421.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103420.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103421.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `90s CT - 8500% Water DMG (Physical). If Human, increases Arts DMG by 50% after using Equipment (Only once). Reduces Arts gauge for 10s.`,
                break: 1000
            },
        passive: 
            {
                ability1: `Physical DMG 15% UP (Only once).`
            },
        lore: `Missing Lore.`
    },
    { //Oathtaker 'Shivlet' - Missing Lore
        id: 103431,
        name: `Oathtaker 'Shivlet'`,
        location: `Limited Event - The Blade is Thick as Blood (Est)`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103430.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103431.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103430.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103431.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 7000% Water DMG (Physical). 50% chance of Freezing enemy.`,
                break: 600
            },
        passive: 
            {
                ability1: `DMG 10% UP to Giant Boss.`
            },
        lore: `Missing Lore.`
    },
    { //Peacemaker 'Leighto' - Missing Lore
        id: 103441,
        name: `Peacemaker 'Leighto'`,
        location: `Limited Event - The Blade is Thick as Blood (Est)`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103440.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103441.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103440.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103441.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 3300% Water DMG (Physical). If Water unit, reduces Skill CT by 100% for 5s.`,
                break: 800
            },
        passive: 
            {
                ability1: `DMG 10% UP to Giant Boss.`
            },
        lore: `Missing Lore.`
    },
    { //Grimoire SHIN-RA-BAN-SHO - Complete
        id: 103451,
        name: `Grimoire SHIN-RA-BAN-SHO`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103450.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103451.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103450.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103451.png`
            },
        stats:
            {
                hp: 500,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - Heals all Allies' HP by 100/s for 10s. If User is Water Elemental then Healing x 1.5. Heals all Allies' Status Ailments except Faint or Disease.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `This is an extremely precious book inherited from the famous "Witch of Destruction". The content changes every time you open it and inside it, you may find everything from valuable wisdom to useless jokes. It contains very valuable information such as the truth about the hidden history and far futures, but it is very not likely that anybody in the world can select the knowledge inside it to use it properly.`
    },
    { //Twin Magical Guns Neglect - Missing Lore
        id: 103461,
        name: `Twin Magical Guns Neglect`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103460.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103461.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103460.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103461.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 5500% Dark DMG (Magic). Reduces enemy's Accuracy rate by 30% for 10s.`,
                break: 1000
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Phantom Robe Hollow Graph - Complete
        id: 103471,
        name: `Phantom Robe Hollow Graph`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103470.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103471.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103470.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103471.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 120
            },
        skillset:  
            {
                skill: `35s CT - Negates 600 DMG Dealt to all Allies' for 10s. Increases all Allies' DEF by 30% for 10s (only once).`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The mantle that a famous gunman used before the Ancient War. Not only does it contain Dark Magic that protects the user from enemy attacks, but it also has the ability to camouflage with the background to confuse enemies. He was indeed a quick and accurate gunman thanks to this mantle. However, in his very last moments he took off this mantle with his own will.`
    },
    { //Necklace 'Barbelun' - Complete
        id: 103481,
        name: `Necklace 'Barbelun'`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 3,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103480.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103481png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103480.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103481.png`
            },
        stats:
            {
                hp: 30,
                atk: 15,
                def: 15
            },
        skillset:  
            {
                skill: `20s CT - Increases all Allies' Arts Gauge by 1 for 5s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `This was the necklace that a certain witch always put on. It affects the owner's mind to think clearly, and to become full of curiosity. Later, called as "Destruction Witch", she is said to have read books from all ages and countries, thus this was a treasure for her. However, this must have been the key to the misfortune that happened to her and others close to her.`
    },
    { //Dark Soul Pendant 'Necrocles' - Complete
        id: 103491,
        name: `Dark Soul Pendant 'Necrocles'`,
        location: `Limited Event - The Twin Knights of the Morning Star (Regulus & Feena)`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103490.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103491.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103490.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103491.png`
            },
        stats:
            {
                hp: 120,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - Heals all Allies' HP by 15%. If Dark unit, increases Heal amount by 10%. If Demon, increases own Arts gauge by 20.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increases own Blind Resistance by 100%. Increase own Curse Resistance by 100%.`
            },
        lore: `A pendant belonging to the Special Unit "Aegis Raven" of La Riiz, that Regulus received from the Cult. Despite he was the personification of the saying "A good attack is the best defense" and his achievements were extraordinary, her didn't have any magical power, so during magic battles, he was often in danger. Thus, the Cult decided to endow him with this pendant, which is catalyzed by the invulnerability of the feared Necromancer's skull, to protect him.`
    },
    { //True 'Nemesis Crow' - Complete
        id: 103501,
        name: `True 'Nemesis Crow'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103500.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103501.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103500.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103501.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 7000% Dark DMG (Magic). Reduces enemies' Dark Resistance by 50% for 10s.`,
                break: 1000
            },
        passive: 
            {
                ability1: `When Equipped by Regulus, increases own Critical DMG by 50% and increases own Arts Gauge automatically (only once).`,
                ability2: `Increases DMG to Dragons by 50%.`
            },
        lore: `This is the blade once wielded by Regulus, member of the Twin Knights of the Morning Star and of the Raven's Aegis of the Magus Empire of La Riiz. The incredible strikes he was known for are the result of the advanced design of this weapon. During his long journey, he fought many tough fighters and famous blades, but he never met anyone who could break his sword, the one that once defeated the fire of the Flamewyrm Dargeon.`
    },
    { //True 'Ryude Magus' - Missing Lore
        id: 103511,
        name: `True 'Ryude Magus'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103510.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103511.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103510.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103511.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 8500% Light DMG (Physical). Increases all allies' CRI rate by 60% during Break for 8s.`,
                break: 1400
            },
        passive: 
            {
                ability1: `When equipped by Zeorg (Light), DMG 20% UP & DEF 50% UP (Only once).`,
                ability2: `CRI DMG 20% UP during Break (Only once).`
            },
        lore: `Missing Lore.`
    },
    { //Ultimate Magic Canon 'Infinite' - Missing Lore
        id: 103521,
        name: `Ultimate Magic Canon 'Infinite'`,
        location: `Limited Event - Echoes of Invasion (Edram)`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103520.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103521.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103520.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103521.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `80s CT - 12000% Light DMG (Magic). Reduces own DEF by 30% for 20s.`,
                break: 100
            },
        passive: 
            {
                ability1: `Paralysis Time Reduction & Paralysis evasion rate 20% UP.`
            },
        lore: `Missing Lore.`
    },
    { //End Circuit 'Judgement' - Complete
        id: 103531,
        name: `End Circuit 'Judgement'`,
        location: `Limited Event - Echoes of Invasion (Edram)`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103530.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103531.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103530.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103531.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `40s CT - Increases all Allies' DEF by 30% for 15s. Heals all Light Elemental Allies' Status Ailments except for Faint and Disease.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increases Paralyze Time Reduction by 20%. Increases Paralyze Evasion Rate by 20%.`
            },
        lore: `The device that was put into the mecha doll. This allows the doll to judge objectively and also works as the safety system for the magic canon. It is impossible for the doll to make decisions on its own and it is created to always obey his master's orders. Rumors say that this circuit is where the dolls mind and feelings are, but there is no way to prove that for now...`
    },
    { //Phantasmagoric 'Cavalry' - Complete
        id: 103541,
        name: `Phantasmagoric 'Cavalry'`,
        location: `Limited Event - Echoes of Invasion (Edram)`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103540.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103541.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103540.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103541.png`
            },
        stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
        skillset:  
            {
                skill: `40s CT - Increases all Allies' Skill CT Speed by 50% and Arts gauge by 1 for 5s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increases Paralyze Time Reduction by 10%. Increases Paralyze Evasion Rate by 10%.`
            },
        lore: `The battle support device that the mecha doll used. As the name tells, it moved freely in the battlefields like a horse and supported its owner. It is possible to set it to move based on rules or to make it move depending on the current situation. In past wars, Machine used this device and by itself, its work equaled up to a hundred people.`
    },
    { //X Marks the Spot - Complete
        id: 103551,
        name: `X Marks the Spot`,
        location: `Limited Event - Ru and Rolly's Day of Adventure`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103550.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103551.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103550.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103551.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `180s CT - 9999% Non-Elemental DMG (Magic). Increases all allies' BE Output & CRI rate by 50% for 5s`,
                break: 999
            },
        passive: 
            {
                ability1: `Own stats 10% UP (Only once).`
            },
        lore: `This is when Rolly and Ru, which Rayas met during his journey, unleash their true powers. Both do not have high battle skills, but through their magical powers and cooperation have improved. Originally, Fairies and Spirits have enormous powers, and these two cute buddies are not an exception. Their powers should become massive through their adventures.`
    },
    { //Help me Rolly! - Complete
        id: 103561,
        name: `Help me Rolly!`,
        location: `Limited Event - Ru and Rolly's Day of Adventure`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103560.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103561.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103560.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103561.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `15s CT - 2200% Light DMG (Physical). 5% Chance of Fainting enemy.`,
                break: 300
            },
        passive: 
            {
                ability1: `BE Output from Equipment 10% UP (Only once).`
            },
        lore: `The Fairy that Iris, a girl who lost her memories, had encountered and saved. Although he looks similar to Kyuons, his fur and other aspects make him a different race, or maybe a new species. But no one knows the truth yet... His intelligence is extremely high and rumors are it might be the same as the adventurer Rayas. His battle abilities are not that high, but a Fairy as he is, his tackle with full speed hurts a lot.`
    },
    { //Show 'em Ru - Complete
        id: 103571,
        name: `Show 'em Ru`,
        location: `Limited Event - Ru and Rolly's Day of Adventure`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103570.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103571.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103570.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103571.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 100% Earth DMG (Magic). 60% chance of Paralyzing Enemy.`,
                break: 100
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A young Spirit of the Paw People. He says that he was saved by Fen, the Head of General Staff in Gramenia, but Fen says he didn't. Ru has his own way and is a curious fellow so that might be the reason he gets along with Gen in some ways. His battle abilities are not so high, but Fen has a high opinion on him due to his quick moves and using his small body to do spying that Humans cannot do.`
    },
    { //Rolly Plush - Complete
        id: 103581,
        name: `Rolly Plush`,
        location: `Limited Event - Ru and Rolly's Day of Adventure`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103580.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103581.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103580.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103581.png`
            },
        stats:
            {
                hp: 500,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `70s CT - Heals all Allies' HP by 1000. If User is Light Elemental then Heals all Allies' HP by 30 for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increases Skill CT Speed and HP Recovery amount by 10% (excludes absorbed ATK) (only once).`
            },
        lore: `The plush toy of Rolly which Iris, a girl who lost her memories, has in her room. She asked the blacksmith in her town to make this. If you look at it from a far distance, it looks just like the real Rolly. By the way, when Rolly saw this toy for his first time, he tackled it full of hostility. However, the moment he was told that it was a toy, he left the room quite embarrassed.`
    },
    { //Poison Spear 'Goroncharge' - Missing Lore
        id: 103591,
        name: `Poison Spear 'Goroncharge'`,
        location: `Main Story [Chapter 8 > Boum Forest Garden > [Challenge] The Magical Plant's Target] Secret Equip`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103590.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103591.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103590.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103591.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 4000% Earth DMG (Physical). 70% chance of Poisoning Enemy.`,
                break: 800
            },
        passive: 
            {
                ability1: `DMG 20% UP when enemy is Poisoned.`
            },
        lore: `Missing Lore.`
    },
    { //True 'Day Break' - Complete
        id: 103601,
        name: `True 'Day Break'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103600.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103601.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103600.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103601.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `55s CT - 6500% Fire DMG (Physical). Increases own Arts Gauge by 80 when near-death.`,
                break: 1000
            },
        passive: 
            {
                ability1: `When Equipped by Ragna, increases own DMG by 20% and negates all Status Ailments except Faint.`,
                ability2: `Increases DMG to Demons by 50%.`
            },
        lore: `This is the true form of the beloved sword of Ragna, a knight who offered his life for Humans despite being a God himself. This blade, tempered by the Gods, possesses an unbelievable edge, and its power unleashes blazing flames. However, this blade was more than a mere weapon to Ragna. For it held true to its name, offering hope no matter how desperate the situation became. No matter how dark the night, daybreak would come and light the way.`
    },
    { //Melia's Sorceress' Hat - Complete
        id: 103611,
        name: `Melia's Sorceress' Hat`,
        location: `Limited Equip Summon: Halloween Banner`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 3,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103610.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103611.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103610.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103611.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 60
            },
        skillset:  
            {
                skill: `60s CT - Negates 500 DMG Dealt to Target for 10s. Reduces Target's Fire Elemental DMG Taken by 30% for 10s (only once).`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `This is the Reigrad Autumn Festival costume hat chosen by Melia, intelligence officer and commander of the 3rd Magus Division of the Empire of La Riiz. The sight of her in her so-called witch's hat brings a wry grin to the face of the male soldiers around her. Her friends the intellectual and the knight-commander sarcastically commented "Oh, a witch... How out of character." Yet the Guildmaster and the Smith got chills down their spines. Only the Adventurer laughed and said, "That's perfect for you!" Which earned him an hour long lecture on exactly what she thought of that!`
    },
    { //Melia's Enchantment - Complete
        id: 103621,
        name: `Melia's Enchantment`,
        location: `Limited Equip Summon: Halloween Banner`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103620.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103621.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103620.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103621.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 5000% Dark DMG (Magic). Reduces all allies' Skill CT by 30% & Equipment CT by 20% for 8s.`,
                break: 500
            },
        passive: 
            {
                ability1: ``
            },
        lore: `This is the Autumn Festival costume of Melia, the Blue-eyed Black Fox. With it's adult appeal and scent of danger, it has the men of the town saying "Trick, please!" when she goes walking past. Her male comrades look on from a safe distance, while the young noblewoman casts sidelong jealous glances at the satisfied looking Blue-eyed Black Fox. She deliberately takes the adventurer's arm and says, with a grin, "Oh, a witch and a vampire do make a wonderful couple!"`
    },
    { //Melia's Broomstick - Complete
        id: 103631,
        name: `Melia's Broomstick`,
        location: `Limited Equip Summon: Halloween Banner`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103630.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103631.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103630.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103631.png`
            },
        stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
        skillset:  
            {
                skill: `50s CT - Increases Target's DMG Inflicted by 20% and Break Power by 10% for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `This is a little something Melia prepared for her Autumn Festival with her costume. Every witch needs a broom, and Melia loved swooping in with a cry of "Here's your witch girl Melia!" The mysterious girl, the priestess and the adventurer all yell for a flyover. The chef, though, just goes red with anger and yells about how her skirt is too short to go flying around over everyone's head. But the other members just look on and mutters "Can't fathom calling yourself a 'witch girl' at that age..." over his drink.`
    },
    { //Nurse-Liese' Cap - Complete
        id: 103641,
        name: `Nurse-Liese' Cap`,
        location: `Limited Equip Summon: Halloween Banner`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103640.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103641.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103640.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103641.png`
            },
        stats:
            {
                hp: 120,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - Heals all Allies' HP by 90/s for 7s. Completely evades Blind and Curse for all Allies for 7s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `This is Priestess Liese' costume hat for the Reigrad Autumn Festival. But she had a real struggle in front of the mirror. A nurse must keep her hair tightly bound and covered with a hat for hygienic reasons. Since she almost never does her hair up, she just couldn't get it right. All the grown-ups told her she should not get so stressed over a costume, but her obstinate nature took over and wouldn't let it rest.`
    },
    { //Nurse-Liese' Gown - Complete
        id: 103651,
        name: `Nurse-Liese' Gown`,
        location: `Limited Equip Summon: Halloween Banner`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103650.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103651.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103650.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103651.png`
            },
        stats:
            {
                hp: 500,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `300s CT - Revives all Allies with 25% HP. If User is Light Elemental then Healing x 2.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `When all the adults heard that the Priestess Liese would be dressing up with Iris' help, they all felt a chill. The Chef gave her a serious talking-to, making sure she picked something fitting to avoid a repeat of the summer swimsuit fiasco. And with her help, the holy woman settled on a very appropriate nurse's uniform. And yet, even this outfit clearly had its allure for some. Ever since this day, more than a few call her Angel instead of Priestess.`
    },
    { //Liese' Medicine Time - Complete
        id: 103661,
        name: `Liese' Medicine Time`,
        location: `Limited Equip Summon: Halloween Banner`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103660.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103661.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103660.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103661.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `70s CT - 100% Non-Elemental DMG (Magic).`,
                break: 8000
            },
        passive: 
            {
                ability1: `Increases Unit's Equipment CT Speed by 5%.`
            },
        lore: `This is a syringe to go with the Priestess Liese' Reigrad Autumn Festival costume. Seeing her in her nurse's costume gave her a different kind of divine aura, and drew those suffering unusual pangs to flock to the shrine. The adults watched on, charmed as she did her best to play nurse for all who came by. However, the mood took a dark turn when she picked up her syringe. It was filled with a sparkling, obviously magical fluid. And sitting across from her was the oblivious adventurer...`
    },
    { //Iris' Bunny Ears - Complete
        id: 103671,
        name: `Iris' Bunny Ears`,
        location: `Limited Equip Summon: Halloween Banner`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 3,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103670.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103671.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103670.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103671.png`
            },
        stats:
            {
                hp: 0,
                atk: 60,
                def: 0
            },
        skillset:  
            {
                skill: `10s CT - 1500% Light DMG (Magic).`,
                break: 300
            },
        passive: 
            {
                ability1: ``
            },
        lore: `These are the bunny ears to go along with Iris' Autumn Festival costume. Apparently, she'd fallen in love at first sight, and according to her shopping partner Chef, she'd thrown a rare tantrum to get her way. When she wore the whole costume, she was the very image of a bunny rabbit, yet the dress itself is so revealing all the adults cannot help but worry. But surely the innocent young thing will be safe with her large, shaven-headed bodyguard behind her.`
    },
    { //Iris' Bunny Dress - Complete
        id: 103681,
        name: `Iris' Bunny Dress`,
        location: `Limited Equip Summon: Halloween Banner`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103680.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103681.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103680.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103681.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `50s CT - Reduces all Allies' DMG Taken by 50% for 5s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `This is Iris' costume dress for the Autumn Festival. The noblewoman had strongly recommended something cute, but she could say nothing when Iris smiled widely and exclaimed: "This is the cutest costume ever!" Every flutter of the short skirt strikes fear into the hearts of the older women. Iris, blissfully unaware of this, goes hopping around and asks the adventurer what he thinks, at which point the ladies fix him with piercing glares, daring him not to screw up.`
    },
    { //Count Rayas' Cape - Complete
        id: 103691,
        name: `Count Rayas' Cape`,
        location: `Limited Equip Summon: Halloween Banner`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103690.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103691.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103690.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103691.png`
            },
        stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
        skillset:  
            {
                skill: `30s CT - Increases Target's Skill CT Speed by 70% and ignores enemies' DEF by 30% for 6s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `This is the cape for Rayas' Autumn Festival costume. He is going for a vampire theme, and he takes on an air of insufferable self-satisfaction with his fake fangs and overblown aristocratic dress. The noblewoman, who knows a thing or two about aristocracy, is hard put to suppress her smile and finds the pathetic display hard to take. Just when she's about to say something, his old friends lets our a huge sigh, "The only thing he knows about the nobility is what he learned in kids' picture books..."`
    },
    { //Count Rayas' Rapier - Complete
        id: 103701,
        name: `Count Rayas' Rapier`,
        location: `Limited Equip Summon: Halloween Banner`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103700.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103701.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103700.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103701.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 5500% Light DMG (Physical). Increases all allies' ATK by 50% for 8s.`,
                break: 3000
            },
        passive: 
            {
                ability1: ``
            },
        lore: `This a sword Rayas plans to carry for Reigrad's Autumn Festival. He has been putting in his fangs, tying on his cape and practicing his poses nonstop! It is not clear where he is going to be showing it off, but he is so intense he does not notice that everyone other than Iris is totally turned off. The noblewoman finally couldn't take it anymore and was about to say something, but his old friend stopped her, saying "This is hilarious, let him go!"`
    },
    { //Jack-O-Penst - Complete
        id: 103711,
        name: `Jack-O-Penst`,
        location: `Limited Halloween Event - Lanterns to Light the World`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103710.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103711.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103710.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103711.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `70s CT - 7000% Dark DMG (Physical). 100% chance of Blinding enemy.`,
                break: 1000
            },
        passive: 
            {
                ability1: `Arts DMG 10% UP (Only once).`
            },
        lore: `This is the evil sword wielded by the Ragsherum Phantom in his twisted form of Jack-O-Sherum. This sword has a pumpkin pattern just like the Jack-O-Sherum's head, and though its a fearful Demonic weapon, it's also kind of cute. Yet it is imbued with deep hatred and dark Demonic magic, so it should never be used without the greatest care.`
    },
    { //Jack-O-Sherum - Complete
        id: 103721,
        name: `Jack-O-Sherum`,
        location: `Limited Halloween Event - Lanterns to Light the World`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103720.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103721.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103720.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103721.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `90s CT - 6666% Dark DMG (Physical). Ignores enemy's DEF by 50% for 6s.`,
                break: 700
            },
        passive: 
            {
                ability1: `Auto-fills Arts gauge by 2 when Near-Death.`
            },
        lore: `This is the armor of Jack-O-Sherum, created when the Ragsherum Phantom somehow became fused with a pumpkin. The helm has a pumpkin design, just like Jack-O-Sherum's head. It appears as if it was created with Ragsherum fused with a mysterious pumpkin spirit, but no one knows for sure. The only thing we know is that the power sleeping within this Demon Armor is every bit as great as the original.`
    },
    { //Jack-O-Shaft - Complete
        id: 103731,
        name: `Jack-O-Shaft`,
        location: `Limited Halloween Event - Lanterns to Light the World`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103730.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103731.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103730.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103731.png`
            },
        stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
        skillset:  
            {
                skill: `40s CT - Increases all Allies' Arts Gauge by 20. Reduces all Allies' HP recovery amount by 30% for 10s (except lifesteal).`,
                break: 0
            },
        passive: 
            {
                ability1: `Increases all Allies' DMG to Light Elemental Units by 5% (only once).`
            },
        lore: `These are the gauntlets of Jack-O-Sherum, a twisted version of Ragsherum Phantom that appeared here. The elbow sections have large round pumpkin decorations. These are intended to protect both hands, but actually wearing them on the battlefield might require another kind of courage... Naturally, they are quite effective as a defensive armor, but not quite often enough to keep you safe from that kind of hellfire.`
    },
    { //Furious Ru - Complete
        id: 103741,
        name: `Furious Ru`,
        location: `Limited Event - Taking the High Road (Fen)`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103740.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103741.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103740.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103741.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 8500% Earth DMG (Physical). If enemy is of Water element, increases own CRI rate by 50% for 5s.`,
                break: 3000
            },
        passive: 
            {
                ability1: `DMG 10% UP & DMG from enemy 10% DOWN when Near-Death (Only once).`
            },
        lore: `This is when Ru, the young Spirit of the Paw People, is in deep fury. Usually he controls his magical powers, but once they are unleashed, he uses strong attacks which no one can imagine. Although he is a naughty boy, he is pretty calm. Thus it is scarce for him to show his anger. Once something has happened to the ones he loves, he will show this form.`
    },
    { //Shadow Blades 'Euthanasia' - Complete
        id: 103751,
        name: `Shadow Blades 'Euthanasia'`,
        location: `Limited Event - Taking the High Road (Fen)`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103750.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103751.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103750.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103751.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `40s CT - 1111% Earth Physical DMG. If user is Earth, increases all allies' Arts gauge by 5 for 5s.`,
                break: 100
            },
        passive: 
            {
                ability1: `DMG 15% UP & Auto-fills Arts gauge when Boss' HP is under 30% (Only once).`
            },
        lore: `The greater the magical power of the user, the more of these throwing knives there are. It has been many years since anyone could use them well, so they lay dormant in the republic treasury until the young Fen found them and mastered them in a matter of days, thus laying claim to their power for himself. Yet watching him using these difficult weapons so self assuredly left no one able to deny his egotistical nature.`
    },
    { //Relic 'Goron Knuckle' - Missing Lore
        id: 103761,
        name: `Relic 'Goron Knuckle'`,
        location: `Rotating Giant Boss - Life Draining Botanical Beast (Goldola)`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103760.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103761.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103760.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103761.png`
            },
        stats:
            {
                hp: 0,
                atk: 250,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 5500% Earth DMG (Magic).`,
                break: 1200
            },
        passive: 
            {
                ability1: `Increases all Allies' ATK by 15% (only once).`
            },
        lore: `Missing Lore.`
    },
    { //Relic 'Dola Armor' - Complete
        id: 103771,
        name: `Relic 'Dola Armor'`,
        location: `Rotating Giant Boss - Life Draining Botanical Beast (Goldola)`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103770.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103771.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103770.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103771.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 60
            },
        skillset:  
            {
                skill: `30s CT - Negates 400 DMG Dealt to all Allies' for 8s. Reduces all Allies' Water Elemental DMG Taken by 35% for 8s (only once).`,
                break: 0
            },
        passive: 
            {
                ability1: `Increases all Allies' ATK by 15% (only once).`
            },
        lore: `This armor was woven from the bark fibers of the Botanical Beast, Gorondola and sections carved from whole roots. It's something any competent armorer could whip up once they knew where to find the materials, but this armor fell into the hands of an eccentric curio collector. It is top class in terms of power, but any who wear it develop an odd fascination with trinkets. Before they know it, they're walking around with a team of underlings like some kind of gang capo.`
    },
    { //True 'Efkeria' - Complete
        id: 103781,
        name: `True 'Efkeria'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103780.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103781.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103780.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103781.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `35s CT - 5000% Earth DMG (Physical). Heals own HP by 260 & increases own Arts gauge by 20 for each enemy hit.`,
                break: 1500
            },
        passive: 
            {
                ability1: `When equipped by Fen (Earth), DMG 20% UP & Evasion rate 15% UP when Near-Death (Only once).`,
                ability2: `Own DMG 15% UP when enemy's HP is under 50% (Only once).`
            },
        lore: `The magical short sword that has been passed on within the Paw People. It is also the favorite sword of Fen, the strategist of Gramenia Republic, which he keeps using despite his promotion. He says that there isn't anything better that suits him. However, it seems that the true reason is because he is aware of the divine protection of it. The powers of the sword are unleashed when Fen feels the trust and feelings of his "friends." Every time he might be thinking about his rare species friend.`
    },
    { //Divine Sword 'Justicia' - Missing Lore
        id: 103791,
        name: `Divine Sword 'Justicia'`,
        location: `Rotating Event - Ravage of the Azure Swordsman (Gaia)`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103790.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103791.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103790.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103791.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 4800% Water DMG (Physical). Reduces enemy's Water Resistance by 20% for 10s.`,
                break: 800
            },
        passive: 
            {
                ability1: `DEF 20% UP when negating DMG.`
            },
        lore: `Missing Lore.`
    },
    { //Divine Shield 'Castillo' - Complete
        id: 103801,
        name: `Divine Shield 'Castillo'`,
        location: `Rotating Event - Ravage of the Azure Swordsman (Gaia)`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103800.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103801.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103800.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103801.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `50s CT - Negates 800 DMG Dealt to all Allies' for 10s (only once). All Allies' evade Burning effect for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increases DEF by 20% when unit is Negating DMG.`
            },
        lore: `This was the shield borne by Gaia, Shining God Warrior and one of the Divine Knights, early in the Ancient War. It turned his water elemental divine protection into powerful defense. Shield usually protect only the bearer, but in his hands this shield creates a wall of water to defend his comrades as well. Each time, it puts him under great spiritual strain, yet he never hesitated to use it, and thus saved the lives of many comrades.`
    },
    { //Divine Ornament 'Amlethal' - Complete
        id: 103811,
        name: `Divine Ornament 'Amlethal'`,
        location: `Rotating Event - Ravage of the Azure Swordsman (Gaia)`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103810.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103811.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103810.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103811.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 120
            },
        skillset:  
            {
                skill: `40s CT - Reduces all Allies' Fire Elemental DMG Taken by 15% for 10s. Heals all Allies' HP by 20/s for 10s. If User is Water Elemental then Healing x 1.5.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increases DEF by 10% when unit is negating DMG.`
            },
        lore: `This ring bound Shining God Warrior Gaia's long hair. As bearer of the blessing of water, he kept his hair uncut to serve as a reservoir for magical power. It grew down to his knees, and to keep it from interfering in battle he bound it into one long tail. This item is inscribed with prayers and loyalty oaths, and when worn on the hair it amplifies magical power to create a boundary of water around the wearer.`
    },
    { //Kaiju Gem - Complete
        id: 103821,
        name: `Kaiju Gem`,
        location: `Crossover Event: Godzilla Stage Clear Reward`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 3,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103820.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103821.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103820.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103821.png`
            },
        stats:
            {
                hp: 0,
                atk: 60,
                def: 0
            },
        skillset:  
            {
                skill: `100s CT - 4500% Non-Elemental DMG (Physical). Increases all allies' Physical DMG by 20% for 30s.`,
                break: 600
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A unique summoning stone discovered in the ruins where Godzilla appeared. It calls that massive Kaiju, a creature far superior to God Beasts and Demon Beasts. However, not even the most skilled Summoner can control it, meaning they might also be on the receiving end of its powerful heat blasts. Confronting this deity of destruction will only result in destruction.`
    },
    { //Godzilla Earth - Complete
        id: 103831,
        name: `Godzilla Earth`,
        location: `Crossover Equip Summon: Godzilla`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103830.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103831.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103830.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103831.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `100s CT - 12000% Non-Elemental DMG (Physical). Increases all allies Physical DMG by 50% for 30s.`,
                break: 1000
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Summons Godzilla, who cannot be stopped until it has destroyed everything in sight. To support its ponderous bulk, Godzilla's body is composed of muscle similar to metal. This not only makes it impervious to blades and projectiles, it can generate electromagnetic fields at will. Anyone who stands before such might can only realize their own powerlessness and retreat in order to survive.`
    },
    { //Induction Rifle - Complete
        id: 103841,
        name: `Induction Rifle`,
        location: `Crossover Equip Summon: Godzilla`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103840.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103841.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103840.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103841.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `5s CT - 1700% Non-Elemental DMG (Magic). 30% chance of reducing enemy's DEF by 10% for 10s.`,
                break: 300
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A rifle more powerful than any found in Raktehelm. Since it is made from a material that does not exist in this world, it cannot be reproduced. It fires bullets with superconductivity, letting them pierce through almost anything. Though its original owner is unknown, the bloodstains speak of a deadly battle against an unimaginably powerful foe.`
    },
    { //Artillery Tank - Complete
        id: 103851,
        name: `Artillery Tank`,
        location: `Crossover Equip Summon: Godzilla`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103850.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103851.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103850.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103851.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `70s CT - 5000% Non-Elemental DMG (Physical).`,
                break: 4000
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A mobile Weapon that can smoothly travel over even the roughest terrain. Not made of any material native to Raktehelm, it was discovered near the ruins where the giant kaiju was summoned. It can carry several passangers at high speed, but it's true value lies in the twin barrels capable of leveling mountains.`
    },
    { //Elastomer Suit - Complete
        id: 103861,
        name: `Elastomer Suit`,
        location: `Crossover Equip Summon: Godzilla`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103860.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103861.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103860.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103861.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 120
            },
        skillset:  
            {
                skill: `30s CT - Increases all allies' Poison, Paralysis & Disease resistance by 100% & reduces all allies' Magic DMG taken by 30% for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A full-body suit of resilient material found nowhere on Raktehelm. It does not tear even after prolonged exposure to harsh conditions and is easy to move in, making it indispensable for combat. However, one cannot help but imagine the brutal circumstances that required its invention.`
    },
    { //Induction Pistol - Missing Lore
        id: 103871,
        name: `Induction Pistol`,
        location: `Crossover Equip Summon: Godzilla`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103870.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103871.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103870.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103871.png`
            },
        stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
        skillset:  
            {
                skill: `30s CT - Increases target's Equipment CT Speed by 15% & Auto-fills target's Arts gauge by 3 for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Relic 'Cylentium' - Missing Lore
        id: 103881,
        name: `Relic 'Cylentium'`,
        location: `Giant Boss - A Subzero Hellscape (Aerugraxes)`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103880.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103881.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103880.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103881.png`
            },
        stats:
            {
                hp: 0,
                atk: 250,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 6800% Water DMG (Physical). If enemy is Giant Boss, increases own Equipment DMG by 30% for 5s. If enemy is Fire, 50% chance of Freezing enemy.`,
                break: 2000
            },
        passive: 
            {
                ability1: `Increases Fire Elemental Resistance by 10% (Only once).`
            },
        lore: `Missing Lore.`
    },
    { //Relic 'Lacus Aurora' - Complete
        id: 103891,
        name: `Relic 'Lacus Aurora'`,
        location: `Giant Boss - A Subzero Hellscape (Aerugraxes)`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103890.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103891.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103890.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103891.png`
            },
        stats:
            {
                hp: 30,
                atk: 15,
                def: 15
            },
        skillset:  
            {
                skill: `30s CT - 	Increases DMG Inflicted from Water Elemental Units to Fire Elemental Units by 20% and Skill CT Speed by 100% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increases Fire Elemental Resistance by 10% (only once).`
            },
        lore: `The boots of an adventurer who explored the ice lands the Frozen Dragon Auregraxes created before becoming a Relic. Being exposed to the magical powers left in the frozen ground, the boots were transformed into a curse charm that freezes the ground it touches. The boat she boarded home was caught in a storm and was wrecked. She was expected to die but had worn the boots. Upon touching the waves, her soles froze the water surface immediately, and she survived.`
    },
    { //True 'Geshpenst' - Complete
        id: 103901,
        name: `True 'Geshpenst'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103900.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103901.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103900.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103901.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 7200% Dark DMG (Physical).`,
                break: 1600
            },
        passive: 
            {
                ability1: `When equipped by Ragsherum, DMG 20% UP & Skill CT 50% DOWN (Only once).`,
                ability2: `All allies' ATK 15% UP (Only once).`
            },
        lore: `Ragsherum's trusted sword that never left his side when he tried to unseal the Demons in Gormarey. The sword cuts through its foes as if it has a mind of its own, soaking up any magical power from its victims to complete itself. Despite Ragsherum's passionate research, the "True" Geshpenst absorbed and perfected its master's moves without any intervention. Once reunited with its former master's armor, it will be an unstoppable force once again.`
    },
    { //Cursed Axe 'Hell Galgen' - Missing Lore
        id: 103911,
        name: `Cursed Axe 'Hell Galgen'`,
        location: `Side Quest [My Will, My Rage > The Masterless Dragon] Secret Equip`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103910.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103911.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103910.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103911.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 6666% Dark DMG (Magic).`,
                break: 600
            },
        passive: 
            {
                ability1: `Negates Poison. When equipped by a Dark Unit, increase own DMG as HP decreases. Reduces DMG from enemy (Only once)`
            },
        lore: `Missing Lore.`
    },
    { //Sacred Tome 'Truth of the Morning Star' - Complete
        id: 103921,
        name: `Sacred Tome 'Truth of the Morning Star'`,
        location: `Limited Event - The Twin Knights of the Morning Star (Regulus & Feena)`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103920.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103921.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103920.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103921.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 3800% Light DMG (Magic). Reduces Light Elemental Resistance of Dark Elemental Units by 20% for 10s.`,
                break: 600
            },
        passive: 
            {
                ability1: `Regenerates Own HP by 4/s.`
            },
        lore: `This ancient tome lain dormant long years in the depths of Lagan, the Royal Library of Famelle. It was mysteriously sealed, but they say that someone possessed of the power to unlock its secrets. It contains the story of the later days of the heroes known as the Twin Knights of the Morning Star. Who could have written this story of their final days, which only they could have known? And why can only the chosen one read what lies within? Those answers still lay ahead for our adventurers.`
    },
    { //Holy Shield 'Lux Feena' - Missing Lore
        id: 103931,
        name: `Holy Shield 'Lux Feena'`,
        location: `Limited Event - The Twin Knights of the Morning Star (Regulus & Feena)`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103930.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103931.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103930.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103931.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 6000% Light DMG (Magic). If User is Light Elemental, completely Evades all Status Ailments except for Faint for 8s.`,
                break: 800
            },
        passive: 
            {
                ability1: `Regenerates HP by 6/s.`
            },
        lore: `Missing Lore.`
    },
    { //True 'Centurion' - Complete
        id: 103941,
        name: `True 'Centurion'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103940.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103941.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103940.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103941.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 6200% Light DMG (Magic). Heals 30% of the DMG all allies took for 10s.`,
                break: 1000
            },
        passive: 
            {
                ability1: `When Equipped by Feena, increases own DMG by 20% and increases own Arts Gauge automatically (only once).`,
                ability2: `Increases Dark Elemental Resistance by 20%.`
            },
        lore: `This is the sacred sword of the Knight of Light Feena, one of the Twin Knights of the Morning Star. This weapon was truly appropriate, for though she was a Human she shone with brilliance equal to the Gods, and all who stood before her blazing light gave up their will to fight and bowed to her glory. She received this weapon in Famelle, but then the Gods blessed it and it was reborn as a divine weapon. For some reason, history books do not record its true value.`
    },
    { //Aqua Sword 'IZANAMI' - Missing Lore
        id: 103951,
        name: `Aqua Sword 'IZANAMI'`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103950.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103951.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103950.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103951.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 2200% Water DMG (Physical). 50% chance of Freezing enemy.`,
                break: 800
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //HIME-AJISAI - Complete
        id: 103961,
        name: `HIME-AJISAI`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103960.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103961.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103960.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103961.png`
            },
        stats:
            {
                hp: 500,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `80s CT - Heal all Allies' HP by 1800. Heals all Allies' Status Ailments except Faint or Disease.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The hair accessory that the swordswoman Yomi, who is said to come from a different world, wore. It is modeled after a flower that does not exist in Raktehelm, and added a fragile sadness to her mysterious image. To her, it was her formal dress and battle dress so she wore it to her important battles. The name "Aqua Lord" comes from the silence pressure she released when she wore this hair accessory held people in awe.`
    },
    { //Air Gun 'Ordonnanz' - Complete
        id: 103971,
        name: `Air Gun 'Ordonnanz'`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103970.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103971.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103970.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103971.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 4200% Light DMG (Magic). Increases all Allies' Light Elemental DMG Dealt by 15% for 6s.`,
                break: 600
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The aircraft that looks like a machine gun which Ameru, a girl who ran about the battlefields during the Ancient War, used. The principle of how it flew is not solved, but it is said that it used the power from the machine gun. It seems that this hypothesis is ridiculous, but the reason why this cannot be completely denied is because this gun could even destroy fortresses. It may be possible to blow a small girl easily.`
    },
    { //Ameru's Letter Bag - Complete
        id: 103981,
        name: `Ameru's Letter Bag`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103980.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103981.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103980.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103981.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `180s CT - Increases all Allies' DMG Inflicted by 25% and Critical Rate by 100% for 12s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The bag which Ameru, a girl who ran about delivering letters during the Ancient War, favored. The bag was full with love letters between different races. She was often attacked by mistake, but because of her Air Gun "Ordonnanz", the letters were never stolen. But she did lock her bag so only she could open it just in case. Her bag was hard enough to even swing around the bag and fight.`
    },
    { //Magic Rod 'Maleficium' - Missing Lore
        id: 103991,
        name: `Magic Rod 'Maleficium'`,
        location: `Limited Event - The Blue-eyed Black Fox (Melia)`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_103990.png`,
                detailmax: `/db/Equipment/Detail/item_detail_103991.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_103990.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_103991.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `110s CT - 9999% Dark DMG (Magic).`,
                break: 700
            },
        passive: 
            {
                ability1: `DMG 20% UP when enemy is Blind (Only once).`
            },
        lore: `Missing Lore.`
    },
    { //Magic Earrings 'Temptation' - Complete
        id: 104001,
        name: `Magic Earrings 'Temptation'`,
        location: `Limited Event - The Blue-eyed Black Fox (Melia)`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104000.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104001.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104000.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104001.png`
            },
        stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
        skillset:  
            {
                skill: `40s CT - Increases Target's Magic DMG, Equipment CT Speed, and Skill CT Speed by 20% for 12s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increases Blind Time Reduction by 20%. Increases Blind Evasion Rate by 20%.`
            },
        lore: `Melia ia a lady with constant rumors who is from Magis Empire of La Riiz. Being so attractive, countless men have fallen for her, and her earrings are fascinating as well. These glowing earrings emphasise her mysteriousness. It's not surprising, because a charm spell has been chanted on it. With Melia's one word, it activates its powers immediately. Not knowing this, countless men have whispered top secret information to her.`
    },
    { //Magic Robe 'Orbscuritas' - Missing Lore
        id: 104011,
        name: `Magic Robe 'Orbscuritas'`,
        location: `Limited Event - The Blue-eyed Black Fox (Melia)`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104010.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104011.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104010.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104011.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 6200% Dark DMG (Magic). Increases Arts gauge by 10 when HIT.`,
                break: 800
            },
        passive: 
            {
                ability1: `Blind Time Reduction & Blind evasion rate 30% UP.`
            },
        lore: `Missing Lore.`
    },
    { //True 'Valheight' - Complete
        id: 104021,
        name: `True 'Valheight'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104020.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104021.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104020.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104021.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `90s CT - 10800% Water DMG (Physical). When equipped by a Human, Arts DMG increases 50% once, then Arts Gauge decreases for 10s.`,
                break: 1000
            },
        passive: 
            {
                ability1: `When Equipped by Est, increases own DMG and Break by 20%.`,
                ability2: `Increases Physical DMG by 15% (only once).`
            },
        lore: `When Est, the daughter of the "Holy Swordmaster" came to understand her lineage and destiny, her guardian angel shows its true form. "Allow me to turn all matter to your trusted blade," so says this armor. Faced with the immense power of this armor and the cruel inability to harness it, Est pushed herself well past her limit, proving her likeness to her mother along her path.`
    },
    { //Relic 'Hrungnir' - Complete
        id: 104031,
        name: `Relic 'Hrungnir'`,
        location: `Rotating Giant Boss - Frozen Calamity in the North (O’zagun)`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104030.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104031.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104030.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104031.png`
            },
        stats:
            {
                hp: 125,
                atk: 62,
                def: 62
            },
        skillset:  
            {
                skill: `65s CT - 500% Water DMG (Physical). Increases all Allies' Break Power by 30% for 6s.`,
                break: 2000
            },
        passive: 
            {
                ability1: `Increases own ATK by 10% (only once).`
            },
        lore: `This massive hammer was used by the Ice Fortress Geo Zagnus, known as the Polar Calamity. It boasts incredible durability, as it was made to withstand the Giant's incredible strength as it destroyed all it saw, even hammering massive glaciers to dust. As it was long wielded by a Giant bearing the magical power of Absolute Zero, it possesses the power to instantly freeze anything that draws near. If the crushing blow doesn’t annihilate its target, the frigid wind and shock of its passing will freeze enemies to ice and shatter them.`
    },
    { //Relic 'Bracchium' - Complete
        id: 104041,
        name: `Relic 'Bracchium'`,
        location: `Rotating Giant Boss - Frozen Calamity in the North (O’zagun)`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104040.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104041.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104040.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104041.png`
            },
        stats:
            {
                hp: 250,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `250s CT - Revives all Allies at 30% HP. Increases all Allies' Arts Gauge by 20. Increases all Allies' DMG Inflicted by 10% for 20s`,
                break: 0
            },
        passive: 
            {
                ability1: `Increases own ATK by 10% (only once).`
            },
        lore: `These gauntlets were worn by the Ice Fortress Geo Zagnus, Giant of Absolute Zero. The Giant's life was dominated by destruction, and so it avoided danger by pure instinct. It may sound like a mindless berserker, but it still made sure to wear mighty armor. It could block any attack with these gauntlets, and even if it was cut the bitter cold of these gauntlets would freeze the wound shut. The only way to fell this Giant is to completely shatter its powerful armor.`
    },
    { //True 'Maleficium' - Complete
        id: 104051,
        name: `True 'Maleficium'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104050.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104051.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104050.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104051.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `90s CT - 130000% Dark DMG (Magic).`,
                break: 1200
            },
        passive: 
            {
                ability1: `When equipped by Melia (Dark), DMG 20% UP & HP Auto-recovery to all allies by 10 (Only once).`,
                ability2: `DMG 20% UP when enemy is Blind (Only once).`
            },
        lore: `The rod especially made for fighting that Melia, the vice-commander of the La Riiz 3rd Magus Division, uses. She had tried not to use her dark magic as much as she could because she sealed off her dark past. However, once she decided to prove worthy to her friends, the rod started to answer her will and released its power. As a result, she hardly needs to chant spells and made it possible to use Anti-Army magic over and over which normal people cannot do.`
    },
    { //Divine Sword 'Balmung' - Complete
        id: 104131,
        name: `Divine Sword 'Balmung'`,
        location: `Side Quest: The Five Freedom Fighters - The Dawn of Raktehelm`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104130.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104131.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104130.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104131.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `25s CT - 4500% Light DMG (Physical). 30% chance of Paralyzing enemy.`,
                break: 1200
            },
        passive: 
            {
                ability1: `DMG to Demons 20% UP.`
            },
        lore: `One of the twin blades used by Freed, noble son of Isliid and leader of the Five Freedom Fighters. Early in the Ancient War, this holy sword was made during a brief period when the Demons and Gods came together in cooperation. They say this sword chooses its master, and only that person can call on its true power. Success at unleashing that power would create a divine weapon of purest light. The story of how it became an heirloom in Freed's family draped in mystery.`
    },
    { //Cursed Sword 'Gram' - Complete
        id: 104141,
        name: `Cursed Sword 'Gram'`,
        location: `Side Quest: The Five Freedom Fighters - The Dawn of Raktehelm`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104140.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104141.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104140.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104141.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 6000% Dark DMG (Physical). Increases all allies' CRI rate by 30% for 6s.`,
                break: 1000
            },
        passive: 
            {
                ability1: `DMG to Gods 20% UP.`
            },
        lore: `One of the twin blades wielded by Freed, leaderof the mysterious terrorist group that rocked the world of Raktehelm, the Five Freedom Fighters. This blade is the dark twin of the divine sword made by the Gods early in the Ancient War. It holds the evil power to convert the bearer's very life force into power, but it will consume the very soul of any deemed unworthy to touch it. When this cursed sword's true potential is unleashed, it can pierce the walls of space and time to create a dimensional rip that consumes all around it.`
    },
    { //Magic Mask 'Gestalt' - Complete
        id: 104151,
        name: `Magic Mask 'Gestalt'`,
        location: `Side Quest: The Five Freedom Fighters - The Dawn of Raktehelm`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104150.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104151.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104150.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104151.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 5500% Dark DMG (Magic). 30% chance of Blinding enemy.`,
                break: 900
            },
        passive: 
            {
                ability1: `DMG to Gods 20% UP.`
            },
        lore: `This is the unbridled form of the mask Grohl, the La Riiz assassin who slew the terrorists of the Five Freedom Fighters, wore over his face. It was designed to dramatically boost the natural abilities of the wearer, but due to his uncommon bloodline, it called up unimaginable power. This mask was consumed by the incredible power he unleashed and became a demonic tool to channel his limitless power into pure destruction.`
    },
    { //Fire Relic 'Bible of the Flaming Knight' - Complete
        id: 104171,
        name: `Fire Relic 'Bible of the Flaming Knight'`,
        location: `Earth Elemental Ruins F6 Stage Clear Reward`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104170.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104171.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104170.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104171.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 7500% Fire DMG (Physical). Increases all allies' Skill CT speed by 80% for 8s.`,
                break: 1200
            },
        passive: 
            {
                ability1: `Increases all Fire Elemental Allies' stats by 20% (Only once).`,
                ability2: `Flaming Knight Returns: "When the Flaming Knight returns, the power will..."`
            },
        lore: `In this bible, anecdotes of a fallen country which praised flames as god. There was only one knight who protected this country. He wore red armor and coat, and with one slash of his sword he could burn away his enemies. On the back cover it says "When the Flaming Knight returns the power will..." but the rest cannot be read. Mysterious powers are flowing from it, but no one knows how to truly use it.`
    },
    { //Water Relic 'Aqua Dragon's Quartz' - Complete
        id: 104181,
        name: `Water Relic 'Aqua Dragon's Quartz'`,
        location: `Fire Elemental Ruins F6 Stage Clear Reward`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104180.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104181.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104180.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104181.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `70s CT - Increases all Water Elemental units' Arts Gauge by 10 for 5s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increases all Water Elemental Allies' stats by 20% (Only once).`,
                ability2: `True Value of the Blue Crystal: "The crystal remains silent..."`
            },
        lore: `This was given powers of the legendary Aqua Dragon that controls the oceans. It is clear that the country which praised the ocean as the origin of life possessed this treasure in ancient times. Its powers are said to overwhelm all countries, but ti can only be used when the three shining weapons gather. However, it is too much of a masterpiece for Humans so it will take extraordinary time to solve all the mysteries.`
    },
    { //Earth Relic 'Gran Forde' - Complete
        id: 104191,
        name: `Earth Relic 'Gran Forde'`,
        location: `Water Elemental Ruins F6 Stage Clear Reward`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104190.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104191.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104190.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104191.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 6000% Earth DMG (Magic). Increases Accuracy rate and Evasion rate by 20% for 8s.`,
                break: 1200
            },
        passive: 
            {
                ability1: `Increases all Earth Elemental Allies' stats by 20% (Only once).`,
                ability2: `Territory of Verdure: The main part of a machine. It does not work by itself.`
            },
        lore: `It is some king of machine parts found in the Aqua ruins. Tremendous power flows from it. There seems to be three parts to the machine, but no one knows what it is or what it is used for. The parts for connection are not found thus it is difficult to understand the structure of it. If all parts are found, will this mystery be solved?`
    },
    { //Holy Night of Fenrir - Complete
        id: 104201,
        name: `Holy Night of Fenrir`,
        location: `Limited Event - Rhapsody of Christmas Eve`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104200.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104201.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104200.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104201.png`
            },
        stats:
            {
                hp: 120,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - Heals all Allies' HP by 10%. Heals all Allies' Status Ailments except for Faint and Disease.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `After hearing about the otherworld festival of Christmas, Roy had his 3rd "Fenrir" division of the Imperial Knights of Isliid dress up an0d give out presents to children. In that other world, Christmas is considered a holy night, so out of respect for the night he prepared a special crest. While the Fenrir knights are out distributing present, the captain and his childhood friend the adventurer went to their orphanage.`
    },
    { //Stuffed Merry Rolly - Complete
        id: 104211,
        name: `Stuffed Merry Rolly`,
        location: `Limited Event - Rhapsody of Christmas Eve`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104210.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104211.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104210.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104211.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `5s CT - Break Mode: Increases all Allies' Break Power by 30% for 10s. Heal Mode: Heals all Allies' HP by 20 for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `This is a stuffed toy Iris brought to the Firecat's Den party for Christmas, an otherworld festival. Iris herself made everything, apparently, and now it sits right alongside the Ru doll like they're best friends watching over the party. When Rolly saw it, though, he was clearly bothered. It turns out he thought it very odd that the doll had a present while the real Rolly did not, and was unhappy with the situation. And when Rayas tried to explain, he was answered only with a swift kick.`
    },
{ //Liese' Santa Dress - Complete
        id: 104221,
        name: `Liese' Santa Dress`,
        location: `Limited Equip Summon: Christmas Banner`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104220.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104221.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104220.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104221.png`
            },
        stats:
            {
                hp: 500,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `70s CT - Heals all Allies' HP by 240/s for 10s. Negates Flinch for all Allies for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `This is a dress Reigrad's Priestess Liese chose to celebrate Christmas, an otherworld festival. Liese was filled with envy seeing young Iris racing through the streets in her Santa suit. The chef saw her trying to keep those feelings bottled up, and took her to see the Alchemist out of pity. The outfit started out nice and warm to keep her from catching a cold, but Liese' teary puppy-dog eyes convinced her to make this dress.`
    },
{ //Iris' Santa Hat - Complete
        id: 104231,
        name: `Iris' Santa Hat`,
        location: `Limited Equip Summon: Christmas Banner`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104230.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104231.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104230.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104231.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 4200% Light DMG (Magic). Reduces Enemies' Freeze Resistance by 30% for 8s.`,
                break: 1500
            },
        passive: 
            {
                ability1: ``
            },
        lore: `This is a hat Iris chose for a party celebrating Christmas, an otherworld festival. She rushed to the Alchemist and burst in to show her all about the person Santa Claus in her picture book. When she was done talking, the Alchemist put this hat on her head. Iris was clearly overjoyed and went rushing out, but soon came back asking for a matching dress.`
    },
{ //Iris' Santa Dress - Complete
        id: 104241,
        name: `Iris' Santa Dress`,
        location: `Limited Equip Summon: Christmas Banner`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104240.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104241.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104240.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104241.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `30s CT - Negates 1000 DMG Dealt to all Allies. Increases all Allies' Healing Amount by 30% for 8s (only once).`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `This is a dress Iris had made for a party celebrating Christmas, an otherworld festival. When she puts it on with a matching hat, she truly embodies the spirit of Christmas. She put a bunch of sweets in a sack the Guildmaster gave her and went out around town. Whenever she saw any children, she'd run over shouting Merry Christmas, then give them a treat. Later that evening, there was a lovely Christmas Party.`
    },
{ //Merry Rolly Hat - Complete
        id: 104251,
        name: `Merry Rolly Hat`,
        location: `Limited Equip Summon: Christmas Banner`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 3,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104250.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104251.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104250.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104251.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 60
            },
        skillset:  
            {
                skill: `30s CT - Reduces all Allies' DMG Taken by 5% and Light Elemental DMG Taken by 10% for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `This is a hat that Rayas made for the Firecat's Den party for Christmas, an otherworld festival. It was so cute, it was a particular hit with Iris. The others all had a laugh acting like Rayas was actually Rolly. However, when the real Rolly showed up, his face showed only astonishment, which soon turned to enraged trembling, a low growl, and a full-on attack on the poor adventurer.`
    },
{ //Shurahalt Tree - Missing Lore
        id: 104261,
        name: `Shurahalt Tree`,
        location: `Limited Equip Summon: Christmas Banner`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104260.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104261.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104260.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104261.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 3800% Fire DMG (Physical).`,
                break: 2500
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
{ //Holy Night Sword 'Charlotte' - Complete
        id: 104271,
        name: `Holy Night Sword 'Charlotte'`,
        location: `Limited Equip Summon: Christmas Banner`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104270.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104271.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104270.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104271.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 8000% Light DMG (Physical).`,
                break: 100
            },
        passive: 
            {
                ability1: ``
            },
        lore: `This is a sword just so the Santa Claus that was supposed to surprise Iris wouldn't have to go empty-handed. Despite all the care taken before preparations, though, the secret was out and so Santa ended up just going along with the others to the Christmas party. The people of Reigrad all gathered at the Firecat's Den, and no one seemed to notice that the gentleman this adorable sword was a grizzled veteran of countless battles. Oh, and apparently his granddaughter was in charge of decorating the blade.`
    },
{ //Toy Santa Ru - Complete
        id: 104281,
        name: `Toy Santa Ru`,
        location: `Limited Equip Summon: Christmas Banner`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 3,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104280.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104281.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104280.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104281.png`
            },
        stats:
            {
                hp: 30,
                atk: 15,
                def: 15
            },
        skillset:  
            {
                skill: `30s CT - Increases Target's BE Output by 50% and Skill CT Speed by 80% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `This is a stuffed animal put up as a decoration at the Firecat's Dan to celebrate the otherworld festival of Christmas. The more you look, the more it looks just like Ru... enough that it convinced Rayas to try talking to it. This led to a fun little game where everyone talked to the doll to get the real deal's goat. The toy's clothing is made from top-quality materials, and it has been clearly will cared for... yet no one knows who left it behind.`
    },
{ //Basilisk Wreath - Complete
        id: 104291,
        name: `Basilisk Wreath`,
        location: `Limited Equip Summon: Christmas Banner`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104290.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104291.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104290.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104291.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 6250% Dark DMG (Magic). 100% chance of Poisoning a Human type enemy. If user is Dark Elemental, increases Arts gauge by 20 for each Enemy Unit hit.`,
                break: 1200
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Some kind of decoration for the otherworld festival of Christmas... Apparently? The adventurers decorated the Firecat's Den based on a picture book they found. Iris wanted a decoration known as a "wreath" but there was no time to track down the appropriate plants. The young girl seemed quite sad, but soon perked up and came running in saying she'd found the perfect thing. Everyone grew pale at the sight, but no one had the heart to ask where she'd got it.`
    },
{ //True 'Siegfried' - Missing Lore
        id: 104301,
        name: `True 'Siegfried'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104300.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104301.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104300.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104301.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `3s CT - 900% Non-Elemental DMG (Physical). Heals HP by 50 when HIT. Reduces own DEF by 20% for 3s. If enemy is God or Demon, 1200% DMG.`,
                break: 240
            },
        passive: 
            {
                ability1: `When equipped by Freed, own stats 30% UP & will not Flinch (Only once).`,
                ability2: `Arts DMG 20% UP. If God, reduces own Equipment CT by 20% (Only once).`
            },
        lore: `Missing Lore.`
    },
{ //True 'Gestalt' - Complete
        id: 104311,
        name: `True 'Gestalt'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104310.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104311.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104310.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104311.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 4444% Dark DMG (Magic). If this attack hits a Boss with Status Ailments, steal 20 Arts gauge from it. Reduce Enemies' Blind resistance by 50% for 6s.`,
                break: 1000
            },
        passive: 
            {
                ability1: `When equipped by Grohl, increase DMG by 20%, and heal HP by 200/s when inflicted by a status ailment (Only once).`,
                ability2: `Increase DMG by 20% to Bosses with less than 30% HP (Only once).`
            },
        lore: `Missing Lore.`
    },
{ //Holy Armor 'Eldrange' - Complete
        id: 104321,
        name: `Holy Armor 'Eldrange'`,
        location: `Limited Event - The Other Battle (Celia)`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104320.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104321.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104320.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104321.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `30s CT - Reduces Target's DMG Taken by 50% for 6s. Heals Target for 10% of DMG Taken for 6s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Auto-Recovery for 20 HP. Increases ATK by 30% of DEF (only once).`
            },
        lore: `The armor especially made for Celia, the next monarch of the Principality of Bamint. It was made elegant, so she could wear it during official work. At first glance, it might seem undependable, but thousands of protection magic is woven within the armor. It is also called "Angel Wings" for it veils her in a thin and shining power.`
    },
{ //Princess Bamint - Complete
        id: 104331,
        name: `Princess Bamint`,
        location: `Limited Event - The Other Battle (Celia)`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104330.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104331.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104330.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104331.png`
            },
        stats:
            {
                hp: 120,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `130s CT - Adds a token to revive Target Unit with 20% of HP for 120s (only once).`,
                break: 0
            },
        passive: 
            {
                ability1: `Increases Own Heal Amount by 15%.`
            },
        lore: `Only Celia, the first in line to the throne of the Principality of Bamint is allowed to wear this tiara. While it is a work of art made from the best and rare crystals, it is also a noble weapon the contains enormous magic. With her potential magic powers, magical armor, and this tiara her defense becomes as if she herself is a barricade. Weapon attacks will be rejected, and all magic will stand no chance.`
    },
{ //Magical Sword 'Siegel' - Complete
        id: 104341,
        name: `Magical Sword 'Siegel'`,
        location: `Side Quest: The Five Freedom Fighters - Last Wishes Passed to the Future`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104340.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104341.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104340.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104341.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 120
            },
        skillset:  
            {
                skill: `35s CT - 3800% Dark DMG (Physical). Reduces all Allies' DMG Taken by 20% for 8s.`,
                break: 700
            },
        passive: 
            {
                ability1: `Reduces Physical DMG Taken by 10%.`
            },
        lore: `This is a short sword used by Onfuan, one of the Five Freedom Fighters. He was once a member of La Riiz' intelligence corps, and they say he started using this blade after he left service. This blade was once apparently the tool of a sorcerer, and now it gives the bearer great power over barrier magic. Magical power courses through the chain attached to the hilt, and when thrown the blade moves like a thing alive to hunt down its target. Once bound by this chain, it's nearly impossible to escape on your own.`
    },
{ //Magical Hat 'Akashic Eye' - Complete
        id: 104351,
        name: `Magical Hat 'Akashic Eye'`,
        location: `Side Quest: The Five Freedom Fighters - Last Wishes Passed to the Future`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104350.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104351.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104350.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104351.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 6500% Earth DMG (Magic). Increases own Arts gauge by 30 when HP is 50% or more.`,
                break: 800
            },
        passive: 
            {
                ability1: `Magic DMG 20% UP.`
            },
        lore: `This is a hat worn by the mysterious sorcerer Alma. The strangely glowing stone is unlike any known type of crystal, and no one has been able to explain the structure. There are those who say that the stone "remembers" all that it has seen. The rumors go on to say that these etched memories go back far beyond the Ancient War, to a past lost to the shades of time. There are none alive who know how long this hat has existed, making it yet one more of the many deep mysteries surrounding the witch Alma.`
    },
{ //Inheritance 'Fatalite' - Complete
        id: 104361,
        name: `Inheritance 'Fatalite'`,
        location: `Limited Event - The Blade Master's Purpose (Riana)`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104360.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104361.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104360.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104361.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `30s CT - 3400% Water DMG (Physical). If Human, increases Skill DMG by 25% after using this Equipment (First time only).`,
                break: 800
            },
        passive: 
            {
                ability1: `Equipment CRI rate 20% UP (Only once).`
            },
        lore: `A sword handed down among the Sword Master dynasty, currently wielded by Riana. Th eonly one of its kind in the world, it symbolizes both her title and her superior technique. S single foundational element makes up this weapon, and those who unlock its true power can manipulate that element. However, none but Riana's founding ancestor and Riana herself have done so.`
    },
{ //Divine Rapier 'Vandring' - Complete
        id: 104371,
        name: `Divine Rapier 'Vandring'`,
        location: `Limited Event - The Blade Master's Purpose (Riana)`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104370.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104371.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104370.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104371.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `30s CT - 5250% Water DMG (Physical).`,
                break: 400
            },
        passive: 
            {
                ability1: `Skill CT speed 15% UP.`
            },
        lore: `A holy blade wielded by the globetrotting Sword Master, Riana. Everywhere she went, she was remembered as a priestess who cleansed the land of miasma, but details, such as the weapon she held, were scarce. The most anyone could deduce was its divine quality, since she was never seen without it. At last, someone worked up the courage to speak with her, and she shared its name. Never did she notice it was a metaphor for her own life.`
    },
{ //Blessed Garb 'Autel' - Complete
        id: 104381,
        name: `Blessed Garb 'Autel'`,
        location: `Limited Event - The Blade Master's Purpose (Riana)`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104380.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104381.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104380.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104381.png`
            },
        stats:
            {
                hp: 120,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - Recovers targets' HP by 2200.`,
                break: 0
            },
        passive: 
            {
                ability1: `CRI DMG 10% UP (Only once).`
            },
        lore: `The outfit of the Sword Master cursed with perennial youth, Riana. Thougb ordinary in appearance, it protects the wearer with holy power, neutralizing evil influences of every kind on contact. As she must enter spiritually hazardous areas in order to absorb miasma, this lets her do so without fear.`
    },
{ //True 'Fatalite' - Missing Lore
        id: 104391,
        name: `True 'Fatalite'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104390.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104391.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104390.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104391.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `25s CT - 4200% Water DMG (Physical). If Human, increases Skill DMG & Skill Break Power by 25% after using Equipment (Only once).`,
                break: 1000
            },
        passive: 
            {
                ability1: `When equipped by Riana, DMG 20% UP & Skill CT 20% DOWN (Only once).`,
                ability2: `Equipment CRI rate 20% UP (Only once).`
            },
        lore: `Missing Lore.`
    },
{ //True 'Joyeuse' - Complete
        id: 104401,
        name: `True 'Joyeuse'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104400.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104401.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104400.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104401.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `70s CT - 8000% Light DMG (Physical). For 8s, ignore 50% of enemies' DEF, and increase DMG resistance by 30%.`,
                break: 1200
            },
        passive: 
            {
                ability1: `When equipped by Celia (Light), DMG 20% UP. Paralyze & Freeze resistance 100% UP (Only once).`,
                ability2: `MAX HP 1000 UP & DMG from enemy 10% DOWN when Near-Death (Only once).`
            },
        lore: `Only Celia, the first in line to the throne of the Principality of Bamint is allowed to use this sword. This promises her position as a princess and means that she was the chosen one. The sword always watches over the owner and at times even reveals even stronger owner. The sword will answer her feelings and make her a true hero through her high desires and achievement.`
    },
{ //True 'Alchemia' - Complete
        id: 104411,
        name: `True 'Alchemia'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104410.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104411.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104410.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104411.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `56s CT - 7200 Dark DMG (Magic). Increases own BE output by 50% & reduces enemy's ATK and DEF by 20% for 5s.`,
                break: 700
            },
        passive: 
            {
                ability1: `When equipped by Rem, DMG 20% UP & Rem's ability "Alchemy" UP (Only Once).`,
                ability2: `Magic DMG 10% UP & Auto-fills Arts gauge when Near-Death (Only Once).` 
            },
        lore: `The rod that Rem, the Alchemist who always supports adventurers, uses. This is said to be her masterpiece. Using all the rare material she gathered ever since she became a sorcerer and alchemist, this rod is the closest existence to the "Philosopher's Stone". The only difference is that she made it to be used to save the world.`
    },
{ //Relic 'Periusia' - Complete
        id: 104421,
        name: `Relic 'Periusia'`,
        location: `Giant Boss - Crimson Flames of the Phoenix (Flogassa)`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104420.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104421.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104420.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104421.png`
            },
        stats:
            {
                hp: 250,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - Heals Target's HP by 3000. If User is Fire Elemental then Unit evades Paralyze for 5s.`,
                break: 0
            },
        passive: 
            {
                ability1: `If User is Fire Elemental then HP regenerates by 20 (only once).`
            },
        lore: `A compound that forms inside Ogrone Volcano's master, the giant 'Flogassa'. Much like blood to mammals, it traverses its flaming crimson body. This relic is a miracle of nature, magic in liquid form. Untouched, it can be consumed as an elixir. To consume, it must be inhaled in an instant as it evaporates upon touching their air.`
    },
{ //Relic 'Claritus' - Complete
        id: 104431,
        name: `Relic 'Claritus'`,
        location: `Giant Boss - Crimson Flames of the Phoenix (Flogassa)`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104430.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104431.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104430.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104431.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 60
            },
        skillset:  
            {
                skill: `30s CT - Negates 400 DMG Dealt to all Allies' for 8s. Reduces all Allies' Fire Elemental DMG Taken by 35% for 8s (only once).`,
                break: 0
            },
        passive: 
            {
                ability1: `If User is Fire Elemental then HP regenerates by 20 (only once).`
            },
        lore: `This armor cast of rare metal and the feathers of the giant 'Flogassa' a bird said to embody the great Ogrone Volcano. The feathers provide its owner a magic barrier, which the same effect is achieved in this armor as well. However, to control this energy it consumes tremendous amounts of this relic, which has made mass production impossible.`
    },
{ //Blue Life Sword 'Glamerald' (碧命剣『グラメラルド』) - JP Complete
        id: 104441,
        name: `碧命剣『グラメラルド』`,
        translate: `Blue Life Sword 'Glamerald'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104440.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104441.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104440.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104441.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 5200% Earth DMG (Physical). Increase all Earth allies' Break Power by 30% for 8s.`,
                break: 2400
            },
        passive: 
            {
                ability1: `Evasion rate 10% UP when Near-Death.`
            },
        lore: `Missing Lore.`
    },
{ //Blue Life Bow 'Eldrasta' (碧命弓『エルドラスタ』) - JP Complete
        id: 104451,
        name: `碧命弓『エルドラスタ』`,
        translate: `Blue Life Bow 'Eldrasta'`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104450.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104451.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104450.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104451.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `20s CT - 700% Earth DMG (Magic).`,
                break: 1500
            },
        passive: 
            {
                ability1: `CRI DMG 10% UP.`
            },
        lore: `Currently unreleased in Global.`
    },
{ //True 'Glamerald' - Missing Lore
        id: 104461,
        name: `True 'Glamerald'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104460.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104461.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104460.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104461.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 7200% Earth DMG (Physical). Increases Earth units' Break Power by 30% for 8s.`,
                break: 2400
            },
        passive: 
            {
                ability1: `When equipped by Vogue, DMG 20% UP & Physical DMG from Enemy 15% DOWN (Only once).`,
                ability2: `Evasion rate 10% UP when Near-Death.`
            },
        lore: `Missing Lore.`
    },
{ //True 'Peluscia' - Complete
        id: 104471,
        name: `True 'Peluscia'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104470.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104471.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104470.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104471.png`
            },
        stats:
            {
                hp: 500,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 7000% Non-Elemental DMG (Magic). Heals own HP by 150 when Hit.`,
                break: 1200
            },
        passive: 
            {
                ability1: `When Equipped by Number 2, increases DMG by 20% and increases BE from Skill by 50% (only once).`,
                ability2: `Increases Own Status Ailment (not Faint) Time Reduction and Evasion Rate by 20%`
            },
        lore: `The circlet which Iris' clone "Number 2" wears to control her abilities. When the magic stones shine, it signifies the attempt to read her thoughts, as she supposedly has none. Ever since a certain battle, the stone has flickered with light, releasing more power than imagined.`
    },
{ //Magic Spear 'Graofang' (魔槍『グラオファング』) - JP Complete
        id: 104481,
        name: `魔槍『グラオファング』`,
        translate: `Magic Spear 'Graofang'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104480.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104481.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104480.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104481.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `55s CT - 6800% Dark DMG (Physical). Increase own Skill CT speed by 30% for 12s.`,
                break: 1000
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
{ //Flame Staff 'Terenolio' (焔精杖『テレノライオ』) - JP Complete
        id: 104491,
        name: `焔精杖『テレノライオ』`,
        translate: `Flame Staff 'Terenolio'`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104490.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104491.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104490.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104491.png`
            },
        stats:
            {
                hp: 500,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - Heal all allies' HP by 1200. If user is Fire, heal all allies' HP by 1800 instead.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
{ //Protection Suit 'Viper' - Complete
        id: 104501,
        name: `Protection Suit 'Viper'`,
        location: `General Equip Banner`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104500.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104501.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104500.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104501.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `30s CT - Reduces all allies' DMG taken by 20% & reduces Earth DMG by 30% for 12s. Increases Poison & Paralyze resistance by 100% for 12s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The lab coat worn by Keit, a mad scientist from the early days of the Ancient War. He was obsessed with changes in biological composition, so he needed something that could protect him from the various poisons he used in his experiments. Though he did not want to divide his attention from his research, the genius actually created this protective gear in no time. It probably would have made him rich if he had made more to sell, but money was of no interest to him.`
    },
{ //Light Relic 'Tin Cane of Relief' - Complete
        id: 104511,
        name: `Light Relic 'Tin Cane of Relief'`,
        location: `Darj Elemental Ruins F6 Stage Clear Reward`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104510.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104511.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104510.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104511.png`
            },
        stats:
            {
                hp: 500,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 4000% Light DMG (Magic). Heals all Light Elemental Allies by 200/s for 20s.`,
                break: 600
            },
        passive: 
            {
                ability1: `Increases all stats on all Light Elemental Unit Allies by 20% (only once).`,
                ability2: `Pilgrimage to Reproduction: "It softly glows..."`
            },
        lore: `A light relic of a fallen nation that believed that light would save everyone. It is said that when the Cane touches the ground it vanishes all evil with noble sound. This relic is the proof of the promise of the Gods that, when the world will reach its limits, they will show the way to destruction and resurrection. When the three shining relics awaken, the Tin Cane will play its role.`
    },
{ //Dark Relic 'Nichts' - Complete
        id: 104521,
        name: `Dark Relic 'Nichts'`,
        location: `Light Elemental Ruins F6 Stage Clear Reward`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104520.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104521.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104520.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104521.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 5500% Dark DMG (Magic). Dark Elemental Allies ignore Enemies' DEF by 50% for 8s.`,
                break: 900
            },
        passive: 
            {
                ability1: `Increases all Dark Elemental Allies' stats by 20% (only once).`,
                ability2: `When allies are equipped with 4 Dark Relic Equipment. Dark allies' DMG & equip CT speed 20% UP (Only Once).`,
                ability3: `Chain of Tragedy: "Blood is all over it and it seems you cannot use it as it is."`
            },
        lore: `The earrings possessed by the king of the sect that used darkness to tyrannize over people. Some countries even banned to raise his name. Thus, he is known as "The Nameless" and is remembered as a symbol of terror. Among the anecdotes, the one about the "Three Devils" who swore loyalty to him is especially famous, and their relics still sleep somewhere out there.`
    },
{ //Cursed Light Sword 'Anestra'- Complete
        id: 104591,
        name: `Cursed Light Sword 'Anestra'`,
        location: `Raid Equip (Auge)`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104590.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104591.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104590.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104591.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `10s CT - 2300% Light DMG (Physical). Increases own Arts gauge by 5.`,
                break: 500
            },
        passive: 
            {
                ability1: `Increases Critical Rate and Critical DMG by 10%.`
            },
        lore: `This cursed dagger gives off a blinding light. It embodies both light and dark, and they say the line between the two is thinner than paper. The blade shifts properties constantly due to an unstable dimensional wap within it. The light it carries is not that of purity, but born of darkness. The tension of that contradiction leads to incredible physical instability, meaning that although this is a small dagger it carries the power to annihilate all that it touches. It truly is a cursed blade.`
    },
{ //Rallying Banner 'Gramenia' - Complete
        id: 104601,
        name: `Rallying Banner 'Gramenia'`,
        location: `Limited Event - Distant Prospect (Kane)`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104600.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104601.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104600.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104601.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `30s CT - Increases all allies' Equipment CT speed by 20% & Skill CT speed by 50% for 8s.`,
                break: 0
            },
        passive: 
            {
                ability1: `HP Auto-recovery by 30 when Near-Death.`
            },
        lore: `The glossy flag that flies over the Gramenian army camp. Made of fireproof and bulletproof material, it supports the soldiers' morale no matter how the tide turns. The sight of the gleaming crest reminds them of their homeland's glory and prestige, filling them with vigor. The president's fair rule is key, but the will of its founders carried on through the ages is no less important.`
    },
{ //Patriot Rifle 'Glorious' - Complete
        id: 104611,
        name: `Patriot Rifle 'Glorious'`,
        location: `Limited Event - Distant Prospect (Kane)`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104610.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104611.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104610.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104611.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 6200% Earth DMG (Magic). 60% chance of Paralyzing enemy.`,
                break: 800
            },
        passive: 
            {
                ability1: `If user is a Human, Arts DMG 10% UP & Arts DMG taken 15% DOWN (Only once).`
            },
        lore: `The firearm owned by Kane, the Republic of Gramenia's president and Fen's boss. He is never seen without it on the battlefield. With overwhelming strength combined with magically enhanced and trajectory correcting, his targets have no hope of survival. It increases his own innate capabilities to make him a truly matchless soldier.`
    },
{ //Combat Knife 'Vixen' - Complete
        id: 104621,
        name: `Combat Knife 'Vixen'`,
        location: `Limited Event - Distant Prospect (Kane)`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104620.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104621.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104620.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104621.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 3200% Earth DMG (Physical). If user is a Human, increases ATK by 30% for 8s.`,
                break: 500
            },
        passive: 
            {
                ability1: `DMG to Demons 20% UP.`
            },
        lore: `A dagger that Kane, the eccentric Gramenian official, always keeps hidden on himself. Despite firearms being his strongest suits, he is also trained in several martial arts. Even a humble knife such as this becomes a weapon of mass destruction in his hands. Its name, however, has a less threatening origin: "Nothing I'd want by my side more than a lovely lady.`
    },
{ //True 'Glorious' - Missing Lore
        id: 104631,
        name: `True 'Glorious'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104630.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104631.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104630.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104631.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 7200% Earth DMG (Magic). 80% chance of Paralyzing enemy.`,
                break: 1200
            },
        passive: 
            {
                ability1: `When equipped by Kane (Earth), DMG 20% UP. Paralysis & Freeze resistance 100% UP (Only once).`,
                ability2: `If user is a Human unit, Arts DMG 10% UP & Arts DMG taken 15% DOWN (Only once).`
            },
        lore: `Missing Lore.`
    },
{ //Blade 'Dragon Age' - Missing Lore
        id: 104641,
        name: `Blade 'Dragon Age'`,
        location: `Rotating Event - The Dragon General's Sword (Rohst)`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104640.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104641.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104640.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104641.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `95s CT - 9500% Dark DMG (Physical).`,
                break: 700
            },
        passive: 
            {
                ability1: `Equipment CT 10% DOWN.`
            },
        lore: `Missing Lore.`
    },
{ //Armor 'Dragon Scale' - Complete
        id: 104651,
        name: `Armor 'Dragon Scale'`,
        location: `Rotating Event - The Dragon General's Sword (Rohst)`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104650.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104651.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104650.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104651.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 120
            },
        skillset:  
            {
                skill: `50s CT - Reduces all Allies' DMG Taken by 25% for 8s. Increases all Allies' Blind and Curse Resistance by 50% for 8s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increases Dark Elemental Resistance by 10%.`
            },
        lore: `The armor that protected Rohst, a Dragon general whose name echoes through history. This armor, made from tempered Dragon God scales, is a rarity only he could have borne. Even when he was finally wounded by one bearing the legendary Dragon Killer blade, this armor kept him safe, and his relentless attack went unstopped. It could be said that this armor, which protected the brave general through battles uncounted, is a true symbol of his unbending spirit.`
    },
{ //Relic 'Sword of Revenge' - Complete
        id: 104661,
        name: `Relic 'Sword of Revenge'`,
        location: `Limited Event - Divine Spirit of Remembrance (Marzex)`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104660.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104661.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104660.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104661.png`
            },
        stats:
            {
                hp: 150,
                atk: 150,
                def: 150
            },
        skillset:  
            {
                skill: `70s CT - 5000% Non-Elemental DMG (Physical). Increases DMG to all races by 15% for 8s (Except Gods).`,
                break: 500
            },
        passive: 
            {
                ability1: `ATK & DEF gradually UP for 90s (MAX 30%, Only once).`
            },
        lore: `Darkness wielded by the Harbinger of Armageddon Marzex, a manifestation of the hatred that consumed him, one of the great Ten Divine Forces after he was defeated by an adventurer. Its owner was once judge of the sinful, but when he was overcome with malice he himself turned to the way of sin, and this sword is a manifestation of that turn from dark to light. Its very presence is a stain on the world. the stain will continue to grow until it covers all the world. A casual swing of this blade could doom the world to darkness.`
    },
{ //Fire Relic 'Sword of the Flaming Knight' - Complete
        id: 104671,
        name: `Fire Relic 'Sword of the Flaming Knight'`,
        location: `Earth Elemental Ruins F7 Stage Clear Reward`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104670.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104671.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104670.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104671.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 3200% Fire DMG (Magic). Reduces Enemies' Burn Resistance by 35% for 12s.`,
                break: 800
            },
        passive: 
            {
                ability1: `When Fire Relic "Bible of the Flaming Knight" is also equipped, Increases all Fire Elemental Units' Freeze Resistance by 30% (only once).`
            },
        lore: `This blade was recorded in the annals of the Flame Knights. The powers of divine flame sleep within and the blade burns with such heat that all it touches bursts to flame. This blade, once wielded by a knight, had slain any number of threats, but as the sacred texts have grown faded with time its secrets remain hidden. It appears that when the sword is brought near those holy texts, it could up hidden powers within them.`
    },
{ //Water Relic 'Aqua Dragon's Sword' - Complete
        id: 104681,
        name: `Water Relic 'Aqua Dragon's Sword'`,
        location: `Fire Elemental Ruins F7 Stage Clear Reward`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104680.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104681.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104680.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104681.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 3750% Water DMG (Physical). Reduces enemies' Freeze resistance by 25% for 10s.`,
                break: 800
            },
        passive: 
            {
                ability1: `When Water Relic 'Aqua Dragon's Quartz' is also equipped, Water units' Poison resistance 30% UP (Only once).`,
                ability2: `When allies are equipped with 4 Water Relic equipment, "Unleashing the power of Water Relic" is activated.`
            },
        lore: `This magical sword is endowed with the power of the legendary Dragon ruler of the seas. As its name implies, it is sharp as the Sea Dragon's talon. Few who have seen Talonblade drawn have lived to tell the tale, and enemies fear it as death itself. Though to those allies who were saved by this treasure praised it has a treasure of promising life. The blade itself possess immense strength, but they say if it is enhanced with Light Orbs, it could slay any enemy in a single stroke.`
    },
{ //Earth Relic 'Ain Wold' - Complete
        id: 104691,
        name: `Earth Relic 'Ain Wold'`,
        location: `Water Elemental Ruins F7 Stage Clear Reward`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104690.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104691.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104690.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104691.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 3200% Earth DMG (Magic). Reduces Enemies' Poison Resistance by 35% for 12s.`,
                break: 800
            },
        passive: 
            {
                ability1: `When Earth Relic 'Gran Forde' is also equipped, Increases all Earth Elemental Units' Burn Resistance by 30% (only once).`
            },
        lore: `This device, apparently some odd machine part which was found in mist-shrouded ruins, defies easy comprehension. The technique survives in no existing books, yet it appears to have some similarities to Gran Forde, hinting at a connection. It contains vast magical power, and may be useful as a weapon, but... it appears difficult to unleash that power. Could there be other missing parts?`
    },
{ //Holy Bow 'Forester' - Complete
        id: 104711,
        name: `Holy Bow 'Forester'`,
        location: `Limited Event - Crimson Stars Circus - Part 1`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104710.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104711.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104710.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104711.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `50s CT - 2222% Earth DMG (Physical). Increases all allies' Accuracy rate by 20% for 10s. If Earth unit, increases own Arts gauge by 20 & increases all allies' Evasion rate by 15% for 10s.`,
                break: 500
            },
        passive: 
            {
                ability1: `Equipment DMG 15% UP & HP Auto-recovery by 10 (Only once).`
            },
        lore: `The beloved bow of Mamori, a girl who looked up to the legendary hero Ashe Toto. Mamori's mother had told Mamori stories about the hero since she was young, and Mamori had dreamed of living up to his name ever since. After she taught herself to wield a bow, she continued to polish her skill as the archer of the Crimson Stars Circus. This bow accompanied Mamori through her many days of training and is inscribed with the story of how this one girl grew up into somebody the world looked at as a hero. The years spent together with Mamori imbued the bow with divine power, and the bow supported the hero Mamori until the end of her days.`
    },
{ //Holy Cloak 'Familia' - Complete
        id: 104721,
        name: `Holy Cloak 'Familia'`,
        location: `Limited Event - Crimson Stars Circus - Part 1`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104720.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104721.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104720.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104721.png`
            },
        stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
        skillset:  
            {
                skill: `40s CT - Reduces all allies' Equip CT by 25% for 8s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Own Evasion rate 5% UP.`
            },
        lore: `The cloak worn by Mamori, who was the only girl in the Crimson Stars, a travelling circus from the early days of the Ancient War. While Mamori would come to be called a hero by the many people around the world she saved, she had humble beginnings as an orphan of the war. Mamori wore this cloak to the very end, and the kindness in her heart combined with her desire to make the people she met on her journeys smile to imbue it with a divine power. This cloak is filled with the story of how the young Mamori became like the hero she had so looked up to.`
    },
{ //Sniper Rifle 'Howling' - Complete
        id: 104731,
        name: `Sniper Rifle 'Howling'`,
        location: `Limited Event - Crimson Stars Circus - Part 1`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104730.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104731.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104730.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104731.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `20s CT - 3200% Water DMG (Physical). 30% chance of Freezing enemy.`,
                break: 900
            },
        passive: 
            {
                ability1: `CRI DMG 20% UP.`
            },
        lore: `A tool used by the Giant assassin Sly to carry out his shadowy work wherever it took him in the early days of the Ancient War. His claim to fame was his ability to bury targets in an instant with a well aimed shot from afar, but it actually wasn't uncommon for his targets to evade him. This is by no means because of a lack of skill, as Sly's aim was unparalleled. On the contrary, it was because his shots were filled with so much killing intent and force that they would his target's primal instinct to flee. One shot from him, and his target's body would go flying. Going up against Sly was said to be like facing down a giant wolf with a murderous appetite to match its size.`
    },
{ //True 'Forester' - Complete
        id: 104741,
        name: `True 'Forester'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104740.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104741.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104740.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104741.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `40s CT - 2222% Earth DMG (Physical). Increases all allies' Accuracy Rate by 30% for 10s. If User is Earth Elemental then increases own Arts gauge by 20 and increases all allies' Evasion rate by 30% for 10s.`,
                break: 500
            },
        passive: 
            {
                ability1: `When Equipped by Mamori, increases own DMG by 20%, reduces equipment CT by 20%, and auto-fills Arts gauge (only once).`,
                ability2: `Increases equipment DMG by 15% and HP Auto-Recovery by 10 (only once).`
            },
        lore: `The Divine Bow, the symbol of the heroine Mamori, who traveled the world with the "Crimson Stars" Circus during the early days of the Ancient War, to help and bring a smile to many people. With her bow, she could always hit at the first shot, but her major ability was to strike a shower of arrows, like shooting stars, that would knock down an entire army in one blow. She kept training on her own in the depths of the forests for all her life, without anyone ever teaching her the technique, and thanks to her persistence, she could reach levels above capability.`
    },
{ //Magical Outfit 'Pierrot Crimson' - Complete
        id: 104751,
        name: `Magical Outfit 'Pierrot Crimson'`,
        location: `Rotating Event - Pierrot of Flames (Pientz)`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104750.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104751.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104750.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104751.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 3250% Fire DMG (Magic). Reduces Enemies' ATK and DEF by 20% for 8s.`,
                break: 500
            },
        passive: 
            {
                ability1: `Increases Burning Time Reduction and Burning Evasion Rate by 30%.`
            },
        lore: `This is the trademark outfit of Pientz, the leader of the Crimson Stars, a circus popular early in the Ancient War. As befitting the crimson name, his speciality was fire tricks... Which is to say, he was no mere prestidigitator, but a flame sorcerer of the first order. This outfit was made of special flame-resistant materials, and they saved him from mishap many a time. It is also endowed with special powers to hide his overflowing magical power from prying eyes.`
    },
{ //Magical Box 'Monstre' - Missing Lore
        id: 104761,
        name: `Magical Box 'Monstre'`,
        location: `Rotating Event - Pierrot of Flames (Pientz)`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104760.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104761.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104760.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104761.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 6000% Fire DMG (Magic). 10% chance of Fainting enemy.`,
                break: 1200
            },
        passive: 
            {
                ability1: `Water Resistance 10% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Holy Cane 'Proscris' - Complete
        id: 104771,
        name: `Holy Cane 'Proscris'`,
        location: `Limited Event - Crimson Stars Circus - Part 2`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104770.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104771.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104770.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104771.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 3500% Water DMG (Magic). Heals Own HP by 240 & Increases Own Arts Gauge by 20 every Hit.`,
                break: 1200
            },
        passive: 
            {
                ability1: `Increases all Allies' Max HP by 5% (only once). Increases all Beast race Allies' DMG by 15% (only once).`
            },
        lore: `The staff of Diaz, the beast summoner and star magician of the Crimson Stars, a travelling circus from the early days of the Ancient War. Diaz was praised from a young age as a genius unlike any other in his race. When his race was annihilated during an invasion, he swore vengeance, and that dark desire for retribution drove him day and night. It would take until Diaz had grown up for him to realize that the unfading brilliance of his staff was actually a light of hope entrusted to him by the companions who had left the world before him.`
    },
{ //Holy Robe 'Lathraea' - Complete
        id: 104781,
        name: `Holy Robe 'Lathraea'`,
        location: `Limited Event - Crimson Stars Circus - Part 2`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104780.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104781.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104780.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104781.png`
            },
        stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
        skillset:  
            {
                skill: `70s CT - 100% Water DMG (Magic). Increases DMG to enemy's weak-element by 15% for 8s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Auto-recovery of own HP by 6.`
            },
        lore: `The robe worn by Diaz, the expert beast summoner of the Crimson Star Circus, which was famed for how they traveled the world to help those in need during the early days of the Ancient War. While Diaz may have just worn the robe as a stage outfit, to the people who truly enjoyed the circus' performance and were grateful for their help, it was something to believe in. The robe became something like a uniform for Diaz. The reverence directed towards Diaz throughout his lifelong journeys became a warm and gentle light which enveloped and protected its wearer.`
    },
{ //Blood Katana 'MINAGOROSHI' - Complete
        id: 104791,
        name: `Blood Katana 'MINAGOROSHI'`,
        location: `Limited Event - Crimson Stars Circus - Part 2`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104790.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104791.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104790.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104791.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 5500% Fire DMG (Physical). Increases all allies' CRI rate by 30% during Break for 15s.`,
                break: 1800
            },
        passive: 
            {
                ability1: `Break Power 8% UP.`
            },
        lore: `During the early Ancient War, the infamously bloodthirsty assassin Voghan altered his own arm with this blade. Murder was pure pleasure for him, and he would do anything for more of it. He became obsessed with growing stronger so that he could kill anyone that crossed his path. This blade was his tool of choice for pushing his body past its natural limits. Its sinisterly crimson edge has spilled more blood than any of his other weapons, and within it lies a terrifying and accursed power.`
    },
{ //True 'Proscris' - Complete
        id: 104801,
        name: `True 'Proscris'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104800.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104801.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104800.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104801.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `35s CT - 5000% Water DMG (Magic). Heals own HP by 260 & increases Arts gauge by 20 every HIT.`,
                break: 1500
            },
        passive: 
            {
                ability1: `When equipped by Diaz, DMG 20% UP & HP Auto-recovery to all allies by 10 (Only once).`,
                ability2: `All allies MAX HP 5% & Beast units' DMG 15% UP (Only once).`
            },
        lore: `The scepter of Diaz, one of the heroes of the famous "Crimson Star Circus". When he was liberated from the desire for revenge that kept burning fro his childhood, the hope for the future which had sprouted in his heart, entrusted by his companions, became one in the form of this scepter. Recognizing the companions who had walked with him as a new family, and his vows to create a new tomorrow, the scepter was transformed into a holy scepter.`
    },
{ //Forbidden Book 'Irafael' - Complete
        id: 104811,
        name: `Forbidden Book 'Irafael'`,
        location: `Raid Equip (Auge)`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104810.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104811.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104810.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104811.png`
            },
        stats:
            {
                hp: 500,
                atk: 500,
                def: 500
            },
        skillset:  
            {
                skill: `80s CT - 8500% Fire DMG (Magic). 100% chance of Burning Enemy.`,
                break: 1200
            },
        passive: 
            {
                ability1: ``
            },
        lore: `This tome was written long before the Ancient War, and its existence was a closely guarded secret. They say it contains the secrets of powerful magic that nearly destroyed the world, and a way to open the gate to the underworld. No one alive knows the truth of this, but one thing is for certain: no one must use this power. Simply possessing the book is enough to doom one to destruction; actually opening it would burn the reader to ashes, they say.`
    },
{ //God Blade 'Dragon Twilight' - Complete
        id: 104851,
        name: `God Blade 'Dragon Twilight'`,
        location: `Limited Event - Seeking a Perfect World - Part 1`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104850.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104851.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104850.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104851.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 6500% Fire DMG (Physical). Increases all allies' Break power by 20% for 8s.`,
                break: 1000
            },
        passive: 
            {
                ability1: `DMG 10% UP & DMG taken 10% DOWN when Near-Death (Only once).`
            },
        lore: `This is the trusted blade of Tallis, hero and Divine Royal Army Fencing Instructor early in the Ancient War, and is imbued with the power of the Radiant Ittoryu school that she mastered. They say the founder of the school slew a many-headed fire-breathing dragon, and this blade appeared from its corpse. The tassel on the pommel is part of the founder's holy garb. Tallis grew to become the Ogre Slayer using this, the blade of her school's founder, the Dragon Slayer.`
    },
{ //Holy Robe 'Flora Twilight' - Complete
        id: 104861,
        name: `Holy Robe 'Flora Twilight'`,
        location: `Limited Event - Seeking a Perfect World - Part 1`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104860.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104861.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104860.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104861.png`
            },
        stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
        skillset:  
            {
                skill: `35s CT - Increases all Allies' Arts gauge by 1 for 20s. If User is Fire Elemental then heals all Allies' HP by 50 for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increases Fire Elemental Resistance by 15%.`
            },
        lore: `This is the trusted blade of Tallis, hero and Divine Royal Army Fencing Instructor early in the Ancient War, and is imbued with the power of the Radiant Ittoryu school that she mastered. They say the founder of the school slew a many-headed fire-breathing dragon, and this blade appeared from its corpse. The tassel on the pommel is part of the founder's holy garb. Tallis grew to become the Ogre Slayer using this, the blade of her school's founder, the Dragon Slayer.`
    },
{ //War Blade 'Diamond Chakram' - Complete
        id: 104871,
        name: `War Blade 'Diamond Chakram'`,
        location: `Limited Event - Seeking a Perfect World - Part 1`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104870.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104871.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104870.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104871.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 5800% Water DMG (Physical). Increases all allies' Equipment DMG by 20% for 8s. If the user is a Water unit, increases own Equipment DMG by 10% more.`,
                break: 800
            },
        passive: 
            {
                ability1: `HP Auto-recovery by 30 when Near-Death.`
            },
        lore: `This is a beloved weapon of war-loving Lozze, second prince of the Beasts early in the Ancient War. For someone like him who acts on instinct instead of reason, the best weapon is the one that feels right in the hand. And since he has more than enough strength to fight bare-handed, long weapons like swords, spears or axes actually get in the way of his battle-sense. Though this was meant to be a throwing tool, he put a handle on it so it fits right in with his free-form fighting style, letting him hit and slice his enemies up close.`
    },
{ //True 'Dragon Twilight' - Complete
        id: 104881,
        name: `True 'Dragon Twilight'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104880.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104881.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104880.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104881.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 7800% Fire DMG (Physical). Increases all allies' Break power by 20% for 8s.`,
                break: 1500
            },
        passive: 
            {
                ability1: `When equipped by Tallis, DMG 20% UP & Paralyze, Freeze & Curse resistance 100% UP (Only once).`,
                ability2: `DMG 10% to enemy & 10% DOWN from enemy when Near-Death (Only once).`
            },
        lore: `The beloved blade of Tallis, famed for being the ultimate mistress of the Radiant Ittoryu sword style of the Gods. Although it had been passed down in her family for generations, they say she was the only one to take the style to its pinnacle. Her flowing strikes, and the flames spraying forth from this sword like the fiery breath of the many headed dragon it slew, have dispatched evils beyond number. Yet herself was so powerful, she rarely had to draw her sword at all.`
    },
{ //True 'Diamond Chakram' - Missing Lore
        id: 104891,
        name: `True 'Diamond Chakram'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104890.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104891.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104890.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104891.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 6650% Water DMG (Physical). Increases all allies' Equipment DMG by 25% for 8s. If the user is a Water unit, increases own Equipment DMG by 15% more.`,
                break: 1000
            },
        passive: 
            {
                ability1: `When equipped by Lozze, DMG 20% UP & Skill DMG 20% UP (Only once).`,
                ability2: `HP Auto-recovery by 30 when Near-Death`
            },
        lore: `Missing Lore.`
    },
{ //Gun Sword 'Bray Canon' - Missing Lore
        id: 104901,
        name: `Gun Sword 'Bray Canon'`,
        location: `Rotating Event - Weapon with a Heart (Vicious)`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104900.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104901.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104900.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104901.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 2400% Earth DMG (Physical). Increases all allies' ATK by 20% for 4s.`,
                break: 1800
            },
        passive: 
            {
                ability1: `DMG to Gods 20% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Machina Hyperdrive 'Magna Ark' - Missing Lore
        id: 104911,
        name: `Machina Hyperdrive 'Magna Ark'`,
        location: `Rotating Event - Weapon with a Heart (Vicious)`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104910.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104911.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104910.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104911.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `55s CT - 5000% Earth DMG (Physical). If Enemy is God, Earth DMG 8000%.`,
                break: 1500
            },
        passive: 
            {
                ability1: `Physical DMG from enemy 15% DOWN.`
            },
        lore: `Missing Lore.`
    },
{ //Sweet Poison 'Sacrifice' - Missing Lore
        id: 104921,
        name: `Sweet Poison 'Sacrifice'`,
        location: `Main Story [Chapter 9 > Realm of Gods > [Challenge] Giaveil, the Grand Prince of Sin] Secret Equip`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104920.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104921.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104920.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104921.png`
            },
        stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
        skillset:  
            {
                skill: `50s CT - Increases all allies' DMG taken by 30%, Magic DMG by 30% & CRI rate by 30% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Magic DMG from enemy 10% DOWN.`
            },
        lore: `Missing Lore.`
    },
{ //Magi-Umbrella 'Sans Pluie' - Complete
        id: 104931,
        name: `Magi-Umbrella 'Sans Pluie'`,
        location: `Limited Event - Seeking a Perfect World - Part 2`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104930.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104931.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104930.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104931.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 6000% Light DMG (Magic). 60% chance of Poisoning enemy. If Demon, Arts gage 20 UP.`,
                break: 900
            },
        passive: 
            {
                ability1: `Accuracy rate 20% UP & Skill CT 20% DOWN (Only once).`
            },
        lore: `The umbrella was the ceaseless companion of the Demoness Mixie, who fought under the command of her general father, who himself served directly under the Great Six Demon Generals early in the Ancient War. She took much more care with her appearance than most and detested being seen with more conventional and unseemly weapons, so she assigned double roles to items she carried. This particular one was usually an effective parasol, but when needed it also served as a powerful weapon. when opened it could block live fire or magical attacks, and when closed it was capable of mowing down enemies. They say it was an elegant sight, even in battle.`
    },
{ //Magic Robe 'Tout Acconier' - Complete
        id: 104941,
        name: `Magic Robe 'Tout Acconier'`,
        location: `Limited Event - Seeking a Perfect World - Part 2`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104940.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104941.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104940.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104941.png`
            },
        stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
        skillset:  
            {
                skill: `35s CT - Increases Target's Arts gauge by 40. Reduces DEF by 30% for 8s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increases Poison Time Reduction by 30%. Increases Poison Evasion Rate by 30%.`
            },
        lore: `The formal dress that Mixie, one of the leading personas of the Demon fashionable society during the early period of the Ancient War. The gold color emphasizes her elegance and makes the atmosphere bright in just a second which makes it impossible to ignore her existence. Not only its design, but magic is woven into it to protect herself, the director, and negates attacks from blades and even magic attacks. Those with only such power would never be able to stand her dignity anyway.`
    },
    { //Carving Whip 'Viperra' - Complete
        id: 104951,
        name: `Carving Whip 'Viperra'`,
        location: `Limited Event - Seeking a Perfect World - Part 2`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104950.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104951.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104950.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104951.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 7200% Earth DMG (Physical). Reduces enemy's Earth resistance by 30% for 8s.`,
                break: 1400
            },
        passive: 
            {
                ability1: `All allies' DMG 10% UP for first 30s of quest (Only once).`
            },
        lore: `This was the beloved whip used by Jill, an important diplomat with the Humans early in the Ancient War, and one who tried to protect her people's neutrality by promoting lasting peace. In contrast her beautiful, gentle facade, though, this whip is a terrible weapon of attack. It bears blades that can crush an opponent's head or rip their entrails from their bodies, as well as poisoned spikes that can bring death at a scratch. They say that it would writhe around enemies like a true viper.`
    },
{ //True 'Sans Pluie' - Complete
        id: 104961,
        name: `True 'Sans Pluie'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104960.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104961.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104960.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104961.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 7200% Light DMG (Magic). 80% chance of Poisoning enemy. If Demon, Arts gauge 30 UP.`,
                break: 1200
            },
        passive: 
            {
                ability1: `When equipped by Mixie, DMG 20% UP & Equipment CT 20% DOWN (Only once).`,
                ability2: `Accuracy rate 20% UP & Skill CT DOWN (Only once).`
            },
        lore: `This was an item beloved of Mixie, a Demoness who flourished early in the Ancient War and was close to the Six Great Demon Generals. She never carried a weapon, preferring to provoke and unsettle her opponents with a smug "An umbrella is all I need." However, this umbrella was actually ideal for battle, and in her hands it could be a defensive shield as well as a magical weapon strong enough to shatter diamond. They say that when she twirled her umbrella over the remains of enemy forces, she grew as fearsome as the God of Death.`
    },
{ //True 'Viperra' - Complete
        id: 104971,
        name: `True 'Viperra'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104970.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104971.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104970.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104971.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 8500% Earth DMG (Physical). Reduces enemy's Earth resistance by 50% for 8s.`,
                break: 1700
            },
        passive: 
            {
                ability1: `When equipped by Jill, DMG 20% UP & Auto-fills Arts gauge by 2 for 90s (Only once).`,
                ability2: `All allies' DMG 10% UP for first 30s of quest (Only once).`
            },
        lore: `This was the beloved whip used by Jill, a human extremist who moved behind the scenes during the Ancient War. She put on the persona of one so loving of peace she wouldn't harm a fly, yet in truth, she was a war-mongering meddler who looked to provoke the greater races. As a cunning schemer, she never fought battles head-on, yet her whip could decapitate foes or inject deadly poison, making her an enemy worth respecting. Indeed, she was every bit as much of a viper as the name of her whip implies.`
    },
{ //Frozen Spear 'Draco Hasta' - Complete
        id: 104981,
        name: `Frozen Spear 'Draco Hasta'`,
        location: `Limited Event - Shadows of the Azul Frost (Alvina)`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104980.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104981.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104980.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104981.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `55s CT - 7000% Dark DMG (Magic). Reduce enemy's Freeze resistance by 50% for 10s.`,
                break: 1000
            },
        passive: 
            {
                ability1: `All allies' Water & Dark Equipment 10% UP (Only once).`
            },
        lore: `The magical spear of Alvina, sister to Corsair, of the Frozen Blade, one of the Three Blade Masters. She was able to overcome the dark power sleeping in her blood, and thus managed to awaken the draconic power in this spear. They say that Alvina herself, with her incomparable spear technique and the way she wove magical dragons and blades of ice, appeared like a goddess on the battlefield. They say her power possessed a will of its own, and at times it left its mistress' hands to attack and return, unbidden.`
    },
{ //Frozen Boots 'Caerula Nix' - Complete
        id: 104991,
        name: `Frozen Boots 'Caerula Nix'`,
        location: `Limited Event - Shadows of the Azul Frost (Alvina)`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_104990.png`,
                detailmax: `/db/Equipment/Detail/item_detail_104991.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_104990.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_104991.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 3500% Water DMG (Magic). Increases Own Magic DMG Dealt by 15% for 8s.`,
                break: 800
            },
        passive: 
            {
                ability1: `Increases Freeze Time Reduction and Freeze Evasion Rate by 40%.`
            },
        lore: `Alvina, called the Azul Spear, placed huge trust in these boots. Because she wielded a spear, she disliked heavy armor and could never separate from this accessory. Ever since she was blessed with the divine protection of a certain tundra lord, they say these boots were eternally clad in beautiful snow crystals. In addition to their defensive ability, they also allow attacks with frozen blades. Legends say that thanks to these boots she used to show off by walking on the surface of lakes and rivers.`
    },
{ //True 'Draco Hasta' - Missing Lore
        id: 105001,
        name: `True 'Draco Hasta'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105000.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105001.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105000.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105001.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 8200% Dark DMG (Magic). Reduces enemy's Freeze resistance by 60% for 12s. If Water unit, increases own DMG by 20%.`,
                break: 1200
            },
        passive: 
            {
                ability1: `When equipped by Alvina, DMG 20% UP & Paralyze, Seal, Curse resistance 100% UP (Only once).`,
                ability2: `All allies' Water & Dark Equipment DMG 10% UP (Only once).`
            },
        lore: `Missing Lore.`
    },
{ //Sacred Sword 'Grandeus' - Missing Lore
        id: 105011,
        name: `Sacred Sword 'Grandeus'`,
        location: `Rotating Event - Misson of the Sacred Sword (Nogia)`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105010.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105011.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105010.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105011.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 6800% Light DMG (Physical). Increases own Arts DMG by 20% for 10s.`,
                break: 900
            },
        passive: 
            {
                ability1: `DMG to Dark enemy 20% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Sacred Garb 'Destina' - Complete
        id: 105021,
        name: `Sacred Garb 'Destina'`,
        location: `Rotating Event - Misson of the Sacred Sword (Nogia)`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105020.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105021.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105020.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105021.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 4200% Light DMG (Magic). Recovers Own HP by 50/s for 10s.`,
                break: 700
            },
        passive: 
            {
                ability1: ``
            },
        lore: `This sacred garb has protected the swordsman Mogia on his life's journey to fulfill the destiny of his sacred sword. Likely, the only thing that has kept his spirit strong after he was chosen by the sword and became a plaything of fate is the divine protection woven into this clothing. Such clothing must truly have been a blessing, as it has protected him through trials unknown as he faces up to the fickleness of fate alone.`
    },
{ //Silent Summonstone - Complete
        id: 105031,
        name: `Silent Summonstone`,
        location: `Limited Event - Faith in the Stars`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105030.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105031.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105030.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105031.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `120s CT - 8000% Water DMG (Physical). Summon Gem Blessing: Increases all allies' Water DMG by 60% for 35s (Only once).`,
                break: 2000
            },
        passive: 
            {
                ability1: `Summon Gem Blessing Lv5: The highest Lv effect of the Summon Gem Blessing is effective.`
            },
        lore: `This summonstone is the symbol of a pact with the Silent Divine, Palamecia. When the pact holder prays to the stone, the goddess manifests in agreement with the accord. Palamecia possesses a lofty, noble soul, and anyone who wishes to use this stone must always follow her path, remain pure and just, and ceaselessly pursue strength and discipline. The greatest sword master of the Gods will be willing to lend her power to those who honor this stone well and light their path forward.`
    },
{ //Inferno Summonstone - Complete
        id: 105041,
        name: `Inferno Summonstone`,
        location: `Limited Event - Faith in the Stars`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105040.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105041.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105040.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105041.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `120s CT - 8000% Fire DMG (Magic). Summon Gem Blessing: Increases all allies' Fire DMG by 60% for 35s (Only once).`,
                break: 1200
            },
        passive: 
            {
                ability1: `Summon Gem Blessing Lv5: The highest Lv effect of the Summon Gem Blessing is effective.`
            },
        lore: `This summonstone is the symbol of a pact with the Inferno God, Ifrit. When the holder of this stone calls out to it, the power of the world will manifest in molded flesh. If the summoner's goal does not meet the standards of justice held by Ifrit, who owns the will of the world, the pact holder may be burned to ash as well. Holding this stone is like being in communication with the world itself. If the holder can avoid being overwhelmed by the massive will of the world, perhaps its great power will unveil the true path forward.`
    },
{ //Relic 'Silent Wings' - Complete
        id: 105051,
        name: `Relic 'Silent Wings'`,
        location: `Limited Event - Faith in the Stars`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105050.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105051.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105050.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105051.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `35s CT - 4200% Water DMG (Magic). Increases all Allies' Heal amount by 10% for 8s.`,
                break: 800
            },
        passive: 
            {
                ability1: `Regenerates Own HP by 4/s.`
            },
        lore: `These boots belonged to the Silent Divine, Palamacia, one of the greatest sword masters of the Gods. Forged by the greatest craftsman of the Gods, these boots are light as an ibis' wing, and glitter with divine blessing. The Goddess used her swift footwork to maintain the perfect distance from her opponents and dispatched them at times with silent speed, and at times with sweeping elegance. They say that the ability to close any distance in an instant offered by these boots brought her skill with the sword to even higher levels.`
    },
{ //True 'Frozen Twin Swords' - Complete
        id: 105061,
        name: `True 'Frozen Twin Swords'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105060.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105061.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105060.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105061.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `5s CT - 2200% Water DMG (Magic). Heals own HP by 100.`,
                break: 500
            },
        passive: 
            {
                ability1: `When equipped by Palamecia, DMG 20% UP & Break power 25% UP Only once).`,
                ability2: `Auto-fills Arts gauge by 2 when Near-Death.`
            },
        lore: `These are the blades to which the Silent Divine Palamecia, one of the Gods' greatest sword masters, devoted her life. The way these two blades danced silently through the air earned her comparisons to a swan in flight, and their grace helped her skill grow even more significant. The blades remained gleaming clean no matter their use, and thus reflected the tiniest tremor of hesitation shown by opponents so she could any opening to strike. They say they were so sharp, and they could cut the surface of a lake without raising a ripple.`
    },
{ //True 'Volkans' - Missing Lore
        id: 105071,
        name: `True 'Volkans'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105070.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105071.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105070.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105071.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `70s CT - 10000% Fire DMG (Physical). Burns all allies & enemies.`,
                break: 3300
            },
        passive: 
            {
                ability1: `When equipped by Ifrit, DMG 20% UP & MAX HP 50% UP (Only once).`,
                ability2: `Skill CT speed 35% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Spirit Spear 'Melchizelek' - Complete
        id: 105081,
        name: `Spirit Spear 'Melchizelek'`,
        location: `Purchased from Store: Master Summoner Pack`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105080.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105081.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105080.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105081.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 6000% Water DMG (Physical). Increases own Break power by 20% & Arts gauge by 2 for 7s.`,
                break: 3000
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The spear which Daisy, the Human Warrior full of talent used during the Ancient War. It has charms different from those of magic, thus no one could use it, and Daisy was in charge of looking after it. However, by sympathizing with it, she became to communicate with it, and succeeded to bring out its true powers. Ever since, the spirit spear gave her enormous power.`
    },
{ //Holy Robe 'Mercel' - Complete
        id: 105091,
        name: `Holy Robe 'Mercel'`,
        location: `Limited Event - Two Hearts, One Will - Part 1`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105090.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105091.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105090.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105091.png`
            },
        stats:
            {
                hp: 120,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - Heals all allies' Disease. Hp Auto-recovery by 120 for 7s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Own Skill CT 10% DOWN.`
            },
        lore: `The holy robe worn by Daki, a mage who withdrew from the front lines in the early days of the Ancient War despite his exceptional talent. It is made of a high quality fabric that was purified in an ancient temple and has the ability to heal the wounds of whoever wears it. The power of the gemstone in the center of the robe can relieve the illnesses of those around it and help them to heal. After retiring from the front lines, Daki is said to have continued to care for and heal wounded warriors in various places regardless of their race.`
    },
{ //Staff of Phoenix Tear 'Atropos' - Complete
        id: 105101,
        name: `Staff of Phoenix Tear 'Atropos'`,
        location: `Limited Event - Two Hearts, One Will - Part 1`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105100.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105101.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105100.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105101.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 6000% Light DMG (Magic). Reduces own Skill CT by 30% for 12s. Light units get an extra 30%.`,
                break: 900
            },
        passive: 
            {
                ability1: `Magic DMG 20% UP.`
            },
        lore: `The holy staff beloved bi Daki, the mage of the 3rd Dove Brigade. It was made with crystallized phoenix tears, which are among the most magically powerful crystals. When Daki slept, exhausted from all the magic he used in battle, his Spirit attendant Meo would take care of his staff in secret. "Meo, the staff seems to be shining more brightly than before." "Really? I thing you're just imagining it."`
    },
{ //True 'Atropos' - Complete
        id: 105111,
        name: `True 'Atropos'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105110.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105111.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105110.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105111.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 7200% Light DMG (Magic). Reduces own Skill CT by 30% for 16s. If Light unit, reduce Skill CT by 50% instead.`,
                break: 1200
            },
        passive: 
            {
                ability1: `When equipped by Daki, DMG, Evasion rate & Accuracy rate 20% UP (Only once).`,
                ability2: `Magic DMG 30% UP.`
            },
        lore: `The favorite staff of Daki that he used throughout his life. The staff was able to let off a much powerful light through not only Daki's own power, but also the added power of his Spirit servant. Daki's name was well-known amongst the soldiers in his brigade on the battlefield during the beginning of the Ancient War. Despite this, after leaving the military, he swore to carry on his parents' will by saving those in trouble. He did so while gripping the staff in his hand, feeling the warmth that radiated from it due to his servant's power.`
    },
{ //Light Relic 'Dagger of Creation' - Complete
        id: 105121,
        name: `Light Relic 'Dagger of Creation'`,
        location: `Dark Elemental Ruins F7 Stage Clear Reward`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105120.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105121.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105120.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105121.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 3750% Light DMG (Physical). Reduces enemy's Paralyze resistance by 25% for 10s.`,
                break: 800
            },
        passive: 
            {
                ability1: `When Light Relic 'Tin Cane of Relief' is also equipped, Light units' Blind resistance 30% UP (Only once).`,
                ability2: `When allies are equipped with 4 Light Relic equipment, "Unleashing the power of Light Relic" is activated.`
            },
        lore: `A light relic of the fallen country that believed that light would save everyone. It is said that this dagger created the world, but it does not have the powers to actually cut. There are various theories of how and why this dagger was made, but still, there is no accepted explanation for it. It might be waiting for the next opportunity to create a new world...`
    },
{ //Dark Relic 'Brute' - Complete
        id: 105131,
        name: `Dark Relic 'Brute'`,
        location: `Light Elemental Ruins F7 Stage Clear Reward`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105130.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105131.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105130.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105131.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 3200% Dark DMG (Physical). Reduces enemy's Blind resistance by 35% for 12s.`,
                break: 800
            },
        passive: 
            {
                ability1: `When Dark Relic 'Nichts' is also equipped, Dark units' Paralyze resistance 30% UP (Only once).`,
                ability2: `When allies are equipped with 4 Dark Relic equipment, "Unleashing the power of Dark Relic" is activated.`
            },
        lore: `The sharp claws of an adept who swore loyalty to "The Nameless". He was a madman, whose highest pleasure was to see blood flowing out of his victim's body. This weapon was specially made to slash anything it touched, causing abundant bleeding. When ordered by "The Nameless", he slashed anything that moved, both allies and enemies, into a sea of blood. Alone after the carnage, he looked at his claws stained with the blood still warm with a dignified expression on his face.`
    },
{ //Celia's Seashore Pareo - Complete
        id: 105181,
        name: `Celia's Seashore Pareo`,
        location: `Limited Equip Summon: Summer Banner`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105180.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105181.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105180.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105181.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `40s CT - Reduces all Allies' DMG Taken by 35% for 8s. Heals all Allies' HP by 25% of DMG Taken for 8s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `This swimwear was carefully chosen by Celia, the first princess of the Principality of Bamint. Those close to her grew tired of seeing her work herself to the bone, so they decided to get her one day off. With their choices wide-open, they suggested a luxury stay at a beachfront holiday resort. And to top it off, a certain junior princess also joined the plan. Another day off was required for the suddenly enthusiastic princess' swimsuit selection.`
    },
{ //Sonije's Hot-Pink Bikini - Complete
        id: 105191,
        name: `Sonije's Hot-Pink Bikini`,
        location: `Limited Equip Summon: Summer Banner`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105190.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105191.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105190.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105191.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `65s CT - Increases all Allies' Arts gauge by 30. Increases all Allies' Arts gauge by 2 for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A competition swimsuit worn by Sonije, a noblewoman of Bamint who ran through the age of confusion after the Ancient War. Before they became known as one of the Five Freedom Fighters, she traveled with her companions on a journey of investigation, and they were all just young and full of fun. With her Spirit blood running strong, Sonije herself was quite wild, and she never saw a body of water she wouldn't jump into; yet she still always took the time to change into her swimsuit. That's likely because she knew the eyes of her Dragon traveling companion were on her...`
    },
{ //Alma's Bandeau Bikini - Complete
        id: 105201,
        name: `Alma's Bandeau Bikini`,
        location: `Limited Equip Summon: Summer Banner`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105200.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105201.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105200.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105201.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 6600% Earth DMG (Magic). Reduces Enemy's Magic Resistance by 20% for 10s.`,
                break: 1200
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Rayas was playing in the sea with his friends, when he suddenly recalled the orphanage. Lapleh, who always took such good care of them, would often lead the children to a nearby river in summer. And every time, a woman in a green swimsuit joined them. And he still remembers how nervous he was around her in that tantalizing suit, even though he was just a kid. Come to think of it, that mysterious woman they met a Lapleh's grave looked a lot like her...`
    },
{ //Rishley's Sunset Bikini - Complete
        id: 105211,
        name: `Rishley's Sunset Bikini`,
        location: `Limited Equip Summon: Summer Banner`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105210.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105211.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105210.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105211.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 8200% Fire DMG (Physical). Increases DMG to enemy by 30% for 12s.`,
                break: 2500
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A bikini chosen with passion by Rishley, fifth in line of succession in the Kingdom of Famelle in the age of confusion after the Ancient War. When they were young and travelling in peace, her noble friend would change into her swimsuit and go for a dip in every river and lake they came to. She would always watch from a distance until, one day, her friend challenged her. "What, are you that self-conscious?" she said, and taking that opportunity she brought out this swimsuit she had prepared, unveiling it with all due pomp.`
    },
{ //Fen's Aloha Shorts - Complete
        id: 105221,
        name: `Fen's Aloha Shorts`,
        location: `Limited Equip Summon: Summer Banner`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105220.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105221.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105220.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105221.png`
            },
        stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
        skillset:  
            {
                skill: `35s CT - Increases all Allies' Arts gauge by 1 for 20s. Increases all allies' Equipment CT Recovery Speed by 15% for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `This swimsuit was presented rather forcefully on Fen, Master Strategist of the Gramenian Army, by his superior. They were supposed to be on a special assignment, but suddenly his superior said "Let's mix a little business and pleasure!" and Fen immediately realized he'd been tricked. Even if the sight of his superior rushing ogg to get changed the minute they got to the inn dispirited him, he changed himself nevertheless. They'd known each other for years, but still Fen felt he didn't really understand the man as they headed to the water's edge.`
    },
{ //Kane's Aloha Shirt - Complete
        id: 105231,
        name: `Kane's Aloha Shirt`,
        location: `Limited Equip Summon: Summer Banner`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105230.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105231.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105230.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105231.png`
            },
        stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
        skillset:  
            {
                skill: `30s CT - Increases target's Arts gauge by 30. Increases target's DMG by 20% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Swimwear prepared by Kane, a high official of the Gramenia Republic, for some fun in the sun. He claimed he was on important official business, but clearly he was just thinking of some fun, since he had a swimsuit for his Master Strategist as well. He wouldn't hear a word of argument, and changed to head to the beach as soon as they arrived at destination. Shaking his head in disgust, Fen changed too, and Kane looked on in amusement. 'I figured you needed a break!" he said, laughing.`
    },
{ //Sorcerer's Treasured Robe 'Caligula' - Complete
        id: 105241,
        name: `Sorcerer's Treasured Robe 'Caligula'`,
        location: `Limited Event - Two Hearts, One Will - Part 2`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105240.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105241.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105240.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105241.png`
            },
        stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
        skillset:  
            {
                skill: `45s CT - All allies' Arts gauge 20 UP. If Light or Dark unit, increases own Arts DMG by 20% for 8s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Evasion rate 10% UP when Near-Death.`
            },
        lore: `The holy robe worn by twin-blade warrior Raki, who withdrew from the front lines in the early days of the Ancient War despite his exceptional swordsmanship. It is said to have been created by a brilliant Demon priest and can only be worn by those who are chosen by it. Raki's servant Gobbie didn't know about this and suffered the consequences for it more than once. "Hey, Raki, don't I look good in this, too?" "Take it off now, or you'll dry up like a mummy."`
    },
{ //Demon Twin-Blade 'Deino' - Complete
        id: 105251,
        name: `Demon Twin-Blade 'Deino'`,
        location: `Limited Event - Two Hearts, One Will - Part 2`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105250.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105251.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105250.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105251.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 5800% Dark DMG (Physical). Lifesteal by 5% of DMG. Increases all allies' Accuracy rate by 20% for 10s.`,
                break: 900
            },
        passive: 
            {
                ability1: `Own ATK 40% UP when Near-Death.`
            },
        lore: `The Demon blades beloved by Raki, the twin-blade swordsman of the 3rd Raven Brigade. They are embedded with crystallized phoenix tears, which are among the most magically powerful crystals. When he first got these, Raki had trouble handling them because they were a bit too long for twin blades. He asked the Demon's army weapon smith to optimize the blades just for him. The peculiarly shaped hilts were an extra gift from the smith, and Raki was very fond of them.`
    },
{ //True 'Deino' - Complete
        id: 105261,
        name: `True 'Deino'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105260.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105261.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105260.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105261.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 7800% Dark DMG (Physical). Lifesteal by 8% of DMG. Additionally, increases all allies' Accuracy rate by 30% for 10s.`,
                break: 1200
            },
        passive: 
            {
                ability1: `When equipped by Raki, BE Output 15% UP, Skill CT 20% DOWN & DMG 20% UP (Only once).`,
                ability2: `Own ATK 40% UP when Near-Death.`
            },
        lore: `The favorite sword of Raki that he used throughout his life. The blade continued to grow sharper, as if it was proof of Raki's growth from overcoming the sadness of being separated from his brother. Although he was a skilled warrior of the army's magic corps, he reported his resignation to the captain after rumors began to arise that Raki would be promised a path to become captain himself. It is said that the captain did not stop him from leaving because of the determination in his eyes that burned brighter than he had ever seen.`
    },
{ //Gauntlets 'Gault Fort' - Missing Lore
        id: 105271,
        name: `Gauntlets 'Gault Fort'`,
        location: `Rotating Event - The Beast's Rampage (Zerkalo)`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105270.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105271.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105270.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105271.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 4400% Dark DMG (Physical). Increases own CRI rate by 50% for 5s.`,
                break: 1100
            },
        passive: 
            {
                ability1: `CRI DMG 20% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Demonic Wicked Globe - Complete
        id: 105281,
        name: `Demonic Wicked Globe`,
        location: `Raid Equip (Agnophobos)`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105280.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105281.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105280.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105281.png`
            },
        stats:
            {
                hp: 500,
                atk: 500,
                def: 500
            },
        skillset:  
            {
                skill: `40s CT - Heals all Allies' HP by 100/s for 15s. Increases all Allies' MAX HP by 25% for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increases Disease Time Reduction by 10%. Increases Disease Evasion Rate by 10%.`
            },
        lore: `In ancient times, the Demon Agnophobos suddenly appeared from another world, causing great harm to the people and was finally sealed with great sacrifice. This crystal is a magic tool made by the Demon's adepts to gain power from him. It can create a fracture through the seal of the Demon, who can normally be released only when the stars are in the correct position. It was hidden very strictly by those who feared the return of the Demon, but one day it suddenly disappeared without a trace.`
    },
{ //Fire Relic 'Flaming Knight's Armor' - Complete
        id: 105381,
        name: `Fire Relic 'Flaming Knight's Armor'`,
        location: `Earth Elemental Ruins F8 Stage Clear Reward`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105380.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105381.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105380.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105381.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `35s CT - Negates Fire allies' DMG from enemy for a total of 1200 & reduces all DMG by 15% for 12s.`,
                break: 0
            },
        passive: 
            {
                ability1: `When Fire Relic 'Bible of the Flaming Knight' is also equipped, increase Fire units' Break power by 20% (Only once).`,
                ability2: `When allies are equipped with 4 Fire Relic equipment, "Unleashing the power of Fire Relics" is activated.`
            },
        lore: `Armor from the Bible of the Flaming Knight. It houses the flame, which is the power of the god of the land. It is filled with an extreme heat that will not freeze even in the coldest of environments. This knight's armor is said to have never let any attack pierce through it. The heat of the armor would neutralize the enemy's blade. The authenticity of the tale is still unclear, as the letters of the bible have faded. When the bible is near the armor, the mysterious power of the bible seems to draw out the armor's hidden abilities.`
    },
{ //Water Relic 'Water Dragon's Shield' - Complete
        id: 105391,
        name: `Water Relic 'Water Dragon's Shield'`,
        location: `Fire Elemental Ruins F8 Stage Clear Reward`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105390.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105391.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105390.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105391.png`
            },
        stats:
            {
                hp: 500,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - Heals Water units HP by 1500. HP Heal amount 50% UP for 12s.`,
                break: 0
            },
        passive: 
            {
                ability1: `When Water Relic 'Aqua Dragon's Quartz' is also equipped, Water units' Break power 20% UP (Only once).`,
                ability2: `When allies are equipped with 4 Water Relic equipment, "Unleashing the power of Water Relics" is activated.`
            },
        lore: `This great shield was blessed with the grace of the legendary Aqua dragon who ruled the great ocean. As the name says, the shield is as light as a feather and as tough as the scale of the Aqua Dragon. It becomes an impregnable wall of defense while also healing the wounds of the soldiers and aiding their movement when attacking. The shield by itself is already formidable, but with the power of the quartz, it can gently absorb any impact, like water in a flowing river.`
    },
{ //Earth Relic 'Groove Lumina' - Missing Lore
        id: 105401,
        name: `Earth Relic 'Groove Lumina'`,
        location: `Water Elemental Ruins F8 Stage Clear Reward`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105400.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105401.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105400.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105401.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `40s CT - Auto-fills Earth allies' Arts gauge by 2 for 12s. Increase DMG to enemy by 20%.`,
                break: 0
            },
        passive: 
            {
                ability1: `When Earth Relic 'Gran Forde' is also equipped, Earth units' Break power 20% UP (Only once).`,
                ability2: `When allies are equipped with 4 Earth Relic equipment, "Unleashing the power of Earth Relics" is activated.`
            },
        lore: `Missing Lore.`
    },
{ //Sakura Sword 'IZANAGI' - Complete
        id: 105411,
        name: `Sakura Sword 'IZANAGI'`,
        location: `Limited Event - Inheritress of the Sakura Secret (Sakura Mira)`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105410.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105411.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105410.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105411.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `10s CT - 1700% Earth DMG (Physical). Increases own Arts gauge by 1 for 3s.`,
                break: 200
            },
        passive: 
            {
                ability1: `Equipment CT 10% DOWN.`
            },
        lore: `The "Radiant Itto-Ryu" style sword that has passed on for generations in a foreign land. They say the sword already existed by the time the founder had formalized the sword style. Those who are approved from the Soul of the Sword take possession of a blade that defeats countless enemies by swinging like the cherry blossom petals that fall from the trees in a beautiful dance. However, for many years nobody wielded the sword and was forgotten over time. Eventually, the third princess inherited its power and became successor to the "Sakura Itto-Ryu".`
    },
{ //Delicate kimono 'Translucent Blossom' - Complete
        id: 105421,
        name: `Delicate kimono 'Translucent Blossom'`,
        location: `Limited Event - Inheritress of the Sakura Secret (Sakura Mira)`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105420.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105421.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105420.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105421.png`
            },
        stats:
            {
                hp: 120,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - Increases Target's MAX HP by 2000, heals Poison and Paralyze, and increases Poison and Paralyze resistance by 60% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Arts Gauge Regenerates by 1 when Near-Death.`
            },
        lore: `The garment that the young princess inheritor of the "Sakura Itto-Ryu" took back from the other world. A wonderful kimono decorated with flowers from another world, called "Translucent Blossom" from its clear and transparent appearance. Because she is protected by the swordswoman who taught her this sword technique, Sakura is guarded from impurities and endowed with a bold tenacity. They say she doesn't wear this kimono in public very often because she's still learning how to wear it properly.`
    },
{ //True 'IZANAGI' - Complete
        id: 105431,
        name: `True 'IZANAGI'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105430.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105431.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105430.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105431.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `10s CT - 2300% Earth DMG (Physical). Increases own Arts Gauge by 2 for 3s.`,
                break: 300
            },
        passive: 
            {
                ability1: `When Equipped by Sakura Mira, increases own DMG by 20% and Auto-fills Arts gauge by 2 (only once).`,
                ability2: `Increases Equipment CT Speed by 15%.`
            },
        lore: `It is one of the two swords that the lady swordsman Yomi carried when she drifted ashore to Raktehelm from another world. For many years, the soul of her friend swordswoman who was asleep in the sword didn't approve of those who attempted to wield it. It sat unused until the third princess of Bamint unleashed the dormant power of "Sakura Itto-Ryu". The sparkle of the blade fills the air like flowers from another world and the opponent, intoxicated from the scene, is slashed without even noticing.`
    },
{ //Iris' Rolly-Frill Bikini - Missing Lore
        id: 105441,
        name: `Iris' Rolly-Frill Bikini`,
        location: `Limited Equip Summon: Summer Banner`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105440.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105441.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105440.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105441.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `30s CT - Reduces all allies' DMG from enemy by 10% & Fire & Water DMG by 30% for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
{ //Mira's Sailor Swimsuit - Complete
        id: 105451,
        name: `Mira's Sailor Swimsuit`,
        location: `Limited Equip Summon: Summer Banner`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105450.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105451.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105450.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105451.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `15s CT - 1000% Fire DMG (Physical). 80% Chance of Burning enemy.`,
                break: 400
            },
        passive: 
            {
                ability1: ``
            },
        lore: `For some reason, the noblewoman Mira chose the swimsuit as the Arena receptionist. Though they were at the beach, somehow the mood felt felt more like a battlefield. I won't say why they matched. They just asked the adventurer: "Based on what do you judge when two girls wear the same swimsuit?" and he answered: "What is there to judge, as long as you both look great?" For some reason, she couldn't answer and merely disappeared into the sea, blushing furiously.`
    },
{ //Melia's Purple Sexy Bikini - Complete
        id: 105461,
        name: `Melia's Purple Sexy Bikini`,
        location: `Limited Equip Summon: Summer Banner`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105460.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105461.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105460.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105461.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 8000% Dark DMG (Magic). Reduces enemy's Accuracy rate by 80% for 8s.`,
                break: 700
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Melia, the Blue-eyed Black Fox, looks dashing in her brand new swimsuit. As she attracted the gaze of the men scattered around the beach, she quietly joined up with her friends... And then the third princess exploded. She began shouting that Melia's clothes weren't fit for public view, that she should be ashamed, and so on... And Melia, looking amused, answered, "Oh, don't hold back. Do say what you really mean!" Yet again, her only goal was to stir trouble.`
    },
{ //Rem's Sexy Lace Bikini - Complete
        id: 105471,
        name: `Rem's Sexy Lace Bikini`,
        location: `Limited Equip Summon: Summer Banner`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105470.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105471.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105470.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105471.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 120
            },
        skillset:  
            {
                skill: `35s CT - Heals all Allies' Seal and Curse. Increases all Allies' Seal and Curse Resistance by 100% for 10s. Reduces all Allies' DMG Taken by 25% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The alchemist Rem chose this swimwear just for this summer. In past years, she and Melia had often gotten into conflict over their overlapping sexiness, so to prevent that this year they went shopping together. When they did, they both shared the same passion for the colour purple, and so they both bought the same color swimsuit. The fact that Iris was all ears for the talk about how she was looking for something cuter than last time, in "adult purple", is out secret.`
    },
{ //Liese' Swimwear - Complete
        id: 105481,
        name: `Liese' Swimwear`,
        location: `Limited Equip Summon: Summer Banner`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105480.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105481.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105480.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105481.png`
            },
        stats:
            {
                hp: 120,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 3800% Light DMG (Magic). Heals all Allies' HP by 1000.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Everyone was so worried! Liese the Priestess had caused much consternation last time, so this year's swimsuit was causing much talk. The result exceeded everyone's expectations! It was a mysterious suit like none anyone had ever seen before. Apparently, someone had brought it from the Otherworld. That person has written her name on the chest "So she won't lose it". Later, everyone said the same thing. "Somehow, I feel like I shouldn't look straight at it".`
    },
{ //Est's Sailor Swimsuit - Complete
        id: 105491,
        name: `Est's Sailor Swimsuit`,
        location: `Limited Equip Summon: Summer Banner`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105490.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105491.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105490.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105491.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 4000% Water DMG (Physical). Increases own CRI rate & CRI DMG by 30% for 8s during Break.`,
                break: 600
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Est, the Arena receptionist, in the same swimsuit as the third princess, thinks back at it. It was back in town. By chance, both who were about to buy a new bathing suit, put hands on the same one, at the same time. They fought a silent battle of wills and eventually they bought different colors of the same suit. They agree implicitly without speaking a single word. "If the swimsuit is the same, then victory rests solely on what's inside." And so, their quiet woman's war began...`
    },
{ //Aristela's Competition Swimsuit - Complete
        id: 105501,
        name: `Aristela's Competition Swimsuit`,
        location: `Limited Equip Summon: Summer Banner`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105500.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105501.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105500.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105501.png`
            },
        stats:
            {
                hp: 500,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `70s CT - Heals all Allies' HP by 50%.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Swimsuit worn by Aristela, the "Annihilation Witch". She and four of her companions took a trip not recorded in any history books, but stories remain in every land they passed, giving hints at how close they all were, and how fun-loving. Aristela herself apparently memorized the entire library of the Grim Stella coven, yet was totally hopeless at sports. The reason why she chose an austere swimsuit perfect for swimming, may be not to be teased by her friends again.`
    },
{ //Selia's Snow White Bikini - Complete
        id: 105511,
        name: `Selia's Snow White Bikini`,
        location: `Limited Equip Summon: Summer Banner`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105510.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105511.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105510.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105511.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 8000% Water DMG (Magic). Reduces Freeze resistance by 50% for 12s.`,
                break: 800
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Selia, one of Grimm Stella, a coven of the Six Great Witches, wore this swimsuit. She always had a free spirit. She went wherever and did whatever she wanted. Whenever she got in the mood for a swim, she headed to the nearest beach, river or lake. However, her curious nature didn't lend itself to normal water fun. She would want to cut through the highest waves, or slide over the ice when she got tired of swimming. And so sometimes she would turn the area into a frozen wasteland in the blink of an eye.`
    },
{ //Electro-Magnetic Gun 'Griffin' - Complete
        id: 105521,
        name: `Electro-Magnetic Gun 'Griffin'`,
        location: `Limited Event - Encounter with the Unknown (Norn)`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105520.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105521.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105520.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105521.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `55s CT - 6200% Earth DMG (Magic). Reduces Enemies' Paralyze Resistance by 60% for 8s.`,
                break: 1000
            },
        passive: 
            {
                ability1: `Increases Break Power by 6%.`
            },
        lore: `This weapon uses electromagnetic induction to fire projectiles. This is among the highest class of weapons used by the humanoid Machines, and though it's incredibly difficult to use, it offers unrivaled destructive power. The user's magical power is converted to Electromagnetic power, and Machine users achieve a particularly high conversion rate. It's also much safer that gunpowder-based weapons. It's quite complex, so only very experienced fighters can use it to its full capacity.`
    },
{ //Rapid-fire Gun 'Peregrine' - Complete
        id: 105531,
        name: `Rapid-fire Gun 'Peregrine'`,
        location: `Limited Event - Encounter with the Unknown (Norn)`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105530.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105531.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105530.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105531.png`
            },
        stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
        skillset:  
            {
                skill: `40s CT - 3000% Earth DMG (Magic). Increases own Arts gauge by 7 for 5s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increases Skill CT Recovery Speed by 10%.`
            },
        lore: `This cannon turns magical power into firepower. It excels in accuracy, velocity, power, and energy conversion, meaning it's nearly unequaled in fighting performance. It's an incredible example of cutting edge Machine technology, and it's easy to use as well, making it well-loved on battlefields all over. However, its ease of use implies that the user's skill will have a direct effect on efficacy, so it takes a high level of experience and sense to get the most out of it.`
    },
{ //True 'Griffin' - Complete
        id: 105541,
        name: `True 'Griffin'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105540.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105541.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105540.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105541.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 7800% Earth DMG (Magic). Reduces enemy's Paralyze resistance by 60% for 12s.`,
                break: 1500
            },
        passive: 
            {
                ability1: `When equipped by Norn, DMG 20% UP & Arts gauge 100 UP when Quest starts (Only once).`,
                ability2: `Break Power 10% UP.`
            },
        lore: `This special order weapon has been customized for easier use by the Machine warrior Norn. The original weapon has been lightened and its firepower increased, and since it can store up energy in advance it's capable of offering maximum power from the first shots of battle. On the other hand, it suffers from reduced energy efficiency and extreme recoil, which Norn compensates for with her own high-performance ballistic control system and high-output magic reactor.`
    },
{ //Spiked Helmet 'Agni' - Complete
        id: 105551,
        name: `Spiked Helmet 'Agni'`,
        location: `Rotating Event - The Guardian Giant (Badoul)`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105550.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105551.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105550.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105551.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `35s CT - Reduces all Allies' DMG Taken by 10% for 20s. Reduces all Allies' Magical DMG Taken by 20% for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increases DEF by 30% when Near-Death.`
            },
        lore: `The headpiece of the Giant Badoul, who was once celebrated as the guardian and hero of a feudal domain that once existed long ago. It was created with secret techniques passed down throughout generations of Giants. It is not only hard enough to break any blade that strikes it, but also can absorb its power--as well as the power of any magic attacks--and store them as heat within it. Its horns were red hot from the helmet's magic, as well as the Giant's own flames, and as such was used as both a piece of armor and a weapon. With the helmet decorated with scorching horns on his head, he burned swarms of foreign invaders to a crisp, becoming the absolute defense of the domain.`
    },
{ //Water Boots 'Aqua Ferme' - Complete
        id: 105561,
        name: `Water Boots 'Aqua Ferme'`,
        location: `Limited Event - The Blade Master's Purpose (Riana)`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105560.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105561.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105560.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105561.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `30s CT - 7200% Water DMG (Physical). Increases Skill CT speed by 120% for 10s.`,
                break: 700
            },
        passive: 
            {
                ability1: `Skill DMG 10% UP.`
            },
        lore: `Footwear belonging to history's greatest Sword Master, Riana. Crafted from the remains of an ancient beast slain by the clan's founder, they are an essential part of her battle dress. Though legend says it is possible to call forth the spirit of the creature residing inside them, only she has perfectly mastered that ability. Phantasmal fangs of ice combine with her ultimate blade skill to create a truly flawless offense.`
    },
{ //Dark Relic 'Groll' - Complete
        id: 105571,
        name: `Dark Relic 'Groll'`,
        location: `Light Elemental Ruins F8 Stage Clear Reward`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105570.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105571.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105570.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105571.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `45s CT - Increases Dark units' Arts gauge by 30. Increases BE Output by 70% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `When Dark Relic 'Nichts' is also equipped, Dark units' Break power 20% UP (Only once).`
            },
        lore: `A spell used by one who swore allegiance to "The Nameless". His greatest joy of all was listening to people scream and cry. The spell was written with forbidden effects for maximizing the suffering of men, so that the targets screamed in agony as they wandered through a long, half-dead life. He used the spell on himself at the end, and when he heard his own scream in pain, he died in a frenzy of joy.`
    },
{ //Light Relic 'Eradication Bow' - Complete
        id: 105581,
        name: `Light Relic 'Eradication Bow'`,
        location: `Dark Elemental Ruins F8 Stage Clear Reward`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105580.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105581.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105580.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105581.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 7800% Light DMG (Magic). Increases Light units' Break power by 20% for 10s.`,
                break: 1000
            },
        passive: 
            {
                ability1: `When Light Relic 'Tin Cane of Relief' is also equipped, Light units' Break power 20% UP (Only once).`
            },
        lore: `The Light Relic of a nation that faced destruction in ages past, believing light would bring about salvation for all. Although the target of this bow is unknown, it is said that "All life will eventually end and be extinguished by this Eradiction Bow". Though some believed it to not exist, those that laid eyes upon the bow, had but no choice to recognize reality. A reality that this bow exists purely to bring an end to all. In other words, one the world will eventually come to an end.`
    },
{ //Mysterious Necklace 'Eternal' - Missing Lore
        id: 105591,
        name: `Mysterious Necklace 'Eternal'`,
        location: `Rotating Event - Mask of Madness (Strife)`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105590.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105591.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105590.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105591.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 7200% Dark DMG (Magic). Uses 10% of own current HP. Auto-fills own Arts gauge by 1 for 15s.`,
                break: 700
            },
        passive: 
            {
                ability1: `Magic DMG 20% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Dark Blade 'Exceed Zechs' - Complete
        id: 105601,
        name: `Dark Blade 'Exceed Zechs'`,
        location: `Limited Event - The Hero and the Cursed Sword (Dark Zeorg)`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105600.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105601.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105600.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105601.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 7500% Dark DMG (Magic). If own HP is over 75%, increases Arts gauge by 30. Increases Arts DMG by 30% for 8s.`,
                break: 800
            },
        passive: 
            {
                ability1: `Lifesteal by 2% of DMG when Near-Death.`
            },
        lore: `This cursed blade was unsealed by Zeorg, the Hero of Famelle. It had been hidden away in the depths of a forbidden land, since the kingdom's founding. They say the blade has the power to slay dragons with ease, without the tiniest chip. However, since its immense power has consumed so many bearers, it was sealed away as a forbidden blade and its very existence hidden. But the Hero released it, and it has reappeared as a sword of unequaled might.`
    },
{ //Dark Armor 'Evil Death' - Complete
        id: 105611,
        name: `Dark Armor 'Evil Death'`,
        location: `Limited Event - The Hero and the Cursed Sword (Dark Zeorg)`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105610.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105611.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105610.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105611.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 4000% Dark DMG (Physical). If user is Human, increases DMG by 20% for 5s.`,
                break: 600
            },
        passive: 
            {
                ability1: `DMG to Light enemy 15% UP.`
            },
        lore: `This armor was worn by Zeorg when he took the cursed blade in hand. They say that no one but a truly great hero could hold the sword and survive, so it was sealed in the national treasury. This armor is so thoroughly steeped in darkness, that those who merely approach it are overcome. The courage and craftiness to control darkness with even greater darkness could only be possible in the Hero himself. The armor is so stout that it shatters blades, and indeed makes the owner's body a weapon capable of crushing stone.`
    },
{ //True 'Exceed Zechs' - Complete
        id: 105621,
        name: `True 'Exceed Zechs'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105620.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105621.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105620.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105621.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 8800% Dark DMG (Magic). If own HP is over 75%, increases Arts gauge by 30. Increases Arts DMG by 50% for 8s.`,
                break: 1000
            },
        passive: 
            {
                ability1: `When equipped by Hero of Darkness Zeorg, DMG & HP UP (MAX 20%). Paralyze & Freeze resistance 50% UP (Only once).`,
                ability2: `Heals own HP by 3% of DMG to enemy when Near-Death.`
            },
        lore: `A cursed blade said to be the shadow to the magical blade Ryude Magus, symbol of Zeorg, Hero of Famelle. They say that all who have taken it up descended into berserker madness, yet still all who bore the sword of light were eventually driven to reach out for this cursed sword as well. It is the final trial for all those who pursued power and sought to evade their own inevitable fading. A ritual to choose the truly supreme, woven by the two swords themselves. And now, having dominated the cursed blade and the magic, Zeorg is truly a hero.`
    },
{ //Twin Bone Swords 'Dos Muertes' - Complete
        id: 105631,
        name: `Twin Bone Swords 'Dos Muertes'`,
        location: `Limited Event - The Promised Place (Ashe)`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105630.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105631.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105630.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105631.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `35s CT - 5800% Dark DMG (Magic). Lifesteal by 4% of DMG.`,
                break: 800
            },
        passive: 
            {
                ability1: `DMG to Humans 30% UP.`
            },
        lore: `The two swords of Ashe, the Cult's assassin. He received this weapon from the researcher when he left the facility. Just like his body, the blade was perfected for killing. he can handle it only because he has a physical ability that exceeds human limits. An ordinary warrior would not be able to handle it even after a rough training. With these blades, Ashe can strike a hit out from the dark and without making any sound.`
    },
{ //Cursed Spear 'Tres Lanzas' - Complete
        id: 105641,
        name: `Cursed Spear 'Tres Lanzas'`,
        location: `Limited Event - The Promised Place (Ashe)`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105640.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105641.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105640.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105641.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `30s CT - 3000% Dark DMG (Magic). 80% chance of Blinding Enemy.`,
                break: 500
            },
        passive: 
            {
                ability1: `Increases DMG to Humans by 20%.`
            },
        lore: `The cursed spear of Ashe, the assassin of La Riiz. at first glance, the three spears may seem not practical, but they unleash tremendous dark magic. The wrap the enemy in impenetrable darkness that causes uneasiness and fear, inducing the target to lose the reason. If he wields the spears even once, an obscure cursing aura persists, so anybody can tell an assassination has been performed there.`
    },
{ //True 'Dos Muertes' - Complete
        id: 105651,
        name: `True 'Dos Muertes'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105650.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105651.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105650.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105651.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `30s CT - 6800% Dark DMG (Magic). Lifesteal 7% of DMG Dealt.`,
                break: 1200
            },
        passive: 
            {
                ability1: `When Equipped by Ashe, increases own Critical DMG by 50% and auto-fills Arts gauge by 1 (only once).`,
                ability2: `Increases DMG to Humans by 30%.`
            },
        lore: `The two swords that Ashe, the Cult's experiment, used in every fight. By using this weapon perfected for killing, he reached the highest levels of power. The magic flowing inside the swords reverberates with him, penetrates into the gaps of any protective gear of the opponent to make a blow that surely results in death. His perfect technique is achieved thanks to his double swords.`
    },
{ //宝碧鎧『ザグラルダ』 (Vogue Farmable) - JP Complete
        id: 105661,
        name: `宝碧鎧『ザグラルダ』`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105660.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105661.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105660.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105661.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `20s CT - 3800% Earth DMG (Magic). If this equip deals a CRI hit, reduce enemy's Accuracy rate and Evasion rate by 20% for 8s.`,
                break: 300
            },
        passive: 
            {
                ability1: `CRI rate 20% UP.`
            },
        lore: `Currently unreleased in Global.`
    },
{ //True 'Fe'Ode' - Missing Lore
        id: 105671,
        name: `True 'Fe'Ode'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105670.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105671.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105670.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105671.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 6800% Dark DMG (Magic). Increases own Arts gauge by 2 for 10s.`,
                break: 1200
            },
        passive: 
            {
                ability1: `When equipped by Zenon, increases DMG to Humans by 100% (Only once).`,
                ability2: `BE Output 20% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Insect Goddess Necklace - Complete
        id: 105681,
        name: `Insect Goddess Necklace`,
        location: `Raid Equip (Radachne)`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105680.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105681.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105680.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105681.png`
            },
        stats:
            {
                hp: 500,
                atk: 500,
                def: 500
            },
        skillset:  
            {
                skill: `40s CT - Increases all Allies' Arts gauge by 2 for 15s. Increases all allies' Accuracy Rate by 15% for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increases DMG to Dark Elemental Units by 10%.`
            },
        lore: `When the grotesque Goddess of the Spiders appeared, she conferred this necklace to a strong warrior. By using this treasure as an icon, the force of exorcising evil can be unleashed. This regal ornament beyond human work can be touched only by someone who is powerful enough to defeat her. If someone without enough strength touches it, the proud Goddess anger will transform the unfortunate victim into a spider.`
    },
{ //Fire Scepter 'Fomalhaut' - Complete
        id: 105691,
        name: `Fire Scepter 'Fomalhaut'`,
        location: `Side Quest: Knights of Blood - Act 1`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105690.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105691.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105690.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105691.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 6000% Fire DMG (Magic). Reduces Enemies' Fire Resistance by 30% for 10s.`,
                break: 800
            },
        passive: 
            {
                ability1: `Increases DMG to Earth Elemental Units by 10%.`
            },
        lore: `This magical scepter was wielded by Berwick, one of the Juno Templars. They say that it was made of a sacred flame that had burned since ancient times, and the blaze sleeping within is powerful enough to consume all it touches. The flames change with the magical power its user feeds them, and he can thus control not only their power but their form. The scepter also responds to the user's character, and since the flames created by his aggressive nature were a danger even to his allies, no one dared to come close to him in battle.`
    },
{ //Blood Crest 'Arc Ignite' - Complete
        id: 105701,
        name: `Blood Crest 'Arc Ignite'`,
        location: `Side Quest: Knights of Blood - Act 1`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105700.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105701.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105700.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105701.png`
            },
        stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
        skillset:  
            {
                skill: `15s CT - Increases all Allies' Arts Gauge by 10.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increases Unit's Skill CT Speed by 10%.`
            },
        lore: `This Crest of Granadia governs flame. Berwick of the Juno Templars wore it proudly, and they say when he unleashed it, it raised such incredible power from within him he could level whole armies on his own. When it is unsealed, the crest itself burns blazing hot and magical flames envelop the user. For that reason only a true master of fire can use it and indeed no one apart from Berwick has ever succeeded in controlling its flames.`
    },
{ //True 'Fomalhaut' - Complete
        id: 105711,
        name: `True 'Fomalhaut'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105710.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105711.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105710.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105711.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 7200% Fire DMG (Magic). Reduces enemies' Fire Resistance by 50% for 10s.`,
                break: 1200
            },
        passive: 
            {
                ability1: `When Equipped by Berwick, increases all status by 30% and DMG by 30% (only once).`,
                ability2: `Increases DMG to Earth Elemental Units by 20%.`
            },
        lore: `The man who bore this scepter used it to drive back an entire invading army on his own, and even incinerated a mighty ice dragon. The holy flames it generates burn all to ash and scatters the ashes to the four winds. From this, it earned the name Hell Flame Scepter, and it played a role in all his great works. Many longed for his power and attempted to carry this weapon, but its flames depend on the bearer's magical power, and only one with a worthy fighting spirit can bring out its true power.`
    },
{ //Earth Axe 'Tempest' - Complete
        id: 105721,
        name: `Earth Axe 'Tempest'`,
        location: `Side Quest: Knights of Blood - Act 2`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105720.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105721.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105720.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105721.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `100s CT - 12000% Earth DMG (Physical). If Earth unit, increases Arts gauge by 30.`,
                break: 500
            },
        passive: 
            {
                ability1: `Accuracy rate 10% UP.`
            },
        lore: `The double axes wielded by Riviera of the Juno Templars. The special shape allows them to blow so hard, they say, the earth would break, the air would tear and the walls would smash. Some even say that the exceptional power unleashes with her double hit is equivalent of that of a thousand troops. The double axes are so heavy that ordinary people would not be able to even carry it, and that they are so difficult to handle that nobody other than her would ever be able to wield them.`
    },
{ //Earth Battledress 'Aster' - Complete
        id: 105731,
        name: `Earth Battledress 'Aster'`,
        location: `Side Quest: Knights of Blood - Act 2`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105730.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105731.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105730.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105731.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `5s CT - 1700% Earth DMG (Magic). Increases own ATK by 20% and Reduces own DEF by 20% for 5s.`,
                break: 200
            },
        passive: 
            {
                ability1: `Increases DMG to Water Elemental Units by 5%.`
            },
        lore: `She went through many battles with this garment, but since she takes good care of it, it doesn't get worn out. This clothing wakes up magical powers lodged in the body of those who wear it and make it possible to instantly release a blow beyond the limits. However, since all the magical powers concentrate on the attack and lose the function as armor, appropriate preparation is required.`
    },
{ //True 'Tempest' - Complete
        id: 105741,
        name: `True 'Tempest'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105740.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105741.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105740.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105741.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `100s CT - 14000% Earth DMG (Physical). If equipped by an Earth unit, Increases Arts gauge by 50.`,
                break: 800
            },
        passive: 
            {
                ability1: `When Equipped by Riviera, Increases DMG dealt by 30% and will not flinch (only once).`,
                ability2: `Increases accuracy rate by 20%.`
            },
        lore: `The woman knight who handles this double weapon has always defended the Princess behind the enemy lines, along with the knight with the large shield. They say that the one who was mowed down by her axes was swept away together with the rocks in the surroundings, without leaving any trace. Nobody else could ever wield this weapon, but she was able to unleash all its destructive power.`
    },
{ //Crimson Blade 'Degilagon' - Complete
        id: 105751,
        name: `Crimson Blade 'Degilagon'`,
        location: `Mines Equip - Degilmagna`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105750.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105751.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105750.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105751.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `15s CT - 1700% Fire DMG (Magic). 60% chance of Burning enemy. Reduces own DEF by 30% for 10s.`,
                break: 200
            },
        passive: 
            {
                ability1: `CRI rate 10% UP.`
            },
        lore: `This Draco sword was possessed from the Death Dragon, whose flame breath could burn this whole world down. This blade can slash the enemy without touching him thanks to its extreme heat. The burn will never cure and scorch until the entire body becomes ashes. It is said that victory is promised to those who do not fear the blazing dragon. Hundreds and thousands desired this sword, though every single one got terrified and was eaten by the sword. Even now, the flame grins inside the blade as if it's laughing at the next challenger.`
    },
{ //Holy Necklace 'Dignified Silence' - Complete
        id: 105761,
        name: `Holy Necklace 'Dignified Silence'`,
        location: `Mines Equip: Palamicia`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105760.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105761.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105760.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105761.png`
            },
        stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
        skillset:  
            {
                skill: `50s CT - Reduces own Arts gauge by 50. Increases all Allies' Arts gauge by 50 except ownself.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increases Water Elemental Resistance by 10%.`
            },
        lore: `The necklace of Palamecia, the most excellent God swordswoman also known as "the Silent Divine". She received this accessory as a present from her friend when she set out on her journey to gain ultimate sword skills. Since then, she always took great care of it. Glowing in graceful light, this accessory sublimated her and recognized her deepest desires to become stronger. The necklace's noble radiance reflects her way of life. Those who look at it will be able to sharpen their conscience to the utmost to fully use their powers.`
    },
{ //Emerald Feather 'Biosdora' - Complete
        id: 105771,
        name: `Emerald Feather 'Biosdora'`,
        location: `Mines Equip: Tonetaurus`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105770.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105771.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105770.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105771.png`
            },
        stats:
            {
                hp: 120,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - Heals Target's HP by 330/s for 10s. Heals target's Blind.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increases Accuracy Rate by 10%.`
            },
        lore: `The feather that dropped from Tonetaurus, the emerald giant bird that appeared from the supercell called the "Curtains of the Thunder God". The lightning that sprung from its wings shatters the earth and shakes the air, but the gust of wind blesses the lives it touches once the giant bird is gone. This ornament made from the wings that destroy with thunder and bless by flapping, hide the powers of healing performed with the radiance of lightning.`
    },
{ //Hatered Claws 'Grief' - Complete
        id: 105781,
        name: `Hatered Claws 'Grief'`,
        location: `Mines Equip: Subject B-3`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105780.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105781.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105780.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105781.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `10s CT - 3000% Non-Elemental DMG (Physical). Uses 25% own of own HP. Increases own ATK by 30% & Auto-fills Arts gauge by 2 for 10s.`,
                break: 500
            },
        passive: 
            {
                ability1: `MAX HP 15% DOWN.`
            },
        lore: `The ominous claws of Subject β-3, the result of inhuman experiments that gave life to this deformed creature. It has outstanding magical powers that criminals exploit for their vicious ends. The resentment for being abandoned after the terrifying experiments, eventually shaped up into this cursed claw. But because of its evil nature, even after the death of the creature, deep sorrow lies inside the claws.`
    },
{ //Hades Earrings 'Halos Mors' - Complete
        id: 105791,
        name: `Hades Earrings 'Halos Mors'`,
        location: `Mines Equip: Oldeus`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105790.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105791.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105790.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105791.png`
            },
        stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
        skillset:  
            {
                skill: `50s CT - Increases all Allies' Skill CT Recovery Speed by 50% and Equipment CT Recovery Speed by 30% for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increases Curse Time Reduction by 10%. Increases Curse Evasion Rate by 10%.`
            },
        lore: `The earrings of Oleus, the Demon who hunted numerous souls with its giant reaper. The hanging skulls belong to his first victims and contain their souls, while the souls woven into his coat are a tribute to fill the loneliness of those who dwell in the earrings. They say that when you can hear strange sounds, similar to the roaring sounds of the sea, coming from the skulls, you can hear the voices of the souls of dead from all over the world and can use those powers to lead the living.`
    },
{ //Sacred 'Silent Treasure' - Complete
        id: 105801,
        name: `Sacred 'Silent Treasure'`,
        location: `Mines Equip: Elite Palamicia`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105800.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105801.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105800.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105801.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `30s CT - Uses 15% of all Allies HP. Increases all Allies' Arts gauge by 35. Increases all Allies' Critical DMG by 30% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increases ATK by 10%.`
            },
        lore: `The sacred treasure of Palamacia, known as the Silent Divine, the greatest swordswoman of all Gods, Memories of Palamacia's life were engraved into her necklace and eventually embodied into this sacred treasure. Since it is the embodiment of Palamacia herself, by embedding vitality into this sacred vessel, her existence becomes even more substantial. Through the recollection of the memory of her ultimate swordsmanship, one can use her powers to achieve victory.`
    },
{ //Sealed Earrings 'Filiamo' - Complete
        id: 105811,
        name: `Sealed Earrings 'Filiamo'`,
        location: `Mines Equip: Elite Oldeus`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105810.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105811.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105810.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105811.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `180s CT - Auto-fills Target's Arts gauge by 5 for 20s. Increases All Stats on Target by 30% and reduces All Stats on Self by 30% for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increases Curse Time Reduction by 10%. Increases Curse Evasion Rate by 10%.`
            },
        lore: `The earrings where dwells Oldeus, the Demon who wears a mantle knitted with the souls of the dead. He has woven thousands and millions of souls into his coat to comfort the souls of the first people he killed, who dwell in his skull earrings. After listening to their whispers, similar to the sound of the sea, throughout the years, he chose to seal his soul, too, into the earrings. It is said that wearing this ornament allows you to use its power, by offering the souls of the dead. To this day, no one knows why Oldeus sealed his own soul into his earrings...`
    },
{ //Fen's Pirate Hat - Complete
        id: 105821,
        name: `Fen's Pirate Hat`,
        location: `Limited Equip Summon: Halloween Banner`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105820.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105821.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105820.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105821.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `10s CT - 3000% Earth DMG (Physical). Increases all allies' Earth units' ATK by 10% for 5s.`,
                break: 400
            },
        passive: 
            {
                ability1: ``
            },
        lore: `This was the costume given to fen who is Head of General Staff in Gramenia. It's not even funny because it actually suits him pretty well. In fact, he seems to favor this costume and looks pleasant. After, he started to give the most horrible costumes to his subordinates saying, "Will I trick you if you don't wear this? Ahaha, no these are orders. If you don't wear this, you're fired." with a nice spooky smile.`
    },
{ //Swashbuckling Fen - Complete
        id: 105831,
        name: `Swashbuckling Fen`,
        location: `Limited Equip Summon: Halloween Banner`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105830.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105831.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105830.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105831.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `45s CT - Increases Target's Arts gauge by 100.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `This is the outfit given to Fen, Head of General Staff in Gramenia, for the otherworld festival. He gets an A for being the last person to be in his place with this unmatching costume. His subordinates all praised him, but the only person who knows Fen's past just burst into laughter. He approached him and whispered, "What a promotion for a naughty orphan to become a captain." Of course, his boss prepared this costume!`
    },
{ //Feena's Witch Hat - Mising Lore
        id: 105841,
        name: `Feena's Witch Hat`,
        location: `Limited Equip Summon: Halloween Banner`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105840.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105841.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105840.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105841.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `75s CT - 12000% Light DMG (Magic). Increases all Allies' DEF by 100% for 15s.`,
                break: 1200
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
{ //Feena's Devil Wings - Complete
        id: 105851,
        name: `Feena's Devil Wings`,
        location: `Limited Equip Summon: Halloween Banner`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105850.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105851.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105850.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105851.png`
            },
        stats:
            {
                hp: 500,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - Heals all Allies' HP by 200/s for 15s. Increases all Allies' stats by 35% for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `These wings do not suit the divine beauty Feena, who is one of the Twin Knights of the Morning Star. However she did not want to let the children down; thus she wore her costume. Although feeling a little embarrassed, her cute devil smile grabbed everyone's heart. She has made so many achievements and saved many, but she will not know that she did a small sinful things at this festival.`
    },
{ //Little-Red-Riding Mira - Missing Lore
        id: 105861,
        name: `Little-Red-Riding Mira`,
        location: `Limited Equip Summon: Halloween Banner`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105860.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105861.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105860.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105861.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `30s CT - Reduces all Allies' DMG Taken by 15% for 20s. Reduces all Allies' Fire Elemental DMG by 20%. All Allies Evade Burn for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
{ //Big-Bad-Wolf Roy - Complete
        id: 105871,
        name: `Big-Bad-Wolf Roy`,
        location: `Limited Equip Summon: Halloween Banner`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105870.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105871.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105870.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105871.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 120
            },
        skillset:  
            {
                skill: `50s CT - Reduces all Allies' DMG Taken by 20% for 10s. If user is Water Elemental then Reduces all Allies' DMG Taken by an additional 15% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `He was forced to wear this costume just because he is called as the "Silver Hungry Wolf." He is chased by the red-little-riding-princess who wants to defeat the bad wolf but asks her to wait until the moon comes out. While waiting, he acted as a wolf and entertained everyone. Now that night is here, his eyes glare with light, and stood in front of the princess. He asked for treats for a reward of today, so he could give them to an orphanage. As soon as she heard that, she left her treats and went away.`
    },
{ //Jack-O-Sherum's Boots - Complete
        id: 105881,
        name: `Jack-O-Sherum's Boots`,
        location: `Limited Event - Lanterns to Light the World`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105880.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105881.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105880.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105881.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `30s CT - Increases all Allies' Critical Rate by 50% and Skill CT Speed by 50% for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increases own Critical DMG by 10%.`
            },
        lore: `The boots that Jack-O-Sherum, a ghost that somehow fused with Ragsherum Phantom. It continues to wander and tricks people in order to get treats. This might seem kind of innocent, but in fact, it is said that this might be a ghost wandering to find a peaceful place to rest. It might have fused with Ragsherum Phantom which wanders to find its master, but no one knows the reason...`
    },
{ //Aqua Blade 'Maelstrom' - Complete
        id: 105891,
        name: `Aqua Blade 'Maelstrom'`,
        location: `Limited Event - The Illusory Twin Blades (Water Melia)`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105890.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105891.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105890.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105891.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `55s CT - 7000% Water DMG (Physical). If Water unit, increases all allies' Status Ailment resistance by 100% for 10s (Except Faint).`,
                break: 900
            },
        passive: 
            {
                ability1: `Evasion rate 5% UP.`
            },
        lore: `The twin blades used by Melia, the Blue-eyed Black Fox, in her secret work. She dedicated herself to mastering the double-sword style learned from the Cult to accomplish her work quickly and chose swords with a unique shape to strike overwhelming attacks from any position. Specialized in ensuring the incapacitation of the enemy, these swords, in combination with her constant fire, will bury the target without giving a chance to blink.`
    },
{ //Mist-guise 'Mirage Hood' - Complete
        id: 105901,
        name: `Mist-guise 'Mirage Hood'`,
        location: `Limited Event - The Illusory Twin Blades (Water Melia)`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105900.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105901.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105900.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105901.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 120
            },
        skillset:  
            {
                skill: `90s CT - Reduces all Allies' DMG Taken by 10% and Water Elemental DMG Taken by 30% for 7s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increases Accuracy Rate by 10%.`
            },
        lore: `A hood worn by Melia to hide her face when on secret missions. It's large to conceal the face, and it blends into the darkness when worn to keep enemy eyes from spotting her. As one of the most powerful sorcerers in La Riiz, she endowed it with water resistance and defensive power as well, and its incredible durability made it a valuable tool in her long secret career.`
    },
{ //True 'Maelstrom' - Complete
        id: 105911,
        name: `True 'Maelstrom'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105910.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105911.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105910.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105911.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 7800% Water DMG (Physical). If Water unit, increases all allies' Status Ailment resistance by 100% for 12s (Except Faint).`,
                break: 1500
            },
        passive: 
            {
                ability1: `When equipped by Illusory Twin Blades Melia, DMG 30% UP & Skill CT 30% DOWN (Only once).`,
                ability2: `Own Evasion rate 10% UP.`
            },
        lore: `The beloved twin blades used by Melia during her work as assassin and spy. This weapon earned her renown as the fastest of the Cult's spies, the "Illusory Twin-blades Assassin". The sword technique is focused on pure speed of attack, and even when facing multiple enemies, usually a handicap for double weapons, her flowing style is overwhelming. After she fled from the Cult, those she met on her journey have helped her grow, and now she uses her twin blades for good.`
    },
{ //Twin-sword 'Aurora & Zephyr' (双天剣 『紫耀・蒼風』) - JP Complete
        id: 105921,
        name: `双天剣 『紫耀・蒼風』`,
        translate: `'Aurora & Zephyr'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105920.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105921.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105920.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105921.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 8000% Light DMG (Physical). Reduce enemies' Light resistance by 20% for 10s. Additionally, if own HP is above 50%, increase own Physical DMG by 30% for 10s.`,
                break: 1200
            },
        passive: 
            {
                ability1: `Increase own Skill CT Recovery by 10%.`
            },
        lore: `Currently unreleased in Global.`
    },
{ //Armor 'Gale Cloud' (Eternia Farmable) - JP Complete
        id: 105931,
        name: `駆鎧『疾風朧雲』`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105930.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105931.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105930.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105931.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `35s CT - 4200% Light DMG (Magic). Increase Light allies' ATK by 20% for 8s.`,
                break: 500
            },
        passive: 
            {
                ability1: `DMG to Dark enemy 5% UP`
            },
        lore: `Currently unreleased in Global.`
    },
{ //True 'Aurora & Zephyr' - Complete
        id: 105941,
        name: `True 'Aurora & Zephyr'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105940.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105941.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105940.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105941.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 9000% Light DMG (Physical). Reduces enemy's Light resistance by 35% for 12s.`,
                break: 1500
            },
        passive: 
            {
                ability1: `When equipped by Eternia, MAX HP 30% UP & DMG to Giant Boss 35% UP (Only once).`,
                ability2: `Skill CT speed 25% UP.`
            },
        lore: `Clad in blue and purple light, these blades of Eternia have destroyed many powerful Dragons. Their edges, sharper than cloud-piercing light, can sever any material. They choose a wielder with a clear soul, meaning she too was fated to hold them. Whenever she fought on the battlefield, their dazzling light created the illusion of floating in dawn's resplendency.`
    },
{ //Dragon Gauntlet 'Sol Helios' - Missing Lore
        id: 105951,
        name: `Dragon Gauntlet 'Sol Helios'`,
        location: `Mines Equip: Fosradius`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105950.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105951.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105950.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105951.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 2500% Light DMG (Magic). Reduces allies' and enemies' Status Ailment resistance by 20% for 10s.`,
                break: 500
            },
        passive: 
            {
                ability1: `Light resistance 10% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Night Thunder 'Noxara' (夜霹飾『ノクスアーラ』) - JP Complete
        id: 105961,
        name: `夜霹飾『ノクスアーラ』`,
        location: `Mines Equip: Elite Tonetaurus`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105960.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105961.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105960.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105961.png`
            },
        stats:
            {
                hp: 500,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `100s CT - Recovers target's HP by 2500. Increase target's HP Recovery amount by 100% and reduce target's DMG by 50% for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Heal own HP by 4/s.`
            },
        lore: `Currently unreleased in Global.`
    },
{ //Icy Shell 'Frigid Scales' - Missing Lore
        id: 105971,
        name: `Icy Shell 'Frigid Scales'`,
        location: `Rotating Event - Wrath of the Dragon Slayer (Granith)`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105970.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105971.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105970.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105971.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `55s CT - Negates target's DMG taken for a total of 1000 & increases target's Freeze resistance by 100% for 12s (Only once). If the user is a Water unit, negates additional 1000 DMG taken (Only once).`,
                break: 0
            },
        passive: 
            {
                ability1: `Freeze Time Reduction & Freeze evasion rate 10% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Diamond Shield 'Adamas Gear' - Complete
        id: 105981,
        name: `Diamond Shield 'Adamas Gear'`,
        location: `Side Quest: Knights of Blood - Act 3`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105980.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105981.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105980.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105981.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `35s CT - Reduces Target's DMG Taken by 30% for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increases DEF by 10% when unit is Negating DMG.`
            },
        lore: `The huge shield of the Demon knight Thetis, who is renown for saving his companions many times thanks to his "Wall of Steel". This incredibly solid shield was made by his ancestor who founded his family's armor shop. They say it would not be scratched even by a Dragon's attack. However, because of its weight, it was stored away for a long time and only thanks to his tireless training, Thetis mastered the technique to handle it.`
    },
{ //Torrent Sword 'Grosveld' - Complete
        id: 105991,
        name: `Torrent Sword 'Grosveld'`,
        location: `Side Quest: Knights of Blood - Act 3`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_105990.png`,
                detailmax: `/db/Equipment/Detail/item_detail_105991.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_105990.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_105991.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 7000% Water DMG (Physical). Increases own DEF by 35% for 15s.`,
                break: 900
            },
        passive: 
            {
                ability1: `DMG from enemy 5% DOWN when Near-Death.`
            },
        lore: `The sword of the young Juno Templar Thetis. He is always in the front line ready to protect his companions and thus he prefers a powerful weapon over a sharp one. He wields this big and heavy sword together with a huge shield, and even if it is blunt, his strike can smash rocks. After thousands of battles, the sword still knocks down numerous enemies.`
    },
{ //True 'Adamas Gear' - Complete
        id: 106001,
        name: `True 'Adamas Gear'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106000.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106001.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106000.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106001.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `30s CT - Reduces Target's DMG from enemy by 35% for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: `When Equipped by Thetis, increases own DMG by 20% , increases HP by 2000, and negates Paralyze, Freeze, and Seal.`,
                ability2: `Increases DEF by 20% when unit is negating DMG.`
            },
        lore: `The great shield symbol of the Juno Templar Theits. He is said to have confronted the enemy army without ever hesitating. He always protected his companions by taking the hits for them and was finally recognized as the "Shield of the Templars". As a fortress, the shield protected his penetration in enemy lines and prevented the enemy's advance.`
    },
{ //Fire Relic 'Flaming Knight's Mantle' - Missing Lore
        id: 106011,
        name: `Fire Relic 'Flaming Knight's Mantle'`,
        location: `Earth Elemental Ruins F9 Stage Clear Reward`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106010.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106011.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106010.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106011.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `45s CT - Increases Fire units' DMG & Break power by 40% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `When Fire Relic 'Bible of the Flaming Knight' is also equipped, Water DMG taken by Fire units 20% DOWN (Only once).`,
                ability2: `When allies are equipped with 4 Fire Relic equipment, 'Unleashing the power of Fire Relics' is activated.`
            },
        lore: `Missing Lore.`
    },
{ //Water Relic 'Water Dragon's Fang Earrings' - Missing Lore
        id: 106021,
        name: `Water Relic 'Water Dragon's Fang Earrings'`,
        location: `Fire Elemental Ruins F9 Stage Clear Reward`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106020.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106021.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106020.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106021.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `70s CT - 9000% Water DMG (Magic). Heals Water units' HP by 20% of DMG taken for 10s.`,
                break: 800
            },
        passive: 
            {
                ability1: `When Water Relic 'Aqua Dragon's Quartz' is also equipped, Fire DMG taken by Water units 20% DOWN (Only once).`,
                ability2: `When allies are equipped with 4 Water Relic equipment, 'Unleashing the power of Water Relics' is activated.`
            },
        lore: `Missing Lore.`
    },
{ //Earth Relic 'Diewelt' - Missing Lore
        id: 106031,
        name: `Earth Relic 'Diewelt'`,
        location: `Water Elemental Ruins F9 Stage Clear Reward`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106030.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106031.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106030.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106031.png`
            },
        stats:
            {
                hp: 500,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - Heals all allies' HP by 1000. Auto-fills Earth units' Arts gauge by 2 for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `When Earth Relic 'Gran Forde' is also equipped, Water DMG taken by Earth units 20% DOWN (Only once).`,
                ability2: `When allies are equipped with 4 Earth Relic equipment, 'Unleashing the power of Earth Relics' is activated.`
            },
        lore: `Missing Lore.`
    },
{ //Relic 'Santa Arpa' - Complete
        id: 106041,
        name: `Relic 'Santa Arpa'`,
        location: `Rotating Giant Boss - Sanctuary of the Bright Spirit (Wilkes)`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106040.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106041.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106040.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106041.png`
            },
        stats:
            {
                hp: 125,
                atk: 62,
                def: 62
            },
        skillset:  
            {
                skill: `50s CT - Increases Target's Arts Gauge by 35. Adds a token that increases Target's Arts DMG by 50%. This token lasts until the unit dies.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increases Seal Time Reduction by 20%. Increases Seal Evasion Rate by 20%.`
            },
        lore: `This lyre holds a spirit's singing voice that brings good fortune and blessings. Since ancient times, there always have been people longing for the Spirit's blessing, yet most of them have ended up in ruin. However, one girl offered up the voice of this lyre to the Spirit, who was so enraptured by the sound that she bound herself to the instrument. They say that those who are touched by its voice will receive good fortune and a chance to grasp victory.`
    },
{ //Judgement Blade 'ZANKETSU' - Complete
        id: 106051,
        name: `Judgement Blade 'ZANKETSU'`,
        location: `Side Quest: Knights of Blood - Act 4`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106050.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106051.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106050.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106051.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 8000% Water DMG (Physical). Reduces own Skill CT by 50% for 10s.`,
                break: 1200
            },
        passive: 
            {
                ability1: `BE Output 5% UP.`
            },
        lore: `This is the beloved sword of Vox, captain of the Juno Templars and protector of Princess Leone. Stories say his sword was forged by a master smith in another land, and its core is made with incredibly pure magic crystals. The blade gleams with light in reaction to the bearer's strength, and the power it adds lets the blade bite even deeper. He treasured this blade his whole life long, and that brilliant light never faded.`
    },
{ //Demon Garb 'Ritter Stola' - Complete
        id: 106061,
        name: `Demon Garb 'Ritter Stola'`,
        location: `Side Quest: Knights of Blood - Act 4`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106060.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106061.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106060.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106061.png`
            },
        stats:
            {
                hp: 120,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - Heals Target's HP by 30%.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increases own Skill DMG by 10%.`
            },
        lore: `This adornment is worn by Vox, the proud captain of the Juno Templars. It had to be made from incredibly light and durable materials to both keep from holding back his lightning attacks, and offer protection in battle. It was also imbued with powerful healing magic by his queen who always worried when he ventured deep into enemy territory. Any injuries received while wearing this garb will be healed, allowing the warrior to rise and fight again.`
    },
{ //True 'ZANKETSU' - Missing Lore
        id: 106071,
        name: `True 'ZANKETSU'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106070.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106071.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106070.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106071.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `35s CT - 12000% Water DMG (Physical). Reduces own Skill CT by 50% for 10s.`,
                break: 2000
            },
        passive: 
            {
                ability1: `When equipped by Vox, own stats & CRI rate 20% UP & Evasion rate 10% UP (Only once).`,
                ability2: `When equipped by Swordsman Berwick, DMG 30% UP. Arts gauge 30 UP when Battle starts (Only once).`,
                ability3: `BE Output 15% UP.`
            },
        lore: `Missing Lore.`
    },
{ //True 'Knights Glow' - Complete
        id: 106081,
        name: `True 'Knights Glow'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106080.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106081.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106080.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106081.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `70s CT - 2000% Light DMG (Physical).`,
                break: 8000
            },
        passive: 
            {
                ability1: `When equipped by Platina, Break power 30% UP. DMG 30% UP during Break (Only once).`,
                ability2: `Dark resistance 20% UP.`
            },
        lore: `The proud sword of Platina of the Divine Knights, beloved blade that cut so much evil. The shining blade was improved by a high-ranking sorcerer, who made it sharp and lethal on the condition of the pureness and nobility of its bearer. They even say that when it's possessor loses pride as a knight, the blade will decay as well. However, its effulgence has not been lost until now, and it continues to irradiate solemn light, proving that she who bears it is worth of her acclaim as a true knight.`
    },
{ //Red-Nosed Rayas Doll - Complete
        id: 106091,
        name: `Red-Nosed Rayas Doll`,
        location: `Limited Equip Summon: Christmas Banner`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106090.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106091.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106090.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106091.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `55s CT - Reduces all Allies' DMG Taken by 20% for 10s. If User is Human, Reduces DMG Taken by an additional 20% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `This is one of the decorations Iris prepared for a party celebrating Christmas, an otherworld festival.. Iris gets carried away by every festival that comes her way, and this year she made loads of dolls. This doll version of Rayas is made to look to look like magical flying beasts of the otherworld called "reindeer" which pull Santa's sleigh through the sky delivering presents all over the world in a single night. When asked why she'd chosen herself to play Santa Claus, the master of the reindeer, Iris changed the topic.`
    },
{ //Santa Iris Doll - Complete
        id: 106101,
        name: `Santa Iris Doll`,
        location: `Limited Equip Summon: Christmas Banner`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106100.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106101.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106100.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106101.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 7200% Light DMG (Magic). Increases own Light DMG by 50% for 8s.`,
                break: 900
            },
        passive: 
            {
                ability1: ``
            },
        lore: `This is a doll Iris made to get ready for Christmas, a festival celbrated in the otherworld. The bright red clothing of the jolly old elf called Santa Claus transformed into a cute dress under her scissors. Though the clothing has sacrificed its usual warmth for style, it certainly has attracted the attention of adventurers visiting the Firecat's Den. The two reindeer accompanying her seem pretty happy, too.`
    },
{ //Red-Nosed Roy Doll - Missing Lore
        id: 106111,
        name: `Red-Nosed Roy Doll`,
        location: `Limited Equip Summon: Christmas Banner`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106110.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106111.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106110.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106111.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `30s CT - Reduces all Allies' DMG Taken by 10% and Water Elemental DMG Taken by 20% for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
{ //Snow-Lady Mira Doll - Missing Lore
        id: 106121,
        name: `Snow-Lady Mira Doll`,
        location: `Limited Equip Summon: Christmas Banner`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106120.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106121.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106120.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106121.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `55s CT - 8000% Fire DMG (Magic). Reduces enemy's Burn resistance by 50% for 8s.`,
                break: 1200
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
{ //Santa Fen Doll - Complete
        id: 106131,
        name: `Santa Fen Doll`,
        location: `Limited Equip Summon: Christmas Banner`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106130.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106131.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106130.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106131.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `20s CT - Increases Target's Arts Gauge by 30. Increases Target's Arts Gauge by 3 for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A doll Iris made for the otherworld festival of Christmas. The other toys were all Iris' idea, but this one was made by request of the model. Astonished by the incredible potential threat of a man capable of flying all over the world and delivering presents to every child in a single night, Fen began researching Christmas to discover how he could gain such power. This doll is proof of just how deeply he longs for it...`
    },
{ //Ganan Tree Doll - Missing Lore
        id: 106141,
        name: `Ganan Tree Doll`,
        location: `Limited Equip Summon: Christmas Banner`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106140.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106141.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106140.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106141.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `80s CT - 7000% Fire DMG (Physical). If Fire unit, increases CRI rate by 100% for 5s.`,
                break: 3200
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
{ //Merry Liese Doll - Complete
        id: 106151,
        name: `Merry Liese Doll`,
        location: `Limited Equip Summon: Christmas Banner`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106150.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106151.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106150.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106151.png`
            },
        stats:
            {
                hp: 500,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - Heals all Allies' HP by 500/s for 8s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A doll Iris made for the otherworld festival of Christmas, when every child gets a present. When one of the priests saw that Liese was dressing all in red and hanging a stocking near her pillow to get ready for Christmas, he secretly asked Iris to make her a doll. When she found this cute little toy in her stocking on Christmas morning, she was so happy she tried to take it with her when she did her duties, throwing the other priests into a tizzy.`
    },
{ //Divine Crown 'Holy Jingle' - Complete
        id: 106161,
        name: `Divine Crown 'Holy Jingle'`,
        location: `Limited Event - Rhapsody of Christmas Eve`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106160.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106161.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106160.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106161.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 8500% Light DMG (Physical). If enemy is Dark, increases own CRI rate by 100% for 5s.`,
                break: 3000
            },
        passive: 
            {
                ability1: `Own HP Heal amount 15% UP (Except Lifesteal).`
            },
        lore: `One winter morning, Celia awoke to find this sword lying by her pillow. The sword bore a card reading Merry Christmas!, so it was apparently a gift. However, the royal rooms are under heavy guard, so who could have put it there without her noticing. They say using this adorably decorated sword, with its oddly nostalgic bells ringing, is a sure call to happiness.`
    },
{ //Ghost Shield 'Tenebrae Lux' - Complete (109961 = Tenebrius EV Equip)
        id: 106171,
        name: `Ghost Shield 'Tenebrae Lux'`,
        location: `Raid Equip (Tenebrius)`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106170.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106171.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106170.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106171.png`
            },
        stats:
            {
                hp: 500,
                atk: 500,
                def: 500
            },
        skillset:  
            {
                skill: `5s CT - Heals all allies' HP by 25. Negates all allies' DMG for a total of 250 for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Seal Time Reduction & Seal Evasion rate 10% UP.`
            },
        lore: `A Dragon shield imbued with the power of Fallen Beast God Tenebrius, who is whispered of in legend as a dragon of illusion with unfathomable power. While the dragon's body has eroded away with time, its soul lives on as a crystal of Dragon magic. A shard of that crystal is embedded in the middle of the shield, and if the person bearing the shield comes across any danger, it will put up an impenetrable barrier to protect them.`
    },
{ //Light Relic 'Cape of Reincarnation' - Complete
        id: 106181,
        name: `Light Relic 'Cape of Reincarnation'`,
        location: `Dark Elemental Ruins F9 Stage Clear Reward`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106180.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106181.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106180.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106181.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `40s CT - Reduces Light units' DMG taken by 35% & Magic DMG taken by 15% for 12s.`,
                break: 0
            },
        passive: 
            {
                ability1: `When Light Relic 'Tin Cane of Relief' is also equipped, Dark DMG taken by Light units' 20% DOWN (Only once).`,
                ability2: `When allies are equipped with 4 Light Relic equipment, "Unleashing the Power of Light Relics" is activated`
            },
        lore: `A Light Relic of a nation that believed the light would save all and was destroyed long ago. It was said that when the world is reborn, whoever holds one of these will also be restored. Lighter than air and radiating divinity, it can only be worn by those who are pure and honest. Any presence of sin and it will flee as if of its own will. When the fated hour is at hand, how many will be found bearing it.`
    },
{ //Dark Relic 'Metzelei' - Complete
        id: 106191,
        name: `Dark Relic 'Metzelei'`,
        location: `Light Elemental Ruins F9 Stage Clear Reward`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106190.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106191.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106190.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106191.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 6666% Dark DMG (Physical). Increases Dark units' equipment DMG by 50% for 6s.`,
                break: 1500
            },
        passive: 
            {
                ability1: `When Dark Relic 'Nichts' is also equipped, Light DMG taken by Dark units' 20% DOWN (Only once).`,
                ability2: `When allies are equipped with 4 Dark Relic equipment, "Unleashing the Power of Dark Relics" is activated.`
            },
        lore: `A great sword used by one who swore allegiance to the "The Nameless'. His greatest joy of all was severing the heads of his victims. The weapon was forged wit superb durability, so it shows no use signs of wear despite heavy use. He firmly believed that the sensation of it separating head from body proved he was alive. It was said that the madness of his slaughter dyed the blade's edge not in the red of blood, but a darker violet hue.`
    },
{ //聖徽『イノセントノア』 (Kush's Farmable) - JP Complete
        id: 106201,
        name: `聖徽『イノセントノア』`,
        translate: `'Innocent Noah'`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106200.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106201.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106200.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106201.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 7200% Light DMG (Magic). Increase all allies' ATK and DEF by 35% for 10s.`,
                break: 900
            },
        passive: 
            {
                ability1: `ATK 10% UP.`
            },
        lore: `Currently unreleased in Global.`
    },
{ //裂魔剣『グランキラー』 (Rebol's Farmable) - JP Complete
        id: 106211,
        name: `裂魔剣『グランキラー』`,
        translate: `'Gran Killer'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106210.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106211.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106210.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106211.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `65s CT - 6800% Dark DMG (Physical). Increase own CRI rate by 50% for 10s. If user is Demon, increase own CRI rate by 70% instead.`,
                break: 500
            },
        passive: 
            {
                ability1: `Skill CT speed 15% UP.`
            },
        lore: `Currently unreleased in Global.`
    },
{ //真『イノセントノア』 (Kush's TW) - JP Complete
        id: 106221,
        name: `真『イノセントノア』`,
        translate: `True 'Innocent Noah'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106220.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106221.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106220.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106221.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `55s CT - 8800% Light DMG (Magic). Increase all Allies' ATK and DEF by 50% for 10s.`,
                break: 1500
            },
        passive: 
            {
                ability1: `When equipped by Kush, increase ATK and DMG by 25% (Only once).`,
                ability2: `ATK 15% UP.`
            },
        lore: `Currently unreleased in Global.`
    },
{ //真『グランキラー』 (Rebol's TW) - JP Complete
        id: 106231,
        name: `真『グランキラー』`,
        translate: `True 'Gran Killer'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106230.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106231.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106230.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106231.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 7500% Dark DMG (Physical). Increase own Critical rate by 50% for 10s. If user is Demon, increase own CRI rate by 80% instead.`,
                break: 900
            },
        passive: 
            {
                ability1: `When equipped by Rebol, increase Critical DMG by 30% and increase own Arts gauge by 1/s (Only once).`,
                ability2: `Skill CT speed 20% UP.`
            },
        lore: `Currently unreleased in Global.`
    },
{ //True 'Saint Blaze' - Complete
        id: 106241,
        name: `True 'Saint Blaze'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106240.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106241.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106240.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106241.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `65s CT - 9500% Water DMG (Physical). Increases all allies' Water DMG by 40% for 10s.`,
                break: 1000
            },
        passive: 
            {
                ability1: `When Equipped by Nies, increases own DMG by 20%, regenerates own HP by 100, and auto-fills arts gauge by 2/sec (only once).`,
                ability2: `Increases own Skill DMG by 20%.`
            },
        lore: `Former leader of the Divine Guardian's Divine Knights, Nies, wielded this great sword his whole life. It possesses massive magical power, and so even among the Gods only the chosen ones could control it: those without true strength were forbidden to touch it. When those bearing this sword attack, the power unleashed is directly proportionate to the bearer's will, and thus Nies' iron will and incredible skill channeled through this blade meant he was the match of ten thousand warriors.`
    },
{ //翠砡杖『ヴァルトレニス』 (Belle's Farmable) - JP Complete
        id: 106251,
        name: `翠砡杖『ヴァルトレニス』`,
        translate: `'Valtrenith'`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106250.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106251.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106250.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106251.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `30s CT - Increase all allies' DMG to Light enemy by 30% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `DMG to Light enemy 10% UP.`
            },
        lore: `Currently unreleased in Global.`
    },
{ //翠神翼『ミルエール』 (Belle's Farmable) - JP Complete
        id: 106261,
        name: `翠神翼『ミルエール』`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106260.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106261.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106260.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106261.png`
            },
        stats:
            {
                hp: 500,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `90s CT - Heals all allies' HP by 2000.`,
                break: 0
            },
        passive: 
            {
                ability1: `Light resistance 10% UP.`
            },
        lore: `Currently unreleased in Global.`
    },
{ //True 'Valtrenith' - Missing Lore
        id: 106271,
        name: `True 'Valtrenith'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106270.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106271.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106270.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106271.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `30s CT - 100% Earth DMG (Magic). Reduces enemy's Earth resistance by 20% & increases all allies' DMG to Light enemy by 50% for 10s.`,
                break: 800
            },
        passive: 
            {
                ability1: `When equipped by Belle, all status 20% UP & auto-fills Arts gauge by 2 (Only once).`,
                ability2: `DMG to Light enemy 15% UP.`
            },
        lore: `Missing Lore.`
    },
{ //水凰帽『フェロニカ』 (Tia's Farmable) - JP Complete
        id: 106281,
        name: `水凰帽『フェロニカ』`,
        translate: `'Feronica'`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106280.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106281.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106280.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106281.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `65s CT - Increase own Magic DMG by 20% and reduce enemy's Freeze resistance by 20% for 10s.`,
                break: 600
            },
        passive: 
            {
                ability1: `Fire resistance 10% UP.`
            },
        lore: `Currently unreleased in Global.`
    },
{ //風水衣『ヴィルガルト』 (Tia's Farmable) - JP Complete
        id: 106291,
        name: `風水衣『ヴィルガルト』`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106290.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106291.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106290.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106291.png`
            },
        stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
        skillset:  
            {
                skill: `30s CT - Increase all allies' Skill CT speed and Skill DMG by 50% for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Magic DMG 10% UP.`
            },
        lore: `Currently unreleased in Global.`
    },
{ //真『フェロニカ』 (Tia's TW) - JP Complete
        id: 106301,
        name: `真『フェロニカ』`,
        translate: `True 'Feronica'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106300.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106301.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106300.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106301.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 7500% Water DMG (Magic). Increase own Magic DMG by 30% and reduce enemy's Freeze resistance by 30% for 10s.`,
                break: 1000
            },
        passive: 
            {
                ability1: `When equipped by Tia, increase own DMG by 20% and DMG to Fire Enemies by 20% (Only once).`,
                ability2: `Fire resistance 15% UP.`
            },
        lore: `Currently unreleased in Global.`
    },
{ //Blazing Cloak 'Flame Stola' - Complete
        id: 106311,
        name: `Blazing Cloak 'Flame Stola'`,
        location: `Side Quest: Knights of Blood - Act 5`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106310.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106311.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106310.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106311.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `45s CT - Negates 500 DMG Dealt to all Allies for 15s. Reduces all Allies' DMG Taken by 20% for 15s (only once).`,
                break: 0
            },
        passive: 
            {
                ability1: `Increases own Physical DMG by 10%.`
            },
        lore: `This mantle was dear to the blazing-fast swordsman Berwick of the Leone Templars. It was designed for mobility to meet his high-speed fighting style, but the material is imbued with flame magic in its very fibers, giving it extremely high defensive capabilities as well. To protect him whenever he uses his true power, the mantle fills the air with flames, and for this reason many began to call him "Swordsman of Flames".`
    },
{ //Fire Boots 'Rubellum' - Complete
        id: 106321,
        name: `Fire Boots 'Rubellum'`,
        location: `Side Quest: Knights of Blood - Act 5`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106320.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106321.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106320.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106321.png`
            },
        stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
        skillset:  
            {
                skill: `50s CT - Increases all stats on all Allies' by 10% for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `These boots were dear to the blazing-fast swordsman Berwick of the Leone Templars. They were made strong to withstand treading the flames he wielded, yet still light enough to allow him to move with quickness. The way he ended battle stalemates with his swordsmanship and mobility encouraged not only his comrades but all the soldiers around.`
    },
{ //Star Etcher 'Asterisk' - Complete
        id: 106331,
        name: `Star Etcher 'Asterisk'`,
        location: `Side Quest: Knights of Blood - Act 6`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106330.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106331.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106330.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106331.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `60s CT - Increases all allies' Arts gauge by 20. Increases all allies' Arts gauge by 1 for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Magic DMG 10% UP.`
            },
        lore: `A pen created to exercise her magic by the genius sorcerer and pride of the Leone Templars. The pen holder was made of a special metal, discovered in Granadia and inherited from the Ancestors along with the Crest runes. The allows the pen nib to channel the user's magic effectively. At the time, crest sorcerers of Granadia preferred pens to staves or rods.`
    },
{ //Inventor Robe 'Genesis' - Complete
        id: 106341,
        name: `Inventor Robe 'Genesis'`,
        location: `Side Quest: Knights of Blood - Act 6`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106340.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106341.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106340.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106341.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `70s CT - 5000% Water DMG (Magic). Increases all Allies' Water Elemental DMG Dealt by 10% for 10s.`,
                break: 600
            },
        passive: 
            {
                ability1: `Auto-fills Arts Gauge by 1 when Near-Death.`
            },
        lore: `A robe worn by the Leone Templars' extraordinary inventor. It resembles the uniform of the Sorcery Academy, but it is made with fibers infused of massive magical power. It was specially designed to increase the wearer's Magic power, rather than to protect. The robe is also treated with mysterious Crest sorcery, which inspires the wearer with a desire of knowledge, and can bring out talents in a variety of fields like literature, sorcery and medicine.`
    },
{ //True 'Asterisk' - Complete
        id: 106351,
        name: `True 'Asterisk'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106350.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106351.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106350.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106351.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `60s CT - Increases all Allies' Arts Gauge by 20. Increases all Allies' Arts Gauge by 2 for 10s. Increases Own Arts gauge by 20.`,
                break: 0
            },
        passive: 
            {
                ability1: `When Equipped by Rosetta, Auto-fills Arts gauge by 1. When Magia Drive is activated, Arts gauge 50 UP & extends Magia Drive activation time by 20s (only once).`,
                ability2: `Increases own Magical DMG by 20%.`
            },
        lore: `The pen used to exercise her powerful Crest magic by the genius sorcerer of the Leone Templars, called "Heavenly Witch". She made it by concentrating her knowledge and technique, so the pen nib blazes with magical brilliance. When she writes the Crest runes in the air, she leaves sparkling traces like stars, which earned her another name: "Star Etcher".`
    },
{ //True 'Flambardo' - Complete
        id: 106361,
        name: `True 'Flambardo'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106360.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106361.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106360.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106361.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `25s CT - Reduces all Allies' DMG Taken by 20% for 12s. If an ally's HP is under 50%, reduces DMG by an additional 10%.`,
                break: 0
            },
        passive: 
            {
                ability1: `When Equipped by Sanstone, increases Equipment CT Recovery Speed by 30% and increases MAX HP by 20% (only once).`,
                ability2: `Increases Resistance to Physical DMG by 10%.`
            },
        lore: `This is the shield borne by Sanstone, vice-commander of the Divine Guardian Knights, when she fulfilled her ultimate duty. This proud shield became her symbol, and it held the power to turn back even dragon's breath. It never lost its shine even after long years of use, and though scholars studied it deeply, all they could say was that it was likely the work of the Spirits. The platinum is not only set with countless jewels, but also imbued with the unshakable will of the knight who bore it.`
    },
{ //Incensed Helm 'Astragoth' - Missing Lore
        id: 106371,
        name: `Incensed Helm 'Astragoth'`,
        location: `Mines Equip: Ifrit`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106370.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106371.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106370.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106371.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 120
            },
        skillset:  
            {
                skill: `40s CT - Reduces target's CRI resistance by 50% & negates DMG taken for a total of 1000 for 15s (Only once).`,
                break: 0
            },
        passive: 
            {
                ability1: `Burn Time Reduction & Burn evasion rate 10% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Shadowflame Helm 'Helioskia' - Missing Lore
        id: 106381,
        name: `Shadowflame Helm 'Helioskia'`,
        location: `Mines Equip: Elite Ifrit`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106380.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106381.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106380.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106381.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `60s CT - Reduces target's ATK by 100% & negates DMG taken for a total of 8000 for 20s (Only once).`,
                break: 0
            },
        passive: 
            {
                ability1: `Break Power 12% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Enchanted Rifle 'Stratega' - Complete
        id: 106391,
        name: `Enchanted Rifle 'Stratega'`,
        location: `Limited Event - Spy in the Shadows Part 1 (Dark Fen)`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106390.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106391.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106390.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106391.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `70s CT - 8000% Dark DMG (Magic). Increases DMG to Enemies by 20% for 10s.`,
                break: 800
            },
        passive: 
            {
                ability1: `Increases DMG to Giant Bosses by 10%.`
            },
        lore: `The rifle used by Vanguard Spy Fen. Aside from shooting physical bullets, it can also discharge magical ammunition. Despite the gun being prepared by the client country for Fen specifically for his escort mission, it only took him a few hours to master it. According to Fen, "The greatest weapon chooses its master, but the ultimate weapon has capability to synchronize itself to its owner." However, in truth, his prowess with the gun is most likely a result of his exceptional wit and his experience cultivated on the battlefield.`
    },
{ //Stealth Gear 'Atra Nebula' - Complete
        id: 106401,
        name: `Stealth Gear 'Atra Nebula'`,
        location: `Limited Event - Spy in the Shadows Part 1 (Dark Fen)`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106400.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106401.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106400.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106401.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `20s CT - 2300% Dark DMG (Magic). Reduces enemy's Blind Resistance by 15% for 8s.`,
                break: 400
            },
        passive: 
            {
                ability1: `Increases Blind Time Reduction and Evasion Rate by 10%.`
            },
        lore: `A pitch black hat worn by Fen, given to him by a nation that tasked him with escorting a female captain to them. While it can naturally fulfill its purpose as headwear, it can also be used as a weapon due to it being charged with magic.\n"Hey, Fen! Let me wear that nifty hat, too!"\n"...Rations have no need for fashion."\n"Th-That's just mean!!"`
    },
{ //True 'Stratega' - Complete
        id: 106411,
        name: `True 'Stratega'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106410.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106411.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106410.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106411.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 8800% Dark DMG (Magic). Increases DMG to enemy by 30% for 10s.`,
                break: 1100
            },
        passive: 
            {
                ability1: `When Equipped by Dark Fen, increases own Critical DMG by 50% (only once).`,
                ability2: `Increases DMG to Giant Bosses by 20%.`
            },
        lore: `The beloved rifle of Spy of Darkness Fen. It is said that after experiencing fierce combat during his escort mission, his skills with the weapon came to surpass even those of the greatest marksmen across the land. He was able to use physical and magic bullets in such a way that even the creator of the rifle wouldn't have even imagined. It is easy to see how intelligent and flexible of a warrior Fen is through his apparent lack of difficulty in mastering a new weapon. It's also obvious as to how well this rifle matched Fen due to how quickly he mastered it.`
    },
{ //Stealth Blade 'Nanashi' - Complete
        id: 106421,
        name: `Stealth Blade 'Nanashi'`,
        location: `Limited Event - Spy in the Shadows Part 2 (Dark Kane)`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106420.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106421.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106420.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106421.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `80s CT - 10000% Dark DMG (Physical). If Dark unit, increase own DMG by 30% for 10s.`,
                break: 1200
            },
        passive: 
            {
                ability1: `Own CRI rate 10% UP.`
            },
        lore: `A short sword used by Sword Agent Kane. Crafted by multiple blacksmiths, this weapon got its name from the passion of each blacksmith that forged it. This sword is famous for its extraordinary sharpness and unbreakable blade, and it is very rare and difficult to obtain. Kane chose the sword for his escort mission because he saw an illustration of it in an old tome from another land.`
    },
{ //Twilight Dagger 'YOICHI' - Complete
        id: 106431,
        name: `Twilight Dagger 'YOICHI'`,
        location: `Limited Event - Spy in the Shadows Part 2 (Dark Kane)`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106430.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106431.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106430.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106431.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 120
            },
        skillset:  
            {
                skill: `40s CT - Negates 700 DMG Dealt to all Allies' for 10s. Increases all Allies' ATK by 10% for 10s (only once).`,
                break: 0
            },
        passive: 
            {
                ability1: `Increases Critical DMG by 10%.`
            },
        lore: `A pitch black coat worn by Kane, given to him by a nation that tasked him with escorting a female captain to them. He had originally planned to wear the same clothes as Fen, but he requested they get him the cloak instead because it was cooler.\n"I think you stand out too much to be a bodyguard..."\n"You think so? Well, don't sweat the small stuff! Hahaha!!"\n"...Hmph."`
    },
{ //True 'NANASHI' - Complete
        id: 106441,
        name: `True 'NANASHI'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106440.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106441.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106440.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106441.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `70s CT - 12500% Dark DMG (Physical). If Dark unit, increase own DMG by 50% for 10s.`,
                break: 1500
            },
        passive: 
            {
                ability1: `When equipped by Agent of Darkness Kane, DMG 30% UP & Auto-fills Arts gauge by 1 (Only once).`,
                ability2: `Own CRI rate 20% UP.`
            },
        lore: `A short sword used by Agent of Darkness Kane. He learned how to use the blade to cut through the air and smash rocks, among other things. However, after multiple battles during the escort mission, he realized that a single slash with a calm mind was the strongest technique of them all. Feeling the hearts and souls of the blacksmiths inside of the sword they crafted, he came to recognize the Nanashi as his favorite sword. He displayed it the sword in his room back in Gramenia after returning from the escort mission, never wanting to forget his gratitude and respect toward those who created such a masterpiece.`
    },
{ //魔爪剣『エプルーヴ』 (Subject y2 Raid Equip) - JP Complete
        id: 106451,
        name: `魔爪剣『エプルーヴ』`,
        location: `Raid Equip (Subject y-2)`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106450.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106451.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106450.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106451.png`
            },
        stats:
            {
                hp: 500,
                atk: 500,
                def: 500
            },
        skillset:  
            {
                skill: `50s CT - 6000% Water DMG (Physical). Increase own DMG by 20% and Break Power by 70% for 10s.`,
                break: 1000
            },
        passive: 
            {
                ability1: `Ignore enemy's DEF by 5%.`
            },
        lore: `Currently unreleased in Global.`
    },
{ //闘拳『セイバーアーム』 (Cynthia's Farmable) - JP Complete
        id: 106461,
        name: `闘拳『セイバーアーム』`,
        translate: `'Sabertooth Gauntlets'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106460.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106461.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106460.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106461.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 5000% Water DMG (Physical). Reduce enemy's Physical & Water resistance by 15% for 10s.`,
                break: 900
            },
        passive: 
            {
                ability1: `DMG to Giant Boss 10% UP.`
            },
        lore: `Currently unreleased in Global.`
    },
{ //翔具『マッハチェイサー』 (Cynthia's Farmable) - JP Complete
        id: 106471,
        name: `翔具『マッハチェイサー』`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106470.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106471.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106470.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106471.png`
            },
        stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
        skillset:  
            {
                skill: `30s CT - Increase target's Skill CT speed by 70% & Arts gauge by 1 for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Evasion rate 5% UP.`
            },
        lore: `Currently unreleased in Global.`
    },
{ //True 'Magmation' - Missing Lore
        id: 106481,
        name: `True 'Magmation'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106480.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106481.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106480.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106481.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 5800% Fire DMG (Physical). If Fire unit, increases BE Output by 50% for 8s.`,
                break: 0
            },
        passive: 
            {
                ability1: `When equipped by Gran Brave, DMG 20% UP & all Status 20% UP (Only once).`,
                ability2: `Skill DMG 30% UP.`
            },
        lore: `Missing Lore.`
    },
{ //True 'Sabertooth Gauntlets' - Missing Lore
        id: 106491,
        name: `True 'Sabertooth Gauntlets'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106490.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106491.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106490.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106491.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 9000% Water DMG (Physical). Reduce enemy's Water and Physical resistance by 20% for 12s.`,
                break: 1000
            },
        passive: 
            {
                ability1: `When equipped by Cynthia, increase DMG by 30% and Action Speed by 10% (Only once).`,
                ability2: `Increase DMG to Giant Bosses by 20%.`
            },
        lore: `Missing Lore.`
    },
{ //宵竜刀『スタリサルダ』 (Barossa's Farmable) - JP Complete
        id: 106501,
        name: `宵竜刀『スタリサルダ』`,
        translate: `'Starisarta'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106500.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106501.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106500.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106501.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 8500% Dark DMG (Physical). For 10s, increase all allies' DEF by 40%`,
                break: 1500
            },
        passive: 
            {
                ability1: `Break Power 4% UP.`
            },
        lore: `Currently unreleased in Global.`
    },
{ //輝竜槍『アルバソル』 (Melissa's Farmable) - JP Complete
        id: 106511,
        name: `輝竜槍『アルバソル』`,
        translate: `'Arbasol'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106510.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106511.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106510.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106511.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 8000% Light DMG (Physical). 60% chance to inflict Paralysis.`,
                break: 800
            },
        passive: 
            {
                ability1: `ATK 5% UP.`
            },
        lore: `Currently unreleased in Global.`
    },
{ //True 'Shadow Stella' - Missing Lore
        id: 106521,
        name: `True 'Shadow Stella'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106520.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106521.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106520.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106521.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 10000% Dark DMG (Physical). Increase all allies' DEF by 50% for 10s.`,
                break: 1800
            },
        passive: 
            {
                ability1: `When equipped by Barossa, increase own Max HP by 20% and Arts gauge by 2/s (Only once).`,
                ability2: `Break Power 6% UP.`
            },
        lore: `Missing Lore.`
    },
{ //True 'Arbasol' - Missing Lore
        id: 106531,
        name: `True 'Arbasol'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106530.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106531.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106530.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106531.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 10000% Light DMG (Physical). 70% chance of Paralyzing enemy.`,
                break: 1000
            },
        passive: 
            {
                ability1: `When equipped by Melissa, CRI rate 20% UP & 2% UP for True Arts' Lifesteal amount (Only once).`,
                ability2: `ATK 15% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Divine Arachnid's Blood - Missing Lore (Enchantable Equip)
        id: 106541,
        name: `Divine Arachnid's Blood`,
        location: `Mines Equip: Radachne`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106540.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106541.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106540.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106541.png`
            },
        stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
        skillset:  
            {
                skill: `100s CT - Costs 50% of HP. Increases all allies' CRI rate by 120% for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: [`10% Burn resistance UP.`, `10% Poison resistance UP.`, `10% Freeze resistance UP.`, `10% Blind resistance UP.`, `Auto-Heal 2 HP/s.`],
                ability2: [`None.`, `5% Fire resistance UP.`, `5% Dark resistance UP.`, `5% Light resistance UP.`, `Auto-Heal 4 HP/s.`],
                ability3: [`None.`, `HP Recovery amount 5% UP (Except Lifesteal).`, `Accuracy 10% UP.`]
            },
        lore: `Missing Lore.`
    },
{ //Divine Arachnid's Tears - Missing Lore (Enchantable Equip)
        id: 106551,
        name: `Divine Arachnid's Tears`,
        location: `Mines Equip: Elite Radachne`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106550.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106551.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106550.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106551.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `70s CT - Costs 99% of target's HP. Increases target's Arts gauge by 120.`,
                break: 0
            },
        passive: 
            {
                ability1: [`10% Burn resistance UP.`, `10% Poison resistance UP.`, `10% Freeze resistance UP.`, `5% DMG UP against Dark enemies.`, `5% DMG UP against Light enemies.`],
                ability2: [`None.`, `5% Light resistance UP.`, `5% Fire resistance UP.`, `5% Dark resistance UP.`, `10% DMG UP against God enemies.`, `10% DMG UP against Demon enemies.`]
            },
        lore: `Missing Lore.`
    },
{ //True 'EX Gate' - Complete
        id: 106581,
        name: `True 'EX Gate'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106580.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106581.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106580.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106581.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `70s CT - Increases Target's Arts Gauge by 10 for 15s. Increases all allies' Critical Rate by 100% for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: `When Equipped by Leone, increases DMG by 30% and Heal amount by 10% (except lifesteal). When Demonic True Eclipse is activated, heals HP by 100% (only once).`,
                ability2: `Increases Magic DMG by 20%.`
            },
        lore: `A necklace made following the model of one of the advanced crests, the "Circle Crest". Since it can distribute efficiently the magic power over a wide area, it is suitable for auxiliary use such as a magic barrier. They say that the Princess Leone of Granadia used to wear it, but the historians consider it a mystery because of the discrepancy between the Princess' reign and the manufacturing period.`
    },
{ //Star Crest 'EX Gate' - Complete
        id: 106591,
        name: `Star Crest 'EX Gate'`,
        location: `Side Quest: Knights of Blood - Act 7`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106590.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106591.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106590.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106591.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `80s CT - Auto-fills target's Arts gauge by 10 for 12s. Increases All allies' CRI rate by 50% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Magic DMG 10% UP.`
            },
        lore: `A ritual necklace inscribed with ancient runes used to control the Crest. It was produced in the eastern continent during the Ancient War. Since the magical power is automatically distributed in a very precise way, the user can easily activate it after setting the power and range of magic. In large-scale battles, where many sorcerers who belong to large troops, such the the Imperial Army, move in parallel, gladly take them into the fray.`
    },
{ //Star Robe 'Regalia' - Complete
        id: 106601,
        name: `Star Robe 'Regalia'`,
        location: `Side Quest: Knights of Blood - Act 7`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106600.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106601.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106600.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106601.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 120
            },
        skillset:  
            {
                skill: `30s CT - Negates 300 DMG Dealt to all Allies' for 15s. Reduces all Allies' Light Elemental DMG Taken by 15% for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increases Magical Resistance by 5%.`
            },
        lore: `This is the beloved robe of the guardian of the eone Templars, the knights the protect Granadia. During the manufacturing process, the light of the stars shining in the night sky of Granadia was knitted in the fabric, so that it emits a faint light when it blows in the wind. Not only is it light and durable, but it also has high resistance to magic due to the blessing of light Spirits. Since it takes a lot of time to weave the light of stars in the fibers, it is not suitable for mass production and few are seen even between merchants.`
    },
{ //Dieago's Ring - Missing Lore
        id: 106611,
        name: `Dieago's Ring`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106610.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106611.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106610.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106611.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 6400% Fire DMG (Magic). Increases Fire units' CRI rate to Water enemy by 100% for 12s.`,
                break: 800
            },
        passive: 
            {
                ability1: `Water resistance 10% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Ruschel's Spear 'Amphisbaena' - Missing Lore
        id: 106621,
        name: `Ruschel's Spear 'Amphisbaena'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106620.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106621.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106620.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106621.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `80s CT - 8000% Water DMG (Physical). Increases Water units' Physical DMG to Earth enemy by 50% for 15s.`,
                break: 1800
            },
        passive: 
            {
                ability1: `Earth resistance 10% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Agora's Dagger - Missing Lore
        id: 106631,
        name: `Agora's Dagger`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106630.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106631.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106630.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106631.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `30s CT - 4800% Earth DMG (Magic). If Earth unit, recovers HP by 500.`,
                break: 400
            },
        passive: 
            {
                ability1: `Fire resistance 10% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Obscure Blade 'Vanish' - Missing Lore
        id: 106641,
        name: `Obscure Blade 'Vanish'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106640.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106641.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106640.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106641.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 6800% Dark DMG (Physical). Reduces all allies' Skill CT by 15% for 10s.`,
                break: 1800
            },
        passive: 
            {
                ability1: `DMG 10% UP during Break.`
            },
        lore: `Missing Lore.`
    },
{ //Illusory Boots 'Geist' - Complete
        id: 106651,
        name: `Illusory Boots 'Geist'`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106650.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106651.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106650.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106651.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 120
            },
        skillset:  
            {
                skill: `60s CT - Negates 800 Light Elemental DMG Dealt to all Allies' for 10s. If User is Demon, Increases all Allies' DEF by 50% for 10s (only once).`,
                break: 0
            },
        passive: 
            {
                ability1: `Reduces DMG taken from Enemies by 5% when Near-Death.`
            },
        lore: `The battle boots worn by the leader of the Leone Templars, Duke. Those who saw him on the battlefield described him as a phantom, but it was probably due to the movements greatly accelerated by these "Illusory Boots". Since they are made using the most precious parts of the flying dragons, in addition to ancient and unique sorcery, it is impossible to produce a copy. They so no one can withstand the power of these boots.`
    },
{ //True 'Vanish' - Complete
        id: 106661,
        name: `True 'Vanish'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106660.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106661.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106660.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106661.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 9200% Dark DMG (Physical). Reduces all allies' Skill CT by 30% for 10s.`,
                break: 5500
            },
        passive: 
            {
                ability1: `When equipped by Duke, DMG 30% UP & Skill CT 50% DOWN (Only once).`,
                ability2: `DMG 20% UP during Break.`
            },
        lore: `A large sword that has faced many national crises with Duke of the Templars of Granadia. The inscription on it says: "Every time the blade was stained with blood, a shiver of joy runs down my spine." This is why it was considered a cursed sword for a long time. Since the name of the leader of the Templars was erased from history, the whereabouts of the sword is unknown, but if it is truly a cursed sword, it may still be waiting for a new wielder somewhere in a forgotten demon land.`
    },
{ //双煉杖『ドスクラテル』 (Garm Raid Equip) - JP Complete (109951 = Garm EV Equip)
        id: 106671,
        name: `双煉杖『ドスクラテル』`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106670.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106671.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106670.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106671.png`
            },
            stats:
            {
                hp: 500,
                atk: 500,
                def: 500
            },
        skillset:  
            {
                skill: `30s CT - 4500% Fire DMG (Magic). If enemy is Earth, reduce enemy's CRI resistance by 30% for 5s.`,
                break: 1250
            },
        passive: 
            {
                ability1: `DMG to Earth enemy 10% UP.`
            },
        lore: `Currently unreleased in Global.`
    },
{ //Light Relic 'Light Oath Pendant' - Missing Lore
        id: 106681,
        name: `Light Oath Pendant`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106680.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106681.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106680.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106681.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `30s CT - Increases Light & Dark units' Accuracy rate & DMG by 20% for 5s & Increases Arts gauge by 20 if 'Dark Oath Pendant' is activated.`,
                break: 0
            },
        passive: 
            {
                ability1: `Dark resistance 10% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Dark Relic 'Dark Oath Pendant' - Missing Lore
        id: 106691,
        name: `Dark Oath Pendant`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106690.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106691.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106690.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106691.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `30s CT - Increases Light & Dark units' Break Power by 50% for 5s & Increases Arts gauge by 20 if 'Light Oath Pendant' is activated.`,
                break: 0
            },
        passive: 
            {
                ability1: `Light resistance 10% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Forbidden 'Demonic Seal' - Complete
        id: 106701,
        name: `Forbidden 'Demonic Seal'`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106700.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106701.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106700.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106701.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `30s CT - Increases own Arts Gauge by 3 and DMG by 30% for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increases HP Regeneration by 2.`
            },
        lore: `The hidden shinobi scroll held by Mizuki, young leader of Imari Village. this scroll, passed throughout the generations, contains the secrets of the frame of mind one must have to become a great shinobi. It says that the source of an Imari shinobi's power is the ability to retain their pure heart when they are forced to sully their hands in order to realize their ambitions, even if their body is soaked in the darkness of their craft. When the one reading the scroll is able to understand the meaning behind this, their soul will reach true enlightenment and they will inherit the power of Imari's ninjutsu.`
    },
{ //Ninja Spear 'Netherworld Silver' - Complete
        id: 106711,
        name: `Ninja Spear 'Netherworld Silver'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106710.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106711.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106710.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106711.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `70s CT - 12000% Dark DMG (Physical). Increases own Physical DMG by 35% for 5s.`,
                break: 1800
            },
        passive: 
            {
                ability1: `Skill CT 10% DOWN when Near-Death.`
            },
        lore: `The halberd held by the guardian of Imari Village, Ginzo. The Blade of this spear has not been so much as chipped in the approximately thousand years after the battle i ancient times with the Great Yoma Lord. This spear is called "Dusk Moon Fang" due to its ability to release light like a moonbeam on even the darkest of nights. It's said that the levels of brightness and sharpness change depending on the fighting spirit of its master. This weapon is able to cut through any enemy with its spearhead shining like the moon when its owner is ready to sacrifice their life to protect someone.`
    },
{ //True 'Demonic Seal' - Complete
        id: 106721,
        name: `True 'Demonic Seal'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106720.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106721.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106720.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106721.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `25s CT - Increases Own Arts Gauge by 3 and DMG by 40% for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: `When Equipped by Mizuki, increases Accuracy Rate by 30%, and every time Third Eye is activated, extends Third Eye duration by 60s (only once).`,
                ability2: `Increases HP Regeneration by 10.`
            },
        lore: `The hidden scroll held by Mizuki, the leader of Imari Village. This scroll containing various techniques and battle strategies is said to hide the secret greatness as a shinobi. It's said that only those who have lived honestly and trained both body and mind over a long period of time will be able to understand the true meaning of the scroll. However, the path is long and treacherous. One who is able to arrive at the location described in the scroll will be able to obtain power that is even comparable to that of the Great Yoma Lord.`
    },
{ //True 'Netherworld Silver' - Complete
        id: 106731,
        name: `True 'Netherworld Silver'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106730.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106731.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106730.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106731.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 13000% Dark DMG (Physical). Increases own Physical DMG by 50% for 8s. If enemy is Light, enemy's Dark resistance 20% DOWN.`,
                break: 2000
            },
        passive: 
            {
                ability1: `When equipped by Ginzo, all stats 30% UP & Auto-fills Arts gauge by 2 (Only once).`,
                ability2: `Skill CT 20% DOWN when Near-Death.`
            },
        lore: `The spear used by Ginzo, a legendary shinobi who protected Imari Village. Its tranquil atmosphere resembles the moon, and shines even within the all-enveloping darkness. It is said that this spear stabbed the great Yoma Lord himself, and only truly awakens when it resonates with the feelings of the one who holds it. When the spear's master puts his or her life on the line to protect someone, it transforms into a holy silver spear, and unleashes an attack that resembles a moonbeam cutting through the night to a brighter future ahead.`
    },
{ //Pyro Blade 'Will-o'-the-Wisp' - Complete
        id: 106741,
        name: `Pyro Blade 'Will-o'-the-Wisp'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106740.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106741.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106740.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106741.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `90s CT - 10000% Fire DMG (Physical). Increases own ATK by 50% for 8s. If Fire unit, extra 30%.`,
                break: 2000
            },
        passive: 
            {
                ability1: `Physical DMG from enemy 5% DOWN.`
            },
        lore: `The beloved sword of Kisaragi, high-ranked shinobi living in Imari Village. Its name comes from the way only its sheath flickered in the darkness, as if it were a will-o'-the-wisp. Though it was considered a top class weapon, there are many odd rumors floating around about it, including one that says wounds made by its blade never heal. Kisaragi--who remained a shinobi till the very end--always had this sword at her side.`
    },
{ //Storm Blade 'TSUJI-KAZE' - Complete
        id: 106751,
        name: `Storm Blade 'TSUJI-KAZE'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106750.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106751.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106750.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106751.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `75s CT - 6000% Earth DMG (Physical). Increases all allies' Earth DMG by 30% for 10s.`,
                break: 1900
            },
        passive: 
            {
                ability1: `Own Skill CT 5% DOWN.`
            },
        lore: `The favorite sword of Saku, a shinobi of Imari Village. It is said that the jade-colored blade was created from the leaves of a holy tree in ancient times. It is said that it is able to repair itself when nicked, and keep sharp like no other blade. If the legends are true, this sword is perfect for the shinobi that prefers to fight alone. Although it was passed down throughout the generation within Saku's family without any change to its appearance, Saku decorated the sword with a jewel charm his respected teacher gave to him.`
    },
{ //True 'Will-o'-the-Wisp' - Complete
        id: 106761,
        name: `True 'Will-o'-the-Wisp'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106760.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106761.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106760.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106761.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `80s CT - 11000% Fire DMG (Physical). Increases own ATK by 80% for 12s. If Fire unit, extra 70%.`,
                break: 2500
            },
        passive: 
            {
                ability1: `When equipped by Kisaragi, ATK 50% UP & Arts gauge 100 UP when quest starts (Only once).`,
                ability2: `Physical DMG from enemy 10% DOWN.`
            },
        lore: `The sword of Kisaragi, a teacher of ninja techniques living in Imari Village. This burning blade has experienced much hardship alongside her, and by using memories of those events, has awoken the true strength sleeping within. The flames that flow out of the wounds of enemies cut with this blade eventually envelop them, and burn eternally, much like the passion in Kisaragi's heart. She never spoke of the memories burned into the blade till the day she died. However, the sword understood her feelings as she gazed upon it alone, as if recalling a precious memory.`
    },
{ //True 'TSUJI-KAZE' - Complete
        id: 106771,
        name: `True 'TSUJI-KAZE'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106770.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106771.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106770.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106771.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 9000% Earth DMG (Physical). Increases all allies' Earth DMG by 40% for 10s.`,
                break: 2200
            },
        passive: 
            {
                ability1: `When equipped by Saku, DMG 30% UP & MAX HP 20% UP (Only once).`,
                ability2: `Own Skill CT 10% DOWN.`
            },
        lore: `The favorite sword of Saku, who is aiming to become a full-fledged shinobi. A legend was spoken throughout the generations of Saku's family--one that says that only those who understand the truth of the Holy Tree will be able to awaken the hidden power of the sword. The legitimacy of this legend, however, was confirmed by Saku. After all, he was able to unleash the sword's power due to his efforts during training with his master, who he respected greatly. The jade-colored blade with the ability to ward off evil with the power of the holy tree within it seemed to shine more and more as Saku's heart grew.`
    },
{ //Platina's Golden Bikini - Missing Lore
        id: 106781,
        name: `Platina's Golden Bikini`,
        location: `Limited Equip Summon: Summer Banner`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106780.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106781.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106780.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106781.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `30s CT - Reduces all Allies' DMG Taken by 10% for 15s. Reduces all Allies' DMG Taken from Demons by 25% for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
{ //Parlot's Southern Aloha Shirt - Complete
        id: 106791,
        name: `Parlot's Southern Aloha Shirt`,
        location: `Limited Equip Summon: Summer Banner`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106790.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106791.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106790.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106791.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `50s CT - Increases all Allies' Arts gauge by 2 for 20s. Increases all allies' Skill CT Recovery Speed by 50% for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `This is the summer fashion or Parlot, the right hand of the Divine Knights. This attire, with a beautifully designed shirt and a grass skirt from the southern countries, is not suitable for swimming but he doesn't seem to be particularly bothered by this. It's not like he cannot swim, bit for this summer he didn't feel like it, so he kept reading under the parasol during all the vacation, without touching the water even once.`
    },
{ //Nies' Surf Shorts - Complete
        id: 106801,
        name: `Nies' Surf Shorts`,
        location: `Limited Equip Summon: Summer Banner`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106800.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106801.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106800.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106801.png`
            },
        stats:
            {
                hp: 500,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - Heals all God Type Allies' HP by 300/s for 20s. Heals all Allies' Status Ailments except for Faint.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The surf shorts prepared by the leader of the Divine Knights, Nies. He jumped into the turquoise water surface and was amazed by his first experience of the majestic sea. After reading about the monstrous creatures hidden in the depths of the sea, a colorful paradise spread out in front of his eyes. Admired, he smiled slightly. "After all, you have to confirm everything with your own eyes." According to the guards, when he returned to the beach, he looked more refreshed than usual.`
    },
{ //Sanstone's Summer Dress - Complete
        id: 106811,
        name: `Sanstone's Summer Dress`,
        location: `Limited Equip Summon: Summer Banner`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106810.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106811.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106810.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106811.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `60s CT - Negates 2000 DMG dealt to all Allies. Reduces all Allies' Fire Elemental DMG taken by 50% for 5s (only once).`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The swim dress of the redhead knight Sanstone of the Divine Knights. Passionate as the midsummer sun, she dashes to the vice-leader when she finds her at the beach. She was radiant when she went to buy a swimsuit with the vice-leader in view of the vacation. She tried on one after another and chose this swimsuit on the advice of the vice-leader. "It matches your beautiful hair color!" Embracing the sweet words of her dear friend, her summer begins to shine.`
    },
{ //Sanstone's Monster-shaped Dinghy - Complete
        id: 106821,
        name: `Sanstone's Monster-shaped Dinghy`,
        location: `Limited Equip Summon: Summer Banner`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106820.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106821.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106820.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106821.png`
            },
        stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
        skillset:  
            {
                skill: `40s CT - Increases Target's DEF by 100% and Heal amount by 50% for 15s (except Lifesteal).`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The Invincible Shield of the Divine Knights prepared this monster-shaped dinghy to sail with her beloved vice-leader. "This boat is only for two," she announced to the leader and she got into the boat with the vice-captain. "I see." That's all the captain said. She breathed a sigh of relief. "I wonder what happened if I didn't swallow the words that came up to my lips; or do you want to sail with the vice-captain?"`
    },
{ //Platina's Gorgeous Fan - Complete
        id: 106831,
        name: `Platina's Gorgeous Fan`,
        location: `Limited Equip Summon: Summer Banner`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106830.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106831.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106830.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106831.png`
            },
        stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
        skillset:  
            {
                skill: `100s CT - 100% Non-Elemental DMG (Physical).`,
                break: 5000
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A gorgeous fan that the vice-captain of the Divine Knights bought for the summer vacation. She was planning to deepen her relationship with the captain on the beach. However, the leader was so crazy about swimming that he didn't come back to the beach all day. When the redhead knight stole her fan and began to fan herself in a silly way, her stiff expression naturally relaxed. "Summer days likes this are not that bad".`
    },
{ //蒼魔杖『ミゼラブル』 (Adele's Farmable) - JP Complete
        id: 106841,
        name: `蒼魔杖『ミゼラブル』`,
        translate: `'Empath'`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106840.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106841.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106840.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106841.png`
            },
        stats:
            {
                hp: 500,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `55s CT - Heal all allies' HP by 1500. Increase own Skill CT speed by 40% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Curse Time Reduction & Curse Evasion rate 10% UP.`
            },
        lore: `Currently unreleased in Global.`
    },
{ //True 'Empath' - Missing Lore
        id: 106851,
        name: `True 'Empath'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106850.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106851.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106850.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106851.png`
            },
        stats:
            {
                hp: 500,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - Recovers all allies' HP by 2000. Increases Skill CT speed by 50% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `When equipped by Adele, doubles recovery amount for the lowest HP unit by her Skill (Only once).`,
                ability2: `Curse Time Reduction & Curse evasion rate 20% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Leone's Crest Bikini - Complete
        id: 106861,
        name: `Leone's Crest Bikini`,
        location: `Limited Equip Summon: Knights of Blood Summer Banner`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106860.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106861.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106860.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106861.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `20s CT - Increases own Critical Rate by 100% for 10s. If user is Demon, Heals own HP by 10%.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The bikini of the young princess Leone of the Kingdom of Ganadia. She was so busy that she couldn't buy a swimsuit for herself, but she found one in her older sister's wardrobe, which had been sealed for a long time. Even if she winced because of the audacious design, she decided to wear it anyway, attracted by the image of herself relaxing on the beach. She saw herself in the mirror and felt that she had not reached her sister yet.`
    },
{ //Riviera's Battle Bikini - Complete
        id: 106871,
        name: `Riviera's Battle Bikini`,
        location: `Limited Equip Summon: Knights of Blood Summer Banner`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106870.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106871.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106870.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106871.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `120s CT - 9999% Earth DMG (Physical). Increases Demon allies' Physical DMG by 60% for 20s.`,
                break: 2800
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The self-proclaimed commander of the "Vanguard Battalion" of the Leone templars, Riviera, came to the beach for this summer vacation with a rather daring swimsuit. Her profile moved by the beauty of the sky and the sea is more impressive than anything else. After enjoying the sea for a while, she began to pick up something on the beach. The multicolored shells she collected delivered a little sense of summer to the knights who were waiting back at the castle.`
    },
{ //Riviera's Floating Mattress - Complete
        id: 106881,
        name: `Riviera's Floating Mattress`,
        location: `Limited Equip Summon: Knights of Blood Summer Banner`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106880.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106881.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106880.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106881.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 4000% Earth DMG (Physical). If Demon, increases own ATK by 50% for 10s.`,
                break: 800
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Riviera of the "Brutal War Axe" brought this shell-shaped float to brighten up her summer. Lying next to her float, she was enjoying her vacation. Even if she was dazzled by the sunlight, she was smiling, hearing the cheerful voices of the girls having fun. She she opened her eyes, the smiling princess was in front of her, inviting her. The memory of the three girls dashing to the sea will shine forever in her mind.`
    },
{ //Rosetta's Mermaid Bikini - Complete
        id: 106891,
        name: `Rosetta's Mermaid Bikini`,
        location: `Limited Equip Summon: Knights of Blood Summer Banner`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106890.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106891.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106890.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106891.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `25s CT - Increases Target's Magical DMG by 100% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `This is the mermaid-shaped bikini of the Genius Sorcerer, Rosetta. She is an inventor, who sometimes creates strange items. This swimsuit was developed imitating the characteristics of aquatic beasts, and it is fast enough to generate a shock wave in the water. Precious materials were used to create it, but she sealed it away for a long time afraid that it would create chaos in the world, but she decided to dig it out only for this summer.`
    },
{ //Rosetta's Pareo - Complete
        id: 106901,
        name: `Rosetta's Pareo`,
        location: `Limited Equip Summon: Knights of Blood Summer Banner`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106900.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106901.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106900.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106901.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 4800% Water DMG (Magic). Increases all Allies' Magic DMG Dealt by 20% for 8s.`,
                break: 1000
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A special swimsuit made by the Princess' friend Rosetta, the proud genius sorceress of the Templars. Produced through repeated trial and error, this swimsuit seems to be packed with everything for girls needs. Surely, her image standing on the beach in her pareo makes her look like an adult... but it's always her, rushing innocently. While she talks like a blue streak about the progress of her research, the princess smiles in an embarrassed way.`
    },
{ //Berwick's Magia Surf Shorts - Complete
        id: 106911,
        name: `Berwick's Magia Surf Shorts`,
        location: `Limited Equip Summon: Knights of Blood Summer Banner`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106910.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106911.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106910.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106911.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `20s CT - 3800% Fire DMG (Magic). If user is Demon, Increases own Fire Elemental DMG by 30% for 8s.`,
                break: 600
            },
        passive: 
            {
                ability1: ``
            },
        lore: `He accompanied the Princess at the beach as a guardian, but he felt a little uncomfortable because the Immovable Shield, who was supposed to join them, deserted to carry out a job in town. And he was the only man assigned... He tried to get rid of the embarrassment and focus on his task. Watching his sweat figure standing on the beach, the women at the foreshore smiled saying "very much like him".`
    },
{ //氷霜飾フリオステラ (Ice Wilkes Raid Equip) - JP Complete
        id: 106921,
        name: `氷霜飾フリオステラ`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106920.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106921.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106920.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106921.png`
            },
        stats:
            {
                hp: 500,
                atk: 500,
                def: 500
            },
        skillset:  
            {
                skill: `50s CT - Increase all Allies' Break Power by 30% and Arts Gauge by 2 for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Freeze Time Reduction & Freeze Evasion rate 10% UP.`
            },
        lore: `Currently unreleased in Global.`
    },
{ //焔双刃『インフェリア』 (Orgah's Farmable) - JP Complete
        id: 106931,
        name: `焔双刃『インフェリア』`,
        translate: `'Exferia'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106930.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106931.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106930.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106931.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `55s CT - 7000% Fire DMG (Physical). Increase own Physical DMG by 20% for 8s. If user is Fire, increase own Physical DMG by 40% instead.`,
                break: 800
            },
        passive: 
            {
                ability1: `Physical DMG 20% UP.`
            },
        lore: `Currently unreleased in Global.`
    },
{ //True 'Exferia' - Missing Lore
        id: 106941,
        name: `True 'Exferia'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106940.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106941.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106940.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106941.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 7000% Fire DMG (Physical). Increases Physical DMG by 20% for 8s & if the user is Fire, 30% more DMG.`,
                break: 1000
            },
        passive: 
            {
                ability1: `When equipped by Orgah, DMG to Water enemy 70% UP (Only once).`,
                ability2: `Physical DMG 20% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Genesis Blade “Creation” - Missing Lore
        id: 106951,
        name: `Genesis Blade “Creation”`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106950.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106951.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106950.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106951.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `200s CT - 20000% Dark DMG (Physical).`,
                break: 2500
            },
        passive: 
            {
                ability1: `MAX HP 15% DOWN.`
            },
        lore: `Missing Lore.`
    },
{ //True 'Creation' - Complete
        id: 106961,
        name: `True 'Creation'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106960.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106961.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106960.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106961.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 8000% Dark DMG (Physical). Auto-fills own Arts gauge by 3 & increases Magic DMG by 30% for 8s.`,
                break: 1100
            },
        passive: 
            {
                ability1: `When equipped by Ruthless Demon Divine Celia, all stats 30% UP & Status Ailment resistance 50% UP (Except Faint, Only once).`
            },
        lore: `The sword of dusk wielded by Celia, First Lady of Bamint, in order to build a new world. The Princess craving for the power to destroy the old world, continued to pour the darkness of her heart into this sword, so it eventually sublimated into a sword with a will. This sword which irradiates darkness, turns into a catastrophic weapon when wielded by her. As if embodying the Princess' belief itself, this sword will not fail as long as the wielder has the will to fight.`
    },
{ //Flying Timepiece “Chronos” - Missing Lore
        id: 106971,
        name: `Flying Timepiece “Chronos”`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106970.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106971.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106970.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106971.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `60s CT - Auto-decreases all allies' Arts gauge by 100 for 20s. Increases all allies' Skill CT speed & Skill DMG by 100% for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: `DMG to Gods 10% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Oni Arm 'Crimson Talon' - Complete
        id: 106981,
        name: `Oni Arm 'Crimson Talon'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106980.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106981.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106980.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106981.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `70s CT - 9800% Dark DMG (Physical). Auto-fills own Arts gauge by 3 & reduces DEF by 30% for 10s.`,
                break: 2000
            },
        passive: 
            {
                ability1: `Own ATK 10% UP when Near-Death.`
            },
        lore: `The mechanical arm of Shuri, the shinobi leader of Yakuno Village. Despite having lost her left arm, her true ability awakened when she discovered this mechanical arm, and she was able to rise to the rank of village leader. This mechanical weapon serving as her left arm let her fight her way through countless battlefields, and legend has it that the legend of a monster called an Oni resides inside of it. It has the massive destructive power to even cut through steel. Her second name as a shinobi "Crimson Talon" came from the appearance of this arm.`
    },
{ //True 'Crimson Talon' - Complete
        id: 106991,
        name: `True 'Crimson Talon'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_106990.png`,
                detailmax: `/db/Equipment/Detail/item_detail_106991.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_106990.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_106991.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 12500% Dark DMG (Physical). Auto-fills own Arts gauge by 4 & reduces DEF by 30% for 10s.`,
                break: 2300
            },
        passive: 
            {
                ability1: `When equipped by Shuri, Arts gauge 80 UP every time Third Eye is activated (Only once).`,
                ability2: `Own ATK 30% UP when Near-Death.`
            },
        lore: `The mechanical arm of Shuri, a shinobi who swore revenge against the Demon Fox that destroyed her village. The rage within her heart was able to awaken the power of the Oni inside the arm, and increases its extremely high attack, even further than its initial state. True to her name "Crimson Talon," her hand was covered in the red blood of the victims caught in her claw and crushed to death without mercy. It is said that the shocking appearance of her arm struck fear into the hearts of her enemies, with many even losing the will to fight completely.`
    },
{ //Mech Blades 'Dual Thunderbolts' - Complete
        id: 107001,
        name: `Mech Blades 'Dual Thunderbolts'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107000.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107001.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107000.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107001.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `30s CT - 3000% Light DMG (Physical). Increases own CRI rate by [# of times this equip has been used in quest x 25%] (up to 100%) for 5s.`,
                break: 400
            },
        passive: 
            {
                ability1: `DMG to Humans 10% UP.`
            },
        lore: `The favorite blades of Amane, a shinobi of Yakuno Village. After receiving them from her beloved leader of the village, she used them to eradicate any and all threats to the settlement. Though they were often looked down upon on first glance due to their small size and light weight, Amane was able to show their true value. The lightweight yet strong blades worked perfectly combined with her mechanical legs, quick movements and cold-blooded nature. Spinning like a nimble dancer, she was able to unleash attacks from her blades and slice up her enemies without ever giving them a split second for them to flee.`
    },
{ //True 'Dual Thunderbolts' - Complete
        id: 107011,
        name: `True 'Dual Thunderbolts'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107010.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107011.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107010.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107011.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `20s CT - 3800% Light DMG (Physical). Increases own CRI rate by [# of times this equip has been used in quest x 25%] (up to 100%) for 5s.`,
                break: 500
            },
        passive: 
            {
                ability1: `When equipped by Amane, CRI DMG 70% UP (Only once).`,
                ability2: `DMG to Humans 20% UP.`
            },
        lore: `The dual blades of Amane, a Shinobi who was taken in by Yakuno village when she was abandoned by her parents as a child. After training underground and overcoming her tragic past, she was able to awaken the power sleeping within her. The blades feed on the user's anger, and become faster and sharper the more enraged they become, making their attacks nearly unavoidable. It is said that the first lives the blades took when invading Imari Village where those of Amane's own parents, who abandoned her in the mountains so many years ago.`
    },
{ //Ninja Garb 'Mirage Kimono' - Complete
        id: 107021,
        name: `Ninja Garb 'Mirage Kimono'`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107020.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107021.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107020.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107021.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `65s CT - 6500% Light DMG (Magic). Increases all allies' BE Output by 30% for 10s. If Light Unit, own DMG 20% UP for 10s.`,
                break: 1000
            },
        passive: 
            {
                ability1: `Evasion rate 5% UP when Near-Death.`
            },
        lore: `The combat garb of Shiki, a shinobi from Imari Village located in the Far East. The fabric used for the garb was once woven in her homeland, but the country that once created the beautiful colors was destroyed due to some unknown individual's evildoing. She trained in Imari to learn their shinobi techniques to take revenge on the one who took her home away. It is said that she gained enough power to finally defeat the great Demon.`
    },
{ //True 'Mirage Kimono' - Complete
        id: 107031,
        name: `True 'Mirage Kimono'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107030.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107031.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107030.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107031.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 7500% Light DMG (Magic). Increases all allies' BE Output by 50% for 10s. If Light unit, own DMG 30% UP for 10s.`,
                break: 1300
            },
        passive: 
            {
                ability1: `When equipped by Shiki, extends the Status Ailment time inflicted by her Skill by 2s (only once).`,
                ability2: `Evasion rate 20% UP when Near-Death.`
            },
        lore: `A kimono held by Shiki, a shinobi of Imari Village and the princess of a fallen nation. This garb created from her mother's keepsake is decorated with ornaments from the fallen land of Ena, and is enchanted with a defensive ninjutsu native to Imari Village. This piece of clothing served as emotional support for Shiki, who lived proudly as both a shinobi of Imari Village and the princess of Ena. It is said that even after her greatest enemy, the Demon Fox Uzuki was defeated, the kimono continued to protect her for many, many years.`
    },
{ //Demon Edge 'Blood Moon' - Complete
        id: 107041,
        name: `Demon Edge 'Blood Moon'`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107040.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107041.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107040.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107041.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `70s CT - 8800% Fire DMG (Magic). Reduces Enemies' Fire and Dark Resistance by 20% for 8s.`,
                break: 1500
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A sword used by a girl living in the Far East who was chosen by a sealed Demon Fox to become the vessel for its soul. The sword -which was originally used to exterminate Demons- unleashes flames with a crimson tint, resembling that of the blood moon. Seeking the complete resurrection of the Demon Fox, she used the blade to overwhelm even the most skilled of shinobi. However, it is said that she eventually met her end due to the blade's ability to sharpen by draining the life force of the user.`
    },
{ //True 'Blood Moon' - Complete
        id: 107051,
        name: `True 'Blood Moon'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107050.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107051.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107050.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107051.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `65s CT - 10000% Fire DMG (Magic). Reduces enemy's Fire & Dark resistance by 40% for 10s.`,
                break: 1800
            },
        passive: 
            {
                ability1: `When equipped by Tamae, CRI DMG 60% UP (Only once).`,
                ability2: `ATK 10% UP.`
            },
        lore: `A girl's sword that became the vessel for a Demon Fox. Although the blade was once used by the founder of Ginzo's clan to defeat a Demon Fox, it turned into a threat for the shinobi when it fell into the hands of a girl controlled by the Demon. The sword is able to become sharper by whittling away at the user's life force. However, this power has been changed thanks to the power of the Demon Fox, and it now can also be fed by draining the souls of those it cuts. This sword, that can burn away most of anything with a single stroke, came to be called a Demonic Katana.`
    },
{ //飢刻剣『ドーラブレイド』 (Plant Raid Equip) - JP Complete
        id: 107061,
        name: `飢刻剣『ドーラブレイド』`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107060.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107061.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107060.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107061.png`
            },
        stats:
            {
                hp: 0,
                atk: 1000,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 6000% Earth DMG (Physical). Reduce enemy's Blind and Poison resistance by 30% for 8s.`,
                break: 1000
            },
        passive: 
            {
                ability1: `Skill CT speed 10% UP.`
            },
        lore: `Currently unreleased in Global.`
    },
{ //炎長杖『フレルニカ』 (Vermilia's Farmable) - JP Complete
        id: 107071,
        name: `炎長杖『フレルニカ』`,
        translate: `'Flerunika'`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107070.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107071.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107070.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107071.png`
            },
        stats:
            {
                hp: 500,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - Heal all allies' HP by 30%. Cleanses Seal from self.`,
                break: 0
            },
        passive: 
            {
                ability1: `Seal Time Reduction & Seal Evasion rate 10% UP.`
            },
        lore: `Currently unreleased in Global.`
    },
{ //True 'Flerunica' - Complete
        id: 107081,
        name: `True 'Flerunica'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107080.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107081.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107080.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107081.png`
            },
        stats:
            {
                hp: 500,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - Recovers 35% of all allies' HP & Heals all allies' Seal.`,
                break: 0
            },
        passive: 
            {
                ability1: `When equipped by Vermilia, MAX HP 30% UP & HP Recovery amount 10% UP (Except Lifesteal) (Only once).`,
                ability2: `Seal Time Reduction & Seal Evasion rate 20% UP.`
            },
        lore: `The true form of Vermilia's weapon. When the soul of the legendary beast inside resonates with its holder, it can release an all-consuming, everlasting blaze. Lengthy training in the magical arts gave her a realistic but earnest perspective of its nature. This pure heart was recognized by the spirit of the staff, allowing her to draw out its maximum power.`
    },
{ //碧大鎌『リーザベルテ』 (Vernonica's Farmable) - JP Complete
        id: 107091,
        name: `碧大鎌『リーザベルテ』`,
        translate: `'Lizaverde`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107090.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107091.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107090.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107091.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 5500% Water DMG (Magic). Increase own CRI DMG by 20% for 10s. If user is Water, increase own CRI DMG by 40% instead.`,
                break: 1000
            },
        passive: 
            {
                ability1: `DMG 10% UP when enemy is Broken.`
            },
        lore: `Currently unreleased in Global.`
    },
{ //True 'Lizaverde' - Complete
        id: 107101,
        name: `True 'Lizaverde'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107100.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107101.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107100.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107101.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `30s CT - 6000% Water DMG (Magic). Increases own CRI DMG by 30% for 8s. Water units get an extra 30%.`,
                break: 1200
            },
        passive: 
            {
                ability1: `When equipped by Veronica, CRI DMG 40% UP & Skill CT 40% DOWN (Only once).`,
                ability2: `DMG 20% UP during Break.`
            },
        lore: `There were times when Veronica brought out Lizaverde's power even more than usual. However, she limited herself to only doing so in extreme situations, as making one mistake when releasing that much power could destroy everything in existence. Legend says that when Veronica was unable to contain Lizaverde after it went berserk before, even the sky was cut in half, and she continued to destroy the monsters that appeared around her until the end of her life.`
    },
{ //光輝弓『レイヴァチカ』 (Aluze's Farmable) - JP Complete
        id: 107111,
        name: `光輝弓『レイヴァチカ』`,
        translate: `'Leivachka`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107110.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107111.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107110.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107111.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `100s CT - 12000% Light DMG (Physical). Increase own Physical DMG by 30% for 10s.`,
                break: 2000
            },
        passive: 
            {
                ability1: `5% chance to evade Physical ATK.`
            },
        lore: `Currently unreleased in Global.`
    },
{ //True 'Leivachka' - Missing Lore
        id: 107121,
        name: `True 'Leivachka'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107120.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107121.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107120.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107121.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `90s CT - 12500% Light DMG (Physical). Increases Physical DMG by 40% & if the user is Light, increases CRI rate by 50% for 12s.`,
                break: 2600
            },
        passive: 
            {
                ability1: `When equipped by Aluze, DMG & ATK 30% UP (Only once).`,
                ability2: `10% chance of evading Physical ATK.`
            },
        lore: `Missing Lore.`
    },
{ //剛槍斧『ウーリバルト』 (Garlan's Farmable) - JP Complete
        id: 107131,
        name: `剛槍斧『ウーリバルト』`,
        translate: `'Yulibert'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107130.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107131.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107130.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107131.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `80s CT - 9000% Earth DMG (Physical). Increase own Break Power by 50% for 10s. When used during Giant Boss' Break, increase own Arts gauge by 30.`,
                break: 2500
            },
        passive: 
            {
                ability1: `Break Power 4% UP.`
            },
        lore: `Currently unreleased in Global.`
    },
{ //True 'Yulibert' - Missing Lore
        id: 107141,
        name: `True 'Yulibert'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107140.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107141.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107140.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107141.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `80s CT - 10000% Earth DMG (Physical). Increases Break power by 50% for 15s. Increases Arts gauge by 50 during Giant Boss' Break.`,
                break: 3400
            },
        passive: 
            {
                ability1: `When equipped by Garlan, DMG 50% UP during Break (Only once).`,
                ability2: `Increases Break Power by 8%.`
            },
        lore: `Missing Lore.`
    },
{ //Jack-O-Sherum's Cape - Complete (109561 = Ragsherum EV Equip)
        id: 107151,
        name: `Jack-O-Sherum's Cape`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107150.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107151.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107150.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107151.png`
            },
        stats:
            {
                hp: 500,
                atk: 500,
                def: 500
            },
        skillset:  
            {
                skill: `50s CT - 3000% Dark DMG (Magic). Negates 800 DMG Dealt to all Allies for 10s. Reduces all Allies' DMG Taken by 15% for 10s. Increases all Allies' Seal and Curse Resistance by 100% for 10s (only once).`,
                break: 800
            },
        passive: 
            {
                ability1: `Increases DMG to Light Elemental Units by 20%.`
            },
        lore: `The cape of Phantom Ragsherum when it was possessed by a pumpkin ghost. Donning this, he was seen around the land, wandering through the darkness of the autumn nights. The reason as to why he would perform "tricks" on those he would encounter until he was quelled with a "treat" is still unknown. No one could see his face, as it was covered by a pumpkin, but he somehow seemed joyous when he received a treat and disappeared into the night.`
    },
{ //Jack-O-Bomb - Complete
        id: 107161,
        name: `Jack-O-Bomb`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 4,
        image: 
            {
                detail: ``,
                detailmax: `/db/Equipment/Detail/item_detail_107161.png`,
                thumb: ``,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107161.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `90s CT - 5000% Dark DMG (Magic). 33% chance of Fainting enemy. If enemy doesn't Faint, Faints ownself.`,
                break: 1200
            },
        passive: 
            {
                ability1: [`Fire Weakness Lv5`, `Water Weakness Lv5`, `Earth Weakness Lv5`, `Light Weakness Lv5`, `Dark Weakness Lv5`],
                ability2: [`None.`, `Accuracy 10% UP`, `Attack 5% UP`, `Critical Rate 10% UP`],
                ability3: [`None.`, `Physical Damage 10% UP.`, `Magic Damage 10% UP.`]
            },
        lore: `Mm... Tastes like the bursting flavors of autumn (no, seriously, it will burst. Immediately)!`
    },
{ //Mega Jack-O-Bomb - Complete
        id: 107171,
        name: `Mega Jack-O-Bomb`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: ``,
                detailmax: `/db/Equipment/Detail/item_detail_107171.png`,
                thumb: ``,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107171.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `100s CT - 8000% Dark DMG (Magic). Increase enemy's DMG to ally by 33% & all Allies' DMG to enemy by 33% for 13s.`,
                break: 2000
            },
        passive: 
            {
                ability1: [`Reduces own Max HP by 20%.`, `Reduces own Attack by 40%.`, `Reduces own Defense by 40%`, `Reduces own Status Ailment Resistance by 40%.`],
                ability2: [`None.`, `Increases own Break Power by 6%.`, `Increases own BE Output by 5%.`, `Attacks while ignoring enemy's DEF by 5%.`],
                ability3: [`None.`, `Increases own CRI DMG by 20%.`, `Increases DMG to Light enemies by 20%.`]
            },
        lore: `Mm... Tastes like the bursting flavors of autumn (no, seriously, it will burst... eventually)!`
    },
{ //Prince Berwick's Garb - Complete
        id: 107181,
        name: `Prince Berwick's Garb`,
        location: `Limited Equip Summon: Knights of Blood Halloween Banner`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107180.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107181.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107180.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107181.png`
            },
        stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
        skillset:  
            {
                skill: `30s CT - Increases own DMG by 60% for 12s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The costume of Berwick, Templar of the Kingdom of Granadia. Because Berwick preferred to don more formal garb, he was perplexed at his Queen's request for him to participate in the costume party - a traditional celebration in a world separate from theirs. It was then when he remembered when the previous captain of the Templars was forced to deal with diplomatic matters in the Queen's place when she fell ill. Those who saw him, confident and donned in royal garb, could only assume he was royalty, not a stand-in. Remembering how gallant his former captain looked that day, Berwick knew exactly what he would wear.`
    },
{ //Cheer Squad Riviera - Complete
        id: 107191,
        name: `Cheer Squad Riviera`,
        location: `Limited Equip Summon: Knights of Blood Halloween Banner`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107190.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107191.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107190.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107191.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 5000% Earth DMG (Magic). Heals Male Unit's HP by 15%. Increases Female Unit's ATK by 50% for 10s.`,
                break: 1200
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The costume of Riviera, Templar of the Kingdom of Granadia. She had always had an interest in the garb of the students she happened to see in the kingdom, so she immediately procured her costume as soon as she heard she'd be dressing up. She apparently got it from one of her comrades, who was an alumna of the academy, who borrowed it from a current student. It is said that the kingdom's citizens were quite excited to see Riviera dressed in the uniform, despite her womanly figure, which was quite a mismatch for the uniform normally reserved for younger girls.`
    },
{ //Thetis' Chef Hat - Complete
        id: 107201,
        name: `Thetis' Chef Hat`,
        location: `Limited Equip Summon: Knights of Blood Halloween Banner`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107200.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107201.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107200.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107201.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `35s CT - Reduces Target's DMG Taken by 20% for 8s. Reduces Target's Water Elemental DMG Taken by 50% for 8s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The costume of Thetis, Templar of the Kingdom of Granadia. This cooking fanatic immediately got out this item after hearing that all citizens of the kingdom would be dressing up in costume to celebrate the harvest. After all, a festival always has to have a feast. Imagining his Queen and comrades eating his dishes with glee, master chef Thetis knew this was the time he had to show his true skill. However, those who saw his delicacies had one thought: if he was really going to cook, could one really call his garb a costume.`
    },
{ //Duke's Bad Boy Jacket - Complete
        id: 107211,
        name: `Duke's Bad Boy Jacket`,
        location: `Limited Equip Summon: Knights of Blood Halloween Banner`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107210.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107211.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107210.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107211.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `30s CT - 6000% Dark DMG (Physical). Increases own Skill's Break power by 100% for 10s.`,
                break: 2300
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The sostume of Duke, captain of the Templars of the Kingdom of Granadia. Despite his busy schedule, Duke was forced to chose a festive outfit. Not sure of what to wear, he chose the jacket he found in the basement of the castle storehouse. According to a tome about another world, it was the garb of the leader of a certain kind of combat unit. While he was always happy to see his comrade grow in the ways of the sword, Duke had a secret competitive spirit. It's said that in order to continue to become stronger as captain, he donned this jacket as a sort of oath to himself to train even harder.`
    },
{ //Vampire Cutie Rosetta - Complete
        id: 107221,
        name: `Vampire Cutie Rosetta`,
        location: `Limited Equip Summon: Knights of Blood Halloween Banner`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107220.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107221.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107220.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107221.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `35s CT - Increases all allies' Arts gauge by 5 for 5s and increases all Allies' Break Power by 50% for 5s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The costume of Rosetta, Templar of the Kingdom of Granadia, Leone's friend from school, and master of magic. Excited for the festival, Rosetta and her best friend promised to choose each other's costumes. Seeing the outfit meant for the enchanted undead in another world and its bold design, the magic genius' cheeks turned red. It is said that while she had refused to wear it at first, unable to fight back against her friend's puppy eyes, she ended up donning the outfit and enjoying the festival to the fullest.`
    },
{ //Leone's Candy Pink Smock - Complete
        id: 107231,
        name: `Leone's Candy Pink Smock`,
        location: `Limited Equip Summon: Knights of Blood Halloween Banner`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107230.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107231.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107230.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107231.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 120
            },
        skillset:  
            {
                skill: `40s CT - Negates 1500 DMG Dealt to Self for 10s. Heals Own HP by 20% of DMG Taken for 10s (only once).`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The costume of Leone, queen of the Kingdom of Granadia. After finding a tome with records of an otherworldly festival in which people dressed in costume to celebrate the harvest, Leone was inspired to have her own kingdom hold the event as well. Her magic-using friend chose Leone's garb for her: an outfit that was apparently the uniform of female youth in another world, and anyone who wore this spent their days in good health being adored by the populace. Watching Leone spend the entire festival with a vigorous smile on her face, her friend prayed that those days of happiness would continue without end.`
    },
{ //True 'Brodithung' - Complete
        id: 107241,
        name: `True 'Brodithung'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107240.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107241.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107240.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107241.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 5000% Water DMG (Physical). Increases all allies' Physical DMG by 30% & reduces Physical DMG taken by 10% for 10s.`,
                break: 1500
            },
        passive: 
            {
                ability1: `When equipped by Filia, DMG 20% UP & additionally 20 Arts gained when her Arts 'Dazzling Pool' is used (Only once).`,
                ability2: `HP Recovery amount 20% UP (Except Lifesteal).`
            },
        lore: `With strenuous effort, Filia gained the power to communicate with her weapon. Its blade resembling the blue of pure water could slash through evil with a single stroke. When she stopped to rest in a certain village, a sudden storm caused the river to overflow and strand it. With the help of her sword, she cut the sediment blocking the flow and drained the water. This is one of her favorite stories, but few believe it happened.`
    },
{ //Automatic 'SV61' - Complete
        id: 107251,
        name: `Automatic 'SV61'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107250.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107251.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107250.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107251.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `35s CT - 5000% Fire DMG (Physical). Increases Skill CT speed & Skill DMG by 30% for 5s.`,
                break: 700
            },
        passive: 
            {
                ability1: `Physical DMG 10% UP.`
            },
        lore: `The famed rifle manufacturer Swagger & Vecket made these pistols in a bid for official adoption by the Isliid army. Boasting high precision along with ease of handling and maintenance, many soldiers found them to be reliable side arms. The Fenrir Corps too considers optional gear. The lack stopping power, but imperial-style gunplay has always placed a greater emphasis on technique over sheer force.`
    },
{ //True 'SV61' - Complete
        id: 107261,
        name: `True 'SV61'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107260.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107261.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107260.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107261.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `30s CT - 6400% Fire DMG (Physical). Reduces own Skill CT by 50% & increases own Skill DMG by 50% for 8s.`,
                break: 1000
            },
        passive: 
            {
                ability1: `When equipped by Bullet Hell Roy, all stats 20% UP & Skill DMG 50% UP (Only once).`,
                ability2: `Physical DMG 20% UP.`
            },
        lore: `A full-auto handgun used by the Isliid military and developed by the rifle maker Swagger & Vecket in hopes of escaping bankruptcy. It's not well-liked by other nations' militaries that don't use Imperial Gun Arts, but other members of the firearms industry praise the weapon for its technical finesse. The company successfully regained its financial footing, while also obtaining approval within their own industry (however, the company did not continue developing pistols, but instead went back to making rifles).`
    },
{ //Specialist 'Tailor's Pride' - Complete
        id: 107271,
        name: `Specialist 'Tailor's Pride'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107270.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107271.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107270.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107271.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 2900% Fire DMG (Physical). Increases Fire units' ATK by 25% for 10s.`,
                break: 500
            },
        passive: 
            {
                ability1: `5% chance of evading Physical ATK.`
            },
        lore: `The outfit adopted by the Imperial Information Bureau. It was designed to be both durable enough for regular duty and formal enough for official functions. As the Isliid military does not enforce uniforms, many knights choose to stow theirs during peacetime. In contrast, bureau agents find the flexible fabric highly suitable for office work, so they usually wear them except for undercover or plainclothes operations.`
    },
{ //True 'Valiant' - Missing Lore
        id: 107281,
        name: `True 'Valiant'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107280.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107281.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107280.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107281.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `30s CT - 4500% Light DMG (Physical). Increases DMG to enemy by 20% for 5s (Except God).`,
                break: 0
            },
        passive: 
            {
                ability1: `When equipped by Marzex (Light), CRI DMG 70% UP & Auto-fills Arts gauge by 1 (Only once).`,
                ability2: `DMG from enemy 10% DOWN when Near-Death.`
            },
        lore: `Missing Lore.`
    },
{ //True 'Vengeance' - Missing Lore
        id: 107291,
        name: `True 'Vengeance'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107290.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107291.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107290.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107291.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 8000% Dark DMG (Magic). Increases God unit's DMG by 40% for 8s.`,
                break: 1600
            },
        passive: 
            {
                ability1: `When equipped by Marzex (Dark), Arts gauge 100 UP when quest starts (Only once).`,
                ability2: `Dark resistance 10% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Robe of Mercy 'Vacant' - Complete
        id: 107301,
        name: `Robe of Mercy 'Vacant'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107300.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107301.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107300.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107301.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 6500% Light DMG (Physical). Increases own DMG & Accuracy rate by 30% for 10s.`,
                break: 1300
            },
        passive: 
            {
                ability1: `Increases Critical DMG by 20%.`
            },
        lore: `A mysterious armor that was owned by Marzex. the name appears in the history of Gods, but the details are unknown. Some say it was his daily armor and some say it is a replica made by someone who admired him. Some even say that this armor is Marzex himself before he was sealed by Humans... But this theory is questionable, and of course, no one can prove it. This armor encloses magical power beyond people's imagination.`
    },
{ //Emotional Soul 'Stella Bough' - Missing Lore
        id: 107311,
        name: `Emotional Soul 'Stella Bough'`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107310.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107311.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107310.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107311.png`
            },
        stats:
            {
                hp: 120,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - Recovers all allies' HP by 30%. Reduces all allies' Status Ailment resistance by 50% for 30s.`,
                break: 0
            },
        passive: 
            {
                ability1: `ATK 10% DOWN.`
            },
        lore: `Missing Lore.`
    },
{ //Withered Soul 'Meteor Bough' - Missing Lore
        id: 107321,
        name: `Withered Soul 'Meteor Bough'`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107320.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107321.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107320.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107321.png`
            },
        stats:
            {
                hp: 500,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `25s CT - Recovers target's HP by 100%. Reduces effect amount by 30% every time this equip is used (MAX 90%).`,
                break: 0
            },
        passive: 
            {
                ability1: `MAX HP 5% DOWN.`
            },
        lore: `Missing Lore.`
    },
{ //Sword of the Stars 'Supernova' - Complete
        id: 107331,
        name: `Sword of the Stars 'Supernova'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107330.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107331.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107330.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107331.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `35s CT - 5000% Earth DMG (Physical). If Earth unit, Auto-fills Arts gauge by 1 & increases Physical DMG by 20% for 10s.`,
                break: 800
            },
        passive: 
            {
                ability1: `Own ATK 10% UP when Near-Death.`
            },
        lore: `This sword slices through the air like a meteor, shining brightly on battlefields in the dark of night. It was forged from a specifically made alloy of blessed orihalcon and meteorite, and legends say that the first wielder of this sword shattered an entire mountain with just one swing. It is also said that the sword should be kept unsheathed, as the blade never rusts and continuously hums, as if it is breathing.`
    },
{ //True 'Supernova' - Complete
        id: 107341,
        name: `True 'Supernova'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107340.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107341.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107340.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107341.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `30s CT - 7000% Earth DMG (Physical). If Earth unit, Auto-fills Arts gauge by 1 & increases Physical DMG by 30% for 12s.`,
                break: 900
            },
        passive: 
            {
                ability1: `When equipped by Cestina, Arts DMG of Majestic Grace 150% UP. Auto Fills Arts gauge by 2 (Only once).`,
                ability2: `Own ATK 20% when Near-Death.`
            },
        lore: `Many blessed armaments have been passed down through generations of the royal family. This sword is one of them. While the sword is difficult to handle, it responds to the power of the wielder, and ever since Princess Cestina of the Gran Verge Kingdom, nobody has ever been able to unleash its true power. The name reflects how a single swing of this revered blade is is powerful enough to end any battle.`
    },
{ //Coat of Plates 'Fighting Spirit' - Complete
        id: 107351,
        name: `Coat of Plates 'Fighting Spirit'`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107350.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107351.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107350.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107351.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `45s CT - Negates all allies' DMG for a total of 1000 & reduces Physical DMG taken by 15% for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Own Skill CT 5% DOWN.`
            },
        lore: `One of the garments given to Beasts serving the gran Verge family. This breastplate was only given to the precious few who showed true talent for close combat, making it a point of pride for those who had the chance to wear it. A crystal that improves magic energy conversion is embedded in the center, and it amplifies the wearer's offensive capability on top of providing protection.`
    },
{ //Petra Shield - Missing Lore
        id: 107361,
        name: `Petra Shield`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107360.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107361.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107360.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107361.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `40s CT - Reduces Fire Units' Magic DMG by 35% & Increases Burn & Freeze resistance by 100% for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Poison Time Reduction & Poison evasion rate 20% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Veil of Serenity - Missing Lore
        id: 107371,
        name: `Veil of Serenity`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107370.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107371.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107370.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107371.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `40s CT - If the target is a Water unit, reduces Fire DMG taken by 25% for 10s. If the user is a Water unit, Auto-fills Arts gauge by 5 for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Burn Time Reduction & Burn evasion rate 20% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Wanderer's Jade Flute - Missing Lore
        id: 107381,
        name: `Wanderer's Jade Flute`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107380.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107381.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107380.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107381.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `25s CT - 100% Earth DMG (Magic). Reduces Water enemy's Earth resistance by 30% for 5s. If the user is an Earth unit, 70% chance of Poisoning enemy.`,
                break: 100
            },
        passive: 
            {
                ability1: `Freeze Time Reduction & Freeze evasion rate 20% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Silk Guiding Broom 'Etoile' - Complete
        id: 107391,
        name: `Silk Guiding Broom 'Etoile'`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107390.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107391.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107390.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107391.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `40s CT - Auto-fills target's Arts gauge by 4 for 20s. If Light unit, increases target's Magic DMG by 15% for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Magic DMG from enemy 5% DOWN.`
            },
        lore: `It is said that the best way to measure a mage's power is to examine the catalyst for their magic. This magic broom is one of the finest examples. It was considered one of Gran Verge's greatest treasures. Its handle has highly magically conductive meteorite blended into it and is significantly heavier than it looks. Due to this, only a great mage would be able to pick it up, but Liza wielded it easily with just one hand--a sign of her fearsome and incredible magical power.`
    },
{ //True 'Etoile' - Complete
        id: 107401,
        name: `True 'Etoile'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107400.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107401.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107400.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107401.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `35s CT - Auto-fills target's Arts gauge by 4 for 20s. If Light unit, increases target's Magic DMG by 30% for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: `When equipped by Liza, DMG 30% UP, Curse Resistance 100% UP & Arts gauge 50 UP when quests starts (Only once).`,
                ability2: `Magic DMG from enemy 10% DOWN.`
            },
        lore: `The name of the blacksmith who created this legendary magic broom has been lost to time and there are no other brooms like it. True to its name, this broom might have been created in a spark of inspiration which faded away moments after. In the Gran Verge Kingdom, the broom was cherished by Princess Liza, who became the first head of the Royal Academy of Magic, and it was passed down from her to the future heads of the Academy.`
    },
{ //Magisaw 'Pathmaker' - Complete
        id: 107411,
        name: `Magisaw 'Pathmaker'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107410.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107411.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107410.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107411.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `120s CT - 11000% Dark DMG (Physical). Increases DMG to Light enemy & Giant Boss by 20% for 8s.`,
                break: 1500
            },
        passive: 
            {
                ability1: `Own Skill CT 5% DOWN.`
            },
        lore: `The trees planted in the magnificent garden of the Gran Verge palace were all so special that any ordinary blade could not cut them. This magic saw was prepared for that purpose. Normally, it was activated with the weak magical power that flowed through it's user's body and then used to cut the trees in the garden. However, there was one gardener with an incredible amount of magical power who proved that the magic saw could also be used as a deadly weapon.`
    },
{ //Loyal Santa Vox Plushie - Complete
        id: 107421,
        name: `Loyal Santa Vox Plushie`,
        location: `Limited Equip Summon: Knights of Blood Christmas Banner`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107420.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107421.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107420.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107421.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `7s CT - CRI Mode: Increases own CRI rate by 100% for 10s. Arts gauge Mode: Auto-fills own Arts gauge by 2 for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `This is a plushie that resembles Vox, the former leader of the Leone Templars. The girl finished making the presents for the Templar members and looked at the plushies she just made. After thinking for a while, she started making another one. She gently added the last swordsman to the others. Made from her special feelings, this plushie brought a heartwarming night to the winter wonderland in Granadia.`
    },
{ //Santa Wizard Berwick Plushie - Complete
        id: 107431,
        name: `Santa Wizard Berwick Plushie`,
        location: `Limited Equip Summon: Knights of Blood Christmas Banner`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107430.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107431.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107430.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107431.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 6000% Fire DMG (Magic). Increases own ATK by 60% & CRI DMG by 30% for 10s.`,
                break: 800
            },
        passive: 
            {
                ability1: ``
            },
        lore: `This is a plushie that resembles Berwick when he was a magic sorcerer. Compared to before, his personality changed quite a bit, so his comrades, especially the young sorcerer girl, were teasing him. Listening to them, he remembered the words of their former leader, who saved him and gave him a home. "People change... other people change you." With these words in his heart, he finally accepted his feelings and had a wonderful time.`
    },
{ //Sexy Santa Riviera Plushie - Complete
        id: 107441,
        name: `Sexy Santa Riviera Plushie`,
        location: `Limited Equip Summon: Knights of Blood Christmas Banner`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107440.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107441.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107440.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107441.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 8000% Earth DMG (Magic). Reduces enemy's Earth resistance by 30% for 10s when this Equip scores a critical Hit.`,
                break: 1200
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A plush toy in the likeness of Riviera, the commander of the "Vanguard Battalion". It has a bold design with a plunging neckline to match Riviera's vivacious character. The plush was a gift from the Princess and Riviera was very pleased with it, going so far as to climb a ladder so she could find a good place on the decorative tree to put it. Riviera even dressed up like her plush to celebrate, which made for an uncomfortable situation for her serious compatriots who didn't know where to look.`
    },
{ //Devoted Reindeer Thetis Plushie - Complete
        id: 107451,
        name: `Devoted Reindeer Thetis Plushie`,
        location: `Limited Equip Summon: Knights of Blood Christmas Banner`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107450.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107451.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107450.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107451.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 6000% Water DMG (Physical). Reduces own DMG from enemy by 15% & increases own Break power by 30% for 8s.`,
                break: 900
            },
        passive: 
            {
                ability1: ``
            },
        lore: `This is a plushie that resembles Thetis, the warrior full of kindness. Running through the sky in just one night, the red-nosed subordinate devoted himself to the holy one. Knowing his feelings towards his comrades, the Princess made this plushie first. The receiver smiled embarassed, but seemed pretty satisfied with his handsome little new buddy.`
    },
{ //Dauntless Reindeer Duke Plushie - Complete
        id: 107461,
        name: `Dauntless Reindeer Duke Plushie`,
        location: `Limited Equip Summon: Knights of Blood Christmas Banner`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107460.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107461.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107460.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107461.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 8000% Dark DMG (Physical). If enemy is Giant Boss, DMG to enemy 35% UP for 10s when used during Break.`,
                break: 2500
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The plushie that resembles Duke, the brave warrior of the Templars. The strong knight who led the way of his knights on countless battlefields became a very cute red-nosed reindeer plushie, made by her hands. He did not seem so happy receiving this gift at first, but since he had it with him the whole time at the party, the Princess concluded that he really loved it.`
    },
{ //Strawberry Cake Rosetta Plushie - Complete
        id: 107471,
        name: `Strawberry Cake Rosetta Plushie`,
        location: `Limited Equip Summon: Knights of Blood Christmas Banner`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107470.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107471.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107470.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107471.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `45s CT - Auto-fills all allies' Arts gauge by 3 for 10s. If user is Demon, reduces own Skill CT by 120% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A plush toy in the likeness of the genius sorcerer Rosetta. The Princess had heard of a custom in another world where one would celebrate a holy night by feasting with a loved one. To show her gratitude and love for Rosetta, who was both her knight and her best friend, she sent her plush with a cake design. On the day of the festival, Rosetta and the Princess had tea amid the laughter of the Templars and chatted the day away.`
    },
{ //Giftbox Leone Plushie - Complete
        id: 107481,
        name: `Giftbox Leone Plushie`,
        location: `Limited Equip Summon: Knights of Blood Christmas Banner`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107480.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107481.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107480.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107481.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `90s CT - Increases all allies' CRI rate by 130% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Granadia's young princess Leone made this plush toy for Christmas, a festival from another world. After finding out that it was a festival spent with loved ones, she decided to hold a party with the Templars. She wanted to make individual presents for everyone too, so she started knitting away to make plush toys in everyone's likeness. This winter should bring happy memories for Leone and all her knights.`
    },
{ //Rus' Happy Doll - Complete
        id: 107491,
        name: `Rus' Happy Doll`,
        location: `Limited Equip Summon: Knights of Blood Christmas Banner`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107490.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107491.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107490.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107491.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 120
            },
        skillset:  
            {
                skill: `45s CT - Reduces all allies' DMG taken by 10% & Light Unit's HP Auto-recovery by 20 for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Own HP Heal amount 20% UP (Except Lifesteal).`
            },
        lore: `A doll modeled after a certain Paw Person decorated for Christmas by Iris. Ru, hearing that Fen has a cute doll in his quarters, snuck in to check if the rumor was true and found the spitting image of himself, dressed up for the holidays. It was then said when Fen suddenly returned to his room, he started to scold the furry intruder, but after seeing him shed tears of joy, his expression softened into a slight grin and he patted him on the head.`
    },
{ //妖魔鏡『血月ノ鏡』 (Ugetsu Raid Equip) - JP Complete
        id: 107501,
        name: `妖魔鏡『血月ノ鏡』`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107500.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107501.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107500.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107501.png`
            },
        stats:
            {
                hp: 500,
                atk: 500,
                def: 500
            },
        skillset:  
            {
                skill: `40s CT - 8500% Fire DMG (Magic). Increase own Skill CT speed by 50% and Magic DMG by 20% for 8s.`,
                break: 800
            },
        passive: 
            {
                ability1: `Fire & Dark resistance 5% UP.`
            },
        lore: `Currently unreleased in Global.`
    },
{ //Holy Skull 'Saint's Mind' - Missing Lore
        id: 107511,
        name: `Holy Skull 'Saint's Mind'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107510.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107511.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107510.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107511.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `90s CT - 8000% Light DMG (Physical). Costs own HP by 2000. Reduces enemy's Physical resistance by 15% for 5s. If the user is Light, 10% more.`,
                break: 800
            },
        passive: 
            {
                ability1: `Blind Time Reduction & Blind evasion rate 20% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Stained Wings 'Skia Ptera' - Missing Lore
        id: 107521,
        name: `Stained Wings 'Skia Ptera'`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107520.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107521.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107520.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107521.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `80s CT - 7000% Dark DMG (Magic). Reduces enemy's Magic resistance by 15% for 5s. If the user is Dark, increases DMG by 30% for 5s.`,
                break: 700
            },
        passive: 
            {
                ability1: `Paralysis Time Reduction & Paralysis evasion rate 20% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Assault Rifle Sable - Complete
        id: 107531,
        name: `Assault Rifle Sable`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107530.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107531.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107530.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107531.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 9000% Dark DMG (Physical). Increases own Physical DMG by 30% for 15s. If Human, get an extra 30%.`,
                break: 900
            },
        passive: 
            {
                ability1: `When equipped by Forte, DMG 30% UP & Skill CT 50% DOWN (Only once).`,
                ability2: `Own Accuracy rate 20% UP.`
            },
        lore: `Forte's beloved magic gun, forged and strengthened by using the Adelite Obsidian of Garrah. The original destructive power has been retained, while also having the capability of enhancing the user's latent talents. A magical barrier emerges as an aura around Forte when she wields it. It is said to have a variety of effects, not only on herself, but also the people around her. The power of this gun will allow Forte to reach even greater heights as a formidable commander.`
    },
{ //True 'Assault Rifle Sable' - Missing Lore
        id: 107541,
        name: `True 'Assault Rifle Sable'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107540.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107541.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107540.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107541.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 9000% Dark DMG (Physical). Increases own Physical DMG by 30% for 15s. If Human, get an extra 30%.`,
                break: 900
            },
        passive: 
            {
                ability1: `When equipped by Forte, DMG 30% UP & Skill CT 50% DOWN (Only once).`,
                ability2: `Accuracy 20% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Forte's Boots - Complete
        id: 107551,
        name: `Forte's Boots`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107550.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107551.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107550.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107551.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `30s CT - 3000% Dark DMG (Magic). Reduces own Skill CT by 30% for 8s.`,
                break: 600
            },
        passive: 
            {
                ability1: `Evasion rate 5% UP when Near-Death.`
            },
        lore: `The military boots Forte wears as a memento of her late mother. They're made of a fabric tinged with magical powers, and are unbelievably strong for a piece of fabric equipment. They are not threatened by blades and bullets, and also have resistance to magic. Forte told her troops, "I feel like I'm protected and supported by a mysterious force while I'm wearing these boots." The powerful force that flows from these boots may be the thoughts of her dear parents.`
    },
{ //True 'Shurahalt' - Missing Lore
        id: 107561,
        name: `True 'Shurahalt'`,
        location: `AWK Ganan Stage Clear Reward`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107560.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107561.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107560.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107561.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 8000% Fire DMG (Physical). If Fire unit, increases own DMG by 50% & Break Power by 100% for 6s.`,
                break: 2100
            },
        passive: 
            {
                ability1: `When equipped by Ganan, DMG 80% UP during Berserk mode (Only once).`,
                ability2: `All allies' Break Power 15% UP (Only once).`
            },
        lore: `Missing Lore.`
    },
{ //Alloy Shield 'Arda Core' - Missing Lore
        id: 107571,
        name: `Alloy Shield 'Arda Core'`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107570.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107571.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107570.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107571.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 120
            },
        skillset:  
            {
                skill: `50s CT - Reduces all allies' DMG taken by 40% for 10s & then increases all allies' DMG taken by 40% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `MAX HP 5% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Anti Shield 'Abyss Core' - Missing Lore
        id: 107581,
        name: `Anti Shield 'Abyss Core'`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107580.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107581.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107580.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107581.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `40s CT - Reduces target's DMG by 100% & DMG taken by [Number of Status Ailments x 15%] for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: `DMG to Machines 10% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Sword of Glorious Will 'Grand Destin' - Complete
        id: 107591,
        name: `Sword of Glorious Will 'Grand Destin'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107590.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107591.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107590.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107591.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `100s CT - 11000% Light DMG (Physical). Increases own Physical DMG by 25% for 12s. If God, increases own Accuracy rate by 30%.`,
                break: 1500
            },
        passive: 
            {
                ability1: `Own Skill CT 5% DOWN.`
            },
        lore: `A king's reputation changes drastically depending on the times and how people view him. The decisions he makes always follow his reputation. In this sense, the symbolism of this great sword is significant. The blade has the ability to detect the character of its owner and can only be wielded by someone whose will and determination point them in the right direction. It only responds to those of royal blood who seek to nurture courage, wisdom and strength.`
    },
{ //True 'Grand Destin' - Complete
        id: 107601,
        name: `True 'Grand Destin'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107600.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107601.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107600.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107601.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `75s CT - 13000% Light DMG (Physical). Increases Light unit's DMG by 40% for 10s.`,
                break: 2000
            },
        passive: 
            {
                ability1: `When equipped by Orvell, ATK 50% UP & Equipment DMG 20% UP (only once).`,
                ability2: `BE Output 10% UP.`
            },
        lore: `It is only a sword. A sword made to take lives. It is no different from any other sword. You take it in your hand, brandish it, and cut with its blade. However, Prince Orvell once said that the sword became heavier the more he wielded it. While the weight of the sword cannot actually increase, there is a heaviness to it that can only be felt by those qualified for it. It asks its wielder to become worthy of its weight. That is the true value of this sword which raises kings.`
    },
{ //Supreme Mantle 'Ailes Blanches - Complete
        id: 107611,
        name: `Supreme Mantle 'Ailes Blanches`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107610.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107611.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107610.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107611.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 5200% Light DMG (Physical). If Light unit, increases own DMG by 10% for 8s.`,
                break: 800
            },
        passive: 
            {
                ability1: `Own DEF 5% UP.`
            },
        lore: `The Gran Verge Kingdom has a long history, but the first commander of the knights that comes to mind is none other than Prince Orvell. The prince would put himself at the front line of the battlefield. The mantle he ware gleamed even in the dark of night. It looked like a pair of silver wings tearing through the battlefield. A man who crossed blades with Orvell once said that the prince in his mantle was unlike anything he had seen before, and the sight was breathtakingly beautiful.`
    },
{ //Blazing Greataxe 'Drago La'cryma' - Complete
        id: 107621,
        name: `Blazing Greataxe 'Drago La'cryma'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107620.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107621.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107620.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107621.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `100s CT - 10000% Fire DMG (Physical). Increase own CRI DMG by 30% for 12s.`,
                break: 1200
            },
        passive: 
            {
                ability1: `Break Power 2% UP.`
            },
        lore: `A battle axe which chooses its master. First, the axe itself emits an intense scorching heat meaning it can only be held by those who can withstand its scorching hell. Second, its heaviness. Many a powerful man challenged this battle axe in hopes of becoming its master, but none that challenged were able to lift it. In the end, a small girl of the Dragons was chosen. The axe is not something that responds to physical strength. Rather it responds to those who wish to bring about pain and those who hold blazing emotions within.`
    },
{ //True 'Drago La'cryma' - Complete
        id: 107631,
        name: `True 'Drago La'cryma'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107630.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107631.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107630.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107631.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `55s CT - 9000% Fire DMG (Physical). 100% chance of Burning enemy. If Burned, enemy's Physical resistance reduced by 35% for 8s.`,
                break: 1600
            },
        passive: 
            {
                ability1: `When equipped by Angelas, CRI DMG 60% UP & Dragon Flame's duration increased by 10s (Only once).`,
                ability2: `ATK 10% UP.`
            },
        lore: `This axe was not sought after for the sharpness of its blades. Leaving burning wounds that cannot be dealt with through healing or even revival. The cursed lacerations left behind by its blade reveal the true essence of this axe. The scars burn so hot that a gentle breeze brings rise to screaming pain form slightest touch. Those wounded by this axe are said to have lost themselves in a world of madness and despair. Gouging away at their very flesh, burning their hearts, and devouring them whole. Throughout the ages the axe has been feared as the manifestation of a Dragon once swallowed whole by vengeance and led by obsession.`
    },
{ //Gemstone Boots 'Melusine' - Complete
        id: 107641,
        name: `Gemstone Boots 'Melusine'`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107640.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107641.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107640.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107641.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 4000% Fire DMG (Magic). Increases own DMG by 5% for 8s. Fire units get an extra 10%.`,
                break: 500
            },
        passive: 
            {
                ability1: `5% chance of evading Magic ATK.`
            },
        lore: `Shoes covered in beautiful sparkling jewels, through the jewels resonating with one another magical power is brought forth. Furthermore, the very vibration of the shoes as they tread on the ground further amplifies the magical power even greater, sending it throughout the body of the user. It has been said that those who wear the boots are supplied with a never-ending well of magical power, allowing for extended battle. In fact, it has also been said that they could read the vibrations within the earth and predict the incoming activation of magic. The boots themselves were favored by supreme warriors in battle.`
    },
{ //翠鳳飾『アネモス』 (Tonetaurus Raid Equip) - JP Complete
        id: 107651,
        name: `翠鳳飾『アネモス』`,
        translate: `'Anemos'`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107650.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107651.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107650.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107651.png`
            },
        stats:
            {
                hp: 500,
                atk: 500,
                def: 500
            },
        skillset:  
            {
                skill: `55s CT - Heal all Allies' HP by 200 and increase all Allies' Earth Resistance by 10% for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Poison Time Reduction & Poison Evasion rate 10% UP`
            },
        lore: `Currently unreleased in Global.`
    },
{ //True 'Berserk' - Complete
        id: 107661,
        name: `True 'Berserk'`,
        location: `AWK Grad Stage Clear Reward`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107660.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107661.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107660.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107661.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `70s CT - 6666% Dark DMG (Physical). Reduces enemy's Physical & Dark resistance by 20% for 10s.`,
                break: 1666
            },
        passive: 
            {
                ability1: `When equipped by Grad, DMG 50% UP for 30s after activating True Arts (Only once).`,
                ability2: `CRI DMG 20% UP.`
            },
        lore: `The magical spear of the Demon Grad that hungers for vengeance against a certain swordsman. It absorbed Grad's desire for revenge over countless battles, gaining incredible lethality in the process. Although feared as a cursed spear that drives its wielder mad, causing them to fight until its foes are annihilated, Berserk has no such power. These rumors come from the lunatic appearance of Grad, who shouted crazed warcries as he wielded the spear on the battlefield.`
    },
{ //絢爛細剣『シンティラ』 (Ashleigh's Farmable) - JP Complete
        id: 107671,
        name: `絢爛細剣『シンティラ』`,
        translate: `'Scintilla`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107670.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107671.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107670.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107671.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 6800% Fire DMG (Physical). Increase own DMG against Weak-elemental enemy by 10% for 8s.`,
                break: 800
            },
        passive: 
            {
                ability1: `ATK 5% UP.`
            },
        lore: `Currently unreleased in Global.`
    },
{ //True 'Scintilla' - Missing Lore
        id: 107681,
        name: `True 'Scintilla'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107680.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107681.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107680.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107681.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 8000% Fire DMG (Physical). Increases all allies' CRI DMG by 35% & negates all allies' DMG taken for a total of 1200 for 10s (Only once).`,
                break: 700
            },
        passive: 
            {
                ability1: `When equipped by Ashleigh, CRI rate 20% UP & Skill CT speed 50% UP (Only once).`,
                ability2: `DEF 20% UP.`
            },
        lore: `Missing Lore.`
    },
{ //絢爛外套『ブリランテ』 (Ashleigh's Farmable) - JP Complete
        id: 107691,
        name: `絢爛外套『ブリランテ』`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107690.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107691.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107690.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107691.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `70s CT - Negates all allies' DMG taken by 2000 and heal all Fire allies' HP by 40 for 20s (Only once).`,
                break: 0
            },
        passive: 
            {
                ability1: `Increase own DEF by 10% when under a DMG barrier.`
            },
        lore: `Currently unreleased in Global.`
    },
{ //旗槍『バンディエーラ』 (Salyssa's Farmable) - JP Complete
        id: 107701,
        name: `旗槍『バンディエーラ』`,
        translate: `Bandiera`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107700.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107701.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107700.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107701.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 5800% Earth DMG (Physical). Increase All Allies Skill CT speed by 10% for 8s. Increase Earth allies' Skill CT speed by 20% instead.`,
                break: 600
            },
        passive: 
            {
                ability1: `ATK 10% UP when Near-Death.`
            },
        lore: `Currently unreleased in Global.`
    },
{ //True 'Bandiera' - Complete
        id: 107711,
        name: `True 'Bandiera'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107710.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107711.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107710.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107711.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 8000% Earth DMG (Physical). Reduces enemy's Physical resistance by 20% & Earth resistance by 30% for 10s.`,
                break: 700
            },
        passive: 
            {
                ability1: `When equipped by Salyssa, DMG 30% UP. Arts gauge 10 UP when Skill is used (Only once).`,
                ability2: `Skill DMG 20% UP.`
            },
        lore: `Salyssa's trusty spear. Her painstaking efforts keep it looking surprisingly new for its age. She wants to have it ready to return to the original owner at a moment's notice, which is the only reason she has not removed the unwieldy banner. (She has also unconsciously used it to wipe the sweat from her brow, so she washes it regularly.) When her mother found it more practical as a laundry pole, she made her first attempt to leave home.`
    },
{ //宮廷騎士の帽子 (Salyssa's Farmable) - JP Complete
        id: 107721,
        name: `宮廷騎士の帽子`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107720.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107721.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107720.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107721.png`
            },
        stats:
            {
                hp: 500,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `35s CT - Heal Target's HP by 3500 and Cleanse Target of Poison and Blind. Increase Target's Poison and Blind resistance by 100% for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: `DEF 5% UP.`
            },
        lore: `Currently unreleased in Global.`
    },
{ //Blaze Coil 'Annulus' - Missing Lore
        id: 107731,
        name: `Blaze Coil 'Annulus'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107730.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107731.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107730.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107731.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 3300% Fire DMG (Physical).`,
                break: 0
            },
        passive: 
            {
                ability1: `Break power 100% DOWN. Physical DMG 25% UP (Only once).`
            },
        lore: `Missing Lore.`
    },
{ //Ruinous Coil 'Galelion' - Missing Lore
        id: 107741,
        name: `Ruinous Coil 'Galelion'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107740.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107741.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107740.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107741.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `75s CT - 5500% Fire DMG (Physical).`,
                break: 600
            },
        passive: 
            {
                ability1: `Fire DMG 50% UP. All status 50% DOWN for first 60s of quest (Only once).`
            },
        lore: `Missing Lore.`
    },
{ //Heavy Arms 'Arsenal Wall' - Complete
        id: 107751,
        name: `Heavy Arms 'Arsenal Wall'`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107750.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107751.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107750.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107751.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `50s CT - Reduces all allies' Physical DMG taken by 15% & increases all allies' ATK by 15% for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: `BE Output 5% UP.`
            },
        lore: `A transforming shield invented by a master Human crafter to fight large enemies like Gods, Demons and Dragons. What the Human race lacked in strength was made up by their remarkable creativity and altruism. Together, they devised a weapon with solid defense that could shift to offense at any moment. Any opponent who underestimated it as a simple barrier soon learned that finding a chance to attack left no time to rest.`
    },
{ //True 'Arsenal' - Complete
        id: 107761,
        name: `True 'Arsenal'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107760.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107761.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107760.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107761.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `25s CT - Negates target's DMG taken for a total of 5000 & increases target's DEF by 50% for 12s (Only once).`,
                break: 0
            },
        passive: 
            {
                ability1: `When equipped by Eagle, Animosity gauge & Arts DMG 50% UP (Only once).`,
                ability2: `DMG from enemy 15% DOWN when Near-Death.`
            },
        lore: `A shield invented by Humans that combines offense and defense. Originally carried by soldiers protecting the front lines, it was adapted for use by single units in guerilla warfare. They would force their way to the enemy's weak point then switch to their blades, felling them with one swift motion. Although a reckless strategy, this change was necessary with the Humans often being split into small groups. The fear and hatred born from each battle also imbued their shields with a dark aura.`
    },
{ //Heavy Arms 'Arsenal Leaf' - Complete
        id: 107771,
        name: `Heavy Arms 'Arsenal Leaf'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107770.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107771.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107770.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107771.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 3500% Earth DMG (Physical). Increases DEF by 20% for 10s.`,
                break: 700
            },
        passive: 
            {
                ability1: `Skill CT speed 10% UP when Near-Death.`
            },
        lore: `A streamlined version of the shield created by Humans to fight against larger races like Gods, Demons and Dragons. It's said if the user activates the blade-release mechanism with exact timing, it'll be launched at supersonic speed. It can also cause panic in enemies when they suddenly get torn to shreds by a target presumed to be on the defense.`
    },
{ //Blazing Dragon Goblet 'Rubia Irae' - Missing Lore
        id: 107781,
        name: `Blazing Dragon Goblet 'Rubia Irae'`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107780.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107781.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107780.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107781.png`
            },
        stats:
            {
                hp: 500,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `30s CT - Auto-recovers all allies' HP by 100 for 15s. If the user is a Fire unit, increases all allies' DMG by 10% for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: `When allies are equipped with 3 Dragon Goblet equipments, all allies' all status 20% UP (Only once).`,
                ability2: `HP Recovery amount 10% UP (Except Lifesteal).`
            },
        lore: `Missing Lore.`
    },
{ //Torrent Dragon Goblet 'Altus' - Missing Lore
        id: 107791,
        name: `Torrent Dragon Goblet 'Altus'`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107790.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107791.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107790.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107791.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `30s CT - Increases all allies' Burn resistance by 100% & reduces Physical DMG taken by 15% for 12s. If the user is a Water unit, reduces Physical DMG taken by 10% more.`,
                break: 0
            },
        passive: 
            {
                ability1: `When allies are equipped with 3 Dragon Goblet equipments, all allies' all status 20% UP (Only once).`,
                ability2: `DEF 30% UP when Near-Death.`
            },
        lore: `Missing Lore.`
    },
{ //Sylvan Dragon Goblet 'Granatum' - Missing Lore
        id: 107801,
        name: `Sylvan Dragon Goblet 'Granatum`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107800.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107801.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107800.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107801.png`
            },
        stats:
            {
                hp: 500,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `30s CT - 6500% Earth DMG (Physical). 50% chance of Poisoning enemy. If the user is an Earth unit, reduces enemy's CRI resistance by 30% for 6s.`,
                break: 500
            },
        passive: 
            {
                ability1: `When allies are equipped with 3 Dragon Goblet equipments, all allies' all status 20% UP (Only once).`,
                ability2: `CRI DMG 20% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Cursed Chains 'Discontent' - Complete
        id: 107811,
        name: `Cursed Chains 'Discontent'`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107810.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107811.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107810.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107811.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 4000% Dark DMG (Magic). Reduces enemy's Magic resistance by 10% for 8s.`,
                break: 200
            },
        passive: 
            {
                ability1: `BE Output 10% UP.`
            },
        lore: `All living things are driven by desire. These were lyrics written by a famous minstrel. Though his songs were popular among the nobility and the educated, he himself died with his greatest wish unfulfilled: love. His soul, holding despair great enough to be visible on his lifeless face, was then taken into a violet jewel. Whoever owns it will gains power at the cost of having their being gradually taken over by darkness.`
    },
{ //True 'Discontent' - Complete
        id: 107821,
        name: `True 'Discontent'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107820.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107821.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107820.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107821.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 9000% Dark DMG (Magic). Increases own Arts gauge by 30. Reduces enemy's Dark resistance by 30% for 8s.`,
                break: 1000
            },
        passive: 
            {
                ability1: `When equipped by Bakoo, ATK 30% UP & Arts gauge 50 UP when quest starts (Only once).`,
                ability2: `Magic DMG 10% UP.`
            },
        lore: `Whoever gazes into this gem will be overwhelmed with pain and anger of every variety. It is doubtful any Human, Divine or Demon can withstand such trauma. Regardless, it is still highly sought for its promise of great power. There was one who obtained it for the sake of revenge, but all that remained after its fulfillment was a hollow, shriveled figure.`
    },
{ //False Crown 'Dream Glutton' - Complete
        id: 107831,
        name: `False Crown 'Dream Glutton'`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107830.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107831.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107830.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107831.png`
            },
        stats:
            {
                hp: 500,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - Target's HP Auto-recovery by 300 & increases target's HP Heal amount by 25% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Skill CT speed 10% UP when Near-Death.`
            },
        lore: `Throughout history, people who want authority could be found in spades. Though it would find a crown to sit awkwardly upon their hollow heads, they still dream of this in their bouts of stupidity. Should their fantasy become reality, the weight of the heavy headpiece would crush their spirit and turn them into puppets manipulated by silver tongues. Throughout history, nothing has caused as much trouble as these assertive fools.`
    },
{ //Moonlight Grail 'Aura' - Missing Lore
        id: 107841,
        name: `Moonlight Grail 'Aura'`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107840.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107841.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107840.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107841.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - Reduces DMG taken by 20% & recovers HP by 10% of DMG taken for 10s. If the user is a Light unit, doubles effect time.`,
                break: 0
            },
        passive: 
            {
                ability1: `Arts gauge 20 UP when quest starts (Stacks).`
            },
        lore: `Missing Lore.`
    },
{ //Burial Grail 'Nox' - Missing Lore
        id: 107851,
        name: `Burial Grail 'Nox'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107850.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107851.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107850.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107851.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `100s CT - 7200% Dark DMG (Physical). Increases all allies' Physical DMG by 40% for 25s. If the user is a Dark unit, 20% more.`,
                break: 800
            },
        passive: 
            {
                ability1: `Curse Time Reduction & Curse evasion rate 10% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Expanding Shield 'E-Field' - Complete
        id: 107861,
        name: `Expanding Shield 'E-Field'`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107860.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107861.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107860.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107861.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 1000
            },
        skillset:  
            {
                skill: `40s CT - Reduces all allies' DMG taken & CRI DMG Taken by 20% for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Accuracy 10% UP.`
            },
        lore: `Part of an automatic defense system discovered in an underwound ruin, implanted with Eed's bioengineered cells. It boasts incredible regenerative agility but will grow around its holder if left undisturbed. Should this happen, they will be granted with both impervious defense and an overwhelming urge to battle.`
    },
{ //Astral Robe 'Axis' - Complete
        id: 107871,
        name: `Astral Robe 'Axis'`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107870.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107871.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107870.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107871.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `65s CT - Increases target's Arts gauge by 100. Additionally, increases target's Arts DMG by 10% for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Dark resistance 5% UP.`
            },
        lore: `Garb worn by Leone, the young princess of Granadia. It was once her sister's until she perished in the Sea of Trees, then it was altered to fit her. She's more confident when she wears it since she feels like her sister is watching over her when she does. That's why she brings it to nearly every national event. And although she isn't aware of it, it was the remnants of her sister's power in the robe that controlled her blood rage at the Coffin Ritual.`
    },
{ //Scatter-18 - Missing Lore
        id: 107881,
        name: `Scatter-18`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107880.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107881.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107880.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107881.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 2000% Fire DMG (Magic).`,
                break: 4000
            },
        passive: 
            {
                ability1: `Break Power 10% UP.`
            },
        lore: `Missing Lore.`
    },
{ //True 'Scatter-18' - Complete
        id: 107891,
        name: `True 'Scatter-18'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107890.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107891.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107890.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107891.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 2000% Fire DMG (Magic).`,
                break: 6000
            },
        passive: 
            {
                ability1: `When equipped by Marika, Break power & Arts DMG 30% UP (Only once).`,
                ability2: `Skill CT speed 5% UP.`
            },
        lore: `One of Marika's inventions. It has gone through many revisions over the years to better match her needs. Although many are impressed by her talent to make ordinary objects do extraordinary things, she only sees herself as staying true to her parents' lesson of "treat your belongings with respect".`
    },
{ //Geek Shielder - Missing Lore
        id: 107901,
        name: `Geek Shielder`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107900.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107901.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107900.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107901.png`
            },
        stats:
            {
                hp: 500,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `80s CT - Auto-recovers all allies' HP by 300 & increases all allies' Accuracy rate by 30% for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Burn Time Reduction & Burn evasion rate 10% UP.`
            },
        lore: `Missing Lore.`
    },
{ //True 'Geek Shielder' - Complete
        id: 107911,
        name: `True 'Geek Shielder'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107910.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107911.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107910.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107911.png`
            },
        stats:
            {
                hp: 500,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - Auto-recovers all allies' HP by 300 & reduces all allies' Physical DMG taken by 10% for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: `When equipped by Marika, Arts gauge 100 UP when quest starts (only once).`,
                ability2: `Burn Time Reduction & Burn Evasion rate 20% UP.`
            },
        lore: `One of Marika's inventions. It was designed to only fit her own head, so she has no plans for mass production. Several companies approached her with licensing offers, but the quality she demands drives up the manufacturing costs to unprofitable levels. since most of the features she wants to add will increase its weight, she is always trying to make it lighter.`
    },
{ //JP Equip Name (???) - Unknown
        id: 107921,
        name: `???`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107920.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107921.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107920.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107921.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: ``,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Currently unreleased in Global.`
    },
{ //Phantom Horn 'Cantabile' - Complete
        id: 107931,
        name: `Phantom Horn 'Cantabile'`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107930.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107931.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107930.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107931.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 4400% Light DMG (Magic). Reduces enemy's Light resistance & Magic resistance by 15% for 10s.`,
                break: 800
            },
        passive: 
            {
                ability1: `MAX HP 5% UP.`
            },
        lore: `A strange instrument-shaped weapon that can only be used with the breath of the Spirits. Unlike normal weapons, it does not strike flesh and bone. Instead, it releases unique vibrations carried by sound waves to destroy internal organs. The fact they are difficult to tell from normal instruments meant there was a time where they were common assassination tools and heavily restricted as a result.`
    },
{ //True 'Cantabile' - Complete
        id: 107941,
        name: `True 'Cantabile'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107940.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107941.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107940.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107941.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 6000% Light DMG (Magic). Reduces enemy's Light resistance by 50% for 10s.`,
                break: 1000
            },
        passive: 
            {
                ability1: `When equipped by Victoire, auto-fills Arts gauge by 2 & extends own True Arts duration by 10s.`,
                ability2: `Magic DMG from enemy 10% DOWN.`
            },
        lore: `A fearsome instrument that destroys the target's body from the inside. It's indistinguishable from a standard instrument by amateurs, but a trained expert can unleash a destructive blast with pinpoint accuracy. As it receives air directly from the wielder, it can also convey their emotions. Some have even theorized the proper frequency can affect intangible things like memories and ideas.`
    },
{ //Shako of Aspiration 'Betzfald' - Complete
        id: 107951,
        name: `Shako of Aspiration 'Betzfald'`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107950.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107951.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107950.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107951.png`
            },
        stats:
            {
                hp: 120,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - Recovers 20% of all allies' HP. Light units get an extra 20% & increases Spirit units' Arts gauge by 20.`,
                break: 0
            },
        passive: 
            {
                ability1: `Magic DMG 10% UP.`
            },
        lore: `A hat preferred by musicians. In wearing it, they can become one with their instrument and perform at a level impossible for ordinary people. However, both mental strain and dependence can develop, leading to addiction with overuse. This forced a ban that continues to this day despite protests from critics claiming they are being deprived of the masterful performances they once enjoyed.`
    },
{ //Blade of Secrets 'Insight' - Complete
        id: 107961,
        name: `Blade of Secrets 'Insight'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107960.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107961.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107960.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107961.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 6600% Dark DMG (Physical). Increases ATK & Dark DMG by 35% for 10s.`,
                break: 800
            },
        passive: 
            {
                ability1: `CRI DMG 10% UP.`
            },
        lore: `A weapon from a tribe of the eastern mountains, forged by a blind swordsmith in the sunless dark. As if infused with his passion and soul, whoever uses it will be able to sense the invisible such as what lies in the hearts of others and even the future itself. Its name was earned from anecdotes describing these sorts of feats.`
    },
{ //True 'Insight' - Complete
        id: 107971,
        name: `True 'Insight'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107970.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107971.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107970.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107971.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 10000% Dark DMG (Physical). Increases Skill CT speed by 30% & CRI rate by 70% for 10s.`,
                break: 1300
            },
        passive: 
            {
                ability1: `When equipped by Luahn, CRI DMG 50% UP (Only once).`,
                ability2: `Physical DMG 20% UP.`
            },
        lore: `A sword from the east carried by Luahn, second prince of Gran Verge. This blade chooses its wielder. Depending on how its power is used, it can become so great a burden that it even crushes the mind. However, it becomes a truly exceptional ability once mastered. The opponent's bloodlust, their circulation, their consciousness, and more will be revealed in fine detail, allowing victory with a single slash.`
    },
{ //Voracious Coat 'Splendor' - Complete
        id: 107981,
        name: `Voracious Coat 'Splendor'`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107980.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107981.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107980.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107981.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 4600% Dark DMG (Magic). Lifesteal by 2% of DMG for 5s.`,
                break: 500
            },
        passive: 
            {
                ability1: `DEF 5% UP.`
            },
        lore: `Long ago, this armor was worn by a soldier known as the Voracious Emperor. Its fearsome aura made approach impossible, so he was unscathed after every battle. Gran Verge found this very interesting, so the country that owned it offered it as a gift It was said that the aura was revealed to be a curse, but the kingdom's priests were able to dispel it. Even so, records showed that it remained in storage for a long time, with one of the few exceptions being when Prince Luahn sometimes wore it.`
    },
{ //Oathkeeper 'Testament' - Complete
        id: 107991,
        name: `Oathkeeper 'Testament'`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_107990.png`,
                detailmax: `/db/Equipment/Detail/item_detail_107991.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_107990.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_107991.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `20s CT - 4600% Light DMG (Magic). Increases own Skill CT speed by 70% for 8s. If user is Demon, further increase by 30%.`,
                break: 400
            },
        passive: 
            {
                ability1: `Magic DMG from enemy 5% DOWN.`
            },
        lore: `A short sword created by the Ancients said to be able to withstand unfathomable heat. The light it reflects has the power to annihilate anything it touches. Although it vanished along with the Ancients, a few records mention it came into the possession of the first queen of Granadia. Even so, the authenticity of these records are often debated.`
    },
{ //True 'Testament' - Complete
        id: 108001,
        name: `True 'Testament'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108000.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108001.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108000.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108001.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 8000% Light DMG (Magic). Reduces enemy's Magic resistance by 35% for 10s.`,
                break: 500
            },
        passive: 
            {
                ability1: `When Equipped by Juno, Arts gauge 100 UP & Heals HP by 50% when Demonic True Eclipse is active (Only once).`,
                ability2: `Magic DMG 20% UP.`
            },
        lore: `A short sword masterfully crafted in a massive kiln by the Ancients. It is made of an impossible metal, said to need heat millions of times stronger than the sun to forge. The blade itself also bears three high-ranking Crests layered over each other. This lets it synergize with the powers of its owner, making it much more than a mere dagger.`
    },
{ //Eclipse Crown 'Todestrieb' - Complete
        id: 108011,
        name: `Eclipse Crown 'Todestrieb'`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108010.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108011.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108010.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108011.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `50s CT - Increases all Allies' DMG by 35% for 12s. If user is a Light unit, increase DMG 15% more.`,
                break: 0
            },
        passive: 
            {
                ability1: `Seal Time Reduction & Seal Evasion rate 20% UP.`
            },
        lore: `The crown belonging to Queen Juno of Granadia. Though it has seen the birth and death of stars, legends tell that its luster shall never fade. Records of her ancestor receiving it from the Ancients were eventually found, becoming proof of the Ancients being cooperative with races outside of their own. To prevent theft, replicas of the crown were made while the original was hidden.`
    },
{ //Cursed Shadow 'Asesinato' - Missing Lore
        id: 108021,
        name: `Cursed Shadow 'Asesinato'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108020.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108021.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108020.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108021.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 4000% Dark DMG (Physical). Reduce enemy's Blind resistance by 30% for 10s.`,
                break: 1000
            },
        passive: 
            {
                ability1: `DMG 40% UP to enemy with Blind (Only once).`
            },
        lore: `Missing Lore.`
    },
{ //Rayas' Sunny Aloha Shirt - Complete
        id: 108031,
        name: `Rayas' Sunny Aloha Shirt`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108030.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108031.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108030.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108031.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `30s CT - Reduces all allies' DMG from enemy by 10% for 15s. Reduces all allies' Light & Dark DMG from enemy by an additional 30% for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The summer season has come once again, bringing with it the bright red sun in the skies and the scorching heat of the sandy beaches. Taking a break from his life as an adventurer, Rayas came to the beach in a bright red aloha shirt, ready to spend his time like an adult. Well, that was the plan anyway, until he quickly got bored of relaxing on the beach while looking up at the sky. The moment he heard the voices of his friends having fun, he instantly dashed towards the voices with a smile on his face. It looks like Rayas still isn't quite yet ready to act like an adult at the beach.`
    },
{ //Iris' Sunshine Straw Hat - Complete
        id: 108041,
        name: `Iris' Sunshine Straw Hat`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108040.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108041.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108040.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108041.png`
            },
        stats:
            {
                hp: 120,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - Heals all allies' HP by 20% & Heals all allies' Curse, Freeze, Seal & Blind.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The straw hat with a sky blue ribbon on it the Iris wore with her pure white dress. Iris was so excited for the beach, she made a straight line for the ocean after getting off the carriage that Mira had prepared, with her swimsuit all ready to go, thanks to having put it on that morning before heading out. Without a thought in the world Iris paid no heed to the others who laughed about her innocent excitement. The summer memories of her running towards the waves without a care, will surely become a treasure to her.`
    },
{ //Mira's Tropical Hula - Complete
        id: 108051,
        name: `Mira's Tropical Hula`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108050.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108051.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108050.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108051.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 8000% Fire DMG (Physical). Increases all allies' Fire DMG by 20% and Physical DMG by 30% for 12s.`,
                break: 2000
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The Third Lady of Bamint, Mira, has appeared once again to take it easy at the beach after inviting Rayas and the others to come along. Standing on the pure white beach with her exotic outfit on display, she took in the ocean breeze. Thought she initially hesitated to join Rayas and the others in the water due to her outfit being something a tropical dancer would wear, she ended up running over to join them. With a smile brighter than any sun, she brings a vibrance to a midsummer trip that no one else could.`
    },
{ //Fen's Beach Sunglasses - Complete
        id: 108061,
        name: `Fen's Beach Sunglasses`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108060.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108061.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108060.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108061.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `40s CT - Increases target's Arts gauge by 40. Auto-fills all allies' Arts gauge by 3 for 8s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Upon hearing that Rayas and the others would be going to the beach once again this year, Master Strategist Fen decide to escort them. Supposedly, he has come to the realization that there is no one else besides him who is capable of taking care of them. In a completely different style than that of before, Fen has come to escort them this time in a more relaxed manner and much more relaxed clothing. As he lifted up his sunglasses while looking out over the others playing in the water, he quietly said to himself "Ah... I forgot to bring Ru with me..."`
    },
{ //Serah's Almighty Cute Maid Swimsuit - Complete
        id: 108071,
        name: `Serah's Almighty Cute Maid Swimsuit`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108070.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108071.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108070.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108071.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 3000% Non-Elemental DMG (Magic). Reduces enemy's Burn & Freeze resistance by 40% for 8s.`,
                break: 1000
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Serah's maid clothing is made specially for scientific experiments, with excellent protection against various forms of acids and liquids. While maintaining those capabilities, a swimsuit designed by Marika took form. The swimsuit has a near-perfect resemblance to Serah's maid outfit and it fits her perfectly! It also has functions to improve blood-circulation and a system that helps her from getting lost by showing her where she is at all times. Serah likes the swimsuit so much that she sometimes even wears it while doing experiments.`
    },
{ //Scatter H2O - Complete
        id: 108081,
        name: `Scatter H2O`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108080.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108081.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108080.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108081.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `30s CT - 1600% Water DMG (Magic). Increases Break power by 70% for 5s.`,
                break: 1800
            },
        passive: 
            {
                ability1: ``
            },
        lore: `After Rayas invited Marika to a beach vacation, she quickly set to work on a new kind of water gun. She used her Scatter series as the base but installed a dry heater to expand air for propulsion. This lets it fire a forceful stream with nearly no charge time. It also has a distillation mode to remove salt from sea water.`
    },
{ //Inferno Boots 'Caliente' - Complete
        id: 108091,
        name: `Inferno Boots 'Caliente'`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108090.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108091.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108090.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108091.png`
            },
        stats:
            {
                hp: 500,
                atk: 500,
                def: 500
            },
        skillset:  
            {
                skill: `30s CT - Increases target's DEF by 50% & recovers HP by 50% of Fire DMG taken for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Field Effect "Hellfire" 10% DOWN.`
            },
        lore: `Footwear that contains Ifrit's power. They once belonged to a God whose determination to master the art of combat led him to form a pact with the avatar of flame itself. The wearer will obtain both its scorching might as well as the God's aggressive soul, eager to n=know the thrill of battle once more.`
    },
{ //Scintillation - Complete
        id: 108101,
        name: `Scintillation`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108100.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108101.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108100.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108101.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 4000% Light DMG (Physical). Increases Arts DMG by 30% & Will not Flinch for 7s.`,
                break: 500
            },
        passive: 
            {
                ability1: `DEF 10% UP when Near-Death.`
            },
        lore: `Noel's military jacket. The lightweight yet sturdy fabric not only shields against everything from intense sunlight to frigid air, glancing blades and bullets will leave nary a mark. Essential to his duties in the Zero Guard in a practical sense, the fact he received it directly from the Emperor of Isliid after his induction gives it sentimental value as well.`
    },
{ //True 'Scintillation' - Complete
        id: 108111,
        name: `True 'Scintillation'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108110.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108111.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108110.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108111.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 9000% Light DMG (Physical). Increases own Physical DMG by 30% for 10s & 30% more when own HP is over 80%.`,
                break: 700
            },
        passive: 
            {
                ability1: `When equipped by Noel, Arts DMG 40% UP (Only once).`,
                ability2: `DEF 10% UP.`
            },
        lore: `A jacket worn by Noel, knight of the Zero Guard, fitted to his measurements and perfect to the last detail. Its design is fancy but not garish, allowing him to discreetly protect the Emperor as well as carry out his less exciting duties. The tailor, surprised by how stunning it looked on him, is expecting much more business from Noel in the future.`
    },
{ //True 'Velocity' - Complete
        id: 108121,
        name: `True 'Velocity'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108120.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108121.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108120.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108121.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 8000% Light DMG (Physical). Reduces enemy's Physical resistance by 20% & Light resistance by 30% for 20s.`,
                break: 800
            },
        passive: 
            {
                ability1: `When equipped by Noel, ATK 40% UP (Only once).`,
                ability2: `DMG 10% to Giant Boss.`
            },
        lore: `A pair of blades used by noel, knight of the Zero Guard. They can be wielded as separate weapons to deal with multiple enemies or as a single double-edged sword to break the defenses of a powerful foe. However, this versatility takes a lot of training ad refined judgement to master. Anyone besides Noel might find the blades difficult to use.`
    },
{ //Glister - Complete
        id: 108131,
        name: `Glister`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108130.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108131.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108130.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108131.png`
            },
        stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
        skillset:  
            {
                skill: `30s CT - Auto-fills all allies' Arts gauge by 2 for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `ATK 5% UP.`
            },
        lore: `Noel's boots, made to be comfortable and sturdy enough to wear on long missions. The Zero Guard's outfitters make all their accoutrements by hand, a lengthy and laborious process. This applies to the shoes as well which are molded to each soldier's unique fit and gait. However, that effort grants them sure footing o even the roughest paths.`
    },
{ //Interlocking Edges 'Velocity' - Missing Lore
        id: 108141,
        name: `Interlocking Edges 'Velocity'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108140.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108141.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108140.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108141.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `30s CT - 3200% Light DMG (Physical). If the user is a Light unit, increases DMG by [its usage count x 10%] (MAX 80%) for 10s.`,
                break: 300
            },
        passive: 
            {
                ability1: `MAX HP 15% UP.`
            },
        lore: `Missing Lore.`
    },
{ //魔剣『ゲシュペンスト』・狂 (Ragsherum Phantom Raid Equip) - JP Complete
        id: 108151,
        name: `魔剣『ゲシュペンスト』・狂`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108150.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108151.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108150.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108151.png`
            },
        stats:
            {
                hp: 200,
                atk: 0,
                def: 800
            },
        skillset:  
            {
                skill: `60s CT - 8800% Dark DMG (Physical). Increase own Arts DMG & reduce own DMG resistance by 30% for 20s. If user is Demon, increase own Arts gauge by 20.`,
                break: 900
            },
        passive: 
            {
                ability1: `DMG to Human enemy 10% UP.`
            },
        lore: `Currently unreleased in Global.`
    },
{ //魔剣『ゲシュペンスト』・賢 (Ragsherum Phantom Raid Equip) - JP Complete
        id: 108161,
        name: `魔剣『ゲシュペンスト』・賢`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108160.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108161.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108160.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108161.png`
            },
        stats:
            {
                hp: 200,
                atk: 0,
                def: 800
            },
        skillset:  
            {
                skill: `40s CT - 3000% Dark DMG (Physical). Increase own DEF by 20% and reduce enemy's ATK by 30% for 10s.`,
                break: 700
            },
        passive: 
            {
                ability1: `DMG to God enemy 10% UP.`
            },
        lore: `Currently unreleased in Global.`
    },
{ //Ces' Wavy Frill - Complete
        id: 108171,
        name: `Ces' Wavy Frill`,
        location: `Limited Equip Summon: Cross the Blaze Summer Banner`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108170.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108171.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108170.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108171.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `40s CT - Reduces all allies' Magic DMG taken by 20% & increases Freeze resistance by 100% for 30s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `It was summer, when the royal children longed for freedom in body and spirit. Running along the white coastline and diving into the blue waters helped relieve the stress of their daily duties. Cestina returned to her favorite beach in her favorite swimsuit, and this time with Luahn. All of the siblings together at last had the makings of the best summer ever. Though she wanted to plan something special, she also thought it just as productive to jump right in.`
    },
{ //Liza's Pearly One-Piece - Complete
        id: 108181,
        name: `Liza's Pearly One-Piece`,
        location: `Limited Equip Summon: Cross the Blaze Summer Banner`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108180.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108181.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108180.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108181.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `35s CT - Increases all allies' Arts gauge by 30. Increases Light & Dark units' ATK by 30% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Liza waited for her younger siblings by the castle early in the morning. It wasn't because she was looking forward to visiting the beach; she was simply doing her duty as the oldest. In truth, she found the whole idea of sunburn-risking leisure to be illogical. That was why instead of packing her swimsuit, she wore it beneath her clothes. It was more sensible than needing to change into later, of course -- certainly not a sign of anticipation.`
    },
{ //Orvell's Sunny Trunks - Complete
        id: 108191,
        name: `Orvell's Sunny Trunks`,
        location: `Limited Equip Summon: Cross the Blaze Summer Banner`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108190.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108191.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108190.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108191.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `80s CT - 13500% Light DMG (Physical). Increases ATK by 100% for 10s & if the user is a God, 60% extra.`,
                break: 1000
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The beach was a training ground. Running along the sand built endurance and stability. Swimming improved breathing and focus. As the next king, Orvell always needed to better himself. He didn't visit just to sample Ces' shaved ice, or feast on Luahn's cooking, or watch Liza's dazzling fireworks displays. Those were reserved for special occasions, such as today.`
    },
{ //Luahn's Shady Canopy - Complete
        id: 108201,
        name: `Luahn's Shady Canopy`,
        location: `Limited Equip Summon: Cross the Blaze Summer Banner`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108200.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108201.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108200.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108201.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `80s CT - 5000% Dark DMG (Magic). Reduces enemy's Dark resistance by 30% for 10s & if the user is Human or God, reduce an extra 30%.`,
                break: 300
            },
        passive: 
            {
                ability1: ``
            },
        lore: `During his family's visit to the summer summer island, Luahn hunted. He set traps on animal trails, fished along the shores, and felled birds with stones. He also gathered wild plants, mushrooms, nuts, sea salt, and fruit for their oils and juices. What were once pure survival skills were now employed to prepare a sunset barbeque fit for royalty. Watching his siblings joyfully stuff their mouths also put a slight smile on his face, as well as Chloe's.`
    },
{ //Chloe's Eye Service - Complete
        id: 108211,
        name: `Chloe's Eye Service`,
        location: `Limited Equip Summon: Cross the Blaze Summer Banner`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108210.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108211.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108210.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108211.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 3200% Dark DMG (Physical). 80% chance of Bleeding enemy. 80% chance of increasing Bleed level by 1 when enemy is Bleeding (Max 3 times).`,
                break: 2000
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Chloe couldn't decide what swimsuit to wear. Since Luahn only responded with indifferent mutterings, she decided to ask the fashion-conscious Cestina. Immediately, the princess brought her to the tailor to spend a whole day trying on a hundred different designs. Dazed and confused when she made her choice, it wasn't until later that she took another look at the daring ensemble and realized her mistake.`
    },
{ //Radak's Lifejacket - Complete
        id: 108221,
        name: `Radak's Lifejacket`,
        location: `Limited Equip Summon: Cross the Blaze Summer Banner`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108220.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108221.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108220.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108221.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 120
            },
        skillset:  
            {
                skill: `30s CT - Reduces Fire & Earth units' DMG taken by 20% for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Of course I can swim. I simply wear this as a precaution, not because I'm afraid of the water. It is my duty to rescue the royal children should one start to drown, and it wouldn't do if I needed rescuing myself. Therefore, I must take every possible measure. Moreover, the thought of what might lurk in the depths is terrifyi-- No, never mind. The point being is I need your help too, Virago.`
    },
{ //Fox Raiment 'Ravissante' - Missing Lore
        id: 108231,
        name: `Fox Raiment 'Ravissante'`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108230.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108231.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108230.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108231.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `35s CT - 4000% Dark DMG (Magic). 80% chance to inflict Blind. If enemy is Blinded by this equipment, reduce enemy's Dark resistance by 20% for 10s.`,
                break: 500
            },
        passive: 
            {
                ability1: `Magic resistance 5% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Blaze Garb 'Akanehitoe' - Missing Lore
        id: 108241,
        name: `Blaze Garb 'Akanehitoe'`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108240.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108241.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108240.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108241.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `30s CT - 3500% Fire DMG (Magic). Increases DMG taken by 10% (MAX 50%) every time this equip is used. Increases DMG to enemy by 15% for 15s.`,
                break: 500
            },
        passive: 
            {
                ability1: `DEF 10% DOWN.`
            },
        lore: `Missing Lore.`
    },
{ //Hellflame Garb 'Gurenhitoe' - Missing Lore
        id: 108251,
        name: `Hellflame Garb 'Gurenhitoe'`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108250.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108251.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108250.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108251.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `15s CT - 2000% Dark DMG (Magic). Costs Arts gauge by 20 & 20% of own MAX HP. Reduces enemy's Fire & Dark resistance by 6% for 40s when own HP is over 20% of MAX HP (Stacks).`,
                break: 500
            },
        passive: 
            {
                ability1: `DEF 20% DOWN.`
            },
        lore: `Missing Lore.`
    },
{ //戦闘用絵具『コローリ』 (Raine's Farmable) - JP Complete
        id: 108261,
        name: `戦闘用絵具『コローリ』`,
        translate: `'Colori'`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108260.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108261.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108260.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108261.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 4000% Water DMG (Magic). If own HP is 50% or higher, increase own Arts gauge by 20. If own HP is less than 50%, heal 20% of own MAX HP.`,
                break: 800
            },
        passive: 
            {
                ability1: `Skill CT speed 5% UP.`
            },
        lore: `Currently unreleased in Global.`
    },
{ //True 'Colori' - Missing Lore
        id: 108271,
        name: `True 'Colori'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108270.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108271.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108270.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108271.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 4500% Water DMG (Magic). Increase Magic DMG by 30% for 10s. If the user is a Water unit, 30% more.`,
                break: 500
            },
        passive: 
            {
                ability1: `When equipped by Raine, Skill CT speed 60% UP & DMG 35% UP (Only once).`,
                ability2: `Skill DMG 20% UP.`
            },
        lore: `Missing Lore.`
    },
{ //レインのネコミミ帽子 (Raine's Farmable) - JP Complete
        id: 108281,
        name: `レインのネコミミ帽子`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108280.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108281.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108280.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108281.png`
            },
        stats:
            {
                hp: 500,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `70s CT - Heals target's HP by 5% for 20s. If user is Water, heals target's HP by 8% instead.`,
                break: 0
            },
        passive: 
            {
                ability1: `Water resistance 5% UP.`
            },
        lore: `Currently unreleased in Global.`
    },
{ //創成杖『バストーネ』 (Alulu's Farmable) - JP Complete
        id: 108291,
        name: `創成杖『バストーネ』`,
        translate: `'Bastone'`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108290.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108291.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108290.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108291.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 7500% Dark DMG (Magic). Reduce enemy's and all allies' DMG resistance by 30% for 12s.`,
                break: 800
            },
        passive: 
            {
                ability1: `Magic DMG 10% UP.`
            },
        lore: `Currently unreleased in Global.`
    },
{ //True 'Bastone' - Missing Lore
        id: 108301,
        name: `True 'Bastone'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108300.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108301.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108300.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108301.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 4000% Dark DMG (Magic). Increases Dark DMG by 20% & DMG to Giant Boss by 50% for 15s.`,
                break: 500
            },
        passive: 
            {
                ability1: `When equipped by Alulu, ATK & Magic DMG 30% UP (Only once).`,
                ability2: `Curse Time Reduction & Curse evasion rate 20% UP.`
            },
        lore: `Missing Lore.`
    },
{ //アルルのマント (Alulu's Farmable) - JP Complete
        id: 108311,
        name: `アルルのマント`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108310.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108311.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108310.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108311.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `50s CT - Increase target's Arts gauge by 70. Increase target's Magic DMG by 30% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `DEF 10% UP.`
            },
        lore: `Currently unreleased in Global.`
    },
{ //Edel Kleid - Complete
        id: 108321,
        name: `Edel Kleid`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108320.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108321.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108320.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108321.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 7300% Light DMG (Magic). Costs 1% of own MAX HP & increases Magic DMG by 35% for 15s.`,
                break: 500
            },
        passive: 
            {
                ability1: `Seal Time Reduction & Seal Evasion rate 10% UP.`
            },
        lore: `A dress belonging to Juno, the first queen of Granadia. It was said she wore it at every major ceremony. Though made of thick, sturdy fabric, it was as light as a feather. This meant the skirt's hem swayed with each step, earning her many comparisons to a black lily in the breeze. It was also a superior magic catalyst that transformed to match her needs in combat. This outfit always gave support to the mighty ruler when she needed it most.`
    },
{ //Majestic Crest - Complete
        id: 108331,
        name: `Majestic Crest`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108330.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108331.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108330.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108331.png`
            },
        stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
        skillset:  
            {
                skill: `30s CT - Increases all allies Arts gauge by 12. If user is Light, all allies recover 10% of their HP.`,
                break: 0
            },
        passive: 
            {
                ability1: `DEF 5% UP.`
            },
        lore: `A shiny brooch awarded to Juno from her supporters to commemorate Granadia's founding. Painstakingly crafted form a special alloy with techniques advanced fro the era, it granted a number of benefits such as magic amplification. The great joy she felt in receiving this gift renewed her conviction to become a queen worthy of such warmhearted people.`
    },
{ //Smashing Heart 'MorNyan Star' - Complete
        id: 108341,
        name: `Smashing Heart 'MorNyan Star'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108340.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108341.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108340.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108341.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 7700% Dark DMG (Physical). Costs 30% of MAX HP. Reduces enemy's Physical resistance by 30% for 8s.`,
                break: 800
            },
        passive: 
            {
                ability1: `ATK 10% UP when Near-Death.`
            },
        lore: `A curious iron ball in the shape of a cat's head that seems to guide itself toward the enemy when thrown. Besides the cute and cuddly design, it leaves a distinct pawprint after impact. On flesh, that mark lasts for at least a week, so criminals who value their dignity find the humiliation to be punishment enough.`
    },
{ //True 'MorNyan Star' - Missing Lore
        id: 108351,
        name: `True 'MorNyan Star'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108350.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108351.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108350.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108351.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 9000% Dark DMG (Physical). Increases own CRI DMG by 70% & reduces own Accuracy rate by 30% for 10s.`,
                break: 1000
            },
        passive: 
            {
                ability1: `When equipped by Liese (Dark), Skill CT speed 60% UP (Only once).`,
                ability2: `ATK 20% UP when Near-Death.`
            },
        lore: `Missing Lore.`
    },
{ //Thinking Cap 'GeNyas' - Complete
        id: 108361,
        name: `Thinking Cap 'GeNyas'`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108360.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108361.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108360.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108361.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 1500% Dark DMG (Magic). Increases Skill CT speed by 20% for 10s. If the user is female, 20% more.`,
                break: 400
            },
        passive: 
            {
                ability1: `Magic DMG from enemy 5% DOWN.`
            },
        lore: `Headgear that amplifies intellect and concentration. A gentle warmth stimulates blood flow in the cranium, both activating brain cells and relieving stress. Although it sets up the perfect conditions for inspiration to strike, it also makes the wearer crave sweets. Careful use must be exercised for the waistline's sake.`
    },
{ //Cestina's Scrubs - Complete
        id: 108371,
        name: `Cestina's Scrubs`,
        location: `Limited Equip Summon: Cross the Blaze Halloween Banner`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108370.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108371.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108370.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108371.png`
            },
        stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
        skillset:  
            {
                skill: `35s CT - Auto-fills allies' Arts gauge by 2 & negates DMG taken for a total of 800 for 10s. (Only once).`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Cestina pondered what to wear for the costume party. As royalty, it would have been improper to wear anything too outrageous, but her sister warned against wasting a rare opportunity to show her inner aspirations. In her youth, she adored the white vestments of the healers, compassionately bringing relief to the suffering. Normally they were the only ones allowed to wear them, but they made an exception for one night.`
    },
{ //Angelas' Royal Dress - Complete
        id: 108381,
        name: `Angelas' Royal Dress`,
        location: `Limited Equip Summon: Cross the Blaze Halloween Banner`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108380.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108381.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108380.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108381.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `70s CT - 7000% Fire DMG (Physical). 100% chance of Burning enemy. Reduces Fire resistance by 60% for 10s for every enemy Burned by this Equip.`,
                break: 1000
            },
        passive: 
            {
                ability1: ``
            },
        lore: `When Angelas first received the invitation to Liza's costume party, she didn't want to go. Only after Victoire suggested it would be the perfect chance to get revenge did she pull out her traditional party outfit. Incidentally, they both threatened Eagle to receive his vote for best costume, so he was not seen in the capital on the day of the event.`
    },
{ //Luahn's Sprinting Shorts - Complete
        id: 108391,
        name: `Luahn's Sprinting Shorts`,
        location: `Limited Equip Summon: Cross the Blaze Halloween Banner`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108390.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108391.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108390.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108391.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 3000% Dark DMG (Magic). Increases own Accuracy by 30% for 10s.`,
                break: 500
            },
        passive: 
            {
                ability1: ``
            },
        lore: `As someone who rarely appeared in public, the thought of doing so in costume was enough to make his face turn bright red. Even so, he couldn't refuse Chloe, who wore a rare smile as her needle worked the fabric. The compromise was that he was allowed to bring his sword in case a rambunctious reveler lost control. That mismatch between his scant outfit and his stately weapon earned adoration from some of the Humans.`
    },
{ //Victoire's Catsuit - Complete
        id: 108401,
        name: `Victoire's Catsuit`,
        location: `Limited Equip Summon: Cross the Blaze Halloween Banner`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108400.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108401.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108400.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108401.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 4000% Light DMG (Magic). Increases Arts gauge by 30. Increases Magic DMG by 60% for 8s.`,
                break: 1000
            },
        passive: 
            {
                ability1: ``
            },
        lore: `"I shall be number one." The city was abuzz with word that whoever was voted to have the best costume at the upcoming party would receive a prize from the royal family. She believed winning would take those fools down a peg and place revenge in her grasp. Climbing to the top of the castle, she declared victory in a bold performance that sang her praises. However, when it came time to actually reveal her attraction, she resorted to a forbidden tactic: relying upon the charm of a cute kitty.`
    },
{ //Liza's Smart Skirt - Complete
        id: 108411,
        name: `Liza's Smart Skirt`,
        location: `Limited Equip Summon: Cross the Blaze Halloween Banner`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108410.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108411.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108410.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108411.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `30s CT - Auto-fills all allies' Arts gauge by 3 & Increases all Stats by 20% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `While Liza systematically organized the otherworld data in the tower, their custom of costume parties caught her interest. By letting attendees dress however they wanted, everyone could have fun regardless of status. Enthusiastically taking initiative, she made her own costume to spread the word. The one she chose was the uniform of educated women in the outside world symbolizing knowledge and maturity.`
    },
{ //Orvell's Sailor Shirt - Complete
        id: 108421,
        name: `Orvell's Sailor Shirt`,
        location: `Limited Equip Summon: Cross the Blaze Halloween Banner`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108420.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108421.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108420.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108421.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `30s CT - 3200% Light DMG (Physical). Increases ATK by the usage count x10% (MAX 80%) for 10s.`,
                break: 300
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Orvell's clothes always spoke of the unbroken tradition and dignity of generations, so he was somewhat resistant to wear a costume. This was until his older sister said the festival's tradition was rooted in the homecoming of fishermen and their hauls, who looked forward to the outfits of the royal family. Convinced, he steeled his nerves and shed his regal finery to meet the public's expectations. And that he did, the nautical costume revealing his sculpted physique to all.`
    },
{ //Jack-O-Sherum Plushie - Complete
        id: 108431,
        name: `Jack-O-Sherum Plushie`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108430.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108431.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108430.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108431.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `80s CT - 6666% Dark DMG (Physical). Increases ATK by 50% & Demons' ATK by 30% for 15s.`,
                break: 1000
            },
        passive: 
            {
                ability1: `DMG to Gods 20% UP.`
            },
        lore: `Modeled after the pumpkin-headed monster that roams around threatening tricks if it does not receive treats. One merchant saw a comical charm in his top-heavy appearance and fashioned these dolls. Since he appears only at harvest time, he has already become a symbol of the season, and he will undoubtedly appear during Reigrad's festival this year too.`
    },
{ //旋氷棍『蒼佳奉撃』 (Tami-Nyan's Farmable) - JP Complete
        id: 108441,
        name: `旋氷棍『蒼佳奉撃』`,
        translate: `'Azure Offering'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108440.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108441.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108440.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108441.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 6000% Water DMG (Physical). Reduce enemy's Freeze resistance by 30% for 8s.`,
                break: 1000
            },
        passive: 
            {
                ability1: `Burn Time Reduction & Burn Evasion rate 20% UP.`
            },
        lore: `Currently unreleased in Global.`
    },
{ //True 'Azure Offering' - Missing Lore
        id: 108451,
        name: `True 'Azure Offering'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108450.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108451.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108450.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108451.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 3000% Water DMG (Physical). Increases Break power by 70% for 10s.`,
                break: 3500
            },
        passive: 
            {
                ability1: `When equipped by Tami-nyan, Break power 30% UP. DMG 30% UP during Break (Only once).`,
                ability2: `BE Output 10% UP.`
            },
        lore: `Missing Lore.`
    },
{ //朱玉錘『紅華崩撃』 (Miri-Nyan's Farmable) - JP Complete
        id: 108461,
        name: `朱玉錘『紅華崩撃』`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108460.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108461.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108460.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108461.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `6000% Fire DMG (Physical). Increase own Physical DMG by 30% & reduce enemy's Fire resistance by 20% for 8s.`,
                break: 600
            },
        passive: 
            {
                ability1: `Physical resistance 5% UP.`
            },
        lore: `Currently unreleased in Global.`
    },
{ //Starving Root 'Trypdola' - Complete
        id: 108471,
        name: `Starving Root 'Trypdola'`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108470.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108471.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108470.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108471.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 3000% Earth DMG (Magic). 50% chance of Poisoning enemy. Increases DMG taken by 20% for 20s.`,
                break: 800
            },
        passive: 
            {
                ability1: `MAX HP 5% DOWN.`
            },
        lore: `The root of a divine tree warped beyond recognition by the ravages of war. Hatred tainted its soothing power, and it continues to ooze a poisonous sap even after being removed from the trunk. Anyone who touches it will slowly feel their heart become strangled by the vines of malice.`
    },
{ //Despair Vine 'Groldola' - Missing Lore
        id: 108481,
        name: `Despair Vine 'Groldola'`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108480.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108481.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108480.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108481.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 6000% Earth DMG (Magic). Costs Arts gauge by 50. Increases ATK & Earth DMG by 80% for 15s.`,
                break: 800
            },
        passive: 
            {
                ability1: `Magic DMG 10% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Oracle Index 'Vertrag' - Complete
        id: 108491,
        name: `Oracle Index 'Vertrag'`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108490.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108491.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108490.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108491.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `30s CT - 3000% Earth DMG (Magic). Increases Magic DMG by 6% every time this equip is used (MAX 60%).`,
                break: 300
            },
        passive: 
            {
                ability1: `Magic DMG from enemy 5% DOWN.`
            },
        lore: `A tome of advanced magic theory, mainly covering how to abbreviate incantations while keeping their effectiveness. The text is written in enchanted ink, letting it to not only provide knowledge but act as a potent catalyst for those shortened chants. Its author is the one who devised the groundbreaking technique.`
    },
{ //True 'Vertrag' - Complete
        id: 108501,
        name: `True 'Vertrag'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108500.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108501.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108500.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108501.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 7000% Earth DMG (Magic). Increases DMG by 40% & reduces enemy's Earth resistance by 35% for 10s.`,
                break: 900
            },
        passive: 
            {
                ability1: `When equipped by Hart, all Stats 10% UP & Auto-fills Arts gauge by 2 (Only once).`,
                ability2: `Magic DMG 10% UP.`
            },
        lore: `The tome has a will of its own which allows it to discern the nature of its holder. It becomes more attuned the more it is used, increasing its efficiency as a catalyst. This means it does not value prodigious talent of purity of heart nearly as much as tireless experimentation and insatiable curiosity.`
    },
{ //Empty Grandeur 'Mizer' - Complete
        id: 108511,
        name: `Empty Grandeur 'Mizer'`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108510.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108511.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108510.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108511.png`
            },
        stats:
            {
                hp: 120,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - Auto-recovers target's HP by 200 for 10s. If target is Human, increases DEF by 25% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `DEF 5% UP.`
            },
        lore: `A fancy coat rumored to have belonged to the earl of a distant land who died heirless. It captivated the normally unfashionable Hart the moment he saw it displayed among the wares of travelling peddler, and he scrounged up his meager savings to make the purchase. One of his few material treasures, he is rarely seen leaving his home without it.`
    },
{ //True 'Basilisk' - Missing Lore
        id: 108521,
        name: `True 'Basilisk'`,
        location: `AWK Nier Stage Clear Reward`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108520.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108521.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108520.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108521.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `70s CT - 6666% Dark DMG (Physical). Increases Dark DMG by 44% for 10s.`,
                break: 1666
            },
        passive: 
            {
                ability1: `When equipped by Nier, increases Recovery amount & Accuracy by 30% (Only once).`,
                ability2: `DMG 15% UP to enemy with Poison or Disease.`
            },
        lore: `Missing Lore.`
    },
{ //Santa Ces, Gifting Justice - Complete
        id: 108541,
        name: `Santa Ces, Gifting Justice`,
        location: `Limited Equip Summon: Cross the Blaze Christmas Banner`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108540.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108541.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108540.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108541.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 7700% Earth DMG (Physical). Reduces enemy's Earth resistance by 30% for 10s. Reduces extra 30% when enemy's HP is 50% or less.`,
                break: 500
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A doll fashioned after Princess Cestina of Gran Verge for their Christmas celebration. Her servant, the dexterous Radak, created it after much trial and error despite what its incredible craftsmanship might suggest. As a doll enthusiast, his candlelight toil moved her so much that she baked a variety of seasonal treats to encourage him.`
    },
{ //Santa Liza, Seeking Wisdom - Complete
        id: 108551,
        name: `Santa Liza, Seeking Wisdom`,
        location: `Limited Equip Summon: Cross the Blaze Christmas Banner`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108550.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108551.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108550.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108551.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `20s CT - Increases all allies' Arts gauge by 20. Increases all allies' Magic DMG by 30% for 8s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A doll fashioned after Princess Liza of Gran Verge for their Christmas celebration. Radak made it according to Liza's unreasonable specifications, taking her statement of "Gods are in the details" literally. Its utter perfection -- from the choice of materials to the precise measurements -- was said to have elicited a rare shout of wonder from her lips.`
    },
{ //Santa Orvell, Standing Firm - Missing Lore
        id: 108561,
        name: `Santa Orvell, Standing Firm`,
        location: `Limited Equip Summon: Cross the Blaze Christmas Banner`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108560.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108561.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108560.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108561.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `80s CT - 7500% Light DMG (Physical). Increases ATK by 100% for 10s. If the user is a Light unit, increases Arts gauge by 30.`,
                break: 500
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
{ //Reindeer Luahn, Finding Bravery - Complete
        id: 108571,
        name: `Reindeer Luahn, Finding Bravery`,
        location: `Limited Equip Summon: Cross the Blaze Christmas Banner`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108570.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108571.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108570.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108571.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `30s CT - 3500% Dark DMG (Magic). Increases DMG by 35% for 8s. If CRI, increases Arts gauge by 25 (Only 1 HIT).`,
                break: 500
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A doll fashioned after Prince Luahn of Gran verge for their Christmas celebration. Luahn, aware that he had seldom interacted with Radak, its creator, thought to invite him to the dinner table and deepen their bond. This also let Radak observe Luahn's unique features to recreate in the doll, including a rare but nonetheless genuine smile.`
    },
{ //Reindeer Eagle, Dutiful Defender - Complete
        id: 108581,
        name: `Reindeer Eagle, Dutiful Defender`,
        location: `Limited Equip Summon: Cross the Blaze Christmas Banner`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108580.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108581.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108580.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108581.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `30s CT - Reduces target's DMG taken by 33% & Earth DMG taken by 66% for 8s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Angelas, Victoire and eagle were also invited to Gran Verge's Christmas celebration. Of the three, Radak chose Eagle as the model for a special doll after learning he donated much of his earnings to a needy orphanage. However, the shield-bearing warrior accepted this commemorative gift without a word.`
    },
{ //Jolly Bakoo Doll - Complete
        id: 108591,
        name: `Jolly Bakoo Doll`,
        location: `Limited Equip Summon: Cross the Blaze Christmas Banner`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108590.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108591.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108590.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108591.png`
            },
        stats:
            {
                hp: 500,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - Recovers all allies' HP by 20%. Heals all allies' Disease.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A festive version of the popular Bakoo doll, created by Radak. It served as a base for the dolls modeled after the royal children. The originals were removed from store shelves after the calamity until Liza vouched for their innocence. Glittering decorations also gave their classic look an irresistible charm, reigniting everyone's love for them.`
    },
{ //Jingle Bells - Complete
        id: 108601,
        name: `Jingle Bells`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108600.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108601.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108600.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108601.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 4400% Earth DMG (Magic). Increases ATK & Accuracy by 30% for 8s.`,
                break: 500
            },
        passive: 
            {
                ability1: `BE Output 5% UP.`
            },
        lore: `A blessed instrument said to be used in the celebration of an other world holiday called Christmas. Once a year, people young and old gather around an illuminated tree, enjoy a grand feast, and exchange presents until morning. This event has also started gaining popularity in Raktehelm as the festive decor becomes more elaborate with each passing occasion.`
    },
{ //異神槍『メレク・へレス』 (Alabastor God Raid Equip) - JP Complete
        id: 108631,
        name: `異神槍『メレク・へレス』`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108630.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108631.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108630.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108631.png`
            },
        stats:
            {
                hp: 0,
                atk: 1000,
                def: 0
            },
        skillset:  
            {
                skill: `17s CT - 4000% Dark DMG (Magic). Increase Own Arts Gauge by 7. Increase own Dark DMG by 30% for 10s.`,
                break: 400
            },
        passive: 
            {
                ability1: `DMG taken 30% UP.`
            },
        lore: `Currently unreleased in Global.`
    },
{ //Light Relic 'Miraculous Sands' - Missing Lore
        id: 108641,
        name: `Light Relic 'Miraculous Sands'`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108640.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108641.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108640.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108641.png`
            },
        stats:
            {
                hp: 500,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `80s CT - Auto-recovers Light units' HP by 4% for 40s.`,
                break: 0
            },
        passive: 
            {
                ability1: `When allies are equipped with 5 Light Relics, Light units' DMG 70% UP & Break power 30% UP (Only once).`,
                ability2: `DMG to Dark enemy 5% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Dark Relic 'Abyssal Sands' - Missing Lore
        id: 108651,
        name: `Dark Relic 'Abyssal Sands'`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108650.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108651.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108650.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108651.png`
            },
        stats:
            {
                hp: 500,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `80s CT - Auto-recovers Dark units' HP by 4% for 40s.`,
                break: 0
            },
        passive: 
            {
                ability1: `When allies are equipped with 5 Dark Relics, Dark units' DMG 70% UP & Break power 30% UP (Only once).`,
                ability2: `DMG to Light enemy 5% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Shining Buckler 'Engaged Shield' - Complete
        id: 108661,
        name: `Shining Buckler 'Engaged Shield'`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108660.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108661.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108660.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108661.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `35s CT - Negates Target's DMG taken for a total of 5000 for 15s & Auto-recovers its HP by 2% for 15s if the target is Light (Only once).`,
                break: 0
            },
        passive: 
            {
                ability1: `DEF 10% UP.`
            },
        lore: `As the name implies, one must take a vow to this shield to receive its magical protection. Many do so before thinking about the consequences, and they later come to curse their haste. It is often said getting rid of this shield is much harder than obtaining it.`
    },
{ //True 'Engaged Shield' - Complete
        id: 108671,
        name: `True 'Engaged Shield'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108670.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108671.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108670.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108671.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `30s CT - Reduces DMG taken by 20% for 15s. Reduces additional 20% when HP is 80% or more.`,
                break: 0
            },
        passive: 
            {
                ability1: `When equipped by Miranda, auto-fills Arts gauge by 2 & will not Flinch (Only once).`,
                ability2: `DEF 30% UP when negating DMG.`
            },
        lore: `The ties between shield and holder deepen over time, unlocking its true power. The metal becomes feather-light and harder than diamond. However, when the holder passes their prime, so too does the shield's protection weaken. Indeed, their vow only ends in mutual death. Many such tales exist, all of them stirring and cruel in equal measure.`
    },
{ //Radiant Sword 'Flaredight' - Complete
        id: 108681,
        name: `Radiant Sword 'Flaredight'`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108680.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108681.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108680.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108681.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `35s CT - 3800% Light DMG (Magic). Increases Skill CT speed by 50% for 15s. If the user is Human, auto-fills Arts gauge by 1 for 15s.`,
                break: 400
            },
        passive: 
            {
                ability1: `Skill DMG 10% UP.`
            },
        lore: `One of the few holy weapons made by Human hand. Along with it's legendary craftsmanship, the gems embedded into the blade granted it power over time. Mastery bestows phenomenal speed, enough to cut through a bullet in flight twice. It was once stored in the armory of an imperial nobleman but has since gone missing. The fact that its popularity gave rise to numerous imitations makes finding the genuine article even more difficult.`
    },
{ //Skull Helm 'Dusk Roar' - Missing Lore
        id: 108691,
        name: `Skull Helm 'Dusk Roar'`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108690.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108691.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108690.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108691.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 120
            },
        skillset:  
            {
                skill: `50s CT - Reduces all allies' Magic DMG by 20% & Physical resistance by 20% for 25s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Skill CT speed 5% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Void Helm 'Disaster' - Missing Lore
        id: 108701,
        name: `Void Helm 'Disaster'`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108700.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108701.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108700.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108701.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `35s CT - Reduces all allies' Physical DMG taken by 35% & ATK by 70% for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: `DEF 10% UP.`
            },
        lore: `Missing Lore.`
    },
{ //探求衣『レディメイド』 (Serah's Farmable) - JP Complete
        id: 108711,
        name: `探求衣『レディメイド』`,
        translate: `'Ready Maid'`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108710.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108711.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108710.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108711.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `45s CT - Increase target's Skill CT speed by 100% for 20s. If user is Light, increase own Accuracy rate by 30% for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Dark resistance 10% UP.`
            },
        lore: `Currently unreleased in Global.`
    },
{ //True 'Ready Maid' - Complete
        id: 108721,
        name: `True 'Ready Maid'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108720.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108721.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108720.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108721.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `40s CT - Auto-fills all allies' Arts gauge by 3 & increases Light units; DMG by 30% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `When equipped by Serah, All Stats 20% UP & Skill CT speed 70% DOWN (Only once).`,
                ability2: `10% chance of evading Magic ATK.`
            },
        lore: `Serah's favorite maid dress. The fabric repels everything from water to acid, making it suitable for light housework or dangerous experiments. Professor Arcana has praised how her choice to wear an all-purpose outfit reflects a "battle-ready" mindset. However, his tendency to ask her to make dinner while she's busy has come close to actually starting battles.`
    },
{ //熾光飾『セラフィムアーチ』 (Serah's Farmable) - JP Complete
        id: 108731,
        name: `熾光飾『セラフィムアーチ』`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108730.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108731.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108730.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108731.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `55s CT - 3500% Light DMG (Magic). Increase own DEF by 25% for 10s. If user is Light, increase own ATK by 25% for 10s.`,
                break: 300
            },
        passive: 
            {
                ability1: `Blind Time Reduction & Blind Evasion rate 10% UP.`
            },
        lore: `Currently unreleased in Global.`
    },
{ //Water Relic 'Soul Lattice' - Missing Lore
        id: 108741,
        name: `Water Relic 'Soul Lattice'`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108740.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108741.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108740.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108741.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `45s CT - Auto-recovers HP by 100 & reduces Water units' DMG taken by 20% for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: `When allies are equipped with 5 Water Relics, Water units' DMG 70% UP & Break power 30% UP (Only once).`,
                ability2: `DEF 5% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Earth Relic 'Cyclus Fati' - Missing Lore
        id: 108751,
        name: `Earth Relic 'Cyclus Fati'`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108750.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108751.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108750.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108751.png`
            },
        stats:
            {
                hp: 500,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `80s CT - Auto-recovers Earth units' HP by 5% for 40s.`,
                break: 0
            },
        passive: 
            {
                ability1: `When allies are equipped with 5 Earth Relics, Earth units' DMG 70% UP & Break power 30% UP (Only once).`,
                ability2: `Seal Time Reduction & Seal evasion rate 10% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Fire Relic 'Flaming Knight's Eyes' - Missing Lore
        id: 108761,
        name: `Fire Relic 'Flaming Knight's Eyes'`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108760.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108761.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108760.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108761.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `40s CT - Increases Fire units' Accuracy & HP recovery amount by 30% for 25s.`,
                break: 0
            },
        passive: 
            {
                ability1: `When allies are equipped with 5 Fire Relics, Fire units' DMG 70% UP & Break power 30% UP (Only once).`,
                ability2: `Curse Time Reduction & Curse evasion rate 10% UP.`
            },
        lore: `Missing Lore.`
    },
{ //True 'Wild Cat' - Missing Lore
        id: 108771,
        name: `True 'Wild Cat'`,
        location: `AWK Sophie Stage Clear Reward`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108770.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108771.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108770.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108771.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `60s CT - 1000% Fire DMG (Physical). Increases all allies' Break power by 25% & increases Fire units' DMG by 15% for 20s.`,
                break: 1000
            },
        passive: 
            {
                ability1: `When equipped by Sophie, Accuracy 30% UP & auto-fills Arts gauge by 2 (Only once).`,
                ability2: `DMG 10% UP during Break.`
            },
        lore: `Missing Lore.`
    },
{ //闘弦器『ワンダホー』 (Rits' Farmable) - JP Complete
        id: 108781,
        name: `闘弦器『ワンダホー』`,
        translate: `'Wonderful'`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108780.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108781.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108780.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108781.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 3000% Light DMG (Magic). Increase own DMG against Dark enemy by 20% and own DMG against Demons by 20%.`,
                break: 700
            },
        passive: 
            {
                ability1: `ATK 5% UP.`
            },
        lore: `Currently unreleased in Global.`
    },
{ //True 'Wonderful' - Missing Lore
        id: 108791,
        name: `True 'Wonderful'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108790.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108791.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108790.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108791.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 8000% Light DMG (Magic). Increases Magic DMG by 30% for 10s. If the user is a Light unit, 30% more.`,
                break: 1000
            },
        passive: 
            {
                ability1: `When equipped by Rits, Skill CT speed 50% UP. Arts gauge 50 UP when quest starts (Only once).`,
                ability2: `Dark resistance 10% UP.`
            },
        lore: `Missing Lore.`
    },
{ //奏着『アモーレ』 (Rits' Farmable) - JP Complete
        id: 108801,
        name: `奏着『アモーレ』`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108800.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108801.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108800.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108801.png`
            },
        stats:
            {
                hp: 500,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - Heal target's HP by 200 for 20s. If user is Light, increase target's MAX HP by 20% for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Magic resistance 5% UP.`
            },
        lore: `Currently unreleased in Global.`
    },
{ //Night Jacket - Complete
        id: 108811,
        name: `Night Jacket`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108810.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108811.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108810.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108811.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `35s CT - 4000% Dark DMG (Physical). Increases Skill CT speed by 50% & if the user is a Dark unit, increases ATK by 30% for 8s.`,
                break: 500
            },
        passive: 
            {
                ability1: `Physical DMG from enemy 5% DOWN.`
            },
        lore: `A coat made with a special lightweight textile to keep up with Batiste on his secret missions. It looks flimsier than armor, but reinforced threads and hidden plates shield him from slashing swords and piercing arrows. He has been saved on more than a few occasions thanks to this reliable article.`
    },
{ //True 'Night Jacket' - Missing Lore
        id: 108821,
        name: `True 'Night Jacket'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108820.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108821.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108820.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108821.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 5000% Dark DMG (Physical). Increases Dark DMG by 50% for 8s. Increases Arts gauge by 30 (First time only).`,
                break: 300
            },
        passive: 
            {
                ability1: `When equipped by Batiste, ATK 40% UP & auto-fills Arts gauge by 1 (Only once).`,
                ability2: `DEF 20% UP when Near-Death.`
            },
        lore: `Missing Lore.`
    },
{ //True 'Night Edge' - Complete
        id: 108831,
        name: `True 'Night Edge'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108830.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108831.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108830.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108831.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 6000% Dark DMG (Physical). In the user is a Dark unit, reduces enemy's Physical resistance by 20% for 8s.`,
                break: 600
            },
        passive: 
            {
                ability1: `When equipped by Batiste, DMG 40% UP & Auto-fills Arts gauge by 1 (Only once).`,
                ability2: `DMG 10% UP when enemy's HP is under 30%.`
            },
        lore: `Batiste's preferred weapon, given to him personally by the emperor when he was inducted into the Zero Guard. Their coloring lets them melt into the shadows. The shape gives the impression they are meant to be thrown from a distance, but one of his physical prowess and reflexes can use them in close combat. The deadly glint as he turns them in his hand before striking is the origin of his nickname: "The Black Flash".`
    },
{ //Night Walkers - Complete
        id: 108841,
        name: `Night Walkers`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108840.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108841.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108840.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108841.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 120
            },
        skillset:  
            {
                skill: `40s CT - Reduces target's Dark DMG taken & Physical DMG taken by 20% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `DEF 5% UP.`
            },
        lore: `Though these appear to be standard walking boots at first glance, their lightness and flexibility are anything but, providing perfect support for Batiste's nimble movements. The special material is impervious to the average blade and gives extra lift for superhuman jumps.`
    },
{ //Night Edge - Missing Lore
        id: 108851,
        name: `Night Edge`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108850.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108851.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108850.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108851.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `20s CT - 4500% Dark DMG (Physical). Reduces DEF & CRI resistance by 30% for 10s when enemy is Bleeding.`,
                break: 100
            },
        passive: 
            {
                ability1: `CRI rate 10% UP.`
            },
        lore: `Missing Lore.`
    },
{ //True 'Hepaistos' - Complete
        id: 108861,
        name: `True 'Hepaistos'`,
        location: `AWK Gilliam Stage Clear Reward`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108860.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108861.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108860.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108861.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 10000% Earth DMG (Physical). Increases Earth DMG by 30% for 8s.`,
                break: 300
            },
        passive: 
            {
                ability1: `When equipped by Gilliam, all allies' Equipment DMG 20% UP (Only once).`,
                ability2: `DEF 10% UP.`
            },
        lore: `Gilliam's prized warmallet used to make the finest arms. with considerable girth and tough alloy construction, it obliterates the hardest, hottest foes. A novice soldier will see a simple weighted weapon, but a true master will take full advantage of its all-purpose nature. The clear, sharp clang of red-hot steel echoing throughout Reigrad is proof of the pride he puts into his work.`
    },
{ //覇罪杖『シュトラーフェ』 (Giaveil Raid Equip) - JP Complete
        id: 108871,
        name: `覇罪杖『シュトラーフェ』`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108870.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108871.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108870.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108871.png`
            },
        stats:
            {
                hp: 0,
                atk: 1000,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 6200% Dark (Magic). Consume 30% of own MAX HP. Reduce enemy's Dark resistance by 30% for 8s.`,
                break: 300
            },
        passive: 
            {
                ability1: `Magic resistance 5% UP.`
            },
        lore: `Currently unreleased in Global.`
    },
{ //True 'Ember Bolt' - Complete
        id: 108881,
        name: `True 'Ember Bolt'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108880.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108881.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108880.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108881.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 4000% Fire DMG (Physical). Reduces enemy's Physical resistance by 20% for 8s. Reduces extra 20% when enemy's HP is 50% or less.`,
                break: 500
            },
        passive: 
            {
                ability1: `When equipped by Priscilla, DMG & Accuracy 50% UP (Only once).`,
                ability2: `ATK 5% UP.`
            },
        lore: `The bow used by Priscilla, also known as the Inferno Archer. The beautiful blaze that bursts from each arrowhead shines brighter the more emotional she is. At maximum intensity, their heat even melts steel. The skill and accuracy she demonstrated in subduing and double-headed monster that appeared in the castle garnered much praise from even the Imperial Knights.`
    },
{ //Candent Bow 'Ember Bolt' - Complete
        id: 108891,
        name: `Candent Bow 'Ember Bolt'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108890.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108891.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108890.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108891.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `80s CT - 12000% Fire DMG (Physical). If the user is a Fire unit, increases DMG by 20% for 10s.`,
                break: 900
            },
        passive: 
            {
                ability1: `DMG to Beasts 10% UP.`
            },
        lore: `The bow belonging to Priscilla, an archer from Simon's Hill. The fire spirit that resides in it responds to her feelings, granting each arrow only as much strength as is necessary. She hunted monsters and protected villages on her journey to the capital, and damage was kept to a minimum in each conflict. That could be credited to both her own talent and spirit's fine tuning.`
    },
{ //Blazing Boots 'Ignition' - Complete
        id: 108901,
        name: `Blazing Boots 'Ignition'`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108900.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108901.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108900.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108901.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 5500% Fire DMG (Magic). Increases CRI DMG by 50% for 10s. If the user is a Fire unit, increases Arts gauge by 20.`,
                break: 600
            },
        passive: 
            {
                ability1: `MAX HP 5% UP.`
            },
        lore: `The footgear of Priscilla, famous archer from Simon's Hill. Their mysterious flames create heat shimmers that perplex foes, leading to embarrassing mistakes on their part. She stands on the front lines because of her strong desire to protect, but it is her quick judgement and flexibility which saves her from serious harm.`
    },
{ //True 'Jaeger' - Complete
        id: 108911,
        name: `True 'Jaeger'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108910.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108911.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108910.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108911.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 10000% Dark DMG (Physical). Increases Physical & Dark DMG by 40% for 8s.`,
                break: 600
            },
        passive: 
            {
                ability1: `When equipped by Simon, ATK 50% UP & Status Ailment resistance (except Faint) 20% UP (Only Once).`,
                ability2: `DMG to Giant Boss 10% UP.`
            },
        lore: `The gun carried by legendary sniper Simon. Based on Swagger & Vecket and Goldoa Corps.'s masterpiece, he tasked the best gunsmith in the empire to further refine its capabilities. It not only takes the original high-precision design concept to next level, projectile velocity was also greatly increased. This ballistic marvel is the very embodiment of his "one shot, one kill" creed.`
    },
{ //Jaeger M4A2 - Complete
        id: 108921,
        name: `Jaeger M4A2`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108920.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108921.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108920.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108921.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 4000% Dark DMG (Physical). Reduces enemy's Dark resistance by 20% for 8s. If the user is a Dark unit, reduces enemy's Evasion rate by 20% for 8s.`,
                break: 600
            },
        passive: 
            {
                ability1: `ATK 5% UP.`
            },
        lore: `The gun carried by expert sniper Simon. A joint product between rifle-maker Swagger & Vecket and magic artificers Goldoa Corp., it was designed with a focus on accuracy. It uses standard ammunition guided by runes inscribed into the firing hammer and gun barrel. Manageable recoil also meets his high standards, letting him eliminate targets with the fewest possible shots.`
    },
{ //Phantom Coat 'Stigma' - Complete
        id: 108931,
        name: `Phantom Coat 'Stigma'`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108930.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108931.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108930.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108931.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `55s CT - 3500% Dark DMG (Magic). Increases ATK by 30% for 8s. If the user is a Dark unit, increases DEF by 20% for 8s.`,
                break: 300
            },
        passive: 
            {
                ability1: `Paralysis Time Reduction & Paralysis Evasion rate 10% UP.`
            },
        lore: `The black overcoat worn by Simon, hero of Simon's Hill. The emperor personally bestowed it upon him in recognition of his outstanding service to the empire. In addition to its edgeproof and bulletproof qualities, the magical cloth dampens perception in others. He melts into the darkness at night, rendering him practically invisible to the untrained eye.`
    },
{ //蒼空衣『セレスト』 (Rosetta Farmable) - JP Complete
        id: 108941,
        name: `蒼空衣『セレスト』`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108940.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108941.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108940.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108941.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `40s CT - Increase own Art Gauge by 3 for 20s. Increase all Allies' (Except self) Water DMG by 40% for 10s`,
                break: 0
            },
        passive: 
            {
                ability1: `Heal HP by 2/s.`
            },
        lore: `Currently unreleased in Global.`
    },
{ //魔魂套『ファウスト』 (Duke Farmable) - JP Complete
        id: 108951,
        name: `魔魂套『ファウスト』`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108950.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108951.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108950.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108951.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `35s CT - 3500% Dark Damage (Physical). Increase own DMG by 20% for 10s. If used when enemy is in Break, increase own DMG by 80% instead.`,
                break: 3000
            },
        passive: 
            {
                ability1: `Skill DMG 10% UP.`
            },
        lore: `Currently unreleased in Global.`
    },
{ //Shrieking Claws 'Despair' - Missing Lore
        id: 108961,
        name: `Shrieking Claws 'Despair'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108960.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108961.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108960.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108961.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `10s CT - 500% Non-Elemental DMG (Physical). Costs 30% of MAX HP. Reduces enemy's Physical resistance by 10% for 30s (Stacks).`,
                break: 200
            },
        passive: 
            {
                ability1: `Skill CT speed 5% UP.`
            },
        lore: `Missing Lore.`
    },
{ //True 'Ouen' - Complete
        id: 108971,
        name: `True 'Ouen'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108970.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108971.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108970.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108971.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `70s CT - 5000% Earth DMG (Physical). Increases DMG by 50% & Earth DMG by 70% for 15s.`,
                break: 600
            },
        passive: 
            {
                ability1: `When equipped by Airi, DMG 30% UP & auto-fills Arts gauge by 1 (Only once).`,
                ability2: `Physical DMG 20% UP.`
            },
        lore: `The katana used by famed "Divine Swallow Fencer" Airi. She believes it to be her fated companion as it brings out the most of her thrusting techniques. Its tip, sharpened to the limit with Valkian whetstones, fascinates swordsmiths of all levels. The reason she always keeps it in prime condition is not because of its historic value, but her own pride as a soldier.`
    },
{ //Traditional Blade 'Ouen' - Complete
        id: 108981,
        name: `Traditional Blade 'Ouen'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108980.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108981.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108980.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108981.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 4000% Earth DMG (Physical). Increases Physical DMG by 30% for 15s. If the user is an Earth unit, auto-fills Arts gauge by 1 for 15s.`,
                break: 800
            },
        passive: 
            {
                ability1: `DMG 10% UP during Break.`
            },
        lore: `One of Valkia's most significant weapons. It passed between many different collectors as a high-valued antique, but few have used it in actual combat. The narrow tip makes it ideal for thrusting, but the sharp edge gives it slashing attacks are just as strong. Airi especially likes the pattern on its sheath, so she cleans it every night before turning in.`
    },
{ //Airi's Boots - Complete
        id: 108991,
        name: `Airi's Boots`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_108990.png`,
                detailmax: `/db/Equipment/Detail/item_detail_108991.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_108990.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_108991.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 120
            },
        skillset:  
            {
                skill: `30s CT - Negates all allies' DMG taken for a total of 300 & reduces Earth DMG taken by 15% for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Physical DMG from enemy 5% DOWN.`
            },
        lore: `Airi's military footwear. Despite their plain appearance, they are the sum product of Valkia's advanced magic, which was necessary to endure her nimble movements. The manner in which the vast resource investment to create them was readily approved speaks volumes about her impootance to the nations' forces.`
    },
{ //Silent Ritual - Missing Lore
        id: 109001,
        name: `Silent Ritual`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109000.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109001.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109000.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109001.png`
            },
            stats:
            {
                hp: 500,
                atk: 500,
                def: 500
            },
        skillset:  
            {
                skill: `50s CT - Heal all Allies' HP by 100 and increase all God Allies' HP Recovery Amount by 30% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `5% chance to evade Physical ATK.`
            },
        lore: `Missing Lore.`
    },
{ //Silent Ritual EV - Missing Lore
        id: 109011,
        name: `Silent Ritual EV`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109010.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109011.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109010.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109011.png`
            },
        stats:
            {
                hp: 500,
                atk: 500,
                def: 500
            },
        skillset:  
            {
                skill: `50s CT - Heal all Allies' HP by 100 and increase all God Allies' HP Recovery Amount by 30% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `5% chance to evade Physical ATK.`,
                ability2: [`ATK 10% UP.`, `DEF 10% UP.`, `Burn resistance 30% UP.`, `Burn resistance 50% UP.`, `Accuracy rate 10% UP.`, `Accuracy rate 20% UP.`]
            },
        lore: `Missing Lore.`
    },
{ //True 'Gold Rush' - Complete
        id: 109021,
        name: `True 'Gold Rush'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109020.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109021.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109020.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109021.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 5000% Light DMG (Magic). Increases DMG by 50% for 12s. If the user is a Light unit, increases Accuracy by 30% for 12s.`,
                break: 700
            },
        passive: 
            {
                ability1: `When equipped by Sharon, all stats 15% UP & extends Arts duration by 10s (Only once).`,
                ability2: `Magic DMG from enemy 5% DOWN.`
            },
        lore: `There once lived a Bamint-born actress of matchless talent. Sharon idolizes her so much that she went to great lengths to obtain her trademark stole. Whether she is basking in applause or laboring behind the scenes, it can always been seen glimmering around her shoulders. People eventually started believing it is the sign of a top star.`
    },
{ //煌翔織『ゴールドラッシュ』 (Sharon's Farmable) - JP Complete
        id: 109031,
        name: `煌翔織『ゴールドラッシュ』`,
        translate: `'Gold Rush'`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109030.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109031.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109030.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109031.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 6000% Light DMG (Magic). Increase own Magic DMG by 35% and reduce enemy's Light resistance by 25% for 10s.`,
                break: 800
            },
        passive: 
            {
                ability1: `BE Output 5% UP.`
            },
        lore: `Currently unreleased in Global.`
    },
{ //明星衣『スターダム』 (Sharon's Farmable) - JP Complete
        id: 109041,
        name: `明星衣『スターダム』`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109040.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109041.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109040.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109041.png`
            },
        stats:
            {
                hp: 500,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `30s CT - Heal all allies' HP by 2% for 10s. If user is Light, heal all allies' Blind.`,
                break: 0
            },
        passive: 
            {
                ability1: `5% chance to evade Physical ATK.`
            },
        lore: `Currently unreleased in Global.`
    },
{ //True 'Bel Canto' - Complete
        id: 109051,
        name: `True 'Bel Canto'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109050.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109051.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109050.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109051.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 7500% Dark DMG (Magic). Increases Bleed Level by 1 when enemy is Bleeding (Up to Lv3). Reduce enemy's Magic resistance by 35% for 8s.`,
                break: 300
            },
        passive: 
            {
                ability1: `When equipped by Hart-Xi, ATK 30% UP & auto-fills Arts gauge by 3 (Only once).`,
                ability2: `Accuracy 10% UP.`
            },
        lore: `The gun Hart uses in his rebellion against the empire. It was once the flagship product of Goldoa Corp., popular throughout Isliid for its ease of use and high accuracy. The solid barrel, where most of its limited weight is located, ensures precision. Its report is somewhat higher pitched compared to other guns due to the metal's properties, giving it the nickname of "the Singing Gun".`
    },
{ //Bel Canto M3 - Complete
        id: 109061,
        name: `Bel Canto M3`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109060.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109061.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109060.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109061.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 4400% Dark DMG (Magic). Increases Dark DMG & Accuracy by 30% for 10s.`,
                break: 500
            },
        passive: 
            {
                ability1: `ATK 5% UP.`
            },
        lore: `A pistol used by the magic gunner Hart. Goldoa Corp. drew upon their experience working with rifle makers Swagger & Vecket to independently manufacture this model. Designed for self-defense, it is much smaller and lighter than the Jaeger. It maintains its high accuracy even in burst-fire mode, added to make up for its reduced stopping power. Women in particular found it an attractive choice.`
    },
{ //Archaic Attire 'Antiqua' - Complete
        id: 109071,
        name: `Archaic Attire 'Antiqua'`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109070.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109071.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109070.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109071.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 3800% Dark DMG (Magic). Reduces Light enemy's Dark resistance by 20% for 10s.`,
                break: 600
            },
        passive: 
            {
                ability1: `DEF 5% UP.`
            },
        lore: `The battle outfit Hart wears as he fights the empire. He received it from Azvan as his affiliate. Not only resistant to blades and bullets, it is remarkably effective against spells. The lightweight fabric bears not a single seam or tear, giving the impression it was created by magic or some long-forgotten technology.`
    },
{ //禍毒兜『ベルグヘルム』 (Belgiras Mines Equip) - JP Complete
        id: 109081,
        name: `禍毒兜『ベルグヘルム』`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109080.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109081.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109080.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109081.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 2000% Earth DMG (Magic). Increase own Accuracy rate by 30% and reduce own DMG by 20% for 10s.`,
                break: 500
            },
        passive: 
            {
                ability1: `Seal Time Reduction & Seal Evasion rate 10% UP.`
            },
        lore: `Currently unreleased in Global.`
    },
{ //絶禍兜『レーアベルグ』 (Elite Belgiras Mines Equip) - JP Complete
        id: 109091,
        name: `絶禍兜『レーアベルグ』`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109090.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109091.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109090.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109091.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 3000% Earth DMG (Magic). Reduce own CRI resistance by 50% and Increase own CRI rate against enemies with status ailment by 100% for 20s.`,
                break: 200
            },
        passive: 
            {
                ability1: `DEF 5% UP.`
            },
        lore: `Currently unreleased in Global.`
    },
{ //Winged Mail 'Blue Pinions' - Missing Lore
        id: 109101,
        name: `Winged Mail 'Blue Pinions'`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109100.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109101.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109100.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109101.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 120
            },
        skillset:  
            {
                skill: `25s CT - Reduces all allies' CRI DMG taken by 30% and Increases all allies' Non-CRI DMG taken by 20% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Freeze Time Reduction & Freeze Evasion rate 10% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Occult Wings 'Rafale' - Missing Lore
        id: 109111,
        name: `Occult Wings 'Rafale'`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109110.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109111.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109110.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109111.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `55s CT - Reduces all allies' ATK by 50% and DMG taken for 3 HITs by 80% for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Blind Time Reduction & Blind Evasion rate 20% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Sealed Branches 'Aurora' - Missing Lore
        id: 109121,
        name: `Sealed Branches 'Aurora'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109120.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109121.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109120.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109121.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `35s CT - 5000% Earth DMG (Physical). Reduces own Arts gauge by 10. Increases own DMG against enemies with an Elemental Advantage by 30% for 10s.`,
                break: 100
            },
        passive: 
            {
                ability1: `Earth resistance 5% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Occult Branches 'Seratta' - Missing Lore
        id: 109131,
        name: `Occult Branches 'Seratta'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109130.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109131.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109130.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109131.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 7300% Earth DMG (Physical). Reduces all allies' Arts gauge by 20. Increase all allies' Earth DMG by 40% for 10s.`,
                break: 300
            },
        passive: 
            {
                ability1: `Poison Time Reduction & Poison Evasion rate 10% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Jade Star 'Le Courage' - Complete
        id: 109141,
        name: `Jade Star 'Le Courage'`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109140.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109141.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109140.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109141.png`
            },
        stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
        skillset:  
            {
                skill: `40s CT - Auto-fills target's Arts gauge by 3 for 10s. If the user is an Earth unit, reduces target's DMG taken by 10% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Freeze Time Reduction & Freeze Evasion rate 10% UP.`
            },
        lore: `The battle dress of Cestina, second princess of Gran Verge. Unwilling to compromise comfort or protection, she asked her older sister for help in creating a loom that weaves magic directly into the fabric. This is how it can grant extra protection in response to her will while sporting a breezy, sleeveless design.`
    },
{ //Spectral Talon - Missing Lore
        id: 109151,
        name: `Spectral Talon`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109150.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109151.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109150.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109151.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `30s CT - 4700% Light DMG (Physical). Increases DMG by 20% for 10s. If enemy is Light or Dark, 20% more.`,
                break: 400
            },
        passive: 
            {
                ability1: `If the user is Light & Spectral Wings is equipped by an ally, Physical DMG 15% UP & Physical DMG taken 15% DOWN (Only once).`,
                ability2: `Paralysis Time Reduction & Paralysis evasion rate 10% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Spectral Wings - Missing Lore
        id: 109161,
        name: `Spectral Wings`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109160.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109161.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109160.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109161.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `30s CT - Recovers target's HP by 50% of Dark DMG taken for 12s.`,
                break: 0
            },
        passive: 
            {
                ability1: `If the user is Dark & Spectral Talon is equipped by an ally, Physical DMG 15% UP & Physical DMG taken 15% DOWN (Only once).`,
                ability2: `Blind Time Reduction & Blind evasion rate 10% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Hart's Waistline Headline - Complete
        id: 109171,
        name: `Hart's Waistline Headline`,
        location: `Limited Equip Summon: Phantom Snipers Summer Banner`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109170.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109171.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109170.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109171.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `40s CT - Increases target's Arts gauge by 80. Increases target's Magic DMG by 80% for 12s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A pair of swimming trunks with a unique newsprint pattern. Vultee told Hart he needed a more enticing look if he wanted to make some real summer memories. On the other hand, the women in his life praised how it matched his well-read nature. This caused Hart to smile bashfully... and Vultee to seethe ineffectually.`
    },
{ //Priscilla's Summer Stakes - Complete
        id: 109181,
        name: `Priscilla's Summer Stakes`,
        location: `Limited Equip Summon: Phantom Snipers Summer Banner`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109180.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109181.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109180.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109181.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 4500% Fire DMG (Physical). Reduces enemy's Burn & Fire resistance by 30% for 10s.`,
                break: 500
            },
        passive: 
            {
                ability1: ``
            },
        lore: `This is the year she goes all in. She browsed countless swimsuits, pondered for days, then finally decided to pin her unspoken hopes and dreams on a timeless design that also accentuates the tenderness of the female figure. The season's liberating thrill convinced her to throw restraint to the wind and charge right out the gate. Whether or not she will cross the finish line first is anyone's guess.`
    },
{ //Vultee's Vista of Paradise - Complete
        id: 109191,
        name: `Vultee's Vista of Paradise`,
        location: `Limited Equip Summon: Phantom Snipers Summer Banner`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109190.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109191.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109190.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109191.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 7500% Water DMG (Physical). Increases Arts gauge by 30. Reduces enemy's Water resistance by 30% for 20s.`,
                break: 600
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Vultee knows catching a big wave needs the right gear. With this stylish shirt, he can undo the buttons to reveal just enough of his sculpted physique to drive the ladies wild, or so he believed. That was until Priscilla gave him a puzzled look and asked why he suddenly couldn't dress himslef properly. Ever since, he keeps all the buttons fastened tight.`
    },
{ //Simon's Nostalgic Shades - Complete
        id: 109201,
        name: `Simon's Nostalgic Shades`,
        location: `Limited Equip Summon: Phantom Snipers Summer Banner`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109200.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109201.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109200.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109201.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `80s CT - 10000% Dark DMG (Magic). Increases ATK by 100% for 8s. If the user is a Dark unit, will not Flinch.`,
                break: 800
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Simon spent most of his time at the beach reading under an umbrella when not training. Underneath the summer sun's glare, he always wore these tinted glasses. He may have been protecting his most important asset as a sharpshooter... or hiding his embarrassment at Miranda's shameless swimwear. No one knew for certain.`
    },
{ //Miranda's Dynamite Blast - Missing Lore
        id: 109211,
        name: `Miranda's Dynamite Blast`,
        location: `Limited Equip Summon: Phantom Snipers Summer Banner`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109210.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109211.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109210.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109211.png`
            },
        stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
        skillset:  
            {
                skill: `30s CT - Increases all allies' Arts gauge by 20. If the user is a Light unit, increases all allies' DEF by 30% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
{ //Luana's Seaside Fantasy - Complete
        id: 109221,
        name: `Luana's Seaside Fantasy`,
        location: `Limited Equip Summon: Phantom Snipers Summer Banner`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109220.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109221.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109220.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109221.png`
            },
        stats:
            {
                hp: 120,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `30s CT - Heals all allies' Poison. Auto-recovers all allies' HP by 100 for 8s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Luana's favorite swimsuit worn during her family's yearly trips to the beach. She chose it wanting to show Hart, but that opportunity never came. Although outgrown during his absence, she couldn't bring herself to part with it. In her mind, that would be no different than giving up entirely.`
    },
{ //True 'Castillo' - Missing Lore
        id: 109231,
        name: `True 'Castillo'`,
        location: `AWK Gaia Stage Clear Reward`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109230.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109231.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109230.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109231.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `40s CT - Reduces all allies' DMG taken by 15% & increases Water units' DEF by 50% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `When equipped by Gaia, ATK 20% UP & Auto-fills Arts gauge by 2 (Only once).`,
                ability2: `DEF 20% UP when negating DMG.`
            },
        lore: `Missing Lore.`
    },
{ //True 'Maximus' - Complete
        id: 109241,
        name: `True 'Maximus'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109240.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109241.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109240.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109241.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 8800% Light DMG (Physical). Reduces enemy's Physical resistance by 20% for 12s. If the user is a Light unit, 15% more.`,
                break: 1100
            },
        passive: 
            {
                ability1: `When equipped by Lyla, CRI DMG 60% UP (Only once).`,
                ability2: `Skill DMG 20% UP.`
            },
        lore: `Lyla's trusty partner on the battlefield. In her quest for further improvement, she wandered into a used bookstore. There, she discovered a reference about Valkia's ancient "gundancing" custom and became engrossed in the topic. The key concept was managing the weapon's unpredictable movements with lithe motions of the body. In mastering that, she became famous among gunmen around the world.`
    },
{ //Valkian Heritage 'Maximus' - Complete
        id: 109251,
        name: `Valkian Heritage 'Maximus'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109250.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109251.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109250.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109251.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 7000% Light DMG (Physical). Increases Arts gauge by 20. Increases CRI DMG by 50% for 10s.`,
                break: 600
            },
        passive: 
            {
                ability1: `Skill CT speed 5% UP.`
            },
        lore: `A model of gatling gun once manufactured in Valkia Kingdom. Thorough maintenance has preserved its luster against the ravages of time. The army officers chose this for Lyla after assessing her skills. Although she struggled with its weight at first, the fact she learned to wield it so well in a such a short time reflects her untiring efforts to become the best she can be.`
    },
{ //Combat Coat 'Veste Noir' - Complete
        id: 109261,
        name: `Combat Coat 'Veste Noir'`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109260.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109261.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109260.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109261.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `40s CT - Increases all allies' DEF by 50% for 10s. If the user is a Light unit, increases ATK by 30% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Blind Time Reduction & Blind Evasion rate 10% UP.`
            },
        lore: `Lyla's military outfit. The magic sewn into the fabric of Valkia's army uniforms affects each soldier differently. It granted her the power of light which enhances the strength of her shots. However, they all share the sturdiness to withstand the soldiers' daily regimen, so everyone treats theirs own as a dependable ally.`
    },
{ //Flame Maiden's Satchel - Missing Lore
        id: 109271,
        name: `Flame Maiden's Satchel`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109270.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109271.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109270.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109271.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `70s CT - If the user is a Fire unit, increases Fire units' CRI rate by 100% to Earth enemy for 10s & increases Fire units' Arts gauge by 30.`,
                break: 0
            },
        passive: 
            {
                ability1: `Earth resistance 5% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Atlante's Fangs - Missing Lore
        id: 109281,
        name: `Atlante's Fangs`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109280.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109281.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109280.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109281.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 8000% Water DMG (Physical). If the user is a Water unit, increases Equipment DMG to Fire enemy by 100% for 10s.`,
                break: 800
            },
        passive: 
            {
                ability1: `Fire resistance 5% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Glorious Decline - Missing Lore
        id: 109291,
        name: `Glorious Decline`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109290.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109291.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109290.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109291.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 5000% Earth DMG (Magic). If the user is a Earth unit, 100% chance of Poisoning Water enemy. Reduces Earth resistance by 30% if enemy was Poisoned by this Equip for 20s.`,
                break: 300
            },
        passive: 
            {
                ability1: `Water resistance 5% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Halos Pragma - Missing Lore (109311 = Mavro Halos EV Equip)
        id: 109301,
        name: `Halos Pragma`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109300.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109301.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109300.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109301.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 120
            },
        skillset:  
            {
                skill: `50s CT - Increase own DEF and Dark DMG by 20% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Blind Time Reduction & Blind Evasion rate 10% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Halos Pragma EV - Missing Lore
        id: 109311,
        name: `Halos Pragma EV`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 4,
        image: 
            {
                detail: ``,
                detailmax: `/db/Equipment/Detail/item_detail_109311.png`,
                thumb: ``,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109311.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 120
            },
        skillset:  
            {
                skill: `50s CT - Increase own DEF and Dark DMG by 20% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Blind Time Reduction & Blind Evasion rate 10% UP.`,
                ability2: [`DMG to Beasts 10% UP.`, `DMG to Spirits 10% UP.`, `DMG to Dragons 10% UP.`, `Dark resistance 5% UP.`, `Negates 500 of own DMG taken at Start of Quest.`, `Negates 1000 of own DMG taken at Start of Quest.`]
            },
        lore: `Missing Lore.`
    },
{ //Leone's Precious One-Piece - Complete
        id: 109321,
        name: `Leone's Precious One-Piece`,
        location: `Limited Equip Summon: Knights of Blood Summer Banner`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109320.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109321.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109320.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109321.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `40s CT - Increases Arts gauge by 40 for the lowest gauge unit. Auto-fills all allies' Arts gauge by 3 for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Cute swimwear belonging to Leone, princess of Granadia. Although she first hid in the shade, the listening sands and beckoning waves had coaxed her onto the beach beofre she knew it. Tired from playing all day, the swaying of the carriage soon rocked her to sleep beneath the warm smiles of the Templars.`
    },
{ //Juno's Seafoam Pareo - Complete
        id: 109331,
        name: `Juno's Seafoam Pareo`,
        location: `Limited Equip Summon: Knights of Blood Summer Banner`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109330.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109331.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109330.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109331.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 8000% Water DMG (Physical). Reduces enemy's Magic resistance by 20% for 10s. Reduces additional 20% when HP is 90% or more.`,
                break: 600
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A graceful ensembel worn by the queen of Granadia, Juno. As part of summer vacation for her Templars (minus Berwick), she decided to surprise Leone by taking her to beach in secret. Despite a few unexpected happenings, her plan ended succesfully. While watching her dear sister and her dependable guardians at play, she renewed her oath to do whatever it took to protect their smiles.`
    },
{ //Blue Ray Vox - Complete
        id: 109341,
        name: `Blue Ray Vox`,
        location: `Limited Equip Summon: Knights of Blood Summer Banner`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109340.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109341.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109340.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109341.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `30s CT - Increases all allies' Skill CT speed & Water DMG by 50% for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Camouflage-patterned swim trunks for Vox, captain of the Juno Templars. his oath to protect Juno remained strong even during the relaxed vacation mood. Not even fun in the sun would keep him from carrying out his duties as a knight, such as rescuing the princess from the roving monsters. This display of unwavering commitment also helped close the distance between the two ever so slightly.`
    },
{ //Duke the Legendary - Complete
        id: 109351,
        name: `Duke the Legendary`,
        location: `Limited Equip Summon: Knights of Blood Summer Banner`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109350.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109351.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109350.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109351.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 5000% Dark DMG (Physical). Increases DMG by 50% during Break for 10s. Increases Break power by 50% to enemy not in Break for 10s.`,
                break: 5000
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Swimming briefs that Riviera picked out for Duke. He gave her the chance since he has no strong preference in fashion and realized how much she was looking forward to their beach vacation. However, its gaudy, prismatic design brought instant regret. Positioned imposingly in the waist-high shallows, he reflected upon the importance of proper consideration in all things, on and off the battlefield.`
    },
{ //Riviera's Sundrenched Stunner - Complete
        id: 109361,
        name: `Riviera's Sundrenched Stunner`,
        location: `Limited Equip Summon: Knights of Blood Summer Banner`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109360.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109361.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109360.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109361.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 4500% Earth DMG (Magic). Increases CRI DMG by 70% for 10s. Increases Arts gauge by 70 (First time only).`,
                break: 800
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The daring bikini of the Juno Templars' vanguard fighter, Riviera. She had secretly worked on her figure all year just so she could wear it without hesitation. The captivating sight of her frolicking on the white sands more than soldified her role as beauty of the group. also, she was the only one who remembered to bring a souvenir for Berwick, earning his respect.`
    },
{ //Thetis' Mikyu-Mate - Complete
        id: 109371,
        name: `Thetis' Mikyu-Mate`,
        location: `Limited Equip Summon: Knights of Blood Summer Banner`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109370.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109371.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109370.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109371.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 8000% Water DMG (Physical). Increases DMG by 50% for 8s. If the user is Water, increases Accuracy by 30% for 8s.`,
                break: 400
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Mikyuon-themed diving gear belonging to the shield bearer of the Juno Templars, Thetis. The magical snorkel gives its wearer the power to breathe underwater, and he hoped it would let him gather rare deep-sea delicacies at their freshest. While it performed admirably in that regard, he realized most of what he found would be hard for the others to stomach. His reward was not gasps of admiration at his culinary skills but smiles of content after a satisfying meal.`
    },
{ //Leone's Pretty Parasol - Complete
        id: 109381,
        name: `Leone's Pretty Parasol`,
        location: `Limited Equip Summon: Knights of Blood Summer Banner`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109380.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109381.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109380.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109381.png`
            },
        stats:
            {
                hp: 120,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - Auto-recovers all allies' HP by 150 for 10s. If user is Earth, Negates DMG taken for a total of 1000 for 10s (Only once).`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Besides blue waters and white sands underneath the yellow sun, the beach was also home to green monsters. Leone strolled along the shore, enjoying the scent of the breeze with her parasol open wide... utterly unaware a small parade had formed behind her. Though the impish creatures scared her at first, she felt a little sad to say goodbye at day's end. The little parasol is filled with memories of their time together.`
    },
{ //Diever Boat 2021 Model - Complete
        id: 109391,
        name: `Diever Boat 2021 Model`,
        location: `Limited Equip Summon: Knights of Blood Summer Banner`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109390.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109391.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109390.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109391.png`
            },
        stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
        skillset:  
            {
                skill: `30s CT - Increases targets' Magic DMG & Water DMG by 30% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Juno's personal Diever Boat. From the moment she saw it on display, its hydrodynamic form and cute eyes stole her heart. All of the latest features are installed, allowing for realistic Diever movement that instantly responds to its driver. Those who watched her tear through the waves found her greater-than-usual exuberance charming.`
    },
{ //Selfish Aria - Missing Lore
        id: 109401,
        name: `Selfish Aria`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109400.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109401.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109400.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109401.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `70s CT - 4000% Fire DMG (Magic). Increase own Magic DMG by 50% for 15s. If User is Fire, increase Own DEF by 50% for 15s.`,
                break: 400
            },
        passive: 
            {
                ability1: `Burn Time Reduction & Burn Evasion rate 10% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Liz's Curio - Missing Lore
        id: 109411,
        name: `Liz's Curio`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109410.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109411.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109410.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109411.png`
            },
        stats:
            {
                hp: 120,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - Heals Target's HP by 2000. If target's HP is below 50%, heal target's HP by 4000 instead.`,
                break: 0
            },
        passive: 
            {
                ability1: `Poison Time Reduction & Poison Evasion rate 10% UP.`
            },
        lore: `Missing Lore.`
    },
{ //True 'Selfish Aria' - Missing Lore
        id: 109421,
        name: `True 'Selfish Aria'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109420.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109421.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109420.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109421.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 6000% Fire DMG (Magic). Increases DMG by 50% for 12s. If the enemy is in Status Ailment, reduces enemy's DMG by 30% for 12s.`,
                break: 600
            },
        passive: 
            {
                ability1: `When equipped by Elizabeth, DMG 30% UP & auto-fills Arts gauge by 2 (Only once).`,
                ability2: `Magic DMG from enemy 10% DOWN.`
            },
        lore: `Missing Lore.`
    },
{ //Inspector Z4 - Complete
        id: 109431,
        name: `Inspector Z4`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109430.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109431.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109430.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109431.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 7000% Water DMG (Magic). Increases Magic DMG by 40% for 20s. If the user is a Water unit, Auto-fills Arts gauge by 1 for 20s.`,
                break: 600
            },
        passive: 
            {
                ability1: `DMG to Fire enemy 5% UP.`
            },
        lore: `Goldoa Corp.'s iconic laser gun. Lightweight, durable, and customizable down to the pulse color, it has fans among gunslingers of all skill levels. There are also "Inspector Collectors" who seek out rare versions, driving up prices on the second-hand market as a result. some will even travel to far-flung rural shops in hopes of discovering treasure.`
    },
{ //Selunian Brick Top - Complete
        id: 109441,
        name: `Selunian Brick Top`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109440.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109441.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109440.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109441.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 120
            },
        skillset:  
            {
                skill: `35s CT - Reduces Water units' DMG taken by 20% for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: `BE Output 5% UP.`
            },
        lore: `Vultee's favorite hat, a style popular among ranchers in Selunia. He firmly believes a gunner always needs a hat, so he has dozens in all shapes and sizes. The reason he prefers this particular one is the perfect balance of formal and casual provided by the broken-in fabric, suiting him to a T.`
    },
{ //True 'Inspector' - Complete
        id: 109451,
        name: `True 'Inspector'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109450.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109451.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109450.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109451.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 8000% Water DMG (Magic). Reduces enemy's Water resistance by 30% for 10s. if the user is a Water unit, increases DMG by 50% for 10s.`,
                break: 800
            },
        passive: 
            {
                ability1: `When equipped by Vultee, Auto-fills Arts gauge by 3 (Only once).`,
                ability2: `Magic DMG 10% UP.`
            },
        lore: `The customized weapon of Vultee, imperial soldier from Simon's Hill. most of the visual adjustments are minor like the perfectly molded grip. Far more important are magically strengthened gun barrel, able to withstand up to twice the output, and the precision calibration chip tuned to his personal quirks.`
    },
{ //Ancient Law - Complete
        id: 109461,
        name: `Ancient Law`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109460.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109461.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109460.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109461.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 6000% Dark DMG (Magic). Increases CRI DMG by 25% for 20s. If the user is a Dark unit, 25% more.`,
                break: 600
            },
        passive: 
            {
                ability1: `BE Output 5% UP.`
            },
        lore: `A necklace inscribed with the text of a lost civilization. It has the power to bind and control the minds of others. A partial decoding of the script led researchers to believe it to be the code of law for an extinct race known as the Ancients. Only a handful still exist, and many fabricated. Even so, merely imitating the shape gives them some effectiveness.`
    },
{ //Nine Oaths - Complete
        id: 109471,
        name: `Nine Oaths`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109470.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109471.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109470.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109471.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 4000% Dark DMG (Magic). Increases ATK by 80% for 12s. If the user is a Dark unit, increases Skill CT speed by 80% for 12s.`,
                break: 700
            },
        passive: 
            {
                ability1: `DEF 5% UP.`
            },
        lore: `A magical dress with enchanted embroidery. It grants the wearer immense power but places a number of restricting terms on them. Moreover, no record of the consequences should those terms be broken exists. The fabric adapts to the body's contours even if it changes dramatically.`
    },
{ //Dread Lotus - Complete
        id: 109481,
        name: `Dread Lotus`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109480.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109481.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109480.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109481.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `55s CT - 3500% Dark DMG (Magic). Reduces enemy's Blind resistance by 20% for 25s.`,
                break: 500
            },
        passive: 
            {
                ability1: `5% chance of evading Physical ATK.`
            },
        lore: `Magical boots worn by Tursowa, champion of the Ancients. They were crafted in the distant past using a spell-nullifying material, but the ones worn by Emi are imitations created with her own magic. Since she destroys them whenever she transforms, that is a more reasonable method of replacing such rare articles.`
    },
{ //Kingdom's Blade 'Grand Scale' - Missing Lore
        id: 109491,
        name: `Kingdom's Blade 'Grand Scale'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109490.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109491.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109490.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109491.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `120s CT - 18000% Light DMG (Physical). Increases Equipment Accuracy by 50% for 6s.`,
                break: 1000
            },
        passive: 
            {
                ability1: `DMG taken 20% UP.`
            },
        lore: `Missing Lore.`
    },
{ //True 'Ancient Law' - Complete
        id: 109501,
        name: `True 'Ancient Law'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109500.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109501.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109500.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109501.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `80s CT - 5500% Dark DMG (Magic). Increases Arts gauge by 35. Increases Dark DMG by 30% for 30s.`,
                break: 900
            },
        passive: 
            {
                ability1: `When equipped by Emi, DMG 20% UP & Arts gauge 100 UP when quest starts (Only once).`,
                ability2: `Blind Time Reduction & Blind Evasion rate 20% UP.`
            },
        lore: `Emi's necklace engraved with the script of a lost civilization. It gives her the ability to control the minds of her retainers. Unlike the waning effect found in others, the power of this one is fully intact since it was made recently.`
    },
{ //True 'Nine Oaths' - Complete
        id: 109511,
        name: `True 'Nine Oaths'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109510.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109511.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109510.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109511.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `80s CT - 10000% Dark DMG (Magic). Reduces enemy's Dark resistance by 25% for 30s.`,
                break: 800
            },
        passive: 
            {
                ability1: `When equipped Emi, extends Revelation by 30s.`,
                ability2: `Magic DMG from enemy 5% DOWN.`
            },
        lore: `Emi's dress inlaid with enchanted embroidery. The pact she formed with it gave her strength in exchange for limiting her actions. There are nine restrictions in all, with each additional one offering a proportional benefit. Since complete commitment makes it impossible to lead a normal life, some other source of stability, such as a firm will or a deep-rooted anger, is necessary.`
    },
{ //True 'Grand Scale' - Missing Lore
        id: 109521,
        name: `True 'Grand Scale'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109520.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109521.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109520.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109521.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `55s CT - 10000% Light DMG (Physical). Increases DMG by 60% for 12s. If the user is a Light unit, increases ATK by 60% for 12s.`,
                break: 800
            },
        passive: 
            {
                ability1: `When equipped by Awoken Rayas, extends Ex-Force for 30s. Arts gauge 100 UP when Ex-Force is activated (Only once).`,
                ability2: ``
            },
        lore: `Missing Lore.`
    },
{ //イクリプスランチャー (Norn Farmable) - JP Complete
        id: 109531,
        name: `イクリプスランチャー`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109530.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109531.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109530.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109531.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 9000% Earth DMG (Magic). Increase own Magic DMG by 30% for 10s. If user is Earth, increase own Magic DMG by 60% instead.`,
                break: 900
            },
        passive: 
            {
                ability1: `Accuracy rate 30% UP when own HP is 90% or above.`
            },
        lore: `Currently unreleased in Global.`
    },
{ //絶焔剣『デグルオルタ』 (Elite Degilmagna Mines Equip) - JP Complete
        id: 109541,
        name: `絶焔剣『デグルオルタ』`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109540.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109541.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109540.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109541.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 7000% Fire DMG (Magic). Reduces enemy's Fire resistance by 70% and allies' Fire DMG by 30% for 15s.`,
                break: 500
            },
        passive: 
            {
                ability1: `Burn Time Reduction & Burn Evasion rate 10% UP.`
            },
        lore: `Currently unreleased in Global.`
    },
{ //カボシェルムの召喚石 (Jackosherum Raid Equip) - JP Complete
        id: 109551,
        name: `カボシェルムの召喚石`,
        translate: `Jack-o-Sherum Summonstone`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109550.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109551.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109550.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109551.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `120s CT - 6000% Dark DMG (Magic). Increase all Allies' Arts gauge by 10. Increase all Allies' Magic and Dark DMG by 25% for 20s.`,
                break: 1000
            },
        passive: 
            {
                ability1: `Summon Gem Blessing Lv5: Only the highest level effect of the Summon Gem Blessing is effective.`
            },
        lore: `Currently unreleased in Global.`
    },
{ //極『カボシェルムのマント』(Jack-o-sherum cape EV) - JP Complete
        id: 109561,
        name: `極『カボシェルムのマント』`,
        translate: `Jack-O-Sherum's Cape EV`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: ``,
                detailmax: `/db/Equipment/Detail/item_detail_109561.png`,
                thumb: ``,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109561.png`
            },
            stats:
            {
                hp: 500,
                atk: 500,
                def: 500
            },
        skillset:  
            {
                skill: `50s CT - 3000% Dark DMG (Magic). Negates 800 DMG Dealt to all Allies for 10s. Reduces all Allies' DMG Taken by 15% for 10s. Increases all Allies' Seal and Curse Resistance by 100% for 10s (only once).`,
                break: 800
            },
        passive: 
            {
                ability1: `Increases DMG to Light Elemental Units by 20%.`,
                ability2: [`ATK 5% UP.`, `ATK 10% UP.`, `DEF 5% UP.`, `DEF 10% UP.`, `Heal Recovery 5% UP.`, `Heal Amount 5% UP.`]
            },
        lore: `Currently unreleased in Global.`
    },
{ //Hart's Discovery - Complete
        id: 109571,
        name: `Hart's Discovery`,
        location: `Limited Equip Summon: Phantom Snipers Halloween Banner`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109570.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109571.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109570.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109571.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `40s CT - Auto-fills target's Arts gauge by 5 & auto-recovers all allies' HP by 150 for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The town of Simon's Hill held a costume festival to celebrate the harvest each fall. For the school event, Hart's class chose to open a maid café. Hart originally volunteered to cook, but Vultee's remark of "Y'know, I think you'd look good in a dress." was met with unanimous agreement. Despite his grumbling in the changing room, he couldn't deny that thrilling sensation as he looked himself over in the mirror.`
    },
{ //Priscilla's Maid Dress - Complete
        id: 109581,
        name: `Priscilla's Maid Dress`,
        location: `Limited Equip Summon: Phantom Snipers Halloween Banner`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109580.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109581.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109580.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109581.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `55s CT - 6000% Fire DMG (Physical). Reduces enemy's Fire resistance by 30% for 10s. Reduces extra 30% when enemy's HP is 50% or less.`,
                break: 700
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Priscilla's maid outfit. She fell in love with the frilly design, but it merely occupied space in her wardrobe for months since there was no opportunity to wear it until the costume festival. Encouraged that it still fit her, she hoped to catch Hart's eyes, but the fact that everyone else's were focused on him in a skirt left her feeling conflicted... even though she happily applied his makeup.`
    },
{ //Vultee's Dandy Duds - Complete
        id: 109591,
        name: `Vultee's Dandy Duds`,
        location: `Limited Equip Summon: Phantom Snipers Halloween Banner`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109590.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109591.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109590.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109591.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `35s CT - 3500% Water DMG (Magic). Increases Arts DMG by 35% for 7s. Auto-fills Arts gauge by the usage count x1 for 7s (MAX 5).`,
                break: 300
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Vultee's traditional yet classy butler suit. To apologize for inadvertently causing Hart to dress like a maid, he decided to join him on the service side of the class café. However, no amount of guilt could bring him to make the leap into a skirt as well. This was why he was secretly relieved to see Hart enjoying himself regardless of everything.`
    },
{ //Miranda's Relived Youth - Complete
        id: 109601,
        name: `Miranda's Relived Youth`,
        location: `Limited Equip Summon: Phantom Snipers Halloween Banner`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109600.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109601.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109600.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109601.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `30s CT - Heals all allies' Seal. Reduces all allies' DMG taken by 20% & Light DMG taken by 30% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A devastating (on several levels) outfit of Miranda's, said to have been fashionable in a distant country. Although she needed some time to realize the absurdly long sock were meant to be scrunched up, she claimed it made her feel rejuvenated. Indeed, more than a few visitors to the school festival assumed she too was part of the student body from her ebullience.`
    },
{ //Bushido Master S - Missing Lore
        id: 109611,
        name: `Bushido Master S`,
        location: `Limited Equip Summon: Phantom Snipers Halloween Banner`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109610.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109611.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109610.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109611.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 10000% Dark DMG (Physical). Increases True Arts & Phantom Bullet DMG by 70% after this Equip is used. If the user is a Dark unit, 50% more.`,
                break: 600
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
{ //Bouncy Bunny Luana - Complete
        id: 109621,
        name: `Bouncy Bunny Luana`,
        location: `Limited Equip Summon: Phantom Snipers Halloween Banner`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109620.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109621.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109620.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109621.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 3500% Light DMG (Magic). Increases Magic DMG by 60% & Accuracy by 30% for 10s.`,
                break: 300
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Everyone is free to pick their costume for the fall festival, so there are invariably a handful of shocking selections such as Luana's. Still, no one could work up the courage to dissuade her from wearing what was bought with her own hard-earned allowance money. The perfectly innocent reason she chose it further complicated matters: "Hart said he likes bunnies."`
    },
{ //True 'Astral Bolt' - Complete
        id: 109631,
        name: `True 'Astral Bolt'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109630.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109631.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109630.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109631.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 10000% Water DMG (Physical). Reduces enemy's Physical resistance by 20% for 12s. If the user is a Water unit, 15% more.`,
                break: 700
            },
        passive: 
            {
                ability1: `When equipped by Priscilla-IS, Auto-fills Arts gauge by 3 (Only once).`,
                ability2: `ATK 5% UP.`
            },
        lore: `A magic compound bow once sealed away for its sinister powers. Though no one can tell when it was made, the mechanisms responsible for its tremendous lethality cannot be recreated or analyzed by modern technology. The most fearsome attribute is how anyone who wields it even for a short time, regardless of nature or personality, will grow fiercely attached, their destructive impulses stoked beyond control.`
    },
{ //Astral Bolt - Complete
        id: 109641,
        name: `Astral Bolt`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109640.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109641.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109640.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109641.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `35s CT - 6000% Water DMG (Physical). If the user is a Water unit, Auto-fills Arts gauge by 1 & increases Physical DMG by 30% for 10s.`,
                break: 500
            },
        passive: 
            {
                ability1: `ATK 5% UP when HP is 90% or more.`
            },
        lore: `A complex magical blow long stored within a imperial armory for its shady past only to suddenly vanish. The strength of its shots is not determined by physical might, but magical aptitude. Additionally, it will use that magic to create shadow projectiles in the absence of tangible arrows.`
    },
{ //Otherlight - Complete
        id: 109651,
        name: `Otherlight`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109650.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109651.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109650.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109651.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 4500% Water DMG (Physical). Increases DMG by 5% for 8s. If the user is a Water unit, 10% more.`,
                break: 400
            },
        passive: 
            {
                ability1: `DEF 5% UP.`
            },
        lore: `A coat worn by those who shoot or kill for a living. It increases one's ability to conceal their presence by altering the target's mind. Essentially, it causes the brain to selectively ignore visual information. However, the wearer might start to believe they are being rudely disregarded as a result.`
    },
{ //True 'Cypher' - Missing Lore
        id: 109661,
        name: `True 'Cypher'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109660.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109661.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109660.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109661.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 8000% Earth DMG (Physical). Reduces enemy's Poison resistance by 30% for 20s. Increases Arts gauge by 50 (First time only).`,
                break: 800
            },
        passive: 
            {
                ability1: `When equipped by Groitz, DMG & Skill CT speed 30% UP (Only once).`,
                ability2: `ATk 15% UP when HP is 90% or more.`
            },
        lore: `Missing Lore.`
    },
{ //True 'Crew V' - Missing Lore
        id: 109671,
        name: `True 'Crew V'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109670.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109671.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109670.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109671.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 7000% Earth DMG (Magic). Increases DMG & Earth DMG by 25% for 25s.`,
                break: 900
            },
        passive: 
            {
                ability1: `When equipped by Groitz, Arts gauge 50 UP at Revelation deactivation (Only once).`,
                ability2: `DEF 10% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Cypher - Missing Lore
        id: 109681,
        name: `Cypher`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109680.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109681.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109680.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109681.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 7000% Earth DMG (Physical). 70% chance of Poisoning enemy.`,
                break: 700
            },
        passive: 
            {
                ability1: `DMG 30% UP when enemy is Poisoned.`
            },
        lore: `Missing Lore.`
    },
{ //Crew V - Complete
        id: 109691,
        name: `Crew V`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109690.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109691.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109690.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109691.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 5000% Earth DMG (Magic). Increases Earth DMG & Skill CT speed by 30% for 10s.`,
                break: 700
            },
        passive: 
            {
                ability1: `Fire Resistance 5% UP.`
            },
        lore: `A unique outfit from pure magic essence by a master of technology lost to history. Despite the resemblance to regular fabric, not a single stitch or seam can be found. The ethereal nature allows it to change shape as required.`
    },
{ //Vagrant - Complete
        id: 109701,
        name: `Vagrant`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109700.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109701.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109700.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109701.png`
            },
        stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
        skillset:  
            {
                skill: `35s CT - Auto-fills all allies' Arts gauge by 1 & increases Earth units' ATK by 25% for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: `DEF 5% UP.`
            },
        lore: `Groitz's boots. The soles are inlaid with crystal fragments that naturally repulse energies in the ground. This repulsion can be adjusted to do anything from muffle footsteps to leap great distances. The fact it does not expect one's own magic reserves makes them all the more convenient.`
    },
{ //Shielding Edge 'Will Craver' - Missing Lore
        id: 109711,
        name: `Shielding Edge 'Will Craver'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109710.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109711.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109710.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109711.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `90s CT - 11000% Light DMG (Physical). Faints user for 5s.`,
                break: 500
            },
        passive: 
            {
                ability1: `Skill CT speed 5% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Abyss Edge 'Star Craver' - Missing Lore
        id: 109721,
        name: `Abyss Edge 'Star Craver'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109720.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109721.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109720.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109721.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 12000% Light DMG (Physical). Auto-fills Arts gauge by 3 & increases Physical DMG by 30% for 20s. Faints user for 5s.`,
                break: 1000
            },
        passive: 
            {
                ability1: `ATK 10% UP.`
            },
        lore: `Missing Lore.`
    },
{ //True 'Barbatos' - Complete
        id: 109731,
        name: `True 'Barbatos'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109730.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109731.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109730.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109731.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 12000% Earth DMG (Physical). Reduces enemy's Physical resistance by 20% for 15s. If the user is an Earth unit, 10% more.`,
                break: 700
            },
        passive: 
            {
                ability1: `When equipped by Vultee-Lr, DMG taken 10% DOWN & Will Not Flinch (Only once).`,
                ability2: `Skill CT speed 10% UP.`
            },
        lore: `A super-heavyweight turret gun modified for full compatibility with Vultee's prosthetic arm. Unsparing focus on firepower meant anything that resembled a safety device was stripped away. Without his strength-boosting arm, it would pose only slightly less risk to him than the target. The fact that he went through so much trouble to properly wield it proves his commitment.`
    },
{ //Barbatos Mk. II - Complete
        id: 109741,
        name: `Barbatos Mk. II`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109740.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109741.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109740.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109741.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `80s CT - 12000% Earth DMG (Physical). Increases ATK by 50% for 10s. If the user is an Earth unit, 30% more.`,
                break: 1000
            },
        passive: 
            {
                ability1: `BE Output 5% UP.`
            },
        lore: `The largest class of portable turret gun. Originally developed to be installed at a fixed location, it was later miniaturized for quick repositioning. Even so, firing it unmounted is nearly impossible without superhuman strength. Vultee can only handle it due to the enhanced power output of his prosthetic arm.`
    },
{ //Selunian Stormers - Complete
        id: 109751,
        name: `Selunian Stormers`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109750.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109751.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109750.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109751.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `30s CT - Reduces target's Earth DMG taken by 40% for 10s. Recovers HP by 10% of DMG taken.`,
                break: 0
            },
        passive: 
            {
                ability1: `ATK UP by 10% of MAX HP.`
            },
        lore: `Military boots created in Selunia. They are sturdy enough to give extra anchoring for Vultee's massive weapon while flexible enough for quick movements. Their high performance even shocks expert shoemakers in the capital, and reproducing a fraction of it takes too much effort for most imitators. He has sworn by them ever since he chose to pursue a career as a marksman.`
    },
{ //Giltsole Boots - Missing Lore
        id: 109761,
        name: `Giltsole Boots`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109760.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109761.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109760.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109761.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 9000% Fire DMG (Magic). Increases Fire DMG by 50% for 10s.`,
                break: 900
            },
        passive: 
            {
                ability1: `Accuracy 20% UP when HP is 90% or more.`
            },
        lore: `Missing Lore.`
    },
{ //True 'Sky Light' - Complete.
        id: 109771,
        name: `True 'Sky Light'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109770.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109771.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109770.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109771.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 10000% Light DMG (Magic). Reduces enemy's Light resistance by 30% for 10s. If the user is a Light unit, increases DMG by 50% for 10s.`,
                break: 700
            },
        passive: 
            {
                ability1: `When equipped by Emperor Isliid, Accuracy 30% UP (Only once).`,
                ability2: `10% chance of evading Magic ATK.`
            },
        lore: `A sword gleaming bright enough to overwhelm the power of the divine. A God forged it to subvert the Ancient-established order. After fulfilling that purpose, it was given to the Original Human and thus passed down through the unbroken imperial Isliid lineage. Now, Humans revere it as a symbol of fearlessness against seemingly superior foes.`
    },
{ //True 'Terra Master' - Complete
        id: 109781,
        name: `True 'Terra Master'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109780.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109781.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109780.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109781.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `35s CT - 5000% Light DMG (Physical). Auto-fills all allies' Arts gauge by 3 & increases Human units' DMG by by 35% for 10s.`,
                break: 500
            },
        passive: 
            {
                ability1: `When equipped by Emperor Isliid, all stats 30% UP (Only once).`,
                ability2: `DMG 20% UP during Break.`
            },
        lore: `A legendary sword that grants its wielder the ability to read minds, leading them to victory. Despite its fame, the details of its creation and whereabouts are lost to history. among the few known facts is how it was bestowed to the first emperor of Isliid who decimated the opposing Human faction with its power overnight.`
    },
{ //True 'Dark Bringer' - Complete
        id: 109791,
        name: `True 'Dark Bringer'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109790.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109791.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109790.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109791.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `55s CT - 13000% Light DMG (Physical). Reduces enemy's Physical resistance by 40% for 10s. If the user is a Light unit, increases ATK by 40% for 10s.`,
                break: 1000
            },
        passive: 
            {
                ability1: `When equipped by Emperor Isliid, DMG 30% UP (Only once).`,
                ability2: `10% chance of evading Physical ATK.`
            },
        lore: `A sword brimming with dark energy that contains the soul of a trapped apparition. Long ago, demons used it to fight others of their kind drunk with power with the help of oppressed Humans. This weapon they created together represented their joint effort. Unfortunately, it was not enough to overcome the racial barrier for long, and it ironically came into the hand of a Human emperor who slew countless more Demons.`
    },
{ //Sky Light Replica - Complete
        id: 109801,
        name: `Sky Light Replica`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109800.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109801.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109800.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109801.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 7000% Light DMG (Magic). Increases Arts gauge by 35 when HP is 85% or more.`,
                break: 600
            },
        passive: 
            {
                ability1: `DMG to Gods 20% UP.`
            },
        lore: `A copy of Sky Light, the Sword of Coruscation. The Emperor of Isliid's three blades must be sealed at all times outside of combat, and even imitations are used during ceremonies. The lone exception is the coronation rite due to a customary belief they decide the bearer and thus the nation's master. As a result, some people joke that the true rulers of Isliid are the swords themselves.`
    },
{ //Terra Master Replica - Complete
        id: 109811,
        name: `Terra Master Replica`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109810.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109811.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109810.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109811.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `40s CT - 4000% Light DMG (Physical). Negates all allies' DMG taken for a total of 2000 & auto-fills Arts gauges by 1 for 20s.`,
                break: 500
            },
        passive: 
            {
                ability1: `DMG to Humans 20% UP.`
            },
        lore: `A copy of Terra Master, the Sword of Control. While inferior to the original, the edge is still incredibly keen, and it has been enchanted with near-weightlessness. Long symbolizing the Human race's ability to forge the future, it is most synonymous with imperial might among the Emperor's three blades.`
    },
{ //Dark Bringer Replica - Missing Lore
        id: 109821,
        name: `Dark Bringer Replica`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109820.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109821.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109820.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109821.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 10000% Light DMG (Physical). Increases all status by 10% for 7s. If the user is a Light unit, 10% more.`,
                break: 400
            },
        passive: 
            {
                ability1: `DMG to Demons 20% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Joyful Santa Hart - Missing Lore
        id: 109831,
        name: `Joyful Santa Hart`,
        location: `Limited Equip Summon: Phantom Snipers Christmas Banner`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109830.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109831.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109830.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109831.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `40s CT - Increases target's Arts gauge by 50 & all allies' Arts gauge by 20.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
{ //Sweet Snow Priscilla - Missing Lore
        id: 109841,
        name: `Sweet Snow Priscilla`,
        location: `Limited Equip Summon: Phantom Snipers Christmas Banner`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109840.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109841.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109840.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109841.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 5000% Fire DMG (Magic). Increases DMG to enemy by 20% for 10s. If the user is a Fire unit, 20% more.`,
                break: 500
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
{ //Dauntless Reindeer Vultee - Missing Lore
        id: 109851,
        name: `Dauntless Reindeer Vultee`,
        location: `Limited Equip Summon: Phantom Snipers Christmas Banner`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109850.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109851.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109850.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109851.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 8500% Water DMG (Magic). Reduces enemy's Water resistance & Evasion rate by 30% for 10s.`,
                break: 800
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
{ //Warm Santa Miranda - Missing Lore
        id: 109861,
        name: `Warm Santa Miranda`,
        location: `Limited Equip Summon: Phantom Snipers Christmas Banner`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109860.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109861.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109860.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109861.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `35s CT - Increases all allies' Magic DMG by 20% & reduces all allies' DMG taken by 20% for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
{ //Nostalgic Santa Simon - Missing Lore
        id: 109871,
        name: `Nostalgic Santa Simon`,
        location: `Limited Equip Summon: Phantom Snipers Christmas Banner`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109870.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109871.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109870.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109871.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 16000% Dark DMG (Physical). Costs 10% of MAX HP.`,
                break: 600
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
{ //Dreaming Luana Doll - Missing Lore
        id: 109881,
        name: `Dreaming Luana Doll`,
        location: `Limited Equip Summon: Phantom Snipers Christmas Banner`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109880.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109881.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109880.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109881.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `65s CT - 3500% Light DMG (Magic). Reduces enemy's Light resistance & Magic resistance by 35% for 10s.`,
                break: 300
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
{ //Winter Wonderland - Complete
        id: 109891,
        name: `Winter Wonderland`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109890.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109891.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109890.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109891.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `40s CT - Reduces all allies' Water & Magic DMG taken by 20% for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: `DEF 5% UP.`
            },
        lore: `A decoration used in an otherworld festival known as "Christmas". Inside the crystal sphere is a miniaturized scene of Raktehelm, and shaking it recreates gentle snowfall. The small but captivating sight can provide entertainment for hours on end. A member of Reigrad Adventurer's Guild is known to be particularly vulnerable to its charm.`
    },
{ //Genocider’s Helm - Missing Lore (109941 = Emi EV Equip)
        id: 109901,
        name: `Genocider’s Helm`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109900.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109901.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109900.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109901.png`
            },
            stats:
            {
                hp: 0,
                atk: 1000,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 4500% Dark DMG (Magic). Reduce enemy's Dark resistance by 30% for 8s. When used for the first time, increase own Arts gauge by 50.`,
                break: 800
            },
        passive: 
            {
                ability1: `DEF 5% UP.`
            },
        lore: `Missing Lore.`
    },
{ //True 'Assault Architect' - Complete
        id: 109911,
        name: `True 'Assault Architect'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109910.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109911.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109910.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109911.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 7000% Water DMG (Magic). Increases Magic & Water DMG by 40% for 10s.`,
                break: 800
            },
        passive: 
            {
                ability1: `When equipped by Selia the Beguiler, auto-fills Arts gauge by 2 (Only once).`,
                ability2: `Fire Resistance 10% UP.`
            },
        lore: `The magic wand of famous conjurer Selia. The crystal at the top, made up of highly endothermic materials, approaches temperatures close to absolute Zero. This increases the strength of ice magic by a magnitude of over 1000%. However, the uninitiated should never touch it as a single mistake could leave them frozen solid.`
    },
{ //Genocider’s Helm EV - Missing Lore
        id: 109941,
        name: `Genocider’s Helm EV`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: ``,
                detailmax: `/db/Equipment/Detail/item_detail_109941.png`,
                thumb: ``,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109941.png`
            },
        stats:
            {
                hp: 0,
                atk: 1000,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 4500% Dark DMG (Magic). Reduce enemy's Dark resistance by 30% for 8s. When used for the first time, increase own Arts gauge by 50.`,
                break: 800
            },
        passive: 
            {
                ability1: `DEF 5% UP.`,
                ability2: [`DMG to Light enemy 5% UP.`, `DMG to Light enemy 10% UP.`, `Magic resistance 5% UP.`, `Skill CT speed 5% UP.`, `ATK 5% UP.`, `Skill DMG 10% UP.`]
            },
        lore: `Missing Lore.`
    },
{ //極『ドスクラテル』 (Garm Raid Equip EV) - JP Complete
        id: 109951,
        name: `極『ドスクラテル』`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: ``,
                detailmax: `/db/Equipment/Detail/item_detail_109951.png`,
                thumb: ``,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109951.png`
            },
        stats:
            {
                hp: 500,
                atk: 500,
                def: 500
            },
        skillset:  
            {
                skill: `30s CT - 4500% Fire DMG (Magic). If enemy is Earth, reduce enemy's CRI resistance by 30% for 5s.`,
                break: 1250
            },
        passive: 
            {
                ability1: `DMG to Earth enemy 10% UP.`,
                ability2: [`DMG to Gods 20% UP.`, `CRI DMG 10% UP.`, `CRI DMG 20% UP.`, `BE Output 5% UP.`, `CRI rate 10% UP.`, `HP Recovery Amount 5% UP.`]
            },
        lore: `Currently unreleased in Global.`
    },
{ //極『テネブルキア』 (Ghost Shield 'Tenebrae Lux' EV) - Complete (JP)
        id: 109961,
        name: `極『テネブルキア』`,
        translate: `Ghost Shield 'Tenebrae Lux' EV`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: ``,
                detailmax: `/db/Equipment/Detail/item_detail_109961.png`,
                thumb: ``,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109961.png`
            },
        stats:
            {
                hp: 500,
                atk: 500,
                def: 500
            },
        skillset:  
            {
                skill: `5s CT - For 10s, all Allies gain a DMG barrier that negates 250 DMG. Heal all Allies' HP by 25.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increase Seal resistance by 10%.`,
                ability2: [`Increase DEF by 30% when under a barrier.`, `When HP is above 90%, Increase ATK by 15%.`, `Increase Magic resistance by 5%.`, `When HP is above 90%, Increase ATK by 10%.`, `Increase Accuracy by 10%.`, `Increase Status Ailment resistance (Except Faint) by 10%.`]
            },
        lore: `Currently unreleased in Global.`
    },
{ //True 'Asteroid' - Complete
        id: 109971,
        name: `True 'Asteroid'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109970.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109971.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109970.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109971.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 7500% Earth DMG (Magic). Increases Earth DMG by 50% & reduces enemy's Magic resistance by 30% for 10s.`,
                break: 500
            },
        passive: 
            {
                ability1: `When equipped by Foliage Carver Rosetta, DMG 40% UP. Arts gauge 50 UP when Magia Drive is activated (Only once).`,
                ability2: `Skill CT speed 20% UP when Near-Death.`
            },
        lore: `A dagger crafted especially for Rosetta. The layout of the engraved runes has been customized for sharper reactions to her commands. Though its magic is fairly weak, the improved tracking and speed allows it to strike vital spots with deadly accuracy. The blacksmith who reworked the dagger used his knowledge of Shinobi culture and weaponry as a reference.`
    },
{ //Vibrant Edge 'Asteroid' - Complete
        id: 109981,
        name: `Vibrant Edge 'Asteroid'`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109980.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109981.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109980.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109981.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 4400% Earth DMG (Magic). Increases Earth DMG & Accuracy by 30% for 10s.`,
                break: 500
            },
        passive: 
            {
                ability1: `ATK 10% UP when HP is 90% or more.`
            },
        lore: `A dagger with finely engraved runes across the blade. When thrown, rune magic will activate upon striking the target. The rarity of this technique combined with its wide range of effect easily catches opponents unawares. It can also return to the wielder mid-flight for a relentless string of attacks.`
    },
{ //Mana Recycler - Complete
        id: 109991,
        name: `Mana Recycler`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_109990.png`,
                detailmax: `/db/Equipment/Detail/item_detail_109991.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_109990.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_109991.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `45s CT - Auto-fills target's Arts gauge by 4 for 25s. If the user is an Earth unit, increases target's Magic DMG by 20% for 25s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Magic DMG from enemy 5% DOWN.`
            },
        lore: `A cloak made to contain magical power. Spellcasters must concentrate to have energy ready for use, but this also causes inevitable seepage. The inner lining keeps such loss to a minimum by redirecting it back into the body.`
    },
{ //Mana Messenger - Complete
        id: 110001,
        name: `Mana Messenger`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110000.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110001.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110000.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110001.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 4000% Earth DMG (Magic). Increases DMG by 5% for 8s. If the user is an Earth unit, 10% more.`,
                break: 500
            },
        passive: 
            {
                ability1: `DEF 5% UP.`
            },
        lore: `Gloves engraved with runes. When charging staves or blades with magic, even masters will experience rare instances of misfiring. These remove that risk by ensuring a perfectly stable flow. As this not only makes producing the spell's intended effect easier but also amplifies it slightly, they are very popular.`
    },
{ //Fortress Armor 'Geoplate' - Missing Lore
        id: 110011,
        name: `Fortress Armor 'Geoplate'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110010.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110011.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110010.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110011.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 3000% Water DMG (Physical). Costs 3% of MAX HP & increases DMG & Water DMG by 20% for 10s.`,
                break: 500
            },
        passive: 
            {
                ability1: `DEF 5% UP.`
            },
        lore: `Missing Lore.`
    },
{ //True 'Heroic Path' - Complete
        id: 110021,
        name: `True 'Heroic Path'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110020.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110021.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110020.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110021.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 7500% Fire DMG (Physical). Increases DMG by 50% & reduces enemy's Fire resistance by 30% for 10s.`,
                break: 700
            },
        passive: 
            {
                ability1: `When equipped by Charlotte, Status Ailment resistance 30% UP (except Faint) & auto-fills Arts gauge by 2 (Only once).`,
                ability2: `Skill CT speed 10% UP when HP is 90% or more.`
            },
        lore: `Charlotte's trusty weapon. The Scarlet Mana Stone worked into the blade glows brightly in response to its holder's spirit. both her prestigious bloodline and intense training allows the sword to glow an even brighter red. She was inspired to become a hero from the moment she grasped the hilt, but she knows months and years of committed effort will be needed for her to reach her goal.`
    },
{ //Heroic Path - Complete
        id: 110031,
        name: `Heroic Path`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110030.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110031.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110030.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110031.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 8200% Fire DMG (Physical). Increases Physical DMG by 30% for 10s. If the user is a Fire unit, 30% more.`,
                break: 700
            },
        passive: 
            {
                ability1: `Accuracy 20% UP when HP is 90% or more.`
            },
        lore: `A two-handed sword imbued with the power of light and flame. Once stored in the kingdom's treasury, an aged soldier took it with permission and lent it to his granddaughter. Each swing creates a blazing stream for attacks which are difficult to dodge and fatal upon direct contact. Many who witness the girl wrapped in the weapon's fire say she resembles its proper owner, the Hero of Famelle Zeorg, in his younger days.`
    },
{ //Long-Eared Crown - Complete.
        id: 110041,
        name: `Long-Eared Crown`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110040.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110041.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110040.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110041.png`
            },
        stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
        skillset:  
            {
                skill: `40s CT - Increases all allies' Arts gauge by 20. If the user is a Fire unit, increases Arts DMG by 20% for 8s.`,
                break: 0
            },
        passive: 
            {
                ability1: `ATK 5% UP.`
            },
        lore: `Rabbit-shaped headgear belonging to Charlotte. It absorbs magic energy in the air to relieve the wearer's fatigue. Many believe this is what lets her wield a sword the size of her body with ease. However, she keeps it on even outside of battle for the simple reason that she likes bunnies.`
    },
{ //Blaze Fan 'Magnigate' - Missing Lore
        id: 110051,
        name: `Blaze Fan 'Magnigate'`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110050.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110051.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110050.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110051.png`
            },
            stats:
            {
                hp: 0,
                atk: 140,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 3500% Fire DMG (Magic). Reduce Giant Boss' Fire resistance by 15% for 8s.`,
                break: 500
            },
        passive: 
            {
                ability1: `Burn Time Reduction & Burn Evasion rate 10% UP`
            },
        lore: `Missing Lore.`
    },
{ // Magnigate EV - Missing Lore
        id: 110061,
        name: `Magnigate EV`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 4,
        image: 
            {
                detail: ``,
                detailmax: `/db/Equipment/Detail/item_detail_110061.png`,
                thumb: ``,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110061.png`
            },
        stats:
            {
                hp: 0,
                atk: 140,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 3500% Fire DMG (Magic). Reduce Giant Boss' Fire resistance by 15% for 8s.`,
                break: 500
            },
        passive: 
            {
                ability1: `Burn Time Reduction & Burn Evasion rate 10% UP`,
                ability2: [`DMG to Beasts 10% UP.`, `Water resistance 5% UP.`, `DMG to enemy in Break 10% UP.`, `ATK 5% UP.`, `DEF 5% UP.`, `ATK 10% UP when own HP is 90% or above.`]
            },
        lore: `Missing Lore.`
    },
    { //Super 'Gramlude' - Missing Lore
        id: 110070,
        name: `Super 'Gramlude'`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: ``,
                detailmax: `/db/Equipment/Detail/item_detail_110070.png`,
                thumb: ``,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110070.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `2s CT - 5000% Fire DMG (Magic). Increase own Critical DMG by 30% (Stacks) for 15s.`,
                break: 500
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Super 'Eisenstern' (LCest Ultra Equip) - Missing Lore
        id: 110080,
        name: `Super 'Eisenstern'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: ``,
                detailmax: `/db/Equipment/Thumbnail/item_thumbnail_110080.png`,
                thumb: ``,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110080.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `2s CT - 3000% Light DMG (Physical). Heal all allies' HP by 1000. Reduce enemy's Physical resistance by 5% (Stacks) for 12s.`,
                break: 300
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
{ //True 'Gramlude' - Missing Lore
        id: 110091,
        name: `True 'Gramlude'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110090.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110091.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110090.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110091.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `65s CT - 12000% Fire DMG (Magic). Increase own Magic DMG by 50% and reduce enemy's Fire resistance by 30% for 15s.`,
                break: 1000
            },
        passive: 
            {
                ability1: `When equipped by Orvell (Fire), increase own DMG and Status Ailment resistance by 20% (Only once).`,
                ability2: `Physical resistance 10% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Demon Blade 'Gramlude' - Missing Lore
        id: 110101,
        name: `Demon Blade 'Gramlude'`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110100.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110101.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110100.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110101.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 8000% Fire DMG (Magic). Increase own Critical DMG by 50% for 10s. If user is Fire, increase own ATK by 30% for 10s.`,
                break: 600
            },
        passive: 
            {
                ability1: `DMG 10% UP when enemy is in Break.`
            },
        lore: `Missing Lore.`
    },
{ //Edel Rüstung - Missing Lore
        id: 110111,
        name: `Edel Rüstung`,
        translate: `Edel Rustung`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110110.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110111.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110110.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110111.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `40s CT - Increase all allies' DMG resistance by 25% for 10s. If user is God, increase all allies' Paralysis and Freeze resistance by 100% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `ATK 5% UP.`
            },
        lore: `Missing Lore.`
    },
{ //True 'Eisenstern' - Missing Lore.
        id: 110121,
        name: `True ''Eisenstern'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110120.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110121.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110120.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110121.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 11000% Light DMG (Physical). Increase own DMG and Equipment DMG by 40% for 20s.`,
                break: 900
            },
        passive: 
            {
                ability1: `When equipped by Cestina (Light), increase own ATK by 50% and Arts gauge by 1/s (Only once).`,
                ability2: `Heal Amount 20% UP (Except Lifesteal).`
            },
        lore: `Missing Lore.`
    },
{ //Divine Lance 'Eisenstern' - Missing Lore
        id: 110131,
        name: `Divine Lance 'Eisenstern'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110130.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110131.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110130.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110131.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `20s CT - 3000% Light DMG (Physical). Increase own Light DMG by 25% for 10s.`,
                break: 300
            },
        passive: 
            {
                ability1: `Blind Time Reduction & Blind Evasion rate 30% UP`
            },
        lore: `Missing Lore.`
    },
{ //Zenit Tiara - Missing Lore
        id: 110141,
        name: `Zenit Tiara`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110140.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110141.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110140.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110141.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `50s CT - Increase all God allies' BE Output and MAX HP by 25% for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increase all allies' Accuracy rate by 20%.`
            },
        lore: `Missing Lore.`
    },
{ //True 'Mukudori' - Missing Lore
        id: 110151,
        name: `True 'Mukudori'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110150.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110151.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110150.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110151.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 9000% Water DMG (Physical). Increase own DMG by 50% and reduce enemy's Water resistance by 30% for 10s.`,
                break: 800
            },
        passive: 
            {
                ability1: `When equipped by Mizuki the Twelfth, increase all stats by 20% and Arts gauge by 100 when Quest starts (Only once).`,
                ability2: `Physical resistance 10% UP.`
            },
        lore: `Missing Lore.`
    },
{ //True 'Hell's Throat' - Missing Lore
        id: 110161,
        name: `True 'Hell's Throat'`,
        location: `Ginzo: Battle Form Stage Clear Reward`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110160.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110161.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110160.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110161.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 6500% Dark DMG (Physical). If user is Dark, increase own CRI Rate by 100% for 8s. If user is Human, increase own CRI DMG by 50% for 8s.`,
                break: 700
            },
        passive: 
            {
                ability1: `When equipped by Ginzo: Battle Form, increase own stats by 30% and Arts gauge by 1/s (Only once).`,
                ability2: `ATK 5% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Resonant Blades 'Mukudori' - Missing Lore
        id: 110171,
        name: `Resonant Blades 'Mukudori'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110170.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110171.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110170.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110171.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 7000% Water DMG (Physical). Increase own Water DMG by 50% for 8s. When used for the first time, increase own Arts gauge by 50.`,
                break: 400
            },
        passive: 
            {
                ability1: `ATK 10% UP when own HP is 90% or above.`
            },
        lore: `Missing Lore.`
    },
{ //Shinobi Gear 'Paaka' - Missing Lore
        id: 110181,
        name: `Shinobi Gear 'Paaka'`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110180.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110181.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110180.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110181.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `40s CT - Increase target's Arts gauge by 50. If user is Water, increase target's Accuracy rate by 40% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Max HP 5% UP.`
            },
        lore: `Missing Lore.`
    },
{ //腐刀『冥府ノ喉』 (WarGinzo Farmable) - JP Complete
        id: 110191,
        name: `腐刀『冥府ノ喉』`,
        translate: `'Throat of the Underworld'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110190.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110191.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110190.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110191.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `30s CT - 5000% Dark DMG (Physical). Heal 1 of own Status Ailments (except Faint).`,
                break: 500
            },
        passive: 
            {
                ability1: `ATK 5% UP.`
            },
        lore: `Currently unreleased in Global.`
    },
{ //鬼忍装『紫紅』 (WarGinzo Farmable) - JP Complete
        id: 110201,
        name: `鬼忍装『紫紅』`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110200.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110201.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110200.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110201.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `40s CT - Increase all allies' Physical resistance by 30% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Hellfire resistance 10% UP.`
            },
        lore: `Currently unreleased in Global.`
    },
{ //Stronghold Armor 'Geocradle' - Missing Lore
        id: 110211,
        name: `Stronghold Armor 'Geocradle'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110210.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110211.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110210.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110211.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 5500% Water DMG (Physical).`,
                break: 600
            },
        passive: 
            {
                ability1: `Water DMG 50% UP. Costs 5% of MAX HP first for 50s of quest (Only once).`
            },
        lore: `Missing Lore.`
    },
{ //True 'Tenchi Raimei' - Missing Lore
        id: 110221,
        name: `True 'Tenchi Raimei'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110220.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110221.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110220.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110221.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 8000% Light DMG (Physical). Increase own Light DMG by 70% for 20s.`,
                break: 700
            },
        passive: 
            {
                ability1: `When Equipped by Thetis (Light), increase Arts Gauge by 2/s and Paralysis & Freeze resistance by 100% (Only once).`,
                ability2: `Skill DMG 20% UP.`
            },
        lore: `Missing Lore.`
    },
{ //True 'Noble Blood' - Missing Lore
        id: 110231,
        name: `True 'Noble Blood'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110230.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110231.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110230.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110231.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 8000% Dark DMG (Magic). Increase own Dark DMG by 70% for 20s.`,
                break: 700
            },
        passive: 
            {
                ability1: `When equipped by Riviera (Dark), increase own Skill CT speed by 30% and Arts gauge by 100 at the Start of Quest (Only once).`,
                ability2: `Magic DMG 10% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Tenchi Raimei - Missing Lore
        id: 110241,
        name: `Tenchi Raimei`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110240.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110241.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110240.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110241.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 4000% Light DMG (Physical). Reduce enemy's Light resistance by 20% for 8s. If user is Light, reduce enemy's Evasion rate by 20% for 8s.`,
                break: 600
            },
        passive: 
            {
                ability1: `ATK 5% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Tenchi Shojin - Missing Lore
        id: 110251,
        name: `Tenchi Shojin`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110250.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110251.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110250.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110251.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 7000% Light DMG (Physical). Increase own Physical DMG by 40% for 20s. If user is Light, increase own Arts gauge by 1 for 20s.`,
                break: 600
            },
        passive: 
            {
                ability1: `DMG to Dark enemy 5% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Noble Gash - Missing Lore
        id: 110261,
        name: `Noble Gash`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110260.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110261.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110260.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110261.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 7000% Dark DMG (Magic). Heals 2% of own DMG dealt for 6s.`,
                break: 900
            },
        passive: 
            {
                ability1: `ATK 10% UP when Near-Death.`
            },
        lore: `Missing Lore.`
    },
{ //Mortal Scarlet - Missing Lore
        id: 110271,
        name: `Mortal Scarlet`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110270.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110271.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110270.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110271.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `45s CT - Increase all allies' Arts gauge by 2 for 15s. When used for the first time, increase all allies' CRI rate by 100% for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Dark resistance 5% UP.`
            },
        lore: `Missing Lore.`
    },
{ //True 'Hyakka' - Missing Lore
        id: 110281,
        name: `True 'Hyakka'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110280.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110281.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110280.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110281.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 7500% Earth DMG (Physical). Increase own DMG by 50% and reduce enemy's Earth resistance by 30% for 10s.`,
                break: 700
            },
        passive: 
            {
                ability1: `When equipped by Sakuya, increase Skill CT speed by 30% and increase Max number of possible planted bombs by 1.`,
                ability2: `Evasion rate 10% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Blooming Edges 'Hyakka' - Missing Lore
        id: 110291,
        name: `繚乱刃『百花』`,
        translate: `'Hyakka'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110290.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110291.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110290.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110291.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 5500% Earth DMG (Physical). Increase own Physical DMG and Accuracy rate by 30% for 8s.`,
                break: 500
            },
        passive: 
            {
                ability1: `ATK 5% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Shinobi Scarf 'Mafura' - Missing Lore
        id: 110301,
        name: `Shinobi Scarf 'Mafura'`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110300.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110301.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110300.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110301.png`
            },
        stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
        skillset:  
            {
                skill: `45s CT - Increase target's Arts gauge by 30. If user is Earth, increase target's Arts gauge by 40 instead. If target is Earth, increase target's ATK by 30% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Skill CT speed 5% UP.`
            },
        lore: `Missing Lore.`
    },
{ //ティアーズ・オブ・エイジ (Fongion Raid Equip) - JP Complete
        id: 110311,
        name: `ティアーズ・オブ・エイジ`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110310.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110311.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110310.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110311.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `65s CT - 4000% Light DMG (Physical). Increase own BE Output by 20% and Skill CT Speed by 100% for 7s.`,
                break: 300
            },
        passive: 
            {
                ability1: `Skill DMG 10% UP.`
            },
        lore: `Currently unreleased in Global.`
    },
{ //Super Spiker - Complete
        id: 110321,
        name: `Super Spiker`,
        location: `Limited Equip Summon: Cross the Blaze Summer Banner`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110320.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110321.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110320.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110321.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `35s CT - 6000% Fire DMG (Physical). Increases all Stats by 10% for 6s. If the user is a Fire unit, 10% more.`,
                break: 400
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Regulation-size equipment for beach battleball, a combination of volleyball and martial arts. The maker claims it can withstand the weight of a dragon. Some joke about building castle walls with them, but the same super-tough ore used in barriers and ramparts is mixed into the flexible coating. Getting hit by one seriously hurts.`
    },
{ //Ces' Sandy Blossom - Complete
        id: 110331,
        name: `Ces' Sandy Blossom`,
        location: `Limited Equip Summon: Cross the Blaze Summer Banner`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110330.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110331.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110330.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110331.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `85s CT - Increases all allies' Arts gauge by 50. Auto-fills all allies' Arts gauge by 2 for 25s (First time only).`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A swimsuit with a design inspired by tropical flowers. Cestina purchased it on impulse only to realize how skimpy it was after returning home. She hid it deep inside her wardrobe, but a sudden change of heart struck her moments before departure. That delay while she ran back to the castle gave her siblings reason enough for teasing along the journey.`
    },
{ //Super Douser - Missing Lore
        id: 110341,
        name: `Super Douser`,
        location: `Limited Equip Summon: Cross the Blaze Summer Banner`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110340.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110341.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110340.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110341.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 6000% Water DMG (Magic). Increases CRI DMG by 100% for 10s.`,
                break: 500
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
{ //Liza's Mature Look - Complete
        id: 110351,
        name: `Liza's Mature Look`,
        location: `Limited Equip Summon: Cross the Blaze Summer Banner`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110350.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110351.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110350.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110351.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 4500% Water DMG (Physical). Increases Arts gauge by 30. Increases HP recovery amount by 20% for 20s.`,
                break: 700
            },
        passive: 
            {
                ability1: ``
            },
        lore: `This bikini is a popular choice for young women who have never worn before. Besides the classic design, comfortable fit, and cute ribbons, the top is enchanted with a back-stretching spell to make the wearer ever-so-slightly taller. Liza, after nearly an hour's deliberation, became its latest customer.`
    },
{ //Orvell's Royal Flare - Complete
        id: 110361,
        name: `Orvell's Royal Flare`,
        location: `Limited Equip Summon: Cross the Blaze Summer Banner`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110360.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110361.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110360.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110361.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `80s CT - 10000% Light DMG (Physical). Increases Physical DMG by 70% for 10s. If the user is a God unit, 30% more.`,
                break: 1000
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Fireworks invented by Orvell with the simple intent of making huge explosions in the night sky. The launcher is small enough to carry one-handed, but it releases a massive fireball once ignited. Still under development when summer vacation began, the results were satisfactory enough to move on to the production stage. The name, however, is being kept intact.`
    },
{ //Luahn's Wabi-Sabi Jams - Complete
        id: 110371,
        name: `Luahn's Wabi-Sabi Jams`,
        location: `Limited Equip Summon: Cross the Blaze Summer Banner`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110370.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110371.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110370.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110371.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `70s CT - 8000% Dark DMG (Physical). Reduces enemy's Physical resistance by 40% for 10s. If the user is a God unit, 20% more.`,
                break: 800
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A new fashion label started with the concept of making trendy yet traditional designs for young men, such as these shorts. Though it has considerable resiliency, it does not quite hold up to the rigors of beach battleball -- something Luahn discovered first-hand. When interviewed after the tournament, he said, "I never expected it would split open in the back like that..."`
    },
{ //Angelas' Sweet & Saucy - Complete
        id: 110381,
        name: `Angelas' Sweet & Saucy`,
        location: `Limited Equip Summon: Cross the Blaze Summer Banner`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110380.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110381.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110380.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110381.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 2000% Fire DMG (Magic). If user is Female, increases own Arts gauge by 1 for 10s. If user is Male, increases own Break Power by 30% for 10s`,
                break: 1500
            },
        passive: 
            {
                ability1: ``
            },
        lore: `An exotic swimsuit Angelas obtained from a shady, high-pressure market peddler. She regretted the purchase for a few days until finally working up the courage to wear it. Not only did she think it looked cute on her, so did everyone she showed it to. More than improving her mood, that attention went straight to her head.`
    },
{ //Victoire's Victory - Complete
        id: 110391,
        name: `Victoire's Victory`,
        location: `Limited Equip Summon: Cross the Blaze Summer Banner`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110390.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110391.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110390.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110391.png`
            },
        stats:
            {
                hp: 120,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - Recovers target's HP by 2000. If the target is a male unit, increases his ATK by 30% for 8s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A bold and aggressive bikini that accentuates the wearer's natural physique. Simply putting it on gives one the poise and manner of a top model. In Victoire's case, who already had a figure sculpted by proper posture and breathing methods, there is no more fitting swimwear.`
    },
{ //Airsenal Shell - Complete
        id: 110401,
        name: `Airsenal Shell`,
        location: `Limited Equip Summon: Cross the Blaze Summer Banner`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110400.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110401.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110400.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110401.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 2000% Earth DMG (Physical). Increases Physical DMG by 10% & reduces Physical DMG taken by 10% for 10s.`,
                break: 100
            },
        passive: 
            {
                ability1: ``
            },
        lore: `An inflatable raft for two. The stability and comfort its large size offers makes it perfect for floating on the waves, but it needs a lot of time and air to fill. Eagle was silently flattered they borrowed the shape of his shield for the design, but he was unamused by the turtle-like caricature.`
    },
{ //Magisteel-Toe Boots - Complete
        id: 110411,
        name: `Magisteel-Toe Boots`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110410.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110411.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110410.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110411.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `40s CT - Auto-fills all allies' Arts gauges by 3 & increases all allies' HP recovery amount 15% UP for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Water resistance 5% UP.`
            },
        lore: `Hart's favorite pair of boots. Their standard appearance belies a special composition designed for mages. Its magically charged laces fed the gravity-control spell in the soles for extra agility, or they can be wrapped around the wearer's arms for a boost of energy. However, his first attempt at the latter saw him immediately trip over the loosened flaps, so he never did it again.`
    },
{ //魔双甲『ナルクラーゲン』 (Ragsherum Mines Equip) - Complete
        id: 110421,
        name: `魔双甲『ナルクラーゲン』`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110420.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110421.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110420.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110421.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `30s CT - 3000% Dark DMG (Physical). Consumes 30% of own MAX HP. Increases own DMG and Dark DMG by 20% for 10s.`,
                break: 500
            },
        passive: 
            {
                ability1: `DEF 5% UP.`
            },
        lore: `Currently unreleased in Global.`
    },
{ //True 'Seafoam Pareo' - Complete
        id: 110431,
        name: `True 'Seafoam Pareo'`,
        location: `Alchemist - Exchange via Rainbow Gems (Limited)`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110430.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110431.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110430.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110431.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 9000% Water DMG (Magic). Increases Arts gauge by 30. If the user is a Water unit, increases All Stats by 30% for 10s.`,
                break: 800
            },
        passive: 
            {
                ability1: `When equipped by Beach Majesty Juno, extends duration of True Arts by 20s (Only once).`,
                ability2: `Skill CT speed 5% UP.`
            },
        lore: `Juno's vivid pareo-style bikini, only slightly altered. She tried it on one year later, and the strange snugness sent a chill down her spine. It was impossible she gained weight; her daily routine included and hour of rigorous exercise. Then, she remembered Riviera would be a reliable source of information on the matter. That struggle to regain composure made it clear she forgot some Demons were exceptionally late bloomers.`
    },
{ //True 'Precious One-Piece - Complete
        id: 110441,
        name: `True 'Precious One-Piece`,
        location: `Alchemist - Exchange via Rainbow Gems (Limited)`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110440.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110441.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110440.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110441.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `40s CT - Increases target's Arts gauge by 80. Increases target's Earth DMG by 50% for 12s.`,
                break: 0
            },
        passive: 
            {
                ability1: `When equipped by Summer Princess Leone, Auto-fills Arts gauge by 3 (Only once).`,
                ability2: `DEF 40% UP when Near-Death.`
            },
        lore: `Leone's cute leotard-style swimsuit, unaltered in any way. She tried it on one year later, and the comfortable fit sent a chill down her spine. It was impossible she had not grown; her daily snack included a glass of nutritious milk. Then, she remembered her sister would be a reliable source of information on the matter. That glimmer of hope in her eyes made it clear she was unaware of what disappointment the future held.`
    },
{ //True 'Shooting Star' - Missing Lore
        id: 110451,
        name: `True 'Shooting Star'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110450.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110451.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110450.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110451.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 9000% Water DMG (Physical). Reduce Enemies' Physical Resistance by 40% for 12s. When used for the first time, increase Allies' Stats by 30% for 12s.`,
                break: 500
            },
        passive: 
            {
                ability1: `When equipped by Valerie, increase own DMG by 30% and Arts gauge by 2/s (Only once).`,
                ability2: `Physical resistance 5% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Shooting Star - Missing Lore
        id: 110461,
        name: `Shooting Star`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110460.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110461.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110460.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110461.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 7000% Water DMG (Physical). Costs 20% of Max HP. Reduce enemy's Physical and Water resistance by 20% for 10s.`,
                break: 500
            },
        passive: 
            {
                ability1: `ATK 5% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Studded Heart - Missing Lore
        id: 110471,
        name: `Studded Heart`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110470.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110471.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110470.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110471.png`
            },
        stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
        skillset:  
            {
                skill: `40s CT - Increases target's Skill CT speed by 70% for 10s. If Target is Water, increase Target's Accuracy rate by 30% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Skill DMG 10% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Charlotte's Pure Ruffles - Complete
        id: 110481,
        name: `Charlotte's Pure Ruffles`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110480.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110481.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110480.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110481.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `20s CT - 3000% Fire DMG (Magic). Reduces enemy's Fire resistance by 20% for 20s (Stacks).`,
                break: 300
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A bright pink swimsuit Charlotte chose for the summer. Though she did like the balance between the cute frills and mature shaping, Valerie's alarming enthusiasm was the main factor. It caused her to reflect on how Valerie influenced most of her wardrobe, but as long as her best friend was happy, she didn't mind.`
    },
{ //Valerie's Sentimental Skirt - Complete
        id: 110491,
        name: `Valerie's Sentimental Skirt`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110490.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110491.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110490.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110491.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `30s CT - 5500% Water DMG (Physical). Increases Arts gauge by 30. If the user is a Water unit, increases Accuracy by 30% for 10s.`,
                break: 300
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A white dress Valerie chose for the summer. She tended towards the casual style of shirts and pants, but Charlotte pressured her into a more feminine look. Its breeziness was a bit uncomfortable, but as she watched the long hem twirl around her legs in the mirror, she began to understand the appeal.`
    },
{ //Forte's Gothic Straps - Complete
        id: 110501,
        name: `Forte's Gothic Straps`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110500.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110501.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110500.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110501.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `30s CT - Increases Human units' Arts gauges by 30. Increases Human units' DMG by 40% for 12s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Forte's one-piece swimsuit with a starkly somber design. Her soldiers, assuming the boutique clerk forced the purchase, were flabbergasted when she said it was her own decision. That contrast between the adornments and her stoic nature further enhanced the appeal. Everyone even started imagining what the rest of her private attire might look like.`
    },
{ //Emi's Smooth Streamline - Missing Lore
        id: 110511,
        name: `Emi's Smooth Streamline`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110510.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110511.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110510.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110511.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `70s CT - 4000% Dark DMG (Magic). Reduces enemy's Magic resistance by 40% for 10s. If the user is a Human unit, 20% more.`,
                break: 400
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
{ //Groitz's Grotesque Trunks - Complete
        id: 110521,
        name: `Groitz's Grotesque Trunks`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110520.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110521.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110520.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110521.png`
            },
        stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
        skillset:  
            {
                skill: `30s CT - Increases all allies' Arts gauge by 20. If the user is an Earth unit, increases ATK by 40% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Groitz's repulsive beach shorts. Although he clearly liked the sickly shade of green and creepy skull-and-thorn pattern, everyone else at the beach gave him a wide berth. Emi observed he would be useful in opening a path through the homeward-bound crowds, but that benefit did not outweigh the humiliation of being seen in his company.`
    },
{ //天魔槍『レムナシュタール』 (Za'remnah Raid Equip) - JP Complete
        id: 110531,
        name: `天魔槍『レムナシュタール』`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110530.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110531.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110530.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110531.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 5500% Dark DMG (Physical). 70% Chance to Blind enemy. If enemy is Blinded by this equipment, Reduce enemy's DMG resistance by 20% for 8s.`,
                break: 0
            },
        passive: 
            {
                ability1: `DMG 20% UP when Enemy is Blind.`
            },
        lore: `Currently unreleased in Global.`
    },
{ //Oracle Veil - Missing Lore
        id: 110541,
        name: `Oracle Veil`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110540.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110541.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110540.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110541.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 5000% Light DMG (Magic). Increase own Light DMG by 40% for 10s. When used for the first time, increase own Arts gauge by 50.`,
                break: 500
            },
        passive: 
            {
                ability1: `Magic resistance 5% UP.`
            },
        lore: `Missing Lore.`
    },
{ //True 'Soaring Z' - Complete
        id: 110551,
        name: `True 'Soaring Z'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110550.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110551.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110550.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110551.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 8000% Light DMG (Magic). Increases Light DMG & Skill CT speed by 50% for 10s.`,
                break: 500
            },
        passive: 
            {
                ability1: `When equipped by Cosmo, DMG 30% UP & auto-fills Arts gauge by 3 (Only once).`,
                ability2: `Physical DMG from enemy 5% DOWN.`
            },
        lore: `The ever-reliable partner of Cosmo, Bamint officer and rising star. It transforms her outflowing emotions into killer riffs. Although Kane insists he would never give away such an expensive article, he does not seem too concerned about her borrowing it for so long. This might be because he knows she has become good enough to form a true bond.`
    },
{ //Soaring Z (ライジングZ) - JP Complete
        id: 110561,
        name: `ライジングZ`,
        translate: `'Soaring Z'`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110560.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110561.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110560.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110561.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 5500% Light DMG (Magic). Increase own Magic DMG by 30% and reduce enemy's Paralysis resistance by 30% for 8s.`,
                break: 400
            },
        passive: 
            {
                ability1: `Skill DMG 10% UP.`
            },
        lore: `Currently unreleased in Global.`
    },
{ //グロリアスベレー (Cosmo Farmable) - JP Complete
        id: 110571,
        name: `グロリアスベレー`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110570.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110571.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110570.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110571.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 120
            },
        skillset:  
            {
                skill: `45s CT - Increase all allies' Dark and Magic resistance by 20% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `HP Recovery Amount 5% UP.`
            },
        lore: `Currently unreleased in Global.`
    },
{ //Beast Steel 'Trance Frenzy' Replica - Missing Lore
        id: 110581,
        name: `Beast Steel 'Trance Frenzy' Replica`,
        type: `/db/Mines/thumbnail/physLB6.png`,
        star: 6,
        location: `Earth Summoner's Road`,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110580.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110581.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110580.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110581.png`
            },
        stats:
            {
                hp: 0,
                atk: 600,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 9900% Earth DMG (Physical). Reduce enemy's Earth Resistance by 40% for 7s. If user is Earth, reduce enemy's Earth Resistance by 70% instead.`,
                break: 800
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
{ //Beast Steel 'Trance Frenzy' Replica - Missing Lore
        id: 110591,
        name: `Beast Steel 'Trance Frenzy' Custom`,
        type: `/db/Mines/thumbnail/physLB6.png`,
        star: 6,
        location: `Earth Summoner's Road`,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110590.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110591.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110590.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110591.png`
            },
        stats:
            {
                hp: 0,
                atk: 600,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 12000% Earth DMG (Physical). Reduce enemy's Earth Resistance by 30% for 15s. If user is Earth, reduce enemy's Earth Resistance by 60% instead.`,
                break: 800
            },
        passive: 
            {
                ability1: `Increase all allies' Skill CT speed by 10%.`
            },
        lore: `Missing Lore.`
    },
{ //True 'Euthanasia' - Missing Lore
        id: 110601,
        name: `True 'Euthanasia'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110600.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110601.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110600.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110601.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `30s CT - Increase all allies' Arts gauge by 25. Increase all Human allies' DMG by 40% for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: `When equipped by War Hero Fen, increase own Skill CT Speed and Arts DMG by 20% (Only once).`,
                ability2: `Increase DMG to Humans by 10%.`
            },
        lore: `Missing Lore.`
    },
{ //Shrewd Cloak 'Einweiser' - Missing Lore
        id: 110611,
        name: `Shrewd Cloak 'Einweiser'`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110610.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110611.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110610.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110611.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 120
            },
        skillset:  
            {
                skill: `30s CT - Increase all allies' DMG resistance by 20% for 10s. If user is Earth, increase all allies' Poison resistance by 100% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `HP Recovery Amount 5% UP.`
            },
        lore: `Missing Lore.`
    },
{ //True 'Garuda' - Missing Lore
        id: 110621,
        name: `True 'Garuda'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110620.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110621.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110620.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110621.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `40s CT - 3000% Fire DMG (Physical). Increase own Fire DMG by 30% and DMG resistance by 20% for 10s.`,
                break: 700
            },
        passive: 
            {
                ability1: `When equipped by Kirisame, increase own Paralyze & Freeze Resistance by 100% and Increase Third Eye duration by 60s (Only once).`,
                ability2: `ATK 5% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Kingblade 'Garuda' - Missing Lore
        id: 110631,
        name: `Kingblade 'Garuda'`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110630.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110631.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110630.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110631.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `40s CT - 2300% Fire DMG (Physical). Increase own Fire resistance by 25% for 12s.`,
                break: 400
            },
        passive: 
            {
                ability1: `DEF 5% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Shinobi Shoes 'Sunika' - Missing Lore
        id: 110641,
        name: `Shinobi Shoes 'Sunika'`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110640.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110641.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110640.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110641.png`
            },
        stats:
            {
                hp: 120,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `30s CT - Heal all Allies' HP by 700 HP. If User is Fire, heal all Allies' Burn.`,
                break: 0
            },
        passive: 
            {
                ability1: `Burn Time Reduction & Burn Evasion rate 10% UP.`
            },
        lore: `Missing Lore.`
    },
{ //True 'Tenrui-Giri' - Missing Lore
        id: 110651,
        name: `True 'Tenrui-Giri'`,
        location: `Hakuro Stage Clear Reward`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110650.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110651.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110650.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110651.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `65s CT - 8000% Earth DMG (Magic). Reduce enemy's Blind resistance and Evasion rate by 30% for 10s.`,
                break: 600
            },
        passive: 
            {
                ability1: `When Equipped by Hakuro, increase own Arts DMG by 40% (Only once).`,
                ability2: `DEF 5% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Yokai Sickle "Bloody Mikazuki" Replica (SR Equip) - Missing Lore
        id: 110661,
        name: `Yokai Sickle 'Bloody Mikazuki' Replica`,
        type: `/db/Mines/thumbnail/physLB6.png`,
        star: 6,
        location: `Fire Summoner's Road`,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110660.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110661.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110660.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110661.png`
            },
        stats:
            {
                hp: 0,
                atk: 600,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 9900%  DMG (Physical). Reduce enemy's Fire Resistance by 40% for 7s. If user is Fire, reduce enemy's Fire Resistance by 70% instead.`,
                break: 800
            },
        passive: 
            {
                ability1: `DEF 5% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Yokai Sickle "Bloody Mikazuki" Custom - Missing Lore
        id: 110671,
        name: `Yokai Sickle 'Bloody Mikazuki' Custom`,
        type: `/db/Mines/thumbnail/physLB6.png`,
        location: `Fire Summoner's Road`,
        star: 6,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110670.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110671.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110670.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110671.png`
            },
        stats:
            {
                hp: 0,
                atk: 600,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 12000% Fire DMG (Physical). Reduce enemy's Fire Resistance by 30% for 15s. If user is Fire, reduce enemy's Fire Resistance by 60% instead.`,
                break: 800
            },
        passive: 
            {
                ability1: `Increase all allies' Skill CT speed by 10%.`
            },
        lore: `Missing Lore.`
    },
{ //Shinobi Guard 'Hojo' - Missing Lore
        id: 110681,
        name: `Shinobi Guard 'Hojo'`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110680.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110681.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110680.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110681.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `40s CT - Increase all Allies' Magic resistance by 30% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Hellfire resistance 10% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Hidden Dragon 'Tenrui-Giri' - Missing Lore
        id: 110691,
        name: `Hidden Dragon 'Tenrui-Giri'`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110690.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110691.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110690.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110691.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `30s CT - 5000% Earth DMG (Magic). Cure 1 of own Status Ailments (Excluding Faint).`,
                break: 500
            },
        passive: 
            {
                ability1: `ATK 5% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Rusty Waitress Marika- Missing Lore
        id: 110701,
        name: `Rusty Waitress Marika`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110700.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110701.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110700.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110701.png`
            },
        stats:
            {
                hp: 500,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - Heal all allies' HP by 200 for 20s. When used for the first time, increase all allies' Arts gauge by 10.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
{ //Field Medic Forte - Missing Lore
        id: 110711,
        name: `Field Medic Forte`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110710.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110711.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110710.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110711.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 7000% Dark DMG (Physical). Ignore 50% of enemy DEF for 8s. If user is Dark, ignore 100% of enemy's DEF instead.`,
                break: 500
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
{ //Fantom Filcher Fen - Missing Lore
        id: 110721,
        name: `Fantom Filcher Fen`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110720.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110721.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110720.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110721.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `30s CT - Increase all allies' Physical resistance by 20% and Arts gauge by 3/s for 10s`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
{ //Ruffhousing Lotte - Missing Lore
        id: 110731,
        name: `Ruffhousing Lotte`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110730.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110731.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110730.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110731.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 5000% Fire DMG (Magic). Increase own ATK by 50% for 10s. If user is not affected by any Status Ailments, increase own Arts gauge by 50. `,
                break: 400
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
{ //Magical Muscles Valerie - Missing Lore
        id: 110741,
        name: `Magical Muscles Valerie`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110740.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110741.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110740.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110741.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `80s CT - 10000% Water DMG (Physical). Increase own Physical DMG by 60% and Water Allies' DMG by 40% for 10s.`,
                break: 1000
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
{ //Shepherd of Isliid - Missing Lore
        id: 110751,
        name: `Shepherd of Isliid`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110750.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110751.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110750.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110751.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `55s CT - 10000% Light DMG (Physical). Reduce enemy's Physical resistance by 30% for 10s. If user is Human, reduce enemy's Physical resistance by 50% instead.`,
                break: 1000
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
{ //True 'Enmon Zanketsu' - Missing Lore
        id: 110761,
        name: `True 'Enmon Zanketsu'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110760.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110761.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110760.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110761.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 10000% Fire DMG (Physical). Reduce enemy's Physical resistance by 20% for 12s. If user is Fire, reduce enemies' Physical resistance by 35% instead.`,
                break: 700
            },
        passive: 
            {
                ability1: `When equipped by Demon Edge Berwick, increase Arts by 5/s and Max HP by 1000. Does not stack with Abilities of the same name.`,
                ability2: `Increase ATK by 5%.`
            },
        lore: `Missing Lore.`
    },
{ //Enmon Zanketsu - Missing Lore
        id: 110771,
        name: `Enmon Zanketsu`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110770.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110771.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110770.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110771.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 5000% Fire DMG (Physical). Increase own Fire DMG and Skill CT speed by 30% for 10s.`,
                break: 700
            },
        passive: 
            {
                ability1: `Water resistance 10% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Simmering Mantle - Missing Lore
        id: 110781,
        name: `Simmering Mantle`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110780.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110781.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110780.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110781.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 120
            },
        skillset:  
            {
                skill: `35s CT - Increase all Fire Allies' DMG resistance by 20% for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: `BE Output 5% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Fleetfire Greaves - Missing Lore
        id: 110791,
        name: `Fleetfire Greaves`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110790.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110791.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110790.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110791.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 2000% Fire DMG (Magic). Increase own Arts gauge by 10.`,
                break: 200
            },
        passive: 
            {
                ability1: `CRI rate 30% UP.`
            },
        lore: `Missing Lore.`
    },
{ //True 'Jurai' - Missing Lore
        id: 110801,
        name: `True 'Jurai'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110800.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110801.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110800.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110801.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 10000% Light DMG (Physical). Increase own DMG by 50% and increase all Light allies' DMG by 30% for 10s.`,
                break: 800
            },
        passive: 
            {
                ability1: `When equipped by Hazuki, increase all stats by 20% and increase own Arts gauge by 100 when entering Third Eye (Only once).`,
                ability2: `Skill CT speed 5% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Chainblade 'Jurai' - Missing Lore
        id: 110811,
        name: `Chainblade 'Jurai'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110810.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110811.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110810.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110811.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 8200% Light DMG (Physical). Increase own Physical DMG by 30% for 10s. If user is Light, increase own Physical DMG by 60% instead.`,
                break: 700
            },
        passive: 
            {
                ability1: `Accuracy rate 20% UP when own HP is 90% or above`
            },
        lore: `Missing Lore.`
    },
{ //Shinobi Haori 'Gaun' - Missing Lore
        id: 110821,
        name: `Shinobi Haori 'Gaun'`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110820.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110821.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110820.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110821.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 120
            },
        skillset:  
            {
                skill: `40s CT - Increase target's Light resistance and Physical resistance by 20% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `DEF 5% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Ever Flash "Fon Cruz" Replica - Missing Lore
        id: 110831,
        name: `Ever Flash 'Fon Cruz' Replica`,
        type: `/db/Mines/thumbnail/magLB6.png`,
        star: 6,
        location: `Light Summoner's Road`,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110830.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110831.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110830.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110831.png`
            },
        stats:
            {
                hp: 0,
                atk: 600,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 9900% Light DMG (Magic). Reduce enemy's Light Resistance by 40% for 7s. If user is Light, reduce enemy's Light Resistance by 70% instead.`,
                break: 800
            },
        passive: 
            {
                ability1: `DEF 5% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Ever Flash 'Fon Cruz' Custom - Missing Lore
        id: 110841,
        name: `Ever Flash 'Fon Cruz' Custom`,
        type: `/db/Mines/thumbnail/magLB6.png`,
        star: 6,
        location: `Light Summoner's Road`,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110840.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110841.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110840.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110841.png`
            },
        stats:
            {
                hp: 0,
                atk: 600,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 12000% Light DMG (Magic). Reduce enemy's Light Resistance by 30% for 15s. If user is Light, reduce enemy's Light Resistance by 60% instead.`,
                break: 800
            },
        passive: 
            {
                ability1: `Increase all allies' Skill CT speed by 10%.`
            },
        lore: `Missing Lore.`
    },
{ //True 'Mercurius' - Missing Lore
        id: 110851,
        name: `True 'Mercurius'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110850.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110851.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110850.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110851.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `50s CT - Increase all allies' Arts gauge by 4 and all allies' Light DMG by 40% (Stacks) for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `When equipped by Sublime Supernova Liza, increase all stats and Skill CT REC by 20% (Only once).`,
                ability2: `Magic DMG 10% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Sainted Staff 'Mercurius' - Missing Lore
        id: 110861,
        name: `Sainted Staff 'Mercurius'`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110860.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110861.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110860.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110861.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `55s CT - Increase target's Arts gauge by 70. If user is Light, increase target's Arts DMG by 70% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Magic DMG 10% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Sagacious Robe 'Cathedral' - Missing Lore
        id: 110871,
        name: `Sagacious Robe 'Cathedral'`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110870.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110871.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110870.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110871.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 4000% Light DMG (Magic). If the enemy is a Demon, reduce enemy's Light resistance by 25% for 10s.`,
                break: 500
            },
        passive: 
            {
                ability1: `MAX HP 5% UP.`
            },
        lore: `Missing Lore.`
    },
{ //JP Equip Name (???) - Unknown
        id: 110881,
        name: `???`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110880.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110881.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110880.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110881.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: ``,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Currently unreleased in Global.`
    },
{ //Red Flame Idol - Missing Lore
        id: 110891,
        name: `Red Flame Idol`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110890.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110891.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110890.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110891.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 1200% Fire DMG (Magic). If user is Fire, reduce enemy's Magic resistance by 20% for 8s.`,
                break: 500
            },
        passive: 
            {
                ability1: `Status Ailment resistance 20% DOWN.`
            },
        lore: `Missing Lore.`
    },
{ //Blue Drop Idol - JP Complete
        id: 110901,
        name: `Blue Drop Idol`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110900.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110901.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110900.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110901.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 1200% Water DMG (Magic). If user is Water, reduce enemy's Magic resistance by 20% for 8s.`,
                break: 500
            },
        passive: 
            {
                ability1: `Status Ailment resistance 20% DOWN.`
            },
        lore: `Missing Lore.`
    },
{ //Green Leaf Idol - JP Complete
        id: 110911,
        name: `Green Leaf Idol`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110910.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110911.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110910.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110911.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 1200% Earth DMG (Magic). If user is Earth, reduce enemy's Magic resistance by 20% for 8s.`,
                break: 500
            },
        passive: 
            {
                ability1: `Status Ailment resistance 20% DOWN.`
            },
        lore: `Missing Lore.`
    },
{ //Innocent Santa Lotte - Missing Lore
        id: 110921,
        name: `Innocent Santa Lotte`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110920.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110921.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110920.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110921.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 3000% Fire DMG (Physical). Increase own Arts DMG by 100% for 10s. If unaffected by Status Ailment, increase own Arts DMG by 150% instead.`,
                break: 300
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
{ //Thoughtful Santa Valerie - Missing Lore
        id: 110931,
        name: `Thoughtful Santa Valerie`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110930.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110931.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110930.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110931.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 10000% Water DMG (Physical). Increase own Physical DMG by 60% & reduce enemies' Water resistance by 30% (Stacks) for 12s.`,
                break: 500
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
{ //Lethargic Snow Marika - Missing Lore
        id: 110941,
        name: `Lethargic Snow Marika`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110940.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110941.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110940.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110941.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 2000% Fire DMG (Magic). Increase own Break Power by 50% for 12s. If own HP is 80% or above, increase own Break Power by 100% instead.`,
                break: 2000
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
{ //Serving Serah Doll - Missing Lore
        id: 110951,
        name: `Serving Serah Doll`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110950.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110951.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110950.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110951.png`
            },
        stats:
            {
                hp: 500,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `15s CT - Heal all allies' HP by 800. Heal all Light allies' HP by 1300 instead.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
{ //Restless Reindeer Arcana - Missing Lore
        id: 110961,
        name: `Restless Reindeer Arcana`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110960.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110961.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110960.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110961.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `60s CT - Increase Human allies' Arts gauge by 30 and all allies' CRI rate by 100% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
{ //Yule Staff 'Merecrimus' - Missing Lore
        id: 110971,
        name: `Yule Staff 'Merecrimus'`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110970.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110971.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110970.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110971.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `80s CT - Increase all allies' Magic DMG by 40% for 20s. If user is God, increase Magic DMG by 70% instead.`,
                break: 0
            },
        passive: 
            {
                ability1: `Skill CT speed 5% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Sinful Guards "Fathomless" Replica  - Missing Lore
        id: 110981,
        name: `Sinful Guards "Fathomless" Replica`,
        type: `/db/Mines/thumbnail/magLB6.png`,
        star: 6,
        location: `Dark Summoner's Road`,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110980.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110981.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110980.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110981.png`
            },
        stats:
            {
                hp: 0,
                atk: 600,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 9900% Dark DMG (Physical). Reduce enemies’ Dark resistance by 40% for 7s. If user is Dark, reduce enemies’ Dark resistance by 70% instead.`,
                break: 800
            },
        passive: 
            {
                ability1: `DEF 5% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Sinful Guards "Fathomless" Custom  - Missing Lore
        id: 110991,
        name: `Sinful Guards "Fathomless" Custom`,
        type: `/db/Mines/thumbnail/magLB6.png`,
        star: 6,
        location: `Dark Summoner's Road`,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_110990.png`,
                detailmax: `/db/Equipment/Detail/item_detail_110991.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_110990.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_110991.png`
            },
        stats:
            {
                hp: 0,
                atk: 600,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 12000% Dark DMG (Physical). Reduce enemies’ Dark resistance by 40% for 15s. If user is Dark, reduce enemies’ Dark resistance by 60% instead.`,
                break: 800
            },
        passive: 
            {
                ability1: `Increase all allies’ Skill CT REC by 10%. `
            },
        lore: `Missing Lore.`
    },
{ //非業鞭『ミゼラブルペイン』 (B3 Raid Equip) - JP Complete
        id: 111001,
        name: `非業鞭『ミゼラブルペイン』`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111000.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111001.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111000.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111001.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `55s CT - 4000% Non-Elemental DMG (Physical). Reduce Giant Bosses' DMG resistance by 15% for 8s.`,
                break: 400
            },
        passive: 
            {
                ability1: `Disease Time Reduction & Disease Evasion rate 10% UP.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //Violet Murk Idol - Missing Lore
        id: 111011,
        name: `Violet Murk Idol`,
        translate: `Violet Murk Idol`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111010.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111011.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111010.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111011.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 1200% Dark DMG (Magic). If user is Dark, reduce enemies' Magic resistance by 20% for 8s.`,
                break: 500
            },
        passive: 
            {
                ability1: `Status Ailment resistance 20% DOWN.`
            },
        lore: `Missing Lore.`
    },
    { //Golden Shrine Idol - Missing Lore
        id: 111021,
        name: `Golden Shrine Idol`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111020.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111021.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111020.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111021.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 1200% Light DMG (Magic). If user is Light, reduce enemies' Magic resistance by 20% for 8s.`,
                break: 500
            },
        passive: 
            {
                ability1: `Status Ailment resistance 20% DOWN.`
            },
        lore: `Missing Lore.`
    },
{ //絶魔甲『ファントムベイン』 (Elite Ragsherum Mines Equip) - JP Complete
        id: 111031,
        name: `絶魔甲『ファントムベイン』`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111030.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111031.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111030.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111031.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 2800% Dark DMG (Physical). Consumes 3% of own MAX HP for 15s. Increase own Break Power by 80% against Light enemies by 80% for 15s.`,
                break: 2800
            },
        passive: 
            {
                ability1: `Curse Time Reduction & Curse Evasion rate 10% UP.`
            },
        lore: `Currently unreleased in Global.`
    },
{ //True 'Yamata' - Missing Lore
        id: 111041,
        name: `True 'Yamata'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111040.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111041.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111040.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111041.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 8000% Water DMG (Physical). Reduce enemies' Physical resistance by 30% for 10s. If user is Water, increase own Arts gauge by 20.`,
                break: 500
            },
        passive: 
            {
                ability1: `When equipped by Long, increase own DMG by 40% (Only once).`,
                ability2: `Physical resistance 5% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Coldsteel Claws 'Yamata' - Missing Lore
        id: 111051,
        name: `Coldsteel Claws 'Yamata'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111050.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111051.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111050.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111051.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 9000% Water DMG (Physical). Increase own Water DMG by 50% for 10s.`,
                break: 900
            },
        passive: 
            {
                ability1: `Increase own Accuracy by 20% when HP is 90% or above.`
            },
        lore: `Missing Lore.`
    },
{ //Cerulean Platemail 'Wrathscale' - Missing Lore
        id: 111061,
        name: `Cerulean Platemail 'Wrathscale'`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111060.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111061.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111060.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111061.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `40s CT - Increase all allies' Water resistance and Physical resistance by 20% for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Disease Time Reduction & Disease Evasion rate 10% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Glacial Flail 'Dawn Devastator' Replica  - Missing Lore
        id: 111071,
        name: `Glacial Flail 'Dawn Devastator' Replica`,
        type: `/db/Mines/thumbnail/physLB6.png`,
        location: `Water Summoner's Road`,
        star: 6,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111070.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111071.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111070.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111071.png`
            },
        stats:
            {
                hp: 0,
                atk: 600,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 9900% Water DMG (Physical). Reduce enemies’ Water resistance by 40% for 7s. If user is Water, reduce enemies’ Water resistance by 70% instead.`,
                break: 800
            },
        passive: 
            {
                ability1: `DEF 5% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Glacial Flail "Dawn Devastator" Custom - Missing Lore
        id: 111081,
        name: `Glacial Flail "Dawn Devastator" Custom`,
        type: `/db/Mines/thumbnail/physLB6.png`,
        location: `Water Summoner's Road`,
        star: 6,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111080.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111081.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111080.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111081.png`
            },
        stats:
            {
                hp: 0,
                atk: 600,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 12000% Water DMG (Physical). Reduce enemies’ Water resistance by 40% for 15s. If user is Water, reduce enemies’ Water resistance by 60% instead.`,
                break: 800
            },
        passive: 
            {
                ability1: `Increase all allies’ Skill CT speed by 10%.`
            },
        lore: `Missing Lore.`
    },
{ //Miranda's Adolescence - Missing Lore
        id: 111091,
        name: `Miranda's Adolescence`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111090.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111091.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111090.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111091.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 5000% Light DMG (Physical). Reduce enemies' Poison resistance by 30% for 10s. If user is Light, increase own DMG by 30% for 10s.`,
                break: 500
            },
        passive: 
            {
                ability1: `ATK 10% UP when HP is 90% or above.`
            },
        lore: `Missing Lore.`
    },
{ //Miranda's Fantabulous Cloak - Missing Lore
        id: 111101,
        name: `Miranda's Fantabulous Cloak`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111100.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111101.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111100.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111101.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 4000% Light DMG (Magic). Reduce enemies' Light resistance by 30% for 10s. If own HP is 80% or above, increase own Arts gauge by 20.`,
                break: 600
            },
        passive: 
            {
                ability1: `DEF 5% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Priscilla's Cupid Brand - Missing Lore
        id: 111111,
        name: `Priscilla's Cupid Brand`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111110.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111111.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111110.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111111.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 6000% Fire DMG (Physical). Increase own Break Power by 40% for 10s. If user is Fire, increase own Physical DMG by 30% for 10s.`,
                break: 800
            },
        passive: 
            {
                ability1: `ATK 5% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Priscilla's Dreamy Apron - Missing Lore
        id: 111121,
        name: `Priscilla's Dreamy Apron`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111120.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111121.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111120.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111121.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `40s CT - Increase target's Arts gauge by 5 and ATK by 30% for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increase own DEF by 5%.`
            },
        lore: `Missing Lore.`
    },
{ //Twinflame Helm 'Garmroar' Replica - Missing Lore
        id: 111131,
        name: `Twinflame Helm 'Garmroar' Replica`,
        type: `/db/Mines/thumbnail/defLB6.png`,
        star: 6,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111130.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111131.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111130.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111131.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 600
            },
        skillset:  
            {
                skill: `30s CT - Increase all allies' DMG resistance by 10% and Fire resistance by 30% for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Heal own HP by 2/s.`
            },
        lore: `Missing Lore.`
    },
{ //Twinflame Helm 'Garmroar' Custom - Missing Lore
        id: 111141,
        name: `Twinflame Helm 'Garmroar' Custom`,
        type: `/db/Mines/thumbnail/defLB6.png`,
        star: 6,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111140.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111141.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111140.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111141.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 600
            },
        skillset:  
            {
                skill: `30s CT - Increase all allies' DMG resistance by 15% and Fire resistance by 30% for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Heal Amount 5% UP.`
            },
        lore: `Currently unreleased in Global.`
    },
{ //Iceshard Shield 'Aeruscale' Replica - Missing Lore
        id: 111151,
        name: `Iceshard Shield 'Aeruscale' Replica`,
        type: `/db/Mines/thumbnail/defLB6.png`,
        star: 6,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111150.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111151.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111150.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111151.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 600
            },
        skillset:  
            {
                skill: `30s CT - Increase all allies' DMG resistance by 10% and Water resistance by 30% for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Heal own HP by 2/s.`
            },
        lore: `Missing Lore.`
    },
{ //Iceshard Shield 'Aeruscale' Custom - Missing Lore
        id: 111161,
        name: `Iceshard Shield 'Aeruscale' Custom`,
        type: `/db/Mines/thumbnail/defLB6.png`,
        star: 6,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111160.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111161.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111160.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111161.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 600
            },
        skillset:  
            {
                skill: `30s CT - Increase all allies' DMG resistance by 15% and Water resistance by 30% for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Heal Amount 5% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Verdant Bracers 'Elpdaron' Replica - Missing Lore
        id: 111171,
        name: `Verdant Bracers 'Elpdaron' Replica`,
        type: `/db/Mines/thumbnail/defLB6.png`,
        star: 6,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111170.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111171.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111170.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111171.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 600
            },
        skillset:  
            {
                skill: `30s CT - Increase all allies' DMG resistance by 10% and Earth resistance by 30% for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Heal own HP by 2/s.`
            },
        lore: `Missing Lore.`
    },
    { //Verdant Bracers 'Elpdaron' Custom - Missing Lore
        id: 111181,
        name: `Verdant Bracers 'Elpdaron' Custom`,
        type: `/db/Mines/thumbnail/defLB6.png`,
        star: 6,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111180.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111181.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111180.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111181.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 600
            },
        skillset:  
            {
                skill: `30s CT - Increase all allies' DMG resistance by 15% and Earth resistance by 30% for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Heal Amount 5% UP.`
            },
        lore: `Missing Lore.`
    },
    { //True 'Braise de Bamint' - Missing Lore
        id: 111191,
        name: `True 'Braise de Bamint'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111190.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111191.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111190.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111191.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 8000% Fire DMG (Physical). Reduce enemies' Fire resistance by 40% for 12s. If User is Fire, increase own Accuracy rate by 30% for 12s.`,
                break: 800
            },
        passive: 
            {
                ability1: `When equipped by Noble Flare Mira, increase own Skill CT by 10% & increase own Arts gauge at the Start of Quest by 50 (Only once).`,
                ability2: `Increase DMG to Enemies in BREAK by 20%.`
            },
        lore: `Missing Lore.`
    },
    { //Searing Blade 'Braise de Bamint' - Missing Lore
        id: 111201,
        name: `Searing Blade 'Braise de Bamint'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111200.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111201.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111200.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111201.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 7000% Fire DMG (Physical). Increase own Physical DMG by 30% for 15s. If user is Fire, increase own Arts gauge by 1 for 15s.`,
                break: 800
            },
        passive: 
            {
                ability1: `DMG 10% UP against enemies in Break.`
            },
        lore: `Missing Lore.`
    },
    { //Petal Cloth 'Mirage de Bamint' - Missing Lore
        id: 111211,
        name: `Petal Cloth 'Mirage de Bamint'`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111210.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111211.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111210.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111211.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 4000% Fire DMG (Magic). Increase own Skill CT Speed and DMG against Giant Bosses by 30% for 10s.`,
                break: 600
            },
        passive: 
            {
                ability1: `Magic resistance 5% UP.`
            },
        lore: `Missing Lore.`
    },
    { //True 'Happiness Trigger' - Missing Lore
        id: 111231,
        name: `True 'Happiness Trigger'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111230.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111231.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111230.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111231.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `45s CT - Increase all Allies' Arts gauge by 3/s and Water Allies' Break Power by 40% for 12s.`,
                break: 0
            },
        passive: 
            {
                ability1: `When equipped by Aristela Orbis, increase own Arts by 3/s and DMG by 50% (Only once).`,
                ability2: `Magic DMG 10% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Paradise Needle 'Happiness Trigger' - Missing Lore
        id: 111241,
        name: `Paradise Needle 'Happiness Trigger'`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111240.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111241.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111240.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111241.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `45s CT - Increase target's Arts gauge by 5/s for 20s. If user is Water, increase target's Arts DMG by 20% for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Magic resistance 5% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Wrenchcoat 'Mind Cracker' - Missing Lore
        id: 111251,
        name: `Wrenchcoat 'Mind Cracker'`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111250.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111251.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111250.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111251.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `65s CT - 5000% Water DMG (Magic). Reduce enemies' Magic resistance by 20% for 10s. If user is Water, reduce enemies' Magic resistance by 30% instead.`,
                break: 500
            },
        passive: 
            {
                ability1: `DMG 10% UP when enemy is Broken.`
            },
        lore: `Missing Lore.`
    },
{ //Gleaming Crux 'Fosbrace' Replica - Missing Lore
        id: 111261,
        name: `Gleaming Crux 'Fosbrace' Replica`,
        type: `/db/Mines/thumbnail/healLB6.png`,
        star: 6,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111260.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111261.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111260.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111261.png`
            },
        stats:
            {
                hp: 600,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - Heal target's HP by 5% for 20s. If the user is a Human or Beast, heal target's HP by 10% for 20s instead.`,
                break: 0
            },
        passive: 
            {
                ability1: `Seal Time Reduction & Seal Evasion rate 10% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Gleaming Crux 'Fosbrace' Custom - Missing Lore
        id: 111271,
        name: `Gleaming Crux 'Fosbrace' Custom`,
        type: `/db/Mines/thumbnail/healLB6.png`,
        star: 6,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111270.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111271.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111270.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111271.png`
            },
        stats:
            {
                hp: 600,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - Heal target's HP by 5% for 20s. If the user is a Human or Beast, heal target's HP by 7% and reduce target's DMG taken by 20% for 20s instead.`,
                break: 0
            },
        passive: 
            {
                ability1: `BE Output 5% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Hades Tome 'Plutorix' Replica - Missing Lore
        id: 111281,
        name: `Hades Tome 'Plutorix' Replica`,
        type: `/db/Mines/thumbnail/suppLB6.png`,
        star: 6,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111280.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111281.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111280.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111281.png`
            },
        stats:
            {
                hp: 300,
                atk: 150,
                def: 150
            },
        skillset:  
            {
                skill: `40s CT - Increase all allies' Arts gauge by 2 for 15s. If the user is a Demon or Spirit, increase all allies' Arts gauge by 3 for 15s instead.`,
                break: 0
            },
        passive: 
            {
                ability1: `DEF 5% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Hades Tome 'Plutorix' Custom - Missing Lore
        id: 111291,
        name: `Hades Tome 'Plutorix' Custom`,
        type: `/db/Mines/thumbnail/suppLB6.png`,
        star: 6,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111290.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111291.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111290.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111291.png`
            },
        stats:
            {
                hp: 300,
                atk: 150,
                def: 150
            },
        skillset:  
            {
                skill: `40s CT - Increase all allies' Arts gauge (except for self) by 2 for 16s. If the user is a Demon or Spirit, increase all allies' Arts gauge (except for self) by 4 for 16s instead.`,
                break: 0
            },
        passive: 
            {
                ability1: `ATK 5% UP.`
            },
        lore: `Missing Lore.`
    },
    { //True 'Kubou' - Missing Lore
        id: 111301,
        name: `True 'Kubou'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111300.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111301.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111300.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111301.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `30s CT - Increase Allies' Arts by 20. Increase Fire Allies' ATK and DMG by 30% for 8s.`,
                break: 0
            },
        passive: 
            {
                ability1: `When equipped by Kokuri, increase own Arts by 3/s and Equip CT REC by 20%. Does not stack with Abilities of the same name (Only once).`,
                ability2: `Magic resistance 5% UP.`
            },
        lore: `Missing Lore.`
    },
    { //Conflagrant Pearls 'Kubou' - Missing Lore
        id: 111311,
        name: `Conflagrant Pearls 'Kubou'`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111310.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111311.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111310.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111311.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `50s CT - Increase all allies' DMG by 35% for 12s. If user is Fire, increase all allies' DMG by 60% instead.`,
                break: 0
            },
        passive: 
            {
                ability1: `Curse Time Reduction & Curse Evasion rate 10% UP.`
            },
        lore: `Missing Lore.`
    },
    { //Magical Garb 'Ruban le Lien' - Missing Lore
        id: 111321,
        name: `Magical Garb 'Ruban le Lien'`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111320.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111321.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111320.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111321.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 6000% Fire DMG (Magic). Increase own Magic DMG by 35% and reduce enemies' Fire resistance by 25% for 10s.`,
                break: 800
            },
        passive: 
            {
                ability1: `Magic DMG 10% UP.`
            },
        lore: `Missing Lore.`
    },
    { //烈脚装『テラウィンド』(Riviera Farmable) - JP Complete
        id: 111331,
        name: `烈脚装『テラウィンド』`,
        translate: `Striking Pants 'Terra Wind'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111330.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111331.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111330.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111331.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 2500% Earth DMG (Physical). For 10s, increase own Skill CT Speed by 20%. If user is Demon, increase own Skill CT Speed by 40% instead.`,
                break: 300
            },
        passive: 
            {
                ability1: `Increase Physical resistance by 5%.`
            },
        lore: `Currently unreleased in Global.`
    },
{ //翠鳴杖『エルプマギカ』(Elpenumas Raid) - JP Complete
        id: 111341,
        name: `翠鳴杖『エルプマギカ』`,
        translate: `Jade Roar Staff 'Elpmagica'`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111340.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111341.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111340.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111341.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 3500% Earth DMG (Magic). For 10s, increase own ATK by 30%. If User is Earth, increase own Arts Gauge by 1/s.`,
                break: 400
            },
        passive: 
            {
                ability1: `Reduce own Max HP by 10%.`
            },
        lore: `Currently unreleased in Global.`
    },
{ //極『エルプマギカ』(Elpneumas Raid EV) - JP Complete
        id: 111351,
        name: `極『エルプマギカ』`,
        translate: `Jade Roar Staff 'Elpmagica' EV`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111350.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111351.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111350.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111351.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
            skillset:  
            {
                skill: `50s CT - 3500% Earth DMG (Magic). For 10s, increase own ATK by 30%. If User is Earth, increase own Arts Gauge by 1/s.`,
                break: 400
            },
        passive: 
            {
                ability1: `Reduce own Max HP by 10%.`,
                ability2: [`Water resistance 5% UP.`, `Auto-Heal own HP by 4/s.`, `HP Recovery Amount 5% UP.`, `Max HP 5% UP.`, `ATK 10% UP when above 90% HP.`, `Skill CT REC 10% UP when above 90% HP.`]
            },
        lore: `Currently unreleased in Global.`
    },
{ //True 'Maken Zanketsu' - Missing Lore
        id: 111361,
        name: `True 'Maken Zanketsu'`,
        translate: `True 'Demon Sword ZANKETSU'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111360.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111361.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111360.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111361.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `35s CT - 13000% Dark DMG (Physical). For 8s, reduce enemies' Physical resistance and CRI resistance by 30%.`,
                break: 2000
            },
        passive: 
            {
                ability1: `When equipped by Demon Lord Vox, increase own Skill CT Speed and Equip CT Speed by 15% (Only once).`,
                ability2: `ATK 5% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Maken Zanketsu - Missing Lore
        id: 111371,
        name: `Maken Zanketsu`,
        translate: `Demon Sword ZANKETSU`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111370.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111371.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111370.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111371.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 8000% Dark DMG (Physical). Consume 20% of own MAX HP. For 10s, reduce enemies' Dark resistance and Physical resistance by 20%.`,
                break: 400
            },
        passive: 
            {
                ability1: `Skill DMG 10% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Netherlord Armor 'Beelzedia' - Missing Lore
        id: 111381,
        name: `Netherlord Armor 'Beelzedia'`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111380.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111381.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111380.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111381.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 6000% Dark DMG (Physical). For 8s, increase own Dark DMG by 50%. When used for the first time, increase own Arts gauge by 50.`,
                break: 400
            },
        passive: 
            {
                ability1: `ATK 10% UP when above 90% HP.`
            },
        lore: `Missing Lore.`
    },
    { //True 'Neon Bayonet' - Missing Lore
        id: 111391,
        name: `True 'Neon Bayonet'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111390.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111391.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111390.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111391.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 7500% Water DMG (Physical). Increase own Water DMG by 50% and reduce enemies' Physical resistance by 30% for 10s.`,
                break: 500
            },
        passive: 
            {
                ability1: `When equipped by Jay, increase own DMG and Break Power by 30% (Only once).`,
                ability2: `Increase Physical resistance by 5%.`
            },
        lore: `Missing Lore.`
    },
    { //Neon Bayonet - Missing Lore
        id: 111401,
        name: `Neon Bayonet`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111400.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111401.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111400.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111401.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 6500% Water DMG (Physical). Increase own DMG by 40% & Accuracy by 20% for 8s.`,
                break: 800
            },
        passive: 
            {
                ability1: `Increase ATK by 10% when above 90% HP.`
            },
        lore: `Missing Lore.`
    },
    { //Blue Jacket - Missing Lore
        id: 111411,
        name: `Blue Jacket`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111410.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111411.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111410.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111411.png`
            },
        stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
        skillset:  
            {
                skill: `40s CT - Increase target's Arts gauge by 3/s for 10s. If user is Water, increase target's DMG resistance by 10% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Paralysis Time reduction & Paralysis Evasion rate 10% UP.`
            },
        lore: `Missing Lore.`
    },
    { //Sword God's Proof - Missing Lore
        id: 111421,
        name: `Sword God's Proof`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111420.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111421.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111420.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111421.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `50s CT - Increase all allies' Arts gauge by 3 for 10s. If user is Fire, increase own Skill CT Speed by 120% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Frozen Empress' Proof - Missing Lore
        id: 111431,
        name: `Frozen Empress' Proof`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111430.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111431.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111430.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111431.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `30s CT - Reduce target's DMG taken by 33% and Water DMG taken by 66% for 8s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Sword Emperor's Proof - Missing Lore
        id: 111441,
        name: `Sword Emperor's Proof`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111440.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111441.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111440.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111441.png`
            },
        stats:
            {
                hp: 500,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `30s CT - Heal all allies' HP by 15% and cleanses all allies' Blind and Curse.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //剣聖の証【翠】(Stiria Mines Equip) - JP Complete
        id: 111451,
        name: `氷精靴『フロストヴァイト』`,
        translate: `Ice Boots 'Frostbite'`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111450.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111451.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111450.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111451.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 120
            },
        skillset:  
            {
                skill: `30s CT - Increase all allies' Fire resistance by 30% for 15s. Freeze ownself for 7s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increase MAX HP by 5%.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //Luminous Tiara - Missing Lore
        id: 111461,
        name: `Luminous Tiara`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111460.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111461.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111460.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111461.png`
            },
        stats:
            {
                hp: 120,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - Heal all Allies' HP by 15%. If user is God, Heal all Allies' HP by 30% instead.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Ravenblack Headpiece - Missing Lore
        id: 111471,
        name: `Ravenblack Headpiece`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111470.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111471.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111470.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111471.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 120
            },
        skillset:  
            {
                skill: `30s CT - Increase Light & Dark Allies' DMG resistance by 20% for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //ノーブルアイ - JP Complete
        id: 111481,
        name: `ノーブルアイ`,
        translate: `Noble Eye`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111480.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111481.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111480.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111481.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 4000% Light DMG (Magic). Reduce enemies' Light resistance by 20% for 10s. If user is Light, reduce enemies' Evasion rate by 20%.`,
                break: 500
            },
        passive: 
            {
                ability1: `DEF 5% UP.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //True 'Millenium Ark' - Missing Lore
        id: 111491,
        name: `True 'Millenium Ark'`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111490.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111491.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111490.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111491.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 7000% Light DMG (Magic). Increase own Magic DMG & DMG to Giant Bosses by 35% for 10s.`,
                break: 500
            },
        passive: 
            {
                ability1: `When equipped by Luana, Increase own Arts gauge by 4/s. Does not stack with Abilities of the same name.`,
                ability2: `Skill DMG 20% UP.`
            },
        lore: `Missing Lore.`
    },
    { //Aged Crystal 'Millenium Arc' - Missing Lore
        id: 111501,
        name: `Aged Crystal 'Millenium Arc'`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111500.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111501.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111500.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111501.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 7000% Light DMG (Magic). Increase own Light DMG by 50% for 10s.`,
                break: 500
            },
        passive: 
            {
                ability1: `Increase own Accuracy by 20% when above 90% HP.`
            },
        lore: `Missing Lore.`
    },
    { //Ribnald Beret - Missing Lore
        id: 111511,
        name: `Ribnald Beret`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111510.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111511.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111510.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111511.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `55s CT - 4000% Light DMG (Magic). Reduce enemy's Light resistance by 30% for 10s. When used for the first time, increase own Arts gauge by 50.`,
                break: 400
            },
        passive: 
            {
                ability1: `Magic resistance 5% UP.`
            },
        lore: `Missing Lore.`
    },
    { //Spirit Steps 'Elements Row' - Missing Lore
        id: 111521,
        name: `Spirit Steps 'Elements Row'`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111520.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111521.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111520.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111521.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 120
            },
        skillset:  
            {
                skill: `35s CT - Blind ownself and increase all allies' Light resistance and Magic resistance by 20% for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Heal own HP by 2/s.`
            },
        lore: `Missing Lore.`
    },
    { //クレイヴ・ライトニング (Wilkes Raid equip) - JP Complete
        id: 111531,
        name: `クレイヴ・ライトニング`,
        translate: `Craver Lightning`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111530.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111531.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111530.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111531.png`
            },
        stats:
            {
                hp: 500,
                atk: 500,
                def: 500
            },
        skillset:  
            {
                skill: `40s CT - 5000% Light DMG (Physical). Increase own Physical DMG by 50% for 12s. When used for the first time, increase own Arts gauge by 40.`,
                break: 500
            },
        passive: 
            {
                ability1: `ATK 5% UP.`
            },
        lore: `Currently unreleased in Global.`
    },
{ //Mira’s Essential Purifier - Missing Lore
        id: 111541,
        name: `Mira’s Essential Purifier`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111540.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111541.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111540.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111541.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 10000% Fire DMG (Magic). Reduce enemies' DMG resistance by 20% for 10s,. If user's HP is 80% or higher, reduce enemies' DMG resistance by 40% instead.`,
                break: 1000
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
{ //Kane’s Independence Day - Missing Lore
        id: 111551,
        name: `Kane’s Independence Day`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111550.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111551.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111550.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111551.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 6000% Dark DMG (Physical). Increase own DMG of next Super Arts by 100%. If user is Human, increase own DMG of next Super Arts by 150% instead.`,
                break: 700
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
{ //Serah’s Outdoor Attendant - Missing Lore
        id: 111561,
        name: `Serah’s Outdoor Attendant`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111560.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111561.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111560.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111561.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `35s CT - Increase all Allies' Arts gauge by 30. Increase own Magic DMG by 25% for 60s (Stackable).`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
{ //Marika’s Mari-Style Mariner - Missing Lore
        id: 111571,
        name: `Marika’s Mari-Style Mariner`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111570.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111571.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111570.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111571.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 2000% Fire DMG (Magic). Increase own Break Power by 50% and Fire Allies' Break Power by 30% for 15s.`,
                break: 1500
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
{ //Lotte’s Friendship Frills - Missing Lore
        id: 111581,
        name: `Lotte’s Friendship Frills`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111580.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111581.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111580.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111581.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 6500% Fire DMG (Magic). Increase own Arts DMG by 50% for 8s. If the user is Fire, Increase own Arts gauge by 40.`,
                break: 500
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
{ //Valerie’s Friendship Frills - Missing Lore
        id: 111591,
        name: `Valerie’s Friendship Frills`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111590.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111591.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111590.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111591.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 6500% Water DMG (Physical). Increase own Arts DMG by 50% for 8s. If the user is Water, Increase own Arts gauge by 40.`,
                break: 500
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Iris' Dream Rocket - Missing Lore
        id: 111601,
        name: `Iris' Dream Rocket`,
        location: `Summer Treasure (7th Summer Event)`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111600.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111601.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111600.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111601.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 5000% Fire DMG (Magic). For 10s, increase own Fire DMG by 40%. When used for the first time, increase own Arts gauge by 50.`,
                break: `500`
            },
        passive: 
            {
                ability1: `Magic resistance 5% UP.`
            },
        lore: `Missing Lore.`
    },
    { //Fen's Wavecutter - Missing Lore
        id: 111611,
        name: `Fen's Wavecutter`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111610.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111611.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111610.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111611.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `35s CT - 4000% Water DMG (Physical). If user's HP is 80% or higher, increase own Physical DMG by 35% for 8s. If user's HP is below 80%, recover 6% of own HP.`,
                break: 500
            },
        passive: 
            {
                ability1: `Increase own HP Recovery Amount by 5%.`
            },
        lore: `Missing Lore.`
    },
    { //Celestial Vessel 'Zexraster' Replica - Missing Lore
        id: 111621,
        name: `Celestial Vessel 'Zexraster' Replica`,
        type: `/db/Mines/thumbnail/healLB6.png`,
        star: 6,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111620.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111621.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111620.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111621.png`
            },
        stats:
            {
                hp: 600,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - Heal target's HP by 5%/s for 20s. If user is God or Giant, heal target's HP by 10%/s instead.`,
                break: 0
            },
        passive: 
            {
                ability1: `Seal Time Reduction & Seal Evasion rate 10% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Celestial Vessel 'Zexraster' Custom - Missing Lore
        id: 111631,
        name: `Celestial Vessel 'Zexraster' Custom`,
        type: `/db/Mines/thumbnail/healLB6.png`,
        star: 6,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111630.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111631.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111630.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111631.png`
            },
        stats:
            {
                hp: 600,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - Heal target's HP by 5%/s for 20s. If user is God or Giant, heal target's HP by 7%/s and increase all allies' DMG resistance by 20% instead.`,
                break: 0
            },
        passive: 
            {
                ability1: `BE Output 5% UP.`
            },
        lore: `Missing Lore.`
    },
    { //Kilmon Sword - Missing Lore
        id: 111641,
        name: `Kilmon Sword`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111640.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111641.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111640.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111641.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 8000% Fire DMG (Physical). Increase own Arts DMG by 30% for 8s. If user is Fire, increase own Arts DMG by 50% instead.`,
                break: 800
            },
        passive: 
            {
                ability1: `Tasmon's Courage: When allies have all 3 Tasmon Equipment equipped, increase user's CRI DMG by 70%. (Only Once)`
            },
        lore: `Missing Lore.`
    },
    { //Defmon Shield - Missing Lore
        id: 111651,
        name: `Defmon Shield`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111650.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111651.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111650.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111651.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `35s CT - Increase all allies' Physical resistance by 20% for 15s. If user is Water, increase all allies' Physical resistance by 30% instead.`,
                break: 0
            },
        passive: 
            {
                ability1: `Tasmon's Bond: When allies have all 3 Tasmon Equipment equipped, increase user's CRI resistance by 40%. (Only Once)`
            },
        lore: `Missing Lore.`
    },
    { //Tufmon Rod - Missing Lore
        id: 111661,
        name: `Tufmon Rod`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111660.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111661.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111660.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111661.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 5000% Earth DMG (Magic). Reduce enemy's Magic resistance by 20% for 8s. If user is Earth, reduce enemies' Magic resistance by 40% instead.`,
                break: 500
            },
        passive: 
            {
                ability1: `Tasmon's Wisdom: When allies have all 3 Tasmon Equipment equipped, increase user's Arts by 2/s. (Only Once)`
            },
        lore: `Missing Lore.`
    },
    { //絶凍靴『フロストペイン』 - JP Complete
        id: 111671,
        name: `絶凍靴『フロストペイン』`,
        translate: `Frost Boots 'Frost Pain'`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111670.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111671.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111670.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111671.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `30s CT - For 120s, reduce own DMG resistance by 15% (Stackable). For 20s, increase all allies' Water resistance by 30% & Freeze resistance by 100%.`,
                break: 0
            },
        passive: 
            {
                ability1: `Max HP 10% Up.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //リヴィエラのヨーソローマント - JP Complete
        id: 111681,
        name: `リヴィエラのヨーソローマント`,
        translate: `Riviera's Yosoro Mantle`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111680.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111681.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111680.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111681.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 120
            },
        skillset:  
            {
                skill: `40s CT - Increase target's Fire resistance and Physical resistance by 20% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `DEF 5% UP.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //ロゼッタインナー(スリム用) - JP Complete
        id: 111691,
        name: `ロゼッタインナー(スリム用)`,
        translate: `Rosetta's Innerwear (Slim)`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111690.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111691.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111690.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111691.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `35s CT - Increase all allies' Arts gauge by 3/s and all Dark allies' Skill CT Speed by 50% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Currently unreleased in Global.`
    },
    { //さいきょー！ロゼッタアーム - JP Complete
        id: 111701,
        name: `さいきょー！ロゼッタアーム`,
        translate: `Maximum! Rosetta Arm`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111700.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111701.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111700.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111701.png`
            },
        stats:
            {
                hp: 500,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 1000% Non-Elemental DMG (Magic). Heal all allies' HP by 170/s and increase all Demon allies' stats by 30% for 13s.`,
                break: 200
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Currently unreleased in Global.`
    },
    { //レオーネのチャーミンググリーン - JP Complete
        id: 111711,
        name: `レオーネのチャーミンググリーン`,
        translate: `Leone's Charming Green`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111710.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111711.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111710.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111711.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `45s CT - Increase all allies' Arts gauge by 2/s and all Demon allies' DMG by 60% for 10s. Additionally, increase all allies' Arts gauge by 30 (First time only).`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Currently unreleased in Global.`
    },
    { //レオーネのエンジェルサンダル - JP Complete
        id: 111721,
        name: `レオーネのエンジェルサンダル`,
        translate: `Leone's Angel Sandals`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111720.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111721.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111720.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111721.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 3800% Earth DMG (Magic). When CRI, increase own Arts gauge by 50 (1 HIT only). Increase own Magic DMG by 60% for 10s.`,
                break: 300
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Currently unreleased in Global.`
    },
    { //リヴィエラのアンガーアンカー - JP Complete
        id: 111731,
        name: `リヴィエラのアンガーアンカー`,
        translate: `Riviera's Anger Anchor`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111730.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111731.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111730.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111731.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `80s CT - 13500% Fire DMG (Physical). Increase own ATK by 100% for 10s. If user is Demon, increase own ATK by 160% instead.`,
                break: 1000
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Currently unreleased in Global.`
    },
    { //リヴィエラ・ダイナマイト - JP Complete
        id: 111741,
        name: `リヴィエラ・ダイナマイト`,
        translate: `Riviera's Dynamite Attack`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111740.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111741.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111740.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111741.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 8500% Fire DMG (Physical). Reduce enemies' Fire resistance and Evasion rate by 30% for 10s.`,
                break: 800
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Currently unreleased in Global.`
    },
    { //GO！グレート・ガナルディア号 - JP Complete
        id: 111751,
        name: `GO！グレート・ガナルディア号`,
        translate: `GO! Great Gramenia`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111750.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111751.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111750.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111751.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 4000% Dark DMG (Magic). If enemy is a God, reduce enemies' Dark resistance by 25% for 10s.`,
                break: 500
            },
        passive: 
            {
                ability1: `MAX HP 5% UP.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //真『シスのパッションフリル』 - JP Complete
        id: 111761,
        name: `真『シスのパッションフリル』`,
        translate: `True 'Ces Sandy Blossom'`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111760.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111761.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111760.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111761.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `35s CT - Increase all allies' Arts gauge by 2/s and Fire allies' Max HP by 20% for 12s.`,
                break: 0
            },
        passive: 
            {
                ability1: `When equipped by Summer Cestina, increase own Arts gauge by 2/s and all stats by 30% (Only once).`,
                ability2: `Increase own DEF by 5%.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //真『リザ姉の背伸びバンドゥ』 - JP Complete
        id: 111771,
        name: `真『リザ姉の背伸びバンドゥ』`,
        translate: `True 'Liza's Mature Look'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111770.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111771.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111770.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111771.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 4500% Water DMG (Physical). Increase own Water DMG by 40% for 15s. If user is God, increase own Water DMG by 60% instead.`,
                break: 400
            },
        passive: 
            {
                ability1: `When equipped by Summer Liza, increase own Arts gauge by 2/s and Heal Amount by 50% (excluding Lifesteal) (Only once).`,
                ability2: `Increase own Fire resistance by 5%.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //封天鞭『スパインズ・ゴア』レプリカ - JP Complete
        id: 111781,
        name: `封天鞭『スパインズ・ゴア』レプリカ`,
        translate: `Sealed Whip 'Spines Gore' Replica`,
        type: `/db/Mines/thumbnail/physLB6.png`,
        star: 6,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111780.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111781.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111780.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111781.png`
            },
        stats:
            {
                hp: 0,
                atk: 600,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 9900% Dark DMG (Physical). Reduce enemies' Dark resistance by 40% for 7s. If user is Dark, reduce enemies' Dark resistance by 70% instead.`,
                break: 800
            },
        passive: 
            {
                ability1: `DEF 5% UP.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //封天鞭『スパインズ・ゴア』アレンジ - JP Complete
        id: 111791,
        name: `封天鞭『スパインズ・ゴア』アレンジ`,
        translate: `Sealed Whip 'Spines Gore' Custom`,
        type: `/db/Mines/thumbnail/physLB6.png`,
        star: 6,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111790.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111791.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111790.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111791.png`
            },
        stats:
            {
                hp: 0,
                atk: 600,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 12000% Dark DMG (Physical). Reduce enemies' Dark resistance by 30% for 15s. If user is Dark, reduce enemies' Dark resistance by 60% instead.`,
                break: 800
            },
        passive: 
            {
                ability1: `Increases all allies' Skill CT Speed by 10%.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //真『影斬り』- JP Complete
        id: 111801,
        name: `真『影斬り』`,
        translate: `True 'KAGEGIRI'`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111800.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111801.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111800.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111801.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `20s CT - 4000% Water DMG (Physical). Reduce enemies' Physical resistance by 30% for 10s. If user is Human, increase own Arts gauge by 10. `,
                break: 500
            },
        passive: 
            {
                ability1: `When equipped by Canaria, increase own ATK by 100% & Skill CT by 20%. Does not stack with Abilities of the same name.`,
                ability2: `Increase own Physical Evasion by 5%`
            },
        lore: `Currently unreleased in Global.`
    },
    { //影斬り - JP Complete
        id: 111811,
        name: `影斬り`,
        translate: `KAGEGIRI Shadow Slasher Slicer`,
        location: `Limited Event - Blue Jacket Part 2`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111810.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111811.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111810.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111811.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `30s CT - 3000% Water DMG (Physical). Increase own DMG agaisnt weak-element enemies by 30% for 10s. If user is Human, increase own DMG agaisnt weak-element enemies by 50% instead.`,
                break: 400
            },
        passive: 
            {
                ability1: `Increase own Skill DMG by 20%,`
            },
        lore: `Currently unreleased in Global.`
    },
    { //ブルージャケット(カナリア仕様) - JP Complete
        id: 111821,
        name: `ブルージャケット(カナリア仕様)`,
        translate: `Blue Jacket (Canaria Custom)`,
        location: `Limited Event - Blue Jacket Part 2`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111820.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111821.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111820.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111821.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `40s CT - Increase all allies' Fire resistance by 30% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increase own Hellfire resistance by 10%.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //Golcat King Crown - Missing Lore
        id: 111831,
        name: `Golcat King Crown`,
        location: `Dark Elemental Ruins F16 Stage Clear Reward`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111830.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111831.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111830.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111831.png`
            },
        stats:
            {
                hp: 500,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - Heal all allies' HP by 200/s for 10s. If user is Light, heal all allies' Poison and Burn.`,
                break: 0
            },
        passive: 
            {
                ability1: `When a Tasmon equip (Fire/Water/Earth Ruins F16 equip) and Metal Chicken Tiara (Light Ruins F16 equip) is equipped on the party, increase own Heal Amount by 60% (No duplication).`
            },
        lore: `Golcat King Crown.`
    },
    { //Metal Chicken Tiara - Missing Lore
        id: 111841,
        name: `Metal Chicken Tiara`,
        location: `Light Elemental Ruins F16 Stage Clear Reward`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111840.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111841.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111840.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111841.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `40s CT - For Increase all allies' Arts gauge by 2/s for 10s. If user is Dark, increase all allies' Arts gauge by 3/s instead.`,
                break: 0
            },
        passive: 
            {
                ability1: `When a Tasmon equip (Fire/Water/Earth Ruins F16 equip) and Golcat King Crown (Dark Ruins F16 equip) is equipped on the party, increase own CRI rate by 100% (No duplication).`
            },
        lore: `Currently unreleased in Global.`
    },
    { //現忍足『守弐韋袈』 - JP Complete
        id: 111851,
        name: `現忍足『守弐韋袈』`,
        translate: `Current Ninja Footwear 'Moriniike'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111850.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111851.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111850.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111851.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 4000% Water DMG (Physical). For 10s, if enemy is a Demon, reduce enemies' Water resistance by 25%.`,
                break: 500
            },
        passive: 
            {
                ability1: `Increase Max HP by 5%.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //ツヴァイトシーザ - JP Complete
        id: 111861,
        name: `ツヴァイトシーザ`,
        translate: `Zweit Ceaser`,
        location: `Cyber-Z Raid Boss Drop`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111860.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111861.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111860.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111861.png`
            },
        stats:
            {
                hp: 500,
                atk: 500,
                def: 500
            },
        skillset:  
            {
                skill: `55s CT - 5000% Water DMG (Physical). For 10s, increase own Water DMG by 40%.`,
                break: 1000
            },
        passive: 
            {
                ability1: `When own HP is 90% or above, increase own Break Power by 20%.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //True 'Shangri-La' - Missing Lore
        id: 111871,
        name: `True 'Shangri-La'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111870.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111871.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111870.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111871.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 12000% Light DMG (Physical). If user is Light, reduce enemies' DMG resistance by 20% for 10s. If user is Light and above 80% HP, reduce enemies' DMG resistance by 35% instead.`,
                break: 800
            },
        passive: 
            {
                ability1: `When equipped by Goddess Celia, increase own DMG and Skill CT Speed by 30%. Does not stack with Abilities of the same name.`,
                ability2: `Increase own CRI DMG by 20%.`
            },
        lore: `Missing Lore.`
    },
    { //Skybound Sword 'Shangri-La' - Missing Lore
        id: 111881,
        name: `Skybound Sword 'Shangri-La'`,
        location: `Chapter 25 Secret Weapon`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111880.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111881.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111880.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111881.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 12000% Light DMG (Physical). If user is Human, increase own DMG & Accuracy by 80% for 13s.`,
                break: 800
            },
        passive: 
            {
                ability1: `When own HP is 80% or less, reduce own Arts by 1/s.`
            },
        lore: `Missing Lore.`
    },
    { //Jeweled Finery 'Emerodite' - Missing Lore
        id: 111891,
        name: `Jeweled Finery 'Emerodite'`,
        location: `Chapter 25 Farmable Equip`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111890.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111891.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111890.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111891.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `40s CT - Increase all allies' DMG resistance by 25% for 10s. Additionally if user is Human, increase allies' Freeze resistance and Paralysis resistance by 100% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increase own ATK by 5%.`
            },
        lore: `Missing Lore.`
    },
    { //Lotte's Fluffy Mitts - Missing Lore
        id: 111901,
        name: `Lotte's Fluffy Mitts`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111900.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111901.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111900.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111901.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 5000% Fire DMG (Physical). If the Enemy is already Bleeding, increase its stack of Bleed by 1 (up to 3). Reduce enemies' Physical resistance by 30% for 8s.`,
                break: 200
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Lotte's Clawed Paws - Missing Lore
        id: 111911,
        name: `Lotte's Clawed Paws`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111910.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111911.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111910.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111911.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `75s CT - 16000% Fire DMG (Physical). Increase own ATK by 80% for 10s. If user is Fire, increase own ATK by 130% instead.`,
                break: 800
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Valerie's Lizalee Staff - Missing Lore
        id: 111921,
        name: `Valerie's Lizalee Staff`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111920.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111921.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111920.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111921.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `80s CT - 8000% Water DMG (Magic). Increase own Magic DMG by 60% (stackable) and Human allies' Magic DMG by 40% (stackable) for 15s.`,
                break: 800
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Trick the Trickster - Missing Lore
        id: 111931,
        name: `Trick the Trickster`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111930.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111931.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111930.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111931.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `30s CT - Increase all allies' Arts by 3/s and Magic DMG by [15 x # of Unique Races in the Party]% (MAX: 60%) for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Forte's Dolly Dress - Missing Lore
        id: 111941,
        name: `Forte's Dolly Dress`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111940.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111941.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111940.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111941.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `30s CT - Heal all allies' HP by 200/s for 10s. Increase all allies' Light resistance by 30% for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Forte's Funny Hat - Missing Lore
        id: 111951,
        name: `Forte's Funny Hat`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111950.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111951.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111950.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111951.png`
            },
        stats:
            {
                hp: 120,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `30s CT - Heal all allies' HP by 700. Additionally if user is Dark, cleanses all allies' Poison.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increase own Poison resistance by 10%.`
            },
        lore: `Missing Lore.`
    },
    { //極『カボシェルムのぬいぐるみ』(Jack-o-Sherum Plushie EV) - JP Complete
        id: 111961,
        name: `極『カボシェルムのぬいぐるみ』`,
        translate: `Jack-o-Sherum Plushie EV`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: ``,
                detailmax: `/db/Equipment/Detail/item_detail_111961.png`,
                thumb: ``,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111961.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `80s CT - 6666% Dark DMG (Physical). Increases ATK by 50% & Demons' ATK by 30% for 15s.`,
                break: 1000
            },
        passive: 
            {
                ability1: `DMG to Gods 20% UP.`,
                ability2: [`DMG against Light enemies 10% UP.`, `DMG against Dark enemies 10% UP.`, `Light resistance 5% UP.`, `Dark resistance 5% UP.`, `At the Start of Quest, increase own Arts gauge by 10. Does not stack with Abilities of the same name.`, `At the Start of Quest, increase own Arts gauge by 20. Does not stack with Abilities of the same name.`]
            },
        lore: `Currently unreleased in Global.`
    },
    { //True 'Rune Sovereign' - Missing Lore
        id: 111971,
        name: `True 'Rune Sovereign'`,
        type: `/db/Mines/thumbnail/magLB6.png`,
        star: 6,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111970.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111971.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111970.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111971.png`
            },
        stats:
            {
                hp: 0,
                atk: 600,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 10000% Light DMG (Magic). Increase own Magic DMG By 50% and reduce enemies' Magic resistance by 30% (stackable) for 20s.`,
                break: 500
            },
        passive: 
            {
                ability1: `When equipped by Isliid, Human Pinnacle, increase own DMG by 80%. Does not stack with Abilities of the same name.`,
                ability2: `Increase own Magic resistance by 5%.`
            },
        lore: `Missing Lore.`
    },
    { //True 'Komplettes Trio' - Missing Lore
        id: 111981,
        name: `True 'Komplettes Trio'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111980.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111981.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111980.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111981.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 8000% Light DMG (Physical). Increase own Light DMG by 40% (stackable) for 30s.`,
                break: 500
            },
        passive: 
            {
                ability1: `When equipped by Isliid, Human Pinnacle, increase own ATK by 50%. Does not stack with Abilities of the same name.`,
                ability2: `Increase own Physical resistance by 5%.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //Komplettes Trio Replica - Missing Lore
        id: 111991,
        name: `フォルコメンドライ・レプリカ`,
        translate: `Komplettes Trio Replica`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_111990.png`,
                detailmax: `/db/Equipment/Detail/item_detail_111991.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_111990.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_111991.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 5000% Light DMG (Physical). Increase own Arts by 15. If user is Light, increase own Stats by 15% for 10s.`,
                break: 300
            },
        passive: 
            {
                ability1: `Increase own BE Output by 10%.`
            },
        lore: `Missing Lore.`
    },
    { //Rune Sovereign Replica - Missing Lore
        id: 112001,
        name: `Rune Sovereign Replica`,
        type: `/db/Mines/thumbnail/magLB6.png`,
        star: 6,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112000.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112001.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112000.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112001.png`
            },
        stats:
            {
                hp: 0,
                atk: 600,
                def: 0
            },
        skillset:  
            {
                skill: `80s CT - 15000% Light DMG (Magic). Increase own Magic DMG by 100% for 10s. Additionally if user is Light, increase own Accuracy by 50% for 10s.`,
                break: 1000
            },
        passive: 
            {
                ability1: `Increase allies' Magic DMG by 20% when their HP is above 80%. Does not stack with Abilities of the same name.`
            },
        lore: `Although presumed to be the oldest of the Ancients' Crest Blades, no specific mention of that could be found in their records. They were powerful enough to destroy matter effortlessly, but it possesses sheer cutting ability unseen among their other artifacts. Its very existence, to say nothing of its purpose, is yet steeped in mystery.`
    },
    { //True 'Radical Edge' - Missing Lore
        id: 112011,
        name: `True 'Radical Edge'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112010.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112011.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112010.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112011.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 6000% Earth DMG (Physical). 70% Chance to inflict Poison for 12s. If user is Earth, increase own DMG by 35% for 12s.`,
                break: 600
            },
        passive: 
            {
                ability1: `When equipped by Anti-Heroine Pola, increase own Arts by 3/s. Does not stack with Abilities of the same name.`,
                ability2: `Increase own ATK by 5%.`
            },
        lore: `Missing Lore.`
    },
    { //Radical Edge - Missing Lore
        id: 112021,
        name: `Radical Edge`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112020.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112021.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112020.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112021.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 6000% Earth DMG (Physical). Increase own Break Power by 40% for 10s. Additionally if user is Earth, increase own Physical DMG by 30% for 10s.`,
                break: 800
            },
        passive: 
            {
                ability1: `Increase own ATK by 5%.`
            },
        lore: `Missing Lore.`
    },
    { //Center of Attention - Missing Lore
        id: 112031,
        name: `Center of Attention`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112030.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112031.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112030.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112031.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 120
            },
        skillset:  
            {
                skill: `55s CT - Increase all allies' DMG resistance by 10% and Earth allies' DMG resistance by 10% for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increase own BE Output by 5%.`
            },
        lore: `Missing Lore.`
    },
    { //蒼機盾『ツヴァイトシルト』 - JP Complete
        id: 112041,
        name: `蒼機盾『ツヴァイトシルト』`,
        translate: `Blue Machine Shield 'Zweit Shield'`,
        location: `Cyber-Z Mines Boss Drop`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112040.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112041.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112040.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112041.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 120
            },
        skillset:  
            {
                skill: `30s CT - Increase all Water allies' and Light allies' DMG resistance by 25% and reduce all allies' Status ailment resistance by 30% for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increase own HP Recovery by 5%.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //True 'Gladius' - Missing Lore
        id: 112051,
        name: `True 'Gladius'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112050.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112051.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112050.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112051.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 10000% Water DMG (Physical). Increase own Physical DMG by 50% for 10s. Additionally if user is Water, increase own ATK by 50% for 10s.`,
                break: 1000
            },
        passive: 
            {
                ability1: `When equipped by Ultimate Paladin Roy, increase own Arts by 2/s. At the start of a Quest, increase own Arts by 100. Does not stack with Abilities of the same name.`,
                ability2: `Increase own Accuracy by 10%.`
            },
        lore: `Missing Lore.`
    },
    { //Sainted Sword 'Gladius' - Missing Lore
        id: 112061,
        name: `Sainted Sword 'Gladius'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112060.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112061.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112060.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112061.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 10000% Water DMG (Physical). If user is Water, increase own DMG by 50% for 10s.`,
                break: 1500
            },
        passive: 
            {
                ability1: `Ignore enemies' DEF by 20%.`,
                ability2: `Increase all allies' Water DMG by 20%. Does not stack with Abilities of the same name.`
            },
        lore: `Missing Lore.`
    },
    { //Sainted Armor 'Aeolus' - Missing Lore
        id: 112071,
        name: `Sainted Armor 'Aeolus'`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112070.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112071.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112070.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112071.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `40s CT - Increase all allies' Water resistance, Dark resistance and Skill CT Speed by 30% for 12s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Ignore own DEF by 5%.`
            },
        lore: `Missing Lore.`
    },
    { //Garland Sword 'Star Light' - Missing Lore
        id: 112081,
        name: `Garland Sword 'Star Light'`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112080.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112081.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112080.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112081.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `20s CT - 4600% Light DMG (Magic). Increase own Skill CT Speed by 70% for 8s. If user is Light, increase own Skill CT Speed by 100% instead.`,
                break: 400
            },
        passive: 
            {
                ability1: `Increase own Magic resistance by 5%.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //Resolute Reindeer Noel - Missing Lore
        id: 112091,
        name: `Resolute Reindeer Noel`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112090.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112091.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112090.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112091.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 8000% Light DMG (Physical). Increase own Light DMG by 60% for 8s. If used when enemy is below 30% HP, increase own Light DMG by 120% instead.`,
                break: 500
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Perfect Santa Emperor - Missing Lore
        id: 112101,
        name: `Perfect Santa Emperor`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112100.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112101.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112100.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112101.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `30s CT - Increase all allies' stats by 20% and Physical DMG by 50% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Loving Snow Princess - Missing Lore
        id: 112111,
        name: `Loving Snow Princess`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112110.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112111.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112110.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112111.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `40s CT - Increase all allies' Physical resistance by 20% and Freeze resistance by 100% for 30s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Hunting Santa Jay - Missing Lore
        id: 112121,
        name: `Hunting Santa Jay`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112120.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112121.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112120.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112121.png`
            },
        stats:
            {
                hp: 500,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - Cleanse all allies' Status ailments (except for Faint). Heal all Human allies' HP by 300/s for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Watchful Reindeer Canaria - Missing Lore
        id: 112131,
        name: `Watchful Reindeer Canaria`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112130.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112131.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112130.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112131.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `30s CT - Increase all allies' DMG resistance by 15%, Fire resistance, Water resistance and Earth resistance by 25%, and Paralysis resistance by 100% for 12s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //マッドネスサイス - JP Complete (Groitz' raid equip)
        id: 112141,
        name: `マッドネスサイス`,
        translate: `Madness Scythe`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112140.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112141.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112140.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112141.png`
            },
        stats:
            {
                hp: 500,
                atk: 500,
                def: 500
            },
        skillset:  
            {
                skill: `45s CT - 5000% Earth DMG (Magic). Reduce enemies' Earth resistance by 20% for 12s. Additionally if user is Earth, reduce enemies' Evasion rate by 20% for 12s.`,
                break: 500
            },
        passive: 
            {
                ability1: `When own HP is 90% or higher, increase own Accuracy by 20%.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //絶甲盾『アズルヴァイト』 - JP Complete (Cyber-Z Elite Mines Equip)
        id: 112151,
        name: `絶甲盾『アズルヴァイト』`,
        translate: `Armored Shield 'Azurite'`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112150.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112151.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112150.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112151.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `40s CT - Reduce target's DMG taken from Burning, Poisoned or Blinded enemies by 50% for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Reduce own Skill CT Speed by 10%.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //情熱装『スクレパシオン』 - JP Complete (Gorgeous Passionate Part 1 Farmable)
        id: 112161,
        name: `情熱装『スクレパシオン』`,
        translate: `Passionate Outfit 'Sucre Passion'`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112160.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112161.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112160.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112161.png`
            },
        stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
        skillset:  
            {
                skill: `45s CT - Increase target's Arts by 30. If user is Fire, increase target's Arts by 40 instead. If target is Fire, increase target's ATK by 30% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increase own Skill CT Speed by 5%.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //真『ヴェスパーダ』 - JP Complete
        id: 112171,
        name: `真『ヴェスパーダ』`,
        translate: `True 'Vespada'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112170.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112171.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112170.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112171.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `25s CT - 5000% Light DMG (Physical). Reduce enemies' Physical resistance by 15% (stackable) for 15s. If user is Light, increase own Arts by 10.`,
                break: 500
            },
        passive: 
            {
                ability1: `When equipped by Marlet, increase own ATK and Skill CT Speed by 50%. Does not stack with Abilities of the same name.`,
                ability2: `Increase own DMG by 10% against enemies in BREAK.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //煌華銃『ヴェスパーダ』 - JP Complete
        id: 112181,
        name: `煌華銃『ヴェスパーダ』`,
        translate: `Sparkling Gun 'Vespada'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112180.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112181.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112180.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112181.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 7000% Light DMG (Physical). Increase own Physical DMG by 40% for 10s. Additionally if user is Light, increase own Skill CT Speed by 40% for 10s.`,
                break: 500
            },
        passive: 
            {
                ability1: `Increase own ATK by 5%.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //華麗装『ブルヴェスティート』 - JP Complete
        id: 112191,
        name: `華麗装『ブルヴェスティート』`,
        translate: `Brilliant Outfit 'Blue Vestito'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112190.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112191.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112190.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112191.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - Increase own Light DMG by 40% for 10s. Additionally if user's HP is below 70%, heal own HP by 10%.`,
                break: 300
            },
        passive: 
            {
                ability1: `Increase own DEF by 5%.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //真『アスガルドの柩』 - JP Complete
        id: 112201,
        name: `真『アスガルドの柩』`,
        translate: `True 'Coffin of Asgard'`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112200.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112201.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112200.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112201.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 8000% Dark DMG (Magic). Reduce enemies' Dark resistance by 30% for 15s. If used when own HP is below 70%, reduce enemies' Dark resistance by 50% instead.`,
                break: 500
            },
        passive: 
            {
                ability1: `When equipped by Fictional Mage Hart, increase own Arts by 100 at start of quest. Does not stack with Abilities of the same name.`,
                ability2: `Increase own Accuracy by 10%.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //アスガルドの柩 - JP Complete
        id: 112211,
        name: `アスガルドの柩`,
        translate: `Coffin of Asgard`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112210.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112211.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112210.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112211.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 5000% Dark DMG (Magic). Increase own Magic DMG by 70% for 10s. When used for the first time, increase own Arts by 50. If user is Dark and when used for the first time, increase own Arts by 100 instead.`,
                break: 700
            },
        passive: 
            {
                ability1: `Reduce own DMG resistance by 20%.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //古礼靴 『ブレンフェイト』 - JP Complete
        id: 112221,
        name: `古礼靴 『ブレンフェイト』`,
        translate: `Traditional Shoes 'Brain Fate'`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112220.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112221.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112220.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112221.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `60s CT - Increase all allies' Arts by 2/s and Dark Allies' DMG by 25% for 20s.`,
                break: 500
            },
        passive: 
            {
                ability1: `Increase own DEF by 5%.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //火竜神の剣 - JP Complete
        id: 112231,
        name: `火竜神の剣`,
        translate: `Fire Dragon God's Sword`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112230.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112231.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112230.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112231.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `80s CT - Increase own Magic DMG by 40% and all Fire allies' Magic DMG by 40% for 20s.`,
                break: 500
            },
        passive: 
            {
                ability1: `Increase Water resistance by 5%.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //水竜神の耳飾り - JP Complete
        id: 112241,
        name: `水竜神の耳飾り`,
        translate: `Water Dragon God's Earrings`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112240.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112241.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112240.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112241.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `80s CT - Increase own Magic DMG by 40% and all Water allies' Magic DMG by 40% for 20s.`,
                break: 500
            },
        passive: 
            {
                ability1: `Increase Earth resistance by 5%.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //樹竜神の盾 - JP Complete
        id: 112251,
        name: `樹竜神の盾`,
        translate: `Earth Dragon God's Shield`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112250.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112251.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112250.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112251.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `80s CT - Increase own Magic DMG by 40% and all Earth allies' Magic DMG by 40% for 20s.`,
                break: 500
            },
        passive: 
            {
                ability1: `Increase Fire resistance by 5%.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //Silk Loving Broom 'Etoile V' - Missing Lore
        id: 112261,
        name: `Silk Loving Broom 'Etoile V'`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112260.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112261.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112260.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112261.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `55s CT - 4000% Earth DMG (Magic). Reduce enemies' Earth resistance by 30% for 10s. Additionally if used for the first time, increase own Arts by 50.`,
                break: 400
            },
        passive: 
            {
                ability1: `Increase own Magic resistance by 5%.`
            },
        lore: `Missing Lore.`
    },
    { //焔竜兜『デグルロア』 - JP Complete
        id: 112271,
        name: `焔竜兜『デグルロア』`,
        translate: `Dragon Helmet 'Degilroar'`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112270.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112271.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112270.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112271.png`
            },
        stats:
            {
                hp: 500,
                atk: 500,
                def: 500
            },
        skillset:  
            {
                skill: `45s CT - For 20s, increase all allies' Fire resistance by 25%.`,
                break: 0
            },
        passive: 
            {
                ability1: `When own HP is 80% or above, increase own ATK by 15%.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //光竜神の槍 - JP Complete
        id: 112281,
        name: `光竜神の槍`,
        translate: `Light Dragon God's Spear`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112280.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112281.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112280.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112281.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `80s CT - 8000% Dark DMG (Magic). Increase own Magic DMG by 40% and Light allies' Magic DMG by 40% for 20s.`,
                break: 500
            },
        passive: 
            {
                ability1: `Increase own Dark resistance by 5%.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //闇竜神の斧 - JP Complete
        id: 112291,
        name: `闇竜神の斧`,
        translate: `Dark Dragon God's Axe`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112290.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112291.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112290.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112291.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `80s CT - 8000% Dark DMG (Magic). For 20s, increase own Magic DMG by 40% and Dark allies' Magic DMG by 40%.`,
                break: 500
            },
        passive: 
            {
                ability1: `Increase own Light resistance by 5%.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //極『アネモス』 (Tonetaurus Raid Equip) - JP Complete
        id: 112301,
        name: `極『アネモス』`,
        translate: `'Anemos' EV`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: ``,
                detailmax: `/db/Equipment/Detail/item_detail_112301.png`,
                thumb: ``,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112301.png`
            },
        stats:
            {
                hp: 500,
                atk: 500,
                def: 500
            },
        skillset:  
            {
                skill: `55s CT - Heal all Allies' HP by 200 and increase all Allies' Earth Resistance by 10% for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Poison Time Reduction & Poison Evasion rate 10% UP.`,
                ability2: [`Increase own Magic resistance by 5%.`, `Increase own Magic resistance by 10%.`, `Increase own ATK by 5%.`, `Increase own Heal Amount by 10%.`, `Increase own Heal Amount by 15%.`, `Increase own DEF by 5%.`]
            },
        lore: `Currently unreleased in Global.`
    },
    { //蒼翼剣『レイダルファング』(Freydal Raid Equip) - JP Complete
        id: 112311,
        name: `蒼翼剣『レイダルファング』`,
        translate: `Blue-Winged Sword 'Reydal Fang'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112310.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112311.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112310.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112311.png`
            },
        stats:
            {
                hp: 500,
                atk: 500,
                def: 500
            },
        skillset:  
            {
                skill: `30s CT - 4500% Water DMG (Physical). If enemy is Fire, reduce enemies' CRI resistance by 30% for 5s.`,
                break: 1250
            },
        passive: 
            {
                ability1: `Increase DMG against Fire enemies by 10%.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //真『斬塵如火』 - JP Complete
        id: 112321,
        name: `真『斬塵如火』`,
        translate: `True 'Fiery Decapitation'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112320.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112321.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112320.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112321.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 10000% Fire DMG (Physical). Increase own Physical DMG and Fire DMG by 50% for 10s.`,
                break: 600
            },
        passive: 
            {
                ability1: `When equipped by Lark, increase own Arts by 100 at Start of Quest. Does not stack with Abilities of the same name.`,
                ability2: `Increase own Physical resistance by 5%.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //緋燕刀『斬塵如火』 - JP Complete
        id: 112331,
        name: `緋燕刀『斬塵如火』`,
        translate: `Hien Katana 'Fiery Decapitation'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112330.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112331.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112330.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112331.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 4000% Fire DMG (Physical). Increase own ATK by 80% for 12s. Additionally if user is Fire, increase own Skill CT Speed by 80% for 12s.`,
                break: 700
            },
        passive: 
            {
                ability1: `Increase own DEF by 5%.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //天元甲『アルヴェーダ』(Marzex mines equip) - JP Complete
        id: 112341,
        name: `天元甲『アルヴェーダ』`,
        translate: `Heavenly Origin Armor 'Alveda'`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112340.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112341.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112340.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112341.png`
            },
        stats:
            {
                hp: 120,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `30s CT - Cure all allies' Burn and Poison. Heal all allies' HP by 100/s for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Reduce own Max HP by 15%.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //真『フェスタリオン』(Elenore TW) - JP Complete
        id: 112351,
        name: `真『フェスタリオン』`,
        translate: `True 'Festalion'`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112350.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112351.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112350.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112351.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 7000% Earth DMG (Magic). Increase own Magic DMG by 40% and reduce enemies' Magic resistance by 30% for 10s.`,
                break: 900
            },
        passive: 
            {
                ability1: `When equipped by Elenore, the Skill CT Speed buff and Equip CT Speed buff from Super Arts will start at 60% and 20% respectively (MAX effect will not be affected). Does not stack with Abilities of the same name.`,
                ability2: `Increase own BE Output by 10%.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //魔刻杖『フェスタリオン』(Elenore farmable) - JP Complete
        id: 112361,
        name: `魔刻杖『フェスタリオン』`,
        translate: `Magic Staff 'Festalion'`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112360.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112361.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112360.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112361.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 5000% Earth DMG (Magic). Inccrease own Earth DMG by 40% for 10s. Additionally when used for the first time, increase own Arts by 50.`,
                break: 500
            },
        passive: 
            {
                ability1: `Increase own Magic resistance by 5%.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //燐光套『フォスブリオ』(Fosradius Raid Equip) - JP Complete
        id: 112371,
        name: `燐光套『フォスブリオ』`,
        translate: `Phosphorescent Cloak 'Fosbrio'`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112370.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112371.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112370.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112371.png`
            },
        stats:
            {
                hp: 500,
                atk: 500,
                def: 500
            },
        skillset:  
            {
                skill: `40s CT - For 12s, increase allies' DMG resistance by 15% and Light allies' DMG resistance by an additional 15%.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increase own HP Recovery by 25%.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //真『ライジング・ノヴァ』 - JP Complete
        id: 112381,
        name: `真『ライジング・ノヴァ』`,
        translate: `True 'Rising Nova'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112380.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112381.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112380.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112381.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 9000% Water DMG (Physical). Reduce enemies' Water resistance by 40% for 10s. Additionally if user is Water, increase own Accuracy by 30% for 10s.`,
                break: 500
            },
        passive: 
            {
                ability1: `When equipped by Star Swordswoman Valerie, increase own ATK by 50% and Arts by 2/s. Does not stack with Abilities of the same name.`,
                ability2: `Increase own HP Recovery by 5%.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //星剣『ライジング・ノヴァ』 - JP Complete
        id: 112391,
        name: `星剣『ライジング・ノヴァ』`,
        translate: `Star Sword 'Rising Nova'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112390.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112391.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112390.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112391.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 4000% Water DMG (Physical). Increase own ATK by 80% for 12s. Additionally if user is Water, increase own Skill CT Speed by 80% for 12s.`,
                break: 700
            },
        passive: 
            {
                ability1: `Increase own DEF by 5%.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //真『ディープ・ブラッド』 - JP Complete
        id: 112401,
        name: `真『ディープ・ブラッド』`,
        translate: `True 'Deep Blood'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112400.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112401.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112400.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112401.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 7500% Fire DMG (Physical). If enemy is already Bleeding, increase its stack of Bleed by 1 (up to 3). Reduce enemies' Physical resistance by 35% for 8s.`,
                break: 300
            },
        passive: 
            {
                ability1: `When equipped by Velzard, increase own DMG by 30% and Arts by 2/s. Does not stack with Abilities of the same name.`,
                ability2: `Increase own Accuracy by 10%.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //血鎌『ディープ・ブラッド』 - JP Complete
        id: 112411,
        name: `血鎌『ディープ・ブラッド』`,
        translate: `Blood Scythe 'Deep Blood'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112410.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112411.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112410.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112411.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 10000% Fire DMG (Physical). Increase own CRI DMG by 50% for 8s. If user is Fire, increase own CRI DMG by 80% instead.`,
                break: 500
            },
        passive: 
            {
                ability1: `When above 90% HP, increase all allies' Physical DMG by 20%. Does not stack with Abilities of the same name.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //真『ツイン・ファルコン』 - JP Complete
        id: 112421,
        name: `真『ツイン・ファルコン』`,
        translate: `True 'Twin Falcon'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112420.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112421.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112420.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112421.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 8000% Fire DMG (Physical). Increase own Physical DMG by 40% (stackable) for 8s.`,
                break: 4000
            },
        passive: 
            {
                ability1: `When equipped by Azuel, increase own Break Power by 30% and Arts by 2/s. Does not stack with Abilities of the same name.`,
                ability2: `Increase own ATK by 5%.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //絶元甲『ゼクスヴェーダ』 - JP Complete
        id: 112431,
        name: `絶元甲『ゼクスヴェーダ』`,
        translate: `Absolute Armor 'Zex Veda'`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112430.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112431.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112430.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112431.png`
            },
        stats:
            {
                hp: 500,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `30s CT - Heal Light allies' HP by 250/s and God allies' HP by 250/s for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Reduce own MAX HP by 20%.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //真『白玄両儀』 - JP Complete
        id: 112441,
        name: `真『白玄両儀』`,
        translate: `True 'Hakugen Ryogi'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112440.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112441.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112440.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112441.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `20s CT - 4000% Dark DMG (Physical). Reduce enemies' Physical resistance by 30% for 10s. Additionally if user is God, increase own Arts by 10.`,
                break: 500
            },
        passive: 
            {
                ability1: `When equipped by Sword God Luahn, increase own stats by 20% and Arts by 3/s. Does not stack with Abilities of the same name.`,
                ability2: `Increase own Skill DMG by 10%.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //龍神刀『白玄両儀』 - JP Complete
        id: 112451,
        name: `龍神刀『白玄両儀』`,
        translate: `Dragon God Sword 'Hakugen Ryogi'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112450.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112451.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112450.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112451.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `80s CT - 10000% Dark DMG (Physical). Reduce enemies' Dark resistance by 40% for 7s. Additionally if user is God, increase own BE Output by 40% for 7s.`,
                break: 500
            },
        passive: 
            {
                ability1: `Reduce own Arts by 2/s when below 80% HP.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //Dragon God Clothes 'Naru Tenki' - JP Complete
        id: 112461,
        name: `龍神衣『鳴天葵威』`,
        translate: `Dragon God Clothes 'Naru Tenki'`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112460.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112461.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112460.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112461.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 7000% Dark DMG (Magic). Increase own Arts by 20. Increase own CRI DMG by 50% for 10s.`,
                break: 600
            },
        passive: 
            {
                ability1: `Increase own Skill CT Speed by 5%.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //True 'Mira's Swelling Mermaid' - JP Complete
        id: 112471,
        name: `真『ミラのもりもりマーメイド』`,
        translate: `True 'Mira's Swelling Mermaid'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112470.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112471.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112470.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112471.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `70s CT - 12000% Water DMG (Physical). Increase own DMG by 50% and Water DMG by 70% for 15s.`,
                break: 500
            },
        passive: 
            {
                ability1: `When equipped by Summer Mira, increase own ATK and Accuracy by 30%. Does not stack with Abilities of the same name.`,
                ability2: `Increase own Physical DMG by 20%.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //True 'Celia's Rosy Parasol' - JP Complete
        id: 112481,
        name: `真『シーリアのぱらいそパラソル』`,
        translate: `True 'Celia's Rosy Parasol'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112480.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112481.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112480.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112481.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 8000% Dark DMG (Physical). Reduce enemies' Dark resistance by 40% for 12s. Additionally if user is Dark, increase own Accuracy by 30% for 12s.`,
                break: 800
            },
        passive: 
            {
                ability1: `When equipped by Summer Celia, increase own Arts by 3/s and DMG by 30%. Does not stack with Abilities of the same name.`,
                ability2: `Increase own DMG against enemies in BREAK by 20%.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //Mira's Swelling Mermaid - JP Complete
        id: 112491,
        name: `ミラのもりもりマーメイド`,
        translate: `Mira's Swelling Mermaid`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112490.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112491.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112490.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112491.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 8000% Water DMG (Physical). Reduce enemies' Water resistance by 40% for 12s. Additionally if user is Water, increase own Accuracy by 40% for 12s.`,
                break: 800
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Currently unreleased in Global.`
    },
    { //Mira's Critical Bikini - JP Complete
        id: 112501,
        name: `ミラのクリティカルビキニ`,
        translate: `Mira's Critical Bikini`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112500.png`,
                detailmax: `/db/Equipment/Detail/item_detail_1125061.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_1125060.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112501.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `55s CT - Increase all allies' Arts by 4/s and Water allies' CRI rate by 100% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Currently unreleased in Global.`
    },
    { //Mira's Heartful Glasses - JP Complete
        id: 112511,
        name: `ミラのハートフルグラス`,
        translate: `Mira's Heartful Glasses`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112510.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112511.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112510.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112511.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 3000% Water DMG (Physical). Increase own DMG against Giant Bosses by 30% for 10s.`,
                break: 300
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Currently unreleased in Global.`
    },
    { //Celia's Rosy Parasol - JP Complete
        id: 112521,
        name: `シーリアのぱらいそパラソル`,
        translate: `Celia's Rosy Parasol`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112520.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112521.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112520.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112521.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 8000% Dark DMG (Physical). Reduce enemies' Physical resistance by 40% for 10s. Additionally if enemies are inflicted with Status ailment(s), reduce enemies' Physical resistance by an additional 20% for 10s.`,
                break: 500
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Currently unreleased in Global.`
    },
    { //Family's Selection 2024 - JP Complete
        id: 112531,
        name: `眷属'sセレクション2024`,
        translate: `Family's Selection 2024`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112530.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112531.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112530.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112531.png`
            },
        stats:
            {
                hp: 500,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - Heal Human allies' HP by 300/s for 20s. Additionally when used for the first time, increase all allies' Arts by 20.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Currently unreleased in Global.`
    },
    { //Celia's Glasses - JP Complete
        id: 112541,
        name: `シーリアのおめがね`,
        translate: `Celia's Glasses`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112540.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112541.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112540.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112541.png`
            },
        stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
        skillset:  
            {
                skill: `35s CT - Increase all allies' Arts by 15. Additionally if user is Dark, increase all allies' DMG resistance by 10% for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increase own DEF by 5%.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //真『イリスのドリームロケット』 - JP Complete
        id: 112551,
        name: `真『イリスのドリームロケット』`,
        translate: `True 'Iris' Dream Rocket'`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112550.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112551.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112550.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112551.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 8000% Fire DMG (Magic). Reduce enemies' Fire resistance by 40% for 8s. Additionally if user is God, reduce enemies' Evasion rate by 30% for 8s.`,
                break: 400
            },
        passive: 
            {
                ability1: `When equipped by Summer Iris, increase own Equip CT Speed by 20%. Does not stack with Abilities of the same name.`,
                ability2: `Increase own ATK by 5%.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //真『フェンのビッグウェーブ』 - JP Complete
        id: 112561,
        name: `真『フェンのビッグウェーブ』`,
        translate: `True 'Fen's Wavecutter'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112560.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112561.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112560.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112561.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 8000% Water DMG (Physical). Reduce enemies' Water resistance by 30% for 10s. If user is Human, reduce enemies' Water resistance by 50% instead.`,
                break: 500
            },
        passive: 
            {
                ability1: `When equipped by Summer Fen, increase own stats by 20% and Healing Amount by 15% (excluding Lifesteal). Does not stack with Abilities of the same name.`,
                ability2: `Increase own DEF by 5%.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //真『ロゼッタインナー(スリム用)』 - JP Complete
        id: 112571,
        name: `真『ロゼッタインナー(スリム用)』`,
        translate: `True 'Rosetta's Innerwear (Slim)'`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112570.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112571.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112570.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112571.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `35s CT - Increase all allies' Arts by 3/s and Demon allies' stats by 15% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `When equipped by Summer Rosetta, increase own Arts by 100 at Start of Quest. Does not stack with Abilities of the same name.`,
                ability2: `Increase own DEF by 10%.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //真『リヴィエラ・ダイナマイト』 - JP Complete
        id: 112581,
        name: `真『リヴィエラ・ダイナマイト』`,
        translate: `True 'Riviera's Dynamite Attack'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112580.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112581.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112580.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112581.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 6000% Fire DMG (Physical). Increase own Fire DMG by 50% for 15s. If user is Demon, increase own Fire DMG by 80% instead.`,
                break: 600
            },
        passive: 
            {
                ability1: `When equipped by Summer Riviera, increase own Arts by 3/s. Does not stack with Abilities of the same name.`,
                ability2: `Increase own Magic resistance by 5%.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //封魂の魔晶瓶 - JP Complete
        id: 112591,
        name: `封魂の魔晶瓶`,
        translate: `Soul Sealing Crystal Flask`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112590.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112591.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112590.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112591.png`
            },
        stats:
            {
                hp: 500,
                atk: 500,
                def: 500
            },
        skillset:  
            {
                skill: `30s CT - 4500% Dark DMG (Magic). If enemy is Light, reduce enemies' CRI resistance by 30% for 5s.`,
                break: 1250
            },
        passive: 
            {
                ability1: `Increase own DMG against Light enemies by 10%.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //真『ディード・イクス・フラム』 - JP Complete
        id: 112601,
        name: `真『ディード・イクス・フラム』`,
        translate: `True 'Deed, Ix, Flam'`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112600.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112601.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112600.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112601.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `55s CT - 10000% Fire DMG (Magic). Reduce enemies' Fire resistance by 30% for 8s. Additionally if user is Human, reduce enemies' DMG resistance by 20% for 8s.`,
                break: 500
            },
        passive: 
            {
                ability1: `When equipped by Fiery Platoon Leader Charlotte, increase own DMG by 30% and Arts by 2/s. Does not stack with Abilities of the same name.`,
                ability2: `Increase own Magic resistance by 5%.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //地灼剣『ディード・イクス・フラム』 - JP Complete
        id: 112611,
        name: `地灼剣『ディード・イクス・フラム』`,
        translate: `Earth-Shattering Sword 'Deed, Ix, Flam'`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112610.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112611.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112610.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112611.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `70s CT- 8000% Fire DMG (Magic). Increase own Magic DMG by 40% and Fire allies' DMG by 20% for 12s.`,
                break: 400
            },
        passive: 
            {
                ability1: `Increase own BE Output by 5%.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //True 'Forte's Dolly Dress' - JP Complete
        id: 112621,
        name: `True 'Forte's Dolly Dress'`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112620.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112621.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112620.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112621.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `35s CT - Increase all allies' DMG resistance by 20% for 15s. If user is Dark, increase all allies' DMG resistance by 30% instead.`,
                break: 0
            },
        passive: 
            {
                ability1: `When equipped by Halloween Forte, increase HP Recovery amount by 10% and Arts by 2/s. Does not stack with Abilities of the same name.`,
                ability2: `Increase own DEF by 5%.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //True 'Magical Muscles Valerie' - JP Complete
        id: 112631,
        name: `True 'Magical Muscles Valerie'`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112630.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112631.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112630.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112631.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `70s CT - 7000% Water DMG (Magic). Increase own Water DMG by 30% for 15s. Additionally if user is Human, increase all allies' Magic DMG by 40% (stackable) for 15s.`,
                break: 700
            },
        passive: 
            {
                ability1: `When equipped by Halloween Valerie, increase own DMG by 30% and Arts by 2/s. Does not stack with Abilities of the same name.`,
                ability2: `Increase own ATK by 5%.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //True 'Lotte's Fluffy Mitts' - JP Complete
        id: 112641,
        name: `True 'Lotte's Fluffy Mitts'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112640.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112641.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112640.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112641.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 5000% Fire DMG (Physical). If enemy is Bleeding, increase Bleed level by 1 (MAX: 3). Additionally if user is Human, reduce enemies' Fire resistance by 30% for 8s.`,
                break: 200
            },
        passive: 
            {
                ability1: `When equipped by Halloween Charlotte, increase own DMG by 30% and Arts by 2/s. Does not stack with Abilities of the same name.`,
                ability2: `Increase own ATK by 5%.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //真『トロイメライ』 - JP Complete
        id: 112651,
        name: `真『トロイメライ』`,
        translate: `True 'Traumerei'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112650.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112651.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112650.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112651.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `65s CT - 6000% Light DMG (Physical). Reduce enemies' Light resistance by 30% for 8s.`,
                break: 6000
            },
        passive: 
            {
                ability1: `When equipped by Phantom Saintess Platina, increase own ATK by 40% and Arts by 1/s. Does not stack with Abilities of the same name.`,
                ability2: `Increase own Magic resistance by 5%.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //幻聖剣『トロイメライ』 - JP Complete
        id: 112661,
        name: `幻聖剣『トロイメライ』`,
        translate: `Phantom Holy Sword 'Traumerei'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112660.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112661.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112660.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112661.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 3000% Light DMG (Physical). Increase own Break Power by 50% for 10s. If enemy is Dark, increase own Break Power by 100% instead.`,
                break: 6000
            },
        passive: 
            {
                ability1: `When own HP is less than 80%, reduce own Arts by 1/s.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //共鳴装『カンパネラ』 - JP Complete
        id: 112671,
        name: `共鳴装『カンパネラ』`,
        translate: `Echoing Outfit 'Campanella'`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112670.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112671.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112670.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112671.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 120
            },
        skillset:  
            {
                skill: `40s CT - Increase target's Dark resistance and Magic resistance by 20% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increase own ATK by 5%.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //真『ライトニング・メアⅢ』 - JP Complete
        id: 112681,
        name: `真『ライトニング・メアⅢ』`,
        translate: `True 'Lightning Mare III'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112680.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112681.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112680.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112681.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 8000% Dark DMG (Physical). Reduce enemies' DMG resistance by 20% for 10s. If user is a Spirit, reduce enemies' DMG resistance by an additional 10%. Additionally if user is Dark, reduce enemies' DMG resistance by an additional 10%.`,
                break: 500
            },
        passive: 
            {
                ability1: `When equipped by Natasha: Spiritia 05, increase the duration of Arts and Super Arts by an additional 5s and increase own Arts by 1/s. Does not stack with Abilities of the same name.`,
                ability2: `Increase own DEF by 5%.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //紫雷の戦棍『ライトニング・メアⅢ』 - JP Complete
        id: 112691,
        name: `紫雷の戦棍『ライトニング・メアⅢ』`,
        translate: `Purple Lightning War Mace 'Lightning Mare III'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112690.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112691.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112690.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112691.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 7000% Dark DMG (Physical). Increase own Physical DMG by 40% for 10s. Additionally if user is Dark, increase own Skill CT Speed by 40% for 10s.`,
                break: 500
            },
        passive: 
            {
                ability1: `Increase own ATK by 5%.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //超『エクス・クランベルジュ』 - JP Complete
        id: 112701,
        name: `超『エクス・クランベルジュ』`,
        translate: `Super 'Ex Granverge'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: ``,
                detailmax: `/db/Equipment/Detail/item_detail_112701.png`,
                thumb: ``,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112701.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `6s CT - 20000% Light DMG (Physical). Increase all allies' Arts by 20.`,
                break: 1000
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Currently unreleased in Global.`
    },
    { //真『エクス・クランベルジュ』 - JP Complete
        id: 112711,
        name: `真『エクス・クランベルジュ』`,
        translate: `True 'Ex Granverge'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112710.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112711.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112710.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112711.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 10000% Light DMG (Physical). If user is Light, reduce enemies' DMG resistance by 20% for 15s. Additionally if used when own HP is above 80%, reduce enemies' DMG resistance by an additional 15%.`,
                break: 500
            },
        passive: 
            {
                ability1: `When equipped by Orvell D. Granverge, increase own DMG by 50%, and Freeze resistance and Paralysis resistance by 100%. Does not stack with Abilities of the same name.`,
                ability2: `Increase own ATK by 5%.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //双王剣『エクス・クランベルジュ』 - JP Complete
        id: 112721,
        name: `双王剣『エクス・クランベルジュ』`,
        translate: `Twin King Swords 'Ex Granverge'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112720.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112721.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112720.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112721.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `70s CT - 15000% Light DMG (Physical). If user is God, increase own DMG and Accuracy rate by 80% for 20s.`,
                break: 500
            },
        passive: 
            {
                ability1: `Reduce own Arts by 2/s when HP is below 80%.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //煌王鎧『アルファダイン』 - JP Complete
        id: 112731,
        name: `煌王鎧『アルファダイン』`,
        translate: `Brilliant Jewel Armor 'Alphadine'`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112730.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112731.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112730.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112731.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `40s CT - Increase all allies' Light resistance and Physical resistance by 20% for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increase own DEF by 5%.  `
            },
        lore: `Currently unreleased in Global.`
    },
    { //真『マルチプル』 - JP Complete
        id: 112741,
        name: `真『マルチプル』`,
        translate: `True 'Multiple'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112740.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112741.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112740.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112741.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `75s CT - 10000% Fire DMG (Physical). Reduce enemies' Fire resistance by 30% for 10s. If user is Spirit, reduce enemies' Fire resistance by 60% instead.`,
                break: 1000
            },
        passive: 
            {
                ability1: `When equipped by Menas, increase own Arts by 200 at Start of Quest. Does not stack with Abilities of the same name.`,
                ability2: `Increase own ATK by 5%.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //万能刃『マルチプル』 - JP Complete
        id: 112751,
        name: `万能刃『マルチプル』`,
        translate: `Multi-purpose Blade 'Multiple'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112750.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112751.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112750.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112751.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 6000% Fire DMG (Physical). Increase own Physical DMG by 50% for 10s. Additionally if user is not Human, God or Demon, increase own Equip CT Speed by 30% for 10s.`,
                break: 300
            },
        passive: 
            {
                ability1: `Increase own Water resistance by 5%.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //フィリルの平々トナカイ - JP Complete
        id: 112761,
        name: `フィリルの平々トナカイ`,
        translate: `Firil's Flat Reindeer`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112760.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112761.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112760.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112761.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 10000% Water DMG (Physical). Reduce enemies' Water resistance by 30% for 12s. If own HP is above 90%, reduce Water resistance by 50% instead.`,
                break: 1000
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Currently unreleased in Global.`
    },
    { //アズエルのワイルドサンタ - JP Complete
        id: 112771,
        name: `アズエルのワイルドサンタ`,
        translate: `Wild Santa Azuel`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112770.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112771.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112770.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112771.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `30s CT - Heal all allies' HP by 2000. Increase allies' Fire resistance by 30% for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Currently unreleased in Global.`
    },
    { //ヴェナのモフモフサンタ - JP Complete
        id: 112781,
        name: `ヴェナのモフモフサンタ`,
        translate: `Fluffy Santa Vena`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112780.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112781.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112780.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112781.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `30s CT - Increase allies' Magic resistance by 30% for 15s. Additionally when used for the first time, increase Leader's Arts by 50.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Currently unreleased in Global.`
    },
    { //ルアーナのうさンタドレス - JP Complete
        id: 112791,
        name: `ルアーナのうさンタドレス`,
        translate: `Luana's Bunny Dress`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112790.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112791.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112790.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112791.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `80s CT - 12000% Light DMG (Magic). Increase own Arts DMG by 50% and Light allies' DMG by 60% for 20s.`,
                break: 1200
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Currently unreleased in Global.`
    },
    { //聖紋剣『ホーリーマスター』 - JP Complete
        id: 112801,
        name: `聖紋剣『ホーリーマスター』`,
        translate: `Holy Crest Sword 'Holy Master'`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112800.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112801.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112800.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112801.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 6000% Light DMG (Magic). Reduce enemies' DMG resistance by 25% for 10s. If user is Light, reduce enemies' DMG resistance by 50% instead.`,
                break: 500
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Currently unreleased in Global.`
    },
    { //雪逢刃『スノーシティ』 - JP Complete
        id: 112811,
        name: `雪逢刃『スノーシティ』`,
        translate: `Snow Aiming Blade 'Snow City'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112810.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112811.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112810.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112811.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 6000% Light DMG (Physical). Increase own Arts by 30. Increase own ATK by 50% for 10s. When used for the first time, increase own Arts by an additional 30.`,
                break: 1000
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Currently unreleased in Global.`
    },
    { //翠輪装『ファイザード』 - JP Complete
        id: 112821,
        name: `翠輪装『ファイザード』`,
        translate: `Green Ring 'Faizard'`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112820.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112821.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112820.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112821.png`
            },
        stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
        skillset:  
            {
                skill: `35s CT - Increase target's Arts by 30. For 10s, increase target's ATK by 70% and reduce target's DMG resistance by 30%.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increase own Poison resistance by 10%.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //氷竜珠『エルスフィア』 - JP Complete
        id: 112831,
        name: `氷竜珠『エルスフィア』`,
        translate: `Ice Dragon Sphere 'Elsphere'`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112830.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112831.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112830.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112831.png`
            },
        stats:
            {
                hp: 500,
                atk: 500,
                def: 500
            },
        skillset:  
            {
                skill: `30s CT - 2000% Water DMG (Magic). If user's HP is above 90%, for 35s, increase own Water DMG by 35% (Stackable).`,
                break: 200
            },
        passive: 
            {
                ability1: `Increase own DMG against Fire Enemies by 10%.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //渇望の杖『オウンサースト』 - JP Complete
        id: 112841,
        name: `渇望の杖『オウンサースト』`,
        translate: `Staff of Desire 'Own Thirst'`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112840.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112841.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112840.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112841.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `55s CT - 5000% Dark DMG (Magic). If enemy is God or Demon, reduce enemy's DMG resistance by 30% for 10s.`,
                break: 500
            },
        passive: 
            {
                ability1: `At Start of Quest, increase Human allies' Arts by 10. Does not stack with Abilities of the same name.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //人族史書『ロストピア』 - JP Complete
        id: 112851,
        name: `人族史書『ロストピア』`,
        translate: `Book of Human History 'Lostopia'`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112850.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112851.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112850.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112851.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `40s CT - Increase all allies' Arts by 25. Increase all allies' DMG against Gods and Demons by 40% for 13s.`,
                break: 0
            },
        passive: 
            {
                ability1: `At Start of Quest, increase Human allies' Arts by 10. Does not stack with Abilities of the same name.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //合理の剣『アンダーマキナ』 - JP Complete
        id: 112861,
        name: `合理の剣『アンダーマキナ』`,
        translate: `Sword of Reason 'Under Machina'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112860.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112861.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112860.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112861.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 8000% Fire DMG (Physical). Increase own DMG against God and Demon enemies by 80% for 10s.`,
                break: 800
            },
        passive: 
            {
                ability1: `At Start of Quest, increase Human allies' Arts by 10. Does not stack with Abilities of the same name.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //叡智の薬瓶『オッドマーク』 - JP Complete
        id: 112871,
        name: `叡智の薬瓶『オッドマーク』`,
        translate: `Vials of Wisdom 'Odd Mark'`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112870.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112871.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112870.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112871.png`
            },
        stats:
            {
                hp: 500,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - Heal all allies' HP by 3000. Allies heal for 10% of DMG Taken from Gods and Demons for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: `At Start of Quest, increase Human allies' Arts by 10. Does not stack with Abilities of the same name.`
            },
        lore: `Currently unreleased in Global.`
    },  
    { //静寂の兜『ウィルシード』 - JP Complete
        id: 112881,
        name: `静寂の兜『ウィルシード』`,
        translate: `Silent Helmet 'Will Seed'`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112880.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112881.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112880.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112881.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `35s CT - Reduce all allies' DMG Taken from Gods and Demons by 30% for 13s.`,
                break: 0
            },
        passive: 
            {
                ability1: `At Start of Quest, increase Human allies' Arts by 10. Does not stack with Abilities of the same name.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //絶耀具『マグナヘリオス』 - JP Complete
        id: 112891,
        name: `絶耀具『マグナヘリオス』`,
        translate: `Shining Tool 'Magna Helios'`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_112890.png`,
                detailmax: `/db/Equipment/Detail/item_detail_112891.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_112890.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_112891.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 4000% Light DMG (Magic). Reduce all allies' and enemies' Status resistance by 30%, and increase own DMG against enemies with Status ailment(s) by 40% for 10s.`,
                break: 600
            },
        passive: 
            {
                ability1: `Increase own Magic resistance by 10%.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //Ultra Relic 'Petraskia' - Missing Lore
        id: 140011,
        name: `Ultra Relic 'Petraskia'`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_140010.png`,
                detailmax: `/db/Equipment/Detail/item_detail_140011.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_140010.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_140011.png`
            },
        stats:
            {
                hp: `X`,
                atk: `X`,
                def: `X`
            },
        skillset:  
            {
                skill: `55s CT - 8000% Fire DMG (Magic). Increase Own ATK by 50% for 15s.`,
                break: 600
            },
        passive: 
            {
                ability1: `Increase Own Magic DMG by 25% UP. For 165.5s after start of quest, Increase All Allies Fire DMG by 20%. (At Passive Lv.101)`
            },
        lore: `Missing Lore.`
    },
{ //Ultra Relic 'Zero=Recorder' - Missing Lore
        id: 140021,
        name: `Ultra Relic 'Zero=Recorder'`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_140020.png`,
                detailmax: `/db/Equipment/Detail/item_detail_140021.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_140020.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_140021.png`
            },
        stats:
            {
                hp: `X`,
                atk: `X`,
                def: `X`
            },
        skillset:  
            {
                skill: `30s CT - Increase target's DMG by 30% and target's Arts gauge by 3/s for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Every 14.9s, Increase All Allies' Arts Gauge by 10. For 93.7s after start of quest, Increase own Freeze resistance by 15%. (At Passive Lv.101)`
            },
        lore: `Missing Lore.`
    },
{ //Ultra Relic 'Benemopis' - Missing Lore
        id: 140031,
        name: `Ultra Relic 'Benemopis'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_140030.png`,
                detailmax: `/db/Equipment/Detail/item_detail_140031.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_140030.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_140031.png`
            },
        stats:
            {
                hp: `X`,
                atk: `X`,
                def: `X`
            },
        skillset:  
            {
                skill: `25s CT - 3700% Earth DMG (Physical). Increase own DMG by 20% for 6s.`,
                break: 200
            },
        passive: 
            {
                ability1: `Increase Own Poison resistance by 27.5%. For 165.5s after start of quest, Increase all Allies' Earth DMG by 28.5%. (At Passive Lv.101)`
            },
        lore: `Missing Lore.`
    },
{ //Ultra Relic 'Bleak Prophet' - Missing Lore
        id: 140041,
        name: `Ultra Relic 'Bleak Prophet'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_140040.png`,
                detailmax: `/db/Equipment/Detail/item_detail_140041.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_140040.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_140041.png`
            },
        stats:
            {
                hp: `X`,
                atk: `X`,
                def: `X`
            },
        skillset:  
            {
                skill: `55s CT - 10000% Light DMG (Physical). Increase own Arts gauge by 30.`,
                break: 1000
            },
        passive: 
            {
                ability1: `Increase own Physical DMG by 25.0%. For 165.5s after start of quest, Increase all Allies' Light DMG by 20.0%. (At Passive Lv.101)`
            },
        lore: `Missing Lore.`
    },
{ //Ultra Relic 'Eraser Bit' - Missing Lore
        id: 140051,
        name: `Ultra Relic 'Eraser Bit'`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_140050.png`,
                detailmax: `/db/Equipment/Detail/item_detail_140051.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_140050.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_140051.png`
            },
        stats:
            {
                hp: `X`,
                atk: `X`,
                def: `X`
            },
        skillset:  
            {
                skill: `30s CT - 5300% Non-Elemental DMG (Magic). Increase own ATK & DMG by 30% for 10s. If this equipment is used for the first time, increase all Allies' Arts gauge by 30.`,
                break: 300
            },
        passive: 
            {
                ability1: `Increase own Skill CT recovery by 10%. For 20.7s from Start of Quest, increase all Allies' Magic DMG by 27.7%. (At Passive Lv.101)`
            },
        lore: `Missing Lore.`
    },
{ //Ultra Relic 'Pteraturus' - Missing Lore
        id: 140061,
        name: `Ultra Relic 'Pteraturus'`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_140060.png`,
                detailmax: `/db/Equipment/Detail/item_detail_140061.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_140060.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_140061.png`
            },
        stats:
            {
                hp: `X`,
                atk: `X`,
                def: `X`
            },
        skillset:  
            {
                skill: `30s CT - Increase all Allies' DEF by 50% & DMG resistance by 20% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `For 88s at Start of Quest, negate all Allies' DMG for a total of 3451 & increase all Allies' CRI resistance by 23.7%. (At Passive Lv.101)`
            },
        lore: `Missing Lore.`
    },
{ //Ultra Relic 'Empty Heart' - Missing Lore
        id: 140071,
        name: `Ultra Relic 'Empty Heart'`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_140070.png`,
                detailmax: `/db/Equipment/Detail/item_detail_140071.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_140070.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_140071.png`
            },
        stats:
            {
                hp: `X`,
                atk: `X`,
                def: `X`
            },
        skillset:  
            {
                skill: `60s CT - Heals all Allies' HP by 3% & increase all Allies' Arts gauge by 1 for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: `At Start of Quest, increase own Arts gauge by 15. For 122.7s from Start of Quest, increase own Heal amount by 28.1%. (Only once)`
            },
        lore: `Missing Lore.`
    },
{ //Ultra Relic 'Phantom Sword' - JP Complete
        id: 140081,
        name: `Ultra Relic 'Phantom Sword'`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_140080.png`,
                detailmax: `/db/Equipment/Detail/item_detail_140081.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_140080.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_140081.png`
            },
        stats:
            {
                hp: `X`,
                atk: `X`,
                def: `X`
            },
        skillset:  
            {
                skill: `60s CT - Heal target's HP by 3000 and increase target's Arts gauge by 10.`,
                break: 0
            },
        passive: 
            {
                ability1: `For 93s at Start of Quest, heal all Allies' HP by 25/s and Increase Own DEF by 21.1%. (At Passive Lv.101)`
            },
        lore: `Currently unreleased in Global.`
    },
{ //Ultra Relic 'Phantom Core' - JP Complete
        id: 140091,
        name: `Ultra Relic 'Phantom Core'`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_140090.png`,
                detailmax: `/db/Equipment/Detail/item_detail_140091.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_140090.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_140091.png`
            },
        stats:
            {
                hp: `X`,
                atk: `X`,
                def: `X`
            },
        skillset:  
            {
                skill: `95s CT - 11000% Dark DMG (Magic). Increase own DMG to weak element enemies by 66% for 20s.`,
                break: 1000
            },
        passive: 
            {
                ability1: `Reduce Own DMG resistance by 23%. For 79s after Start of Quest, increase Demon Units' DMG by 42.2%. (At Passive Lv.101)`
            },
        lore: `Currently unreleased in Global.`
    },
{ //Ultra Relic 'Volkans' - JP Complete
        id: 140101,
        name: `Ultra Relic 'Volkans'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_140100.png`,
                detailmax: `/db/Equipment/Detail/item_detail_140101.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_140100.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_140101.png`
            },
        stats:
            {
                hp: `X`,
                atk: `X`,
                def: `X`
            },
        skillset:  
            {
                skill: `60s CT - 6200% Fire DMG (Physical). Increase own DMG by 30% & Arts gauge by 1 for 15s.`,
                break: 400
            },
        passive: 
            {
                ability1: `Reduce own Status Ailments resistance by 23%. For 79s after Start of quest, increase God Units' DMG by 42.2%. (At Passive Lv.101)`
            },
        lore: `Currently unreleased in Global.`
    },
{ //Ultra Relic 'Bracchium' - Missing Lore
        id: 140111,
        name: `Ultra Relic 'Bracchium'`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_140110.png`,
                detailmax: `/db/Equipment/Detail/item_detail_140111.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_140110.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_140111.png`
            },
        stats:
            {
                hp: `X`,
                atk: `X`,
                def: `X`
            },
        skillset:  
            {
                skill: `50s CT - Increase all allies' Arts Gauge by 10. Heal all allies' HP by 700 for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `For 113.7s, increase all allies' Physical DMG by 13% and Heal Recovery by 18%. (At Passive Lv.101)`
            },
        lore: `Missing Lore.`
    },
{ //Ultra Relic 'Skeletooth' - Missing Lore
        id: 140121,
        name: `Ultra Relic 'Skeletooth'`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_140120.png`,
                detailmax: `/db/Equipment/Detail/item_detail_140121.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_140120.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_140121.png`
            },
        stats:
            {
                hp: `X`,
                atk: `X`,
                def: `X`
            },
        skillset:  
            {
                skill: `50s CT - Increase target's Arts Gauge by 10. Increase target's Physical resistance by 20% for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: `For 122.7s, increase all allies' Status resistance (Except Faint) by 20% and all allies Heal for 12.5% of DMG taken. (At Passive Lv.101)`
            },
        lore: `Missing Lore.`
    },
{ //Ultra Relic 'Periusia' - JP Complete
        id: 140131,
        name: `Ultra Relic 'Periusia'`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_140130.png`,
                detailmax: `/db/Equipment/Detail/item_detail_140131.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_140130.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_140131.png`
            },
        stats:
            {
                hp: `X`,
                atk: `X`,
                def: `X`
            },
        skillset:  
            {
                skill: `45s CT - Heal target's HP by 75%. If user is a Fire unit, heal target's HP by 100% instead.`,
                break: 0
            },
        passive: 
            {
                ability1: `For 93.7s, increase own Disease resistance by 15.0% and every 14.9s, increase all allies' Arts gauge by 7. (At Passive Lv.101)`
            },
        lore: `Currently unreleased in Global.`
    },
    { //Ultra Relic 'Frozen Twin Swords' - JP Complete
        id: 140141,
        name: `Ultra Relic 'Frozen Twin Swords'`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_140140.png`,
                detailmax: `/db/Equipment/Detail/item_detail_140141.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_140140.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_140141.png`
            },
        stats:
            {
                hp: `X`,
                atk: `X`,
                def: `X`
            },
        skillset:  
            {
                skill: `45s CT - 8000% Water DMG (Magic). Increase own ATK by 50% for 15s.`,
                break: 600
            },
        passive: 
            {
                ability1: `Increase own Magic DMG by 25% UP. For 165.5s after start of quest, Increase all Allies' Water DMG by 20%. (At Passive Lv.101)`
            },
        lore: `Currently unreleased in Global.`
    },
    { //Ultra Relic 'Diablos' - JP Complete
        id: 140151,
        name: `Ultra Relic 'Diablos'`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_140150.png`,
                detailmax: `/db/Equipment/Detail/it em_detail_140151.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_140150.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_140151.png`
            },
        stats:
            {
                hp: `X`,
                atk: `X`,
                def: `X`
            },
        skillset:  
            {
                skill: `50s CT - 8000% Dark DMG (Magic). Increase own ATK by 50% for 15s.`,
                break: 600
            },
        passive: 
            {
                ability1: `Increase own Magic DMG by 25.0%. For the first 165.5s of Quest, increase all allies' Elemental Advantage DMG by 20.0%. (At Passive Lv.101)`
            },
        lore: `Currently unreleased in Global.`
    },
{ //バルクヘルム (Monster Hunter equip) - JP Complete
        id: 150011,
        name: `バルクヘルム`,
        location: `Crossover Equip Summon: Monster Hunter Collab`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150010.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150011.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150010.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150011.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `55s CT - Negates 800 DMG taken to all allies and heal all allies' HP by 30 for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Magic resistance 10% UP.`
            },
        lore: `Currently unreleased in Global.`
    },
{ //バルクメイル (Monster Hunter equip) - JP Complete
        id: 150021,
        name: `バルクメイル`,
        location: `Crossover Equip Summon: Monster Hunter Collab`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150020.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150021.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150020.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150021.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 6500% Dark DMG (Magic). Increase enemy's DMG taken by 30% for 8s.`,
                break: 2200
            },
        passive: 
            {
                ability1: `Break Power 6% UP.`
            },
        lore: `Currently unreleased in Global.`
    },
{ //レウスSアーム (Monster Hunter equip) - JP Complete
        id: 150031,
        name: `レウスSアーム`,
        location: `Crossover Equip Summon: Monster Hunter Collab`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150030.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150031.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150030.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150031.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 6000% Fire DMG (Physical). If user is Human, increase own DMG by 20% for 5s.`,
                break: 800
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Currently unreleased in Global.`
    },
{ //レウスSフォールド (Monster Hunter equip) - JP Complete
        id: 150041,
        name: `レウスSフォールド`,
        location: `Crossover Equip Summon: Monster Hunter Collab`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150040.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150041.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150040.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150041.png`
            },
        stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
        skillset:  
            {
                skill: `40s CT - Increase all allies' Skill CT speed by 30% and Evasion rate by 10% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Skill CT speed 10% UP.`
            },
        lore: `Currently unreleased in Global.`
    },
{ //レイアRヘルム (Monster Hunter equip) - JP Complete
        id: 150051,
        name: `レイアRヘルム`,
        location: `Crossover Equip Summon: Monster Hunter Collab`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150050.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150051.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150050.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150051.png`
            },
        stats:
            {
                hp: 120,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - Heal all allies' HP by 15%.`,
                break: 0
            },
        passive: 
            {
                ability1: `Status Ailment resistance 20% UP (Except Faint).`
            },
        lore: `Currently unreleased in Global.`
    },
{ //レイアRグリーヴ (Monster Hunter equip) - JP Complete
        id: 150061,
        name: `レイアRグリーヴ`,
        location: `Crossover Equip Summon: Monster Hunter Collab`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150060.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150061.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150060.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150061.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 120
            },
        skillset:  
            {
                skill: `40s CT - Increase all allies' DMG resistance by 20% for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: `HP Recovery Amount 10% UP.`
            },
        lore: `Currently unreleased in Global.`
    },
{ //金狼剣シツライ (Monster Hunter equip) - JP Complete
        id: 150071,
        name: `金狼剣シツライ`,
        location: `Crossover Equip Summon: Monster Hunter Collab`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150070.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150071.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150070.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150071.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `30s CT - 5000% Light DMG (Magic). Increase own BE Output by 50% for 10s.`,
                break: 600
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Currently unreleased in Global.`
    },
{ //狐刀カカルクモナキ (Monster Hunter equip) - JP Complete
        id: 150081,
        name: `狐刀カカルクモナキ`,
        location: `Crossover Equip Summon: Monster Hunter Collab`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150080.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150081.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150080.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150081.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `40s CT - 888% Water DMG (Physical). If enemy is a Raid boss, increase all allies' Equipment DMG by 50% for 6s.`,
                break: 100
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Currently unreleased in Global.`
    },
{ //テスカブレイド (Monster Hunter equip) - JP Complete
        id: 150091,
        name: `テスカブレイド`,
        location: `Crossover Equip Summon: Monster Hunter Collab`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150090.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150091.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150090.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150091.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 6800% Fire DMG (Physical). 100% chance to inflict Burn on all enemies and allies. If enemy is a Raid boss, deal 9000% Fire DMG (Physical) instead.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Currently unreleased in Global.`
    },
{ //キリンRベスト (Monster Hunter equip) - JP Complete
        id: 150101,
        name: `キリンRベスト`,
        location: `Crossover Equip Summon: Monster Hunter Collab`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150100.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150101.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150100.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150101.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 120
            },
        skillset:  
            {
                skill: `5s CT - For 5s, allies gain a DMG barrier that negates 300 DMG taken from Light enemies (No duplication). `,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Currently unreleased in Global.`
    },
{ //クイーンブラスターI (Monster Hunter equip) - JP Complete
        id: 150111,
        name: `クイーンブラスターI`,
        location: `Crossover Equip Summon: Monster Hunter Collab`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 3,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150110.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150111.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150110.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150111.png`
            },
        stats:
            {
                hp: 60,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - Heal target's HP by 1000. If user is Fire, heal own HP by 50 for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Currently unreleased in Global.`
    },
{ //レウスネコブレイド (Monster Hunter equip) - JP Complete
        id: 150121,
        name: `レウスネコブレイド`,
        location: `Crossover Equip Summon: Monster Hunter Collab`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 3,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150120.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150121.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150120.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150121.png`
            },
        stats:
            {
                hp: 0,
                atk: 60,
                def: 0
            },
        skillset:  
            {
                skill: `10s CT - 1200% Fire DMG (Physical).`,
                break: 200
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Currently unreleased in Global.`
    },
{ //Scissor Blade - Complete
        id: 150131,
        name: `Scissor Blade`,
        location: `Crossover Equip Summon: Kill La Kill Collab`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150130.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150131.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150130.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150131.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 9000% Fire DMG (Physical). Increases own Arts DMG by 30% for 15s.`,
                break: 800
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The scissor blade used by Ryuko Matoi. It was made in order to fight against Goku Uniforms and can cut Life Fibers. She only carries half of the scissor and, for some reason, she is searching for the one who holds the other blade.`
    },
{ //Bakuzan - Complete
        id: 150141,
        name: `Bakuzan`,
        location: `Crossover Equip Summon: Kill La Kill Collab`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150140.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150141.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150140.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150141.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 7500% Water DMG (Physical). Increases Break power by 75% for 9s.`,
                break: 3000
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The favorite weapon of Satsuki Kiryuin. For most weapons its impossbile to cut Life Fibers, but not for Bakuzan. This incredible ability is the result of Satsuki's skill, who can unleash its fullest potential.`
    },
{ //Fight Club Goku Uniform - Complete
        id: 150151,
        name: `Fight Club Goku Uniform`,
        location: `Crossover Equip Summon: Kill La Kill Collab`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150150.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150151.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150150.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150151.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `30s CT - Increases all Allies' Arts Gauge by 30. Increases all Allies' DMG Inflicted by 20% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The Two-Star Goku Uniform that was given to Mako Mankanshoku, the president of the Fight Club. She was originally considered a No Star student but unexpectedly revealed battle powers beyond imagination when wearing this Two-Star Goku Uniform.`
    },
{ //Mako-Knuckle - Complete
        id: 150161,
        name: `Mako-Knuckle`,
        location: `Limited Crossover Event: Kill La Kill`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150160.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150161.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150160.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150161.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `6s CT - 1200% Light DMG (Physical).`,
                break: 200
            },
        passive: 
            {
                ability1: `Own Skill CT 15% DOWN.`
            },
        lore: `The weapon chosen by Mako Mankanshoku, a student at Honnoji Academy. When wearing the Fight Club-Spec Goku Uniform, she puts this knuckle on her     to punch her enemies without mercy. Though a simple weapon that merely strengthens her fist's impact, its destructive power is extremely high.`
    },
{ //Senketsu - Complete
        id: 150171,
        name: `Senketsu`,
        location: `Limited Crossover Event: Kill La Kill`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150170.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150171.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150170.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150171.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `50s CT - Reduces all Allies' DMG Taken by 25% for 15s. Heals all Allies' HP by 30/s for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increases Critical DMG by 10%.`
            },
        lore: `The sentient black sailor uniform created by Ryuko Matoi's father, Isshin Matoi. it is made purely from Life Fiber which mere mortals cannot wear. It awakens and unleashes its power by absorbing Ryuko's blood. Only Ryuko can hear Senketsu's voice.`
    },
{ //Junketsu - Complete
        id: 150181,
        name: `Junketsu`,
        location: `Limited Crossover Event: Kill La Kill`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150180.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150181.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150180.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150181.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `55s CT - Negates 800 DMG Dealt to all Allies' for 15s. Reduces all Allies' Light and Dark Elemental DMG Taken by 20% for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increases DMG by 20% when Near-Death.`
            },
        lore: `Another Kamui created by Ryuko Matoi's father, Isshin Matoi. Like Senketsu, it is made only from Life Fiber, which mere mortals cannot wear. the burden for the wearer, Satsuki Kiryuin, is so severe she cannot wear it for long.`
    },
{ //Hero Cape - Complete
        id: 150191,
        name: `Hero Cape`,
        location: `Limited Crossover Event: One Punch Man`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150190.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150191.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150190.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150191.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `40s CT - Reduces all Allies' DMG Taken by 25% for 10s. If User is Human, Avoids Paralyze and Freeze effects for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Attacks ignore 20% of Enemy's Defense.`
            },
        lore: `The cape that Saitama, a professional Hero, wears during his superhero activities.`
    },
{ //Cyborg Arm - Complete
        id: 150201,
        name: `Cyborg Arm`,
        location: `Limited Crossover Event: One Punch Man`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150200.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150201.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150200.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150201.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `35s CT - 6600% Dark DMG (Physical). 70% chance of Blinding enemy.`,
                break: 800
            },
        passive: 
            {
                ability1: `DMG 10% UP to Giant Boss.`
            },
        lore: `The mechanical arm of the S-Class Hero Genos. Countless weapons are installed inside.`
    },
{ //Hero Gloves - Complete
        id: 150211,
        name: `Hero Gloves`,
        location: `Crossover Equip Summon: One Punch Man`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150210.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150211.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150210.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150211.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 10000% Light DMG (Physical). Increases own ATK by 120% & reduces Skill CT by 80% for 10s.`,
                break: 3000
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The red gloves that Saitama, a professional Hero, wears during his superhero activities.`
    },
{ //Hero Boots - Complete
        id: 150221,
        name: `Hero Boots`,
        location: `Crossover Equip Summon: One Punch Man`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150220.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150221.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150220.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150221.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 120
            },
        skillset:  
            {
                skill: `30s CT - Negates 800 DMG Dealt to all Allies' for 8s. Reduces all Allies' DMG Taken by 20% for 20s (only once).`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The red boots that Saitama, a professional Hero, wears during his superhero activities.`
    },
{ //Esper Dress - Complete
        id: 150231,
        name: `Esper Dress`,
        location: `Crossover Equip Summon: One Punch Man`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150230.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150231.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150230.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150231.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `35s CT - 6000% Earth DMG (Magic). 100% Chance of Paralyzing enemy.`,
                break: 1800
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The trademark black dress worn by the S-Class Rank-2 Hero, Terrible Tornado.`
    },
{ //Type 30 Arisaka Rifle - Complete
        id: 150241,
        name: `Type 30 Arisaka Rifle`,
        location: `Crossover Equip Summon: One Punch Man`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150240.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150241.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150240.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150241.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 8300% Fire DMG (Physical). Reduces enemy's DEF by 50% for 15s.`,
                break: 900
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Saichi Sugimoto's trusted firearm. It was issued for the Imperial Japanese Army and was well regarded for its good accuracy and range. By attaching a bayonet to the end of the gun, it can also be used in close-range combat.`
    },
{ //Sugimoto's Army Cap - Complete
        id: 150251,
        name: `Sugimoto's Army Cap`,
        location: `Crossover Equip Summon: One Punch Man`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150250.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150251.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150250.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150251.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `35s CT - Reduces all Allies' DMG Taken by 10% for 10s. Reduces all Allies' Physical DMG Taken by 30% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The cap that Sugimoto always wears. It was worn by those in the Imperial Japanese Army, so it was quite common. For whatever reason, Sugimoto almost never takes his cap off.`
    },
{ //Asirpa's Bow - Complete
        id: 150261,
        name: `Asirpa's Bow`,
        location: `Crossover Equip Summon: Golden Kamuy`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150260.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150261.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150260.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150261.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `35s CT - 4800% Light DMG (Physical). 90% chance of Poisoning enemy.`,
                break: 900
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The bow favored by Asirpa. Although it is very flexible, it is still quite tough. Poison coats the tips of the arrowheads. Once pierced by one of these poisoned arrows, even a brown bear would die within ten steps.`
    },
{ //Asirpa's Bandana - Complete
        id: 150271,
        name: `Asirpa's Bandana`,
        location: `Limited Crossover Event: Golden Kamuy`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150270.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150271.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150270.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150271.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 120
            },
        skillset:  
            {
                skill: `40s CT - Negates 700 DMG Dealt to all Allies' for 8s (Only once). Increases all Allies' Freeze Resistance by 50% for 8s. Heals all Allies' HP by 30 for 8s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Regenerates HP by 6.`
            },
        lore: `The bandana which Asirpa, the Ainu girl, always wears. Ainu patterns are embroided into its blue cloth.`
    },
{ //Izumi no Kami Kanesada - Complete
        id: 150281,
        name: `Izumi no Kami Kanesada`,
        location: `Crossover Equip Summon: Golden Kamuy`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150280.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150281.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150280.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150281.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 10000% Dark DMG (Physical). Increases own Arts gauge by 50.`,
                break: 1000
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The sword favored by Toshizo Hijikata. It is an absolute masterpiece of a sword that Hijikata used since his Shinsengumi days. The sword was even kept in a bank safe as a word of art. It is said that Hijikata raided the bank to take back his sword.`
    },
{ //Hijikata's Gun - Complete
        id: 150291,
        name: `Hijikata's Gun`,
        location: `Limited Crossover Event: Golden Kamuy`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150290.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150291.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150290.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150291.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 6600% Dark DMG (Physical) Increases own CRI rate by 50% & CRI DMG by 20% for 6s.`,
                break: 600
            },
        passive: 
            {
                ability1: `Accuracy rate 20% UP.`
            },
        lore: `A cutting-edge shotgun that Hijikata obtained from a foreign source. It contains up to 14 bullets and can be loaded with one hand, making it ideal for long gunfights. These features make it a very powerful weapon for close-range battles.`
    },
{ //Snippity-Snips - Complete
        id: 150301,
        name: `Snippity-Snips`,
        location: `Crossover Equip Summon: Kill La Kill`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150300.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150301.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150300.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150301.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `70s CT - 12000% Fire DMG (Physical). Reduces enemies' Physical Resistance by 20% for 10s. If User is Human, increases own BE output by 50% for 10s.`,
                break: 1500
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The true form of Scissor Blade, a weapon created by Ryuko Matoi's father, Isshin Matoi. By cutting the Life Fiber from each side at the same time, it is possible to seal the strong regenerating power of the Life Fiber.`
    },
{ //Guts - Complete
        id: 150311,
        name: `Guts`,
        location: `Crossover Equip Summon: Kill La Kill`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150310.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150311.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150310.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150311.png`
            },
        stats:
            {
                hp: 500,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `100s CT - 2000% Light DMG (Physical). Increases all Allies' MAX HP by 30% for 30s.`,
                break: 1200
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The pet dog of the Mankanshoku family. It wears a hoodie. He gets along well with Mataro, Mako Mankanshoku's younger brother and they often hang out together. He was named "Guts" because of how he always gorges on food.`
    },
{ //Spiked Bat Missile - Complete
        id: 150321,
        name: `Spiked Bat Missile`,
        location: `Limited Crossover Event: Kill La Kill`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150320.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150321.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150320.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150321.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 6000% Light DMG (Physical). 50% chance of Fainting enemy. Increases DMG to enemy by 15% for 8s.`,
                break: 900
            },
        passive: 
            {
                ability1: `Auto-fills Arts gauge by 1 when Near-Death.`
            },
        lore: `One of the weapons that Mako Mankanshoku used when she was the leader of the Fight Club. Also usable as a close combat weapon, this bat shoots powerful missiles from the end that leave a trail of thick smoke wherever they land.`
    },
{ //Power Suit - Missing Lore
        id: 150331,
        name: `Power Suit`,
        location: `Crossover Equip Summon: Godzilla`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150330.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150331.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150330.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150331.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `60s CT - Negates target's DMG taken for a total of 3000 & Increases Status Ailments (except Faint) resistance by 100% for 15s (Only once).`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
{ //Vulture - Complete
        id: 150341,
        name: `Vulture`,
        location: `Crossover Equip Summon: Godzilla`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150340.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150341.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150340.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150341.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `60s CT - 5000% Non-Elemental DMG (Physical). Increases all allies' Equipment CT speed & DMG by 35% for 12s.`,
                break: 1000
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A war machine discovered in some ruins. A modified version of the Power Suit believed to be specialized for battle. Equipped with a remarkable flight ability and powerful artillery, its offensive capability is far beyond its original state. However, this makes controlling it that much more difficult, and those lacking in natural aptitude are unable to to handle it at all.`
    },
{ //Houtan Bow - Complete
        id: 150351,
        name: `Houtan Bow`,
        location: `Crossover Equip Summon: Godzilla`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150350.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150351.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150350.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150351.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 2500% Non-Elemental DMG (Physical). 60% chance of Poisoning enemy.`,
                break: 800
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A weapon recovered from a newly discovered ruin. The arrowhead has a unique shape and is made of a strange ore called nanometal. Its unique properties grant it strength far beyond that of a standard arrow.`
    },
{ //Hoverbike - Complete
        id: 150361,
        name: `Hoverbike`,
        location: `Crossover Equip Summon: Godzilla`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150360.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150361.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150360.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150361.png`
            },
        stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
        skillset:  
            {
                skill: `30s CT - Increases own Arts gauge by 30. Increases Skill CT speed & Accuracy by 50% for 8s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A vehicle that flies through the air. With its real propulsion device and side attitude control devices, it can be maneuvered in a wide variety of ways. The cannon on the front gives it even greater utility, letting it be used for combat as well as for scouting.`
    },
{ //King Ghidorah - Complete
        id: 150371,
        name: `King Ghidorah`,
        location: `Crossover Equip Summon: Godzilla`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150370.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150371.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150370.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150371.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `100s CT - 12000% Non-Elemental DMG (Magic). Reduces enemy's Magic resistance by 40% for 15s.`,
                break: 1500
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A device that allows one to summon the bringer of doom, King Ghidorah. With strength that rivals Godzilla, the mere mention of its forbidden name sends a chill down the spine. It is said to live in another dimension but can appear in other worlds at any time to seal their fate.`
    },
{ //Rimuru's Sword - Complete
        id: 150381,
        name: `Rimuru's Sword`,
        location: `Crossover Equip Summon: That Time I Got Reincarnated as a Slime`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150380.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150381.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150380.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150381.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `120s CT - 12000% Water DMG (Physical). If Demon, increases DMG by 50% for 10s.`,
                break: 2000
            },
        passive: 
            {
                ability1: ``
            },
        lore: `This is the Katana made for Rimuru by Kurobei, a Kijin blacksmith. It's highly efficient and even stops Gazel Dwargo's attack who is dwarf king called a hero. Although Rimuru is learning sword skills under Hakuro, and old Kijin swordmaster also known as a savant, Rimuru's skills are still developing.`
    },
{ //Milim's Necklace - Complete
        id: 150391,
        name: `Milim's Necklace`,
        location: `Crossover Equip Summon: That Time I Got Reincarnated as a Slime`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150390.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150391.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150390.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150391.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `15s CT - 1000% Fire DMG (Magic). 80% chance of Burning enemy.`,
                break: 400
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The necklace that Milim, also known as "The Destroyer", wears. It is attached to her clothes, so it can also be counted as part of her clothes. Although it is made simple by just putting a string through three colored comma-shaped beads, Milim's fashion itself is simple, so it seems that this necklace is just her taste.`
    },
{ //Milim's Clothes - Complete
        id: 150401,
        name: `Milim's Clothes`,
        location: `Crossover Equip Summon: That Time I Got Reincarnated as a Slime`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150400.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150401.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150400.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150401.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `45s CT - Increases all Allies' Arts Gauge by 5 for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The clothes that Milim, the oldest Demon Lord, wears. The key point to her clothes is that it is quite revealing. Having both Demon Lord taste and childish taste to it, it suits Milim very well.`
    },
{ //Benimaru's Clothes - Complete
        id: 150411,
        name: `Benimaru's Clothes`,
        location: `Crossover Equip Summon: That Time I Got Reincarnated as a Slime`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150410.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150411.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150410.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150411.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 120
            },
        skillset:  
            {
                skill: `50s CT - Reduces all Allies' DMG Taken by 25% for 10s. Increases all Allies' Critical Resistance by 10% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The clothes that Benimaru wears. It was made for the battle between the Orc Lord by dwarf blacksmiths and Shuna, a Kijin girl. Hell Moth cocoon and Magicules are woven into the cloth, so it is long lasting and has high protection.`
    },
{ //Rimuru's Clothes - Complete
        id: 150421,
        name: `Rimuru's Clothes`,
        location: `Limited Crossover Event: That Time I Got Reincarnated as a Slime`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150420.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150421.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150420.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150421.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 5800% Water DMG (Magic). Increases Water Elemental units' ATK by 20% for 10s.`,
                break: 1000
            },
        passive: 
            {
                ability1: `Increases DMG Dealt to Fire Elemental Units by 5%.`
            },
        lore: `The clothes that Rimuru wears during a fight. It was made by dwarf blacksmiths, Garm and Dord, and a Kijin, Shuna. It contains Magicule, so it is stronger than regular cloth and has high protection.`
    },
{ //Magic-seal Mask - Complete
        id: 150431,
        name: `Magic-seal Mask`,
        location: `Limited Crossover Event: That Time I Got Reincarnated as a Slime`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150430.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150431.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150430.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150431.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `35s CT - Increases Target's Arts gauge by 15 for 5s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Reduces Magical DMG Taken by 5%.`
            },
        lore: `The mask that Shizue Izawa, a human from a different world and also known as "Conqueror of Flames" wore. After her death, Rimuru who is also a human from the same world of her has been wearing it. It can seal magic powers. Thus, when Rimuru wears it his Magicules are sealed and will not be recognized as a monster.`
    },
{ //Benimaru's Sword - Complete
        id: 150441,
        name: `Benimaru's Sword`,
        location: `Limited Crossover Event: That Time I Got Reincarnated as a Slime`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150440.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150441.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150440.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150441.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 6000% Fire DMG (Physical). Increases own Accuracy rate by 30% for 8s.`,
                break: 2400
            },
        passive: 
            {
                ability1: `ATK 5% UP.`
            },
        lore: `The Katana that Benimaru, a young Kijin, uses. It was made for the battle between the Orc Lord by Kurobei, and excellent blacksmith. Benimaru's sword skills are extremely high since he had been learning sword skills from the swordmaster Hakuro from childhood.`
    },
{ //Goblin Slayer's Sword - Complete
        id: 150451,
        name: `Goblin Slayer's Sword`,
        location: `Crossover Equip Summon: Goblin Slayer`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150450.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150451.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150450.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150451.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `120s CT - 10000% Dark DMG (Physical). If Human, increase DMG & Break power by 40% for 8s.`,
                break: 3000
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A cheap sword used by Goblin Slayer. It's rather short, but this makes it ideal to use in the narrow caves and holes where goblins live. Not to be sniffed out by the enemies, he never cleans it, and when it becomes unusable due to accumulated blood and flesh, he simply abandons it.`
    },
{ //Goblin Slayer's Helm - Complete
        id: 150461,
        name: `Goblin Slayer's Helm`,
        location: `Crossover Equip Summon: Goblin Slayer`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150460.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150461.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150460.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150461.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `50s CT - Increases all Allies' Critical Resistance by 20% for 10s. Reduces all Allies' DMG Taken by 30% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `This is the helm Goblin Slayer wears every day. Since he does the maintenance by himself, the broken horns remain unreplaced, and the scratches unbuffed. It hides his face completely, but the gaps allow his baleful eyes to be seen.`
    },
{ //Goblin Slayer's Dagger - Missing Lore
        id: 150471,
        name: `Goblin Slayer's Dagger`,
        location: `Limited Crossover Event: Goblin Slayer`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150470.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150471.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150470.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150471.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `20s CT - 2800% Dark DMG (Physical).`,
                break: 800
            },
        passive: 
            {
                ability1: [`Heals own HP by 1% of DMG to enemy when Near-Death.`, `DMG to Demons 10% UP.`, `Break power 2% UP.`, `Dark resistance 5% UP.`, `Disease resistance 10% UP.`],
                ability2: [`None.`, `ATK 10% UP when Near-Death.`, `DMG to Humans 10% UP.`, `Blind resistance 10% UP.`],
                ability3: [`None.`, `DEF 10% UP when Near-Death.`, `HP Auto-Recovery by 10 when Near-Death.`]
            },
        lore: `Missing Lore.`
    },
{ //Sounding Staff - Complete
        id: 150481,
        name: `Sounding Staff`,
        location: `Crossover Equip Summon: Goblin Slayer`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150480.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150481.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150480.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150481.png`
            },
        stats:
            {
                hp: 500,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `80s CT - Adds a token to revive Target Unit with 50% HP for 120s (only once). Increases Target Unit's Own Heal amount taken by 20% for 120s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Priestess carries this holy staff with her daily. It is topped with the sacred symbol of the Earth Mother, and the staff itself is the same height as Priestess. When she uses her miracles, the gold rings sparkle with holy power.`
    },
{ //Priestess' Robe - Complete
        id: 150491,
        name: `Priestess' Robe`,
        location: `Crossover Equip Summon: Goblin Slayer`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150490.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150491.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150490.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150491.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `40s CT - Increases all Allies' Arts Gauge by 30. Increases all Allies' amount Healed by 50% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The sacred garb worn by Priestess. This is the usual robe worn by the devotees of the Earth Mother, adorned with designs fitting a holy servant of the Goddess. After Goblin Slayer recommended it, she began to wear chainmail underneath this robe. Now, she is deeply attached to it.`
    },
{ //Priestess' Hat - Complete
        id: 150501,
        name: `Priestess' Hat`,
        location: `Limited Crossover Event: Goblin Slayer`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150500.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150501.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150500.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150501.png`
            },
        stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
        skillset:  
            {
                skill: `45s CT - Increases target's Arts gauge by 1 and increases target's Healing actions by 50% (except lifesteal) for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increases All Allies' Heal Amount by 5% (except lifesteal).`
            },
        lore: `This is a hat worn by those who dedicate their lives to the Earth Mother. Just like the robe, this hat is protected by divine power and helps defend the holy believer’s good works. The design matches the robe, with a clean, simple appearance.`
    },
{ //Elven Bow - Complete
        id: 150511,
        name: `Elven Bow`,
        location: `Limited Crossover Event: Goblin Slayer`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150510.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150511.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150510.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150511.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `65s CT - 8000% Earth DMG (Physical). Increases own Arts gauge by 2 for 5s.`,
                break: 1200
            },
        passive: 
            {
                ability1: `Own Skill CT 5% DOWN.`
            },
        lore: `This long-bow of durable wood is used by the High Elf Archer. The Archer herself often says that "Any sufficiently advanced skill is indistinguishable from magic" and her incredibly skilled shooting proves it right, as her arrows seem to move at her command.`
    },
{ //Elven Cloth - Missing Lore
        id: 150521,
        name: `Elven Cloth`,
        location: `Crossover Equip Summon: Goblin Slayer`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150520.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150521.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150520.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150521.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `30s CT - Increases all Allies' Skill CT Speed by 60% for 15s. Humans, Spirits, or Dragons get an additional 20%.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The High Elf Archer wears this clothing on her adventures. It is light and well fitted, but it is made from materials that offer good defense in battle. In addition, her mantle hides the obsidian knives sheathed at her hips.`
    },
{ //Demon Sword 'Desiderium' - Complete
        id: 150531,
        name: `Demon Sword 'Desiderium'`,
        location: `Limited Crossover Event: Goblin Slayer`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150530.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150531.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150530.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150531.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `30s CT - 3000% Dark DMG (Magic). Increases Own ATK by 30% for 6s.`,
                break: 900
            },
        passive: 
            {
                ability1: `Increases Seal Time Reduction by 10%. Increases Seal Evasion Rate by 10%.`
            },
        lore: `This demon sword was sealed in an ancient ruin. The sword itself is mediocre, but it is filled with magic power which allows the wielder to cut only what really has to be cut. An old story even narrates of an adventurer who slew many monsters killing only the evil spirits possessing them, but nobody knows if it is true. However, judging by the mysterious glow of the blade, everyone would believe that story is true.`
    },
{ //Hellish Blizzard's Necklace - Complete
        id: 150541,
        name: `Hellish Blizzard's Necklace`,
        location: `Crossover Equip Summon: One Punch Man`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150540.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150541.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150540.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150541.png`
            },
        stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
        skillset:  
            {
                skill: `40s CT - Increases all Allies' Magical DMG by 20% and Equipment DMG by 30% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The necklace worn by the B-Class Rank-1 Hero, Hellish Blizzard.`
    },
{ //Hellish Blizzard's Coat - Complete
        id: 150551,
        name: `Hellish Blizzard's Coat`,
        location: `Limited Crossover Event: One Punch Man`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150550.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150551.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150550.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150551.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 5800% Water DMG (Magic). 50% chance of Freezing Enemy.`,
                break: 1500
            },
        passive: 
            {
                ability1: `Increases Light Elemental Resistance by 5%.`
            },
        lore: `The coat worn by the B-Class Rank-1 Hero, Hellish Blizzard.`
    },
{ //Hellish Blizzard's Dress - Complete
        id: 150561,
        name: `Hellish Blizzard's Dress`,
        location: `Crossover Equip Summon: One Punch Man`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150560.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150561.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150560.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150561.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 8000% Water DMG (Magic). Increases Own Water DMG by 40% and Reduces Enemy's Magic Resistance by 25% for 10s.`,
                break: 1300
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The dress worn by the B-Class Rank-1 Hero, Hellish Blizzard.`
    },
{ //Sonic's Shuriken - Complete
        id: 150571,
        name: `Sonic's Shuriken`,
        location: `Limited Crossover Event: One Punch Man`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150570.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150571.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150570.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150571.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `10s CT - 2800% Dark DMG (Physical). 50% chance of reducing enemy's ATK by 10% for 5s.`,
                break: 600
            },
        passive: 
            {
                ability1: `DMG to Humans 10% UP.`
            },
        lore: `The shuriken of Speed-o'-Sound Sonic. Using this weapon, he unleashes his attack "Exploding Shuriken".`
    },
{ //Sonic's Katana - Complete
        id: 150581,
        name: `Sonic's Katana`,
        location: `Crossover Equip Summon: One Punch Man`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150580.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150581.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150580.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150581.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `30s CT - 6400% Dark DMG (Physical). If Human, increases own Arts gauge by 3 for 10s.`,
                break: 700
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The katana of Speed-o'-Sound Sonic. This extremely sharp blade is the weapon that fulfills Sonic's power.`
    },
{ //Sonic's Mask - Complete
        id: 150591,
        name: `Sonic's Mask`,
        location: `Crossover Equip Summon: One Punch Man`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150590.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150591.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150590.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150591.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `30s CT - Negates 3500 DMG Dealt to Target for 12s. Increases Target's Physical Evasion Rate by 35% for 12s (only once).`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The trademark mask of Speed-o'-Sound Sonic.`
    },
{ //Sonic's Scarf - Complete
        id: 150601,
        name: `Sonic's Scarf`,
        location: `Limited Crossover Event: One Punch Man`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150600.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150601.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150600.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150601.png`
            },
        stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
        skillset:  
            {
                skill: `30s CT - Increases Target's Critical Rate by 100% for 8s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increases Physical ATK Evasion Rate by 5%.`
            },
        lore: `The scarf of Speed-o'-Sound Sonic.`
    },
{ //Crunchyroll-Hime's Bow - Missing Lore
        id: 150611,
        name: `Crunchyroll-Hime's Bow`,
        location: `Crossover Equip Summon: Crunchyroll`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150610.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150611.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150610.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150611.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `20s CT - 5000% Fire DMG (Physical). Increases own Arts gauge by 10.`,
                break: 300
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
{ //Crunchyroll-Hime's Boots - Complete
        id: 150621,
        name: `Crunchyroll-Hime's Boots`,
        location: `Crossover Equip Summon: Crunchyroll`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150620.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150621.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150620.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150621.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `45s CT - Increases Target's DMG by 30% and Arts gauge by 4 for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `You've never seen more sturdy boots! Hime moves faster than the Speed of Japan, and some strong boots are necessary for the amount of land she's covering. Wearing these boots will make you feel like you can tun non-stop for days, without breaking a sweat.`
    },
{ //Crunchyroll-Hime's Clothes - Complete
        id: 150631,
        name: `Crunchyroll-Hime's Clothes`,
        location: `Crossover Equip Summon: Crunchyroll`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150630.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150631.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150630.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150631.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 4000% Fire DMG (Magic). Reduces Enemies' Burn Resistance and Enemies' Fire Resistance by 15% for 20s.`,
                break: 500
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Hime watched one-too-many isekai anime, and decided she needed adventurer's clothing! Sudachi, Hime's younger sister, spent weeks making an outfit for her elder sister. The costume is imbued with sisterly-love, and anyone wearing it will feel a boost of energy!`
    },
{ //Ryuko's Bomber Jacket - Complete
        id: 150641,
        name: `Ryuko's Bomber Jacket`,
        location: `Crossover Equip Summon: Kill La Kill`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150640.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150641.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150640.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150641.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `45s CT - Reduces all Allies' Fire Elemental DMG Taken and Physical DMG Taken by 25% for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The favorite jacket of Ryuko Matoi, a girl who transferred to Honnouji Academy. Not only is it her favourite outfit, but it is also made strong and firm despite not using Life Fibers.`
    },
{ //Nudist Beach Battle Uniform - Complete
        id: 150651,
        name: `Nudist Beach Battle Uniform`,
        location: `Crossover Equip Summon: Kill La Kill`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150650.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150651.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150650.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150651.png`
            },
        stats:
            {
                hp: 120,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - Heals all Allies' HP by 80/s for 10s. If ally's HP is under 50% then HP recovers by an additional 40/s. Increases all allies' Freeze and Paralyze resistance by 100%.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The battle uniform (female ver.) of Nudist Beach, an organization that opposes the threat of Life Fibers. Being a rebel organization, they hardly or never wear any clothes and instead wear this battle uniform and fight to free humans from clothes.`
    },
{ //Priestess' Boots - Complete
        id: 150661,
        name: `Priestess' Boots`,
        location: `Crossover Equip Summon: Goblin Slayer`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150660.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150661.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150660.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150661.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `30s CT - Mode Changes: Arts Mode: Auto-fills Target's Arts Gauge by 8 for 15s. DEF Mode: Reduces Target's DMG taken by 10% and Dark Elemental DMG taken by 10% for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `These are the boots of the Priestess, who dedicates her life to the Earth Mother. Once saved by Goblin Slayer, she followed his advice and purchased useful equipment. These boots fit her very well, and she takes excellent care of it. These beautiful boots representing her dignity as a priestess tells us how much her and Goblin Slayer are prepared for their adventures.`
    },
{ //Goblin Slayer's Shield - Complete
        id: 150671,
        name: `Goblin Slayer's Shield`,
        location: `Limited Crossover Event: Goblin Slayer`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150670.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150671.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150670.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150671.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `40s CT - Reduces Target's DMG Taken by 15% and Physical DMG Taken by 15% for 10s. If target is Near-Death, reduces Target's Physical DMG Taken by an additional 20% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increases DEF by 20% when unit is Negating DMG.`
            },
        lore: `This is the small shield that a man called Goblin Slayer favored. At first glance, it looks like an ordinary shield, but it is excellent fighting in small places such as tunnels. It is convenient in areas with obstacles and also to be used as a weapon. Thanks to its high versatility, it is perfect for Goblin Slayer, who likes simple but strong equipment.`
    },
{ //Small Shield - Complete
        id: 150681,
        name: `Small Shield`,
        location: `Limited Crossover Event: The Rising of the Shield Hero`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150680.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150681.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150680.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150681.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `20s CT - Reduces all Allies' DMG Taken by 20% for 5s. Reduces all Allies' Earth Elemental DMG Taken by 20% for 5s. Less chance of getting knocked back for 5s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Own DEF 10% UP.`
            },
        lore: `The sacred treasure that Naofumi Iwatani, the Shield Hero, received as one of the Four Holy Heroes. It is one of the Legendary Weapons that is passed to Melromarc's protectors. It enhances in proportion to the Hero's level and there are various ways of enhancing its abilities. It is possible to insert materials and recipes into the shield to enhance it further. the shield also has a translator, enabling Naofumi to understand the languages of other worlds.`
    },
{ //True Small Shield - Complete
        id: 150691,
        name: `True Small Shield`,
        location: `Alchemist - Exchange via Rainbow Gems (Only during Shield Hero crossover)`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150690.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150691.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150690.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150691.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `45s CT - Increases Target's DEF by 60%. Reduces Target's DMG Taken by 40% for 10s. Heals Target's HP by 10% of DMG Received after use for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `When equipped by Naofumi, Increases MAX HP by 25% and Auto-Fills Arts Gauge by 1 (only once).`,
                ability2: `Increases HP Regeneration by 4.`
            },
        lore: `The sacred treasure that Naofumi Iwatani, the Shield Hero, received as one of the Four Holy Heroes. It is one of the Legendary Weapons that is passed to Melromarc's protectors. It enhances in proportion to the Hero's level and there are various ways of enhancing its abilities. It is possible to insert materials and recipes into the shield to enhance it further. the shield also has a translator, enabling Naofumi to understand the languages of other worlds.`
    },
{ //Raphtalia's Sword - Complete
        id: 150701,
        name: `Raphtalia's Sword`,
        location: `Limited Crossover Event: The Rising of the Shield Hero`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150700.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150701.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150700.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150701.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `25s CT - 3000% Light DMG (Physical). Increases own Arts gauge by 15.`,
                break: 300
            },
        passive: 
            {
                ability1: `Own ATK 10% UP.`
            },
        lore: `Raphtalia's Sword of choice. She changed to this sword from her knife when she leveled up. She got it at the blacksmith that owed Naofumi Iwatani favors. Made out of magical iron, she has used it to protect Naofumi by slashing any enemy that stands in their way.`
    },
{ //True Raphtalia's Sword - Missing Lore
        id: 150711,
        name: `True Raphtalia's Sword`,
        location: `Alchemist - Exchange via Rainbow Gems (Only during Shield Hero crossover)`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150710.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150711.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150710.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150711.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `35s CT - 4500% Light DMG (Physical). Increases own ATK by 100% & reduces own Skill CT by 100% for 8s.`,
                break: 700
            },
        passive: 
            {
                ability1: `When equipped by Raphtalia, DMG & CRI rate 30% UP (Only once).`,
                ability2: `Skill CT speed 10% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Raphtalia's Bracelet - Complete
        id: 150721,
        name: `Raphtalia's Bracelet`,
        location: `Crossover Equip Summon: The Rising of the Shield Hero`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150720.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150721.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150720.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150721.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 120
            },
        skillset:  
            {
                skill: `25s CT - Reduces all Allies' Physical DMG Taken by 20% for 10s. Increases all Allies' Poison and Disease resistance by 100% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Raphtalia's bracelet that Naofumi Iwatani made for her. He endowed it with magic-enhancing powers.`
    },
{ //Filo's Hairpin - Complete
        id: 150731,
        name: `Filo's Hairpin`,
        location: `Crossover Equip Summon: The Rising of the Shield Hero`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150730.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150731.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150730.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150731.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 10000% Earth DMG (Magic). 70% chance of Paralyzing Enemy. Increases own Arts Gauge by 25, if Enemy is Paralyzed from this equipment then Increase by an additional 25.`,
                break: 800
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Filo's hair accessory that Naofumi Iwatani made for her. He endowed it with speed increasing powers and chose to make it a hairpin since it was unlikely to fall off when Filo transforms... so he says.`
    },
{ //Melty's Dress - Complete
        id: 150741,
        name: `Melty's Dress`,
        location: `Crossover Equip Summon: The Rising of the Shield Hero`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150740.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150741.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150740.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150741.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `40s CT - Auto-fills all Allies' Arts gauge by 6 for 7s. Reduces all Allies' DMG from enemy by 10% for 7s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Melty's plain dresswear of choice. Its elegant design perfectly matches the second princess' noble tastes.`
    },
{ //Puu - Complete
        id: 150751,
        name: `Puu`,
        location: `Crossover Equip Summon: YuYu Hakusho`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150750.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150751.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150750.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150751.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `40s CT - Auto-fills target's Arts gauge by 5 & HP Auto-recovery by 200 for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Own DEF 5% UP.`
            },
        lore: `A Spirit Realm beast that uses Yusuke Urameshi's heart as his life force. His cute appearance belies his immeasurable potential. Since Puu's life is linked to his master Yusuke's, changes in Yusuke's condition are also reflected in Puu's body. This helps Puu notice right away when there is danger, so that he can fly straight to his master's side. Puu is greatly influenced by his master's heart, leading him to do things like using barriers to protect his allies form evil Demonic Energy.`
    },
{ //The Sword of Trials - Complete
        id: 150761,
        name: `The Sword of Trials`,
        location: `Limited Crossover Event: YuYu Hakusho`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150760.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150761.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150760.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150761.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `20s CT - 2500% Light DMG (Magic). Increases own Arts gauge by 10.`,
                break: 300
            },
        passive: 
            {
                ability1: `Skill DMG 10% UP.`
            },
        lore: `The shapeshifting sword given to Kuwabara by the Beautiful Suzuki. At first glance, it just looks like a sword handle, but it was made with a type of plant from the Demon World which grows by absorbing energy from its surroundings, and the handle can take the form of a powerful blade in response to the user's energy and emotions. Kuwabara was afraid of the sword at first, but when he overcame that fear, he obtained a blade of unparalleled strength. However, the user of the blade must pay a dangerous price for its power.`
    },
{ //Kurama's Whip - Complete
        id: 150771,
        name: `Kurama's Whip`,
        location: `Crossover Equip Summon: YuYu Hakusho`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150770.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150771.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150770.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150771.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 4000% Earth DMG (Magic). 70% chance of Poisoning enemy. Increases DMG to enemy by 20% for 10s. If enemy is Poisoned by this equip, extra 20%.`,
                break: 400
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A cut rose that turns into a whip when Kurama imbues it with Demonic Energy. The whip has the lovely fragrance of a rose, and its tip pierces through enemies like a thorn. Kurama can even detect the location of his enemies using the scent coming from the whip. With the way Kurama uses fragrance to control fights and sometimes even manipulates his own hair as a whip, it would not be exaggeration to say that his striking appearance is just like that of a rose.`
    },
{ //Hiei's Sword - Complete
        id: 150781,
        name: `Hiei's Sword`,
        location: `Crossover Equip Summon: YuYu Hakusho`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150780.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150781.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150780.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150781.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 7000% Dark DMG (Physical). Increases own CRI DMG by 50% for 15s. When this attack is Critical, increase own Arts gauge by 50 (Only 1 HIT).`,
                break: 600
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The sword used by Hiei, the bandit who acquired the Jagan Eye. Its edge is sharp even without the power of Demonic Energy. When coupled with Hiei's expert sword technique, it can slice through all enemies in an instant. Hiei placed so much trust in this sword that when its blade was broken during a battle, it became a vessel for the Sword of Darkness Flame using Hiei's fiery demonic energy. Hiei's swordsmanship would not be the same without this sword. Although it is unclear who the swordsmith is, this sturdy and streamlined blade is quite apt for the solitary Hiei.`
    },
{ //Older Toguro - Complete
        id: 150791,
        name: `Older Toguro`,
        location: `Crossover Equip Summon: YuYu Hakusho`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150790.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150791.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150790.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150791.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `35s CT - 6000% Dark DMG (Physical). Increases own Physical DMG by 35% for 10s. When own Arts gauge is over 100, increases own Dark DMG by 35% for 10s.`,
                break: 800
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The elder of the two Toguro brothers. He, like his brother, is a Yokai who used to be a human. He has the power to shapeshift, manipulating his body to use it as a weapon, as well as an incredible ability to heal. He sometimes turns his own body into a sword or shield so that his younger brother can use him to torment enemies. The perfect synchronization between the two brothers is a result of their many years together, and now that they have become Yokai, their connection is only growing stronger. Of course, the elder Toguro brother is incredibly powerful even when on his own.`
    },
{ //Type 30 Bayonet - Complete
        id: 150801,
        name: `Type 30 Bayonet`,
        location: `Crossover Equip Summon: Golden Kamuy`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150800.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150801.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150800.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150801.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 4900% Fire DMG (Physical). Reduces enemy's Physical & Fire resistance by 30% for 10s.`,
                break: 800
            },
        passive: 
            {
                ability1: ``
            },
        lore: `An Imperial Army bayonet used by Sugimoto Saichi. While it can be attached to the tip of a rifle, it can also be used as a handled sword or a regular knife too. This bayonet has survived death many times with Sugimoto, to the point that it could be considered a part of his body.`
    },
{ //Asirpa's Hunting Knife - Complete
        id: 150811,
        name: `Asirpa's Hunting Knife`,
        location: `Crossover Equip Summon: Golden Kamuy`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150810.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150811.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150810.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150811.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `40s CT - Increases all allies' Arts gauge by 20. For 10s, auto-fills all allies' Arts gauge by 2 & increases Break power by 30%.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Asirpa's beloved tashiro, a kind of Ainu hunting knife. It can be used for anything from cooking to crafting tools needed in the mountains. It is incredibly versatile when used with the knowledge of the Ainu people, which has been passed down from generation to generation.`
    },
{ //Hijikata's Clothes - Complete
        id: 150821,
        name: `Hijikata's Clothes`,
        location: `Limited Crossover Event: Golden Kamuy`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150820.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150821.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150820.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150821.png`
            },
        stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
        skillset:  
            {
                skill: `65s CT - Increases all allies' DMG by 25% & Physical DMG by 20% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Light resistance 5% UP.`
            },
        lore: `The clothing worn by Toshizo Hijikata of the Shinsengumi during the last days of the Tokugawa shogunate. The Merciless Vice-Commander, as his nickname goes, is sprightly despite his age and wields his sword in these western-style garments. When Hijikata puts these on, he gives off an incredible air of dignity.`
    },
{ //Hero Belt - Complete
        id: 150831,
        name: `Hero Belt`,
        location: `Limited Crossover Event: One Punch Man`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150830.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150831.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150830.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150831.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `80s CT - 8000% Light DMG (Physical). Increases own ATK by 40% for 10s. If user is Light unit, additional 40%.`,
                break: 1000
            },
        passive: 
            {
                ability1: `Own DEF 5% UP.`
            },
        lore: `The belt worn by the strongest hero Saitama. The golden buckle shines brightly, even today.`
    },
{ //Hero Suit - Complete
        id: 150841,
        name: `Hero Suit`,
        location: `Crossover Equip Summon: One Punch Man`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150840.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150841.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150840.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150841.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `40s CT - Negates all allies' DMG for a total of 2000 for 10s. If Human, reduces Light DMG from enemy by 35% for 10s (Only once).`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The bodysuit worn by the strongest hero Saitama.`
    },
{ //Genos' Vest - Complete
        id: 150851,
        name: `Genos' Vest`,
        location: `Crossover Equip Summon: One Punch Man`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150850.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150851.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150850.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150851.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 3500% Dark DMG (Physical). Increases own DMG by 30% & all allies' DMG by 20% for 10s. On first use, increase own Arts gauge by 70.`,
                break: 500
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The vest worn by S-Class hero Genos. You can find his name written inside.`
    },
{ //Emilia's Cape - Complete
        id: 150861,
        name: `Emilia's Cape`,
        location: `Limited Crossover Event: Re:Zero`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150860.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150861.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150860.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150861.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `55s CT - 5000% Water DMG (Magic). Increases own DMG by 30% & Water units' DMG by 10% for 10s.`,
                break: 800
            },
        passive: 
            {
                ability1: `Magic DMG from enemy 5% DOWN.`
            },
        lore: `The 'Spirit User' Emilia's cloak. There are cat ears in the hood.`
    },
{ //True 'Emilia's Cape' - Complete
        id: 150871,
        name: `True 'Emilia's Cape'`,
        location: `Craftable - Using Re:Zero Raid materials`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150870.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150871.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150870.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150871.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `55s CT - 6000% Water DMG (Magic). Increases own DMG by 30% & Water units' DMG by 10% for 12s.`,
                break: 800
            },
        passive: 
            {
                ability1: `Magic DMG from enemy 5% DOWN.`,
                ability2: `When equipped by Emilia, Arts DMG 15% UP. Upon Arts activation, enemy's Freeze resistance 30% DOWN for 15s (Only once).`,
                ability3: [`ATK 5% UP.`, `DEF 5% UP.`, `MAX HP 5% UP.`, `Burn Time Reduction & Burn Evasion rate 10% UP.`, `Status Ailment (except Faint) Time Reduction 10% UP & Magic DMG 5% UP.`, `CRI rate 15% UP & Equip CT Speed 10% DOWN.`]
            },
        lore: `The 'Spirit User' Emilia's cloak. There are cat ears on the hood.`
    },
{ //Beatrice's Ribbon - Complete
        id: 150881,
        name: `Beatrice's Ribbon`,
        location: `Limited Crossover Event: Re:Zero`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150880.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150881.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150880.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150881.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `45s CT - Auto-fills target's Arts gauge by 5 for 15s. If user is Dark, increases Arts DMG by 15% for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Light Resistance 5% UP.`
            },
        lore: `The frilly pink ribbons that hold up the twin drills of the Forbidden Library's caretaker. It must take a long time to curl that hair every day.`
    },
{ //True 'Beatrice's Ribbon' - Complete
        id: 150891,
        name: `True 'Beatrice's Ribbon'`,
        location: `Craftable - Using Re:Zero Raid materials`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150890.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150891.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150890.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150891.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `50s CT - Auto-fills target's Arts gauge by 5 & if user is Dark elemental, increases Arts DMG by 20% for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: `When equipped by Beatrice, ATK 20% UP. Upon Arts activation, all allies' Dark DMG 20% UP for 10s (Only once).`,
                ability2: `Light Resistance 5% UP.`,
                ability3: [`ATK 5% UP.`, `DEF 5% UP.`, `MAX HP 5% UP.`, `Blind Time Reduction & Blind Evasion rate 10% UP.`, `Break Power & Dark DMG 5% UP.`, `Magic DMG 10% UP & DMG taken 10% UP.`]
            },
        lore: `The frilly pink ribbons that hold up the twin drills of the Forbidden Library's caretaker. It must take a long time to curl that hair that way.`
    },
{ //Rem's Morningstar - Complete
        id: 150901,
        name: `Rem's Morningstar`,
        location: `Limited Crossover Event: Re:Zero`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150900.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150901.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150900.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150901.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 6000% Water DMG (Physical). Increases own ATK by 40% for 20s. Increases own Arts gauge by 50 (First time only).`,
                break: 1000
            },
        passive: 
            {
                ability1: `Physical DMG 10% UP.`
            },
        lore: `The signature Morningstar for Rem, a maid at the Roswaal Mansion. It can crush someone's body with just one blow.`
    },
{ //True 'Rem's Morningstar' - Missing Lore
        id: 150911,
        name: `True 'Rem's Morningstar'`,
        location: `Craftable - Using Re:Zero Raid materials`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150910.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150911.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150910.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150911.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 7000% Water DMG (Physical). Increases own ATK by 50% for 20s. Increases own Arts gauge by 50 (First time only).`,
                break: 1000
            },
        passive: 
            {
                ability1: `When equipped by Rem (RE:ZERO), increase Critical DMG by 30%. Whenever Rem uses Arts (not True Arts), heal Rem's HP by 30%.`,
                ability2: `Physical DMG 10% UP.`,
                ability3: [`ATK 5% UP.`, `DEF 5% UP.`, `MAX HP 5% UP.`, `Freeze Resistance 10% UP.`, `Accuracy 7% UP & Physical DMG 5% UP.`, `ATK 10% UP & DEF 10% DOWN.`]
            },
        lore: `Missing Lore.`
    },
{ //Ram's Mop - Complete
        id: 150921,
        name: `Ram's Mop`,
        location: `Limited Crossover Event: Re:Zero`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150920.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150921.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150920.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150921.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 3000% Earth DMG (Magic). Increases own DEF & Accuracy by 25% for 10s.`,
                break: 700
            },
        passive: 
            {
                ability1: `Water Resistance 5% UP.`
            },
        lore: `The Roswaal Mansion's mop. You can't clean without it. Ram's name is written on it.`
    },
{ //True 'Ram's Mop' - Complete
        id: 150931,
        name: `True 'Ram's Mop'`,
        location: `Craftable - Using Re:Zero Raid materials`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150930.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150931.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150930.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150931.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 4000% Earth DMG (Magic). Increases own DEF & Accuracy by 25% for 15s.`,
                break: 700
            },
        passive: 
            {
                ability1: `When equipped by Ram, DEF 20% UP. Upon True Arts activation, Arts gauge 30 UP.`,
                ability2: `Magic Resistance 5% UP.`,
                ability3: [`ATK 5% UP.`, `DEF 5% UP.`, `MAX HP 5% UP.`, `Disease Time Reduction & Disease Evasion rate 10% UP.`, `Auto-recovery by 20 & Skill CT Speed 5% UP.`, `DEF 10% UP & Evasion rate 10% DOWN.`]
            },
        lore: `The Roswaal Mansion's mop. You can't clean without it. Ram's name is written on it.`
    },
{ //Subaru Natsuki's Tracksuit - Complete
        id: 150941,
        name: `Subaru Natsuki's Tracksuit`,
        location: `Crossover Equip Summon: Re:Zero`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150940.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150941.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150940.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150941.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `30s CT - Reduces all allies' DMG taken by 20% & Dark DMG by all allies by 10% for 15s. HP Auto-recovery by 1% for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Subaru Natsuki's tracksuit. It breathes and is fast to dry while also being quite durable. It makes for an easy wardrobe choice.`
    },
{ //Rem & Ram Plushies - Missing Lore
        id: 150951,
        name: `Rem & Ram Plushies`,
        location: `Crossover Equip Summon: Re:Zero`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150950.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150951.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150950.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150951.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `30s CT - Increases all allies' Arts gauge by 20. Increases all allies' ATK & DMG by 30% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Plushies of Rem and Ram. Now they can be together forever.`
    },
{ //Puck's Pouch - Complete
        id: 150961,
        name: `Puck's Pouch`,
        location: `Crossover Equip Summon: Re:Zero`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150960.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150961.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150960.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150961.png`
            },
        stats:
            {
                hp: 120,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `65s CT - Heals all allies' HP by 25% & additional 25% for the unit with the lowest HP.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The pouch that Emilia's friend Puck is never without. It holds more inside it than meets the eye.`
    },
{ //Roswaal Mansion Headdress - Complete
        id: 150971,
        name: `Roswaal Mansion Headdress`,
        location: `Crossover Equip Summon: Re:Zero`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150970.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150971.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150970.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150971.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 4000% Earth DMG (Magic). Increases weak-elemental DMG by 30% for 8s.`,
                break: 500
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A uniform accessory for servants of the Roswaal household. It is sophisticated while not gaudy, featuring delicate decorations.`
    },
{ //Roswaal Mansion Maid Uniform - Complete
        id: 150981,
        name: `Roswaal Mansion Maid Uniform`,
        location: `Crossover Equip Summon: Re:Zero`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150980.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150981.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150980.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150981.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `55s CT - 2500% Water DMG (Magic). Increases own Skill CT speed by 100% % Magic DMG by 30% for 20s.`,
                break: 400
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The uniform centerpiece for servants of the Roswaal household. For some reason, there is a lot of female stock but male uniforms are in scarce supply.`
    },
{ //Rimuru's Santa Suit - Complete
        id: 150991,
        name: `Rimuru's Santa Suit`,
        location: `Crossover Equip Summon: That Time I Got Reincarnated as a Slime`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_150990.png`,
                detailmax: `/db/Equipment/Detail/item_detail_150991.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_150990.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_150991.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `120s CT - Increase all allies' Arts gauge by 40. Auto-fills Demon units' Arts gauge by 6 for 5s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A Christmas outfit prepared by the people of Reigrad for Rimuru. Although it was made to thank Rimuru for defeating the beasts, it was very uncomfortable that the people thought Rimuru was very cute in the outfit.`
    },
{ //Milim's Santa Coat - Complete
        id: 151001,
        name: `Milim's Santa Coat`,
        location: `Crossover Equip Summon: That Time I Got Reincarnated as a Slime`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151000.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151001.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151000.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151001.png`
            },
        stats:
            {
                hp: 500,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - Recovers HP by 20% for the lowest HP unit. Auto-recovers all allies' HP by 5% for 6s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A Christmas outfit prepared by the people of Reigrad for Milim. She likes how cute the low-cut hem makes her look. Several men approached her with unsavory expressions, but they all came to regret it moments later.`
    },
{ //Shion's Santa Dress - Complete
        id: 151011,
        name: `Shion's Santa Dress`,
        location: `Crossover Equip Summon: That Time I Got Reincarnated as a Slime`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151010.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151011.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151010.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151011.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `40s CT - Reduces all Allies' Physical DMG taken by 20% and increase Paralyze resistance by 100% for 30s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A Christmas outfit prepared by the people of Reigrad for Shion. Although it was made to thank her for helping save the town, it was obviously meant to be more a gift for themselves than her. Regardless, she seemed unaware of their intent and wore it proudly.`
    },
{ //Milim's Thigh-Highs - Complete
        id: 151021,
        name: `Milim's Thigh-Highs`,
        location: `Crossover Equip Summon: That Time I Got Reincarnated as a Slime`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151020.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151021.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151020.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151021.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `20s CT - 3000% Fire DMG (Physical). Auto-fills Arts gauge by 2 & if user is Fire, additional 1 for 6s.`,
                break: 400
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Long stockings prepared by the townspeople to match Milim's Santa outfit. Made from magical fabric, they allow her to more easily control her own strength. It was the most thoughtful choice after hearing how many times her unrestrained power has caused problems...`
    },
{ //Gourikimaru - Complete
        id: 151031,
        name: `Gourikimaru`,
        location: `Limited Crossover Event: That Time I Got Reincarnated as a Slime`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151030.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151031.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151030.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151031.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 6000% Dark DMG (Physical). Increases own BE Output by 30% for 10s. If user is a Demon, additional 10%.`,
                break: 350
            },
        passive: 
            {
                ability1: `ATK 5% UP.`
            },
        lore: `A huge sword carried by Shion. Few can withstand its overwhelming strength. When she offered to slice a fish to help with the Christmas cooking, it seemed she also cut through the table and even left a large dent in the floor...`
    },
{ //Milim's Winter Boots - Complete
        id: 151041,
        name: `Milim's Winter Boots`,
        location: `Limited Crossover Event: That Time I Got Reincarnated as a Slime`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151040.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151041.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151040.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151041.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 120
            },
        skillset:  
            {
                skill: `20s CT - Reduces 5% of DMG take by all allies for 10s. If the enemy is a Demon, reduces additional 10%.`,
                break: 0
            },
        passive: 
            {
                ability1: `DEF 5% UP.`
            },
        lore: `Cute Red boots prepared by the townspeople to match Milim's Santa outfit. Despite how they look, they are sturdy enough to defend against any attack, mostly her own.`
    },
{ //Rimuru's Haori - Complete
        id: 151051,
        name: `Rimuru's Haori`,
        location: `Crossover Equip Summon: That Time I Got Reincarnated as a Slime`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151050.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151051.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151050.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151051.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `120s CT - Increases all allies' CRI rate by 100% & Demon units' DMG by 50% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Rimuru made this based on past New Year memories after seeing Reigrad's celebration. Though an unfamiliar design in Raktehelm, its dignified look left an impression on the other men. There are even rumors they'll adopt it for future New Year's festivals.`
    },
{ //Milim's Kimono - Complete
        id: 151061,
        name: `Milim's Kimono`,
        location: `Crossover Equip Summon: That Time I Got Reincarnated as a Slime`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151060.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151061.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151060.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151061.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 5800% Light DMG (Magic). Increases Light DMG to enemy by 40% for 30s.`,
                break: 700
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A kimono made by Rimuru to match his New Year's Haori. Its low-cut design lets her move easily, so she likes it quite a lot. This sentiment is shared by the other ladies of Reigrad, starting up a new fashion trend.`
    },
{ //Milim's Zori - Complete
        id: 151071,
        name: `Milim's Zori`,
        location: `Crossover Equip Summon: That Time I Got Reincarnated as a Slime`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151070.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151071.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151070.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151071.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 5000% Light DMG (Magic). Increases CRI rate by 100% for 8s.`,
                break: 500
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A pair of Japanese style shoes made by Rimuru to match his New Year's Haori. They are made of steel with magical power to withstand the power of the rampaging Milim. As a result, they are heavy, and only Milim can wear them.`
    },
{ //Dragon Knuckle EX - Complete
        id: 151081,
        name: `Dragon Knuckle EX`,
        location: `Crossover Equip Summon: That Time I Got Reincarnated as a Slime`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151080.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151081.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151080.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151081.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `55s CT - 7500% Light DMG (Physical). Increases ATK by 50% & reduces enemy's Physical resistance by 30% for 8s.`,
                break: 1000
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Made by Rimuru to restrain Milim's strength. It has a weakening effect to hold her crushing might in check. The equipment used its full potential to keep the city from being destroyed during the battle in Raktehelm.`
    },
{ //Rimuru's Scarf - Complete
        id: 151091,
        name: `Rimuru's Scarf`,
        location: `Limited Crossover Event: That Time I Got Reincarnated as a Slime`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151090.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151091.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151090.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151091.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `30s CT - 2500% Dark DMG (Physical). Increases weak-elemental DMG by 40% for 10s.`,
                break: 400
            },
        passive: 
            {
                ability1: `Skill DMG 20% UP.`
            },
        lore: `Rimuru made this based on past New Year memories after seeing Reigrad's celebration. It was originally just an accessory to complete the look with his Haori, but Rimuru truly appreciates its high defensive power and warmth.`
    },
{ //Milim's Kanzashi - Complete
        id: 151101,
        name: `Milim's Kanzashi`,
        location: `Limited Crossover Event: That Time I Got Reincarnated as a Slime`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151100.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151101.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151100.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151101.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 3500% Light DMG (Physical). Increases own ATK by 20% for 10s.`,
                break: 1500
            },
        passive: 
            {
                ability1: `Physical DMG from enemy 5% DOWN.`
            },
        lore: `A hairpin made by Rimuru made for Milim to match his own New Year's Haori. Though she wasn't comfortable with her new look at first, she soon came around since it was a gift from him.`
    },
{ //NEGI - Complete
        id: 151111,
        name: `NEGI`,
        location: `Crossover Equip Summon: Hatsune Miku`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151110.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151111.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151110.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151111.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 5000% Water DMG (Magic). Reduces enemy's Water resistance by 40% for 10s.`,
                break: 600
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The favorite vegetable of the electronic diva Hatsune Miku. It won't break after millions of swings, always staying fresh and springy. It doesn't actually exist in her world; she received it from a higher being called The Maestro. In her hands, it moves at a steady tempo like a conductor's baton.`
    },
{ //Miku's Headphones - Complete
        id: 151121,
        name: `Miku's Headphones`,
        location: `Limited Crossover Event: Hatsune Miku`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151120.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151121.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151120.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151121.png`
            },
        stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
        skillset:  
            {
                skill: `45s CT - Auto-fills all allies' Arts gauge by 1 & increases Skill CT speed by 30% for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Blind Time Reduction & Blind Evasion rate 10% UP.`
            },
        lore: `The fashionable headphones of electronic diva Hatsune Miku. They contain the memories of The Maestro. Whenever she gives a performance with her beautiful voice, she always puts these on first.`
    },
{ //Miku's Outfit - Complete
        id: 151131,
        name: `Miku's Outfit`,
        location: `Crossover Equip Summon: Hatsune Miku`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151130.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151131.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151130.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151131.png`
            },
        stats:
            {
                hp: 500,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `30s CT - Recovers 15% of all allies' HP & Heals all allies' Poison & Burn.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The costume of electronic diva Hatsune Miku. It draws out her natural charm with its chic, cute design. Made with advanced technology, it lets her create a wide variety of sounds like an instrument from her world. It is said to give any singer who wears it the power to pierce the hearts of all living things with their song. Some believe this is because of an enigmatic feature called "absolute territory" but not even she knows for certain.`
    },
{ //Rin Plushie - Complete
        id: 151141,
        name: `Rin Plushie`,
        location: `Limited Crossover Event: Hatsune Miku`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151140.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151141.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151140.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151141.png`
            },
        stats:
            {
                hp: 500,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - Increases target's Accuracy rate by 30% & heals all allies HP by 200 for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Magic DMG from enemy 5% DOWN.`
            },
        lore: `A plush doll of Kagamine Rin, s singer with a girlish voice. Although she looks like the spitting image of Kagamine Ren, she wears a large, cute ribbon. It was brought to Raktehelm by Hatsune Miku, who supports them both as a senior and as a fan. Cuddling it is believed to melt away exhaustion in an instant, and many also enjoy finding ways to pose it with the matching Len plushie.`
    },
{ //Len Plushie - Complete
        id: 151151,
        name: `Len Plushie`,
        location: `Crossover Equip Summon: Hatsune Miku`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151150.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151151.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151150.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151151.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `30s CT - Reduces all allies' Magic DMG taken by 20% & increases Curse resistance by 100% for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A plush doll of Kagamine Len, a singer with a boyish voice. Although he looks like the spitting image of Kagamine Rin, he sports a cool hairstyle. He has many who enjoy his energetic singing. It was brought to Raktehelm by Hatsune Miku, who has more experience than them but still supports their efforts. In their world, a meer picture can cheer up his fans.`
    },
{ //Miku's Guitar - Complete
        id: 151161,
        name: `Miku's Guitar`,
        location: `Crossover Equip Summon: Hatsune Miku`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151160.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151161.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151160.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151161.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 4000% Water DMG (Magic). Increases own CRI rate by 50% for 8s. If Water unit, extra 50%.`,
                break: 600
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The signature guitar of the electronic diva Hatsune Miku. Once belonging to a higher being known as The Maestro, it was converted so she could play it after seeing how she loved its color. The sound when she first played it for The Maestro, while she was still an amateur singer, is still vivid in her memory. It is said that its cheerful, dazzling tones can capture the heart of any who hear it.`
    },
{ //Sakura Miku's Hair Charm - Complete
        id: 151171,
        name: `Sakura Miku's Hair Charm`,
        location: `Crossover Equip Summon: Hatsune Miku`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151170.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151171.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151170.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151171.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `40s CT - Increases target's Arts gauge by 80. If target's Arts gauge hits 200 with this Equip, increases target's stats by 30% for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Hair accessories worn by Miku when she announced spring's return. There is a special tree in world that symbolizes the start of the season, and these were designed after the fruit that grows on them. When she puts them on, her hair color changes from her usual blue-green to the cheerful pink of cherry blossoms. Her singing voice can then create warm, refreshing breezes that carry petals far and wide. The day she visited Bamint, they saw spring like they had never seen it before.`
    },
{ //Sakura Miku's Outift - Complete
        id: 151181,
        name: `Sakura Miku's Outift`,
        location: `Crossover Equip Summon: Hatsune Miku`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151180.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151181.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151180.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151181.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `35s CT - 2500% Earth DMG (Magic). Increases ATK by 15% for 10s. If Earth unit, additional 15%.`,
                break: 500
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The clothes worn by Miku when she saved Bamint from the Giant's winter. While she sang in the capital city, spring flowers began to bloom and bright colors emerged, as if she were unfurling a blanket of warmth all around her. That day, she left her mark on Bamint's history as the Spring-Calling Fairy.`
    },
{ //True 'Shield of Rage' - Complete
        id: 151191,
        name: `True 'Shield of Rage'`,
        location: `Crossover Equip Summon: The Rising of the Shield Hero`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151190.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151191.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151190.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151191.png`
            },
        stats:
            {
                hp: 500,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `35s CT - Auto-recovers 3% of target's MAX HP for 20s. If the user is Light, heals Seal.`,
                break: 0
            },
        passive: 
            {
                ability1: `When equipped by Naofumi Iwatani, Physical DMG taken 35% DOWN & negates 4000 more DMG adding to his Arts effect (Only once).`,
                ability2: `DEF 10% UP.`
            },
        lore: `A Curse series weapon activated by powerful negative emotions of Naofumi Iwatani, one of four the Four Cardinal Heroes. It provides excellent offense and defense with its black flames but reduces the Status of its holder in return.`
    },
{ //Raphtalia's Outfit - Complete
        id: 151201,
        name: `Raphtalia's Outfit`,
        location: `Crossover Equip Summon: The Rising of the Shield Hero`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151200.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151201.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151200.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151201.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 4000% Light DMG (Physical). Increases Physical DMG by 60% for 10s. If covered by an ally, increases own Arts gauge by 30.`,
                break: 400
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Worn by Raphtalia, the sword to the Shield Hero Naofumi Iwatani. Its high flexibility allows it to keep up with her swift movements in battle.`
    },
{ //Melty's Shoes - Complete
        id: 151211,
        name: `Melty's Shoes`,
        location: `Crossover Equip Summon: The Rising of the Shield Hero`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151210.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151211.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151210.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151211.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `70s CT - 3500% Water DMG (Magic). Reduces enemy's Water & Magic resistance by 35% for 10s.`,
                break: 400
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Worn by Melty, the second princess of Melromarc. They match the refined elegance of her dress.`
    },
{ //Filo's Dress - Complete
        id: 151221,
        name: `Filo's Dress`,
        location: `Crossover Equip Summon: The Rising of the Shield Hero`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151220.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151221.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151220.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151221.png`
            },
        stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
        skillset:  
            {
                skill: `35s CT - Auto-fills target's Arts gauge by 4 for 10s. If the user is Earth, increases target's Physical DMG by 20%.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Worn by Filo when she is in human form, an ability granted by her status as a Filolial Queen. It is sewn with threads made of her own magical power.`
    },
{ //Yoh's Necklace - Complete
        id: 151231,
        name: `Yoh's Necklace`,
        location: `Limited Crossover Event: Shaman King`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151230.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151231.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151230.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151231.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 6000% Fire DMG (Magic). Increases Skill CT speed by 100% for 10s. Increases arts gauge by 50 (First time only).`,
                break: 600
            },
        passive: 
            {
                ability1: `Curse Time Reduction & Curse Evasion rate 10% UP.`
            },
        lore: `The necklace worn by Asakura Yoh, a shaman who links the physical and spiritual worlds. The bear claws are its most distinguishing feature.`
    },
{ //Basountou - Complete
        id: 151241,
        name: `Basountou`,
        location: `Limited Crossover Event: Shaman King`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151240.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151241.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151240.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151241.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 10000% Dark DMG (Physical). Increases Physical DMG by 30% for 8s.`,
                break: 1000
            },
        passive: 
            {
                ability1: `DMG 10% UP during Break.`
            },
        lore: `A long-handled blade carried by Tao Ren, rival to asakura Yoh and hopeful Shaman King. It allows him to unleash devastating attacks like the Vorpal Dance.`
    },
{ //Harusame - Complete
        id: 151251,
        name: `Harusame`,
        location: `Crossover Equip Summon: Shaman King`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151250.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151251.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151250.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151251.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `70s CT - 13000% Fire DMG (Physical). Reduces enemy's Physical resistance by 20% for 12s. If the user is Fire, increases Arts gauge by 80.`,
                break: 700
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A blade forged by a swordsmith for his samurai friend long ago. Although the samurai died before he gave it to him, Asakura Yoh helped make his wish come true 600 years later.`
    },
{ //Yoh's Headphones - Complete
        id: 151261,
        name: `Yoh's Headphones`,
        location: `Crossover Equip Summon: Shaman King`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151260.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151261.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151260.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151261.png`
            },
        stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
        skillset:  
            {
                skill: `30s CT - Auto-fills target's Arts gauge by 2 & increases All Stats by 20% for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The red headphones worn by Asakura Yoh, a shaman who links the physical and spiritual worlds. He uses them to listen to his favorite tunes when he wants to relax.`
    },
{ //Anna's Spirit Beads - Complete
        id: 151271,
        name: `Anna's Spirit Beads`,
        location: `Crossover Equip Summon: Shaman King`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151270.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151271.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151270.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151271.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `55s CT - 6500% Earth DMG (Magic). Reduces enemy's Magic resistance & Evasion rate by 30% for 10s.`,
                break: 500
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The spirit beads worn by Kyoyama Anna, the Itako of Osorezan and Asakura Yoh's fiancée.`
    },
{ //Anna's Bandana - Complete
        id: 151281,
        name: `Anna's Bandana`,
        location: `Crossover Equip Summon: Shaman King`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151280.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151281.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151280.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151281.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `30s CT - Reduces all allies' Fire DMG taken by 25% & Auto-recovers all allies' HP by 100 for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The red banadana worn by Kyoyama Anna, the Itako of Osorezan and Asakura Yoh's fiancée.`
    },
{ //Hao's Earrings - Complete
        id: 151291,
        name: `Hao's Earrings`,
        location: `Crossover Equip Summon: Shaman King`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151290.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151291.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151290.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151291.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `40s CT - Auto-fills all allies' Arts gauge by 2 & increases Gods' DMG by 30% for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Worn by Hao, the strongest shaman who calls himself the Future King. They are engraved with a star design.`
    },
{ //Yoh's Necklace EV - Complete
        id: 151301,
        name: `Yoh's Necklace EV`,
        location: `Craftable - Using Shaman King collab materials`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: ``,
                detailmax: `/db/Equipment/Detail/item_detail_151301.png`,
                thumb: ``,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151301.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 6000% Fire DMG (Magic). Increases Skill CT speed by 100% for 10s. Increases Arts gauge by 50 (First time only).`,
                break: 600
            },
        passive: 
            {
                ability1: `Curse Time Reduction & Curse Evasion rate 10% UP.`,
                ability2: [`DMG to Water enemy 5% UP.`, `DMG to Water enemy 10% UP.`, `BE Output 5% UP.`, `BE Output 10% UP.`, `ATK 10% UP.`, `DEF 10% UP.`]
            },
        lore: `The necklace worn by Asakura Yoh, a shaman who links the physical and spiritual worlds. The bears claws are its most distinguishing feature.`
    },
{ //Basountou EV - Complete
        id: 151311,
        name: `Basountou EV`,
        location: `Craftable - Using Shaman King collab materials`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: ``,
                detailmax: `/db/Equipment/Detail/item_detail_151311.png`,
                thumb: ``,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151311.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 10000% Dark DMG (Physical). Increases Physical DMG by 30% for 8s.`,
                break: 1000
            },
        passive: 
            {
                ability1: `DMG 10% UP during Break.`,
                ability2: [`DMG to Light enemy 5% UP.`, `DMG to Light enemy 10% UP.`, `MAX HP 5% UP.`, `CRI rate 10% UP.`, `HP Auto-recovery by 6.`, `DMG 5% UP when Near-Death.`]
            },
        lore: `A long-handled blade carried by Tao Ren, rival to Asakura Yoh and Shaman King hopeful. It allows to unleash devastating attacks like the Vorpal Dance.`
    },
{ //True 'Scissor Blade' - Missing Lore
        id: 151321,
        name: `True 'Scissor Blade'`,
        location: `Alchemist - Exchange via Rainbow Gems (Only during Kill La Kill crossover)`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151320.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151321.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151320.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151321.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `55s CT - 13000% Fire DMG (Physical). Increases Fire DMG by 30% for 20s.`,
                break: 1200
            },
        passive: 
            {
                ability1: `When equipped by Ryuko, DMG 20% UP & auto-fills Arts gauge by 2 (Only once).`,
                ability2: `DMG 5% UP when Near-Death.`
            },
        lore: `Missing Lore.`
    },
{ //True 'Bakuzan' - Missing Lore
        id: 151331,
        name: `True 'Bakuzan'`,
        location: `Alchemist - Exchange via Rainbow Gems (Only during Kill La Kill crossover)`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151330.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151331.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151330.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151331.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 7500% Water DMG (Physical).`,
                break: 5000
            },
        passive: 
            {
                ability1: `When equipped by Satsuki, Break Power 60% UP (Only once).`,
                ability2: `ATK 5% UP.`
            },
        lore: `Missing Lore.`
    },
{ //True 'Fight Club Goku Uniform' - Complete
        id: 151341,
        name: `True 'Fight Club Goku Uniform'`,
        location: `Alchemist - Exchange via Rainbow Gems (Only during Kill La Kill crossover)`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151340.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151341.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151340.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151341.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `30s CT - Increases all allies' Arts gauge by 15. Increases all allies DMG by 20% for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: `When equipped by Mako Mankanshoku, doubles this equip's Skill effect amount (Only once).`,
                ability2: `DEF 5% UP.`
            },
        lore: `The Two-Star Goku Uniform that was given to Mako Mankanshoku, the president of the Fight Club. She was originally considered a No-star student but unexpectedly revealed battle powers beyond imagination when wearing this Two-Star Goku Uniform.`
    },
{ //Scissor Blade Decap Mode - Complete
        id: 151351,
        name: `Scissor Blade Decap Mode`,
        location: `Crossover Equip Summon: Kill La Kill`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151350.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151351.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151350.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151351.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 12000% Fire DMG (Physical). Increases DMG by 80% & reduces enemy's Fire resistance by 30% for 10s.`,
                break: 1000
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Ryuko Matoi's Scissor Blade transformed after fully synchronizing with Senketsu for her final clash against Satsuki Kiryuin.`
    },
{ //Bakuzan Gako & Koryu - Complete
        id: 151361,
        name: `Bakuzan Gako & Koryu`,
        location: `Crossover Equip Summon: Kill La Kill`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151360.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151361.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151360.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151361.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `30s CT - 4000% Water DMG (Physical). Increases Arts gauge by 30 when HP is 90% or more. Increases Physical DMG by 50% for 8s.`,
                break: 800
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A set of swords reforged from Bakuzan after Ragyo Kiryuin broke it at the Cultural & Sports Grand Festival. Bakuzan-Gako was given to Nonon Jakuzure and Bakuzan-Koryu was given to Ira Gamagori before passing it to Uzu Sanageyama who was more familiar with swordplay. After Satsuki Kiryuin was rescued from Honnouji Academy, both were returned to her.`
    },
{ //No-Star Uniform - Complete
        id: 151371,
        name: `No-Star Uniform`,
        location: `Crossover Equip Summon: Kill La Kill`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151370.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151371.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151370.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151371.png`
            },
        stats:
            {
                hp: 500,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `30s CT - Heals all allies' Seal. Auto-recovers all allies' HP by 200 for 12s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Worn by No-Star students of Honnouji Academy like Ryuko Matoi's best friend Mako Mankanshoku. The number of stars determines rank, so they are considered inferior to the One-, Two-, and Three-Star students.`
    },
{ //Ryuko's Motorbike - Complete
        id: 151381,
        name: `Ryuko's Motorbike`,
        location: `Limited Crossover Event: Kill La Kill`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151380.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151381.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151380.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151381.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `40s CT - Reduces all allies' Fire & Water DMG taken by 30% & increases Skill CT speed by 30% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Evasion rate 5% UP when Near-Death.`
            },
        lore: `The vehicle used by Ryuko Matoi to retrieve the pieces of Senketsu after Nui Harime tore him up. Going from Kobe, Kyoto, then to Osaka, she finally went to obtain the last part from Satsuki Kiryuin.`
    },
{ //Mako-Knuckle EV - Complete
        id: 151391,
        name: `Mako-Knuckle EV`,
        location: `Craftable - Using Kill La Kill collab materials`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 4,
        image: 
            {
                detail: ``,
                detailmax: `/db/Equipment/Detail/item_detail_151391.png`,
                thumb: ``,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151391.png`
            },
            stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `6s CT - 1200% Light DMG (Physical).`,
                break: 200
            },
        passive: 
            {
                ability1: `Own Skill CT 15% DOWN.`,
                ability2: [`DMG to Dark enemy 5% UP.`, `DMG to Dark enemy 10% UP.`, `Skill DMG 10% UP.`, `DMG 5% UP when Near-Death.`, `HP Auto-recovery by 4.`, `Blind Time Reduction & Blind Evasion rate 10% UP.`]
            },
        lore: `The weapon chosen by Mako Mankanshoku, a student at Honnoji Academy. When wearing the Fight Club-Spec Goku Uniform, she puts this knuckle on her fist to punch her enemies without mercy. Though a simple weapon that merely strengthens her fist's impact, its destructive power is extremely high.`
    },
{ //Senketsu EV - Complete
        id: 151401,
        name: `Senketsu EV`,
        location: `Craftable - Using Kill La Kill collab materials`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: ``,
                detailmax: `/db/Equipment/Detail/item_detail_151401.png`,
                thumb: ``,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151401.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `50s CT - Reduces all Allies' DMG Taken by 25% for 15s. Heals all Allies' HP by 30/s for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increases Critical DMG by 10%.`,
                ability2: [`All allies' HP Heal amount 5% UP (Except Lifesteal).`, `All allies' HP Heal amount 10% UP (Except Lifesteal).`, `DMG 10% UP during Break.`, `ATK 5% UP.`, `HP Auto-recovery by 20 when Near-Death.`, `Poison Time Reduction & Poison Evasion rate 10% UP.`]
            },
        lore: `The sentient black sailor uniform created by Ryuko Matoi's father, Isshin Matoi. it is made purely from Life Fiber which mere mortals cannot wear. It awakens and unleashes its power by absorbing Ryuko's blood. Only Ryuko can hear Senketsu's voice.`
    },
{ //Junketsu EV - Complete
        id: 151411,
        name: `Junketsu EV`,
        location: `Craftable - Using Kill La Kill collab materials`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: ``,
                detailmax: `/db/Equipment/Detail/item_detail_151411.png`,
                thumb: ``,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151411.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `55s CT - Negates 800 DMG Dealt to all Allies' for 15s. Reduces all Allies' Light and Dark Elemental DMG Taken by 20% for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increases DMG by 20% when Near-Death.`,
                ability2: [`DEF 20% UP when negating DMG.`, `DEF 30% UP when negating DMG.`, `Auto-fills Arts gauge by 2 when Near-Death.`, `ATK 5% UP.`, `HP Recovery amount 10% UP (Except Lifesteal).`, `Disease Time Reduction & Disease Evasion rate 10% UP.`]
            },
        lore: `Another Kamui created by Ryuko Matoi's father, Isshin Matoi. Like Senketsu, it is made only from Life Fiber, which mere mortals cannot wear. The burden for the wearer, Satsuki Kiryuin, is so severe she cannot wear it for long.`
    },
{ //Spiked Bat Missile EV - Complete
        id: 151421,
        name: `Spiked Bat Missile EV`,
        location: `Craftable - Using Kill La Kill collab materials`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: ``,
                detailmax: `/db/Equipment/Detail/item_detail_151421.png`,
                thumb: ``,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151421.png`
            },
            stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 6000% Light DMG (Physical). 50% chance of Fainting enemy. Increases DMG to enemy by 15% for 8s.`,
                break: 900
            },
        passive: 
            {
                ability1: `Auto-fills Arts gauge by 1 when Near-Death.`,
                ability2: [`Attacks while ignoring enemy's DEF by 10%.`, `Accuracy 10% UP.`, `Skill CT speed 5% UP.`, `Break power 4% UP.`, `Evasion rate 5% UP when Near-Death.`, `Paralysis Time reduction & Paralysis Evasion rate 10% UP.`]
            },
        lore: `One of the weapons that Mako Mankanshoku used when she was the leader of the Fight Club. Also usable as a close combat weapon, this bat shoots powerful missiles from the end that leave a trail of thick smoke wherever they land.`
    },
{ //Ryuko's Motorbike EV - Complete
        id: 151431,
        name: `Ryuko's Motorbike EV`,
        location: `Craftable - Using Kill La Kill collab materials`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: ``,
                detailmax: `/db/Equipment/Detail/item_detail_151431.png`,
                thumb: ``,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151431.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `40s CT - Reduces all allies' Fire & Water DMG taken by 30% & increases Skill CT speed by 30% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Evasion rate 5% UP when Near-Death.`,
                ability2: [`Water resistance 5% UP.`, `Water resistance 10% UP.`, `Fire resistance 5% UP.`, `DEF 5% UP.`, `Freeze Time Reduction & Freeze Evasion rate 10% UP.`, `Burn Time Reduction & Burn Evasion rate 10% UP.`]
            },
        lore: `The vehicle used by Ryuko Matoi to retrieve the pieces of Senketsu after Nui Harime tore him up. Going from Kobe, Kyoto, then to Osaka, she finally went to obtain the last part from Satsuki Kiryuin.`
    },
{ //Aqua Heartia - Complete
        id: 151481,
        name: `Aqua Heartia`,
        location: `Crossover Equip Summon: Mushoku Tensei`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151480.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151481.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151480.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151481.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `30s CT - Auto-fills all allies' Arts gauge by 3 for 10s. Increases Fire, Water & Earth units' ATK by 50% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A present from the Boreas Greyrat family to Rudeus on his tenth birthday. Its name means "prideful water dragon". Specially made out of the finest materials by the best staff-maker in the Asura Kingdom, it greatly enhances the effects of any spells.`
    },
{ //Eris' Training Attire - Complete
        id: 151491,
        name: `Eris' Training Attire`,
        location: `Crossover Equip Summon: Mushoku Tensei`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151490.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151491.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151490.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151491.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `55s CT - 7700% Fire DMG (Physical). Increases DMG to enemy by 20% & reduces enemy's Fire resistance by 30% for 10s.`,
                break: 600
            },
        passive: 
            {
                ability1: ``
            },
        lore: `One of Eris Boreas Greyrat's favorite outfits.`
    },
{ //Sylphiette's Outfit - Complete
        id: 151501,
        name: `Sylphiette's Outfit`,
        location: `Crossover Equip Summon: Mushoku Tensei`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151500.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151501.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151500.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151501.png`
            },
        stats:
            {
                hp: 500,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `90s CT - Revives Fallen ally with 50% of HP for 120s (Only once). Auto-recovers all allies' HP by 100 for 40s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Worn by Rudeus' childhood friend, the quarter-elf girl Sylphiette.`
    },
{ //Roxy's Hat - Complete
        id: 151511,
        name: `Roxy's Hat`,
        location: `Limited Crossover Event: Mushoku Tensei`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151510.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151511.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151510.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151511.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 4000% Water DMG (Magic). Increases Magic DMG by 40% for 8s. Increases Arts gauge by 50 (First time only).`,
                break: 400
            },
        passive: 
            {
                ability1: `DEF 5% UP.`
            },
        lore: `The hat worn by Roxy, a Migurdian magician skilled in Saint-class water magic.`
    },
{ //Roxy's Hat EV - Complete
        id: 151521,
        name: `Roxy's Hat EV`,
        location: `Craftable - Using Mushoku Tensei collab materials`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: ``,
                detailmax: `/db/Equipment/Detail/item_detail_151521.png`,
                thumb: ``,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151521.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 4000% Water DMG (Magic). Increases Magic DMG by 40% for 8s. Increases Arts gauge by 50 (First time only).`,
                break: 400
            },
        passive: 
            {
                ability1: `DEF 5% UP.`,
                ability2: [`ATK 15% UP when HP is 90% or more.`, `DMG to Fire enemy 5% UP.`, `DMG to Fire enemy 10% UP.`, `Skill CT speed 5% UP.`, `DMG from enemy 5% DOWN when Near-Death.`, `HP Recovery amount 5% UP (Except Lifesteal).`]
            },
        lore: `The hat worn by Roxy, a Migurdian magician skilled in Saint-class water magic.`
    },
{ //Eris' Sword - Complete
        id: 151531,
        name: `Eris' Sword`,
        location: `Limited Crossover Event: Mushoku Tensei`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151530.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151531.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151530.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151531.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 5000% Fire DMG (Physical). Increases Physical DMG by 40% for 10s. If the User is Fire, increases Skill CT speed by 40% for 10s.`,
                break: 500
            },
        passive: 
            {
                ability1: `ATK 5% UP.`
            },
        lore: `The weapon favored by Eris, young lady of the Boreas Greyrat family. It was received from Roxy's father at the Migurd village.`
    },
{ //Eris' Sword EV - Complete
        id: 151541,
        name: `Eris' Sword EV`,
        location: `Craftable - Using Mushoku Tensei collab materials`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: ``,
                detailmax: `/db/Equipment/Detail/item_detail_151541.png`,
                thumb: ``,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151541.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 5000% Fire DMG (Physical). Increases Physical DMG by 40% UP for 10s. If the User is Fire, increases Skill CT speed by 40% for 10s.`,
                break: 500
            },
        passive: 
            {
                ability1: `ATK 5% UP.`,
                ability2: [`ATK 15% UP when HP is 90% or more.`, `DMG to Earth enemy 5% UP.`, `DMG to Earth enemy 10% UP.`, `Break Power 6% UP.`, `Skill DMG 10% UP.`, `Accuracy 10% UP.`]
            },
        lore: `The weapon favoured by Eris, young lady of the Boreas Greyrat family. It was received from Roxy's father at the Migurd village.`
    },
{ //Natsu's Vest - Complete
        id: 151551,
        name: `Natsu's Vest`,
        location: `Crossover Equip Summon: Fairy Tail`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151550.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151551.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151550.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151551.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 5000% Fire DMG (Physical). Increases DMG by 40% for 15s. If the enemy is Burning, 40% more.`,
                break: 800
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The vest worn by Natsu Dragneel of the wizard guild Fairy Tail.`
    },
{ //Lucy's Top - Complete
        id: 151561,
        name: `Lucy's Top`,
        location: `Crossover Equip Summon: Fairy Tail`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151560.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151561.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151560.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151561.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `20s CT - Auto-fills all allies' Arts gauge by 2 for 8s. Increases CRI DMG by the usage count x15% (MAX 60%) for 8s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Worn by Lucy Heartfilia of the wizard guild Fairy Tail.`
    },
{ //Celestial Gate Key - Complete
        id: 151571,
        name: `Celestial Gate Key`,
        location: `Crossover Equip Summon: Fairy Tail`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151570.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151571.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151570.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151571.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 7000% Light DMG (Magic). Increases Magic DMG by 50% for 10s. Increases all allies' BE Output by 60% for 10s (First time only).`,
                break: 300
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Belongs to Lucy Heartfilia of the wizard guild Fairy Tail. It opens a Gate to another world that calls forth a celestial spirit, powered by her concern for them.`
    },
{ //Cold Excalibur - Complete
        id: 151581,
        name: `Cold Excalibur`,
        location: `Crossover Equip Summon: Fairy Tail`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151580.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151581.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151580.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151581.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 10000% Water DMG (Physical). Reduces enemy's Water & Physical resistance by 30% for 10s.`,
                break: 800
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A sword created by Gray Fullbuster of the wizard guild Fairy Tail. Created with his powerful Ice-Make magic, it strikes with a sharpened sub-zero edge.`
    },
{ //Gray's Necklace - Complete
        id: 151591,
        name: `Gray's Necklace`,
        location: `Crossover Equip Summon: Fairy Tail`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151590.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151591.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151590.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151591.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 4000% Water DMG (Physical). Increases ATK by 30% for 10s. If the user is a Water unit, increases Accuracy by 20% for 10s.`,
                break: 600
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Worn by Gray Fullbuster of the wizard guild Fairy Tail.`
    },
{ //Erza's Blade - Complete
        id: 151601,
        name: `Erza's Blade`,
        location: `Crossover Equip Summon: Fairy Tail`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151600.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151601.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151600.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151601.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 9000% Light DMG (Physical). Increases Physical DMG by 30% for 10s. If the user is affected by a Status Ailment, 30% more.`,
                break: 700
            },
        passive: 
            {
                ability1: ``
            },
        lore: `One of the weapons belonging to Erza Scarlet of the wizard guild Fairy Tail. The wings on the hilt represent its origin as part of the Heaven's Wheel Armor from her "The Knight" magic.`
    },
{ //Happy - Complete
        id: 151611,
        name: `Happy`,
        location: `Crossover Equip Summon: Fairy Tail`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151610.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151611.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151610.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151611.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `70s CT - 7000% Water DMG (Magic). Reduces enemy's Magic resistance by 25% for 10s. Reduces additional 25% when HP is 90% or more.`,
                break: 300
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Natsu's friend, a unique cat that walks on two legs. He can speak Human language, but his tongue is sharper that his cute appearance lets on.`
    },
{ //Guild Flag - Complete
        id: 151621,
        name: `Guild Flag`,
        location: `Crossover Equip Summon: Fairy Tail`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151620.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151621.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151620.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151621.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `30s CT - Reduces all allies' Physical DMG taken by 30% for 15s. Increases all allies' Arts gauge by 10 (First time only).`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A flag emblazoned with the Fairy Tail guild crest. The same mark can be found on the bodies of each member.`
    },
{ //Natsu's Scarf - Complete
        id: 151631,
        name: `Natsu's Scarf`,
        location: `Limited Crossover Event: Fairy Tail`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151630.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151631.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151630.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151631.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 6000% Fire DMG (Physical). Increases Physical DMG by 40% for 10s. Increases Arts gauge by 40 (First time only).`,
                break: 600
            },
        passive: 
            {
                ability1: `ATK 5% UP.`
            },
        lore: `The scarf worn by Natsu Dragneel of the wizard guild Fairy Tail. A gift from his foster parent Igneel, he is never seen without it.`
    },
{ //Natsu's Scarf EV - Complete
        id: 151641,
        name: `Natsu's Scarf EV`,
        location: `Craftable - Using Fairy Tail collab materials`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: ``,
                detailmax: `/db/Equipment/Detail/item_detail_151641.png`,
                thumb: ``,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151641.png`
            },
            stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 6000% Fire DMG (Physical). Increases Physical DMG by 40% for 10s. Increases Arts gauge by 40 (First time only).`,
                break: 600
            },
        passive: 
            {
                ability1: `ATK 5% UP.`,
                ability2: [`ATK 5% UP when HP is 90% or more.`, `ATK 10% UP when HP is 90% or more.`, `ATK 15% UP when HP is 90% or more.`, `Skill CT speed 5% UP.`, `DEF 5% UP.`, `DEF 10% UP.`]
            },
        lore: `The scarf worn by Natsu Dragneel of the wizard guild Fairy Tail. A gift from his foster parent Igneel, he is never seen without it.`
    },
{ //Erza's Armor - Complete
        id: 151651,
        name: `Erza's Armor`,
        location: `Limited Crossover Event: Fairy Tail`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151650.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151651.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151650.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151651.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `40s CT - Reduces all allies' DMG taken by 10% & Physical DMG taken by 20% for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: `BE Output 5% UP.`
            },
        lore: `The everyday outfit worn by Erza Scarlet of the wizard guild Fairy Tail.`
    },
{ //Miku's Headphones EV - Complete
        id: 151661,
        name: `Miku's Headphones EV`,
        location: `Craftable - Using Hatsune Miku collab materials`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 4,
        image: 
            {
                detail: ``,
                detailmax: `/db/Equipment/Detail/item_detail_151661.png`,
                thumb: ``,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151661.png`
            },
            stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
        skillset:  
            {
                skill: `45s CT - Auto-fills all allies' Arts gauge by 1 & increases Skill CT speed by 30% for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Blind Time Reduction & Blind Evasion rate 10% UP.`,
                ability2: [`DMG to Fire enemy 5% UP.`, `DMG to Fire enemy 10% UP.`, `Break power 2% UP.`, `DMG 5% UP when Near-Death.`, `ATK 5% UP.`, `DEF 5% UP.`]
            },
        lore: `The fashionable headphones of electronic diva Hatsune Miku. They contain the memories of The Maestro. Whenever she gives a performance with her beautiful voice, she always puts these on first.`
    },
{ //Rin Plushie EV - Complete
        id: 151671,
        name: `Rin Plushie EV`,
        location: `Craftable - Using Hatsune Miku collab materials`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: ``,
                detailmax: `/db/Equipment/Detail/item_detail_151671.png`,
                thumb: ``,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151671.png`
            },
        stats:
            {
                hp: 500,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - Increases target's Accuracy rate by 30% & heals all allies HP by 200 for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Magic DMG from enemy 5% DOWN.`,
                ability2: [`All allies' HP Heal amount 5% UP (Except Lifesteal).`, `All allies' HP Heal amount 10% UP (Except Lifesteal).`, `Earth resistance 5% UP.`, `Water Resistance 5% UP.`, `Poison Time Reduction & Poison Evasion rate 10% UP.`, `Freeze Time Reduction & Freeze Evasion rate 10% UP.`]
            },
        lore: `A plush doll of Kagamine Rin, s singer with a girlish voice. Although she looks like the spitting image of Kagamine Ren, she wears a large, cute ribbon. It was brought to Raktehelm by Hatsune Miku, who supports them both as a senior and as a fan. Cuddling it is believed to melt away exhaustion in an instant, and many also enjoy finding ways to pose it with the matching Len plushie.`
    },
{ //Erza's Armor EV - Complete
        id: 151681,
        name: `Erza's Armor EV`,
        location: `Craftable - Using Fairy Tail collab materials`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: ``,
                detailmax: `/db/Equipment/Detail/item_detail_151681.png`,
                thumb: ``,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151681.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `40s CT - Reduces all allies' DMG taken by 10% & Physical DMG taken by 20% for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: `BE Output 5% UP.`,
                ability2: [`MAX HP 5% UP.`, `DEF 5% UP.`, `DEF 10% UP.`, `ATK 5% UP.`, `ATK 10% UP.`, `Arts gauge 10 UP when quest starts (Only once).`]
            },
        lore: `The everyday outfit worn by Erza Scarlet of the wizard guild Fairy Tail.`
    },
{ //Stolen Scissor Blade - Complete
        id: 151691,
        name: `Stolen Scissor Blade`,
        location: `Crossover Equip Summon: Kill La Kill`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151690.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151691.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151690.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151691.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 12000% Dark DMG (Magic). Increases DMG by 70% & reduces enemy's Dark resistance by 40% for 8s.`,
                break: 1000
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The other Scissor Blade taken by Nui Harime when she attacked Isshin Matoi's lab. Unlike Ryuko's, it is colored purple.`
    },
{ //Nui's Dress - Complete
        id: 151701,
        name: `Nui's Dress`,
        location: `Crossover Equip Summon: Kill La Kill`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151700.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151701.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151700.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151701.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `30s CT - Auto-fills all allies' Arts gauge by 3 & increases Demons units' ATK by 50% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Nui Harime's pink one-piece lolita-style dress. Unlike the Kamui or Goku Uniforms, it is not made from Life Fibers.`
    },
{ //Nui's Eyepatch - Complete
        id: 151711,
        name: `Nui's Eyepatch`,
        location: `Crossover Equip Summon: Kill La Kill`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151710.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151711.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151710.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151711.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 6000% Dark DMG (Magic). Increases ATK by 50% for 10s. Increases Arts gauge by 30, extra 30 for first time.`,
                break: 1000
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Nui Harime's eyepatch designed after her first name written in Japanese. It is sewn directly onto her face.`
    },
{ //Nui's Parasol - Complete
        id: 151721,
        name: `Nui's Parasol`,
        location: `Limited Crossover Event: Kill La Kill`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151720.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151721.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151720.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151721.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `55s CT - 4200% Dark DMG (Magic). Reduces Giant Boss' Dark resistance by 20% for 10s.`,
                break: 600
            },
        passive: 
            {
                ability1: `ATK 5% UP.`
            },
        lore: `The parasol carried by Nui Harime. Its surprising durability once blacked a direct attack from Uzu Sanageyama in his Blade Regalia.`
    },
{ //Bunny Pajamas - Complete
        id: 151731,
        name: `Bunny Pajamas`,
        location: `Crossover Equip Summon: Kill La Kill`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151730.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151731.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151730.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151731.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `65s CT - Auto-fills target's Arts gauge by 15 & negates all allies' DMG taken for a total of 2000 for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Cute sleepwear Ryuko wore when she spent the night at the Mankanshoku household. She also showed up to Honnouji Academy's No-Tardies Day in it since she needed to wash Senketsu early that morning.`
    },
{ //Seki Tekko - Complete
        id: 151741,
        name: `Seki Tekko`,
        location: `Crossover Equip Summon: Kill La Kill`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151740.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151741.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151740.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151741.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `30s CT - Increases own ATK by 40% & reduces all allies' Physical DMG taken by 30% for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The glove Aikuro Mikisugi gave to Ryuko that lets her easily feed fresh blood to Senketsu. As it too is part of her Kamui, she needs it to completely activate Senketsu.`
    },
{ //Aikuro's Dotonbori Robo - Complete
        id: 151751,
        name: `Aikuro's Dotonbori Robo`,
        location: `Crossover Equip Summon: Kill La Kill`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151750.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151751.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151750.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151751.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 5500% Water DMG (Physical). Increases Arts gauge by 30. Increases Physical DMG by 60% for 12s.`,
                break: 800
            },
        passive: 
            {
                ability1: ``
            },
        lore: `An anti-Honnouji Academy weapon developed by Nudist Beach with Takarada Conglomerate funding. It can combine with Tsumugu Kinagase's model to form the Ultimate Double Naked DTR.`
    },
{ //ER Suction Device - Complete
        id: 151761,
        name: `ER Suction Device`,
        location: `Crossover Equip Summon: Kill La Kill`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151760.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151761.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151760.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151761.png`
            },
        stats:
            {
                hp: 500,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - Heals all allies' Disease. Auto-recovers all allies' HP by 200 for 12s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A weapon created by Houka Inumuta and Shiro Iori nicknamed "QQQQ". It both saves people absorbed by Covers and harms Covers.`
    },
{ //True 'Stolen Scissor Blade' - Complete
        id: 151771,
        name: `True 'Stolen Scissor Blade'`,
        location: `Alchemist - Exchange via Rainbow Gems (Only during Kill La Kill crossover)`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151770.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151771.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151770.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151771.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 8000% Dark DMG (Magic). Increases Dark DMG by 50% & DMG to Giant Boss by 50% for 10s.`,
                break: 800
            },
        passive: 
            {
                ability1: `When equipped by Nui Harime, Skill CT speed & All Stats 20% Up (Only once).`,
                ability2: `Dark resistance 5% UP.`
            },
        lore: `The other Scissor Blade taken by Nui Harime when she attacked Isshin Matoi's lab. Unlike Ryuko's, it is colored purple.`
    },
{ //Nui's Parasol EV - Complete
        id: 151781,
        name: `Nui's Parasol EV`,
        location: `Craftable - Using Kill La Kill collab materials`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 4,
        image: 
            {
                detail: ``,
                detailmax: `/db/Equipment/Detail/item_detail_151781.png`,
                thumb: ``,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151781.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `55s CT - 4200% Dark DMG (Magic). Reduces Giant Boss' Dark resistance by 20% for 10s.`,
                break: 600
            },
        passive: 
            {
                ability1: `ATK 5% UP.`,
                ability2: [`DMG to Humans 10% UP.`, `Skill CT speed 20% UP when HP is 90% or more.`, `Light Resistance 5% UP.`, `DMG 10% UP when Near-Death.`, `DEF 5% UP.`, `HP Recovery 5% UP (Except Lifesteal).`]
            },
        lore: `The parasol carried by Nui Harime. Its surprising durability once blacked a direct attack from Uzu Sanageyama in his Blade Regalia.`
    },
{ //True 'Melty's Dress' - Complete
        id: 151791,
        name: `True 'Melty's Dress'`,
        location: `Alchemist - Exchange via Rainbow Gems (Only during Shield Hero crossover)`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151790.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151791.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151790.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151791.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `40s CT - Reduces all allies' DMG taken by 10% & auto-fills Arts gauge by 3 for 8s.`,
                break: 0
            },
        passive: 
            {
                ability1: `When equipped by Melty, doubles this equip's skill effect amount (Only once).`,
                ability2: `DEF 5% UP.`
            },
        lore: `Melty's plain dresswear of choice. Its elegant design perfectly matches the second princess' noble tastes.`
    },
{ //True 'Filo's Hairpin' - Complete
        id: 151801,
        name: `True 'Filo's Hairpin'`,
        location: `Alchemist - Exchange via Rainbow Gems (Only during Shield Hero crossover)`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151800.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151801.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151800.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151801.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `55s CT - 7000% Earth DMG (Magic). Increases CRI rate by 70% for 10s. If the user is an earth unit, 30% more.`,
                break: 700
            },
        passive: 
            {
                ability1: `When equipped by Filo, DMG 30% UP & auto-fills Arts gauge by 1 (Only once).`,
                ability2: `Skill DMG 20% UP.`
            },
        lore: `Filo's hair accessory that Naofumi Iwatani made for her. He endowed it with speed-increasing powers and chose to make it a hairpin since it was unlikely to fall off when Filo transforms... so he says.`
    },
{ //True 'Fitoria's Gown' - Missing Lore
        id: 151811,
        name: `True 'Fitoria's Gown'`,
        location: `Alchemist - Exchange via Rainbow Gems (Only during Shield Hero crossover)`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151810.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151811.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151810.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151811.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 8300% Earth DMG (Magic). Reduces enemy's Magic resistance by 35% for 15s.`,
                break: 800
            },
        passive: 
            {
                ability1: `When equipped by Fitoria, HP recovery amount, DMG & Accuracy rate 20% UP, and auto-fills Arts gauge by 2 (Only once).`,
                ability2: `DEF 5% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Fitoria's Gown - Missing Lore
        id: 151821,
        name: `Fitoria's Gown`,
        location: `Crossover Equip Summon: The Rising of the Shield Hero`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151820.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151821.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151820.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151821.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `70s CT - 13000% Earth DMG (Magic). Reduces enemy's Magic resistance by 20% for 12s. If user is Earth, increases Arts gauge by 80.`,
                break: 700
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
{ //Fitoria's Boots - Complete
        id: 151831,
        name: `Fitoria's Boots`,
        location: `Limited Crossover Event: The Rising of the Shield Hero`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151830.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151831.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151830.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151831.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `40s CT - Reduces all allies' Earth & Magic DMG taken by 20% for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Poison Time Reduction & Poison Evasion rate 10% UP.`
            },
        lore: `The footwear of Fitoria, queen of all the world's Filolials.`
    },
{ //Leaf Shield - Missing Lore
        id: 151841,
        name: `Leaf Shield`,
        location: `Crossover Equip Summon: The Rising of the Shield Hero`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151840.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151841.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151840.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151841.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `30s CT - Reduces all allies' Earth DMG taken by 25% & HP Auto-recovery by 100 for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
{ //Filolial Tiara - Missing Lore
        id: 151851,
        name: `Filolial Tiara`,
        location: `Crossover Equip Summon: The Rising of the Shield Hero`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151850.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151851.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151850.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151851.png`
            },
        stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
        skillset:  
            {
                skill: `30s CT - Increases all allies' Arts gauge by 12. If the user is an Earth unit, recovers all allies' HP by 15%.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
{ //Toman Flag - Complete
        id: 151861,
        name: `Toman Flag`,
        location: `Limited Crossover Event: Tokyo Revengers`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151860.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151861.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151860.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151861.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `35s CT - Increases all allies' Arts gauge by 25. Increases Human units' ATK by 35% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `DEF 5% UP.`
            },
        lore: `The Tokyo Manji Gang's flag. The standard bearer was Pah-chin, one of the founding members.`
    },
{ //Mikey's Ride - Complete
        id: 151871,
        name: `Mikey's Ride`,
        location: `Crossover Equip Summon: Tokyo Revengers`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151870.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151871.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151870.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151871.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `25s CT - 5000% Dark DMG (Physical). Auto-fills Arts gauge by 2 for 6s. If the user is a Dark Unit, additional 2 gauge.`,
                break: 500
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Originally meant as a birthday present from Mikey's older brother Shinichiro Sano. The unique sound of the exhaust boosts him members' morale and rattles his foes.`
    },
{ //Mikey's Outfit - Complete
        id: 151881,
        name: `Mikey's Outfit`,
        location: `Crossover Equip Summon: Tokyo Revengers`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151880.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151881.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151880.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151881.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `30s CT - Increases all allies' Physical DMG by 20% & reduces all allies' Dark DMG taken by 20% for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A long shirt with a checked pattern. Despite his terrifying reputation, Mikey has a childish side which does things like demand a flag on top of his kid's meal.`
    },
{ //Mikey's Jimbei - Complete
        id: 151891,
        name: `Mikey's Jimbei`,
        location: `Crossover Equip Summon: Tokyo Revengers`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151890.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151891.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151890.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151891.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `30s CT - Increases all allies' Arts gauge by 30. Increases Dark units' DMG by 40% for 8s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `What Mikey wore during the brawl with Moebius on August 3rd. He managed to show Peh-yan who had detected the error of his ways.`
    },
{ //Mitsuya's Ride - Complete
        id: 151901,
        name: `Mitsuya's Ride`,
        location: `Crossover Equip Summon: Tokyo Revengers`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151900.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151901.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151900.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151901.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 7000% Water DMG (Physical). Reduces enemy's Water resistance by 40% for 10s.`,
                break: 400
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Mitsuya's motorcycle which he nicknamed "Kitty". He revs the engine with a unique rhythm easily identifiable among the other members.`
    },
{ //Mitsuya's Earring - Missing Lore
        id: 151911,
        name: `Mitsuya's Earring`,
        location: `Crossover Equip Summon: Tokyo Revengers`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151910.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151911.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151910.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151911.png`
            },
        stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
        skillset:  
            {
                skill: `35s CT - Increases target's DMG by 60% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
{ //Toman Uniform - Complete
        id: 151921,
        name: `Toman Uniform`,
        location: `Limited Crossover Event: Tokyo Revengers`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151920.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151921.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151920.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151921.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 6000% Dark DMG (Physical). Increases DMG to Giant Boss by 40% for 8s. Increases Arts gauge by 40 (First time only).`,
                break: 600
            },
        passive: 
            {
                ability1: `ATK 5% UP.`
            },
        lore: `Black clothes with "Tokyo Manji Gang" embroidered in Japanese. Draken gave Takemichi the outfit Mikey wore immediately after it was founded.`
    },
{ //Clover Necklace - Complete
        id: 151931,
        name: `Clover Necklace`,
        location: `Crossover Equip Summon: Tokyo Revengers`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151930.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151931.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151930.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151931.png`
            },
        stats:
            {
                hp: 500,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - Recovers all allies' HP by 20%. If the HP recovers to MAX, increases the units' Arts gauge by 20.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A precious accessory that symbolizes Takemichi's feelings for Hinata.`
    },
{ //Draken's Ride - Complete
        id: 151941,
        name: `Draken's Ride`,
        location: `Crossover Equip Summon: Tokyo Revengers`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151940.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151941.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151940.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151941.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `80s CT - 12000% Light DMG (Physical). Increases ATK by 100% for 12s. If the user is a Human unit, 50% more.`,
                break: 1000
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Draken's personally customized motorcycle. He boasts it is the greatest machine in all of Japan.`
    },
{ //Draken's Outfit - Complete
        id: 151951,
        name: `Draken's Outfit`,
        location: `Crossover Equip Summon: Tokyo Revengers`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151950.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151951.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151950.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151951.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 8000% Light DMG (Magic). Auto-fills Arts gauge by 4 for 10s.`,
                break: 500
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Draken's usual outfit. He has others like it hanging in his room.`
    },
{ //Chifuyu's School Uniform - Complete
        id: 151961,
        name: `Chifuyu's School Uniform`,
        location: `Crossover Equip Summon: Tokyo Revengers`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151960.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151961.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151960.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151961.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 4000% Earth DMG (Physical). Increases DMG to enemy by 20% & reduces enemy's Earth resistance by 30% for 10s.`,
                break: 500
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The outfit that Chifuyu, the First Division Vice Captain of Toman, wears to school. He attends a different one from his partner Takemichi.`
    },
{ //Coil Gun - Complete
        id: 151971,
        name: `Coil Gun`,
        location: `Crossover Equip Summon: Tokyo Revengers`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151970.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151971.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151970.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151971.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 6000% Water DMG (Physical). Increases CRI DMG by 60% for 10s. Increases Arts gauge by 40 when HP is 80% or more.`,
                break: 400
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A weapon Empress created with reconstructed atmospheric Arche.`
    },
{ //Black Trike - Complete
        id: 151981,
        name: `Black Trike`,
        location: `Crossover Equip Summon: Black Rock Shooter`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151980.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151981.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151980.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151981.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `30s CT - Reduces target's Water DMG taken by 30% for 20s. Increases target's Arts gauge by 50 (First time only).`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A Hemiteos Unit high-mobility military support vehicle used by Empress. On-board AI gives it the ability to speak, although bluntly.`
    },
{ //Empress' Outfit - Complete
        id: 151991,
        name: `Empress' Outfit`,
        location: `Limited Crossover Event: Black Rock Shooter`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_151990.png`,
                detailmax: `/db/Equipment/Detail/item_detail_151991.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_151990.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_151991.png`
            },
        stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
        skillset:  
            {
                skill: `35s CT - Increases all allies' Arts gauge by 15. If the user is a Water unit, reduces all allies' DMG taken by 10% for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: `DEF 5% UP.`
            },
        lore: `Combat gear Empress found in a nearby case when she woke up in the year 2062 AD. It is lightweight and customized for a Hemiteos Unit.`
    },
{ //Dead Wing - Complete
        id: 152001,
        name: `Dead Wing`,
        location: `Crossover Equip Summon: Black Rock Shooter`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152000.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152001.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152000.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152001.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 12000% Earth DMG (Physical). Reduces enemy's Earth resistance by 40% & Evasion rate by 30% for 8s.`,
                break: 800
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A tactical boomerang with a unique design Dead Master keeps stored in her motorbike. She relies on it when unable to use her sniper rifle.`
    },
{ //Dead Master's Bike - Complete
        id: 152011,
        name: `Dead Master's Bike`,
        location: `Crossover Equip Summon: Black Rock Shooter`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152010.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152011.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152010.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152011.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 8000% Earth DMG (Magic). Increases Earth DMG by 50% for 10s. Increases extra 30% when HP is 80% or more.`,
                break: 800
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A Hemiteos Unit high-mobility military support vehicle used by Dead Master.`
    },
{ //Dead Master's Bodysuit - Complete
        id: 152021,
        name: `Dead Master's Bodysuit`,
        location: `Crossover Equip Summon: Black Rock Shooter`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152020.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152021.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152020.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152021.png`
            },
        stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
        skillset:  
            {
                skill: `40s CT - Increases DMG to Giant Boss by 40% for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The outfit Dead Master wears a as Hemiteos Unit. It offers full coverage in contrast to Empress'.`
    },
{ //Gigantic Arms - Complete
        id: 152031,
        name: `Gigantic Arms`,
        location: `Crossover Equip Summon: Black Rock Shooter`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152030.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152031.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152030.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152031.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `80s CT - 12000% Fire DMG (Physical). Increases Arts DMG by 50% for 20s. Increases Fire units' DMG by 30% for 20s.`,
                break: 1200
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Massive artificial limb weapon worn by Strength. Either fist has enough power to crush a vehicle.`
    },
{ //Strength's Buggy - Complete
        id: 152041,
        name: `Strength's Buggy`,
        location: `Crossover Equip Summon: Black Rock Shooter`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152040.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152041.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152040.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152041.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `55s CT - 10000% Fire DMG (Physical). If the used is a Fire unit, increases BE Output by 80% for 10s.`,
                break: 1100
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A Hemiteos Unit high-mobility military support vehicle used by Strength.`
    },
{ //Strength's Overcoat - Complete
        id: 152051,
        name: `Strength's Overcoat`,
        location: `Crossover Equip Summon: Black Rock Shooter`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152050.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152051.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152050.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152051.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 6000% Fire DMG (Physical). Increases ATK by 20% for 8s. Increases extra 20% (First time only).`,
                break: 400
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Worn by Strength as a Hemiteos Unit. Its thickness provides excellent defense for close combat.`
    },
{ //Empress' Outift EV - Complete
        id: 152061,
        name: `Empress' Outift EV`,
        location: `Craftable - Using Black Rock Shooter collab materials`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 4,
        image: 
            {
                detail: ``,
                detailmax: `/db/Equipment/Detail/item_detail_152061.png`,
                thumb: ``,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152061.png`
            },
        stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
        skillset:  
            {
                skill: `35s CT - Increases all allies' Arts gauge by 15. If the user is a Water unit, reduces all allies' DMG taken by 10% for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: `DEF 5% UP.`,
                ability2: [`DMG to Fire enemy 5% UP.`, `DMG to Gods 10% UP.`, `ATK 5% UP when HP is 90% or more.`, `Water resistance 5% UP.`, `DEF 10% UP when HP is 90% or more.`, `HP Recovery amount 5% UP (Except Lifesteal).`]
            },
        lore: `Combat gear Empress found in a nearby case when she woke up in the year 2062 AD. It is lightweight and customized for a Hemiteos Unit.`
    },
{ //Emilia's Casual Dress - Missing Lore
        id: 152071,
        name: `Emilia's Casual Dress`,
        location: `Crossover Equip Summon: Re:Zero`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152070.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152071.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152070.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152071.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `40s CT - Auto-fills all allies' Arts gauge by 3 for 10s. Increase all allies' DMG by [30% x number of Water allies] for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
{ //Young Rem's Robe - Missing Lore
        id: 152081,
        name: `Young Rem's Robe`,
        location: `Crossover Equip Summon: Re:Zero`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152080.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152081.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152080.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152081.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 5000% Water DMG (Magic). Increases DMG to Giant Bosses by 60% for 15s. Increases Arts gauge by 80 (First time only).`,
                break: 700
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
{ //Young Ram's Robe - Missing Lore
        id: 152091,
        name: `Young Ram's Robe`,
        location: `Crossover Equip Summon: Re:Zero`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152090.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152091.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152090.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152091.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `30s CT - Recovers all allies' HP by 2000. Reduces all allies' Water DMG taken by 30% for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
{ //Beatrice's Crown - Missing Lore
        id: 152101,
        name: `Beatrice's Crown`,
        location: `Crossover Equip Summon: Re:Zero`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152100.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152101.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152090.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152101.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `80s CT - 10000% Dark DMG (Magic). Increases own Magic DMG by 60% and Dark allies' DMG by 40% for 20s.`,
                break: 1000
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
{ //Beatrice's Finery - Missing Lore
        id: 152111,
        name: `Beatrice's Finery`,
        location: `Crossover Equip Summon: Re:Zero`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152110.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152111.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152110.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152111.png`
            },
        stats:
            {
                hp: 120,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `30s CT - Recovers all allies' HP by 1000. Heals all allies' Blind.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
{ //Subaru's Cell Phone - Missing Lore
        id: 152121,
        name: `Subaru's Cell Phone`,
        location: `Limited Crossover Event: Re:Zero`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152120.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152121.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152120.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152121.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `50s CT - Increases God, Demon, and Spirit units' ATK & DMG by 50% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Blind Time Reduction & Blind evasion rate 10% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Subaru's Cell Phone EV - Missing Lore
        id: 152131,
        name: `Subaru's Cell Phone EV`,
        location: `Craftable - Using Re:Zero Raid materials`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: ``,
                detailmax: `/db/Equipment/Detail/item_detail_152131.png`,
                thumb: ``,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152131.png`
            },
            stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `50s CT - Increases God, Demon, and Spirit units' ATK & DMG by 50% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Blind Time Reduction & Blind evasion rate 10% UP.`,
                ability2: [`DMG to Spirits 10% UP.`, `DMG to Gods 10% UP.`, `DMG to Demons 10% UP.`, `Stauts Ailment Resistance 10% UP (Except Faint).`, `Skill DMG 10% UP.`, `ATK 10% UP when HP is 90% or more.`]
            },
        lore: `Missing Lore.`
    },
{ //Wooden Stick - Missing Lore
        id: 152141,
        name: `Wooden Stick`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152140.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152141.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152140.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152141.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 5000% Dark DMG (Magic). Increase own Magic DMG by 50% for 10s. If user is Demon, increase own Equipment CT Recovery by 30% for 10s.`,
                break: 300
            },
        passive: 
            {
                ability1: `Light resistance 5% UP.`
            },
        lore: `Missing Lore.`
    },
{ //3F - Missing Lore
        id: 152151,
        name: `3F`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152150.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152151.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152150.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152151.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 10000% Dark DMG (Physical). Increase all stats by 25% for 10s. If user is Dark, increase own ATK by 25% for 10s.`,
                break: 1000
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
{ //Hermes Trismegistus - Missing Lore
        id: 152161,
        name: `Hermes Trismegistus`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152160.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152161.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152160.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152161.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `35s CT - Increase all allies' DMG resistance by 25% for 20s. For the first 3 uses, additionally increase all allies' Physical resistance by 25% for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
{ //Albedo's Dress - Missing Lore
        id: 152171,
        name: `Albedo's Dress`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152170.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152171.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152170.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152171.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 120
            },
        skillset:  
            {
                skill: `40s CT - Increase all allies' CRI DMG resistance by 30% for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
{ //Shalltear's Parasol - Missing Lore
        id: 152181,
        name: `Shalltear's Parasol`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152180.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152181.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152180.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152181.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 7000% Light DMG (Magic). Increase own Magic DMG by 50% and reduce enemies' Light resistance by 30% (Stacks) for 12s.`,
                break: 600
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
{ //Pipette Lance - Missing Lore
        id: 152191,
        name: `Pipette Lance`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152190.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152191.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152190.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152191.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `55s CT - 7000% Light DMG (Magic). Increase own CRI rate by 100% for 10s. When own HP is 80% or higher, increase own CRI DMG by 60% for 10s.`,
                break: 500
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
{ //Shalltear's Gown - Missing Lore
        id: 152201,
        name: `Shalltear's Gown`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152200.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152201.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152200.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152201.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 4000% Light DMG (Physical). Increase own ATK by 30% for 10s. If user is Light, increase own Accuracy rate by 20% for 10s.`,
                break: 600
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
{ //Wooden Stick EV - Missing Lore
        id: 152211,
        name: `Wooden Stick EV`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: ``,
                detailmax: `/db/Equipment/Detail/item_detail_152211.png`,
                thumb: ``,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152211.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 5000% Dark DMG (Magic). Increase own Magic DMG by 50% for 10s. If user is Demon, increase own Equipment CT REC by 30% for 10s.`,
                break: 300
            },
        passive: 
            {
                ability1: `Light resistance 5% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Staff of Ainz Ooal Gown - Missing Lore
        id: 152221,
        name: `Staff of Ainz Ooal Gown`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152220.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152221.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152220.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152221.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `120s CT - 17000% Dark DMG (Magic). Increase own BE Output by 30% for 10s.`,
                break: 1000
            },
        passive: 
            {
                ability1: ``
            },
            lore: `Missing Lore.`
    },
{ //Shooting Star (OL) - Missing Lore
        id: 152231,
        name: `Shooting Star (OL)`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152230.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152231.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152230.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152231.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 3000% Dark DMG (Magic). Reduce enemies' Magic resistance by 35% for 8s. When used for the first time, increase own Arts gauge by 80.`,
                break: 800
            },
        passive: 
            {
                ability1: ``
            },
            lore: `Missing Lore.`
    },
{ //Ainz's Cloak - Missing Lore
        id: 152241,
        name: `Ainz's Cloak`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152240.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152241.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152240.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152241.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `40s CT - Increase all allies' Arts gauge by 3 and DMG by [30% x number of Dark allies] for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
{ //Hourglass - Missing Lore
        id: 152251,
        name: `Hourglass`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152250.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152251.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152250.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152251.png`
            },
        stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
        skillset:  
            {
                skill: `45s CT - Increase Dark allies' DMG by 30% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
            lore: `Missing Lore.`
    },
{ //Keraunos Mk-III - Missing Lore
        id: 152261,
        name: `Keraunos Mk-III`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152260.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152261.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152260.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152261.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `30s CT - Increase all allies' Magic resistance by 20% and Arts gauge by 3 for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
            lore: `Missing Lore.`
    },
{ //Narberal's Headdress - Missing Lore
        id: 152271,
        name: `Narberal's Headdress`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152270.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152271.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152270.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152271.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `40s CT - Increase all allies' Arts gauge by 30. If user is Light, increase all allies' MAX HP by 30% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
            lore: `Missing Lore.`
    },
{ //Narberal's Battle Dress - Missing Lore
        id: 152281,
        name: `Narberal's Battle Dress`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152280.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152281.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152280.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152281.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 3000% Light DMG (Magic). Increase own DMG against Giant Bosses by 30% for 10s.`,
                break: 300
            },
        passive: 
            {
                ability1: ``
            },
            lore: `Missing Lore.`
    },
{ //Jealousy Mask - Missing Lore
        id: 152291,
        name: `Jealousy Mask`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152290.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152291.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152290.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152291.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `60s CT - Increase all allies' Arts gauge by 2 and all allies' Magic DMG by 20% for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: `DEF 5% UP.`
            },
        lore: `Missing Lore.`
    },
{ //Jealousy Mask EV - Missing Lore
        id: 152301,
        name: `Jealousy Mask EV`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: ``,
                detailmax: `/db/Equipment/Detail/item_detail_152301.png`,
                thumb: ``,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152301.png`
            },
            stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `60s CT - Increase all allies' Arts gauge by 2 and all allies' Magic DMG by 20% for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: `DEF 5% UP.`,
                ability2: [`Blind Time Reduction & Blind Evasion rate 20% UP.`, `Blind Time Reduction & Blind Evasion rate 10% UP.`, `Magic resistance 5% UP.`, `Physical resistance 5% UP.`, `ATK 5% UP.`, `Unit EXP 10% UP.`]
            },
        lore: `Missing Lore.`
    },
{ //Rimuru's Clothes EV - Complete
        id: 152311,
        name: `Rimuru's Clothes EV`,
        location: `Craftable - Using That Time I Got Reincarnated as a Slime collab materials`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 4,
        image: 
            {
                detail: ``,
                detailmax: `/db/Equipment/Detail/item_detail_152311.png`,
                thumb: ``,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152311.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 5800% Water DMG (Magic). Increases Water units' ATK by 20% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `DMG to Fire enemy 5% UP.`,
                ability2: [`BE Output 5% UP.`, `BE Output 10% UP.`, `Earth resistance 5% UP.`, `Blind Time Reduction & Blind Evasion rate 10% UP.`, `Poison Time Reduction & Poison Evasion rate 10% UP.`, `MAX HP 5% UP.`]
            },
        lore: `The clothes that Rimuru wears during a fight. It was made by dwarf blacksmiths, Garm and Dord, and a Kijin, Shuna. It contains Magicule, so it is stronger than regular cloth and has high protection.`
    },
{ //Magic-seal Mask EV - Complete
        id: 152321,
        name: `Magic-seal Mask EV`,
        location: `Craftable - Using That Time I Got Reincarnated as a Slime collab materials`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: ``,
                detailmax: `/db/Equipment/Detail/item_detail_152321.png`,
                thumb: ``,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152321.png`
            },
            stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `35s CT - Increases Target's Arts gauge by 15 for 5s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Reduces Magical DMG Taken by 5%.`,
                ability2: [`Status Ailment Time Reduction & Evasion rate 10% UP (Except Faint).`, `ATK 5% UP.`, `ATK 10% UP.`, `DEF 5% UP.`, `DEF 10% UP.`, `Accuracy 10% UP.`]
            },
        lore: `The mask that Shizue Izawa, a human from a different world and also known as "Conqueror of Flames" wore. After her death, Rimuru who is also a human from the same world of her has been wearing it. It can seal magic powers. Thus, when Rimuru wears it his Magicules are sealed and will not be recognized as a monster.`
    },
{ //Gourikimaru EV - Complete
        id: 152331,
        name: `Gourikimaru EV`,
        location: `Craftable - Using That Time I Got Reincarnated as a Slime collab materials`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: ``,
                detailmax: `/db/Equipment/Detail/item_detail_152331.png`,
                thumb: ``,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152331.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 6000% Dark DMG (Physical). Increases BE Output by 30% for 10s. If the user is a Demon, 10% more.`,
                break: 350
            },
        passive: 
            {
                ability1: `ATK 5% UP.`,
                ability2: [`Evasion rate 5% UP when Near-Death.`, `DEF 5% UP.`, `MAX HP 5% UP.`, `MAX HP 10% UP.`, `Skill CT speed 5% UP.`, `Skill CT speed 10% UP.`]
            },
        lore: `A huge sword carried by Shion. Few can withstand its overwhelming strength. When she offered to slice a fish to help with the Christmas cooking, it seemed she also cut through the table and even left a large dent in the floor...`
    },
{ //Rimuru's Scarf EV - Complete
        id: 152341,
        name: `Rimuru's Scarf EV`,
        location: `Craftable - Using That Time I Got Reincarnated as a Slime collab materials`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: ``,
                detailmax: `/db/Equipment/Detail/item_detail_152341.png`,
                thumb: ``,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152341.png`
            },
            stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `30s CT - 2500% Dark DMG (Physical). Increases weak-elemental DMG by 40% for 10s.`,
                break: 400
            },
        passive: 
            {
                ability1: `Skill DMG 20% UP.`,
                ability2: [`DEF 5% UP.`, `DEF 10% UP.`, `MAX HP 5% UP.`, `DMG to Demons 10% UP.`, `Break power 2% UP.`, `All allies' HP Heal amount 5% UP (Except Lifesteal).`]
            },
        lore: `Rimuru made this based on past New Year memories after seeing Reigrad's celebration. It was originally just an accessory to complete the look with his Haori, but Rimuru truly appreciates its high defensive power and warmth.`
    },
    { //True 'Magical Ruby' - Complete
        id: 1523111,
        name: `True 'Magical Ruby'`,
        type: `/db/Mines/thumbnail/magLB.png`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152310.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152311_pris.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152310.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152311_pris.png`
            },
            stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 6000% Light DMG (Magic). If own HP is 80% or above, increase own Magic DMG by 70% for 10s. If own HP is below 80%, heal 20% of MAX HP instead.`,
                break: 700
            },
        passive: 
            {
                ability1: `When equipped by Illya, increase own DMG and stats by 20% (No Duplication).`,
                ability2: `Increase ATK by 5%.`
            },
        lore: `The Kaleidostick of love and justice. She supplies endless power to whoever forms a magical girl contract with her, and she also has hte miraculous ability to make whatever that can be imagined reality. She was created along with Magical Sapphire.`
    },
    { //True 'Zelretch - Complete
        id: 1523211,
        name: `True 'Zelretch'`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        location: `Alchemist - Exchange via Rainbow Gems`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152320.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152321_pris.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152320.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152321_pris.png`
            },
            stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `45s CT - 6000% Fire DMG (Magic). Consume 2%/s of own max HP, increase Allies' Arts by 4/s and increase Fire Allies' DMG by 40% for 10s.`,
                break: 500
            },
        passive: 
            {
                ability1: `When equipped by Rin & Luvia, increase own Arts by 2/s and ATK by 30% (No Duplication).`,
                ability2: `Increase Magic resistance by 5%.`
            },
        lore: `A Mystic Code named after its creator, the Wizard Marshal Zelretch. The blade is a massive crystal instead of sharpened metal, making it more of a staff than a sword.`
    },
    { //Magical Ruby - Complete
        id: 1523311,
        name: `Magical Ruby`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152330.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152331_pris.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152330.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152331_pris.png`
            },
            stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 8000% Light DMG (Magic). Reduce enemies' Light resistance by 40% for 10s. If user is Human, reduce enemies' Light resistance by 60% instead.`,
                break: 800
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The Kaleidostick of love and justice. She supplies endless power to whoever forms a magical girl contract with her, and she also has hte miraculous ability to make whatever that can be imagined reality. She was created along with Magical Sapphire.`
    },
    { //Excalibur - Complete
        id: 1523411,
        name: `Excalibur`,
        type: `/db/Mines/thumbnail/physLB.png`,
        location: `Crossover Equip Summon: Fate/PRISMA☆ILLYA Collab`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152340.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152341_pris.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152340.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152341_pris.png`
            },
            stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `75s CT - 16000% Light DMG (Physical). Increase own ATK by 80% for 10s. If user is Light, increase own ATK by 130% instead.`,
                break: 800
            },
        passive: 
            {
                ability1: ``
            },
        lore: `The strongest of all holy blades, known for its immense power and range. This is a Traced version created by Illya with a Class Card.`
    },
    { //Illya's Saber Dress - Complete
        id: 152351,
        name: `Illya's Saber Dress`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        location: `Crossover Equip Summon: Fate/PRISMA☆ILLYA Collab`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152350.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152351.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152350.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152351.png`
            },
            stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
        skillset:  
            {
                skill: `40s CT - Increase all allies' Arts gauge by 20. If user is Human, increase all allies' DMG resistance by 15% for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `When Illya installs the Saber Class Card, the Heroic Spirit of King Arthur gives her this special outfit.`
    },
    { //Jeweled Sword Zelretch - Complete
        id: 152361,
        name: `Jeweled Sword Zelretch`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        location: `Crossover Equip Summon: Fate/PRISMA☆ILLYA Collab`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152360.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152361.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152360.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152361.png`
            },
            stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `55s CT - Increase all allies' Arts gauge by 4/s and increase all Human allies' CRI rate by 100% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A Mystic Code named after its creator, the Wizard Marshal Zelretch. The blade is a massive crystal instead of sharpened metal, making it more of a staff than a sword.`
    },
    { //Rin Tohsaka's Clothes - Complete
        id: 152371,
        name: `Rin Tohsaka's Clothes`,
        type: `/db/Mines/thumbnail/magLB.png`,
        location: `Crossover Equip Summon: Fate/PRISMA☆ILLYA Collab`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152370.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152371.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152370.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152371.png`
            },
            stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 4000% Fire DMG (Magic). Increase own Magic DMG by 50% for 10s. Increase own Arts gauge by 30. When used for the first time, increase own Arts gauge by 80 instead.`,
                break: 300
            },
        passive: 
            {
                ability1: ``
            },
        lore: `A tight-fitting turtleneck sweater, mini-skirt, and knee-high socks.`
    },
    { //Illya's Costume - Complete
        id: 152381,
        name: `Illya's Costume`,
        type: `/db/Mines/thumbnail/defLB.png`,
        location: `Limited Crossover Event: Fate/PRISMA☆ILLYA Collab`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152380.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152381.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152380.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152381.png`
            },
            stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `55s CT - Increase all allies' DMG resistance by 10% and all Light allies' DMG resistance by 10% for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: `BE Output 5% UP.`
            },
        lore: `The outfit Illya wears when she transforms into a magical girl. According to Magical Ruby, she gains Magic Barrier, Physical Defense, Healing Factor, and Enhanced Physical Ability and other A-rank passives.`
    },
    { //True 'Magical Sapphire' - Missing Lore
        id: 152431,
        name: `True 'Magical Sapphire'`,
        type: `/db/Mines/thumbnail/magLB.png`,
        location: `Crossover True Equip: Fate/PRISMA☆ILLYA Collab`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152430.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152431.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152430.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152431.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 8000% Dark DMG (Magic). Increase own ATK by 50% and reduce enemies' Magic resistance by 30% for 10s.`,
                break: 500
            },
        passive: 
            {
                ability1: `When equipped by Miyu, increase DMG by 60% against Non-Giant Bosses. Does not stack with Abilities of the same name.`,
                ability2: `Magic resistance 5% UP.`
            },
        lore: `Missing Lore.`
    },
{ //True 'Rho Aias' - Missing Lore
        id: 152441,
        name: `True 'Rho Aias'`,
        type: `/db/Mines/thumbnail/defLB.png`,
        location: `Crossover True Equip: Fate/PRISMA☆ILLYA Collab`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152440.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152441.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152440.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152441.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `35s CT - Increase All Allies' DMG resistance by 30% for 10s. If used by Emiya Shirou, increase its duration by an additional 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `When Equipped by Shirou Emiya, At Start of Quest, Increase own Arts by 100 and increase own Skill CT REC by 20%.`,
                ability2: `Increase own Heal Recovery by 5%.`
            },
        lore: `Missing Lore.`
    },
{ //Magical Sapphire - Missing Lore
        id: 152451,
        name: `Magical Sapphire`,
        type: `/db/Mines/thumbnail/magLB.png`,
        location: `Crossover Equip Summon: Fate/PRISMA☆ILLYA Collab`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152450.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152451.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152450.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152451.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 8000% Dark DMG (Magic). Reduce enemies' Dark resistance by 40% for 10s. If user is Human, reduce enemies' Dark resistance by 60% instead.`,
                break: 800
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
{ //Gáe Bolg - Missing Lore
        id: 152461,
        name: `Gáe Bolg`,
        translate: `Gae Bolg`,
        type: `/db/Mines/thumbnail/magLB.png`,
        location: `Crossover Equip Summon: Fate/PRISMA☆ILLYA Collab`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152460.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152461.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152460.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152461.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `75s CT - 8000% Dark DMG (Magic). Increase own Dark DMG by 100% and own Accuracy rate by 50% for 15s.`,
                break: 600
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
{ //Miyu's Rider Dress - Missing Lore
        id: 152471,
        name:  `Miyu's Rider Dress`,
        type: `/db/Mines/thumbnail/defLB.png`,
        location: `Crossover Equip Summon: Fate/PRISMA☆ILLYA Collab`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152470.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152471.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152470.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152471.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 120
            },
        skillset:  
            {
                skill: `60s CT - Increase all allies' DMG resistance by 10%, Light resistance by 20%, and Dark resistance by 20% for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
{ //Rho Aias - Missing Lore
        id: 152481,
        name: `Rho Aias`,
        type: `/db/Mines/thumbnail/defLB.png`,
        location: `Crossover Equip Summon: Fate/PRISMA☆ILLYA Collab`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152480.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152481.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152480.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152481.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `30s CT - Increase all allies' Magic resistance by 30% for 20s. If user is Human, increase all allies' Magic resistance by 45% instead.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
{ //Shirou Emiya's Outfit - Missing Lore
        id: 152491,
        name: `Shirou Emiya's Outfit`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        location: `Crossover Equip Summon: Fate/PRISMA☆ILLYA Collab`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152490.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152491.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152490.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152491.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `30s CT - Increase all allies' Arts gauge by 20. Increase Human allies' DMG by 60% for 12s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
{ //Miyu's Costume - Missing Lore
        id: 152501,
        name: `Miyu's Costume`,
        type: `/db/Mines/thumbnail/magLB.png`,
        location: `Crossover Equip Summon: Fate/PRISMA☆ILLYA Collab`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152500.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152501.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152500.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152501.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 6000% Dark DMG (Magic). Increase own Magic DMG by 50% for 10s. If user is Human, increase own Arts gauge by 2/s for 10s.`,
                break: 400
            },
        passive: 
            {
                ability1: `DMG against Light enemies 5% UP.`
            },
        lore: `Missing Lore.`
    },
{ //True 'Kanshou & Bakuya' - Missing Lore
        id: 152511,
        name: `True 'Kanshou & Bakuya'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        location: `Crossover Equip Summon: Fate/PRISMA☆ILLYA Collab`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152510.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152511.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152510.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152511.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `55s CT - 4000% Fire DMG (Physical). When used for the first time, increase own Arts gauge by 50.`,
                break: 4000
            },
        passive: 
            {
                ability1: `When equipped by Chloe (Fate), increase own Break Power by 40% & auto-fills Arts gauge by 2/s. Does not stack with Abilities of the same name.`,
                ability2: `Physical resistance 5% Up.`
            },
        lore: `Missing Lore.`
    },
{ //True 'Excalibur Morgan' - Missing Lore
        id: 152521,
        name: `True 'Excalibur Morgan'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        location: `Crossover Equip Summon: Fate/PRISMA☆ILLYA Collab`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152520.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152521.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152520.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152521.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 8000% Dark DMG (Physical). Increase own DMG by 50% for 10s. If user is Dark, increase own ATK by 50% for 10s.`,
                break: 700
            },
        passive: 
            {
                ability1: `When equipped by Saber Alter, Increase own CRI DMG by 80%. Does not stack with Abilities of the same name.`,
                ability2: `Magic resistance 5% Up.`
            },
        lore: `Missing Lore.`
    },
{ //Kanshou & Bakuya - Missing Lore
        id: 152531,
        name: `Kanshou & Bakuya`,
        type: `/db/Mines/thumbnail/physLB.png`,
        location: `Crossover Equip Summon: Fate/PRISMA☆ILLYA Collab`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152530.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152531.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152530.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152531.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 1000% Fire DMG (Physical). If User is Fire, increase own Arts gauge by 20.`,
                break: 3200
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
{ //Fake Nine Lives - Missing Lore
        id: 152541,
        name: `Fake Nine Lives`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        location: `Crossover Equip Summon: Fate/PRISMA☆ILLYA Collab`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152540.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152541.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152540.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152541.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `30s CT - Increase all Allies' Arts gauge by 3 and Physical DMG by 35% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
{ //Chloe's Outfit - Missing Lore
        id: 152551,
        name: `Chloe's Outfit`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        location: `Crossover Equip Summon: Fate/PRISMA☆ILLYA Collab`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152550.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152551.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152550.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152551.png`
            },
        stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
        skillset:  
            {
                skill: `35s CT - Increase target's Arts gauge by 4 for 10s. If user is Fire, increase target's Physical DMG by 20% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
{ //Excalibur Morgan - Missing Lore
        id: 152561,
        name: `Excalibur Morgan`,
        type: `/db/Mines/thumbnail/physLB.png`,
        location: `Crossover Equip Summon: Fate/PRISMA☆ILLYA Collab`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152560.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152561.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152560.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152561.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `80s CT - 14000% Dark DMG (Physical). Increase own Arts DMG by 60% & all Dark Allies' DMG by 40% for 15s.`,
                break: 800
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
{ //Saber Alter's Armor - Missing Lore
        id: 152571,
        name: `Saber Alter's Armor`,
        type: `/db/Mines/thumbnail/defLB.png`,
        location: `Crossover Equip Summon: Fate/PRISMA☆ILLYA Collab`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152570.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152571.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152570.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152571.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `30s CT - Increase all Allies' Dark resistance by 30% & Heal all Allies' HP by 100 for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
{ //Caladbolg III - Missing Lore
        id: 152581,
        name: `Caladbolg III`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        location: `Crossover Equip Summon: Fate/PRISMA☆ILLYA Collab`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152580.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152581.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152580.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152581.png`
            },
        stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
        skillset:  
            {
                skill: `40s CT - 2500% Fire DMG (Physical). Increases all allies' Arts gauge by 10. If user is Human, increase all allies' Arts gauge by 20 instead.`,
                break: 300
            },
        passive: 
            {
                ability1: `ATK 5% UP.`
            },
        lore: `Missing Lore.`
    },
    { //Gabimaru's Mask - Missing Lore
        id: 152631,
        name: `Gabimaru's Mask`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        location: `Crossover Event Equip: Hell's Paradise`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152630.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152631.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152630.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152631.png`
            },
        stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
        skillset:  
            {
                skill: `35s CT - Increase all allies' Arts gauge by 15. Additionally if user is Fire, increase all allies' DMG resistance by 10% for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: `DEF 5% UP.`
            },
        lore: `Missing Lore.`
    },
    { //Gabimaru's Mask EV - Missing Lore
        id: 152641,
        name: `Gabimaru's Mask EV`,
        location: `Craftable - Using Hell's Paradise collab materials`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 4,
        image: 
            {
                detail: ``,
                detailmax: `/db/Equipment/Detail/item_detail_152641.png`,
                thumb: ``,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152641.png`
            },
        stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
            skillset:  
            {
                skill: `35s CT - Increase all allies' Arts gauge by 15. Additionally if user is Fire, increase all allies' DMG resistance by 10% for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: `DEF 5% UP.`,
                ability2: [`DMG 10% UP against Gods.`, `DMG 10% UP against Demons.`, `Fire resistance 5% UP.`, `Water resistance 5% UP.`, `Heal own HP by 4/s.`, `When HP is 90% or more, Skill CT speed 10% UP.`]
            },
        lore: `Missing Lore.`
    },
    { //Gabimaru's Gi - Missing Lore
        id: 152651,
        name: `Gabimaru's Gi`,
        type: `/db/Mines/thumbnail/physLB.png`,
        location: `Crossover Equip Summon: Hell's Paradise`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152650.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152651.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152650.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152651.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 4000% Fire DMG (Physical). Increase own Physical DMG by 60% for 10s. Additionally if user is Fire, increase own Freeze/Paralysis resistance by 100% for 10s.`,
                break: 400
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Gabimaru's Tabi - Missing Lore
        id: 152661,
        name: `Gabimaru's Tabi`,
        type: `/db/Mines/thumbnail/magLB.png`,
        location: `Crossover Equip Summon: Hell's Paradise`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152660.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152661.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152660.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152661.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 5000% Fire DMG (Magic). Increase own ATK by 80% for 8s. Additionally, increase own Arts gauge by 80 when used for the first time.`,
                break: 800
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Sagiri's Katana - Missing Lore
        id: 152671,
        name: `Sagiri's Katana`,
        type: `/db/Mines/thumbnail/physLB.png`,
        location: `Crossover Equip Summon: Hell's Paradise`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152670.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152671.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152670.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152671.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `55s CT - 7000% Earth DMG (Physical). If enemy is Human, reduce enemies' DMG resistance by 50% for 10s.`,
                break: 700
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Sagiri's Reifuku - Missing Lore
        id: 152681,
        name: `Sagiri's Reifuku`,
        type: `/db/Mines/thumbnail/physLB.png`,
        location: `Crossover Equip Summon: Hell's Paradise`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152680.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152681.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152680.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152681.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 4500% Earth DMG (Physical). Reduce enemies' Physical resistance and Evasion rate by 30% for 10s.`,
                break: 500
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Sagiri's Kote - Missing Lore
        id: 152691,
        name: `Sagiri's Kote`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        location: `Crossover Equip Summon: Hell's Paradise`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152690.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152691.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152690.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152691.png`
            },
        stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
        skillset:  
            {
                skill: `40s CT - Increase all allies' Arts gauge by 20. Increase all allies' Earth resistance by 15% for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Sagiri's Kamikazari - Missing Lore
        id: 152701,
        name: `Sagiri's Kamikazari`,
        type: `/db/Mines/thumbnail/defLB.png`,
        location: `Crossover Equip Summon: Hell's Paradise`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152700.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152701.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152700.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152701.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 120
            },
        skillset:  
            {
                skill: `25s CT - Increase all allies' Magic resistance by 20%, and Poison resistance and Disease resistance by 100% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Yuzuriha's Kunai - Missing Lore
        id: 152711,
        name: `Yuzuriha's Kunai`,
        location: `Crossover Equip Summon: Hell's Paradise`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152710.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152711.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152710.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152711.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 7000% Dark DMG (Physical). Increase all stats by 20% for 15s. If user is Human, increase all stats by 30% instead.`,
                break: 300
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Yuzuriha's Kanzashi - Missing Lore
        id: 152721,
        name: `Yuzuriha's Kanzashi`,
        location: `Crossover Equip Summon: Hell's Paradise`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152720.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152721.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152720.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152721.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `35s CT - Increase all allies' Arts gauge by 30. Increase own Physical DMG by 25% for 60s (Stackable).`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Yuzuriha's Takezutsu - Missing Lore
        id: 152731,
        name: `Yuzuriha's Takezutsu`,
        location: `Crossover Equip Summon: Hell's Paradise`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152730.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152731.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152730.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152731.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 4500% Dark DMG (Physical). Increase own Physical DMG by 50% and HP Recovery Amount by 30% for 20s.`,
                break: 700
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Yuzuriha's Noragi - Missing Lore
        id: 152741,
        name: `Yuzuriha's Noragi`,
        location: `Crossover Equip Summon: Hell's Paradise`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152740.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152741.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152740.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152741.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `40s CT - Increase all allies' Arts gauge by 2/s and all Human allies' Break Power by 60% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Chobe's Axe - Missing Lore
        id: 152751,
        name: `Chobe's Axe`,
        location: `Crossover Equip Summon: Hell's Paradise`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152750.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152751.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152750.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152751.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `70s CT - 12000% Light DMG (Physical). Increase own Physical DMG by 100% and reduce enemies' Light resistance by 25% for 15s.`,
                break: 800
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Chobe's Ascetic Outfit - Missing Lore
        id: 152761,
        name: `Chobe's Ascetic Outfit`,
        location: `Crossover Equip Summon: Hell's Paradise`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152760.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152761.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152760.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152761.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `50s CT - Reduce all allies' DMG taken from Gods by 30% for 30s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Chobe's Necklace - Missing Lore
        id: 152771,
        name: `Chobe's Necklace`,
        location: `Crossover Equip Summon: Hell's Paradise`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152770.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152771.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152770.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152771.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 6000% Light DMG (Physical). Increase own Light DMG by 50% for 8s. When used for the first time, increase own Arts gauge by 50.`,
                break: 400
            },
        passive: 
            {
                ability1: `Skill DMG 10% UP`
            },
        lore: `Missing Lore.`
    },
    { //Chobe's Necklace EV - Missing Lore
        id: 152781,
        name: `Chobe's Necklace EV`,
        location: `Craftable - Using Hell's Paradise collab materials`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: ``,
                detailmax: `/db/Equipment/Detail/item_detail_152781.png`,
                thumb: ``,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152781.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
            skillset:  
            {
                skill: `45s CT - 6000% Light DMG (Physical). Increase own Light DMG by 50% for 8s. When used for the first time, increase own Arts gauge by 50.`,
                break: 400
            },
        passive: 
            {
                ability1: `Skill DMG 10% UP`,
                ability2: [`DMG 10% UP against Gods.`, `DMG 5% UP against Dark enemies`, `BE Output 5% UP`, `HP Recovery Amount 5% UP`, `DEF 5% UP`, `At the Start of Quest, increase own Arts gauge by 10 (No Duplication)`]
            },
        lore: `Missing Lore.`
    },
    { //True 'Rimuru's Sword' - Missing Lore
        id: 152791,
        name: `True 'Rimuru's Sword'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152790.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152791.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152790.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152791.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 6000% Water DMG (Physical). Reduce enemies' Water resistance by 40% for 8s.`,
                break: 700
            },
        passive: 
            {
                ability1: `When equipped by Rimuru, increase own Arts by 2/s. Does not stack with Abilities of the same name.`,
                ability2: `Increase own ATK by 5%.`
            },
        lore: `Missing Lore.`
    },
{ //True 'Milim's Necklace' - Missing Lore
        id: 152801,
        name: `True 'Milim's Necklace'`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152800.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152801.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152800.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152801.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 6000% Fire DMG (Magic). Reduce enemies' Fire resistance by 40% for 8s.`,
                break: 700
            },
        passive: 
            {
                ability1: `When equipped by Milim, increase own Break Power and ATK by 50%. Does not stack with Abilities of the same name.`,
                ability2: `Increase ATK by 5%.`
            },
        lore: `Missing Lore.`
    },
{ //True 'Benimaru's Sword' - Missing Lore
        id: 152811,
        name: `True 'Benimaru's Sword'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152810.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152811.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152810.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152811.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 6000% Fire DMG (Physical). Reduce enemies' Fire resistance by 40% for 8s.`,
                break: 700
            },
        passive: 
            {
                ability1: `When equipped by Benimaru, increase own Accuracy by 50% and own Arts at the start of quest by 100. Does not stack with Abilities of the same name.`,
                ability2: `Increase ATK by 5%.`
            },
        lore: `Missing Lore.`
    },
{ //True 'Rimuru's Santa Suit' - Missing Lore
        id: 152821,
        name: `True 'Rimuru's Santa Suit'`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152820.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152821.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152820.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152821.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `30s CT - Increase Allies' Arts by 15. Increase Allies' DMG by 20% for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: `When equipped by Santa Rimuru, the Arts generation and DMG buff of this Equip's Skill is doubled. Does not stack with Abilities of the same name.`,
                ability2: `Increase Max HP by 5%.`
            },
        lore: `Missing Lore.`
    },
    { //True 'Milim's Santa Coat' - Missing Lore
        id: 152831,
        name: `True 'Milim's Santa Coat'`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152830.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152831.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152830.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152831.png`
            },
        stats:
            {
                hp: 500,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - Heal the lowest HP Ally's HP by 15%. Heal all Allies' HP by 4%/s for 6s.`,
                break: 0
            },
        passive: 
            {
                ability1: `When equipped by Santa Milim, double the Healing Amount of this equipment's Skill. Does not stack with Abilities of the same name.`,
                ability2: `Increase Max HP by 5%.`
            },
        lore: `Missing Lore.`
    },
{ //True 'Shion's Santa Dress' - Missing Lore
        id: 152841,
        name: `True 'Shion's Santa Dress'`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152840.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152841.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152840.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152841.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `25s CT - Increase Allies' DMG resistance by 15% for 12s. Increase <50% HP Allies' DMG resistance by 25% instead.`,
                break: 0
            },
        passive: 
            {
                ability1: `When equipped by Santa Shion, doubles the DMG resistance buff of this Equip's Skill. Does not stack with Abilities of the same name.`,
                ability2: `Increase Max HP by 5%.`
            },
        lore: `Missing Lore.`
    },
{ //True 'Rimuru's Haori' - Missing Lore
        id: 152851,
        name: `True 'Rimuru's Haori'`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152850.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152851.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152850.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152851.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `50s CT - Increase all allies' Arts gauge by 4/s and Dark DMG by 40% (stackable) for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `When equipped by New Year Rimuru, increase own DMG by 40%. Does not stack with Abilities of the same name.`,
                ability2: `Increase DEF by 5%.`
            },
        lore: `Missing Lore.`
    },
{ //True 'Milim's Kimono' - JP Complete
        id: 152861,
        name: `True 'Milim's Kimono'`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152860.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152861.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152860.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152861.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 5000% Light DMG (Physical). Increase DMG to Giant Bosses by 70% for 12s.`,
                break: 500
            },
        passive: 
            {
                ability1: `When equipped by New Year Milim, increase Arts by 3/s. Does not stack with Abilities of the same name.`,
                ability2: `Increase DEF by 5%.`
            },
        lore: `Missing Lore.`
    },
{ //True 'Rimuru's Mantle' - Missing Lore
        id: 152871,
        name: `True 'Rimuru's Mantle'`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152870.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152871.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152870.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152871.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `40s CT - Increase Target's Arts by 40. Increase all allies' Arts by 4/s for 5s.`,
                break: 0
            },
        passive: 
            {
                ability1: `When equipped by Demon Lord Rimuru, increase own ATK by 70% & Arts by 1/s. Does not stack with Abilities of the same name.`,
                ability2: `Increase own Skill DMG by 10%.`
            },
        lore: `Missing Lore.`
    },
{ //True 'Milim's Armor' - Missing Lore
        id: 152881,
        name: `True 'Milim's Armor'`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152880.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152881.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152880.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152881.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 6000% Fire DMG (Magic). Increase own Fire DMG by 50% for 15s. If user is Demon, increase own Fire DMG by 80% instead.`,
                break: 600
            },
        passive: 
            {
                ability1: `When equipped by Combat Form Milim, increase own Status resistance (except Faint) by 30% and Arts by 2/s. Does not stack with Abilities of the same name.`,
                ability2: `Increase own Magic resistance by 5%.`
            },
        lore: `Missing Lore.`
    },
{ //True 'Diablo's Suit' - Missing Lore
        id: 152891,
        name: `True 'Diablo's Suit'`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152890.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152891.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152890.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152891.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `40s CT - Increase all Allies' DMG resistance by 30% for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: `When equipped by Diablo, reduce the amount of HP required for his Seducer Passive Ability to 80% HP or higher instead. Does not stack with Abilities of the same name.`,
                ability2: `Increase own Physical resistance by 5%.`
            },
        lore: `Missing Lore.`
    },
{ //Rimuru's Mantle - Missing Lore
        id: 152901,
        name: `Rimuru's Mantle`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152900.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152901.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152900.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152901.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `40s CT - Increase all allies' Arts gauge by 30. Additionally if user is Dark, increase all allies' Max HP by 30% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
{ //Milim's Armor - Missing Lore
        id: 152911,
        name: `Milim's Armor`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152910.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152911.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152910.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152911.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `80s CT - 5000% Fire DMG (Magic). Reduce enemies' Fire resistance by 30% for 10s. If user is Human or Demon, reduce enemies' Fire resistance by 60% instead.`,
                break: 300
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
{ //Diablo's Suit - Missing Lore
        id: 152921,
        name: `Diablo's Suit`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152920.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152921.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152920.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152921.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `30s CT - Increase all allies' Physical resistance by 30% for 20s. If user is Demon, increase all allies' Physical resistance by 45% instead.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
{ //God-Grade Sword 'Asura' - Missing Lore
        id: 152931,
        name: `God-Grade Sword 'Asura'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152930.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152931.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152930.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152931.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 5000% Fire DMG (Physical). Increase own Physical DMG by 60% for 15s. When used for the first time, increase own Arts gauge by 80.`,
                break: 800
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
{ //Souei's Blade - Missing Lore
        id: 152941,
        name: `Souei's Blade`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152940.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152941.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152940.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152941.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `20s CT - 3000% Water DMG (Physical). Reduce enemies' Water resistance by 20% (stackable) for 20s.`,
                break: 300
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
{ //Hakurou's Cane Katana - Missing Lore
        id: 152951,
        name: `Hakurou's Cane Katana`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152950.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152951.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152950.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152951.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 8000% Earth DMG (Physical). Reduce enemies' Earth resistance by 40% for 12s. Additionally if user is Earth, increase own Accuracy rate by 40% for 12s.`,
                break: 800
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Vorpal Chopper - Missing Lore
        id: 152961,
        name: `Vorpal Chopper`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152960.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152961.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152960.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152961.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 8000% Fire DMG (Physical). If user is Human or Beast, increase own CRI rate by 100% for 10s.`,
                break: 800
            },
        passive: 
            {
                ability1: `Increase ATK by 5%.`
            },
        lore: `Missing Lore.`
    },
    { //Vorpal Chopper EV - Missing Lore
        id: 152971,
        name: `Vorpal Chopper EV`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: ``,
                detailmax: `/db/Equipment/Detail/item_detail_152971.png`,
                thumb: ``,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152971.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 8000% Fire DMG (Physical). If user is Human or Beast, increase own CRI rate by 100% for 10s.`,
                break: 800
            },
        passive: 
            {
                ability1: `Increase ATK by 5%.`,
                ability2: [`CRI DMG 10% UP`, `CRI DMG 20% UP`, `Evasion rate 5% UP`, `Evasion rate 10% UP`, `When own HP is 90% or higher, increase own Skill CT Speed by 10%`, `When own HP is 90% or higher, increase own Skill CT Speed by 20%`]
            },
        lore: `Missing Lore.`
    },
    { //Vorpal Soul Collar - Missing Lore
        id: 152981,
        name: `Vorpal Soul Collar`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152980.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152981.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152980.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152981.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `30s CT - Increase all allies' Arts by 2/s, all allies' ATK by 20%, and all Fire allies' ATK by 20% for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Antimony - Missing Lore
        id: 152991,
        name: `Antimony`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_152990.png`,
                detailmax: `/db/Equipment/Detail/item_detail_152991.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_152990.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_152991.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 10000% Light DMG (Physical). For 12s, increase own Physical DMG by 50% for 12s. If user is Human, increase own Physical DMG by 100% instead.`,
                break: 1000
            },
        passive: 
            {
                ability1: [`DMG against Light enemies 20% UP.`, `DMG against Dark enemies 20% UP.`, `Skill CT Speed 20% UP.`, `Arts Gauge 20 UP`],
                ability2: [`None.`, `DMG against Human enemies 20% UP.`, `DMG against God enemies 20% UP.`, `DMG against Demon enemies 20% UP.`, `Light resistance 20% UP.`, `Dark resistance 20% UP.`],
                ability3: [`None.`, `ATK 10% UP.`, `ATK 15% UP.`, `ATK 20% UP.`]
            },
        lore: `Missing Lore.`
    },
    { //Two-Faced Helmet - Missing Lore
        id: 153001,
        name: `Two-Faced Helmet`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153000.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153001.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153000.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153001.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `40s CT - Increase all allies' CRI resistance, Light resistance and Dark resistance by 30% for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Pencilgon's Earrings - Missing Lore (GL)
        id: 153011,
        name: `Pencilgon's Earrings`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153010.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153011.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153010.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153011.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `60s CT - Increase all allies' Arts by 2/s and Physical DMG by 20% for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increase own DEF by 5%.`
            },
        lore: `Missing Lore.`
    },
    { //Pencilgon's Earrings EV - Missing Lore (GL)
        id: 153021,
        name: `Pencilgon's Earrings EV`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: ``,
                detailmax: `/db/Equipment/Detail/item_detail_153021.png`,
                thumb: ``,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153021.png`
            },
            stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `60s CT - Increase all allies' Arts by 2/s and Physical DMG by 20% for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increase own DEF by 5%.`,
                ability2: [`Blind resistance 10% UP`, `Blind resistance 20% UP`, `Physical resistance 5% UP`, `Magic resistance 5% UP`, `ATK 5% UP`, `Unit EXP 10% UP`]
            },
        lore: `Missing Lore.`
    },
    { //Pencilgon's Lance - Missing Lore (GL)
        id: 153031,
        name: `Pencilgon's Lance`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: ``,
                detailmax: `/db/Equipment/Detail/item_detail_153031.png`,
                thumb: ``,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153031.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `75s CT - 7000% Dark DMG (Physical). Increase own Physical DMG by 40% and Human allies' Physical DMG by 30% (stackable) for 15s.`,
                break: 700
            },
        passive: 
            {
                ability1: [`Fire resistance 10% UP.`, `Water resistance 10% UP.`, `Earth resistance 10% UP.`, `CRI DMG 10% UP.`, `CRI DMG 20% UP.`],
                ability2: [`None.`, `DMG against Fire enemies 20% UP.`, `DMG against Water enemies 20% UP.`, `DMG against Earth enemies 20% UP.`],
                ability3: [`None.`, `Skill CT Speed 5% UP.`, `Skill CT Speed 10% UP.`, `Arts Gauge 10 UP.`, `Arts Gauge 20 UP.`]
            },
        lore: `Missing Lore.`
    },
    { //Spirit Fist Gloves - Missing Lore (GL)
        id: 153041,
        name: `Spirit Fist Gloves`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153040.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153041.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153040.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153041.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `20s CT - 4000% Fire DMG (Magic). Reduce enemies' Magic resistance by 20% (stackable) for 15s.`,
                break: 400
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Acolyte's Vest - Missing Lore (GL)
        id: 153051,
        name: `Acolyte's Vest`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153050.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153051.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153050.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153051.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `30s CT - Increase all allies' Fire resistance and Water resistance by 25% for 15s. Additionally if user is Human, increase all allies' DEF by 60% for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Momon's Greatsword - Missing Lore
        id: 153061,
        name: `Momon's Greatsword`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153060.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153061.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153060.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153061.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 6000% Dark DMG (Physical). Increase next Super Arts' DMG by 100%. If user is Dark, increase next Super Arts' DMG by 150% instead.`,
                break: 700
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Dark Plate Armor - Missing Lore
        id: 153071,
        name: `Dark Plate Armor`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153070.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153071.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153070.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153071.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `30s CT - Increase all allies' Dark resistance by 30% for 15s. When used for the first time, increase Demon allies' Arts by 20.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Járngreipr - Missing Lore
        id: 153081,
        name: `Járngreipr`,
        translate: `Jarngreipr`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153080.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153081.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153080.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153081.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `50s CT - Increase all allies' DMG resistance by 25% and Demon allies' stats by 20% for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Necroplasmic Mantle - Missing Lore
        id: 153091,
        name: `Necroplasmic Mantle`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153090.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153091.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153090.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153091.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `30s CT - Increase all allies' Magic resistance by 30% and lowest HP ally's DMG resistance by 20% for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Haste Boots - Missing Lore
        id: 153101,
        name: `Haste Boots`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153100.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153101.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153100.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153101.png`
            },
        stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
        skillset:  
            {
                skill: `45s CT - Increase all allies' Arts by 1/s and Demon allies' Skill CT Speed by 50% for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increase own Skill DMG by 10%.`
            },
        lore: `Missing Lore.`
    },
    { //Demiurge's Suit - Missing Lore
        id: 153111,
        name: `Demiurge's Suit`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153110.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153111.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153110.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153111.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 6000% Fire DMG (Magic). Reduce enemies' DMG resistance by 25% for 10s. If user is Demon, reduce enemies' DMG resistance by 50% instead.`,
                break: 500
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Demiurge's Glasses - Missing Lore
        id: 153121,
        name: `Demiurge's Glasses`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153120.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153121.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153120.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153121.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `70s CT - 4000% Fire DMG (Magic). Reduce enemies' Magic resistance by 40% for 10s. If user is Demon, reduce enemies' Magic resistance by 60% instead.`,
                break: 400
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Demiurge's Gloves - Missing Lore
        id: 153131,
        name: `Demiurge's Gloves`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153130.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153131.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153130.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153131.png`
            },
        stats:
            {
                hp: 120,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - Heal all Demon allies' HP by 30%.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increase own Magic resistance by 5%.`
            },
        lore: `Missing Lore.`
    },
    { //Lord of First Invern - Missing Lore
        id: 153141,
        name: `Lord of First Invern`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153140.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153141.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153140.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153141.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `35s CT - Increase all allies' Arts by 30. Increase Earth allies' DMG by 20% (stackable) for 40s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Evileye's Mask - Missing Lore
        id: 153151,
        name: `Evileye's Mask`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153150.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153151.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153150.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153151.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `25s CT - 5000% Earth DMG (Magic). Increase own Arts by 2/s for 6s. If user is Earth, increase own Arts by 4/s instead.`,
                break: 500
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //True 'Staff of Ainz Ooal Gown' - Missing Lore
        id: 153161,
        name: `True 'Staff of Ainz Ooal Gown'`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153160.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153161.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153160.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153161.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 10000% Dark DMG (Magic). Increase own Magic DMG and Dark DMG by 50% for 10s.`,
                break: 600
            },
        passive: 
            {
                ability1: `When equipped by Ainz, increase own Arts by 100 at Start of Quest. Does not stack with Abilities of the same name.`,
                ability2: `Increase own Magic resistance by 5%.`
            },
        lore: `Missing Lore.`
    },
    { //True '3F' - Missing Lore
        id: 153171,
        name: `True '3F'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153170.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153171.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153170.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153171.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `55s CT - 8000% Dark DMG (Physical). Increase own CRI DMG by 50% for 10s. If user is Dark, increase own CRI DMG by 100% instead.`,
                break: 800
            },
        passive: 
            {
                ability1: `When equipped by Albedo, increase own Arts by 100 at Start of Quest. Does not stack with Abilities of the same name.`,
                ability2: `Increase own ATK by 5%.`
            },
        lore: `Missing Lore.`
    },
    { //True 'Pipette Lance' - Missing Lore
        id: 153181,
        name: `True 'Pipette Lance'`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153180.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153181.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153180.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153181.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 8000% Light DMG (Magic). Reduce enemies' Light resistance by 30% for 10s. If the user is Demon, reduce enemies' Light resistance by 50% instead.`,
                break: 800
            },
        passive: 
            {
                ability1: `When equipped by Shalltear, Increase own Arts by 2/s. Does not stack with Abilities of the same name.`,
                ability2: `When Near-death, heal 1% of own DMG dealt.`
            },
        lore: `Missing Lore.`
    },
    { //True 'Keraunos Mk-III' - Missing Lore
        id: 153191,
        name: `True 'Keraunos Mk-III'`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153190.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153191.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153190.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153191.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `40s CT - Increase allies' Magic DMG by 30% and Arts by 3/s for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `When equipped by Narberal, increase own Arts by 2/s. Does not stack with Abilities of the same name.`,
                ability2: `Increase own ATK by 5%.`
            },
        lore: `Missing Lore.`
    },
    { //True 'Momon's Greatsword' - Missing Lore
        id: 153201,
        name: `True 'Momon's Greatsword'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153200.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153201.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153200.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153201.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 8000% Dark DMG (Physical). Increase own Dark DMG & Skill CT Speed by 50% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `When equipped by Momon, increase own Arts by 2/s. Does not stack with Abilities of the same name.`,
                ability2: `Increase own ATK by 5%.`
            },
        lore: `Missing Lore.`
    },
    { //True 'Demiurge's Suit' - Missing Lore
        id: 153211,
        name: `True 'Demiurge's Suit'`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153210.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153211.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153210.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153211.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 6000% Fire DMG (Magic). Increase own Fire DMG by 60% for 10s. When used for the first time, increase own Arts by 30.`,
                break: 300
            },
        passive: 
            {
                ability1: `When equipped by Demiurge, increase own Arts by 2/s. Does not stack with Abilities of the same name.`,
                ability2: `Increase own DEF by 5%.`
            },
        lore: `Missing Lore.`
    },
    { //True 'Evileye's Mask' - Missing Lore
        id: 153221,
        name: `True 'Evileye's Mask'`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153220.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153221.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153220.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153221.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 8000% Earth DMG (Magic). Reduce enemies' Earth resistance by 30% for 10s. If the user is Demon, reduce enemies' Earth resistance by 50% instead.`,
                break: 500
            },
        passive: 
            {
                ability1: `When equipped by Evileye, increase own Arts by 100 at the start of Quest. Does not stack with Abilities of the same name.`,
                ability2: `Increase own Physical resistance by 5%.`
            },
        lore: `Missing Lore.`
    },
    { //Ira's Hot Rod - Missing Lore
        id: 153231,
        name: `Ira's Hot Rod`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153230.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153231.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153230.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153231.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 8200% Dark DMG (Physical). Increase own Physical DMG by 30% for 10s. If user is Dark, increase own Physical DMG by 60% instead.`,
                break: 700
            },
        passive: 
            {
                ability1: `When own HP is 90% or above, increase own Accuracy by 20%.`
            },
        lore: `Missing Lore.`
    },
    { //Ira's Hot Rod EV - Missing Lore
        id: 153241,
        name: `Ira's Hot Rod EV`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: ``,
                detailmax: `/db/Equipment/Detail/item_detail_153241.png`,
                thumb: ``,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153241.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 8200% Dark DMG (Physical). Increase own Physical DMG by 30% for 10s. If user is Dark, increase own Physical DMG by 60% instead.`,
                break: 700
            },
        passive: 
            {
                ability1: `When own HP is 90% or above, increase own Accuracy by 20%.`,
                ability2: [`Increase own DMG against Humans by 20%.`, `Increase own DMG against Beasts by 20%.`, `Increase own Light resistance by 10%.`, `Increase own Dark resistance by 10%.`, `Increase own Skill CT Speed by 10%.`, `Increase own HP Recovery Amount by 10%.`]
            },
        lore: `Missing Lore.`
    },
    { //Blade Regalia - Missing Lore
        id: 153251,
        name: `Blade Regalia`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153250.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153251.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153250.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153251.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 7000% Earth DMG (Physical). Increase own Physical DMG by 60% for 12s. When used for the first time, increase own Arts by 50. If user is Human and when used for the first time, increase own Arts by 100 instead.`,
                break: 500
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Probe Regalia - Missing Lore
        id: 153261,
        name: `Probe Regalia`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153260.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153261.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153260.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153261.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `40s CT - Increase all allies' CRI DMG by 70% for 12s. If user is Water, increase all allies' CRI DMG by 120% instead.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Shackle Regalia - Missing Lore
        id: 153271,
        name: `Shackle Regalia`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153270.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153271.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153270.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153271.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `30s CT - For 10s, heal allies' HP by 200/s. For 20s, increase allies' Physical resistance by 30%.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Symphony Regalia - Missing Lore
        id: 153281,
        name: `Symphony Regalia`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153280.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153281.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153280.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153281.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 2000% Light DMG (Magic). For 15s, increase own Break Power by 50% and Light allies' Break Power by 30%.`,
                break: 3500
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Uzu's Raid Tracksuit - Missing Lore
        id: 153291,
        name: `Uzu's Raid Tracksuit`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153290.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153291.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153290.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153291.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 6000% Earth DMG (Physical). Reduce enemies' DMG resistance by 25% for 10s. If user is Earth, reduce enemies' DMG resistance by 50% instead.`,
                break: 500
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Houka's Raid Tracksuit - Missing Lore
        id: 153301,
        name: `Houka's Raid Tracksuit`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153300.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153301.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153300.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153301.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `30s CT - Increase all allies' Arts by 3/s and Water resistance by 20% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Ira's Raid Tracksuit - Missing Lore
        id: 153311,
        name: `Ira's Raid Tracksuit`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153310.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153311.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153310.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153311.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `30s CT - For 20s, increase target's Light resistance by 30%. When used for the first time, increase target's Arts by 50.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Nonon's Raid Tracksuit - Missing Lore
        id: 153321,
        name: `Nonon's Raid Tracksuit`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153320.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153321.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153320.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153321.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 4000% Light DMG (Magic). Increase own Arts by 30. For 10s, increase own Light DMG by 50%. Additionally when used for the first time, increase own Arts by 50.`,
                break: 300
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Uzu's Eye Mask - Missing Lore
        id: 153331,
        name: `Uzu's Eye Mask`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153330.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153331.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153330.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153331.png`
            },
        stats:
            {
                hp: 500,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `30s CT - Increase all allies' Earth resistance by 30% for 20s. If user is Human, increase all allies' Earth resistance by 45% instead.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Houka's Eyeglasses - Missing Lore
        id: 153341,
        name: `Houka's Eyeglasses`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153340.png`,
                detailmax: `/db/Equipment/Detail/item_detail_1533341.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153340.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153341.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `45s CT - Increase all allies' Arts by 40. Increase Water allies' DMG by 30% for 12s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Nonon's Hat - Missing Lore
        id: 153351,
        name: `Nonon's Hat`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153350.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153351.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153350.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153351.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `50s CT - Increase target's Arts by 100. If user is Light, for 10s, increase target's DMG by 50%.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //True 'Blade Regalia' - Missing Lore
        id: 153361,
        name: `True 'Blade Regalia'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153360.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153361.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153360.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153361.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 8000% Earth DMG (Physical). Reduce enemies' Earth resistance by 30% for 10s. If user is Human, reduce enemies' Earth resistance by 50% instead.`,
                break: 500
            },
        passive: 
            {
                ability1: `When equipped by Uzu Sanageyama, increase own CRI DMG by 70%. Does not stack with Abilities of the same name.`,
                ability2: `Increase own DEF by 10%.`
            },
        lore: `Missing Lore.`
    },
    { //True 'Probe Regalia' - Missing Lore
        id: 153371,
        name: `True 'Probe Regalia'`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153370.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153371.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153370.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153371.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `35s CT - Increase all allies' Arts by 3/s and Human allies' stats by 15% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `When equipped by Houka Inumuta, increase own Arts by 100 at Start of Quest. Does not stack with Abilities of the same name.`,
                ability2: `Increase own DEF by 10%.`
            },
        lore: `Missing Lore.`
    },
    { //True 'Shackle Regalia' - Missing Lore
        id: 153381,
        name: `True 'Shackle Regalia'`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153380.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153381.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153380.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153381.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `30s CT - For 15s, increase own DMG resistance by 20%. If used when own HP is below 50%, increase own DMG resistance by 30% instead.`,
                break: 0
            },
        passive: 
            {
                ability1: `When equipped by Ira Gamagoori, increase own Arts by 100 at Start of Quest. Does not stack with Abilities of the same name.`,
                ability2: `Increase own DEF by 10%.`
            },
        lore: `Missing Lore.`
    },
    { //True 'Symphony Regalia' - Missing Lore
        id: 153391,
        name: `True 'Symphony Regalia'`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153390.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153391.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153390.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153391.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 8000% Light DMG (Magic). For 10s, reduce enemies' DMG resistance by 20%. If user is Light, reduce enemies' DMG resistance by an additional 10%. If user is Human, reduce enemies' DMG resistance by an additional 10%.`,
                break: 500
            },
        passive: 
            {
                ability1: `When equipped by Nonon Jakuzure, increase own ATK and Skill CT Speed by 50%. Does not stack with Abilities of the same name.`,
                ability2: `Increase own DEF by 10%.`
            },
        lore: `Missing Lore.`
    },
    { //True 'Illya's Swimsuit' - Missing Lore
        id: 153401,
        name: `True 'Illya's Swimsuit'`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153400.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153401.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153400.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153401.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `55s CT - 8000% Water DMG (Magic). Increase own Magic DMG by 50% for 10s. If user is Water, increase own Magic DMG by 100% instead.`,
                break: 800
            },
        passive: 
            {
                ability1: `When equipped by Summer Illya, increase own DMG by 30% and Arts by 2/s. Does not stack with Abilities of the same name.`,
                ability2: `Increase own ATK by 5%.`
            },
        lore: `Missing Lore.`
    },
    { //True 'Miyu's Swimsuit' - Missing Lore
        id: 153411,
        name: `True 'Miyu's Swimsuit'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153410.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153411.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153410.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153411.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 5000% Fire DMG (Physical). Reduce enemies' Fire resistance by 40% and increase own ATK by 40% for 10s.`,
                break: 500
            },
        passive: 
            {
                ability1: `When equipped by Summer Miyu, increase own Arts by 3/s. Does not stack with Abilities of the same name.`,
                ability2: `Increase own Physical resistance by 5%.`
            },
        lore: `Missing Lore.`
    },
    { //True 'Chloe's Swimsuit' - Missing Lore
        id: 153421,
        name: `True 'Chloe's Swimsuit'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153420.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153421.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153420.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153421.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `25s CT - 5000% Light DMG (Physical). Reduce enemies' Light resistance by 15% (stackable) for 15s. If user is Human, increase own Arts by 10.`,
                break: 500
            },
        passive: 
            {
                ability1: `When equipped by Summer Chloe, increase own Arts by 100 at the start of Quest. Does not stack with Abilities of the same name.`,
                ability2: `Increase own ATK by 5%.`
            },
        lore: `Missing Lore.`
    },
    { //True 'Luvia's Swimsuit' - Missing Lore
        id: 153431,
        name: `True 'Luvia's Swimsuit'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153430.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153431.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153430.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153431.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 9000% Earth DMG (Physical). Increase own Arts by 30. Additionally if user is Earth, increase all stats by 30% for 10s.`,
                break: 800
            },
        passive: 
            {
                ability1: `When equipped by Summer Luvia & Rin, increase own ATK by 30% and Arts by 2/s. Does not stack with Abilities of the same name.`,
                ability2: `Increase own DEF by 5%.`
            },
        lore: `Missing Lore.`
    },
    { //True 'Bazett's Swimsuit' - Missing Lore
        id: 153441,
        name: `True 'Bazett's Swimsuit'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153440.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153441.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153440.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153441.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 12000% Water DMG (Physical). If user is Water, reduce enemies' DMG resistance by 20% for 10s. If user is Water and when own HP is above 80%, reduce enemies' DMG resistance by 35% instead.`,
                break: 800
            },
        passive: 
            {
                ability1: `When equipped by Summer Bazett, increase own DMG by 50% and Skill CT Speed by 30%. Does not stack with Abilities of the same name.`,
                ability2: `Increase own DEF by 5%.`
            },
        lore: `Missing Lore.`
    },
    { //True 'Sakura's Swimsuit' - Missing Lore
        id: 153451,
        name: `True 'Sakura's Swimsuit'`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153450.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153451.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153450.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153451.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 8000% Dark DMG (Magic). Reduce enemies' Dark resistance by 30% for 10s. If user is Human, reduce enemies' Dark resistance by 50% instead.`,
                break: 500
            },
        passive: 
            {
                ability1: `When equipped by Summer Sakura, increase own ATK by 40% and Arts by 2/s. Does not stack with Abilities of the same name.`,
                ability2: `Increase own ATK by 5%.`
            },
        lore: `Missing Lore.`
    },
    { //Illya's Swimsuit - Missing Lore
        id: 153461,
        name: `Illya's Swimsuit`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153460.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153461.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153460.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153461.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 6000% Water DMG (Magic). Increase all stats by 10% for 12s. If user is Water, increase all stats by 20% instead.`,
                break: 400
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore .`
    },
    { //Keepsake Bracelet - Missing Lore
        id: 153471,
        name: `Keepsake Bracelet`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153470.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153471.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153470.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153471.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 5000% Water DMG (Magic). Increase own Arts by 30. Increase own Magic DMG by 40% for 15s.`,
                break: 500
            },
        passive: 
            {
                ability1: `Increase own Magic resistance by 5%.`
            },
        lore: `Missing Lore.`
    },
    { //Homurabara E. Summer Uniform - Missing Lore
        id: 153481,
        name: `Homurabara E. Summer Uniform`,
        type: `/db/Mines/thumbnail/suppLB.png`, 
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153480.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153481.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153480.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153481.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `35s CT - Increase allies' Arts by 30. Increase Human allies' DMG by 20% (stackable) for 40s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Hecate's Staff - Missing Lore
        id: 153491,
        name: `Hecate's Staff`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153490.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153491.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153490.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153491.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `65s CT - 3000% Water DMG (Magic). Reduce enemies' Magic resistance by 35% for 10s. Additionally if user is Human, reduce enemies' DMG resistance by 35% for 10s.`,
                break: 300
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Rule Breaker - Missing Lore
        id: 153501,
        name: `Rule Breaker`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153500.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153501.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153500.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153501.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 4000% Water DMG (Physical). Increase own Break Power by 60% for 8s. Increase own Break Power by 90% against enemies affected by Status ailments instead.`,
                break: 4000
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Arrest Chain - Missing Lore
        id: 153511,
        name: `Arrest Chain`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153510.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153511.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153510.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153511.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `35s CT - Increase Water allies' DMG by 40% (stackable) for 40s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Miyu's Swimsuit - Missing Lore
        id: 153521,
        name: `Miyu's Swimsuit`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153520.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153521.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153520.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153521.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `80s CT - 13500% Fire DMG (Physical). Increase own ATK by 100% for 10s. If user is above 80% HP, increase own ATK by 150% instead.`,
                break: 1000
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Miyu's Straw Hat - Missing Lore
        id: 153531,
        name: `Miyu's Straw Hat`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153530.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153531.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153530.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153531.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 8000% Fire DMG (Magic). If user is Fire, increase own CRI rate by 100% for 10s.`,
                break: 800
            },
        passive: 
            {
                ability1: `Increase own MAX HP by 5%.`
            },
        lore: `Missing Lore.`
    },
    { //Chloe's Swimsuit - Missing Lore
        id: 153541,
        name: `Chloe's Swimsuit`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153540.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153541.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153540.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153541.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 5000% Light DMG (Physical). Increase own Physical DMG by 40% for 12s. If user is Light, increase own Physical DMG by 80% instead. When used for the first time, increase own Arts by 70.`,
                break: 800
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Chloe's Swim Dress - Missing Lore
        id: 153551,
        name: `Chloe's Swim Dress`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153550.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153551.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153550.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153551.png`
            },
        stats:
            {
                hp: 500,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `25s CT - Heal all allies' HP by 2000 and cure all allies' Poison.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Rin's Swimsuit - Missing Lore
        id: 153561,
        name: `Rin's Swimsuit`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153560.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153561.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153560.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153561.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `35s CT - Increase allies' Arts by 3/s and Earth allies' Skill CT Speed by 80% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Luvia's Swimsuit - Missing Lore
        id: 153571,
        name: `Luvia's Swimsuit`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153570.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153571.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153570.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153571.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `20s CT - 3000% Earth DMG (Physical). Reduce enemies' Earth resistance by 20% (stackable) for 20s.`,
                break: 300
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Bazett's Swimsuit - Missing Lore
        id: 153581,
        name: `Bazett's Swimsuit`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153580.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153581.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153580.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153581.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 5000% Water DMG (Physical). Reduce enemies' Water resistance by 40% for 10s. When used for the first time, reduce enemies' Water resistance by 60% instead.`,
                break: 500
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Bazett's Apron - Missing Lore
        id: 153591,
        name: `Bazett's Apron`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153590.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153591.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153590.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153591.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `40s CT - Heal all allies' HP by 1500. Increase all allies' Physical resistance by 40% for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Fragarach - Missing Lore
        id: 153601,
        name: `Fragarach`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153600.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153601.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153600.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153601.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 10000% Water DMG (Physical). Reduce enemies' Physical resistance by 30% for 10s. If used when own HP is above 90%, reduce enemies' Physical resistance by 50% instead.`,
                break: 1000
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Sakura's Swimsuit - Missing Lore
        id: 153611,
        name: `Sakura's Swimsuit`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153610.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153611.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153610.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153611.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 5000% Dark DMG (Magic). Increase own Magic DMG by 60% for 15s. When used for the first time, increase own Arts by 80.`,
                break: 800
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Sakura's Mask - Missing Lore
        id: 153621,
        name: `Sakura's Mask`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153620.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153621.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153620.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153621.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 5000% Dark DMG (Magic). Increase own Magic DMG by 60% for 10s. Additionally if user is Human, increase own Equip CT Speed by 30% for 10s.`,
                break: 300
            },
        passive: 
            {
                ability1: `Increase own Dark resistance by 5%.`
            },
        lore: `Missing Lore.`
    },
    { //Sakura's Uniform - Missing Lore
        id: 153631,
        name: `Sakura's Uniform`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153630.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153631.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153630.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153631.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `30s CT - Increase allies' Light resistance by 30% for 15s. When used for the first time, increase Human allies' Arts by 20.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //True 'Shadow's Sword' - Missing Lore
        id: 153641,
        name: `True 'Shadow's Sword'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153640.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153641.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153640.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153641.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 8000% Dark DMG (Physical). Reduce enemies' Dark resistance by 40% for 8s. Additionally if user is Human, increase own DMG by 50% for 8s.`,
                break: 1700
            },
        passive: 
            {
                ability1: `When equipped by Shadow, increase own DMG by 40% and Arts by 2/s. Does not stack with Abilities of the same name.`,
                ability2: `Increase own Evasion rate by 5%.`
            },
        lore: `Missing Lore.`
    },
    { //True 'Beta's Bow' - Missing Lore
        id: 153651,
        name: `True 'Beta's Bow'`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153650.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153651.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153650.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153651.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 9000% Water DMG (Magic). Reduce enemies' Water resistance by 30% for 10s. If user is Spirit, reduce enemies' Water resistance by 50% instead.`,
                break: 800
            },
        passive: 
            {
                ability1: `When equipped by Beta, increase own ATK by 30% and Arts by 3/s. Does not stack with Abilities of the same name.`,
                ability2: `Increase own DEF by 5%.`
            },
        lore: `Missing Lore.`
    },
    { //Shadow's Boots - Missing Lore
        id: 153661,
        name: `Shadow's Boots`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153660.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153661.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153660.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153661.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 5800% Dark DMG (Physical). Increase Dark allies' ATK by 20% for 10s.`,
                break: 1000
            },
        passive: 
            {
                ability1: `Increase own DMG against Light enemies by 5%.`
            },
        lore: `Missing Lore.`
    },
    { //Shadow's Sword - Missing Lore
        id: 153671,
        name: `Shadow's Sword`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153670.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153671.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153670.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153671.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 7000% Dark DMG (Physical). Increase own Physical DMG by 60% for 12s. When used for the first time, increase own Arts by 50. Additionally, if user is Dark, increase own Arts by 50.`,
                break: 500
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Shadow's Bodysuit - Missing Lore
        id: 153681,
        name: `Shadow's Bodysuit`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153680.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153681.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153680.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153681.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 10000% Dark DMG (Magic). Increase Dark allies' BE Output by 80% for 10s.`,
                break: 1000
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Beta's Bow - Missing Lore
        id: 153691,
        name: `Beta's Bow`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153690.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153691.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153690.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153691.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `65s CT - 5000% Water DMG (Magic). Reduce enemies' Magic resistance by 30% for 10s. If user is not Human, God, or Demon, reduce enemies' Magic resistance by 60% instead.`,
                break: 500
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Beta's Bodysuit - Missing Lore
        id: 153701,
        name: `Beta's Bodysuit`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153700.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153701.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153700.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153701.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `40s CT - Increase all allies' Arts by 2/s and Water allies' Break Power by 50% for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Beta's Spectacles - Missing Lore
        id: 153711,
        name: `Beta's Spectacles`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153710.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153711.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153710.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153711.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 2000% Water DMG (Magic). Increase own Magic DMG and Magic resistance by 10% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Gamma Plushie - Missing Lore
        id: 153721,
        name: `Gamma Plushie`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153720.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153721.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153720.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153721.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `55s CT - 7000% Earth DMG (Magic). Increase own CRI rate by 100% for 10s. Additionally if user is not affected by Status ailment(s), increase own CRI DMG by 50% for 10s.`,
                break: 500
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Zeta Plushie - Missing Lore
        id: 153731,
        name: `Zeta Plushie`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153730.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153731.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153730.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153731.png`
            },
        stats:
            {
                hp: 500,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - Heal Fire allies' HP by 300/s for 20s. When used for the first time, increase Leader unit's Arts by 50.`,
                break: 0    
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //True 'Alpha's Sword' - Missing Lore
        id: 153741,
        name: `True 'Alpha's Sword'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153740.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153741.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153740.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153741.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `55s CT - 8000% Light DMG (Physical). Increase own CRI DMG by 50% for 10s. If user is Light, increase own CRI DMG by 100% instead.`,
                break: 800
            },
        passive: 
            {
                ability1: `When equipped by Alpha, increase own ATK by 20%. Increase own Arts by 100 at the Start of a Quest. Does not stack with Abilities of the same name.`,
                ability2: `Increase own Magic resistance by 5%.`
            },
        lore: `Missing Lore.`
    },
    { //True 'Delta's Bodysuit' - Missing Lore
        id: 153751,
        name: `True 'Delta's Bodysuit'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153750.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153751.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153750.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153751.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `70s CT - 12000% Fire DMG (Physical). Increase own DMG by 50% and Fire DMG by 70% for 15s.`,
                break: 500
            },
        passive: 
            {
                ability1: `When equipped by Delta, increase own ATK by 40% and Arts by 1/s. Does not stack with Abilities of the same name.`,
                ability2: `Increase own Physical resistance by 5%.`
            },
        lore: `Missing Lore.`
    },
    { //Delta's Hairpiece - Missing Lore
        id: 153761,
        name: `Delta's Hairpiece`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153760.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153761.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153760.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153761.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `55s CT - 5000% Fire DMG (Magic). Reduce Giant Boss' Fire resistance by 20% for 10s.`,
                break: 700
            },
        passive: 
            {
                ability1: `Increase own DMG against Giants by 10%.`
            },
        lore: `Missing Lore.`
    },
    { //Alpha's Sword - Missing Lore
        id: 153771,
        name: `Alpha's Sword`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153770.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153771.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153770.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153771.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 6000% Light DMG (Physical). Increase own Physical DMG by 30% for 12s. Additionally if used when own Arts is below 200, increase own Arts by 50.`,
                break: 700
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Alpha's Bodysuit - Missing Lore
        id: 153781,
        name: `Alpha's Bodysuit`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153780.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153781.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153780.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153781.png`
            },
        stats:
            {
                hp: 500,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - Heal all allies' Status ailments (except Faint). For 20s, heal Light allies' HP by 300/s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Alpha's Boots - Missing Lore
        id: 153791,
        name: `Alpha's Boots`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153790.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153791.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153790.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153791.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 4000% Light DMG (Physical). Increase own CRI DMG by 30% for 10s. Additionally when used for the first time, increase own Arts by 30.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Delta's Bodysuit - Missing Lore
        id: 153801,
        name: `Delta's Bodysuit`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153800.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153801.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153800.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153801.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `65s CT - 5000% Fire DMG (Physical). Reduce enemies' Physical resistance by 30% for 10s. If user is not Human, God or Demon, reduce enemies' Physical resistance by 60% instead.`,
                break: 500
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Delta's Boots - Missing Lore
        id: 153811,
        name: `Delta's Boots`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153810.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153811.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153810.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153811.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `20s CT - 3000% Fire DMG (Physical). Reduce enemies' Physical resistance by 15% (stackable) for 30s.`,
                break: 300
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Epsilon Plushie - Missing Lore
        id: 153821,
        name: `Epsilon Plushie`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153820.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153821.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153820.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153821.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `40s CT - Increase all allies' Arts by 30. Additionally if user is Water, increase all allies' MAX HP by 30% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //Eta Plushie - Missing Lore
        id: 153831,
        name: `Eta Plushie`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153830.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153831.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153830.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153831.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `80s CT - 7000% Dark DMG (Magic). Increase own Magic DMG by 70% and all allies' Magic DMG by 40% for 12s.`,
                break: 700
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Missing Lore.`
    },
    { //真『魔王リムルの侍刀』 - JP Complete
        id: 153841,
        name: `真『魔王リムルの侍刀』`,
        translate: `True 'Demon Lord Rimuru's Samurai Sword'`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153840.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153841.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153840.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153841.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 8000% Dark DMG (Physical). Reduce enemies' Dark resistance by 30% for 10s. If user is Demon, reduce enemies' Dark resistance by 50% instead.`,
                break: 500
            },
        passive: 
            {
                ability1: `When equipped by Demon Lord Rimuru (New Years), increase own ATK and DMG by 30%. Does not stack with Abilities of the same name.`,
                ability2: `Increase own ATK by 5%.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //真『シュナの着物』 - JP Complete
        id: 153851,
        name: `真『シュナの着物』`,
        translate: `True 'Shuna's Kimono'`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153850.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153851.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153850.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153851.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `35s CT - Increase all allies' Arts by 3/s for 10s. Additionally if user is Demon, increase all allies' Stats by 10% for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: `When equipped by Shuna (New Years), increase own MAX HP by 20%. Increase own Arts by 100 at Start of Quest. Does not stack with Abilities of the same name.`,
                ability2: `Increase own DEF by 10%.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //魔王リムルの侍刀 - JP Complete
        id: 153861,
        name: `魔王リムルの侍刀`,
        translate: `Demon Lord Rimuru's Samurai Sword`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153860.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153861.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153860.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153861.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `70s CT - 8000% Dark DMG (Physical). Reduce enemy's Physical resistance by 40% for 10s. If user is Demon, reduce enemies' Physical resistance by 60% instead.`,
                break: 800
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Currently unreleased in Global.`
    },
    { //魔王リムルの袴 - JP Complete
        id: 153871,
        name: `魔王リムルの袴`,
        translate: `Demon Lord Rimuru's Hakama`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153870.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153871.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153870.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153871.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `30s CT - Increase all allies' Arts by 2/s, all allies' ATK by 20% and Dark allies' ATK by an additional 20% for 15s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Currently unreleased in Global.`
    },
    { //魔王リムルのぬいぐるみ - JP Complete
        id: 153881,
        name: `魔王リムルのぬいぐるみ`,
        translate: `Demon Lord Rimuru's Plushie`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153880.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153881.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153880.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153881.png`
            },
        stats:
            {
                hp: 500,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - Heal all allies' HP by 150/s for 20s. Additionally if user is Demon, increase all allies' Heal Amount by 20% for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Currently unreleased in Global.`
    },
    { //シュナの着物 - JP Complete
        id: 153891,
        name: `シュナの着物`,
        translate: `Shuna's Kimono`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153890.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153891.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153890.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153891.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `30s CT - Increase target's Magic resistance by 20% and Arts by 8/s for 10s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Currently unreleased in Global.`
    },
    { //シュナのアクセサリー - JP Complete
        id: 153901,
        name: `シュナのアクセサリー`,
        translate: `Shuna's Accessories`,
        type: `/db/Mines/thumbnail/healLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153900.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153901.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153900.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153901.png`
            },
        stats:
            {
                hp: 500,
                atk: 0,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - Heal Light allies' HP by 300/s for 20s. Additionally when used for the first time, increase Leader's Arts by 50.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Currently unreleased in Global.`
    },
    { //シュナのぬいぐるみ - JP Complete
        id: 153911,
        name: `シュナのぬいぐるみ`,
        translate: `Shuna's Plushie`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153910.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153911.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153910.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153911.png`
            },
        stats:
            {
                hp: 250,
                atk: 125,
                def: 125
            },
        skillset:  
            {
                skill: `35s CT - Increase all allies' Arts by 30. Increase Demon allies' DMG by 20% (Stackable) for 40s.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Currently unreleased in Global.`
    },
    { //スライムリムルのぬいぐるみ - JP Complete
        id: 153921,
        name: `スライムリムルのぬいぐるみ`,
        translate: `Stuffed Slime Rimuru Toy`,
        type: `/db/Mines/thumbnail/suppLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153920.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153921.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153920.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153921.png`
            },
        stats:
            {
                hp: 60,
                atk: 30,
                def: 30
            },
        skillset:  
            {
                skill: `45s CT - Increase all allies' Arts by 1/s and Water allies' Skill CT Speed by 50% for 20s.`,
                break: 0
            },
        passive: 
            {
                ability1: `Increase own Skill DMG by 10%.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //真『ヴェルドラのマント』 - JP Complete
        id: 153931,
        name: `真『ヴェルドラのマント』`,
        translate: `True 'Veldora's Cloak'`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153930.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153931.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153930.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153931.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 10000% Earth DMG (Magic). Increase own Magic DMG by 50% for 10s. Additionally if user is Earth, increase own ATK by 50% for 10s.`,
                break: 1000
            },
        passive: 
            {
                ability1: `When equipped by Veldora, increase own ATK by 50% and Arts by 1/s. Does not stack with Abilities of the same name.`,
                ability2: `Increase own Accuracy by 10%.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //真『シズの剣』 - JP Complete
        id: 153941,
        name: `真『シズの剣』`,
        translate: `True 'Shizu's Sword'`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153940.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153941.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153940.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153941.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `60s CT - 8000% Fire DMG (Physical). Reduce enemies' DMG resistance by 20% for 10s. If user is Fire, reduce enemies' DMG resistance by an additional 10%. If user is Human, reduce enemies' DMG resistance by an additional 10%.`,
                break: 500
            },
        passive: 
            {
                ability1: `When equipped by Shizu, increase own DMG, Heal Amount, and HP Recovery Amount by 30% (excluding Lifesteal). Does not stack with Abilities of the same name.`,
                ability2: `Increase own DEF by 10%.`
            },
        lore: `Currently unreleased in Global.`
    },
    { //ヴェルドラのマント - JP Complete
        id: 153951,
        name: `ヴェルドラのマント`,
        translate: `Veldora's Cloak`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153950.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153951.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153950.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153951.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `20s CT - 3000% Earth DMG (Magic). For 20s, reduce enemies' Earth resistance by 20% (stackable).`,
                break: 300
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Currently unreleased in Global.`
    },
    { //ヴェルドラの手袋 - JP Complete
        id: 153961,
        name: `ヴェルドラの手袋`,
        translate: `Veldora's Gloves`,
        type: `/db/Mines/thumbnail/defLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153960.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153961.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153960.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153961.png`
            },
        stats:
            {
                hp: 0,
                atk: 0,
                def: 500
            },
        skillset:  
            {
                skill: `30s CT - Increase all allies' Magic resistance by 30% for 20s. If user is not Human, God or Demon, increase all allies' Magic resistance by 45% instead.`,
                break: 0
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Currently unreleased in Global.`
    },
    { //シズの剣 - JP Complete
        id: 153971,
        name: `シズの剣`,
        translate: `Shizu's Sword`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153970.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153971.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153970.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153971.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 8000% Fire DMG (Physical). Increase Fire allies' BE Output by 50% for 12s. When used for the first time, increase Fire allies' BE Output by 80% instead.`,
                break: 800
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Currently unreleased in Global.`
    },
    { //シズの服 - JP Complete
        id: 153981,
        name: `シズの服`,
        translate: `Shizu's Clothes`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153980.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153981.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153980.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153981.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `50s CT - 7000% Fire DMG (Magic). 100% chance to Burn enemies for 10s. If enemy was Burned by this equip, reduce enemy's Magic resistance by 40% for 10s.`,
                break: 700
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Currently unreleased in Global.`
    },
    { //シズのぬいぐるみ - JP Complete
        id: 153991,
        name: `シズのぬいぐるみ`,
        translate: `Shizu Plushie`,
        type: `/db/Mines/thumbnail/magLB.png`,
        star: 5,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_153990.png`,
                detailmax: `/db/Equipment/Detail/item_detail_153991.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_153990.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_153991.png`
            },
        stats:
            {
                hp: 0,
                atk: 500,
                def: 0
            },
        skillset:  
            {
                skill: `45s CT - 7000% Fire DMG (Magic). Increase own Magic DMG by 60% for 12s. When used for the first time, increase own Arts by 50. Additionally if user is Fire and when used for the first time, increase own Arts by 100 instead.`,
                break: 500
            },
        passive: 
            {
                ability1: ``
            },
        lore: `Currently unreleased in Global.`
    },
    { //ヴェルドラのぬいぐるみ - JP Complete
        id: 154001,
        name: `ヴェルドラのぬいぐるみ`,
        translate: `Veldora Plushie`,
        type: `/db/Mines/thumbnail/physLB.png`,
        star: 4,
        image: 
            {
                detail: `/db/Equipment/Detail/item_detail_154000.png`,
                detailmax: `/db/Equipment/Detail/item_detail_154001.png`,
                thumb: `/db/Equipment/Thumbnail/item_thumbnail_154000.png`,
                thumbmax: `/db/Equipment/Thumbnail/item_thumbnail_154001.png`
            },
        stats:
            {
                hp: 0,
                atk: 120,
                def: 0
            },
        skillset:  
            {
                skill: `40s CT - 2500% Earth DMG (Physical). Increase own Skill CT Speed by 20% for 10s. If user is Earth, increase own Skill CT Speed by 40% instead.`,
                break: 300
            },
        passive: 
            {
                ability1: `Increase own Magic resistance by 5%.`
            },
        lore: `Currently unreleased in Global.`
    }
]

export function getEquips() {
    return EquipInformation;
}

export function getEquipByName(name) {
    return EquipInformation.find(
        equips => equips.name === name
    );
}

